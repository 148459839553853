import type { FieldPolicy } from '@apollo/client'
import type { DomainOrder, Query } from '@shared/gql/document-nodes'

import { DeliverySlotReservationDocument } from '@shared/gql/document-nodes'
import { asCustomerType } from 'domain/customer'
import { CardType } from 'domain/customer-detail'
import { isPaymentStatus } from 'domain/payment'
import { shoppingCartVar } from 'services/ClientCart/cache'
import { deliverySlotReservationIdVar } from 'services/DeliverySlot/utils/local-storage'

// this should be temporary layer of abstraction to
// read order data from apollo cache and covert it to some form of usable type
// in this case DomainOrder which we can easily use and query in the frontend
//
// All data manipulation from apollo -> domain should happen here in some form of
// mapper function
export const domainOrder: FieldPolicy<DomainOrder, DomainOrder> = {
  read: (_, { readField, cache }): DomainOrder => {
    const customer = readField<Query['customerDetails']>('customerDetails')
    const customerType = readField<Query['customerType']>('customerType')
    const delivery = readField<Query['deliveryDetailsInfo']>('deliveryDetailsInfo')
    const orderEditActive = readField<Query['orderEditActive']>('orderEditActive')
    const payment = readField<Query['paymentDetails']>('paymentDetails')
    const selectedAreaId = readField<Query['selectedAreaId']>('selectedAreaId')
    const selectedStoreId = readField<Query['selectedStoreId']>('selectedStoreId')

    const reservationId = deliverySlotReservationIdVar()
    const reservation =
      (reservationId &&
        cache.readQuery({
          query: DeliverySlotReservationDocument,
          variables: {
            reservationId,
          },
        })?.deliverySlotReservation) ||
      null

    return {
      __typename: 'DomainOrder',
      orderStatus: toNullable(orderEditActive?.orderStatus),
      id: toNullable(orderEditActive?.orderId),
      customer: {
        __typename: 'DomainCustomer',
        type: asCustomerType(customerType),
        email: toNullable(customer?.email),
        firstName: toNullable(customer?.firstName),
        lastName: toNullable(customer?.lastName),
        phone: toNullable(customer?.phone),
        companyName: toNullable(customer?.companyName),
        stockmannCardNumber: toNullable(payment?.stockmannCardNumber),
        cardType: toNullable(payment?.stockmannCardNumber) ? CardType.STOCKMANN : null,
      },
      deliveryAddress: {
        __typename: 'DomainDelivery',
        address: toNullable(delivery?.address),
        city: toNullable(delivery?.city),
        postalCode: toNullable(delivery?.postalCode),
      },
      payment: {
        __typename: 'DomainPayment',
        invoiceNumber: toNullable(payment?.invoiceNumber),
        paymentMethod: toNullable(payment?.paymentMethod),
        paymentStatus:
          payment?.paymentStatus && isPaymentStatus(payment.paymentStatus)
            ? payment.paymentStatus
            : null,
      },
      cartItems: shoppingCartVar(),
      additionalInfo: toNullable(delivery?.additionalInfo),
      comment: toNullable(customer?.comment),
      storeId: toNullable(selectedStoreId),
      reservationId: toNullable(reservation?.reservationId),
      deliveryAreaId: reservation ? reservation.deliveryArea.areaId : toNullable(selectedAreaId),
      deliverySlotId: reservation
        ? reservation.deliverySlot.slotId
        : toNullable(delivery?.deliverySlotId),
      discountCode: '',
    }
  },
}

const toNullable = <T>(x: T | undefined): T | null => x || null
