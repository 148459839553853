import type { TypedTypePolicies } from 'types/gql/apollo-helpers'

type RecipePolicy = NonNullable<TypedTypePolicies['Recipe']>

export const Recipe: RecipePolicy = {
  keyFields: ['id'],
}

export const RecipeIngredient: NonNullable<TypedTypePolicies['RecipeIngredient']> = {
  fields: {
    products: {
      keyArgs: ['storeId'],
    },
  },
}
