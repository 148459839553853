import type { Brand } from '@shared/domain/brand'
import type { StoreAndDeliveryTrackingData } from '@shared/domain/tracking/store-and-delivery'

import { useQuery } from '@apollo/client'
import {
  GetTrackingSelectedStoreDataDocument,
  RemoteGetTrackingSelectedDeliverySlotDataDocument,
  RemoteGetTrackingSelectedStoreDataDocument,
} from '@shared/gql/document-nodes'
import { useEffect } from 'react'
import {
  useDeliverySlotReservation,
  useIsDeliverySlotReservationEnabled,
  useSelectedDeliverySlot,
} from 'services/DeliverySlot/hooks'

import { dataLayer } from '../tag-manager'

export const useStoreAndDeliveryTracker = (brand: Brand) => {
  const selectedStoreAndSlotData = useQuery(GetTrackingSelectedStoreDataDocument)

  const storeData = useQuery(RemoteGetTrackingSelectedStoreDataDocument, {
    variables: {
      storeId: selectedStoreAndSlotData.data?.domainOrder.storeId || '',
    },
    skip: !selectedStoreAndSlotData.data?.domainOrder.storeId,
  })

  const { id: storeId = null, name: storeName = null } = storeData.data?.store ?? {}

  const isDeliverySlotReservationEnabled = useIsDeliverySlotReservationEnabled()
  const remoteReservation = useDeliverySlotReservation()
  const selectedDeliverySlot = useSelectedDeliverySlot()

  const hasReservation = remoteReservation.type === 'SUCCESS'

  const reservationId =
    (isDeliverySlotReservationEnabled && hasReservation && remoteReservation.data.reservationId) ||
    null

  const deprecatedDeliverySlotId = selectedStoreAndSlotData.data?.domainOrder.deliverySlotId || ''

  /** This can be skipped when using slot reservations, since that already includes data */
  const slotData = useQuery(RemoteGetTrackingSelectedDeliverySlotDataDocument, {
    variables: { deliverySlotId: deprecatedDeliverySlotId },
    skip: isDeliverySlotReservationEnabled || !deprecatedDeliverySlotId,
  })

  const deliveryEndDateTime =
    (isDeliverySlotReservationEnabled
      ? selectedDeliverySlot.type === 'SUCCESS' && selectedDeliverySlot.data.endDateTime
      : slotData.data?.deliverySlot?.endDateTime) || null

  const deliveryMethod =
    (isDeliverySlotReservationEnabled
      ? selectedDeliverySlot.type === 'SUCCESS' && selectedDeliverySlot.data.deliveryMethod
      : slotData.data?.deliverySlot?.deliveryMethod) || null

  const deliveryStartDateTime =
    (isDeliverySlotReservationEnabled
      ? selectedDeliverySlot.type === 'SUCCESS' && selectedDeliverySlot.data.startDateTime
      : slotData.data?.deliverySlot?.startDateTime) || null

  useEffect(() => {
    trackStoreAndDeliveryData({
      deliveryEndDateTime,
      deliveryMethod,
      deliveryStartDateTime,
      reservationId,
      siteBrand: brand || null,
      storeId,
      storeName,
    })
  }, [
    brand,
    deliveryEndDateTime,
    deliveryMethod,
    deliveryStartDateTime,
    reservationId,
    storeId,
    storeName,
  ])

  return null
}

const trackStoreAndDeliveryData = ({
  deliveryEndDateTime,
  deliveryMethod,
  deliveryStartDateTime,
  reservationId,
  siteBrand,
  storeId,
  storeName,
}: StoreAndDeliveryTrackingData['customDimensions']): void =>
  dataLayer({
    event: 'pushData',
    customDimensions: {
      storeId,
      storeName,
      siteBrand,
      deliveryMethod,
      reservationId,
      deliveryStartDateTime,
      deliveryEndDateTime,
    },
  })
