import type { FC, ReactNode } from 'react'

import { Col, Row } from '@sok/design-system'
import { useTranslation } from 'react-i18next'

interface Props {
  title?: string
  description?: string
  children?: ReactNode
}

export const ContentError: FC<Props> = ({ children, title, description }) => {
  const { t } = useTranslation()

  return (
    <div className={'m--xlarge-bottom'}>
      <Row justify={'center'} data-test-id="content-error-image">
        <Col>
          <img style={{ width: '184px' }} alt="errorimage" src="/icons/error-logo.svg" />
        </Col>
      </Row>
      <Row justify={'center'} data-test-id="content-error-title">
        <Col>
          <h3 className={'m--large-top m--medium-bottom'}>{title ? title : t('ErrorHttp500')}</h3>
        </Col>
      </Row>
      <Row justify={'center'} data-test-id="content-error-description">
        <Col>
          <p className={'error__subtext'}>{description ? description : t('ErrorHttp500Subtext')}</p>
        </Col>
      </Row>
      <div data-test-id="error-messages">{children}</div>
    </div>
  )
}
