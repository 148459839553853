import type { PaymentDetailsResponse } from '@shared/gql/document-nodes'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  ClearAuthenticationTokensDocument,
  GetCustomerDetailsDocument,
  GetPaymentDetailsDocument,
} from '@shared/gql/document-nodes'
import { refreshTokenExpired } from 'lib/apollo/reactive-vars'
import { useCallback } from 'react'
import { emptyCustomerDetails, emptyPaymentDetails } from 'services/App/empty-initial-states'
import { DaoService } from 'services/LocalStorage'
import { updatePaymentDetails } from 'services/Payment/mutations/update-payment-details'

export const useExpireSession = () => {
  const apolloClient = useApolloClient()
  const { data: paymentDetailsData } = useQuery(GetPaymentDetailsDocument)
  const [clearAuthenticationTokens] = useMutation(ClearAuthenticationTokensDocument)

  return useCallback(async () => {
    await clearAuthenticationTokens()
    refreshTokenExpired(false)
    DaoService.CustomerDAO.reset()
    apolloClient.cache.writeQuery({
      query: GetCustomerDetailsDocument,
      data: {
        customerDetails: emptyCustomerDetails(),
        __typename: 'Query',
      },
    })

    // Update payment details so that saved payment card id is cleared
    // but persist payment method since we keep the store data and store selection does not nessessary set default payment method
    const paymentDetailsParams: PaymentDetailsResponse = {
      ...emptyPaymentDetails(),
      paymentMethod: paymentDetailsData?.paymentDetails?.paymentMethod || null,
    }

    updatePaymentDetails({
      paymentDetailsParams,
      apolloClient,
    })
  }, [apolloClient, clearAuthenticationTokens, paymentDetailsData?.paymentDetails?.paymentMethod])
}
