import { useMutation } from '@apollo/client'
import { getConfig } from '@shared/config'
import { UpdateAuthenticationRedirectPathDocument } from '@shared/gql/document-nodes'
import { useRouter } from 'next/router'

import { useGetCachedAuthorizationTokens } from './use-get-cached-authorization-tokens'

const { auth, stage } = getConfig()

export const useGetProfileActionHandlers = () => {
  const router = useRouter()
  const isLocal = stage === 'dev' || stage === 'preview'
  const { idToken } = useGetCachedAuthorizationTokens() || {}
  const [updateAuthRedirectPath] = useMutation(UpdateAuthenticationRedirectPathDocument)

  const onOrderHistoryClick = (): void => {
    router.push('/tilaus/historia', '/tilaus/historia', { shallow: true })
  }

  const onLogoutClick = (): void => {
    const sIdLogoutUrl = `${auth.authorizationUrl}/logout?idToken=${idToken}${
      isLocal ? '&local=true' : ''
    }`
    window.location.assign(sIdLogoutUrl)
  }

  const onLoginClick = (): void => {
    // We need to keep track of the path where authentication was initiated in order
    // to redirect user back to the same page after login.
    updateAuthRedirectPath({ variables: { authRedirectPath: router.asPath } })

    const sIdLoginUrl = `${auth.authorizationUrl}/login${isLocal ? '?local=true' : ''}`
    window.location.assign(sIdLoginUrl)
  }

  const onRegisterClick = (): void => {
    // We need to keep track of the path where registration was initiated in order
    // to redirect user back to the same page after registration.
    updateAuthRedirectPath({ variables: { authRedirectPath: router.asPath } })

    const sIdRegisterUrl = `${auth.authorizationUrl}/login?type=register${
      isLocal ? '&local=true' : ''
    }`
    window.location.assign(sIdRegisterUrl)
  }

  const onProfileEditClick = (): void => {
    const sIdSsoPage = auth.sIdProfilePageUrl
    if (window) {
      window.open(sIdSsoPage, '_blank')
    }
  }

  const onAddBonusCardClick = (): void => {
    const currentUrlBase64 = btoa(window.location.href)
    const addBonusCardUrl = `${auth.addBonusCardUrl}?ui_locales=fi&return_url=${currentUrlBase64}`
    window.location.assign(addBonusCardUrl)
  }

  return {
    onAddBonusCardClick,
    onOrderHistoryClick,
    onLogoutClick,
    onProfileEditClick,
    onLoginClick,
    onRegisterClick,
  }
}
