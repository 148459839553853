import type { FC } from 'react'

import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useEvictSearchCache } from '../hooks/use-evict-search-cache'

const isSearchPage = (asPath: string) =>
  asPath.startsWith('/hakutulokset') || asPath.startsWith('/tuotteet')

/**
 * Listen to Next.js router events and evict `Store.products` from the Apollo cache
 * when leaving Search. This is so that we can cache search results during the browser
 * Back/Forward buttons and properly restore scroll position, but still make sure
 * search queries hit the server when doing a new search
 * (even for the same query as before).
 *
 * This has to be a component instead of a hook because `useStoreId` depends on the
 * `useTheme` hook, and so this has to be rendered inside the `ThemeProvider`.
 */
export const SearchCacheEvictor: FC = () => {
  const router = useRouter()
  const evictSearchCache = useEvictSearchCache()

  useEffect(() => {
    let isNavigation = true

    router.beforePopState(() => {
      /** Do not evict search cache when navigating Back/Forward */
      isNavigation = false
      return true
    })

    const handleHistoryChange = (newAsPath: string) => {
      const wasOnSearchPage = isSearchPage(router.asPath)
      const willBeOnSearchPage = isSearchPage(newAsPath)

      /**
       * When navigating from non-search page to a search page,
       * evict cache to force network request.
       */
      if (isNavigation && !wasOnSearchPage && willBeOnSearchPage) {
        evictSearchCache()
      }

      isNavigation = true
    }

    router.events.on('beforeHistoryChange', handleHistoryChange)

    return () => {
      router.events.off('beforeHistoryChange', handleHistoryChange)
      router.beforePopState(() => true)
    }
  }, [evictSearchCache, router])

  return null
}
