import type { FC } from 'react'

import { getConfig } from '@shared/config'
import { useState } from 'react'
import { DeliverySelectionStage } from 'types/global'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'
import { useGetCartAlcoholItems } from 'utils/hooks/cart'
import { useAlcoholAllowedForDeliveryArea } from 'utils/hooks/delivery/use-alcohol-allowed'
import { useSelectedDeliveryAreaDetails } from 'utils/hooks/use-selected-delivery-area-name'

import { LoginModal } from './LoginModal'
import { shouldSkipDeliveryLogin } from './skip-delivery-login'
import { DeliveryTimeSelectionContainer } from './time/DeliveryTimeSelectionContainer'
import { TimeAlcoholConflict } from './time-alcohol-conflict/TimeAlcoholConflict'

const { featureFlags } = getConfig()

type DeliveryStage = Exclude<DeliverySelectionStage, DeliverySelectionStage.SELECT_METHOD_AND_STORE>

export const DeliverySelectedContent: FC<{ stage: DeliveryStage }> = ({ stage }) => {
  const alcoholSellingAllowed = useAlcoholAllowedForDeliveryArea()
  const alcoholItems = useGetCartAlcoholItems()
  // If delivery area disallows alcohol selling, then timeslot-specific alcohol checks are disabled
  const cartContainsAlcohol = alcoholItems.length > 0 && !!alcoholSellingAllowed
  const loginModalHidden = shouldSkipDeliveryLogin()
  const isLoggedIn = useIsLoggedIn()
  const [isOpen, setIsOpen] = useState<boolean>(!loginModalHidden)
  const { dynamicPricing } = useSelectedDeliveryAreaDetails()
  const openWithFeatureFlag =
    !isLoggedIn &&
    isOpen &&
    featureFlags.slotReservationWithLogin &&
    (dynamicPricing || featureFlags.slotReservationInAllStoresForLoggedInUsers)

  if (openWithFeatureFlag) {
    return <LoginModal setIsOpen={setIsOpen} />
  } else {
    switch (stage) {
      case DeliverySelectionStage.SELECT_DELIVERY_TIME: {
        return <DeliveryTimeSelectionContainer cartContainsAlcohol={cartContainsAlcohol} />
      }
      case DeliverySelectionStage.SELECT_DELIVERY_TIME_RESERVATION_ERROR: {
        return (
          <DeliveryTimeSelectionContainer
            cartContainsAlcohol={cartContainsAlcohol}
            reservationError
          />
        )
      }
      case DeliverySelectionStage.SELECT_DELIVERY_TIME_ALCOHOL_CONFLICT: {
        return <TimeAlcoholConflict />
      }
    }
  }
}
