import type { Experiments } from './types'
import type { FC, ReactNode } from 'react'

import { useEffect, useState } from 'react'
import { pushData } from 'utils/tracking/custom-events/custom-events'

import { getPersistedUserExperiments } from './cache'
import { ExperimentContext } from './context'
import { useHasExperimentConsent } from './use-experiment-variant'

export const ExperimentProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [experiments, setExperiments] = useState<Experiments | null>(null)
  const hasServiceConsent = useHasExperimentConsent()

  useEffect(() => {
    if (hasServiceConsent) {
      const cookieExperiments = getPersistedUserExperiments(document.cookie)
      setExperiments(cookieExperiments)
    }
  }, [hasServiceConsent])

  useEffect(() => {
    if (experiments && hasServiceConsent) {
      pushData({ experiments })
    }
  }, [experiments, hasServiceConsent])

  return (
    <ExperimentContext.Provider value={{ experiments, setExperiments }}>
      {children}
    </ExperimentContext.Provider>
  )
}
