import type { OrderEditActiveResponse } from '@shared/gql/document-nodes'
import type { MutationResolvers } from 'types/gql/type-defs'

import {
  DeliveryMethod,
  GetCustomerDetailsDocument,
  GetDeliveryDetailsInfoDocument,
  GetPaymentDetailsDocument,
  IsOrderEditActiveDocument,
} from '@shared/gql/document-nodes'
import { defaultTo } from 'ramda'
import {
  emptyCustomerDetails,
  emptyDeliveryDetailsInfo,
  emptyOrderEditActive,
  emptyPaymentDetails,
} from 'services/App/empty-initial-states'
import { clearCart } from 'services/ClientCart/mutations/clear-cart'
import { DaoService } from 'services/LocalStorage'

export const startOrderEdit: MutationResolvers['startOrderEdit'] = (_, args, { cache }) => {
  const startEdit: OrderEditActiveResponse = {
    __typename: 'OrderEditActiveResponse' as const,
    orderId: args.orderId ?? null,
    orderStatus: args.orderStatus ?? null,
  }

  cache.writeQuery({
    query: IsOrderEditActiveDocument,
    data: {
      __typename: 'Query',
      orderEditActive: startEdit,
    },
  })

  DaoService.CartDAO.saveOrderEdit(startEdit)

  return null
}

export const clearCartAndOrderDetails: MutationResolvers['clearCartAndOrderDetails'] = (
  _,
  args,
  { cache },
) => {
  clearCart()
  DaoService.CartDAO.reset()

  // DeliveryMethod needs to remain the same after order, otherwise the areaId is wrong and we'll need to
  // have it to remain in the same store and delivery modal to work (VOIK-5563)

  const deliveryData = DaoService.DeliveryDAO.loadDeliveryData()?.deliveryData.deliveryDetailsInfo
  const deliveryMethod = defaultTo(DeliveryMethod.Pickup, deliveryData?.deliveryMethod)

  const postalCode = deliveryData?.postalCode || ''

  // Quick fix: VOIK-5645 Address information needs to be filled every time
  // to help out most of the customers before we have the actual fix
  // VOIK-5064 - use CAS adress, which includes ability to add multiple addresses

  const additionalInfo = deliveryData?.additionalInfo || ''
  const city = deliveryData?.city || ''
  const address = deliveryData?.address || ''

  const persistAddress = DeliveryMethod.HomeDelivery && args.isLoggedIn

  DaoService.DeliveryDAO.saveDeliveryDetails({
    ...emptyDeliveryDetailsInfo(),
    deliveryMethod,
    postalCode,
    ...(persistAddress && {
      additionalInfo,
      city,
      address,
    }),
  })

  DaoService.CustomerDAO.reset()

  // keep selected payment method
  const paymentData = {
    ...emptyPaymentDetails(),
    ...(args.isLoggedIn && {
      selectedPaymentCardId:
        DaoService.PaymentDAO.loadPaymentData()?.paymentData.paymentDetails
          ?.selectedPaymentCardId || null,
      stockmannCardNumber:
        DaoService.PaymentDAO.loadPaymentData()?.paymentData.paymentDetails?.stockmannCardNumber ||
        null,
      invoiceNumber:
        DaoService.PaymentDAO.loadPaymentData()?.paymentData.paymentDetails?.invoiceNumber || null,
    }),
    paymentMethod:
      DaoService.PaymentDAO.loadPaymentData()?.paymentData.paymentDetails?.paymentMethod || null,
  }
  DaoService.PaymentDAO.savePaymentData(paymentData)
  cache.writeQuery({
    query: GetPaymentDetailsDocument,
    data: { paymentDetails: paymentData, __typename: 'Query' },
  })

  cache.writeQuery({
    query: GetCustomerDetailsDocument,
    data: { __typename: 'Query', customerDetails: emptyCustomerDetails() },
  })

  cache.writeQuery({
    query: GetDeliveryDetailsInfoDocument,
    data: {
      deliveryDetailsInfo: {
        ...emptyDeliveryDetailsInfo(),
        deliveryMethod,
        postalCode,
        ...(persistAddress && {
          additionalInfo,
          city,
          address,
        }),
      },
      __typename: 'Query',
    },
  })

  cache.writeQuery({
    query: IsOrderEditActiveDocument,
    data: { orderEditActive: emptyOrderEditActive(), __typename: 'Query' },
  })

  return null
}

export const stopOrderEdit: MutationResolvers['stopOrderEdit'] = (_, _args, { cache }) => {
  const empty: OrderEditActiveResponse = {
    orderId: '',
    orderStatus: null,
    __typename: 'OrderEditActiveResponse',
  }

  cache.writeQuery({
    query: IsOrderEditActiveDocument,
    data: { orderEditActive: empty, __typename: 'Query' },
  })

  DaoService.CartDAO.saveOrderEdit(empty)

  return null
}
