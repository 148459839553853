import type { IconName } from './icon-name'
import type { FC } from 'react'

import { CalendarOutline24 } from '@sok/design-system'
import styled from 'styled-components'

import {
  AlcoholNotAvailableFilledIcon,
  AlcoholNotAvailableIcon,
  BoxOutlineIcon,
  Card,
  Comment,
  Compose,
  FastTrackPickup,
  FastTrackTime,
  InfoIcon,
  Location,
  MinusIcon,
  OpeningHours,
  PlusIcon,
  RecommendationsIcon,
  SBonusCard,
  SBonusLogo,
  ServiceFee,
  ShoppingCart,
} from './icons'
import { FavoritesNavIcon } from './navigation'

const DisplayIcon: FC<{ name: IconName }> = (props) => {
  let _: never

  switch (props.name) {
    case 'box-outline':
      return <BoxOutlineIcon />
    case 'fast-track-time':
      return <FastTrackTime />
    case 'fast-track-pickup':
      return <FastTrackPickup />
    case 'opening-hours':
      return <OpeningHours />
    case 'shopping-cart':
      return <ShoppingCart />
    case 'calendar':
      return <CalendarOutline24 />
    case 'compose':
      return <Compose />
    case 'card':
      return <Card />
    case 'comment':
      return <Comment />
    case 'favorites':
      return <FavoritesNavIcon />
    case 'service-fee':
      return <ServiceFee />
    case 'plus-icon':
      return <PlusIcon />
    case 'minus-icon':
      return <MinusIcon />
    case 'location':
      return <Location />
    case 's-bonus-logo':
      return <SBonusLogo />
    case 's-bonus-card':
      return <SBonusCard />
    case 'recommendations':
      return <RecommendationsIcon />
    case 'alcohol-not-available':
      return <AlcoholNotAvailableIcon />
    case 'alcohol-not-available-filled':
      return <AlcoholNotAvailableFilledIcon />
    case 'info':
      return <InfoIcon />
    default:
      _ = props.name
      return _
  }
}

interface IconProps {
  className?: string
  fillColor?: string
  height?: number
  margin?: string
  name: IconName
  strokeColor?: string
  width: number
}

const _Icon: FC<IconProps> = ({ className, name }) => (
  <div className={className} data-test-id="icon">
    <DisplayIcon name={name} />
  </div>
)

_Icon.displayName = 'Icon'

export const Icon = styled(_Icon)(({ margin, height, width, fillColor, strokeColor }) => ({
  lineHeight: 1,
  margin,
  maxHeight: height,

  svg: {
    height,
    width,

    path: {
      fill: fillColor,
      stroke: strokeColor,
    },
  },
}))
