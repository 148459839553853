import type { SearchDeliveryAreasQueryVariables } from '@shared/gql/document-nodes'

import { useLazyQuery } from '@apollo/client'
import { DeliveryMethod, SearchDeliveryAreasDocument } from '@shared/gql/document-nodes'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useStoreChange } from 'utils/hooks/store/use-store-change'
import { useURLSearchParams } from 'utils/hooks/use-url-search-params'

const isStoreId = (storeId: string): boolean => /^[0-9]{9}$/.test(storeId)

export const useSelectStoreFromUrlParams = (): void => {
  const router = useRouter()

  const onStoreChange = useStoreChange()
  const { removeParams } = useURLSearchParams({ push: false })
  const [searchAreas] = useLazyQuery(SearchDeliveryAreasDocument, {
    onCompleted: async (areasData) => {
      const area = areasData?.searchDeliveryAreas?.areas?.[0]
      if (area && area.store && area.areaId) {
        const store = {
          id: area.store.id,
          name: area.store.name,
          brand: area.store.brand,
        }
        await onStoreChange({
          selectedStore: store,
          areaId: area.areaId,
          postalCode: '',
          city: null,
        })
      }

      removeParams(['storeId', undefined], ['deliveryAreaSearch', undefined])
    },
  })

  useEffect(() => {
    const storeIDS = router?.query?.storeId
    const deliveryAreaSearch = router?.query?.deliveryAreaSearch

    if (!storeIDS) return

    const storeIdParam = Array.isArray(storeIDS) ? storeIDS[0] : storeIDS
    const deliveryAreaParam = Array.isArray(deliveryAreaSearch)
      ? deliveryAreaSearch[0]
      : deliveryAreaSearch

    if (isStoreId(storeIdParam)) {
      searchAreas({
        variables: {
          deliveryMethod: DeliveryMethod.Pickup,
          freetext: deliveryAreaParam || '',
          storeId: storeIdParam,
        } as SearchDeliveryAreasQueryVariables,
      })
    }
  }, [router?.query?.deliveryAreaSearch, router?.query?.storeId, searchAreas])
}
