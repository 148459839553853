import type { FC } from 'react'

import { Button } from '@s-group/design-system-components'
import { minWidthFromTheme } from '@sok/design-system'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRemoveCartItems } from 'services/ClientCart/mutations/use-remove-cart-items'
import { closeDeliveryModal, setDeliveryModalStage } from 'services/Delivery/cache'
import { useSelectedDeliverySlot } from 'services/DeliverySlot/hooks'
import styled, { useTheme } from 'styled-components'
import { DeliverySelectionStage } from 'types/global'
import { useGetCartAlcoholItems } from 'utils/hooks/cart'
import {
  trackAlcoholConflictSeen,
  trackAlcoholNewTimeClicked,
  trackAlcoholRemovedClicked,
} from 'utils/tracking/alcohol'

interface TimeAlcoholConflictProps {
  className?: string
}

const TimeAlcoholConflictComponent: FC<TimeAlcoholConflictProps> = ({ className }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const alcoholItems = useGetCartAlcoholItems()
  const removeCartItems = useRemoveCartItems()

  useEffect(() => {
    trackAlcoholConflictSeen()
  }, [])

  const selectedSlot = useSelectedDeliverySlot()

  const time = (selectedSlot.type === 'SUCCESS' && selectedSlot.data.time) || ''

  return (
    <div className={className}>
      <img alt="" role="presentation" src={theme.logos.calendarError} width="128" height="128" />
      <h1>{t('Alcohol not available between', { time })}</h1>
      <StyledText>{t('Select pickup time within selling hours')}</StyledText>
      <StyledButtonContainer>
        <Button
          primary
          rounding="small"
          data-test-id="select-pickup-time"
          onClick={() => {
            setDeliveryModalStage(DeliverySelectionStage.SELECT_DELIVERY_TIME)
            trackAlcoholNewTimeClicked()
          }}
        >
          {t('Select new pickup time')}
        </Button>
        <Button
          data-test-id="remove-alcohol-items"
          onClick={async () => {
            await removeCartItems(alcoholItems)
            closeDeliveryModal()
            trackAlcoholRemovedClicked(alcoholItems)
          }}
        >
          {t('Remove alcohol items')}
        </Button>
      </StyledButtonContainer>
    </div>
  )
}

TimeAlcoholConflictComponent.displayName = 'TimeAlcoholConflict'

export const TimeAlcoholConflict = styled(TimeAlcoholConflictComponent)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 6.4rem 0rem;

  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    padding: 6.4rem 6rem;
  }

  h1 {
    font-size: 1.36rem;
    font-weight: normal;
  }
`

const StyledText = styled.p`
  padding: 1rem 0 3rem 0;
`
const StyledButtonContainer = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  [Button]: {
    marginBottom: '1rem',
  },
})
