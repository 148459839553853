import type { ErrorLogEntry, LogEntry, Logger } from './types'
import type { SeverityLevel } from '@sentry/nextjs'

import { RewriteFrames } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'
import { getConfig } from '@shared/config'
import { omit } from 'ramda'

import { maskAuthTokens } from './mask-auth-tokens'
import { maskKeys } from './mask-keys'
import { maskObjectValues } from './mask-object-values'
import { maskUuids } from './mask-uuids'
import { PERSONALLY_IDENTIFIABLE_FIELDS } from './personally-identifiable-fields'

const { buildId, sentry, stage } = getConfig()

const SENTRY_TRACES_SAMPLE_RATE = 0.01

// NEXT_IS_SERVER is defined during build process in next.config.js
const isServerSideLogger = process.env.NEXT_IS_SERVER === 'true'

/**
 * Documentation on how Sentry works with Next.js
 * https://github.com/vercel/next.js/tree/canary/examples/with-sentry
 */

const maskers = [maskKeys(PERSONALLY_IDENTIFIABLE_FIELDS), maskUuids, maskAuthTokens]

interface LogMessage {
  severity: SeverityLevel
  logEntry: LogEntry
}

const logMessage = ({ severity, logEntry }: LogMessage): void => {
  Sentry.captureMessage(logEntry.message, {
    level: severity,
    extra: omit(['message'], logEntry),
    tags: logEntry.tags,
  })
}

const logError = ({ message, data, error }: ErrorLogEntry): void => {
  Sentry.captureException(error, {
    extra: {
      message,
      data,
    },
  })
}

const integrations = isServerSideLogger
  ? [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename?.replace('/var/task/', 'app:///')
          frame.filename = frame.filename?.replace('.next/serverless', '_next')
          return frame
        },
      }),
    ]
  : []

export const initSentryLogger = () => {
  Sentry.init({
    environment: `skaupat-${stage}`,
    dsn: sentry.publicDsn,
    integrations,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    release: buildId,
    beforeSend: (event: Sentry.Event): Sentry.Event => {
      return maskObjectValues(event, maskers)
    },
    enabled: sentry.enabled,
    normalizeDepth: 10,
  })
}

Sentry.setTag('service', 'webstore')

export const sentryLogger: Logger = {
  debug: async (logEntry: LogEntry): Promise<void> => {
    logMessage({ severity: 'debug', logEntry })
    await Sentry.flush(500)
  },
  debugSync: (logEntry: LogEntry): void => {
    logMessage({ severity: 'debug', logEntry })
  },
  info: async (logEntry: LogEntry): Promise<void> => {
    logMessage({ severity: 'info', logEntry })
    await Sentry.flush(500)
  },
  infoSync: (logEntry: LogEntry): void => {
    logMessage({ severity: 'info', logEntry })
  },
  warn: async (logEntry: LogEntry): Promise<void> => {
    logMessage({ severity: 'warning', logEntry })
    await Sentry.flush(500)
  },
  warnSync: (logEntry: LogEntry): void => {
    logMessage({ severity: 'warning', logEntry })
  },
  error: async (logEntry: ErrorLogEntry): Promise<void> => {
    logError(logEntry)
    await Sentry.flush(500)
  },
  errorSync: (logEntry: ErrorLogEntry): void => {
    logError(logEntry)
  },
}
