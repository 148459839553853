import type { Maybe } from '@shared/gql/document-nodes'

import { EuroCents } from '@shared/domain/euro-cents'

import { sum } from './currency'

interface ItemTotalPriceProps {
  itemCount?: number | null | string
  price?: Maybe<number>
}

export const getCartItemTotal = ({ itemCount, price }: ItemTotalPriceProps): EuroCents => {
  if (!price) {
    return EuroCents.zero()
  }

  return EuroCents.fromEuros(price).multiply(Number(itemCount || 1))
}

export const getCartItemsTotal = (cartItems: ItemTotalPriceProps[]): EuroCents =>
  sum(cartItems.map(getCartItemTotal))
