import type { DeliveryAreaAddress } from '@shared/gql/document-nodes'
import type { DeliveryOption, DeliveryOptionStore } from 'domain/delivery-option'
import type { FC } from 'react'

import { useQuery } from '@apollo/client'
import {
  AlcoholSellingStatus,
  DeliveryMethod,
  LocalDomainOrderCartItemsDocument,
} from '@shared/gql/document-nodes'
import { useCallback, useEffect, useRef } from 'react'
import { commonLogger } from 'utils/log/common-logger'
import { containsAlcoholProducts } from 'views/delivery/support/filter-alcohol-products'

import { DeliveryOptionInfoCard } from './DeliveryOptionInfoCard'
import { DeliveryOptionsLoading } from './DeliveryOptionsLoading'

const getTitle = (deliveryOption: {
  name: string
  deliveryMethod: DeliveryMethod
  store: DeliveryOptionStore
}): string => {
  const { store } = deliveryOption
  switch (deliveryOption.deliveryMethod) {
    case DeliveryMethod.Pickup:
      return deliveryOption.name
    case DeliveryMethod.HomeDelivery:
      return store.name
  }
}

const getPickUpPointLocationDescription = (address: DeliveryAreaAddress | undefined): string =>
  address ? `${address.street}, ${address.postalCode} ${address.city}` : ''

interface DeliveryOptionsListProps {
  deliveryOptions: DeliveryOption[]
  isDeliveryOptionsLoading: boolean
  onSelectDeliveryOption: (deliveryOption: DeliveryOption) => void
  initiallySelectedDeliveryOption: DeliveryOption | null
  selectedDeliveryOption: DeliveryOption | null
}

export const DeliveryOptionsList: FC<DeliveryOptionsListProps> = ({
  deliveryOptions,
  isDeliveryOptionsLoading,
  onSelectDeliveryOption,
  initiallySelectedDeliveryOption,
  selectedDeliveryOption,
}) => {
  const selectedDeliveryOptionRef = useRef<HTMLLabelElement>(null)

  const cartItemsQuery = useQuery(LocalDomainOrderCartItemsDocument)
  const cartContainsAlcohol = containsAlcoholProducts(
    cartItemsQuery.data?.domainOrder.cartItems ?? [],
  )

  useEffect(() => {
    if (
      initiallySelectedDeliveryOption &&
      selectedDeliveryOption?.areaId === initiallySelectedDeliveryOption.areaId &&
      selectedDeliveryOptionRef.current
    ) {
      selectedDeliveryOptionRef.current.scrollIntoView({
        block: 'center',
        behavior: 'auto',
      })
    }
  }, [initiallySelectedDeliveryOption, selectedDeliveryOption])

  const handleSelectDeliveryOption = useCallback(
    (selectedDeliveryOptionId: string) => {
      const selectedOption = deliveryOptions.find(
        (deliveryOption) => deliveryOption.areaId === selectedDeliveryOptionId,
      )

      if (selectedOption) {
        onSelectDeliveryOption(selectedOption)
      } else {
        commonLogger.errorSync({
          error: Error('Selected delivery option was not found. This should not happen'),
          data: { selectedDeliveryOptionId, deliveryOptions },
        })
      }
    },
    [deliveryOptions, onSelectDeliveryOption],
  )

  if (isDeliveryOptionsLoading) {
    return <DeliveryOptionsLoading />
  }

  return (
    <>
      {deliveryOptions.map((deliveryOption) => {
        const isSelected =
          Boolean(selectedDeliveryOption) &&
          selectedDeliveryOption?.areaId === deliveryOption.areaId
        const deliveryLocation =
          deliveryOption.deliveryMethod === DeliveryMethod.Pickup
            ? getPickUpPointLocationDescription(deliveryOption.address)
            : undefined

        const alcoholSellingNotAllowed =
          cartContainsAlcohol &&
          deliveryOption.alcoholSellingAllowed === AlcoholSellingStatus.NotAllowed

        return (
          <DeliveryOptionInfoCard
            key={deliveryOption.areaId}
            deliveryOption={deliveryOption}
            alcoholSellingNotAllowed={alcoholSellingNotAllowed}
            brand={deliveryOption.store.brand}
            deliveryLocation={deliveryLocation}
            distance={deliveryOption.location?.distance}
            isSelected={isSelected}
            onSelect={handleSelectDeliveryOption}
            selectedDeliveryOptionRef={
              initiallySelectedDeliveryOption &&
              initiallySelectedDeliveryOption?.areaId === deliveryOption.areaId
                ? selectedDeliveryOptionRef
                : null
            }
            title={getTitle(deliveryOption)}
          />
        )
      })}
    </>
  )
}
