import type { QueryFieldPolicy, TypedTypePolicies } from 'types/gql/apollo-helpers'

export const deliverySlotReservation: NonNullable<QueryFieldPolicy['deliverySlotReservation']> = {
  keyArgs: ['reservationId'],
}

export const DeliverySlotReservationResponse: NonNullable<
  TypedTypePolicies['DeliverySlotReservationResponse']
> = {
  keyFields: ['reservationId'],
}
