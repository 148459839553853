import type { DurationLike } from 'luxon'

import { getConfig } from '@shared/config'
import { DateTime, Duration } from 'luxon'

const { domain } = getConfig()

/** Get the current`DateTime.now()` in the application timezone. */
export const getDateTimeNow = (locale = domain.defaultLocale): DateTime =>
  DateTime.fromObject({}, { locale, zone: domain.localTimezone })

/** Convert input ISO String to Luxon DateTime, and convert to application timezone. */
export const toDateTime = (input: string, locale = domain.defaultLocale): DateTime =>
  DateTime.fromISO(input, { zone: domain.localTimezone, locale })

/** Returns new Date instance from input, or from "now" when input is missing */
const _toDate = (input?: string): Date => (input ? new Date(input) : new Date(Date.now()))

/**
 * Returns the number of calendar days between the input
 * ISO timestamps `from`...`to`, ignoring the time.
 * If `from` is omitted, it will default to "today".
 *
 * @example <caption>Same calendar day, different times</caption>
 * diffInDays('2022-10-05T02:00', '2022-10-05T01:00') // 0
 * @example <caption>Different calendar days</caption>
 * diffInDays('2022-10-06', '2022-10-05') // 1
 * @example <caption>From is in the past</caption>
 * diffInDays('2022-10-04', '2022-10-05') // -1
 * @example <caption>Missing from, defaults to "today"</caption>
 * diffInDays('2022-10-07') // 2, when today is 2022-10-05
 */
export const getDiffInDays = (to: string, from?: string): number => {
  const toStartOfDay = toDateTime(to).startOf('day')
  const fromStartOfDay = (from ? toDateTime(from) : getDateTimeNow()).startOf('day')
  return toStartOfDay.diff(fromStartOfDay, 'days').days
}

type DateTimeFormatter<T extends object = object, R = string> = (
  input: string,
  options?: { locale?: string } & T,
) => R

/**
 * Format input as hours and minutes, with optional locale defaulting to 'fi'
 *
 * @example toFormattedTime('2022-08-03T21:00') // '21.00'
 * @example toFormattedTime('2022-08-03T21:00', { locale: 'en' }) // '9.00 PM'
 */
export const toFormattedTime: DateTimeFormatter = (input, options) => {
  const locale = options?.locale ?? domain.defaultLocale
  return new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: '2-digit',
    timeZone: domain.localTimezone,
  }).format(_toDate(input))
}

/**
 * Format duration-like input as "x minutes" or "y hours", with optional locale defaulting to 'fi'
 */
export const toFormattedDuration = (input: DurationLike, options?: { locale?: string }) => {
  const duration = Duration.fromDurationLike(input).reconfigure({ locale: options?.locale || 'fi' })
  const durationInMinutes = duration.shiftTo('minutes')

  if (durationInMinutes.minutes < 60) {
    return duration.toHuman()
  }

  const hours = duration.shiftTo('hours').hours
  const roundedHours = Math.ceil(2 * hours) / 2 /** round to next 0,5 */
  return durationInMinutes.set({ hours: roundedHours, minutes: 0 }).shiftTo('hours').toHuman()
}

/**
 * Format input as weekday, with optional format and locale defaulting to 'fi'
 *
 * @example toFormattedWeekday('2022-08-03T21:00') // 'keskiviikko'
 * @example toFormattedWeekday('2022-08-03T21:00', { format: 'short' }) // 'ke'
 * @example toFormattedWeekday('2022-08-03T21:00', { locale: 'sv' }) // 'onsdag'
 */
export const toFormattedWeekday: DateTimeFormatter<{
  format?: Intl.DateTimeFormatOptions['weekday']
}> = (input, options) => {
  const locale = options?.locale ?? domain.defaultLocale
  return new Intl.DateTimeFormat(locale, {
    weekday: options?.format ?? 'long',
    timeZone: domain.localTimezone,
  }).format(_toDate(input))
}

/**
 * Format input as relative "today" or "tomorrow", and locale defaulting to 'fi'.
 * If input is not for "today/tomorrow/", null will be returned
 *
 * @example maybeRelativeWeekday(DateTime.now().toISO()) // 'tänään'
 * @example maybeRelativeWeekday(DateTime.now().add({ days: 1 }).toISO()) // 'huomenna'
 * @example maybeRelativeWeekday(DateTime.now().add({ days: 2 }).toISO()) // null
 */
export const toMaybeFormattedRelativeWeekday: DateTimeFormatter<
  { format?: Intl.DateTimeFormatOptions['weekday'] },
  string | null
> = (input, options) => {
  if (!('RelativeTimeFormat' in Intl)) return null

  const locale = options?.locale ?? domain.defaultLocale
  const diffInDays = getDiffInDays(input)

  switch (diffInDays) {
    case 1: // "tomorrow"
    case 0: // "today"
      return new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).format(diffInDays, 'days')
    default:
      return null
  }
}

/**
 * Format input as short weekday, day, and month, and year.
 * The Weekday and Year can be omitted with options.
 * The locale can be overridden with options.
 *
 * @example toFormattedDate('2022-08-03T21:00') // 'ke 3.8.'
 * @example toFormattedDate('2022-08-03T21:00', { year: false }) // 'ke 3.8.'
 * @example toFormattedDate('2022-08-03T21:00', { weekday: false }) // '3.8. 2022'
 * @example toFormattedDate('2022-08-03T21:00', { locale: 'sv' }) // 'ons 3.8.2022'
 */
export const toFormattedDate: DateTimeFormatter<{ year?: boolean; weekday?: boolean }> = (
  input,
  options,
) => {
  const locale = options?.locale ?? domain.defaultLocale
  return new Intl.DateTimeFormat(locale, {
    weekday: options?.weekday === false ? undefined : 'short',
    day: 'numeric',
    month: 'numeric',
    year: options?.year === false ? undefined : 'numeric',
    timeZone: domain.localTimezone,
  }).format(_toDate(input))
}
