import { CustomerType } from '@shared/gql/document-nodes'
import * as t from 'io-ts'

export const Customer = t.type({
  __typename: t.literal('DomainCustomer'),
  type: t.union([t.literal(CustomerType.B2b), t.literal(CustomerType.B2c)]),
  email: t.string,
  firstName: t.string,
  lastName: t.string,
  phone: t.string,
  companyName: t.union([t.null, t.string]),
  stockmannCardNumber: t.union([t.null, t.string]),
  cardType: t.union([t.null, t.string]),
})

export type Customer = t.TypeOf<typeof Customer>

const isCustomerType = (type: unknown): type is CustomerType =>
  typeof type === 'string' && Object.values(CustomerType).includes(type as CustomerType)

export const asCustomerType = (customerType: unknown) =>
  isCustomerType(customerType) ? customerType : CustomerType.B2c
