import type { Experiments } from './types'
import type { Dispatch, SetStateAction } from 'react'

import { createContext } from 'react'

export const ExperimentContext = createContext<{
  /**
   * `null` when not initialized.
   */
  experiments: Experiments | null
  setExperiments: Dispatch<SetStateAction<Experiments | null>>
}>({
  experiments: null,
  setExperiments: () => {},
})
