import { makeVar } from '@apollo/client'
import { IS_BROWSER } from 'utils/is-browser'

const RESERVATION_LOCALSTORAGE_KEY = 'delivery-slot-reservation-id'

const getReservationId = (): string | null => {
  if (IS_BROWSER) {
    try {
      const id = localStorage.getItem(RESERVATION_LOCALSTORAGE_KEY)
      if (typeof id === 'string') return id
    } catch (e) {
      // Silent failure.
    }
  }
  return null
}

export const deliverySlotReservationIdVar = makeVar(getReservationId())

/** Save delivery slot reservation id to localStorage */
export const saveReservationId = (reservationId: string): void => {
  if (IS_BROWSER) {
    deliverySlotReservationIdVar(reservationId)
    window.localStorage.setItem(RESERVATION_LOCALSTORAGE_KEY, reservationId)
  }
}

/** Clear delivery slot reservation id from localStorage */
export const clearReservationId = (): void => {
  if (IS_BROWSER) {
    deliverySlotReservationIdVar(null)
    window.localStorage.removeItem(RESERVATION_LOCALSTORAGE_KEY)
  }
}
