import {
  clearAuthenticationRedirectPath,
  clearAuthenticationTokens,
  updateAuthenticationRedirectPath,
  updateAuthenticationTokens,
} from 'services/Account/resolvers'
import {
  updateCustomerInfo,
  updateCustomerType,
  updatePersonalInfo,
} from 'services/Customer/resolvers'
import { updateDeliveryDetails } from 'services/Delivery/resolvers'
import { clearCartAndOrderDetails, startOrderEdit, stopOrderEdit } from 'services/Order/resolvers'
import { updatePaymentMethodDetails, updateStockmannCardNumber } from 'services/Payment/resolvers'
import {
  resetDeliveryStore,
  setDeliveryStore,
  updateSelectedAreaId,
  updateSelectedBrand,
  updateSelectedStoreId,
} from 'services/Store/resolvers'

export const resolvers = {
  Mutation: {
    clearAuthenticationRedirectPath,
    clearAuthenticationTokens,
    clearCartAndOrderDetails,
    resetDeliveryStore,
    setDeliveryStore,
    startOrderEdit,
    stopOrderEdit,
    updateAuthenticationRedirectPath,
    updateAuthenticationTokens,
    updateCustomerInfo,
    updateCustomerType,
    updateDeliveryDetails,
    updatePaymentMethodDetails,
    updatePersonalInfo,
    updateSelectedAreaId,
    updateSelectedBrand,
    updateSelectedStoreId,
    updateStockmannCardNumber,
  },
}
