import type { CalendarSlot } from './calendar'
import type { FC } from 'react'

import { IconCommerceAlcoholUnavailable } from '@s-group/design-system-icons'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

type SlotParams = {
  className?: string
  slot: CalendarSlot
  isSelected: boolean
  cartContainsAlcohol: boolean
  onSlotSelect: (slot: CalendarSlot) => void
}

const _Slot: FC<SlotParams> = ({
  className,
  slot,
  isSelected,
  cartContainsAlcohol,
  onSlotSelect,
}) => {
  const { t } = useTranslation()
  const showAlcoholWarning = cartContainsAlcohol && !slot.isAlcoholSellingAllowed
  // Playwright tests work from the div downwards. Button props for Cypress tests which will be deprecated.
  return (
    <div
      className={className}
      data-test-id="time-slot"
      data-is-selected={isSelected}
      data-is-alcohol-allowed={slot.isAlcoholSellingAllowed}
      data-is-modifiable={slot.isModifiable}
    >
      <button
        onClick={() => onSlotSelect(slot)}
        data-test-id="time-slot-available"
        data-date={slot.dateTime.toISODate()}
        disabled={isSelected}
        data-is-modifiable={slot.isModifiable}
        data-alcohol-allowed={slot.isAlcoholSellingAllowed}
      >
        <div data-test-id="time-slot-time">{slot.formattedStartAndEndTime}</div>
        <div data-test-id="time-slot-info">
          {showAlcoholWarning && (
            <IconCommerceAlcoholUnavailable data-test-id="alcohol-forbidden-icon" />
          )}
        </div>
        <div data-test-id="time-slot-price">{slot.formattedPrice}</div>
      </button>
      {showAlcoholWarning && isSelected && (
        <div data-test-id="alcohol-forbidden-disclaimer">
          <IconCommerceAlcoholUnavailable />
          <p>
            {t(
              'Alcoholic products can’t be ordered for selected pick up point. Change pick up point to order alcoholic products.',
            )}
          </p>
        </div>
      )}
    </div>
  )
}

export const StyledSlot = styled(_Slot)(
  ({ theme, isSelected }) => css`
    > div {
      width: calc(100% - 0.5rem);
      border-radius: 0.25rem;
      margin: ${theme.spacings.xxSmall} ${theme.spacings.xxxSmall};
      padding: ${theme.spacings.xxSmall};
      border: none;
      text-align: left;
      color: ${theme.colors.black};
      background-color: ${theme.colors.SDS_BRAND_COLOR_BACKGROUND_WEAKEST_GREY};
      display: flex;
      ${theme.sizes.xxs}
      span {
        align-items: top;
        justify-content: center;
        flex: 70px;
      }
    }

    > button {
      width: calc(100% - 0.5rem);
      border-radius: 0.25rem;
      margin: ${theme.spacings.xxxSmall} ${theme.spacings.xxxSmall};
      border: none;
      text-align: left;
      cursor: pointer;
      color: ${isSelected ? theme.colors.white : theme.colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY};
      background-color: ${isSelected
        ? theme.colors.SDS_BRAND_COLOR_BACKGROUND_STRONG_PRIMARY
        : theme.colors.SDS_BRAND_COLOR_BACKGROUND_WEAK_PRIMARY};
      display: flex;
      font: ${theme.fontWeights.medium} ${theme.sizes.xxs.fontSize} / 1.5rem 'S Bonus UX';
      min-height: 2rem;

      &:focus {
        color: ${isSelected
          ? theme.colors.white
          : theme.colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY};
        box-shadow: 0 0 0 0.125rem #ffffff;
        outline: 0.125rem solid ${theme.colors.SDS_BRAND_COLOR_BORDER_STRONG_PRIMARY};
        outline-offset: 0.125rem;
      }

      div {
        margin: ${theme.spacings.xxSmall} ${theme.spacings.xSmall};
        flex: 1 0;
      }

      div:nth-child(2) {
        flex: 0;
        display: flex;
        margin: 0;
        align-items: center;
        align-self: center;
      }

      div:last-child {
        flex: 0 4rem;
        text-align: right;
        white-space: nowrap;
        font-weight: ${theme.fontWeights.regular};
      }
    }
  `,
)

export const StyledNoSlots = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacings.xxSmall} ${theme.spacings.xSmall};
    margin: ${theme.spacings.xxSmall} ${theme.spacings.xxxSmall};
    background-color: ${theme.colors.SDS_BRAND_COLOR_BACKGROUND_WEAK_GREY};
    color: ${theme.colors.SDS_BRAND_COLOR_TEXT_STRONG_GREY};
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.sizes.xxs.fontSize};
  `,
)
