import { useQuery } from '@apollo/client'
import { Brand, getBrandByBrandName } from '@shared/domain/brand'
import { GetSelectedBrandDocument } from '@shared/gql/document-nodes'
import theme from 'themes/s-kaupat'

export const useGetSelectedBrand = (): Brand => {
  const { data: brandData } = useQuery(GetSelectedBrandDocument)
  const currentBrand = brandData?.selectedBrand ?? theme.brandName
  return getBrandByBrandName(currentBrand) || Brand.Eprisma
}
