import { HttpLink } from '@apollo/client'
import { getConfig } from '@shared/config'

const { api, buildId, stage } = getConfig()

const X_CLIENT_NAME = 'x-client-name'
const X_CLIENT_VERSION = 'x-client-version'

export const httpLink = new HttpLink({
  uri: api.url,
  credentials: 'same-origin',
  headers: {
    [X_CLIENT_NAME]: `skaupat-web`,
    [X_CLIENT_VERSION]: `${stage}-${buildId}`,
  },
})
