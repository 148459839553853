export const EAN_CODES_TO_IGNORE: string[] = [
  // Service fee: phone
  '0200424051323',
  // Service fee: email
  '0200040313836',
  // paper bag
  '6417786224096',
  // home delivery
  '0200096901186',
  // pakkausmateriaalimaksu:
  '0200023759637',
  // Rovaniemi packaging product
  '0200060667032',
  '0000000000000',
  // Pickup products:
  '0200070023590',
  '0200062604141',
  '0200042282611',
  '0200015540342',
  '0200009354078',
  '0200005949650',
  '0200031081904',
  '0200077976226',
  '0200055450199',
  '0200049878947',
  '0200055115104',
  '0200020849355',
  '0200071949325',
  '0200007994016',
  '0200090957240',
  '0200080772709',
  '0200073771498',
  '0200074546781',
  '0200030889495',
  '0200054355969',
  '0200010459977',
  '0200071797872',
  '0200045067819',
  '0200049101304',
  '0200041348813',
  '0200065249868',
  '0200047346530',
  '0200080737487',
  '0200064443434',
  '0200098186567',
  '0200097823340',
  '0200019902160',
  '0200029901603',
  '0200083752104',
  '0200041335240',
  '0200049295690',
  '0200075847771',
  '0200077862505',
  '0200057452337',
  '0200005256123',
  '0200090701157',
  '0200033823618',
  '0200089406339',
  '0200042811101',
  '0200004640466',
  // Home delivery products:
  '0200096901254',
  '0200096900431',
  '0200096900448',
  '0200096901193',
  '0200096900462',
  '0200096900479',
  '0200096900486',
  '0200096900493',
  '0200096901209',
  '0200096900516',
  '0200096900523',
  '0200096900530',
  '0200096900547',
  '0200096900554',
  '0200096900561',
  '0200096900578',
  '0200096900585',
  '0200096900592',
  '0200096900608',
  '0200096900615',
  '0200096900622',
  '0200096900639',
  '0200096900646',
  '0200096900653',
  '0200096900660',
  '0200096900677',
  '0200096900684',
  '0200096900707',
  '0200096900714',
  '0200096900721',
  '0200096900738',
  '0200096900745',
  '0200096900752',
  '0200096900769',
  '0200096900776',
  '0200096900783',
  '0200096900790',
  '0200096900806',
  '0200096900813',
  '0200096900820',
  '0200096900837',
  '0200096900844',
  '0200096900851',
  '0200096900868',
  '0200096900875',
  '0200096900882',
  '0200096900899',
  '0200096900905',
  '0200096900912',
  '0200096900929',
  '0200096900936',
  '0200096900943',
  '0200096900950',
  '0200096900967',
  '0200096900974',
  '0200096900981',
  '0200096900998',
  '0200096901216',
  '0200096901223',
  '0200096901025',
  '0200096901032',
  '0200096901049',
  '0200096901056',
  '0200096901063',
  '0200096901070',
  '0200096901087',
  '0200096901094',
  '0200096901100',
  '0200096901230',
  '0200096901247',
  '0200096901131',
  '0200096901148',
  '0200096901155',
  '0200096901162',
  '0200096901179',
  '0200096901186',
  '0200038150900',
  '0200039018476',
  '0200046710035',
  '0200062807283',
  '0200099691053',
  '0200066278775',
  '0200097242202',
  '0200039843665',
  '0200070734182',
  '0200008093077',
  '0200079119003',
  '0200089597143',
  '0200049223518',
  '0200053652908',
  '0200028108423',
  '0200016666911',
  '0200074306347',
  '0200033066305',
  '0200041141766',
  '0200059797665',
  '0200071783233',
  '0200006806181',
  '0200068332291',
  '0200017811815',
  '0200011624411',
  '0200054445417',
  '0200012485332',
  '0200059502900',
  '0200094835919',
  '0200088185044',
  '0200078721863',
  '0200039709442',
  '0200063055935',
  '0200046828310',
  '0200011520171',
  '0200028495516',
  '0200036623109',
  '0200038584231',
  '0200022876991',
  '0200037185149',
  '0200022216667',
  '0200029083927',
  '0200065779228',
  '0200099288895',
  '0200060642725',
]
