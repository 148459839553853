import type { ClientCartItem, Product } from '@shared/gql/document-nodes'

const isKgProduct = (cartItem: ClientCartItem | Product): boolean =>
  cartItem?.basicQuantityUnit?.toUpperCase() === 'KG' ||
  cartItem?.basicQuantityUnit?.toUpperCase() === 'KGM'

const isPceProduct = (cartItem: ClientCartItem | Product): boolean =>
  cartItem?.basicQuantityUnit?.toUpperCase() === 'KPL' ||
  cartItem?.basicQuantityUnit?.toUpperCase() === 'PCE'

export const calculateItemCount = (cartItem: ClientCartItem, product?: Product): number => {
  if (!product) {
    return cartItem.itemCount
  }

  if (isKgProduct(cartItem)) {
    if (isPceProduct(product)) {
      if (product.measurement?.pceApproxWeight) {
        return Math.ceil(cartItem.itemCount / product.measurement.pceApproxWeight)
      } else {
        // Return 1 if approx weight is missing from the PCE product so there
        // will be no zeroes in the frontend UI
        return 1
      }
    }

    if (isKgProduct(product)) {
      return parseFloat(cartItem.itemCount.toFixed(1))
    }
  }

  return cartItem.itemCount
}
