import type { FC } from 'react'

import { DeliveryMethod } from '@shared/gql/document-nodes'
import {
  DeliveryChosenDuotone24,
  FastTrackChosenDuotone24,
  QuickPickupChosenDuotone24,
  StoreChosenDuotone24,
} from '@sok/design-system'
import { useTheme } from 'styled-components'

export const DeliveryMethodIcon: FC<{
  deliveryMethod: DeliveryMethod
  isFastTrack: boolean
}> = ({ deliveryMethod, isFastTrack }) => {
  const theme = useTheme()
  switch (deliveryMethod) {
    case DeliveryMethod.HomeDelivery: {
      return isFastTrack ? (
        <FastTrackChosenDuotone24 secondaryColor={theme.color.action} />
      ) : (
        <DeliveryChosenDuotone24 secondaryColor={theme.color.action} />
      )
    }

    case DeliveryMethod.Pickup: {
      return isFastTrack ? (
        <QuickPickupChosenDuotone24 secondaryColor={theme.color.action} />
      ) : (
        <StoreChosenDuotone24 secondaryColor={theme.color.action} />
      )
    }
  }
}
