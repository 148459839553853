/**
 * True if input is a valid distance. Distance should be a non-negative and finite number
 *
 * @example getDistance('test') // false
 * @example getDistance(-100) // false
 * @example getDistance(0) // true
 * @example getDistance(100) // true
 * @example getDistance(Infinity) // false
 */
export const isDistance = (distance: unknown): distance is number =>
  typeof distance === 'number' && Number.isFinite(distance) && distance >= 0

/**
 * Format a distance number in meters:
 * - kilometers rounded to nearest 0.1, when over 1 km
 * - meters rounded to nearest 10, when under 1 km
 *
 * @example formatDistance(123) // '120 m'
 * @example formatDistance(2353) // '2.4 km'
 */
export const formatDistance = (meters: number): string => {
  if (!Number.isFinite(meters) || meters < 0) {
    throw new Error(`Out of range: "${meters}"`)
  }

  const km = meters / 1000
  return km < 0.995 // 995 meters should be rounded to 1 km
    ? `${Math.round(meters / 10) * 10} m`
    : `${(Math.round(km * 10) / 10).toLocaleString('fi')} km`
}
