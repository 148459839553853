import type { ApolloClient } from '@apollo/client'
import type {
  GetDeliverySlotAndStoreDataQuery,
  GetDeliverySlotAndStoreDataQueryVariables,
  Maybe,
} from '@shared/gql/document-nodes'

import { GetDeliverySlotAndStoreDataDocument } from '@shared/gql/document-nodes'
import { defaultTo } from 'ramda'

interface StoreAndSlotData {
  name: string
  brand: Maybe<string>
  areaId: string
}

export const getStoreAndSlotData = async (
  apolloClient: ApolloClient<unknown>,
  deliverySlotId: Maybe<string>,
  storeId: Maybe<string>,
): Promise<StoreAndSlotData> => {
  if (!deliverySlotId) {
    throw new Error('Delivery slot id is missing')
  }
  if (!storeId) {
    throw new Error(`Store id is missing`)
  }

  const storeAndSlotData = await apolloClient.query<
    GetDeliverySlotAndStoreDataQuery,
    GetDeliverySlotAndStoreDataQueryVariables
  >({
    query: GetDeliverySlotAndStoreDataDocument,
    variables: {
      storeId,
      deliverySlotId,
    },
  })

  const areaId = defaultTo(null, storeAndSlotData.data.deliverySlot?.areaId)
  if (!areaId) {
    throw new Error(`Did not find area for ${deliverySlotId}`)
  }
  if (!storeAndSlotData.data.store) {
    throw new Error('Store data missing')
  }

  const store = storeAndSlotData.data.store

  return { name: store.name, brand: store.brand, areaId }
}
