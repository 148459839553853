import * as E from 'fp-ts/Either'
import * as t from 'io-ts'

const ErrorMessage = t.union([
  t.literal('CreatingFavoritesList'),
  t.literal('RenamingFavoritesList'),
  t.literal('DeletingFavoritesList'),
  t.literal('LoadingFavoritesLists'),
  t.literal('generic'),
  t.literal('ModalTitle'),
  t.literal('NoDeliverySlotSelected'),
  t.literal('customer'),
  t.literal('delivery'),
  t.literal('discountCode'),
  t.literal('cartItems'),
  t.literal('cartItemsNotAvailable'),
  t.literal('cartWouldBeEmptyAfter'),
  t.literal('payment'),
  t.literal('invalidPaymentMethod'),
  t.literal('deliveryMethod'),
  t.literal('deliveryMethodNotAvailable'),
  t.literal('deliveryAddress'),
  t.literal('deliverySlotAvailability'),
  t.literal('deliverySlotIsClosed'),
  t.literal('UnknownError'),
  t.literal('DiscountCodeNotFoundError'),
  t.literal('deliverySlotIsMissing'),
  t.literal('storeNotSelected'),
  t.literal('deliveryAreaNotSelected'),
])
export type ErrorMessage = t.TypeOf<typeof ErrorMessage>

export const isError = (x: string): x is ErrorMessage => E.isRight(ErrorMessage.decode(x))

// Error messages displayed in ErrorModal with { title , description }
const ModalError = t.union([
  t.literal('PostalCodeNotSupportedError'),
  t.literal('SlotFullError'),
  t.literal('SlotClosedError'),
  t.literal('OrderNotFoundError'),
  t.literal('CreatePaymentError'),
  t.literal('AuthorizePaymentError'),
  t.literal('UnableToSavePaymentCardError'),
  t.literal('MissingSlotId'),
  t.literal('DiscountCodeExpiredError'),
])
type ModalError = t.TypeOf<typeof ModalError>

// Error messages with custom translations, but should be
// displayed with incident code in the modal
export const shouldDisplayWithIncidentCode = (x: ModalError | string): boolean => {
  switch (x) {
    case 'OrderNotFoundError':
    case 'CreatePaymentError':
    case 'AuthorizePaymentError':
      return true
    default:
      return false
  }
}

export const isUnkownError = (x: string): boolean =>
  E.isLeft(ModalError.decode(x)) && E.isLeft(ErrorMessage.decode(x))

export const isModalError = (x: string): x is ModalError => E.isRight(ModalError.decode(x))
