import type { CustomEvent, GA4CustomEvent, TrackingContext } from '../interfaces/data-layer-events'
import type { DeliveryMethod } from '@shared/gql/document-nodes'
import type { SearchAnalytics } from 'domain/search'
import type { Experiments } from 'services/Experiment/types'

import { dataLayer } from '../tag-manager'

/**
 * Pushes a custom event to data layer with GA's custom event data convention.
 * Can be used for UI events for example
 * @param customEvent
 */
export const trackCustomEvent = (customEvent: CustomEvent): void => {
  dataLayer({
    event: 'customEvent',
    customEvent,
  })
}

/**
 * Pushes a custom event to data layer with GA4's custom event data convention.
 * Can be used for UI events for example
 * @param customEvent
 */
export const trackGA4CustomEvent = (customEvent: GA4CustomEvent): void => {
  dataLayer({
    event: customEvent.eventName,
    ...(customEvent.eventParams || {}),
  })
}

interface PushData {
  search?: SearchAnalytics
  searchResultsCount?: number
  viewedCategory?: { slug: string; id: string }
  viewedProduct?: {
    ean: string | null
    name: string | null
  }
  deliveryMethod?: DeliveryMethod
  storeId?: string
  storeName?: string
  historyOrderNumber?: string
  productListContext?: TrackingContext
  productListName?: string
  experiments?: Experiments
  sidepanelVisible?: boolean
}
export const pushData = (data: PushData): void => {
  dataLayer({
    event: 'pushData',
    customDimensions: data,
  })
}
