import type { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import {
  NotShowingPage,
  StyledLink,
  StyledList,
  StyledListItem,
  StyledMessage,
} from './NotShowingPage'

const links = [
  { name: 'Google Chrome', url: 'https://www.google.com/chrome/' },
  { name: 'Safari', url: 'https://www.apple.com/safari/' },
  { name: 'Mozilla Firefox', url: 'https://www.mozilla.org/en-US/firefox/' },
  { name: 'Microsoft Edge', url: 'https://www.microsoft.com/en-us/edge' },
]

export const IEPage: FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <NotShowingPage
      icon={theme.logos.browserNotSupported}
      title={t('Dear customer update browser')}
    >
      <StyledMessage>
        {t('Unfortunately the webstore is not supported on this browser')}
      </StyledMessage>
      <StyledMessage>{t('Browser recommendation')}</StyledMessage>
      <StyledList>
        {links.map((link, index) => (
          <StyledListItem key={index} id={link.name}>
            <StyledLink href={link.url}>{link.name}</StyledLink>
          </StyledListItem>
        ))}
      </StyledList>
    </NotShowingPage>
  )
}
