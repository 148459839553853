import Head from 'next/head'

/**
 * OpenSearch description link tag, pointing to the XML file
 * @see https://developer.mozilla.org/en-US/docs/Web/OpenSearch
 * @see https://github.com/dewitt/opensearch/blob/master/opensearch-1-1-draft-6.md
 */
export const OpenSearchDescription = () => (
  <Head>
    <link
      href="/api/opensearch.xml"
      rel="search"
      title="S-kaupat"
      type="application/opensearchdescription+xml"
    />
  </Head>
)
