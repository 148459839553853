import { PageTitle } from 'components/common/Titles'
import { LowAvailabilityWarning } from 'components/ShoppingCart/mobileCart/LowAvailabilityWarning'
import styled from 'styled-components'

import { BackToOrderHistoryLink } from './OrderModification/BackToOrderHistoryLink'

export const StyledBackgroundContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  height: auto;
  padding: 0 20px;
  width: 100%;

  ${BackToOrderHistoryLink} {
    margin-top: 20px;
  }

  ${PageTitle} {
    margin: 36px 0;
  }

  ${LowAvailabilityWarning} {
    margin-bottom: 36px;
  }
`

export const StyledOrderItemContainer = styled.div`
  width: 100%;
`

export const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

export const StyledMobileOrderDetails = styled.div(({ theme }) => ({
  display: 'none',
  flexDirection: 'column',
  marginTop: theme.spacings.xxLarge,

  [`@media (max-width: ${theme.breakpoints.tablet})`]: {
    display: 'flex',
  },
}))

interface DateTimeProps {
  margin?: string
}

export const StyledDateTimeInfo = styled.div<DateTimeProps>`
  margin: ${({ margin }) => margin};
  display: flex;
  align-items: center;

  span,
  time {
    margin-left: 8px;
  }
`
