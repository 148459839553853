import { makeVar, useReactiveVar } from '@apollo/client'
import { getDateTimeNow, getDiffInDays, toDateTime } from 'domain/date-time'
import { createContext, useContext, useEffect } from 'react'
import { useSelectedDeliverySlot } from 'services/DeliverySlot/hooks'

/** Override the current delivery date for the `useUpdateDeliveryDate` hook */
export const DeliveryDateContext = createContext<string | null>(null)

const deliveryDateVar = makeVar<string | null>(null)

/**
 * Returns the ISO Date string for the current order,
 * meaning the "start time" of the selected delivery
 * slot, or "today" if not yet selected.
 */
export const useUpdateDeliveryDate = (): void => {
  const selectedSlot = useSelectedDeliverySlot()

  /**
   * Once we have a selected slot, use that as the delivery date.
   * Removing selected slots defaults back to current date.
   */
  useEffect(() => {
    if (selectedSlot.type === 'SUCCESS') {
      deliveryDateVar(
        getDiffInDays(selectedSlot.data.startDateTime) >= 0
          ? toDateTime(selectedSlot.data.startDateTime).toISODate()
          : /**
             * Default to "today" when selected slot is in the past.
             *
             * @todo [VOIK-11753] invalid delivery slot selection
             * should be cleared from the UI, but a lot of e2e tests
             * would have to be updated.
             */
            getDateTimeNow().toISODate(),
      )
    } else if (selectedSlot.type === 'NOT_ASKED') {
      deliveryDateVar(getDateTimeNow().toISODate())
    }
  }, [selectedSlot])
}

/**
 * Returns the ISO Date string for the delivery slot's date time,
 * or "today" if not yet selected. Can be overriden with the
 * `DeliveryDateContext`
 * */
export const useDeliveryDate = () => {
  const deliveryDateOverride = useContext(DeliveryDateContext)
  const deliveryDate = useReactiveVar(deliveryDateVar)
  return deliveryDateOverride ?? deliveryDate
}
