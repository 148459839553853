import type { GetDeliverySlotByIdQuery } from '@shared/gql/document-nodes'
import type { RemoteData } from 'domain/remote-data'

import { useQuery } from '@apollo/client'
import { GetDeliverySlotByIdDocument } from '@shared/gql/document-nodes'
import { useMemo } from 'react'
import { useGetSelectedSlotId } from 'utils/hooks/use-get-selected-slot-id'

class SelectedDeliverySlotError extends Error {
  public readonly kind = 'SelectedDeliverySlotError'
}

/**
 * @deprecated This points to a "selected" delivery slot, and will be replaced
 * by slot reservations.
 *
 * @see `useSelectedDeliverySlot` from `services/DeliverySlot/hooks`
 */
export const useGetSelectedDeliverySlot = (): RemoteData<
  Error,
  NonNullable<GetDeliverySlotByIdQuery['deliverySlot']>
> => {
  const selectedSlotId = useGetSelectedSlotId()

  const remote = useQuery(GetDeliverySlotByIdDocument, {
    skip: !selectedSlotId,
    variables: {
      slotId: selectedSlotId || '',
    },
  })

  return useMemo(() => {
    if (remote.loading) {
      return { type: 'LOADING' }
    }

    if (!remote.called) {
      return { type: 'NOT_ASKED' }
    }

    if (remote.error || !remote.data?.deliverySlot) {
      return {
        type: 'FAILURE',
        error: new SelectedDeliverySlotError('Could not load GetDeliverySlotByIdDocument'),
      }
    }

    return {
      type: 'SUCCESS',
      data: remote.data?.deliverySlot,
    }
  }, [remote.called, remote.data?.deliverySlot, remote.error, remote.loading])
}
