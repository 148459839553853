import type { FC } from 'react'

import { InfoOutline16 } from '@sok/design-system'
import styled, { css } from 'styled-components'

const _Notice: FC<{ className?: string; message: string; 'data-test-id'?: string }> = ({
  className,
  message,
  'data-test-id': dataTestId,
}) => (
  <aside className={className} data-test-id={dataTestId}>
    <i role="presentation">
      <InfoOutline16 />
    </i>
    <span className="text">{message}</span>
  </aside>
)

export const Notice = styled(_Notice)`
  color: ${({ theme }) => theme.colors.info600};
  margin-bottom: ${({ theme }) => theme.spacings.xLarge};
  margin-top: ${({ theme }) => theme.spacings.xxSmall};
  max-width: 321px;
  text-align: center;

  > i {
    position: relative;
    top: 2px;
  }

  > .text {
    ${({ theme }) => css(theme.variants.body2.regular)};
    text-align: center;
    padding-left: ${({ theme }) => theme.spacings.xSmall};
  }
`
