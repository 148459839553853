import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import type { GetServerSidePropsContext } from 'next'

import deepmerge from 'deepmerge'
import { equals } from 'ramda'
import { useEffect, useRef } from 'react'

import { createApolloClient } from './create-apollo-client'
import { handleSelectedStoreBrand } from './ssr/handle-selected-store-brand'
import { handleSelectedStoreId } from './ssr/handle-selected-store-id'
import { isInitialData } from './write-initial-data-to-apollo'

/**
 * Get an Apollo client instance for React usage. The client is stored
 * in a mutable ref and persistent during the entire client-side lifetime.
 * Note that this is also used inside React during SSR, so the lifetime
 * can also be a single render.
 *
 * During client-side navigation, the Apollo cache is updated when the
 * `initialData` changes. This happens when routes use `getServerSideProps`,
 * because the Next.js client still runs them via a separate API route.
 */
export const useClientSideApolloClient = (
  initialState?: NormalizedCacheObject | null,
): ApolloClient<NormalizedCacheObject> => {
  const apolloClient = useRef(createApolloClient(initialState))
  const initialRender = useRef(true)

  useEffect(() => {
    /**
     * Skip first render because `initialState` is equal to the
     * Apollo client's initial cache... it's created inside the
     * `createApolloClient` method.
     */
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    if (isInitialData(initialState)) {
      /**
       * @see https://github.com/vercel/next.js/blob/0af3b526408bae26d6b3f8cab75c4229998bf7cb/examples/with-apollo/lib/apolloClient.js#L33-L52
       * @see https://github.com/apollographql/apollo-client/discussions/6956
       */
      const data = deepmerge(initialState, apolloClient.current.cache.extract(), {
        arrayMerge: (destinationArray, sourceArray) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          [
            ...sourceArray,
            ...destinationArray.filter((d) => sourceArray.every((s) => !equals(d, s))),
          ],
      })

      apolloClient.current.cache.restore(data)
    }
  }, [initialState])

  return apolloClient.current
}

/**
 * Get a new Apollo client and populate its cache with some initial data
 * for server-side usage.
 */
export const getServerSideApolloClient = async (
  context: GetServerSidePropsContext,
): Promise<ApolloClient<NormalizedCacheObject>> => {
  if (typeof window !== 'undefined') {
    throw new Error('`getServerSideApolloClient` should not be used client-side')
  }

  const apolloClient = createApolloClient()

  const selectedStoreId = handleSelectedStoreId(apolloClient, context)
  await handleSelectedStoreBrand(apolloClient, selectedStoreId)

  return apolloClient
}

export interface ApolloStateProp {
  apolloState: NormalizedCacheObject | null
}
