import type { Router } from 'next/router'

import { useRouter } from 'next/router'
import { uniq } from 'ramda'
import { useCallback, useMemo } from 'react'

/**
 * From `params` remove all `key` with specific `value`.
 * Without a value argument, all values will be removed.
 * Returns a new instance of `params`.
 */
const removeUrlSearchParam = (
  params: URLSearchParams,
  ...toRemove: [string, string | undefined][]
): URLSearchParams => {
  const newParamsList: [string, string][] = []

  const toRemoveByKey = toRemove.reduce<Record<string, string[]>>(
    (result, [key, value]) =>
      Object.assign(result, { [key]: key in result ? [...result[key], value] : [value] }),
    {},
  )

  const keysToRemove = Object.keys(toRemoveByKey)

  /** All existing param keys that are not to be removed */
  const unrelatedKeys = uniq([...params.keys()]).filter((key) => !keysToRemove.includes(key))

  /** Preserve existing and unrelated keys */
  for (const key of unrelatedKeys) {
    const unrelatedValues = params.getAll(key)
    newParamsList.push(...unrelatedValues.map<[string, string]>((v) => [key, v]))
  }

  for (const key of keysToRemove) {
    /** Remove undefined values */
    const values = toRemoveByKey[key].filter(Boolean)

    /** all other existing values for the same key should be preserved if value was specified */
    const newValues = params
      .getAll(key)
      .filter((v) => (values.length ? !values.includes(v) : false))

    newParamsList.push(...newValues.map<[string, string]>((v) => [key, v]))
  }

  return new URLSearchParams(newParamsList)
}

type UseURLSearchParams = {
  addParams: (...toAdd: [string, string][]) => Promise<boolean>
  params: URLSearchParams
  removeParams: (...toRemove: [string, string | undefined][]) => Promise<boolean>
  replaceParam: (key: string, value: string) => Promise<boolean>
}

/**
 * A hook for the getting the current URLSearchParams instance,
 * as well as methods for adding and removing specific key/values.
 *
 * @example <caption>Use current search params</caption>
 * const { params } = useURLSearchParams()
 * params.toString() // `?brand=Valio`
 *
 * @example <caption>Add a search param</caption>
 * const { addParam } = useURLSearchParams()
 * addParams(['brand', 'Kotimaista'])
 *
 * @example <caption>Replace a search param</caption>
 * const { replaceParam } = useURLSearchParams()
 * replaceParam('sort', 'score:desc')
 *
 * @example <caption>Remove a search param with specific value</caption>
 * const { removeParam } = useURLSearchParams()
 * removeParams(['brand', 'Valio'])
 */
export const useURLSearchParams = ({
  push = true,
  shallow = true,
}: {
  push?: boolean
  shallow?: boolean
} = {}): UseURLSearchParams => {
  const router = useRouter()

  const handleUrlChange = useCallback<Router['push']>(
    (...args) => (push ? router.push(...args) : router.replace(...args)),
    [push, router],
  )

  /**
   * Memo the params object based on next/router's `asPath`,
   * which contains the full `location.pathname` + `location.search`
   */
  const [pathname, params] = useMemo(() => {
    /** URL needs a base location for relative paths, but it isn't used */
    const url = new URL(router.asPath, 'http://_')
    const searchParams = new URLSearchParams(url.search)
    searchParams.sort()
    return [url.pathname, searchParams]
    /** asPath reflects changes to the search param */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath])

  /**
   * Add one or more search params `key` with `value`
   */
  const addParams = useCallback(
    (...toAdd: [string, string][]) => {
      const newParams = new URLSearchParams(params)
      for (const [key, value] of toAdd) {
        newParams.append(key, value)
      }
      newParams.sort()
      const search = newParams.toString()

      return handleUrlChange(`${pathname}${search ? `?${search}` : ''}`, undefined, { shallow })
    },
    [handleUrlChange, params, pathname, shallow],
  )

  /**
   * Replace existing search param `key` with `value`
   */
  const replaceParam = useCallback(
    (key: string, value: string) => {
      const newParams = removeUrlSearchParam(params, [key, undefined])
      newParams.set(key, value)
      newParams.sort()
      const search = newParams.toString()

      return handleUrlChange(`${pathname}${search ? `?${search}` : ''}`, undefined, { shallow })
    },
    [handleUrlChange, params, pathname, shallow],
  )

  /**
   * Remove search params with specific `key`, and optionally
   * only specific `value`.
   */
  const removeParams = useCallback(
    (...toRemove: [string, string | undefined][]) => {
      const newParams = removeUrlSearchParam(params, ...toRemove)
      newParams.sort()

      const search = newParams.toString()

      return handleUrlChange(`${pathname}${search ? `?${search}` : ''}`, undefined, { shallow })
    },
    [handleUrlChange, params, pathname, shallow],
  )

  return { addParams, params, removeParams, replaceParam }
}
