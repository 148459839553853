import type {
  GetLocalAuthenticationTokensQuery,
  GetPaymentDetailsQuery,
  IsOrderEditActiveQuery,
} from '@shared/gql/document-nodes'
import type { CurrentUser } from 'domain/current-user'
import type { MutationResolvers } from 'types/gql/type-defs'

import {
  CustomerType,
  GetLocalAuthenticationTokensDocument,
  GetPaymentDetailsDocument,
  IsOrderEditActiveDocument,
  PaymentMethod,
} from '@shared/gql/document-nodes'
import jwt_decode from 'jwt-decode'
import { refreshTokenExpired } from 'lib/apollo/reactive-vars'
import { emptyPaymentDetails } from 'services/App/empty-initial-states'
import { DaoService } from 'services/LocalStorage'
import { PaymentDAO } from 'services/LocalStorage/dao'

export const updateAuthenticationTokens: MutationResolvers['updateAuthenticationTokens'] = (
  _,
  args,
  { cache },
) => {
  const authenticationTokens = {
    __typename: 'AuthenticationTokens' as const,
    ...args,
    modified: new Date().getTime(),
  }

  cache.writeQuery<GetLocalAuthenticationTokensQuery>({
    query: GetLocalAuthenticationTokensDocument,
    data: {
      __typename: 'Query',
      authenticationTokens,
    },
  })

  DaoService.SessionDAO.saveAuthTokens(authenticationTokens)

  const paymentDetailsData = cache.readQuery<GetPaymentDetailsQuery>({
    query: GetPaymentDetailsDocument,
  })

  const userLoggingIn = jwt_decode<CurrentUser>(authenticationTokens.accessToken)

  const isOrderEditActiveData = cache.readQuery<IsOrderEditActiveQuery>({
    query: IsOrderEditActiveDocument,
  })

  /**
   * An anonymous user has filled delivery details as a company, and selected the
   * invoice as payment method. After they login as a private customer, we should
   * clear payment details because they can't order using invoice.
   */
  if (
    paymentDetailsData?.paymentDetails?.paymentMethod === PaymentMethod.Invoice &&
    userLoggingIn.customerType === CustomerType.B2c &&
    !isOrderEditActiveData?.orderEditActive?.orderStatus
  ) {
    cache.writeQuery({
      query: GetPaymentDetailsDocument,
      data: {
        __typename: 'Query',
        paymentDetails: emptyPaymentDetails(),
      },
    })

    PaymentDAO.savePaymentData(emptyPaymentDetails())
  }

  refreshTokenExpired(false)

  return null
}

export const clearAuthenticationTokens: MutationResolvers['clearAuthenticationTokens'] = (
  _,
  _args,
  { cache },
) => {
  cache.writeQuery({
    query: GetLocalAuthenticationTokensDocument,
    data: {
      __typename: 'Query' as const,
      authenticationTokens: {
        __typename: 'AuthenticationTokens' as const,
        accessToken: null,
        refreshToken: null,
        idToken: null,
        modified: null,
      },
    },
  })
  DaoService.SessionDAO.clearAuthTokens()
  return null
}

export const updateAuthenticationRedirectPath: MutationResolvers['updateAuthenticationRedirectPath'] =
  (_, args) => {
    DaoService.SessionDAO.saveAuthRedirectPath(args?.authRedirectPath ?? null)
    return null
  }

export const clearAuthenticationRedirectPath: MutationResolvers['clearAuthenticationRedirectPath'] =
  () => {
    DaoService.SessionDAO.clearAuthRedirectPath()
    return null
  }
