import type { Modify } from '../local-storage'
import type { CustomerDetailsResponse, CustomerType } from '@shared/gql/document-nodes'
import type { TypeOf } from 'zod'

import { CUSTOMER_SCHEMA_VERSION, CustomerSchema } from '@shared/domain/local-storage'

import { LocalStorageService } from '../local-storage'

const KEY_CUSTOMER_DATA = 'customer-data'

const schemaValidator = (data: TypeOf<typeof CustomerSchema>) => CustomerSchema.parse(data)

const newEmptyLocalStorageCustomerData = (): TypeOf<typeof CustomerSchema> => ({
  cacheVersion: CUSTOMER_SCHEMA_VERSION,
  customerData: {
    customerDetails: null,
    customerType: null,
  },
})

const modifyCustomerData = (modifier: Modify<TypeOf<typeof CustomerSchema>>) => {
  LocalStorageService.modify<TypeOf<typeof CustomerSchema>>(
    modifier,
    newEmptyLocalStorageCustomerData,
    KEY_CUSTOMER_DATA,
    schemaValidator,
  )
}

const saveCustomerType = (customerType: CustomerType): void => {
  modifyCustomerData((current) => ({
    ...current,
    customerData: {
      ...current.customerData,
      customerDetails: current.customerData?.customerDetails ?? null,
      customerType,
    },
  }))
}

const saveCustomerData = (customerDetails: CustomerDetailsResponse): void => {
  modifyCustomerData((current) => ({
    ...current,
    customerData: {
      ...current.customerData,
      customerType: current.customerData?.customerType ?? null,
      customerDetails: customerDetails,
    },
  }))
}

const loadCustomerData = (): TypeOf<typeof CustomerSchema> | null => {
  return LocalStorageService.load<TypeOf<typeof CustomerSchema>>(KEY_CUSTOMER_DATA, schemaValidator)
}

const reset = (): void => {
  LocalStorageService.clear(KEY_CUSTOMER_DATA)
}

export const CustomerDAO = {
  loadCustomerData,
  saveCustomerData,
  reset,
  saveCustomerType,
  localStorageKey: KEY_CUSTOMER_DATA,
}
