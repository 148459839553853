import type { FC } from 'react'

import styled from 'styled-components'

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.info400};
  text-decoration: none;
`

interface ImageLinkButtonProps {
  ['data-test-id']?: string
  url: string
  alt: string
  imageUrl: string
}

export const ImageLinkButton: FC<ImageLinkButtonProps> = ({
  ['data-test-id']: dataTestId,
  url,
  imageUrl,
  alt,
}) => (
  <StyledLink href={url} target="_blank" rel="noopener noreferrer" data-test-id={dataTestId}>
    <img src={imageUrl} alt={alt} />
  </StyledLink>
)
