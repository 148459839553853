import type { Order } from '@shared/gql/document-nodes'
import type { FC } from 'react'

import { Alert, AlertType } from '@sok/design-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useReplaceOrder } from 'utils/order/use-edit-order'

const _LowAvailabilityWarning: FC<{ className?: string; order?: Order }> = ({
  className,
  order,
}) => {
  const { t } = useTranslation()
  const editOrder = useReplaceOrder('/yhteenveto')

  return (
    <Alert
      className={className}
      actionButton={!!order}
      actionHandler={order ? () => editOrder(order) : undefined}
      actionText={order ? t('Modify order') : undefined}
      closable={!order /** do not allow notification to be dismissed while on order page */}
      data-test-id="low-availability-warning"
      isMobile={!order /** this layout option saves space on order page */}
      message={t('Low Availability in Cart')}
      type={AlertType.INFO}
    />
  )
}

export const LowAvailabilityWarning = styled(_LowAvailabilityWarning)({
  width: '100%',
})
