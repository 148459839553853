import type { FC, ReactNode } from 'react'

import { Button, ButtonType } from '@sok/design-system'
import { useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'

import { getSnackbarPortal } from './SnackbarPortal'

export interface SnackbarProps {
  onHide: () => void
  onAction?: () => void
  className?: string
  autoHideTimeout?: number
  actionText?: string
  children?: ReactNode
}

const SnackbarComponent: FC<SnackbarProps> = ({
  className,
  children,
  actionText,
  onHide,
  autoHideTimeout = 4000,
  onAction = () => {},
}) => {
  const isClientSide = useIsClientSide()
  const wrapperDiv = useRef<HTMLDivElement>(null)
  const portalElement = getSnackbarPortal(isClientSide)

  const showSnackbar = useCallback(() => {
    const restoreAutoHeight = () => {
      if (wrapperDiv.current) {
        wrapperDiv.current.style.height = 'auto'
      }
    }

    if (wrapperDiv.current) {
      // We need to use scrollHeight since CSS transition to "auto" is not possible.
      wrapperDiv.current.style.height = `${wrapperDiv.current?.scrollHeight}px`

      // And we need to reset to height "auto" once the transition is done to make
      // sure all the styles are applied correctly even on initial render.
      wrapperDiv.current.addEventListener('transitionend', restoreAutoHeight, { once: true })
      wrapperDiv.current.classList.add('visible')
    }
  }, [wrapperDiv])

  const hideSnackbar = useCallback(() => {
    if (wrapperDiv.current) {
      wrapperDiv.current.classList.remove('visible')
      wrapperDiv.current.addEventListener('transitionend', onHide, { once: true })
    }
  }, [onHide, wrapperDiv])

  const onClick = () => {
    if (wrapperDiv.current?.classList.contains('visible')) {
      onAction()
      hideSnackbar()
    }
  }

  useEffect(() => {
    showSnackbar()
    autoHideTimeout && setTimeout(hideSnackbar, autoHideTimeout)
  }, [wrapperDiv, autoHideTimeout, hideSnackbar, showSnackbar])

  if (!isClientSide) {
    return null
  }

  if (!portalElement) {
    throw new Error('No portal element found in DOM. Did you forget to render a SnackbarPortal?')
  }

  return createPortal(
    <div ref={wrapperDiv} className={className} role="alert" data-test-id="snackbar">
      <StyledWrapper>
        {children}
        <StyledButton type={ButtonType.TEXT} text={actionText} onClick={onClick} />
      </StyledWrapper>
    </div>,
    portalElement,
  )
}

SnackbarComponent.displayName = 'Snackbar'

const StyledButton = styled(Button)`
  &.sok-btn.sok-btn-text {
    color: ${({ theme }) => theme.color.snackbarButtonColor} !important;
    height: auto;
    padding: 0;
    margin-left: ${({ theme }) => theme.spacings.small};
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
`

export const Snackbar = styled(SnackbarComponent)`
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.color.lightGrey};
  background-color: ${({ theme }) => theme.color.snackbarBackground};
  border-radius: ${({ theme }) => theme.spacings.xxSmall};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  height: 0;
  opacity: 0;
  transition: all 200ms ease-in-out;

  &.visible {
    opacity: 1;
    margin-top: ${({ theme }) => theme.spacings.xxSmall};
  }

  width: 400px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`
