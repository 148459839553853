import { herkku, neutral } from '@sok/design-system'
import { transparentize } from 'polished'

export const CONTENTFUL_MEDIA_ROOT = 'https://images.ctfassets.net/0o5z0rfk7er2'

// Temporarily using the herkku brand colors until s-kaupat colors have been defined
export const color = {
  primaryBrand: herkku.colors.secondary,
  primaryBrandVariant: herkku.colors.secondary800,
  primaryBrandLight: herkku.colors.secondary400,
  action: herkku.colors.primary,
  actionVariant: herkku.colors.primary900,
  fadedBrand: transparentize(0.7, herkku.colors.white),
  fontBlack: neutral.colors.grey800,
  hoverBlack: neutral.colors.black,
  customLightGray: herkku.colors.grey200,
  white: herkku.colors.white,
  grey: herkku.colors.grey400,
  lightGrey: herkku.colors.grey300,
  backgroundEmphasis: neutral.colors.grey100,
  error: neutral.colors.error800,
  darkGrey800: neutral.colors.grey800,
  // banner colors
  modBanner: herkku.colors.info50,
  info: herkku.colors.primary800,
  successBanner: herkku.colors.success50,
  overlay: transparentize(0.3, herkku.colors.secondary),
  disabled: neutral.colors.primary100,
  navigationHighlight: neutral.colors.primary500,
  globalNavCart: neutral.colors.secondary,
  globalNavCartHover: neutral.colors.secondary800,
  globalNavCartShadow: neutral.colors.secondary500,
  lightestGrey100: neutral.colors.grey100,
  darkGrey500: neutral.colors.grey500,
  darkGrey700: neutral.colors.grey700,
  grey200: neutral.colors.grey200,
  highlightHoverColor: neutral.colors.primary500,
  highlightFocusColor: neutral.colors.primary200,
  timeSlotBackground: neutral.colors.primary50,
  snackbarBackground: neutral.colors.grey900,
  snackbarButtonColor: neutral.colors.primary500,
  secondary700: neutral.colors.secondary700,
  favoriteColor: neutral.colors.error500,
  activeMenuColor: neutral.colors.primary,
  activeTimeSlotColor: neutral.colors.primary800,
  highlight800: neutral.colors.primary800,
  inputBorderColor: neutral.colors.grey300,
}

export const borderRadius = {
  base: '0.15em',
}

export const screenHeight = {
  summaryContainerHeight: 'calc(100vh - 80px)',
  orderContainerHeight: 'calc(100vh - 141px)',
  iPadOrderContainerHeight: 'calc(100vh - 222px)',
  desktopHeight: 'calc(100vh - 95px)',
  contentDesktopHeight: 'calc(100vh - 75px)',
  productContentHeight: 'calc(100vh - 158px)',
  orderHistoryContentHeight: 'calc(100vh - 158px)',
  orderHistoryContentMobileHeight: 'calc(100vh - 125px)',
  mobileHeight: 'calc(100vh - 125px)',
  contentMobileHeight: 'calc(100vh - 61px)',
}

export const favIconUrl = '/favicon.ico'

export const logos = {
  browserNotSupported: '/icons/browser-not-supported.svg',
  calendarError: '/icons/calendar-error.svg',
  calendarExpired: '/icons/calendar-expired.svg',
  calendarTimeRunningOut: '/icons/calendar-time-running-out.svg',
  delivery: '/icons/delivery.svg',
  deliverySuccess: '/icons/delivery-success.svg',
  errorGeneral: '/icons/error-general.svg',
  maintenance: '/icons/maintenance.svg',
  missingProductImage: '/icons/missing-product-image.svg',
  notFoundCategory: '/icons/not-found-category.svg',
  notFoundDelivery: '/icons/not-found-delivery.svg',
  notFoundOrder: '/icons/not-found-order.svg',
  notFoundPage: '/icons/not-found-page.svg',
  notFoundPickup: '/icons/not-found-pickup.svg',
  notFoundProduct: '/icons/not-found-product.svg',
  notFoundSlot: '/icons/not-found-slot.svg',
  pickup: '/icons/pickup.svg',
  pickupSuccess: '/icons/pickup-success.svg',
  shoppingCartEmpty: '/icons/basket-empty.svg',
  shoppingCartFull: '/icons/basket-full.svg',
}

export const defaultSeoTags = {
  additionalMetaTags: [
    {
      content: 'width=device-width, initial-scale=1, maximum-scale=10, user-scalable=yes',
      name: 'viewport',
    },
  ],
}

interface ExtraBreakpoints {
  readonly middleDesktop: '1280px'
  readonly xlDesktop: '1920px'
}

export const extraBreakpoints: ExtraBreakpoints = {
  middleDesktop: '1280px',
  xlDesktop: '1920px',
}
