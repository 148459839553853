import { IS_BROWSER } from '@s-group/react-usercentrics'

export const shouldOpenDeliveryTimeslotAfterLogin = () =>
  IS_BROWSER && sessionStorage.getItem('delivery-timeslot-after-login') === 'true'

export const setOpenDeliveryTimeslotAfterLogin = () =>
  IS_BROWSER ? sessionStorage.setItem('delivery-timeslot-after-login', 'true') : null

export const removeDeliveryTimeslotAfterLoginToken = () =>
  IS_BROWSER && sessionStorage.removeItem('delivery-timeslot-after-login')
