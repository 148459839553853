import { useQuery } from '@apollo/client'
import { GetSelectedDeliverySlotDocument } from '@shared/gql/document-nodes'

/**
 * @deprecated This points to a "selected" delivery slot, and will be replaced
 * by slot reservations.
 *
 * @see `useSelectedDeliverySlot` from `services/DeliverySlot/hooks`
 */
export const useGetSelectedSlotId = (): string | null => {
  const { data: selectedDeliverySlot } = useQuery(GetSelectedDeliverySlotDocument)
  return selectedDeliverySlot?.deliveryDetailsInfo?.deliverySlotId || null
}
