import type { FC } from 'react'

import { DeliveryMethod } from '@shared/gql/document-nodes'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import { capitalize } from 'utils/common/capitalize'

import { StyledDeliveryError, StyledImage, StyledInfoLabelBold } from './styled-no-delivery'

interface DeliveryOptionsNotFoundProps {
  deliveryMethod: DeliveryMethod
  searchInput: string
}

export const DeliveryOptionsNotFound: FC<DeliveryOptionsNotFoundProps> = ({
  deliveryMethod,
  searchInput,
}) => {
  const { t } = useTranslation()
  const isHomeDelivery = deliveryMethod === DeliveryMethod.HomeDelivery

  const theme = useTheme()
  const noDeliveryMethodLogo = isHomeDelivery
    ? theme.logos.notFoundDelivery
    : theme.logos.notFoundPickup

  return (
    <StyledDeliveryError data-test-id="delivery-options-not-found">
      <StyledImage src={noDeliveryMethodLogo} role="presentation" />

      <div>
        <StyledInfoLabelBold
          data-test-id={isHomeDelivery ? 'no-delivery-info-label' : 'no-pickup-info-label'}
        >
          {isHomeDelivery
            ? t('We do not drive to the area', { postalCode: searchInput })
            : t('There is no pickup point in the area yet', {
                capitalizedStoreSearchInput: capitalize(searchInput),
              })}
        </StyledInfoLabelBold>
      </div>
    </StyledDeliveryError>
  )
}
