import { CustomerType } from '@shared/gql/document-nodes'
import * as z from 'zod'

/** When bumping this the next time, change it to a simple number, e.g 1. The only purpose of the version is to increment it. */
export const CUSTOMER_SCHEMA_VERSION = '1.2.17'

export const CustomerSchema = z.object({
  cacheVersion: z.literal(CUSTOMER_SCHEMA_VERSION),
  customerData: z
    .object({
      customerDetails: z
        .object({
          __typename: z.literal('CustomerDetailsResponse'),
          firstName: z.string().nullable(),
          lastName: z.string().nullable(),
          phone: z.string().nullable(),
          email: z.string().nullable(),
          companyName: z.string().nullable(),
          comment: z.string().nullable(),
        })
        .nullable(),
      customerType: z.nativeEnum(CustomerType).nullable(),
    })
    .nullable(),
})
