import type {
  ClientCartItem,
  ClientCartItemViewFromProductFragment,
  Product,
} from '@shared/gql/document-nodes'

import { useApolloClient } from '@apollo/client'
import { getConfig } from '@shared/config'
import { mapProductToCartItem, setCartItemCount } from 'domain/map-products-to-cart-items'
import { pipe } from 'fp-ts/function'
import { showGlobalLoadingIndicatorVar } from 'lib/apollo/reactive-vars'
import { useCallback } from 'react'
import { useStoreId } from 'utils/hooks/store/use-store-id'

import { DaoService } from '../../LocalStorage'
import { shoppingCartVar } from '../cache'
import { getClientCartItem } from '../utils/get-client-cart-item'

const { domain } = getConfig()

const setItemCount = (
  cartItems: ClientCartItem[],
  targetCartItemId: string,
  product: ClientCartItem | ClientCartItemViewFromProductFragment | null | undefined,
  itemCount: number,
): ClientCartItem[] => {
  if (itemCount <= 0) {
    return cartItems.filter((x) => x.id !== targetCartItemId)
  }

  const existingItem = cartItems.find((x) => x.id === targetCartItemId)

  if (!existingItem) {
    if (!product) {
      return cartItems
    }

    return [
      pipe(product as Product, mapProductToCartItem, setCartItemCount(itemCount)),
      ...cartItems,
    ]
  }

  return cartItems.map((x) => {
    if (x.id === targetCartItemId) {
      return {
        ...x,
        itemCount,
      }
    }
    return x
  })
}

export const useSetCartItemCount = () => {
  const apolloClient = useApolloClient()
  const { selectedStoreId } = useStoreId()

  return useCallback(
    async (id: string, itemCount: number, pendingStoreId?: string) => {
      const oldCart = shoppingCartVar()

      // Products are cached by their id and storeId. When changing/selecting the store,
      // there might be a "pending" storeId (eg. the default store id). Otherwise
      // the selected id can be read directly from Apollo cache.
      const storeId = pendingStoreId || selectedStoreId || domain.defaultStoreId

      let isNetworkQuery = false

      const clientCartItem = await getClientCartItem(apolloClient, id, storeId, () => {
        isNetworkQuery = true
        showGlobalLoadingIndicatorVar(true)
      })

      const newCart = setItemCount(oldCart, id, clientCartItem, itemCount)
      await DaoService.CartDAO.saveCartItems(newCart, apolloClient)
      shoppingCartVar(newCart)

      if (isNetworkQuery) {
        /**
         * If query was not from network, do not try to disable loading indicator
         * to avoid possible race conditions with other events triggering it.
         */
        showGlobalLoadingIndicatorVar(false)
      }
    },
    [apolloClient, selectedStoreId],
  )
}
