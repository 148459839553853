import type { Dispatch, FC, ReactNode, SetStateAction } from 'react'

import { getConfig } from '@shared/config'
import { toDateTime } from 'domain/date-time'
import { createContext, useContext, useEffect, useState } from 'react'
import { useExperimentVariant } from 'services/Experiment/use-experiment-variant'
import { pushData } from 'utils/tracking/custom-events/custom-events'

import { persistSidepanelVisibility, readPersistedSidepanelVisibility } from './cache'
import { SidepanelInlineToggle } from './InlineToggle'

const { featureFlags } = getConfig()

type CollapsibleSidepanelContext = {
  isSidepanelCollapsible: boolean
  isSidepanelVisible: boolean | null
  setIsSidepanelVisible: Dispatch<SetStateAction<boolean | null>>
  setIsSidepanelCollapsible: Dispatch<SetStateAction<boolean>>
}
const CollapsibleSidepanelContext = createContext<CollapsibleSidepanelContext | null>(null)

export const useCollapsibleSidepanelContext = (): CollapsibleSidepanelContext => {
  const ctx = useContext(CollapsibleSidepanelContext)

  if (!ctx) {
    return {
      isSidepanelCollapsible: false,
      isSidepanelVisible: null,
      setIsSidepanelCollapsible: () => {},
      setIsSidepanelVisible: () => {},
    }
  }

  return ctx
}

const useSidepanelDefaultVisibility = () => {
  return useExperimentVariant('collapsible-cart', [true, false], toDateTime('2023-09-11T00:00:00Z'))
}

export const CollapsibleSidepanelProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  const [isSidepanelCollapsible, setIsSidepanelCollapsible] = useState(false)
  const [isSidepanelVisible, setIsSidepanelVisible] = useState<boolean | null>(null)

  const defaultVisibility = useSidepanelDefaultVisibility()

  useEffect(() => {
    setIsSidepanelVisible(readPersistedSidepanelVisibility() ?? defaultVisibility)
  }, [defaultVisibility])

  useEffect(() => {
    if (isSidepanelVisible !== null) {
      persistSidepanelVisibility(isSidepanelVisible)
      pushData({
        sidepanelVisible: isSidepanelVisible,
      })
    }
  }, [isSidepanelVisible])

  if (!featureFlags.collapsibleSidepanel) {
    return <>{children}</>
  }

  return (
    <CollapsibleSidepanelContext.Provider
      value={{
        isSidepanelCollapsible,
        isSidepanelVisible,
        setIsSidepanelVisible,
        setIsSidepanelCollapsible,
      }}
    >
      <SidepanelInlineToggle isVisible={defaultVisibility ?? true} />
      {children}
    </CollapsibleSidepanelContext.Provider>
  )
}
