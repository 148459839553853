import type { GetDeliveryAreaQuery } from '@shared/gql/document-nodes'

import { useLazyQuery, useQuery } from '@apollo/client'
import { getConfig } from '@shared/config'
import { GetDeliveryAreaDocument, GetSelectedAreaIdDocument } from '@shared/gql/document-nodes'
import { DateTime } from 'luxon'
import { useDeliverySlotReservationId } from 'services/DeliverySlot/hooks'
import { useGetOrderEditState } from 'utils/order/use-get-order-edit-state'

const { domain } = getConfig()

export const useGetDeliverySlotsFromAreaQuery = (
  startDate: DateTime,
  endDate: DateTime,
  onCompleted: (data: GetDeliveryAreaQuery) => void,
) => {
  const reservationId = useDeliverySlotReservationId()
  const orderEditState = useGetOrderEditState()
  const { data: selectedAreaIdData } = useQuery(GetSelectedAreaIdDocument)
  const selectedAreaId = selectedAreaIdData?.selectedAreaId || ''

  const result = useQuery(GetDeliveryAreaDocument, {
    fetchPolicy: 'network-only' /** always fetch slots from network to update prices */,
    notifyOnNetworkStatusChange: true /** make sure refetch() triggers loading state */,
    skip: !selectedAreaId,
    variables: {
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
      id: selectedAreaId,
      orderId: orderEditState?.orderId,
      reservationId,
    },
    onCompleted,
  })

  return result
}

export const useGetDeliverySlotsFromAreaLazyQuery = (
  startDate: Date | null = null,
  endDate: Date | null = null,
) => {
  const reservationId = useDeliverySlotReservationId()
  const orderEditState = useGetOrderEditState()

  const startDateTime = startDate
    ? DateTime.fromJSDate(startDate, { zone: domain.localTimezone })
    : null
  const endDateTime = endDate ? DateTime.fromJSDate(endDate, { zone: domain.localTimezone }) : null

  const [fetch] = useLazyQuery(GetDeliveryAreaDocument)

  return (selectedAreaId: string) =>
    fetch({
      fetchPolicy: 'network-only' /** always fetch slots from network to update prices */,
      notifyOnNetworkStatusChange: true /** make sure refetch() triggers loading state */,
      variables: {
        endDate: endDateTime?.isValid ? endDateTime.toISODate() : null,
        id: selectedAreaId,
        orderId: orderEditState?.orderId,
        reservationId,
        startDate: startDateTime?.isValid ? startDateTime.toISODate() : null,
      },
    })
}
