import type { DeliveryMethod } from '@shared/gql/document-nodes'
import type { DeliveryOption } from 'domain/delivery-option'
import type { FC } from 'react'

import { useQuery, useReactiveVar } from '@apollo/client'
import { GetSelectedAreaIdDocument } from '@shared/gql/document-nodes'
import { Alert, AlertType } from '@sok/design-system'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { geolocationEnabledVar, geolocationErrorVar } from 'services/Delivery/cache'
import { useDeliveryOptionSearch } from 'services/Delivery/hooks/use-delivery-option-search'
import styled from 'styled-components'

import { DeliveryOptionSearchInput } from './DeliveryOptionSearchInput'
import { DeliveryOptionsForm } from './DeliveryOptionsForm'
import { DeliveryOptionsListPlaceholder } from './DeliveryOptionsListPlaceholder'
import { DeliveryOptionsNotFound } from './DeliveryOptionsNotFound'
import { useGetInitialDeliverySelections } from './use-get-initial-delivery-selections'

const StyledDeliveryOptionSelectorContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'auto',
  padding: `0 ${theme.spacings.small}`,

  '.sok-alert': {
    marginBottom: theme.spacings.large,
  },

  [DeliveryOptionSearchInput]: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
}))

interface Props {
  activeDeliveryMethod: DeliveryMethod
  deliveryMethod: DeliveryMethod
  inputsDisabled: boolean
  isConfirmingSelection: boolean
  onSelect: (searchTerm: string) => (x: DeliveryOption) => void
}

export const DeliveryOptionSelectorTabContainer: FC<Props> = ({
  activeDeliveryMethod,
  deliveryMethod,
  onSelect,
  inputsDisabled,
  isConfirmingSelection,
}) => {
  const { initialDeliveryMethod, initialSearchTerm, loading, called } =
    useGetInitialDeliverySelections()
  const initialLoading = called ? loading : false
  const [searchTerm, setSearchTerm] = useState('')

  /**
   * After loading the initial search term, fill it in unless there's already user input.
   * This mostly happens during tests when Cypress opens the modal very fast
   */
  useEffect(() => {
    if (initialLoading) return
    const initialValue = initialDeliveryMethod === deliveryMethod ? initialSearchTerm : ''
    if (initialValue) {
      setSearchTerm((current) => {
        if (current) return current
        return initialValue
      })
    }
  }, [deliveryMethod, initialDeliveryMethod, initialLoading, initialSearchTerm])

  const handleDeliveryOptionSelect = (selectedDeliveryOption?: DeliveryOption) => {
    if (!selectedDeliveryOption?.store) return

    onSelect(searchTerm)(selectedDeliveryOption)
  }

  const { deliveryOptions, isLoading, isNotFound } = useDeliveryOptionSearch({
    deliveryMethod,
    currentSearchTerm: searchTerm,
  })

  const { data: selectedAreaIdData } = useQuery(GetSelectedAreaIdDocument)
  const selectedAreaId = selectedAreaIdData?.selectedAreaId
  const initiallySelectedDeliveryOption = useMemo(
    () => deliveryOptions.find((area) => area.areaId === selectedAreaId) ?? null,
    [deliveryOptions, selectedAreaId],
  )

  const { t } = useTranslation()

  const geolocationError = useReactiveVar(geolocationErrorVar)
  const geolocationEnabled = useReactiveVar(geolocationEnabledVar)

  /**
   * When geolocation gets enabled, clear any search term because
   * there's no need to filter location-based list.
   */
  useEffect(() => {
    if (geolocationEnabled) setSearchTerm('')
  }, [geolocationEnabled])

  const showSearhCTA = deliveryOptions.length === 0 && !(isLoading || isNotFound)

  if (activeDeliveryMethod !== deliveryMethod) {
    return null
  }

  return (
    <StyledDeliveryOptionSelectorContainer>
      <DeliveryOptionSearchInput
        activeDeliveryMethod={deliveryMethod}
        onSearch={setSearchTerm}
        searchTerm={searchTerm}
        initialLoading={initialLoading}
      />

      {geolocationError && (
        <Alert
          closable
          closeLabel={t`Close`}
          data-test-id="geolocation-prompt-error"
          message={t`Geolocation error`}
          onClose={() => geolocationErrorVar(null)}
          type={AlertType.ERROR}
        />
      )}

      {showSearhCTA && <DeliveryOptionsListPlaceholder deliveryMethod={deliveryMethod} />}

      {!showSearhCTA && !isNotFound && (
        <DeliveryOptionsForm
          activeDeliveryMethod={deliveryMethod}
          deliveryOptions={deliveryOptions}
          inputsDisabled={inputsDisabled}
          isDeliveryOptionsLoading={isLoading}
          initiallySelectedDeliveryOption={initiallySelectedDeliveryOption}
          isConfirmingSelection={isConfirmingSelection}
          onSubmit={handleDeliveryOptionSelect}
        />
      )}

      {isNotFound && (
        <DeliveryOptionsNotFound deliveryMethod={deliveryMethod} searchInput={searchTerm} />
      )}
    </StyledDeliveryOptionSelectorContainer>
  )
}
