import type { ClientCartItem } from '@shared/gql/document-nodes'

import { useApolloClient } from '@apollo/client'

import { DaoService } from '../../LocalStorage'
import { shoppingCartVar } from '../cache'

export const useRemoveCartItems = (): ((items: Pick<ClientCartItem, 'id'>[]) => Promise<void>) => {
  const apolloClient = useApolloClient()

  return async (items) => {
    const existingShoppingCart = shoppingCartVar()

    const newCart = existingShoppingCart.filter(
      // From existing cart, create copy with only those items _NOT_ found in `params.items`.
      // In other words, remove all `params.items` from cart.
      (cartItem) => !items.some((itemToRemove) => itemToRemove.id === cartItem.id),
    )

    await DaoService.CartDAO.saveCartItems(newCart, apolloClient)

    shoppingCartVar(newCart)
  }
}
