import type { ContentfulStateProp } from './get-contentful-content'
import type { Brand } from '@shared/domain/brand'
import type { FC, ReactNode } from 'react'
import type { IContentfulContext, ServiceBannerEntry } from 'types/contentful'
import type { IFooterFields, IPageLayoutFields } from 'types/contentful/generated'

import { getConfig } from '@shared/config'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useRef, useState } from 'react'

import { getContentfulContent } from './get-contentful-content'

export const ContentfulContext = createContext<IContentfulContext | null>(null)

export const useContentfulContent = (): IContentfulContext => {
  const ctxValue = useContext(ContentfulContext)
  if (ctxValue === null) {
    throw new Error('Expected context value to be set')
  }
  return ctxValue
}

interface ContentfulProviderProps {
  brand: Brand
  initialState?: ContentfulStateProp['contentfulState']
  children?: ReactNode
}

const config = getConfig()

const LazyContentfulLivePreviewProvider = dynamic(
  async () => (await import('@contentful/live-preview/react')).ContentfulLivePreviewProvider,
  { ssr: false },
)

export const ContentfulProvider: FC<ContentfulProviderProps> = ({
  brand,
  children,
  initialState,
}) => {
  const [xrayMode, setXrayMode] = useState<boolean | null>(false)
  const [footer, setFooter] = useState<IFooterFields | null>(initialState?.footer || null)

  useEffect(() => {
    if (initialState?.footer) {
      setFooter(initialState?.footer)
    }
  }, [initialState?.footer])

  const [frontPage, setFrontPage] = useState<IPageLayoutFields | null>(
    initialState?.frontPage || null,
  )

  // `router` is null during tests, when Next.js Router is not mocked
  const router = useRouter()
  const isLandingPage = router?.pathname === '/'

  // assume we have pre-loaded during SSR if footer exists
  const lastFetchedBrand = useRef<Brand | null>(footer ? brand : null)

  const [serviceBanners, setServiceBanners] = useState<ServiceBannerEntry[] | null>(
    initialState?.serviceBanners || null,
  )

  useEffect(() => {
    // Only fetch new content when store brand changes
    if (lastFetchedBrand.current === brand) return

    let cancelled = false

    const newBrand = brand

    getContentfulContent(newBrand).then((content) => {
      if (cancelled) return

      lastFetchedBrand.current = newBrand

      setFooter(content.footer)
      setFrontPage(content.frontPage)
      setServiceBanners(content.serviceBanners)
    })

    // Cancel content fetch when brand changes
    return () => void (cancelled = true)
  }, [brand, isLandingPage, serviceBanners])

  return (
    <ContentfulContext.Provider
      value={{ footer, frontPage, serviceBanners, xrayMode, setXrayMode }}
    >
      {config.contentful.livePreview ? (
        <LazyContentfulLivePreviewProvider locale="fi-FI" enableInspectorMode enableLiveUpdates>
          {children}
        </LazyContentfulLivePreviewProvider>
      ) : (
        children
      )}
    </ContentfulContext.Provider>
  )
}
