import { isBrand } from '@shared/domain/brand'
import { overrideBrandVar } from 'lib/apollo/reactive-vars'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'

/**
 * Read a possible brand value from the URL Search parameters,
 * eg. `?theme=herkku`, and set it into a Reactive Var. This should
 * be used to override the current brand/theme of the site.
 * See also `useResetOverrideBrand` for resetting it.
 */
export const useOverrideBrand = (): void => {
  const router = useRouter()
  const isClientSide = useIsClientSide()

  return useMemo(() => {
    if (!isClientSide) return undefined

    const themeParams = router?.query?.theme
    if (!themeParams) return undefined

    const theme = Array.isArray(themeParams) ? themeParams[themeParams.length - 1] : themeParams

    if (isBrand(theme)) {
      overrideBrandVar(theme)
    }
  }, [isClientSide, router?.query?.theme])
}

/** Matches anything before, and between a `?` and a possible `#`
 * to get the current pathname, search parameters, and the hash.
 *
 * @example <caption>/tuotteet</caption>
 * $1: '/tuotteet', $2: undefined, $3: undefined
 *
 * @example <caption>/tuotteet?search</caption>
 * $1: '/tuotteet', $2: 'search', $3: undefined
 *
 * @example <caption>/tuotteet?search#hash</caption>
 * $1: '/tuotteet', $2: 'search', $3: 'hash'
 *
 */
const PATH_SEARCH_HASH_REGEXP = /^(.*?)(\?.+?)?(?=(#.+)|$)/

/** Split a path like `/tuotteet?search#hash` into [pathname, search, string]  */
const splitAsPath = (asPath: string): [string, string, string] => {
  const [, pathname = '', search = '', hash = ''] = asPath.match(PATH_SEARCH_HASH_REGEXP) || []
  return [pathname, search, hash]
}

/**
 * Reset local override theme (Reactive Var), and remove it from the URL if present.
 * See also `useOverrideBrand` for setting an override.
 */
export const useResetOverrideBrand = (): (() => Promise<void>) => {
  const router = useRouter()

  return useCallback(async () => {
    overrideBrandVar(null)

    if (router?.query?.theme) {
      const [realPathname, searchString, hash] = splitAsPath(router.asPath)

      const searchParams = new URLSearchParams(searchString)
      searchParams.delete('theme')
      const search = searchParams.toString()
      const searchAndHash = `${search ? '?' + search : ''}${hash}`

      await router.replace(
        /** "Url" eg. `/tuotteet/[...slug]` */
        `${router.pathname}${searchAndHash}`,
        /** "As" eg. `/tuotteet/juomat-1` */
        `${realPathname}${searchAndHash}`,
        { shallow: true },
      )
    }
  }, [router])
}
