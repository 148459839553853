import type { Modify } from '../local-storage'
import type { DeliveryStore } from '@shared/gql/document-nodes'
import type { TypeOf } from 'zod'

import { STORE_SCHEMA_VERSION, StoreSchema } from '@shared/domain/local-storage'

import { LocalStorageService } from '../local-storage'

const schemaValidator = (data: TypeOf<typeof StoreSchema>) => StoreSchema.parse(data)

const KEY_STORE_DATA = 'store-data'

const newEmptyLocalStorageStoreFormat = (): TypeOf<typeof StoreSchema> => {
  return {
    cacheVersion: STORE_SCHEMA_VERSION,
    storeData: {
      deliveryStore: null,
      selectedBrand: null,
      storeId: null,
    },
  }
}

const modifyStoreData = (modifier: Modify<TypeOf<typeof StoreSchema>>) => {
  LocalStorageService.modify<TypeOf<typeof StoreSchema>>(
    modifier,
    newEmptyLocalStorageStoreFormat,
    KEY_STORE_DATA,
    schemaValidator,
  )
}

const saveDeliveryStore = (deliveryStore: DeliveryStore) => {
  modifyStoreData((current) => ({
    ...current,
    storeData: {
      ...current.storeData,
      deliveryStore,
      selectedBrand: deliveryStore.brand,
      storeId: deliveryStore.id,
    },
  }))
}

const saveStoreId = (storeId: string | null): void => {
  modifyStoreData((current) => ({
    ...current,
    storeData: {
      ...current.storeData,
      storeId,
    },
  }))
}

const saveSelectedBrand = (selectedBrand: string | null): void => {
  modifyStoreData((current) => ({
    ...current,
    storeData: {
      ...current.storeData,
      selectedBrand,
    },
  }))
}

const loadDeliveryStore = (): TypeOf<typeof StoreSchema> | null =>
  LocalStorageService.load<TypeOf<typeof StoreSchema>>(KEY_STORE_DATA, schemaValidator)

const reset = (): void => {
  LocalStorageService.clear(KEY_STORE_DATA)
}

export const StoreDAO = {
  loadDeliveryStore,
  localStorageKey: KEY_STORE_DATA,
  reset,
  saveDeliveryStore,
  saveSelectedBrand,
  saveStoreId,
}
