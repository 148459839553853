import type { ApolloClient } from '@apollo/client'
import type { PaymentDetailsResponse } from '@shared/gql/document-nodes'

import { GetPaymentDetailsDocument } from '@shared/gql/document-nodes'
import { DaoService } from 'services/LocalStorage'

interface UpdatePaymentDetailsParams {
  paymentDetailsParams: PaymentDetailsResponse
  apolloClient: ApolloClient<object>
}

type UpdatePaymentDetailsMutation = (params: UpdatePaymentDetailsParams) => void

export const updatePaymentDetails: UpdatePaymentDetailsMutation = ({
  paymentDetailsParams,
  apolloClient,
}) => {
  const { cachedPaymentDetails } =
    apolloClient.cache.readQuery<{ cachedPaymentDetails: PaymentDetailsResponse }>({
      query: GetPaymentDetailsDocument,
    }) || {}

  const paymentDetails = {
    ...cachedPaymentDetails,
    ...paymentDetailsParams,
    __typename: 'PaymentDetailsResponse' as const,
  }

  apolloClient.cache.writeQuery({
    query: GetPaymentDetailsDocument,
    data: { paymentDetails, __typename: 'Query' },
  })
  DaoService.PaymentDAO.savePaymentData(paymentDetails)
}
