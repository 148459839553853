import * as z from 'zod'

/** When bumping this the next time, change it to a simple number, e.g 1. The only purpose of the version is to increment it. */
export const SESSION_SCHEMA_VERSION = '1.2.17'

export const SessionSchema = z.object({
  cacheVersion: z.literal(SESSION_SCHEMA_VERSION),
  clientSession: z.object({
    authTokens: z
      .object({
        __typename: z.literal('AuthenticationTokens'),
        accessToken: z.string().nullable(),
        refreshToken: z.string().nullable(),
        idToken: z.string().nullable(),
        modified: z.number().nullable(),
      })
      .nullable(),
  }),
  authRedirectPath: z.string().nullable(),
})
