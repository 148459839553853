import { useQuery } from '@apollo/client'
import {
  AlcoholSellingStatus,
  GetDeliveryAreaAlcoholSellingStatusDocument,
  GetSelectedAreaIdDocument,
} from '@shared/gql/document-nodes'

/**
 * Return `true` or `false` depending on whether the selected
 * delivery area allows the selling of alcohol,
 * or `null` when loading and 'false' when failed.
 */
export const useAlcoholAllowedForDeliveryArea = (): boolean | null => {
  const { data: selectedAreaIdData } = useQuery(GetSelectedAreaIdDocument)
  const { data, loading } = useQuery(GetDeliveryAreaAlcoholSellingStatusDocument, {
    variables: {
      id: selectedAreaIdData?.selectedAreaId || '',
    },
    skip: !selectedAreaIdData?.selectedAreaId,
  })

  if (loading) {
    return null
  }

  if (data?.deliveryArea) {
    return data.deliveryArea.alcoholSellingAllowed === AlcoholSellingStatus.Allowed
  }

  return false
}
