import { getConfig } from '@shared/config'
import { createClient as createContentfulClient } from 'contentful'

const { buildId, contentful, stage } = getConfig()

export const createClient = () =>
  createContentfulClient({
    ...contentful,
    application: `skaupat-${stage}/${buildId}`,
    retryLimit: 2,
    timeout: 5000,
    removeUnresolved: true,
  })
