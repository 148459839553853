import type { PaymentDetailsResponse, StoreInfo } from '@shared/gql/document-nodes'

import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import {
  GetAvailablePaymentMethodsDocument,
  GetDeliveryDetailsInfoDocument,
  GetPaymentDetailsDocument,
  SetStoreSelectionTemplateDocument,
} from '@shared/gql/document-nodes'
import { useCallback } from 'react'
import { emptyDeliveryDetailsInfo, emptyPaymentDetails } from 'services/App/empty-initial-states'
import { pendingClientCartItemsVar, pendingProductVar } from 'services/ClientCart/cache'
import { useSetCartItemCount } from 'services/ClientCart/mutations/set-cart-item-count'
import { useSetCartItems } from 'services/ClientCart/mutations/set-cart-items'
import { pendingDeliveryMethodVar } from 'services/Delivery/cache'
import {
  useCancelDeliverySlotReservation,
  useDeliverySlotReservationId,
} from 'services/DeliverySlot/hooks'
import { DeliverySlotReleaseReason } from 'services/DeliverySlot/tracking'
import { updatePaymentDetails } from 'services/Payment/mutations/update-payment-details'
import { useResetOverrideBrand } from 'utils/brand/override-brand'
import { writeSelectedStoreCookie } from 'utils/cookie'
import { commonLogger } from 'utils/log/common-logger'

import { useCustomerType } from '../account/use-customer-type'
import { useGetCachedRemoteUserProfile } from '../account/use-get-remote-user-profile'
import { URL_PARAM_PICKUP } from '../use-open-delivery-modal'
import { useURLSearchParams } from '../use-url-search-params'
import { selectDefaultPaymentMethod } from './default-payment-method'

type UseStoreChange = (params: UseStoreChangeParams) => Promise<void>

type UseStoreChangeParams = {
  areaId: string
  selectedStore: Pick<StoreInfo, 'id' | 'name' | 'brand'>
  postalCode: string
  city: string | null
}

export const useStoreChange = (): UseStoreChange => {
  const pendingMethod = useReactiveVar(pendingDeliveryMethodVar)
  const setItemCount = useSetCartItemCount()
  const setCartItems = useSetCartItems()

  const [setStoreSelectionTemplate] = useMutation(SetStoreSelectionTemplateDocument)
  const apolloClient = useApolloClient()

  const remoteUserProfile = useGetCachedRemoteUserProfile()
  const pendingProduct = useReactiveVar(pendingProductVar)
  // pending products in a reactive var that has
  // the product that was attempted to be added before store is selected
  const addPossiblePendingProducts = useCallback(async (): Promise<void> => {
    if (pendingProduct) {
      await setItemCount(pendingProduct.productId, pendingProduct.amount, pendingProduct.storeId)
      pendingProductVar(null)
    }
  }, [setItemCount, pendingProduct])

  const pendingClientCartItems = useReactiveVar(pendingClientCartItemsVar)
  const addPossiblePendingClientCartItems = useCallback(async () => {
    if (pendingClientCartItems) {
      await setCartItems(pendingClientCartItems)
      pendingClientCartItemsVar(null)
    }
  }, [pendingClientCartItems, setCartItems])

  const [getAvailablePaymentMethods] = useLazyQuery(GetAvailablePaymentMethodsDocument)

  const customerType = useCustomerType()

  const { data } = useQuery(GetPaymentDetailsDocument)
  const paymentDetails = data?.paymentDetails || emptyPaymentDetails()

  const resetOverrideBrand = useResetOverrideBrand()

  const { removeParams } = useURLSearchParams({ push: false, shallow: false })

  const deliverySlotReservationId = useDeliverySlotReservationId()
  const cancelDeliverySlotReservation = useCancelDeliverySlotReservation()

  return useCallback(
    async ({ selectedStore, areaId, postalCode, city }): Promise<void> => {
      const { id, name, brand } = selectedStore

      if (!id) {
        throw new Error('Can not set store correctly without id data')
      }

      if (!brand) {
        throw new Error('Can not set store correctly without brand data')
      }

      if (!areaId) {
        throw new Error('Can not set store correctly without areaId')
      }

      // Quick fix: VOIK-5645 Address information needs to be filled every time
      // to help out most of the customers before we have the actual fix
      // VOIK-5064 - use CAS adress, which includes ability to add multiple addresses
      const useCasAddress =
        postalCode !== '' &&
        !!remoteUserProfile &&
        remoteUserProfile?.address?.postalCode === postalCode

      try {
        writeSelectedStoreCookie(id)

        if (deliverySlotReservationId) {
          await cancelDeliverySlotReservation(
            deliverySlotReservationId,
            DeliverySlotReleaseReason.STORE_CHANGE,
          )
        }

        /**
         * There might an override brand/theme active,
         * from the URL param eg. `?brand=herkku.
         * Make sure it's reset before changing the store.
         */
        await resetOverrideBrand()

        const cachedDeliveryDetails = apolloClient.readQuery({
          query: GetDeliveryDetailsInfoDocument,
        })?.deliveryDetailsInfo

        await setStoreSelectionTemplate({
          variables: {
            ...emptyDeliveryDetailsInfo(),
            ...cachedDeliveryDetails,
            ...(useCasAddress && {
              address: remoteUserProfile.address?.apartmentNumber
                ? `${remoteUserProfile?.address?.streetAddress} ${remoteUserProfile.address.apartmentNumber}`
                : remoteUserProfile.address?.streetAddress,
              city: remoteUserProfile?.address?.postalCity,
            }),
            areaId,
            brand,
            city,
            deliveryDate: null,
            deliveryMethod: pendingMethod,
            deliverySlotId: null,
            deliverySlotPrice: null,
            deliveryTime: null,
            id,
            name,
            postalCode,
          },
        })

        await addPossiblePendingProducts()
        await addPossiblePendingClientCartItems()

        const paymentdata = await getAvailablePaymentMethods({
          variables: { storeId: selectedStore.id },
        })

        const defaultPaymentMethod = selectDefaultPaymentMethod({
          availablePaymentMethods: paymentdata?.data?.store?.availablePaymentMethods,
          customerType,
        })

        const paymentDetailsParams: PaymentDetailsResponse = {
          ...paymentDetails,
          paymentMethod: defaultPaymentMethod,
        }

        updatePaymentDetails({
          paymentDetailsParams,
          apolloClient,
        })

        // Re-run any `getServerSideProps` methods to update brand-based content,
        // and remove possible `?nouto` and `&theme=herkku` from URL
        await removeParams([URL_PARAM_PICKUP, undefined], ['theme', undefined])
      } catch (error) {
        commonLogger.errorSync({
          error,
          message: 'Failed to change store',
          data: { storeId: id, name, brand, postalCode },
        })
      }
    },
    [
      addPossiblePendingClientCartItems,
      addPossiblePendingProducts,
      apolloClient,
      cancelDeliverySlotReservation,
      customerType,
      deliverySlotReservationId,
      getAvailablePaymentMethods,
      paymentDetails,
      pendingMethod,
      remoteUserProfile,
      removeParams,
      resetOverrideBrand,
      setStoreSelectionTemplate,
    ],
  )
}
