import type { CartItem } from '@shared/gql/document-nodes'

import { ProductType } from '@shared/gql/document-nodes'

export const getServiceFee = (cartItems: CartItem[]): CartItem | undefined => {
  if (!cartItems) {
    throw new Error('Cannot filter service items. Cart item array is undefined')
  }
  return cartItems.find((cartItem) => cartItem.productType === ProductType.ServiceFee)
}
