import type { GetPaymentDetailsQuery } from '@shared/gql/document-nodes'
import type { MutationResolvers } from 'types/gql/type-defs'

import { GetPaymentDetailsDocument } from '@shared/gql/document-nodes'
import { emptyPaymentDetails } from 'services/App/empty-initial-states'
import { DaoService } from 'services/LocalStorage'

export const updatePaymentMethodDetails: MutationResolvers['updatePaymentMethodDetails'] = (
  _,
  args,
  { cache },
) => {
  const cachedPaymentDetails = cache.readQuery({ query: GetPaymentDetailsDocument })?.paymentDetails

  const paymentDetails: GetPaymentDetailsQuery['paymentDetails'] = {
    ...emptyPaymentDetails(),
    ...cachedPaymentDetails,
    ...args,
  }

  cache.writeQuery({
    query: GetPaymentDetailsDocument,
    data: { __typename: 'Query', paymentDetails },
  })

  DaoService.PaymentDAO.savePaymentData(paymentDetails)

  return null
}

export const updateStockmannCardNumber: MutationResolvers['updateStockmannCardNumber'] = (
  _,
  args,
  { cache },
) => {
  const cachedPaymentDetails = cache.readQuery({
    query: GetPaymentDetailsDocument,
  })?.paymentDetails

  const paymentDetails: GetPaymentDetailsQuery['paymentDetails'] = {
    ...emptyPaymentDetails(),
    ...cachedPaymentDetails,
    ...args,
  }

  cache.writeQuery({
    query: GetPaymentDetailsDocument,
    data: {
      __typename: 'Query',
      paymentDetails: {
        ...paymentDetails,
      },
    },
  })

  DaoService.PaymentDAO.savePaymentData(paymentDetails)

  return null
}
