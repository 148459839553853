import type { FC } from 'react'

import { getConfig } from '@shared/config'
import { DefaultSeo } from 'next-seo'
import { useTheme } from 'styled-components'
import { useCanonicalUrl } from 'utils/hooks/use-canonical-url'

const { stage } = getConfig()

/** @warn Very dangerously force "robots: noindex" to make sure Preview/TEST/QA are hidden from Google */
const DANGEROUSLY_FORCE_ROBOTS_NOINDEX = stage === 'preview' || stage === 'test' || stage === 'qa'

export const DefaultNextSeo: FC = () => {
  const { seoTags } = useTheme()
  const canonical = useCanonicalUrl()

  return (
    <DefaultSeo
      {...seoTags}
      canonical={canonical}
      dangerouslySetAllPagesToNoIndex={DANGEROUSLY_FORCE_ROBOTS_NOINDEX}
    />
  )
}
