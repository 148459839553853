import { isNonNullable } from './nullable'

type WithoutNullableFields<T extends object> = {
  [K in keyof T]: NonNullable<T[K]>
}

/**
 * From input object, return copy without any fields
 * with `null` or `undefined` values. Useful for creating
 * serializable data.
 *
 * @example
 * removeNullableFIelds({ id: 0, value: null }) // { id: 0 }
 */
export const removeNullableFields = <T extends object>(object: T): WithoutNullableFields<T> =>
  Object.entries(object).reduce((result, entry) => {
    /** Object.entries() returns generic [string, any] */
    const [key, value] = entry as [keyof T, T[keyof T]]

    if (isNonNullable(value)) {
      result[key] = value
    }

    return result
  }, {} as WithoutNullableFields<T>)
