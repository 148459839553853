import type { FC } from 'react'

import { SIDE_PANEL_VISIBILITY_KEY } from './cache'

const INLINE_TOGGLE_ID = 'hide-sidepanel'
const inlineToggleScript = (isVisible: boolean) => `
((d) => {
  try {
    var v = localStorage.getItem('${SIDE_PANEL_VISIBILITY_KEY}')
    document.getElementById('${INLINE_TOGGLE_ID}').checked = v === null ? !d : (v === 'false')
  } catch {}
})(${isVisible ? 'true' : 'false'})
`

// Input and inline script are used to toggle cart visibility as soon as possible when status can be read from local storage.
export const SidepanelInlineToggle: FC<{ isVisible: boolean }> = ({ isVisible }) => {
  return (
    <>
      <input
        type="checkbox"
        id={INLINE_TOGGLE_ID}
        style={{ display: 'none' }}
        checked={!isVisible}
        readOnly
      />
      {/* eslint-disable-next-line react/no-danger */}
      <script dangerouslySetInnerHTML={{ __html: inlineToggleScript(isVisible) }} />
    </>
  )
}
