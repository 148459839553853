import type { FC } from 'react'

import { ArrowLeftOutline24, Button, ButtonIconPosition, ButtonType } from '@sok/design-system'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const _BackToOrderHistoryLink: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <Link href="/tilaus/historia" passHref>
      <Button
        type={ButtonType.LINK}
        className={className}
        data-test-id=""
        iconPosition={ButtonIconPosition.LEFT}
        icon={<ArrowLeftOutline24 />}
        text={t('My Orders')}
      />
    </Link>
  )
}

export const BackToOrderHistoryLink = styled(_BackToOrderHistoryLink)({
  padding: '0 !important',
})
