import * as z from 'zod'

/** When bumping this the next time, change it to a simple number, e.g 1. The only purpose of the version is to increment it. */
export const STORE_SCHEMA_VERSION = '1.2.17'

export const StoreSchema = z.object({
  cacheVersion: z.literal(STORE_SCHEMA_VERSION),
  storeData: z.object({
    deliveryStore: z
      .object({
        __typename: z.string(),
        brand: z.string().nullable(),
        id: z.string(),
        name: z.string().nullable(),
        areaId: z.string().nullable(),
      })
      .nullable(),
    storeId: z.string().nullable(),
    selectedBrand: z.string().nullable(),
  }),
})
