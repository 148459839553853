import type { i18n as I18n } from 'i18next'

import { I18nLang } from '@shared/domain/i18n'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import cmsFi from './translations/cms/fi.json'
import defaultEt from './translations/default/et.json'
import defaultFi from './translations/default/fi.json'
import defaultSv from './translations/default/sv.json'

export const DEFAULT_NS = 'default'
export const CMS_NS = 'cms'

export const initI18n = (locale?: I18nLang | null): I18n => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng: locale || I18nLang.ET,
      supportedLngs: [I18nLang.ET, I18nLang.FI, I18nLang.SV],
      fallbackLng: I18nLang.FI,
      defaultNS: DEFAULT_NS,
      resources: {
        [I18nLang.ET]: {
          [DEFAULT_NS]: defaultEt,
        },
        [I18nLang.FI]: {
          [DEFAULT_NS]: defaultFi,
          [CMS_NS]: cmsFi,
        },
        [I18nLang.SV]: {
          [DEFAULT_NS]: defaultSv,
        },
      },
      nsSeparator: ':',
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    })

  return i18n
}
