import { IS_BROWSER } from 'utils/is-browser'

/**
 * Get the current position from `window.navigator.geolocation.getCurrentPosition`
 * wrapped in a Promise.
 *
 * @example
 * try {
 *   const position = await getCurrentPosition({
 *     enableHighAccuracy: false,
 *     timeout: 2000
 *   })
 *
 *   console.log('Current position is:', position)
 * } catch {
 *   console.error('Failed to get current position')
 * }
 */
export const getCurrentPosition = (options: PositionOptions): Promise<GeolocationPosition> =>
  new Promise((resolve, reject) => {
    if (!IS_BROWSER) reject(new Error('Cannot get current position server-side!'))

    window.navigator?.geolocation?.getCurrentPosition?.(
      (position) => resolve(position),
      (error) => reject(error),
      options,
    )
  })
