import { SDS_FONT_FAMILY_PRIMARY } from '@s-group/design-system-tokens/web/tokens/font.es6'
import { createGlobalStyle } from 'styled-components'
import theme from 'themes/s-kaupat'

export const DefaultGlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%;

    @media print {
      height: auto;
    }
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  * {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: ${SDS_FONT_FAMILY_PRIMARY};
    height: 100%;
    color: ${theme.color.fontBlack};

    > iframe[src*="s-cloud.fi"] {
      display: none;
    }
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  ::placeholder {
    font-family: ${SDS_FONT_FAMILY_PRIMARY};
  }

  .desktop-only {
    @media (max-width: ${theme.breakpoints.tablet}) {
      display: none !important;
    }
  }

  .desktop-hide {
    @media (min-width: calc(${theme.breakpoints.tablet} + 1px)) {
      display: none !important;
    }
  }

  .text-align--center {
    text-align: center;
  }
`
