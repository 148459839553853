import type { DeliverySlotReleaseReason } from '../tracking'

import { useMutation } from '@apollo/client'
import { ReleaseDeliverySlotReservationDocument } from '@shared/gql/document-nodes'

import { trackDeliverySlotReleased } from '../tracking'
import { useClearDeliverySlotReservationFromCache } from './use-clear-delivery-slot-reservation-from-cache'

/** Returns a callback for releasing the currently reserved delivery slot */
export const useCancelDeliverySlotReservation = () => {
  const clearReservationFromCache = useClearDeliverySlotReservationFromCache()
  const [releaseDeliverySlotReservation] = useMutation(ReleaseDeliverySlotReservationDocument)

  return async (reservationId: string, reason: DeliverySlotReleaseReason) => {
    try {
      clearReservationFromCache(reservationId)

      /** Release reservation from backend. This will fail if already expired. */
      await releaseDeliverySlotReservation({
        variables: { reservationId },
      })

      /** Analytics only sent if above succeeds */
      trackDeliverySlotReleased(reason)
    } catch {
      /** @todo [VOIK-9507] add some error... maybe? */
    }
  }
}
