import type { AssertedSearchDeliveryAreasQueryDeliveryArea } from '../utils/types'
import type { QueryHookOptions } from '@apollo/client'
import type {
  SearchDeliveryAreasQuery,
  SearchDeliveryAreasQueryVariables,
} from '@shared/gql/document-nodes'
import type { DeliveryOption } from 'domain/delivery-option'

import { useQuery } from '@apollo/client'
import { Brand, getBrandByBrandName } from '@shared/domain/brand'
import { DeliveryMethod, SearchDeliveryAreasDocument } from '@shared/gql/document-nodes'
import { useMemo } from 'react'
import { useGeolocationPosition } from 'services/Delivery/hooks/use-geolocation-position'
import { useDebounce } from 'use-debounce'
import { useGetCachedRemoteUserProfile } from 'utils/hooks/account/use-get-remote-user-profile'
import { removeNullableFields } from 'utils/remove-nullable-fields'

import { isValidDeliveryArea } from '../utils/is-valid-delivery-area'
import { useStoreAvailabilityByPendingProductEan } from './use-store-availability-by-pending-product-ean'

/**
 * Backend won't return slots for fast track areas when they're closed
 *
 * @todo [VOIK-9091] this should be removed and filtering done in Elasticsearch;
 * requires indexed slots
 */
const removeEmptyFastTrackAreas = (option: DeliveryOption) =>
  !(option.isFastTrack && !option.nextDeliverySlot)

const mapDeliveryAreaToDeliveryOption = (
  deliveryArea: AssertedSearchDeliveryAreasQueryDeliveryArea,
): DeliveryOption => ({
  ...deliveryArea,
  homeDeliveryType: deliveryArea.homeDeliveryType ?? undefined,
  price: deliveryArea.price ?? undefined,
  description: deliveryArea.description ?? undefined,
  store: {
    id: deliveryArea.store.id,
    brand: getBrandByBrandName(deliveryArea.store.brand) || Brand.Eprisma,
    name: deliveryArea.store.name,
  },
  address: deliveryArea.address ?? undefined,
  districts: deliveryArea.districts?.map(({ city, postalCode }) => ({
    __typename: 'DeliveryDistrict',
    city: city || undefined,
    postalCode,
  })),
  isFastTrack: !!deliveryArea.isFastTrack,
  nextDeliverySlot: deliveryArea.nextDeliverySlot
    ? {
        ...deliveryArea.nextDeliverySlot,
        estimatedFastTrackTime: deliveryArea.nextDeliverySlot.estimatedFastTrackTime ?? 0,
      }
    : undefined,
})

interface UseDeliveryOptionSelectorState {
  deliveryMethod: DeliveryMethod
  currentSearchTerm: string
}

// Exported for unit-test
export interface DeliveryOptionSelectorState {
  deliveryOptions: DeliveryOption[]
  isLoading: boolean
  isNotFound: boolean
}

export const useDeliveryOptionSearch = ({
  deliveryMethod,
  currentSearchTerm,
}: UseDeliveryOptionSelectorState): DeliveryOptionSelectorState => {
  // trigger user profile query, so we can populate tags property before fetching delivery areas
  useGetCachedRemoteUserProfile()

  const geolocationPosition = useGeolocationPosition()
  const latitude =
    geolocationPosition.type === 'SUCCESS' ? geolocationPosition.data.coords.latitude : null
  const longitude =
    geolocationPosition.type === 'SUCCESS' ? geolocationPosition.data.coords.longitude : null

  const isHomeDelivery = deliveryMethod === DeliveryMethod.HomeDelivery

  const queryOptions = useMemo<
    QueryHookOptions<SearchDeliveryAreasQuery, SearchDeliveryAreasQueryVariables>
  >(() => {
    const variables = removeNullableFields({
      deliveryMethod,
      freetext: isHomeDelivery ? null : currentSearchTerm,
      location: latitude && longitude ? { latitude, longitude } : null,
      postalCode: isHomeDelivery ? currentSearchTerm : null,
      storeId: '' /** used to limit result to a single store, but we want to search for all */,
    })

    /** To enable the query we need either a geolocation, or a freetext/postalcode depending on delivery method. */
    const skip = isHomeDelivery
      ? (variables.postalCode || '')?.trim().length < 2
      : !variables.location && (variables.freetext || '')?.trim().length < 2

    return {
      variables,
      skip,
      fetchPolicy: 'network-only' /** Make sure next slot times are not cached */,
    }
  }, [currentSearchTerm, deliveryMethod, isHomeDelivery, latitude, longitude])

  const [debouncedQueryOptions] = useDebounce(queryOptions, 500)

  const searchDeliveryAreasResult = useQuery(SearchDeliveryAreasDocument, debouncedQueryOptions)

  const availabilityByEan = useStoreAvailabilityByPendingProductEan()

  const deliveryOptions = useMemo(() => {
    if (debouncedQueryOptions.skip) return []

    const mappedDeliveryOptions = (searchDeliveryAreasResult.data?.searchDeliveryAreas?.areas ?? [])
      .filter(isValidDeliveryArea)
      // Map to DeliveryOption type, which is more restrictive and friendlier to use in the components
      // Compared to the GraphQL generated ones. DeliveryOption is derived from GraphQL types.
      .map(mapDeliveryAreaToDeliveryOption)
      .filter(removeEmptyFastTrackAreas)

    if (availabilityByEan.type === 'SUCCESS') {
      return mappedDeliveryOptions.filter((option) => availabilityByEan.data.has(option.storeId))
    }

    return mappedDeliveryOptions
  }, [
    availabilityByEan,
    debouncedQueryOptions.skip,
    searchDeliveryAreasResult.data?.searchDeliveryAreas?.areas,
  ])

  const isLoading =
    geolocationPosition.type === 'LOADING' ||
    searchDeliveryAreasResult.loading ||
    availabilityByEan.type === 'LOADING'

  const isSearched = searchDeliveryAreasResult.called

  const isNotFound = deliveryOptions.length === 0 && !isLoading && isSearched

  return { deliveryOptions, isLoading, isNotFound }
}
