import type { DeliveryOption } from 'domain/delivery-option'
import type { FC } from 'react'

import { EuroCents } from '@shared/domain/euro-cents'
import { SlotAvailability } from '@shared/gql/document-nodes'
import {
  getDiffInDays,
  toFormattedDate,
  toFormattedDuration,
  toFormattedTime,
  toMaybeFormattedRelativeWeekday,
} from 'domain/date-time'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { capitalize } from 'utils/common/capitalize'

export type NextAvailableSlotType = Pick<
  NonNullable<DeliveryOption['nextDeliverySlot']>,
  'availability' | 'estimatedFastTrackTime' | 'isClosed' | 'isFastTrack' | 'price' | 'startDateTime'
>

export const useNextSlotLabel = (slot?: NextAvailableSlotType | null) => {
  const { t } = useTranslation()

  const nextSlotLabel = useMemo(() => {
    if (!slot) {
      return null
    }

    if (slot.isFastTrack) {
      if (isSlotOverCapacity(slot)) {
        return t('Temporarily over-capacity')
      }

      if (slot.estimatedFastTrackTime && !slot.isClosed) {
        return t('Time estimate from order', {
          estimate: toFormattedDuration({ minutes: slot.estimatedFastTrackTime }),
        })
      }
    }

    const date =
      toMaybeFormattedRelativeWeekday(slot.startDateTime) ||
      toFormattedDate(slot.startDateTime, { year: false })
    const startTime = toFormattedTime(slot.startDateTime)

    return capitalize(
      t(slot.isFastTrack ? 'Available next time' : 'Available slots on date', {
        date,
        startTime,
      }),
    )
  }, [slot, t])

  return nextSlotLabel
}

const _NextAvailableSlot: FC<{ className?: string; slot: NextAvailableSlotType }> = ({
  className,
  slot,
}) => {
  const nextSlotLabel = useNextSlotLabel(slot)

  return (
    <div className={className}>
      <p>
        <time data-test-id="delivery-option-next-slot-time" dateTime={slot.startDateTime}>
          {nextSlotLabel}
        </time>
      </p>

      {slot.isFastTrack ? (
        <p data-test-id="delivery-option-next-slot-price">
          {EuroCents.fromEuros(slot.price).format()}
        </p>
      ) : null}
    </div>
  )
}

const isSlotOverCapacity = (slot: NextAvailableSlotType): boolean =>
  slot.isFastTrack && slot.availability === SlotAvailability.Full

const isClosedFastTrack = (slot: NextAvailableSlotType): boolean =>
  slot.isFastTrack && slot.isClosed

const isNextSlotToday = (slot: NextAvailableSlotType): boolean =>
  getDiffInDays(slot.startDateTime) < 1

export const NextAvailableSlot = styled(_NextAvailableSlot)(({ slot, theme }) => ({
  ...theme.variants.body1.regular,
  color: 'inherit',
  display: 'flex',
  flexDirection: 'column',

  time: {
    color:
      /** Green with good availability "today", and dark grey otherwise */
      isNextSlotToday(slot) && !isSlotOverCapacity(slot) && !isClosedFastTrack(slot)
        ? theme.colors.success500
        : 'inherit',
  },
}))
