import type { Dispatch, FC, SetStateAction } from 'react'

import { Button } from '@s-group/design-system-components'
import {
  SDS_SPACE_MEDIUM,
  SDS_SPACE_SMALL,
  SDS_SPACE_XLARGE,
  SDS_SPACE_XSMALL,
  SDS_SPACE_XXSMALL,
  SDS_SPACE_XXXLARGE,
} from '@s-group/design-system-tokens/web/tokens/space.es6'
import { minWidthFromTheme, Modal } from '@sok/design-system'
import { PageTitle } from 'components/common/Titles'
import { Icon } from 'components/icons/Icon'
import { useTranslation } from 'react-i18next'
import { closeDeliveryModal } from 'services/Delivery/cache'
import styled, { css } from 'styled-components'
import { useGetProfileActionHandlers } from 'utils/hooks/account/use-get-profile-action-handlers'

import { setOpenDeliveryTimeslotAfterLogin } from './delivery-timeslot-after-login'
import { setShouldSkipDeliveryLogin } from './skip-delivery-login'

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const LoginModal: FC<Props> = ({ setIsOpen }) => {
  const { t } = useTranslation()
  const { onLoginClick, onRegisterClick } = useGetProfileActionHandlers()

  const handleOnClick = () => {
    setOpenDeliveryTimeslotAfterLogin()
    onLoginClick()
  }

  const loginSkipHandler = () => {
    setIsOpen(false)
    setShouldSkipDeliveryLogin()
  }

  const cancelHandler = () => {
    loginSkipHandler()
    closeDeliveryModal()
  }

  return (
    <CustomModal
      closeLabel={t('Close')}
      data-test-id="delivery-select-modal"
      disableFocusTrap
      footer={null}
      onCancel={cancelHandler}
      title=""
      visible
    >
      <LoginForTimeSelectionStyles data-test-id="slot-reservation-login-modal">
        <LoginModalContent>
          <figure>
            <img src="/icons/reserved-time.svg" alt="cart-icon" width="104" height="104" />
          </figure>
          <PageTitle data-test-id="page-title">{t`Book a suitable time`}</PageTitle>
          <p data-test-id="page-content">{t`Log in for time selection description`}</p>
          <LoginButtonContainer
            data-test-id="login-button"
            primary
            rounding="small"
            onClick={handleOnClick}
          >
            {t`Log in with S-ID`}
          </LoginButtonContainer>
          <LoginButtonContainer
            rounding="small"
            onClick={onRegisterClick}
          >{t`Create account`}</LoginButtonContainer>
          <BonusInfoContainer>
            <Icon name="s-bonus-logo" width={24} height={24} />
            <BonusText>{t`You get bonus from us`}</BonusText>
          </BonusInfoContainer>
        </LoginModalContent>

        <CustomButton plain rounding="small" data-test-id="skip-login" onClick={loginSkipHandler}>
          {t('Skip')}
        </CustomButton>
      </LoginForTimeSelectionStyles>
    </CustomModal>
  )
}

LoginModal.displayName = 'LoginModal'

const LoginModalContent = styled.div(({ theme }) => ({
  padding: `${SDS_SPACE_SMALL} ${SDS_SPACE_SMALL}`,
  [minWidthFromTheme(theme).tablet]: {
    padding: `${SDS_SPACE_XLARGE} ${SDS_SPACE_XXXLARGE}`,
  },
  display: 'flex',
  gap: SDS_SPACE_MEDIUM,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'center',
  flex: 1,
}))

const CustomModal = styled(Modal)(
  ({ theme }) => css`
    @media (min-width: ${theme.breakpoints.tablet}) {
      max-width: 480px;
    }
  `,
)

const CustomButton = styled(Button)({
  margin: SDS_SPACE_XXSMALL,
})

const LoginForTimeSelectionStyles = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
})

const LoginButtonContainer = styled(Button)({
  width: '100%',
  maxWidth: 320,
})

const BonusInfoContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: SDS_SPACE_XSMALL,
  maxWidth: 320,
})

const BonusText = styled.p({
  textAlign: 'left',
})
