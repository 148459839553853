import type { Slot } from 'domain/delivery-details'

import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GetDeliveryDetailsInfoDocument,
  UpdateDeliveryDetailsDocument,
} from '@shared/gql/document-nodes'
import { toDateTime, toFormattedTime } from 'domain/date-time'
import { showGlobalLoadingIndicatorVar } from 'lib/apollo/reactive-vars'
import { setDeliveryModalStage } from 'services/Delivery/cache'
import { DeliverySelectionStage } from 'types/global'
import { getAlcoholSellingAllowed } from 'utils/delivery/get-alcohol-selling-allowed'
import { useGetCartAlcoholItems } from 'utils/hooks/cart'
import { useAlcoholAllowedForDeliveryArea } from 'utils/hooks/delivery/use-alcohol-allowed'
import { trackCustomEvent } from 'utils/tracking/custom-events/custom-events'
import { EventAction, EventCategory } from 'utils/tracking/interfaces/data-layer-events'

import { useIsDeliverySlotReservationEnabled } from './use-is-delivery-slot-reservation-enabled'
import { useReserveDeliverySlot } from './use-reserve-delivery-slot'
import { useSelectedDeliverySlot } from './use-selected-delivery-slot'

export const useSelectDeliverySlot = () => {
  const [getDeliveryDetails] = useLazyQuery(GetDeliveryDetailsInfoDocument)

  const selectedSlot = useSelectedDeliverySlot()
  const isDeliverySlotReservationEnabled = useIsDeliverySlotReservationEnabled()
  const reserveDeliverySlot = useReserveDeliverySlot()
  const [updateDeliveryDetails] = useMutation(UpdateDeliveryDetailsDocument)

  const alcoholItems = useGetCartAlcoholItems()
  const alcoholSellingAllowed = useAlcoholAllowedForDeliveryArea()
  // If delivery area disallows alcohol selling, then timeslot-specific alcohol checks are disabled
  const cartContainsAlcohol = alcoholItems.length > 0 && !!alcoholSellingAllowed

  return async (slot: Slot) => {
    /**
     * No reason to allow selecting already-selected slot again.
     * This also makes sure customers don't accidentally get a different dynamic price.
     *
     * Fast Track selection logic works completely different, so we need to ignore those...
     */
    if (
      !slot.isFastTrack &&
      selectedSlot.type === 'SUCCESS' &&
      selectedSlot.data.slotId === slot.slotId
    ) {
      return
    }

    /**
     * New way for selecting delivery slots by creating a temporary reservation.
     * This is not yet supported for Fast Track areas...
     */
    if (isDeliverySlotReservationEnabled) {
      await reserveDeliverySlot(slot.slotId)
      const alcoholTimeSlotConflict =
        !getAlcoholSellingAllowed(slot.alcoholSellingAllowed) && cartContainsAlcohol

      setDeliveryModalStage(
        alcoholTimeSlotConflict
          ? DeliverySelectionStage.SELECT_DELIVERY_TIME_ALCOHOL_CONFLICT
          : DeliverySelectionStage.SELECT_DELIVERY_TIME,
      )

      // tracking side effect
      trackSlotSelection()
    } else {
      if (slot.isClosed) {
        return
      }
      showGlobalLoadingIndicatorVar(true)
      const query = await getDeliveryDetails()
      const deliveryDetailsInfo = query.data?.deliveryDetailsInfo
      if (!deliveryDetailsInfo) {
        throw new Error('Illegal state. deliveryDetailsInfo is null')
      }
      updateDeliveryDetails({
        variables: {
          ...deliveryDetailsInfo,
          deliveryDate: toDateTime(slot.startDateTime).toISODate(),
          deliveryTime: toFormattedTime(slot.startDateTime),
          deliverySlotId: slot.slotId,
          deliverySlotPrice: slot.price,
        },
      })
        .then(() => {
          const alcoholTimeSlotConflict =
            !getAlcoholSellingAllowed(slot.alcoholSellingAllowed) && cartContainsAlcohol

          if (alcoholTimeSlotConflict) {
            setDeliveryModalStage(DeliverySelectionStage.SELECT_DELIVERY_TIME_ALCOHOL_CONFLICT)
          }

          // tracking side effect
          trackSlotSelection()
        })
        .finally(() => {
          showGlobalLoadingIndicatorVar(false)
        })
    }
  }
}

const trackSlotSelection = () => {
  trackCustomEvent({
    category: EventCategory.DELIVERY_SELECTIONS,
    action: EventAction.SUBMIT,
    label: 'delivery-details',
  })
}
