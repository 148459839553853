import type { Slot } from 'domain/delivery-details'
import type { FC } from 'react'

import { useQuery } from '@apollo/client'
import { Button, Loader } from '@s-group/design-system-components'
import { SDS_BRAND_COLOR_ELEMENT_INVERSE_GREY } from '@s-group/design-system-tokens/web/tokens/sbrand/colors.es6'
import { getConfig } from '@shared/config'
import { GetDeliveryDetailsInfoDocument } from '@shared/gql/document-nodes'
import { toFormattedDate } from 'domain/date-time'
import { getApolloErrorType } from 'lib/is-apollo-404-error'
import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line no-restricted-imports
import { closeDeliveryModal, setDeliveryModalStage } from 'services/Delivery/cache'
import { useGetDeliverySlotsFromAreaQuery } from 'services/Delivery/hooks/use-get-delivery-slots-from-area-query'
import { useSelectedDeliverySlot } from 'services/DeliverySlot/hooks'
import { useSelectDeliverySlot } from 'services/DeliverySlot/hooks/use-select-delivery-slot'
import {
  DeliverySlotReservationStatus,
  deliverySlotReservationStatusVar,
} from 'services/DeliverySlot/status'
import { handleRequestErrors } from 'services/error-handler'
import { addSnackbar } from 'services/Snackbar/Snackbars'
import styled, { css } from 'styled-components'
import { DeliverySelectionStage } from 'types/global'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'
import { useSelectedDeliveryAreaDetails } from 'utils/hooks/use-selected-delivery-area-name'
import { useSelectedStoreName } from 'utils/hooks/use-selected-store-name'
import { useGetOrderEditState } from 'utils/order/use-get-order-edit-state'
import { StyledLoaderContainer } from 'views/order/styled-order-page'

import { type CalendarDays, mergeCalendar, populateCalendar } from './calendar'
import { CurrentSelectionInfo } from './CurrentSelectionInfo'
import { DeliveryTimeSlotSelector } from './DeliveryTimeSlotSelector'
import { Notifications } from './Notifications'
import { TimeSlotsError } from './TimeSlotsError'

const WEEKS_TO_SHOW = 4 // Number of weeks to show in the calendar.
const DAYS_IN_CALENDAR = WEEKS_TO_SHOW * 7

interface Props {
  cartContainsAlcohol: boolean
  reservationError?: boolean
}

const { featureFlags } = getConfig()

const useDeliveryContext = () => {
  const { storeName } = useSelectedStoreName()
  const { loading: deliveryAreaLoading, deliveryAreaName } = useSelectedDeliveryAreaDetails()
  const { data: deliveryDetailsData } = useQuery(GetDeliveryDetailsInfoDocument)
  const { data } = useQuery(GetDeliveryDetailsInfoDocument)
  const deliveryDetailsInfo = data?.deliveryDetailsInfo
  const deliveryMethod = deliveryDetailsInfo?.deliveryMethod
  const postalCode = deliveryDetailsData?.deliveryDetailsInfo?.postalCode
  if (!deliveryDetailsInfo) {
    throw new Error('Illegal state. deliveryDetailsInfo is null')
  }
  if (!deliveryMethod) {
    throw new Error('DeliveryContextProvider requires a valid delivery method')
  }
  return {
    storeName,
    deliveryMethod,
    deliveryDetailsInfo,
    deliveryAreaLoaded: !deliveryAreaLoading,
    deliveryAreaName: deliveryAreaName || '',
    deliveryPostalCode: postalCode,
  }
}

const isManageableError = (error: Error) =>
  ['FeatureNotEnabledError', 'SlotClosedError'].includes(getApolloErrorType(error))

export const DeliveryTimeSelectionContainer: FC<Props> = ({
  cartContainsAlcohol,
  reservationError,
}) => {
  const orderEditState = useGetOrderEditState()
  const { storeName, deliveryAreaName, deliveryMethod, deliveryDetailsInfo } = useDeliveryContext()
  const [isConfirmingSelection, setIsConfirmingSelection] = useState(false)
  const confirmedSelectedSlot = useSelectedDeliverySlot()
  const [selectedSlotState, setSelectedSlotState] = useState<Slot | null>(
    confirmedSelectedSlot.type === 'SUCCESS' ? confirmedSelectedSlot.data : null,
  )
  const isLoggedIn = useIsLoggedIn()
  const updateSlotSelect = useSelectDeliverySlot()
  const onSlotSelect = (x: Slot) => {
    if (!isConfirmingSelection) {
      setSelectedSlotState(x)
    }
  }

  const { t } = useTranslation()
  const [calendar, setCalendar] = useState<CalendarDays | null>(null)

  const today = DateTime.now().startOf('day')
  const { refetch, error: slotLoadingError } = useGetDeliverySlotsFromAreaQuery(
    today,
    today.plus({ days: DAYS_IN_CALENDAR - 1 }),
    (response) => {
      if (response?.deliveryArea) {
        setCalendar(
          mergeCalendar([response?.deliveryArea], populateCalendar(today, DAYS_IN_CALENDAR)),
        )
      }
    },
  )
  const isFetchingSlots = calendar === null

  const handleSlotSelectConfirm = useCallback(async () => {
    setIsConfirmingSelection(true)
    if (selectedSlotState) {
      try {
        await updateSlotSelect(selectedSlotState)

        const context =
          featureFlags.slotReservationInAllStoresForLoggedInUsers && isLoggedIn
            ? `AUTHENTICATED_${deliveryMethod}`
            : `UNAUTHENTICATED_${deliveryMethod}`

        addSnackbar({
          text: t('SlotSelected', {
            context,
            date: toFormattedDate(selectedSlotState.modificationTimestamp || ''),
          }),
        })

        closeDeliveryModal()
      } catch (e) {
        const error = e as Error
        if (error && isManageableError(error)) {
          // Missing analytics for now.
          refetch()
          setSelectedSlotState(null)
          deliverySlotReservationStatusVar(DeliverySlotReservationStatus.OK)
          setDeliveryModalStage(DeliverySelectionStage.SELECT_DELIVERY_TIME_RESERVATION_ERROR)
        } else {
          handleRequestErrors([error])
        }
      }
    }
    setIsConfirmingSelection(false)
  }, [deliveryMethod, isLoggedIn, refetch, selectedSlotState, t, updateSlotSelect])

  const isSlotSelected = !!selectedSlotState
  const loggedInButtonText = isSlotSelected ? t('Reserve time') : t('Choose time')
  const confirmTimeButtonText =
    featureFlags.slotReservationInAllStoresForLoggedInUsers && isLoggedIn
      ? loggedInButtonText
      : t('Choose time')

  const selectedSlotDate =
    (confirmedSelectedSlot.type === 'SUCCESS' &&
      DateTime.fromISO(confirmedSelectedSlot.data.startDateTime).toISODate()) ||
    undefined

  const noAvailableSlotsOrError =
    !!slotLoadingError ||
    (calendar && !Object.keys(calendar).some((day) => calendar[day].length > 0))

  // Logic for disabling the SELECT SLOT button.
  const selectedSlotId =
    confirmedSelectedSlot.type === 'SUCCESS' ? confirmedSelectedSlot.data.slotId : undefined
  const isLoadingInTheBackground = isFetchingSlots || isConfirmingSelection
  const isSlotUnchanged = selectedSlotId && selectedSlotState?.slotId === selectedSlotId
  const isButtonDisabled = isLoadingInTheBackground || isSlotUnchanged || !isSlotSelected

  return (
    <StyledDeliveryTimeSelection data-test-id="delivery-time-selection">
      {deliveryMethod && (
        <CurrentSelectionInfo
          deliveryMethod={deliveryMethod}
          isStoreSelectionDisabled={orderEditState.isOrderEditActive}
          deliveryAreaName={deliveryAreaName}
          storeName={storeName}
          isSlotSelected={isSlotSelected}
        />
      )}

      {noAvailableSlotsOrError ? (
        <>
          {deliveryDetailsInfo.deliveryMethod && (
            <TimeSlotsError deliveryMethod={deliveryDetailsInfo.deliveryMethod} />
          )}
          <ControlWrapper>
            <Button
              primary
              rounding="small"
              onClick={() => setDeliveryModalStage(DeliverySelectionStage.SELECT_METHOD_AND_STORE)}
              data-test-id="btn-store-selection"
            >
              {t('Switch stores')}
            </Button>
          </ControlWrapper>
        </>
      ) : (
        <>
          {calendar ? (
            <DeliveryTimeSlotSelector
              loading={isFetchingSlots}
              cartContainsAlcohol={cartContainsAlcohol}
              calendar={calendar}
              deliveryDetailsInfo={deliveryDetailsInfo}
              onSlotSelect={onSlotSelect}
              selectedSlotDate={selectedSlotDate}
              selectedSlotId={selectedSlotState?.slotId}
            />
          ) : (
            <StyledLoaderContainer>
              <Loader size="medium" />
            </StyledLoaderContainer>
          )}
          <ControlWrapper>
            <Button
              disabled={isButtonDisabled}
              primary
              rounding="small"
              onClick={handleSlotSelectConfirm}
              data-test-id="btn-select-slot"
            >
              {confirmTimeButtonText}
              {isConfirmingSelection && (
                <Loader
                  aria-label="Loading"
                  color={SDS_BRAND_COLOR_ELEMENT_INVERSE_GREY}
                  size="small"
                  type="circle"
                  style={{ marginLeft: '4px' }}
                />
              )}
            </Button>
          </ControlWrapper>
          <Notifications
            isSlotSelected={isSlotSelected}
            deliveryDetailsInfo={deliveryDetailsInfo}
            selectedSlotQuery={confirmedSelectedSlot}
            reservationError={reservationError}
          />
        </>
      )}
    </StyledDeliveryTimeSelection>
  )
}

const ControlWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    padding-top: ${theme.spacings.small};
    ${Button} {
      flex: 1;
    }
  `,
)

const StyledDeliveryTimeSelection = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    bottom: 0;
    margin: ${theme.spacings.medium};

    flex: 1;
    display: flex;
    flex-direction: column;

    .CurrentSelectionInfo {
      margin-bottom: ${theme.spacings.xSmall};
    }
  `,
)
