import type { Modify } from '../local-storage'
import type { PaymentDetailsResponse } from '@shared/gql/document-nodes'
import type { TypeOf } from 'zod'

import { PAYMENT_SCHEMA_VERSION, PaymentSchema } from '@shared/domain/local-storage'

import { LocalStorageService } from '../local-storage'

const KEY_PAYMENT_DATA = 'payment-data'

const schemaValidator = (data: TypeOf<typeof PaymentSchema>) => PaymentSchema.parse(data)

const newEmptyLocalStoragePaymentData = (): TypeOf<typeof PaymentSchema> => ({
  cacheVersion: PAYMENT_SCHEMA_VERSION,
  paymentData: {
    paymentDetails: null,
  },
})

const modifyPaymentData = (modifier: Modify<TypeOf<typeof PaymentSchema>>) => {
  LocalStorageService.modify<TypeOf<typeof PaymentSchema>>(
    modifier,
    newEmptyLocalStoragePaymentData,
    KEY_PAYMENT_DATA,
    schemaValidator,
  )
}

const savePaymentData = (paymentDetails: PaymentDetailsResponse): void => {
  modifyPaymentData((current) => ({
    ...current,
    paymentData: {
      ...current.paymentData,
      paymentDetails: {
        ...current.paymentData.paymentDetails,
        ...paymentDetails,
        savePaymentCard: paymentDetails.savePaymentCard ?? false,
      },
    },
  }))
}

const loadPaymentData = (): TypeOf<typeof PaymentSchema> | null => {
  return LocalStorageService.load<TypeOf<typeof PaymentSchema>>(KEY_PAYMENT_DATA, schemaValidator)
}

const reset = (): void => {
  LocalStorageService.clear(KEY_PAYMENT_DATA)
}

export const PaymentDAO = {
  loadPaymentData,
  localStorageKey: KEY_PAYMENT_DATA,
  reset,
  savePaymentData,
}
