import type { NormalizedCache, Operation } from '@apollo/client'
import type { ApolloCache } from '@apollo/client/cache'
import type {
  GetLocalAuthenticationTokensQuery,
  GetUserProfileDataQuery,
} from '@shared/gql/document-nodes'

import { ApolloLink } from '@apollo/client'
import {
  GetBrandByIdDocument,
  GetDeliveryAreaDetailsDocument,
  GetLocalAuthenticationTokensDocument,
  GetProductInfoByEansDocument,
  GetUserProfileDataDocument,
  RemoteAvailableStoreProductsDocument,
  RemoteNavigationDocument,
  RemoteProductAvailabilitiesDocument,
  RemoteProductInfoDocument,
} from '@shared/gql/document-nodes'
import { getDocumentName } from '@shared/gql/get-document-name'
import { IS_BROWSER } from 'utils/is-browser'

interface Context {
  headers?: {
    [key: string]: string | string[]
  }
}

export const authorizationLink = new ApolloLink((operation, forward) => {
  const { cache } = operation.getContext()
  const apolloCache = cache as ApolloCache<NormalizedCache>

  const authenticationTokens = apolloCache.readQuery<GetLocalAuthenticationTokensQuery>({
    query: GetLocalAuthenticationTokensDocument,
  })?.authenticationTokens

  if (!authenticationTokens?.accessToken || isUnauthenticatedOperation(operation)) {
    return forward(operation)
  }

  if (!IS_BROWSER) {
    throw new Error('Tried to access user authentication tokens during SSR!')
  }

  // use cached user profile query
  const userProfileQueryResult = apolloCache.readQuery<GetUserProfileDataQuery>({
    query: GetUserProfileDataDocument,
  })

  operation.setContext(({ headers = {} }: Context) => {
    const tags = userProfileQueryResult?.userProfile?.tags

    if (tags?.length) {
      headers['X-Skaupat-Tags'] = tags
    }

    return {
      headers: {
        ...headers,
        authorization: authenticationTokens.accessToken,
      },
    }
  })

  return forward(operation)
})

// List of operation names from which it's safe to rip off the authorization header
// for caching purposes.
const UNAUTHENTICATED_SAFE_OPS = [
  GetBrandByIdDocument,
  GetDeliveryAreaDetailsDocument,
  GetProductInfoByEansDocument,
  RemoteAvailableStoreProductsDocument,
  RemoteNavigationDocument,
  RemoteProductAvailabilitiesDocument,
  RemoteProductInfoDocument,
].map(getDocumentName)

/*
  A much prettier solution for this would be to somehow embed the information into
  the GraphQL schema. Unfortunately it looks like at least with the current Apollo
  any directives defined in the schema are not available on the client side.

  It's safe to NOT add an operation to the UNAUTHENTICATED_SAFE_OPS list above; you
  will not just get the caching benefits for that particular call. So if you're unsure,
  just don't touch the list.
*/
const isUnauthenticatedOperation = (op: Operation): boolean => {
  return UNAUTHENTICATED_SAFE_OPS.includes(op.operationName)
}
