export enum Brand {
  ABC = 'abc',
  Herkku = 'herkku',
  Skaupat = 's-kaupat',
  SMarket = 's-market',
  Prisma = 'prisma',
  SokosHerkku = 'sokos-herkku',
  MestarinHerkku = 'mestarin-herkku',
  Alepa = 'alepa',
  Sale = 'sale',
  Yhteishyvä = 'yhteishyvä',
  Eprisma = 'eprisma',
}

export const isBrand = (input: string | null): input is Brand =>
  Object.values(Brand).includes(input as Brand)

export const getBrandByBrandName = (brandName?: string | null): Brand | null =>
  brandName && isBrand(brandName) ? brandName : null
