import type { FC } from 'react'

import { Brand } from '@shared/domain/brand'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const BRAND_LOGOS: Record<Brand, string> = {
  [Brand.ABC]: '/icons/brand/abc.svg',
  [Brand.Alepa]: '/icons/brand/alepa.svg',
  [Brand.Herkku]: '/icons/brand/food-market-herkku.svg',
  [Brand.MestarinHerkku]: '/icons/brand/mestarin-herkku.svg',
  [Brand.Prisma]: '/icons/brand/prisma.svg',
  [Brand.Sale]: '/icons/brand/sale.svg',
  [Brand.Skaupat]: '/icons/brand/s-kaupat.svg',
  [Brand.SMarket]: '/icons/brand/s-market.svg',
  [Brand.SokosHerkku]: '/icons/brand/sokos-herkku.svg',
  [Brand.Yhteishyvä]: '/icons/brand/yhteishyva.svg',
  [Brand.Eprisma]: '/icons/brand/prisma.svg',
}

export interface BrandStoreLogoProps {
  ['data-test-id']?: string
  alt?: string
  brand: Brand
  className?: string
}

const _BrandStoreLogo: FC<BrandStoreLogoProps> = ({
  'data-test-id': dataTestId,
  alt,
  brand,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <img
      className={className}
      data-test-id={dataTestId}
      alt={alt || t(brand)}
      src={BRAND_LOGOS[brand]}
    />
  )
}

export const BrandStoreLogo = styled(_BrandStoreLogo)({
  objectFit: 'contain',
})
