import styled from 'styled-components'

interface Props {
  className?: string
  ['data-test-id']?: string
}

export const PageTitle = styled.h1<Props>(({ theme }) => theme.variants.title1)

export const SectionTitle = styled.h2<Props>(({ theme }) => theme.variants.title1)

export const SubHeader = styled.h3<Props>(({ theme }) => theme.variants.paragraph1.regular)

export const BlockTitle = styled.p<Props>(({ theme }) => theme.variants.body2.medium)

export const TextBlock = styled.div<Props>(
  {
    'ol, ul': {
      listStylePosition: 'inside',
    },

    a: {
      textDecoration: 'none',
    },
  },

  ({ theme }) => ({
    ...theme.variants.body2.regular,

    'p:not(:first-child)': {
      marginTop: theme.spacings.small,
    },

    a: {
      ...theme.variants.body1.medium,
      color: theme.color.action,
      '&:hover': {
        color: theme.color.action,
      },
    },
  }),
)

export const StyledSpan = styled.span(({ theme }) => theme.variants.body1.regular)

export const StyledParagraph = styled.p(({ theme }) => theme.variants.body2.regular)
