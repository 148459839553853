import { useQuery } from '@apollo/client'
import { GetSelectedStoreIdDocument } from '@shared/gql/document-nodes'
import { useTheme } from 'styled-components'

type StoreIds = {
  selectedStoreId: string
  storeId: string
}

export const useStoreId = (): StoreIds => {
  // Default store id
  const { defaultStoreId } = useTheme()

  // Selected store id
  const { data: selectedStoreIdData } = useQuery(GetSelectedStoreIdDocument)
  const selectedStoreId = selectedStoreIdData?.selectedStoreId || ''

  const storeId = selectedStoreId || defaultStoreId

  return {
    selectedStoreId,
    storeId,
  }
}
