import { parseErrors } from 'domain/error-messages/error-parse'
import { errorModalVar } from 'lib/apollo/reactive-vars'
import { trackError } from 'utils/tracking/error'

export const handleRequestErrors = (errors: unknown[]) => {
  const error = parseErrors(errors)

  trackError(error)
  errorModalVar(error)
}
