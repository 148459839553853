import type { ApolloClient } from '@apollo/client'
import type {
  ClientCartItem,
  RemoteClientCartItemFromProductQuery,
} from '@shared/gql/document-nodes'

import {
  ClientCartItemViewFromProductFragmentDoc,
  GetClientCartItemDocument,
  RemoteClientCartItemFromProductDocument,
} from '@shared/gql/document-nodes'

export type RemoteClientCartItem = NonNullable<RemoteClientCartItemFromProductQuery['product']>

/**
 * Get a ClientCartItem for given product `id` and `storeId`,
 * preferring to read from local Apollo cache.
 *
 * The optional `onNetworkRequest` callback can be used to detect whether a network
 * query is needed, for example to display a loading indicator.
 */
export const getClientCartItem = async (
  apolloClient: ApolloClient<object>,
  id: string,
  storeId: string,
  onNetworkRequest?: () => void,
): Promise<ClientCartItem | RemoteClientCartItem | null> => {
  /** Prefer actual ClientCartItem */
  const cartItemFromCache = apolloClient.readQuery({
    query: GetClientCartItemDocument,
    variables: { id },
  })?.cartItem
  if (cartItemFromCache) return cartItemFromCache

  /** The corresponding product might be in the local Apollo cache */
  const clientCartItemViewFromCache = apolloClient.cache.readFragment({
    fragment: ClientCartItemViewFromProductFragmentDoc,
    fragmentName: 'ClientCartItemViewFromProduct',
    id: apolloClient.cache.identify({ __typename: 'Product', id, storeId }),
  })
  if (clientCartItemViewFromCache) return clientCartItemViewFromCache

  /** As a last effort, query product over network */
  try {
    if (typeof onNetworkRequest === 'function') {
      onNetworkRequest()
    }
    const queryResult = await apolloClient.query({
      query: RemoteClientCartItemFromProductDocument,
      variables: { id, storeId },
    })

    return queryResult?.data?.product || null
  } catch {
    return null
  }
}
