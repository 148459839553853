import { makeVar, useReactiveVar } from '@apollo/client'
import { useCallback, useEffect, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'

/** assume session is active on initial load and SSR */
const isSessionActiveVar = makeVar(true)

const DOCUMENT_EVENTS = [
  'click',
  'keydown',
  'keypress',
  'mousedown',
  'mousemove',
  'touchend',
  'touchmove',
  'touchstart',
]

/**
 * Setup event listeners to track to session activity/idle.
 * Use this hook only once to avoid duplicate listeners.
 */
export const useSessionActivityDetector = (): void => {
  const timer = useRef<NodeJS.Timeout>()

  const setActive = useCallback(() => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      isSessionActiveVar(false)
    }, 60_000 /** one minute */)
    isSessionActiveVar(true)
  }, [])

  const debouncedSetActive = useDebouncedCallback(setActive, 1000, { leading: true })

  useEffect(() => {
    setActive()

    const setIdle = () => {
      debouncedSetActive.cancel()
      clearTimeout(timer.current)
      isSessionActiveVar(false)
    }

    for (const event of DOCUMENT_EVENTS) {
      document.addEventListener(event, debouncedSetActive, { capture: true, passive: true })
    }
    window.addEventListener('scroll', debouncedSetActive, { capture: true, passive: true })
    window.addEventListener('blur', setIdle)
    window.addEventListener('focus', setActive)

    return () => {
      clearTimeout(timer.current)

      for (const event of DOCUMENT_EVENTS) {
        document.removeEventListener(event, debouncedSetActive, { capture: true })
      }
      window.removeEventListener('scroll', debouncedSetActive, { capture: true })
      window.removeEventListener('blur', setIdle)
      window.removeEventListener('focus', setActive)
    }
  }, [setActive, debouncedSetActive])
}

/**
 * @returns true when the user session is active, false when not (idle/background)
 */
export const useIsSessionActive = (): boolean => useReactiveVar(isSessionActiveVar)
