import { getConfigByStage } from './by-stage'
import { parseEnvironment } from './environment'
import { applyFeatureFlagOverrides } from './feature-flag-override'
import { Config } from './schema'

const getStage = (): Config['stage'] => {
  const [, stage] = parseEnvironment(process.env.ENVIRONMENT)
  return stage
}

export const WINDOW_CONFIG_KEY = '__BROWSER_CONFIG__'
export const SSR_CONFIG_ID = 'browser-config'
declare global {
  interface Window {
    [WINDOW_CONFIG_KEY]: Config
  }
}

export const getConfig = (stage: Config['stage'] = getStage()): Config => {
  try {
    /** This code should be tree-shaken from the browser JS bundles */
    if (typeof window === 'undefined') {
      return getConfigByStage(stage)
    }

    if (window[WINDOW_CONFIG_KEY]) {
      return window[WINDOW_CONFIG_KEY]
    }

    const config = Config.parse(JSON.parse(document.getElementById(SSR_CONFIG_ID)?.innerText ?? ''))

    if (stage !== 'production') {
      /** Apply feature flag overrides for e2e tests */
      config.featureFlags = applyFeatureFlagOverrides(config.featureFlags)
    }

    window[WINDOW_CONFIG_KEY] = config
    return config
  } catch {
    throw new Error(`Failed to load configuration for stage "${stage}"`)
  }
}
