import type {
  AuthenticationTokens,
  Customer,
  CustomerDetailsResponse,
  DeliveryDetailsInfo,
  Order,
  OrderEditActiveResponse,
  PaymentDetailsResponse,
} from '@shared/gql/document-nodes'

export const emptyCustomer = (): Customer => ({
  __typename: 'Customer',
  address: '',
  addressLine1: '',
  addressLine2: '',
  bonusCard: '',
  city: '',
  companyName: '',
  contact: null,
  email: '',
  firstName: '',
  invoiceAddress: null,
  invoiceCity: null,
  invoiceCompanyName: null,
  invoiceFirstName: null,
  invoiceLastName: null,
  invoiceNumber: '',
  invoicePhone: null,
  invoicePostalCode: null,
  lastName: '',
  newsletter: null,
  phone: '',
  postalCode: '',
  sMembershipCardLastName: null,
  sMembershipCardPostalCode: null,
  sMembershipNumber: null,
})

export const emptyOrder = (): Order => ({
  __typename: 'Order',
  additionalInfo: '',
  capturedPOSData: null,
  capturedTotalInCents: null,
  cartItems: [],
  comment: '',
  createdAt: null,
  customer: emptyCustomer(),
  deliveryDate: '',
  deliveryMethod: null,
  deliveryPoint: null,
  deliverySlot: null,
  deliverySlotId: '',
  deliveryTime: '',
  discountCode: null,
  handler: null,
  homeDeliveryType: null,
  id: '',
  isCancelable: true,
  isFastTrack: false,
  isModifiable: true,
  linkVerificationToken: '',
  modifiedAt: null,
  orderNumber: null,
  orderStatus: null,
  paymentId: null,
  paymentMethod: null,
  paymentStatus: null,
  pickupLocation: null,
  storeId: '',
  trackingUrl: null,
})

export const emptyDeliveryDetailsInfo = (): DeliveryDetailsInfo => ({
  __typename: 'DeliveryDetailsInfo',
  additionalInfo: '',
  address: '',
  city: '',
  deliveryDate: '',
  deliveryMethod: null,
  deliverySlotId: null,
  deliverySlotPrice: null,
  deliveryTime: '',
  postalCode: '',
})

export const emptyCustomerDetails = (): CustomerDetailsResponse => ({
  __typename: 'CustomerDetailsResponse',
  comment: null,
  companyName: null,
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
})

export const emptyPaymentDetails = (): PaymentDetailsResponse => ({
  __typename: 'PaymentDetailsResponse',
  invoiceNumber: null,
  paymentMethod: null,
  paymentStatus: null,
  stockmannCardNumber: null,
  selectedPaymentCardId: null,
  savePaymentCard: false,
})

export const emptyOrderEditActive = (): OrderEditActiveResponse => ({
  __typename: 'OrderEditActiveResponse',
  orderId: '',
  orderStatus: null,
})

export const emptyAuthenticationTokens = (): AuthenticationTokens => ({
  __typename: 'AuthenticationTokens',
  accessToken: null,
  idToken: null,
  modified: null,
  refreshToken: null,
})
