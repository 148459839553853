import { GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql'
import { ResolveInfo as GraphQLResolveInfo } from './resolvers'
import { Context } from './resolvers'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Void: any
}

export type Address = {
  __typename: 'Address'
  apartmentNumber: Maybe<Scalars['String']>
  postalCity: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
  streetAddress: Maybe<Scalars['String']>
}

export type AddressAutocompleteResult = {
  __typename: 'AddressAutocompleteResult'
  block: Maybe<Scalars['String']>
  building: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  countryCode: Maybe<Scalars['String']>
  countryName: Maybe<Scalars['String']>
  county: Maybe<Scalars['String']>
  district: Maybe<Scalars['String']>
  /** Unique id of address, from HERE Geocoding and Search */
  hereId: Scalars['String']
  houseNumber: Maybe<Scalars['String']>
  label: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
  state: Maybe<Scalars['String']>
  stateCode: Maybe<Scalars['String']>
  street: Maybe<Scalars['String']>
  subblock: Maybe<Scalars['String']>
  subdistrict: Maybe<Scalars['String']>
  /** The localized display name of this result item. */
  title: Scalars['String']
}

export type AddressGeocodeResult = {
  __typename: 'AddressGeocodeResult'
  block: Maybe<Scalars['String']>
  building: Maybe<Scalars['String']>
  city: Scalars['String']
  countryCode: Maybe<Scalars['String']>
  countryName: Maybe<Scalars['String']>
  county: Maybe<Scalars['String']>
  district: Maybe<Scalars['String']>
  /** Unique id of address, from HERE Geocoding and Search */
  hereId: Scalars['String']
  houseNumber: Maybe<Scalars['String']>
  label: Maybe<Scalars['String']>
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  postalCode: Scalars['String']
  resultType: Maybe<AddressGeocodeResultType>
  state: Maybe<Scalars['String']>
  stateCode: Maybe<Scalars['String']>
  street: Scalars['String']
  subblock: Maybe<Scalars['String']>
  subdistrict: Maybe<Scalars['String']>
  /** The localized display name of this result item. */
  title: Scalars['String']
}

export enum AddressGeocodeResultType {
  AddressBlock = 'addressBlock',
  AdministrativeArea = 'administrativeArea',
  HouseNumber = 'houseNumber',
  Intersection = 'intersection',
  Locality = 'locality',
  Place = 'place',
  PostalCodePoint = 'postalCodePoint',
  Street = 'street',
}

export type AddressValidateResult = {
  __typename: 'AddressValidateResult'
  errors: Array<AddressValidationError>
  isValid: Scalars['Boolean']
}

export enum AddressValidationError {
  BuildingNumberNotFound = 'BuildingNumberNotFound',
  NoResultFromThirdPartyApi = 'NoResultFromThirdPartyAPI',
  NonParseableAddress = 'NonParseableAddress',
  StreetAddressNotFound = 'StreetAddressNotFound',
}

export enum AlcoholSellingStatus {
  Allowed = 'ALLOWED',
  NotAllowed = 'NOT_ALLOWED',
}

export type Allergen = {
  __typename: 'Allergen'
  allergenTypeCode: Maybe<Scalars['String']>
  allergenTypeText: Maybe<Scalars['String']>
  levelOfContainmentCode: Maybe<Scalars['String']>
}

export type AuthTokens = {
  __typename: 'AuthTokens'
  accessToken: Maybe<Scalars['String']>
  idToken: Maybe<Scalars['String']>
  refreshToken: Maybe<Scalars['String']>
}

export type AuthenticationTokens = {
  __typename: 'AuthenticationTokens'
  accessToken: Maybe<Scalars['String']>
  idToken: Maybe<Scalars['String']>
  modified: Maybe<Scalars['Float']>
  refreshToken: Maybe<Scalars['String']>
}

export type AuthorizePaymentResponse = {
  __typename: 'AuthorizePaymentResponse'
  authorized: Maybe<Scalars['Boolean']>
  orderId: Maybe<Scalars['String']>
}

export type AvailablePackagingMaterial = {
  __typename: 'AvailablePackagingMaterial'
  ean: Scalars['String']
  materialPrice: Scalars['Float']
  materialType: MaterialType
}

export type BatchRecommendation = {
  __typename: 'BatchRecommendation'
  products: Maybe<ProductList>
  raw: Array<BatchRecommendationProduct>
  runId: Scalars['String']
}

export type BatchRecommendationProductsArgs = {
  storeId: Scalars['String']
}

export type BatchRecommendationProduct = {
  __typename: 'BatchRecommendationProduct'
  ean: Scalars['String']
  sok_id: Scalars['String']
}

export enum BatchRecommendationTypeEnum {
  Cart = 'CART',
  Seasonal = 'SEASONAL',
  YouMayNeed = 'YOU_MAY_NEED',
}

export type BonusInfo = {
  __typename: 'BonusInfo'
  membershipNumber: Maybe<Scalars['String']>
}

export type BonusWidgetToken = {
  __typename: 'BonusWidgetToken'
  token: Scalars['String']
}

export type BoundingBox = {
  bottomRight: Location
  topLeft: Location
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CartCapacity = {
  __typename: 'CartCapacity'
  /**
   * Capacity is [0, n].
   * If capacity < 1; there is still space for more products.
   * If capacity = 1; cart is full.
   * If capacity > 1; then capacity - 1 = exceeded capacity.
   */
  capacity: Scalars['Float']
  items: Array<CartItemCapacity>
  /** Cart ids of products that don't fit into the selected container dimensions or have missing measurement data. */
  rejected: Array<Scalars['String']>
  warnings: Array<CartCapacityDimensionWarning | CartCapacityNumericWarning>
  /** Total weight of the cart in kilograms (KG). */
  weight: Scalars['Float']
}

export type CartCapacityDimensionWarning = ICartCapacityWarning & {
  __typename: 'CartCapacityDimensionWarning'
  dimensionUnit: Scalars['String']
  dimensions: Array<Scalars['Int']>
  type: CartCapacityWarningType
}

export type CartCapacityNumericWarning = ICartCapacityWarning & {
  __typename: 'CartCapacityNumericWarning'
  integerValue: Scalars['Int']
  type: CartCapacityWarningType
  unit: Scalars['String']
}

export enum CartCapacityWarningType {
  MaxCapacityExceeded = 'MAX_CAPACITY_EXCEEDED',
  MaxWeightExceeded = 'MAX_WEIGHT_EXCEEDED',
  OverSizedProducts = 'OVER_SIZED_PRODUCTS',
  SomeProductsAreMissingDimensions = 'SOME_PRODUCTS_ARE_MISSING_DIMENSIONS',
}

export type CartItem = {
  __typename: 'CartItem'
  additionalInfo: Maybe<Scalars['String']>
  basicQuantityUnit: Maybe<Scalars['String']>
  brand: Maybe<Scalars['String']>
  collectingPriority: Maybe<Scalars['Int']>
  ean: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemCount: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  price: Scalars['Float']
  priceUnit: Maybe<Scalars['String']>
  productType: Maybe<ProductType>
  replace: Maybe<Scalars['Boolean']>
}

export type CartItemCapacity = {
  __typename: 'CartItemCapacity'
  capacity: Scalars['Float']
  id: Scalars['String']
  itemCount: Scalars['Int']
  status: CartItemCapacityStatus
  volume: Scalars['Float']
  weight: Scalars['Float']
}

export enum CartItemCapacityStatus {
  ExceedsContainerDimensions = 'EXCEEDS_CONTAINER_DIMENSIONS',
  Ok = 'OK',
  OkButNoMeasurements = 'OK_BUT_NO_MEASUREMENTS',
}

export type CartItemForCapacityInput = {
  id: Scalars['ID']
  itemCount: Scalars['Float']
}

export type CartItemInput = {
  additionalInfo: InputMaybe<Scalars['String']>
  basicQuantityUnit: InputMaybe<Scalars['String']>
  brand: InputMaybe<Scalars['String']>
  collectingPriority: InputMaybe<Scalars['Int']>
  ean: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  itemCount: InputMaybe<Scalars['String']>
  name: InputMaybe<Scalars['String']>
  price: Scalars['Float']
  priceUnit: InputMaybe<Scalars['String']>
  productType: InputMaybe<ProductType>
  replace: InputMaybe<Scalars['Boolean']>
}

export type CartRecommendation = {
  __typename: 'CartRecommendation'
  amounts: Array<CartRecommendationProduct>
  products: Maybe<ProductList>
  runId: Scalars['String']
}

export type CartRecommendationProductsArgs = {
  from: InputMaybe<Scalars['Int']>
  limit: InputMaybe<Scalars['Int']>
  order: InputMaybe<SortOrder>
  orderBy: InputMaybe<SortKey>
  storeId: Scalars['String']
}

export type CartRecommendationProduct = EanProduct & {
  __typename: 'CartRecommendationProduct'
  amount: Scalars['Int']
  ean: Scalars['String']
}

export enum CartRecommendationType {
  Cart = 'CART',
  Try = 'TRY',
  YouMayNeed = 'YOU_MAY_NEED',
}

export type CitrusAd = {
  __typename: 'CitrusAd'
  banners: Maybe<Array<Maybe<CitrusAdBanner>>>
  contentStandardId: Maybe<Scalars['String']>
  slotId: Maybe<Scalars['String']>
}

/** CitrusAd Banner X return items: https://developers.citrusad.com/integration/reference/banner-x-reference-1 */
export type CitrusAdBanner = {
  __typename: 'CitrusAdBanner'
  backgroundColour: Maybe<Scalars['String']>
  backgroundImage: Maybe<Scalars['String']>
  backgroundImagePosition: Maybe<Scalars['String']>
  bannerText: Maybe<Scalars['String']>
  bannerTextColour: Maybe<Scalars['String']>
  citrusAdId: Scalars['String']
  citrusCampaignName: Maybe<Scalars['String']>
  citrusContentStandardId: Maybe<Scalars['String']>
  citrusExpiry: Maybe<Scalars['String']>
  citrusSlotId: Maybe<Scalars['String']>
  contentStandardId: Maybe<Scalars['String']>
  ctaFlag: Maybe<Scalars['Boolean']>
  ctaLink: Maybe<Scalars['String']>
  ctaText: Maybe<Scalars['String']>
  ctaTextAccessibility: Maybe<Scalars['String']>
  gtins: Maybe<Array<Maybe<Scalars['String']>>>
  headingText: Maybe<Scalars['String']>
  heroImage: Maybe<Scalars['String']>
  heroImageAltText: Maybe<Scalars['String']>
  position: Maybe<Scalars['Int']>
  secondaryBackgroundImage: Maybe<Scalars['String']>
  secondaryBackgroundImagePosition: Maybe<Scalars['String']>
  secondaryHeroImage: Maybe<Scalars['String']>
  secondaryHeroImageAltText: Maybe<Scalars['String']>
  secondaryHeroMode: Maybe<Scalars['String']>
  sellerId: Maybe<Scalars['String']>
  slotId: Maybe<Scalars['String']>
}

export enum CitrusAdUserEventType {
  Click = 'CLICK',
  Impression = 'IMPRESSION',
  Purchase = 'PURCHASE',
}

export type ClientCartItem = {
  __typename: 'ClientCartItem'
  additionalInfo: Maybe<Scalars['String']>
  approxPrice: Scalars['Boolean']
  basicQuantityUnit: Scalars['String']
  campaignPrice: Maybe<Scalars['Float']>
  campaignPriceValidUntil: Maybe<Scalars['String']>
  comparisonPrice: Maybe<Scalars['Float']>
  comparisonUnit: Maybe<Scalars['String']>
  countryName: CountryName
  ean: Scalars['String']
  frozen: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  inStoreSelection: Scalars['Boolean']
  isAgeLimitedByAlcohol: Maybe<Scalars['Boolean']>
  itemCount: Scalars['Float']
  lowest30DayPrice: Maybe<Scalars['Float']>
  mainCategoryName: Scalars['String']
  name: Scalars['String']
  packagingLabelCodes: Maybe<Array<Scalars['String']>>
  price: Scalars['Float']
  priceUnit: Scalars['String']
  productType: ProductType
  quantityMultiplier: Scalars['Float']
  regularPrice: Maybe<Scalars['Float']>
  replace: Scalars['Boolean']
}

export enum CoOperative {
  Arina = 'ARINA',
  Eekoo = 'EEKOO',
  Eepee = 'EEPEE',
  Hameenmaa = 'HAMEENMAA',
  Hok = 'HOK',
  Keskimaa = 'KESKIMAA',
  Keula = 'KEULA',
  Koillismaa = 'KOILLISMAA',
  Kpo = 'KPO',
  Kso = 'KSO',
  Maakunta = 'MAAKUNTA',
  Peeassa = 'PEEASSA',
  Peremarket = 'PEREMARKET',
  Pko = 'PKO',
  Pok = 'POK',
  Satakunta = 'SATAKUNTA',
  Sso = 'SSO',
  SuurSavo = 'SUUR_SAVO',
  Tok = 'TOK',
  Vbo = 'VBO',
}

export type CollatedDeliverySlot = {
  __typename: 'CollatedDeliverySlot'
  date: Scalars['String']
  deliveryTimes: Array<DeliverySlot>
}

export type Company = {
  __typename: 'Company'
  identityCode: Maybe<Scalars['String']>
  invoiceId: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
}

export type Coordinates = {
  __typename: 'Coordinates'
  lat: Scalars['String']
  lon: Scalars['String']
}

export type Country = {
  __typename: 'Country'
  code: Scalars['String']
}

export enum CountryCode {
  Et = 'ET',
  Fi = 'FI',
}

export type CountryName = {
  __typename: 'CountryName'
  fi: Maybe<Scalars['String']>
}

export type Customer = {
  __typename: 'Customer'
  address: Maybe<Scalars['String']>
  /** Address part that can be translated to roof top coordinates e.g street address "Työmiehenrata 15" */
  addressLine1: Maybe<Scalars['String']>
  /** More detailed address part like apartment number, building, block etc.. */
  addressLine2: Maybe<Scalars['String']>
  bonusCard: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  companyName: Maybe<Scalars['String']>
  contact: Maybe<Scalars['Boolean']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  invoiceAddress: Maybe<Scalars['String']>
  invoiceCity: Maybe<Scalars['String']>
  invoiceCompanyName: Maybe<Scalars['String']>
  invoiceFirstName: Maybe<Scalars['String']>
  invoiceLastName: Maybe<Scalars['String']>
  invoiceNumber: Maybe<Scalars['String']>
  invoicePhone: Maybe<Scalars['String']>
  invoicePostalCode: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  newsletter: Maybe<Scalars['Boolean']>
  phone: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
  sMembershipCardLastName: Maybe<Scalars['String']>
  sMembershipCardPostalCode: Maybe<Scalars['String']>
  sMembershipNumber: Maybe<Scalars['String']>
}

export type CustomerDetailsResponse = {
  __typename: 'CustomerDetailsResponse'
  comment: Maybe<Scalars['String']>
  companyName: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
}

export type CustomerInput = {
  address: InputMaybe<Scalars['String']>
  /** Address part that can be translated to roof top coordinates e.g street address "Työmiehenrata 15" */
  addressLine1: InputMaybe<Scalars['String']>
  /** More detailed address part like apartment number, building, block etc.. */
  addressLine2: InputMaybe<Scalars['String']>
  bonusCard: InputMaybe<Scalars['String']>
  city: InputMaybe<Scalars['String']>
  companyName: InputMaybe<Scalars['String']>
  contact: InputMaybe<Scalars['Boolean']>
  email: Scalars['String']
  firstName: Scalars['String']
  invoiceAddress: InputMaybe<Scalars['String']>
  invoiceCity: InputMaybe<Scalars['String']>
  invoiceCompanyName: InputMaybe<Scalars['String']>
  invoiceFirstName: InputMaybe<Scalars['String']>
  invoiceLastName: InputMaybe<Scalars['String']>
  invoiceNumber: InputMaybe<Scalars['String']>
  invoicePhone: InputMaybe<Scalars['String']>
  invoicePostalCode: InputMaybe<Scalars['String']>
  lastName: Scalars['String']
  newsletter: InputMaybe<Scalars['Boolean']>
  phone: Scalars['String']
  postalCode: InputMaybe<Scalars['String']>
  sMembershipCardLastName: InputMaybe<Scalars['String']>
  sMembershipCardPostalCode: InputMaybe<Scalars['String']>
  sMembershipNumber: InputMaybe<Scalars['String']>
}

export enum CustomerType {
  B2b = 'b2b',
  B2c = 'b2c',
}

export type DayAvailability = {
  __typename: 'DayAvailability'
  /** Whether or not there is a available slot for the date */
  available: Scalars['Boolean']
  /**
   * Date for the slot availability
   * Format: 2023-01-01
   */
  date: Scalars['String']
}

export enum DayLabel {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED',
}

export type DeleteUserDataResult = {
  __typename: 'DeleteUserDataResult'
  success: Scalars['Boolean']
}

export type DeliveryArea = {
  __typename: 'DeliveryArea'
  address: Maybe<DeliveryAreaAddress>
  alcoholSellingAllowed: AlcoholSellingStatus
  areaId: Scalars['String']
  availablePackagingMaterials: Maybe<Array<AvailablePackagingMaterial>>
  /**
   * For Home Delivery Area the delivery area polygon.
   * Currently in use only for Robot deliveries.
   */
  deliveryAreaPolygon: Maybe<Array<Point>>
  deliveryMethod: DeliveryMethod
  deliverySlots: Maybe<Array<CollatedDeliverySlot>>
  description: Maybe<Scalars['String']>
  /**
   * Geospatial distance from deliveryarea to `to`  in meters
   * User location will be resolved by either from context or by `to` parameter.
   * If non of the previous is provided the return value will be null.
   *
   * NOTES:
   * 1. searchHomeDeliveryTypes `location` parameter is set the context.
   * 2. nextDeliverySlot `to` parameter is set to the context. `distance` has resolved after `nextDeliverySlot` i.e after in the Query.
   */
  distance: Maybe<Scalars['Int']>
  districts: Maybe<Array<DeliveryDistrict>>
  /** @deprecated This is only to enable a feature in clients. Will be removed in near future. */
  dynamicPricing: Scalars['Boolean']
  /**
   * Only available for fast track slots.
   *
   * fastTrackOpeningHours: { from: ISODATE, to: ISODATE, status: OPEN/CLOSED }
   * 16:00pm
   * fastTrackOpeningHours: { from: ISODATE-TODAY-9:00, to: ISODATE-TODAY-21:00, status: OPEN }
   * 22:00pm
   * fastTrackOpeningHours: { from: ISODATE-TOMORROW-9:00, to: ISODATE-TOMORROW-21:00, status: CLOSED }
   */
  fastTrackOpeningHours: Maybe<FastTrackOpeningHours>
  homeDeliveryType: Maybe<HomeDeliveryType>
  isFastTrack: Scalars['Boolean']
  isRemote: Maybe<Scalars['Boolean']>
  location: Maybe<DeliveryLocation>
  name: Scalars['String']
  /**
   * @param rangeDays Duration of days to look for the next slot. Default is 14 days. Minimum is 1 and maximum 31
   * @deprecated Use nextDeliverySlot in public-schema
   */
  nextAvailableDeliverySlot: Maybe<DeliverySlot>
  nextDeliverySlot: Maybe<NextDeliverySlot>
  postalCodes: Maybe<Array<Scalars['String']>>
  /** @deprecated Will be removed from public-api */
  price: Scalars['Float']
  /** @deprecated This is only to enable a feature in clients. Will be removed in near future. */
  reservationEnabled: Scalars['Boolean']
  /**
   * Use this to query robot availability and ETA
   * @throws:
   *   - RobotDeliveryUnavailableError [400], when given coordinats are out of service area
   */
  robotAvailability: Maybe<RobotAvailability>
  store: Maybe<StoreInfo>
  storeId: Scalars['String']
  /** Parameter to restrict delivery area visibility */
  visibilityGroup: Maybe<Scalars['String']>
}

export type DeliveryAreaDeliverySlotsArgs = {
  endDate: InputMaybe<Scalars['String']>
  orderId: InputMaybe<Scalars['ID']>
  reservationId: InputMaybe<Scalars['ID']>
  startDate: InputMaybe<Scalars['String']>
}

export type DeliveryAreaDistanceArgs = {
  to: InputMaybe<Location>
}

export type DeliveryAreaNextAvailableDeliverySlotArgs = {
  rangeDays: InputMaybe<Scalars['Int']>
}

export type DeliveryAreaNextDeliverySlotArgs = {
  to: InputMaybe<Location>
}

export type DeliveryAreaRobotAvailabilityArgs = {
  to: Location
}

export type DeliveryAreaAddress = {
  __typename: 'DeliveryAreaAddress'
  city: Scalars['String']
  postalCode: Scalars['String']
  street: Scalars['String']
}

export type DeliveryAreaPin = {
  __typename: 'DeliveryAreaPin'
  areaId: Scalars['String']
  brand: Scalars['String']
  location: Point
  placeOfBusinessId: Scalars['String']
}

export type DeliveryAreaPinsResponse = {
  __typename: 'DeliveryAreaPinsResponse'
  pins: Maybe<Array<DeliveryAreaPin>>
}

export type DeliveryAreaSearchResponse = {
  __typename: 'DeliveryAreaSearchResponse'
  areas: Maybe<Array<DeliveryArea>>
  total: Maybe<Scalars['Int']>
}

export type DeliveryDetailsInfo = {
  __typename: 'DeliveryDetailsInfo'
  additionalInfo: Maybe<Scalars['String']>
  address: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  deliveryDate: Maybe<Scalars['String']>
  deliveryMethod: Maybe<DeliveryMethod>
  deliverySlotId: Maybe<Scalars['ID']>
  deliverySlotPrice: Maybe<Scalars['Float']>
  deliveryTime: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
}

export type DeliveryDistrict = {
  __typename: 'DeliveryDistrict'
  city: Maybe<Scalars['String']>
  postalCode: Scalars['String']
}

export type DeliveryLocation = {
  __typename: 'DeliveryLocation'
  address: Maybe<DeliveryAreaAddress>
  distance: Maybe<Scalars['Float']>
  distanceFromStore: Maybe<Scalars['Int']>
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  robotAvailability: Maybe<RobotAvailability>
}

export type DeliveryLocationAddressArgs = {
  lang: InputMaybe<Scalars['String']>
}

export type DeliveryLocationDistanceFromStoreArgs = {
  storeId: Scalars['ID']
}

export type DeliveryLocationRobotAvailabilityArgs = {
  areaId: Scalars['ID']
}

export enum DeliveryMethod {
  HomeDelivery = 'HOME_DELIVERY',
  Pickup = 'PICKUP',
}

export type DeliverySlot = {
  __typename: 'DeliverySlot'
  alcoholSellingAllowed: AlcoholSellingStatus
  areaId: Scalars['String']
  availability: SlotAvailability
  /**
   * When will the slot be closed? I.E. When is the last moment to send new orders?
   * Format: 2021-02-07T19:00:00+02:00
   */
  closingTimestamp: Maybe<Scalars['String']>
  deliveryMethod: DeliveryMethod
  /** Format: ISO DateTime */
  endDateTime: Scalars['String']
  /** Estimated total time in minutes */
  estimatedFastTrackTime: Maybe<Scalars['Int']>
  /** Estimated remaining time until delivery in minutes */
  estimatedRemainingFastTrackTime: Maybe<Scalars['Int']>
  /** After slot is closed, new orders are no longer accepted */
  isClosed: Scalars['Boolean']
  isFastTrack: Scalars['Boolean']
  /**
   * If slot is not modifiable, it doesn't generally accept modifications.
   * Order has separate isModifiable field that can have different value depending on the order state
   */
  isModifiable: Scalars['Boolean']
  /** Slot length in minutes */
  length: Scalars['Int']
  /**
   * When is the modification cutoff? I.E. When is the last moment to send modifications?
   * Format: 2021-02-07T19:00:00+02:00
   */
  modificationTimestamp: Maybe<Scalars['String']>
  price: Scalars['Float']
  slotId: Scalars['String']
  /** Format: ISO DateTime */
  startDateTime: Scalars['String']
  /** In minutes since midnight */
  startTime: Scalars['Int']
  /** Human-readable time slot. E.g. 10:00-13:00 */
  time: Scalars['String']
  /** @deprecated will be removed from public schema */
  validFrom: Scalars['String']
}

export type DeliverySlotReservationResponse = {
  __typename: 'DeliverySlotReservationResponse'
  deliveryArea: DeliveryArea
  deliverySlot: DeliverySlot
  /** Format: 2021-02-07T19:00:00+02:00 */
  expiresAt: Scalars['String']
  reservationId: Scalars['String']
}

export type DeliveryStore = {
  __typename: 'DeliveryStore'
  areaId: Maybe<Scalars['ID']>
  availablePaymentMethods: Maybe<Array<Maybe<PaymentMethod>>>
  brand: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Maybe<Scalars['String']>
}

export enum DeliveryTimeStatus {
  Available = 'AVAILABLE',
  Full = 'FULL',
}

export enum DeviceType {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE',
}

export type DiscountProduct = {
  __typename: 'DiscountProduct'
  discountAmount: Scalars['Float']
  discountType: DiscountProductType
  ean: Scalars['String']
  id: Scalars['String']
  isValid: Scalars['Boolean']
  name: Scalars['String']
}

export enum DiscountProductType {
  FixedAmount = 'FIXED_AMOUNT',
  FixedDeliveryPrice = 'FIXED_DELIVERY_PRICE',
}

export enum Domain {
  Abc = 'ABC',
  Eprisma = 'EPRISMA',
  Herkku = 'HERKKU',
  SKaupat = 'S_KAUPAT',
}

export type DomainCustomer = {
  __typename: 'DomainCustomer'
  cardType: Maybe<Scalars['String']>
  companyName: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  stockmannCardNumber: Maybe<Scalars['String']>
  type: CustomerType
}

export type DomainDelivery = {
  __typename: 'DomainDelivery'
  address: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
}

export type DomainOrder = {
  __typename: 'DomainOrder'
  additionalInfo: Maybe<Scalars['String']>
  cartItems: Array<ClientCartItem>
  comment: Maybe<Scalars['String']>
  customer: DomainCustomer
  deliveryAddress: DomainDelivery
  deliveryAreaId: Maybe<Scalars['String']>
  deliverySlotId: Maybe<Scalars['String']>
  discountCode: Maybe<Scalars['String']>
  id: Maybe<Scalars['String']>
  orderStatus: Maybe<OrderStatus>
  payment: DomainPayment
  reservationId: Maybe<Scalars['String']>
  storeId: Maybe<Scalars['String']>
}

export type DomainPayment = {
  __typename: 'DomainPayment'
  invoiceNumber: Maybe<Scalars['String']>
  paymentMethod: Maybe<PaymentMethod>
  paymentStatus: Maybe<PaymentStatus>
}

export type EanProduct = {
  ean: Scalars['String']
}

export type ExternalOrdersPosData = {
  __typename: 'ExternalOrdersPOSData'
  accountID: Maybe<Scalars['String']>
  accountingStoreID: Scalars['String']
  loyaltyTransactionTotals: Maybe<Array<ExternalOrdersPosRoyaltyTransation>>
  ownerBenefit: Maybe<Scalars['String']>
  registerID: Scalars['String']
  registerTransNumber: Scalars['String']
  shipment: Maybe<Array<ExternalOrdersPosShipment>>
  signature: Maybe<Scalars['String']>
  storeCode: Scalars['String']
  storeID: Scalars['String']
  totalAmount: Scalars['String']
  transactionCode: Scalars['String']
  transactionDateTime: Scalars['String']
  transactionID: Scalars['String']
  type: Scalars['String']
  uniqueTransactionID: Maybe<Scalars['String']>
  versionNumber: Scalars['String']
  webStoreID: Scalars['String']
}

export type ExternalOrdersPosRoyaltyTransation = {
  __typename: 'ExternalOrdersPOSRoyaltyTransation'
  loyaltyBenefitAmount: Scalars['String']
  loyaltyCardID: Scalars['String']
  paymentBenefitAmount: Scalars['String']
  sequenceID: Scalars['String']
}

export type ExternalOrdersPosShipment = {
  __typename: 'ExternalOrdersPOSShipment'
  billOfLadingNr: Maybe<Scalars['String']>
  employeeID: Maybe<Scalars['String']>
  infoText: Maybe<Scalars['String']>
  orderID: Scalars['String']
  sentTime: Maybe<Scalars['String']>
  shipmentID: Scalars['String']
  status: Scalars['String']
  statusDate: Scalars['String']
  storeID: Scalars['String']
}

export enum FacetKey {
  BrandName = 'brandName',
  Category = 'category',
  Labels = 'labels',
  SupplierName = 'supplierName',
}

export type FastTrackOpeningHours = {
  __typename: 'FastTrackOpeningHours'
  from: Scalars['String']
  status: StoreOpeningStatus
  to: Scalars['String']
}

export type FavoriteItem = {
  __typename: 'FavoriteItem'
  count: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  ean: Scalars['String']
  modifiedAt: Scalars['String']
  product: Maybe<Product>
}

export type FavoriteItemProductArgs = {
  storeId: Scalars['ID']
}

export type FavoritesList = {
  __typename: 'FavoritesList'
  createdAt: Scalars['String']
  id: Scalars['String']
  isDefault: Scalars['Boolean']
  items: Array<FavoriteItem>
  modifiedAt: Scalars['String']
  name: Scalars['String']
  products: Maybe<Array<Product>>
  slug: Scalars['String']
}

export type FavoritesListProductsArgs = {
  fallbackToGlobal: InputMaybe<Scalars['Boolean']>
  includeAgeLimitedByAlcohol: InputMaybe<Scalars['Boolean']>
  storeId: Scalars['ID']
}

export type FavoritesListDeleteResult = {
  __typename: 'FavoritesListDeleteResult'
  id: Scalars['String']
}

export type FavoritesListPatchInput = {
  isDefault: InputMaybe<Scalars['Boolean']>
  name: InputMaybe<Scalars['String']>
}

export type FavoritesLists = {
  __typename: 'FavoritesLists'
  favoritesLists: Array<FavoritesList>
}

export type FavoritesOperationDataInput = {
  count: InputMaybe<Scalars['String']>
  ean: InputMaybe<Scalars['String']>
  listId: Scalars['String']
}

export type FavoritesOperationInput = {
  data: FavoritesOperationDataInput
  operation: FavoritesOperationOperationEnum
}

export enum FavoritesOperationOperationEnum {
  Delete = 'DELETE',
  Put = 'PUT',
  SetDefaultList = 'SET_DEFAULT_LIST',
}

export type FavoritesOperationResult = {
  __typename: 'FavoritesOperationResult'
  discardedCount: Scalars['Int']
  successfulCount: Scalars['Int']
}

export type Filters = {
  key: FacetKey
  value: Array<Scalars['String']>
}

export type Hierarchy = {
  __typename: 'Hierarchy'
  count: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  slug: Maybe<Scalars['String']>
}

export type HierarchyPathItem = {
  __typename: 'HierarchyPathItem'
  hidden: Scalars['Boolean']
  id: Scalars['String']
  name: Scalars['String']
  slug: Scalars['String']
}

export type HomeDeliverySlot = IHomeDeliverySlot & {
  __typename: 'HomeDeliverySlot'
  closingTime: Scalars['String']
  deliveryTimeEnd: Scalars['String']
  deliveryTimeStart: Scalars['String']
  id: Scalars['String']
  isAlcoholSellingAllowed: Scalars['Boolean']
  isOrderModificationAllowed: Scalars['Boolean']
  price: Scalars['Float']
}

export type HomeDeliverySlotsInStore = {
  __typename: 'HomeDeliverySlotsInStore'
  slots: Array<HomeDeliverySlot>
  store: StoreInfo
}

export enum HomeDeliveryType {
  Normal = 'NORMAL',
  Robot = 'ROBOT',
}

export type ICartCapacityWarning = {
  type: CartCapacityWarningType
}

export type IHomeDeliverySlot = {
  /**
   * When the slot will be closed and no more orders can be added to it
   * Format: 2023-01-01T12:00:00+02:00
   */
  closingTime: Scalars['String']
  /**
   * When order delivery time window end
   * Format: 2023-01-01T12:00:00+02:00
   */
  deliveryTimeEnd: Scalars['String']
  /**
   * When order delivery time window starts
   * Format: 2023-01-01T12:00:00+02:00
   */
  deliveryTimeStart: Scalars['String']
  /**
   * Unique identifier for a slot
   * Format: 2023-01-01:90ac047a-4b03-4292-adfd-476c0efe1404
   */
  id: Scalars['String']
  /** Whether or not alcohol selling is allowed for this slot */
  isAlcoholSellingAllowed: Scalars['Boolean']
  /** Whether or not orders added to this slot can be modified after they've been placed (set the NEW order status) */
  isOrderModificationAllowed: Scalars['Boolean']
  /** Slot price in EUR */
  price: Scalars['Float']
}

export type IStructuredFacet = {
  key: FacetKey
}

export type LocalizableText = {
  __typename: 'LocalizableText'
  default: Scalars['String']
  en: Maybe<Scalars['String']>
  et: Maybe<Scalars['String']>
  fi: Maybe<Scalars['String']>
  sv: Maybe<Scalars['String']>
}

export type LocalizedProductInfo = {
  __typename: 'LocalizedProductInfo'
  en: Maybe<LocalizedProductInfoFields>
  fi: Maybe<LocalizedProductInfoFields>
  sv: Maybe<LocalizedProductInfoFields>
}

export type LocalizedProductInfoFields = {
  __typename: 'LocalizedProductInfoFields'
  allergens: Maybe<Array<Allergen>>
  description: Maybe<Scalars['String']>
  ingredientStatement: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  storageGuideForConsumer: Maybe<Scalars['String']>
  userGuideForConsumer: Maybe<Scalars['String']>
}

export type LocalizedText = {
  __typename: 'LocalizedText'
  en: Maybe<Scalars['String']>
  et: Maybe<Scalars['String']>
  fi: Maybe<Scalars['String']>
  sv: Maybe<Scalars['String']>
}

export type Location = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

/**
 * Combined type for "Delivery Fee" products and "Packaging Material" products. These used to share the
 * same type with real Products, but now they have their own type with only a subset of the "Product" fields.
 */
export type MandatoryProduct = {
  __typename: 'MandatoryProduct'
  ean: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  price: Scalars['Float']
  productType: ProductType
  /** @deprecated This field is not populated, only included for backwards-compatibility with the Product type */
  storeId: Maybe<Scalars['String']>
}

export enum MaterialType {
  CardboardBox = 'CARDBOARD_BOX',
  DepositBag = 'DEPOSIT_BAG',
  StandardPackaging = 'STANDARD_PACKAGING',
}

export type Municipality = {
  __typename: 'Municipality'
  code: Scalars['String']
  name: Maybe<LocalizableText>
}

export type Mutation = {
  __typename: 'Mutation'
  /** No specific errors */
  addUserNotificationSubcription: Maybe<UserNotificationSubscription>
  /** No specific errors */
  addUserPushNotificationDevice: Maybe<PushNotificationDevice>
  /**
   * @throws:
   * - AuthorizePaymentError [500]
   * - SlotFullError [409]
   * - SlotClosedError [409]
   * - ReservationNotFoundError [404](reservation has been expired)
   */
  authorizePayment: Maybe<AuthorizePaymentResponse>
  /**
   * @throws:
   * - OrderNotFoundError [404]
   */
  cancelOrder: Maybe<Order>
  cancelOrderModification: Scalars['Boolean']
  clearAuthenticationRedirectPath: Maybe<Scalars['Boolean']>
  clearAuthenticationTokens: Maybe<Scalars['Boolean']>
  clearCartAndOrderDetails: Maybe<Scalars['Boolean']>
  /**
   * Creates a temporary reservation into a slot.
   *
   * @param deliverySlotId ID of the delivery slot where the reservation is made.
   * @throws:
   * - BadRequestError [400]
   * - NotFoundError [404]
   * - SlotFullError [409]
   * - SlotClosedError [409]
   */
  createDeliverySlotReservation: DeliverySlotReservationResponse
  /**
   * @throws:
   * - MissingSlotId [400]
   * - PostalCodeNotSupportedError [400]
   * - SlotFullError [409]
   * - SlotClosedError [409]
   * - DiscountCodeExpiredError [404]
   * - DiscountCodeNotFoundError[404]
   */
  createOrder: Maybe<Order>
  /**
   * @throws:
   * - CreatePaymentError [500]
   * - PaymentCardNotFoundError [404]
   * - SlotClosedError [409]
   */
  createPayment: Maybe<PaymentTerminalAddress>
  /** @deprecated With shoppinglist multiple favorites list architecture no longer needed */
  createUserFavoritesList: Maybe<FavoritesList>
  /**
   * @throws
   * - OrderCannotBeDeletedError
   */
  deleteAllUserData: Maybe<DeleteUserDataResult>
  /**
   * @throws
   * - DefaultListCannotBeDeletedError
   * @deprecated With shoppinglist multiple favorites list architecture no longer needed
   */
  deleteUserFavoritesList: Maybe<FavoritesListDeleteResult>
  isOptimisticResponse: Maybe<Scalars['Boolean']>
  refreshDeliverySlotReservation: DeliverySlotReservationResponse
  releaseDeliverySlotReservation: Maybe<Scalars['Void']>
  /** No specific errors */
  removeUserNotificationSubscription: Maybe<UserNotificationSubscription>
  /**
   * @throws:
   * - UnableToRemovePaymentCardError [500]
   */
  removeUserPaymentCard: Maybe<RemoveUserPaymentCardResponse>
  /** No specific errors */
  removeUserPushNotificationDevice: Maybe<PushNotificationDevice>
  /**
   * @throws:
   * - UnableToRenamePaymentCardError [500]
   */
  renameUserPaymentCard: Maybe<RenameUserPaymentCardResponse>
  resetDeliveryStore: Maybe<Scalars['Boolean']>
  /**
   * @throws:
   * - UnableToResetPaymentCardForOrderError [500]
   * - UserIdMismatchError [403]
   * - OrderNotFoundError [404]
   */
  resetOrderPaymentCard: Maybe<ResetOrderPaymentCardResponse>
  /**
   * @throws:
   * - UnableToSavePaymentCardError [500]
   */
  saveUserPaymentCard: Maybe<SaveUserPaymentCardResponse>
  sendCitrusAdUserEvents: Maybe<Scalars['Void']>
  /**
   * Sends chat metadata to Giosg service
   *
   * @param visitorId - Giosg visitor id
   * @param storeId - Currently selected store id
   * @throws:
   * - BadRequestError [400]
   */
  sendGiosgChatMetadata: Maybe<Scalars['Void']>
  /**
   * @throws:
   * - BadRequestError [400]
   */
  sendProductUserEvents: Maybe<ProductUserEventResponse>
  /**
   * @throws:
   * - UnableToSetDefaultPaymentCardError [500]
   */
  setDefaultUserPaymentCard: Maybe<SetDefaultUserPaymentCardResponse>
  setDeliveryStore: Maybe<Scalars['Boolean']>
  startOrderEdit: Maybe<Scalars['Boolean']>
  stopOrderEdit: Maybe<Scalars['Boolean']>
  updateAuthenticationRedirectPath: Maybe<Scalars['String']>
  updateAuthenticationTokens: Maybe<UpdateAuthenticationTokensResponse>
  updateCustomerInfo: Maybe<UpdateCustomerInfoResponse>
  updateCustomerType: Maybe<Scalars['Boolean']>
  updateDeliveryDetails: Maybe<UpdateDeliveryDetailsResponse>
  /**
   * @throws:
   * - MissingSlotId [400]
   * - PostalCodeNotSupportedError [400]
   * - SlotFullError [409]
   * - SlotClosedError [409]
   * - DiscountCodeExpiredError [404]
   * - DiscountCodeNotFoundError[404]
   */
  updateOrder: Maybe<Order>
  updatePaymentMethodDetails: Maybe<Scalars['Boolean']>
  updatePersonalInfo: Maybe<UpdatePersonalInfoResponse>
  updateSelectedAreaId: Maybe<Scalars['Boolean']>
  updateSelectedBrand: Maybe<Scalars['Boolean']>
  updateSelectedStoreId: Maybe<Scalars['Boolean']>
  updateStockmannCardNumber: Maybe<Scalars['Boolean']>
  /**
   * @throws
   * - MissingUpdateValuesError
   * @deprecated With shoppinglist multiple favorites list architecture no longer needed
   */
  updateUserFavoritesList: Maybe<FavoritesList>
  /** @deprecated With shoppinglist multiple favorites list architecture no longer needed */
  updateUserFavoritesListItems: Maybe<FavoritesOperationResult>
  /** No specific errors */
  updateUserNotificationSubscription: Maybe<UserNotificationSubscription>
  userFavoritesAddItem: FavoritesList
  userFavoritesRemoveItem: FavoritesList
}

export type MutationAddUserNotificationSubcriptionArgs = {
  mediums: Array<UserNotificationMediumEnum>
  type: UserNotificationSubscriptionEnum
}

export type MutationAddUserPushNotificationDeviceArgs = {
  deviceToken: Scalars['String']
  name: InputMaybe<Scalars['String']>
}

export type MutationAuthorizePaymentArgs = {
  orderId: Scalars['ID']
}

export type MutationCancelOrderArgs = {
  id: Scalars['ID']
}

export type MutationCancelOrderModificationArgs = {
  isLoggedIn: InputMaybe<Scalars['Boolean']>
}

export type MutationClearCartAndOrderDetailsArgs = {
  isLoggedIn: InputMaybe<Scalars['Boolean']>
}

export type MutationCreateDeliverySlotReservationArgs = {
  deliverySlotId: Scalars['ID']
}

export type MutationCreateOrderArgs = {
  order: InputMaybe<OrderInput>
}

export type MutationCreatePaymentArgs = {
  cardId: InputMaybe<Scalars['ID']>
  customWebstoreRedirectUrl: InputMaybe<Scalars['String']>
  device: DeviceType
  orderId: Scalars['ID']
  serviceType: InputMaybe<ServiceType>
  shouldSavePaymentCard: InputMaybe<Scalars['Boolean']>
}

export type MutationCreateUserFavoritesListArgs = {
  name: Scalars['String']
}

export type MutationDeleteAllUserDataArgs = {
  retainUserProfile: InputMaybe<Scalars['Boolean']>
}

export type MutationDeleteUserFavoritesListArgs = {
  listId: Scalars['String']
}

export type MutationRefreshDeliverySlotReservationArgs = {
  reservationId: Scalars['ID']
}

export type MutationReleaseDeliverySlotReservationArgs = {
  reservationId: Scalars['ID']
}

export type MutationRemoveUserNotificationSubscriptionArgs = {
  mediums: Array<UserNotificationMediumEnum>
  type: UserNotificationSubscriptionEnum
}

export type MutationRemoveUserPaymentCardArgs = {
  cardId: Scalars['ID']
}

export type MutationRemoveUserPushNotificationDeviceArgs = {
  deviceToken: Scalars['String']
}

export type MutationRenameUserPaymentCardArgs = {
  cardId: Scalars['ID']
  name: Scalars['String']
}

export type MutationResetOrderPaymentCardArgs = {
  id: Scalars['ID']
}

export type MutationSaveUserPaymentCardArgs = {
  orderId: Scalars['ID']
}

export type MutationSendCitrusAdUserEventsArgs = {
  adId: Scalars['String']
  eventType: CitrusAdUserEventType
  orderId: InputMaybe<Scalars['String']>
  sessionId: Scalars['String']
}

export type MutationSendGiosgChatMetadataArgs = {
  storeId: InputMaybe<Scalars['String']>
  visitorId: Scalars['String']
}

export type MutationSendProductUserEventsArgs = {
  eventType: ProductUserEventType
  productIds: Array<Scalars['String']>
  sessionId: Scalars['String']
}

export type MutationSetDefaultUserPaymentCardArgs = {
  cardId: Scalars['ID']
}

export type MutationSetDeliveryStoreArgs = {
  areaId: InputMaybe<Scalars['ID']>
  availablePaymentMethods: InputMaybe<Array<InputMaybe<PaymentMethod>>>
  brand: InputMaybe<Scalars['String']>
  id: InputMaybe<Scalars['ID']>
  name: InputMaybe<Scalars['String']>
}

export type MutationStartOrderEditArgs = {
  orderId: InputMaybe<Scalars['ID']>
  orderStatus: InputMaybe<OrderStatus>
}

export type MutationUpdateAuthenticationRedirectPathArgs = {
  authRedirectPath: InputMaybe<Scalars['String']>
}

export type MutationUpdateAuthenticationTokensArgs = {
  accessToken: Scalars['String']
  idToken: Scalars['String']
  refreshToken: Scalars['String']
}

export type MutationUpdateCustomerInfoArgs = {
  companyName: InputMaybe<Scalars['String']>
  customerType: InputMaybe<Scalars['String']>
  email: InputMaybe<Scalars['String']>
  firstName: InputMaybe<Scalars['String']>
  lastName: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
}

export type MutationUpdateCustomerTypeArgs = {
  customerType: InputMaybe<Scalars['String']>
}

export type MutationUpdateDeliveryDetailsArgs = {
  additionalInfo: InputMaybe<Scalars['String']>
  address: InputMaybe<Scalars['String']>
  city: InputMaybe<Scalars['String']>
  deliveryDate: InputMaybe<Scalars['String']>
  deliveryMethod: InputMaybe<DeliveryMethod>
  deliverySlotId: InputMaybe<Scalars['ID']>
  deliverySlotPrice: InputMaybe<Scalars['Float']>
  deliveryTime: InputMaybe<Scalars['String']>
  postalCode: InputMaybe<Scalars['String']>
}

export type MutationUpdateOrderArgs = {
  id: InputMaybe<Scalars['String']>
  order: InputMaybe<OrderInput>
}

export type MutationUpdatePaymentMethodDetailsArgs = {
  invoiceNumber: InputMaybe<Scalars['String']>
  paymentMethod: InputMaybe<PaymentMethod>
  paymentStatus: InputMaybe<Scalars['String']>
  savePaymentCard: InputMaybe<Scalars['Boolean']>
  selectedPaymentCardId: InputMaybe<Scalars['ID']>
  stockmannCardNumber: InputMaybe<Scalars['String']>
}

export type MutationUpdatePersonalInfoArgs = {
  comment: InputMaybe<Scalars['String']>
  companyName: InputMaybe<Scalars['String']>
  email: InputMaybe<Scalars['String']>
  firstName: InputMaybe<Scalars['String']>
  lastName: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
}

export type MutationUpdateSelectedAreaIdArgs = {
  selectedAreaId: InputMaybe<Scalars['ID']>
}

export type MutationUpdateSelectedBrandArgs = {
  selectedBrand: InputMaybe<Scalars['String']>
}

export type MutationUpdateSelectedStoreIdArgs = {
  selectedStoreId: InputMaybe<Scalars['ID']>
}

export type MutationUpdateStockmannCardNumberArgs = {
  stockmannCardNumber: InputMaybe<Scalars['String']>
}

export type MutationUpdateUserFavoritesListArgs = {
  listId: Scalars['String']
  values: FavoritesListPatchInput
}

export type MutationUpdateUserFavoritesListItemsArgs = {
  operations: Array<FavoritesOperationInput>
}

export type MutationUpdateUserNotificationSubscriptionArgs = {
  enabled: Scalars['Boolean']
  mediums: Array<UserNotificationMediumEnum>
  type: UserNotificationSubscriptionEnum
}

export type MutationUserFavoritesAddItemArgs = {
  ean: Scalars['String']
}

export type MutationUserFavoritesRemoveItemArgs = {
  ean: Scalars['String']
}

export type Navigation = {
  __typename: 'Navigation'
  navigation: Maybe<Array<NavigationItem>>
}

export type NavigationItem = {
  __typename: 'NavigationItem'
  children: Maybe<Array<NavigationItem>>
  hidden: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['String']>
  itemCount: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  redirectedSlugs: Array<Scalars['String']>
  sequence: Maybe<Scalars['String']>
  slug: Maybe<Scalars['String']>
  type: Maybe<Scalars['String']>
}

/**
 * More condensed version of delivery slot which is compatible with the slots
 * that we store in in deliveryareas elastic search. The goal in the future
 * would be to bring the ordinary DeliverySlot closer to his model as well
 * because it contains a lot of extra fields.
 */
export type NextDeliverySlot = {
  __typename: 'NextDeliverySlot'
  alcoholSellingAllowed: AlcoholSellingStatus
  availability: SlotAvailability
  /** Format: ISO DateTime */
  endDateTime: Scalars['String']
  /** Estimated time in minutes */
  estimatedFastTrackTime: Maybe<Scalars['Int']>
  isClosed: Scalars['Boolean']
  isFastTrack: Scalars['Boolean']
  price: Scalars['Float']
  slotId: Scalars['String']
  /** Format: ISO DateTime */
  startDateTime: Scalars['String']
}

export type Nutrient = {
  __typename: 'Nutrient'
  kcal: Maybe<Scalars['Float']>
  kj: Maybe<Scalars['Float']>
  name: Maybe<Scalars['String']>
  ri: Maybe<Scalars['String']>
  value: Maybe<Scalars['String']>
}

export type NutrientClaim = {
  __typename: 'NutrientClaim'
  nutritionalClaimElement: Maybe<Scalars['String']>
  nutritionalClaimType: Maybe<Scalars['String']>
}

export type NutrientPerServing = {
  __typename: 'NutrientPerServing'
  name: Maybe<Scalars['String']>
  unit: Maybe<Scalars['String']>
  value: Maybe<Scalars['Float']>
}

export type NutrientRaw = {
  __typename: 'NutrientRaw'
  measurementPrecisionCode: Maybe<Scalars['String']>
  measurementUnitCode: Maybe<Scalars['String']>
  nutrientTypeCode: Maybe<Scalars['String']>
  quantityContained: Maybe<Scalars['Float']>
}

export type ObjectFacet = IStructuredFacet & {
  __typename: 'ObjectFacet'
  key: FacetKey
  objectValue: Array<ObjectFacetValue>
}

export type ObjectFacetValue = {
  __typename: 'ObjectFacetValue'
  doc_count: Scalars['Int']
  name: Scalars['String']
  value: Scalars['String']
}

export type Order = {
  __typename: 'Order'
  additionalInfo: Maybe<Scalars['String']>
  capturedPOSData: Maybe<ExternalOrdersPosData>
  capturedTotalInCents: Maybe<Scalars['Int']>
  cartItems: Maybe<Array<CartItem>>
  comment: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['String']>
  customer: Maybe<Customer>
  deliveryDate: Scalars['String']
  deliveryMethod: Maybe<DeliveryMethod>
  /**  Client-supplied destination where the robot order should be delivered  */
  deliveryPoint: Maybe<DeliveryLocation>
  deliverySlot: Maybe<DeliverySlot>
  deliverySlotId: Scalars['ID']
  deliveryTime: Scalars['String']
  discountCode: Maybe<Scalars['String']>
  handler: Maybe<Scalars['String']>
  homeDeliveryType: Maybe<HomeDeliveryType>
  id: Scalars['ID']
  /**
   * Can this order be canceled?
   * In most cases, this matches slot's isModifiable, but there are cases where they don't match
   */
  isCancelable: Scalars['Boolean']
  isFastTrack: Maybe<Scalars['Boolean']>
  /** Can this order be modified? */
  isModifiable: Scalars['Boolean']
  linkVerificationToken: Scalars['String']
  modifiedAt: Maybe<Scalars['String']>
  orderNumber: Maybe<Scalars['Int']>
  orderStatus: Maybe<OrderStatus>
  paymentId: Maybe<Scalars['String']>
  paymentMethod: Maybe<PaymentMethod>
  paymentStatus: Maybe<PaymentStatus>
  pickupLocation: Maybe<Scalars['String']>
  storeId: Scalars['ID']
  trackingUrl: Maybe<Scalars['String']>
}

export type OrderBy = {
  key: InputMaybe<SortKey>
  order: InputMaybe<SortOrder>
}

export type OrderEditActiveResponse = {
  __typename: 'OrderEditActiveResponse'
  orderId: Maybe<Scalars['ID']>
  orderStatus: Maybe<OrderStatus>
}

export type OrderInput = {
  additionalInfo: InputMaybe<Scalars['String']>
  cartItems: InputMaybe<Array<CartItemInput>>
  comment: InputMaybe<Scalars['String']>
  customer: CustomerInput
  /**  Client-supplied destination where the robot order should be delivered  */
  deliveryPoint: InputMaybe<Location>
  deliverySlotId: Scalars['ID']
  discountCode: InputMaybe<Scalars['String']>
  paymentMethod: PaymentMethod
  reservationId: InputMaybe<Scalars['ID']>
  storeId: Scalars['ID']
}

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Modified = 'MODIFIED',
  New = 'NEW',
  Open = 'OPEN',
}

export type PaymentCard = {
  __typename: 'PaymentCard'
  expired: Scalars['Boolean']
  expiryDate: Scalars['String']
  id: Scalars['ID']
  maskedCardNumber: Scalars['String']
  name: Scalars['String']
  type: PaymentCardType
  userGeneratedName: Scalars['String']
}

export enum PaymentCardType {
  AmericanExpress = 'AMERICAN_EXPRESS',
  Mastercard = 'MASTERCARD',
  SBusiness = 'S_BUSINESS',
  SVisa = 'S_VISA',
  Unknown = 'UNKNOWN',
  Visa = 'VISA',
}

export type PaymentCards = {
  __typename: 'PaymentCards'
  cards: Array<PaymentCard>
  defaultPaymentCardId: Maybe<Scalars['ID']>
}

export type PaymentDetailsResponse = {
  __typename: 'PaymentDetailsResponse'
  invoiceNumber: Maybe<Scalars['String']>
  paymentMethod: Maybe<PaymentMethod>
  paymentStatus: Maybe<Scalars['String']>
  savePaymentCard: Maybe<Scalars['Boolean']>
  selectedPaymentCardId: Maybe<Scalars['ID']>
  stockmannCardNumber: Maybe<Scalars['String']>
}

export enum PaymentMethod {
  CardPayment = 'CARD_PAYMENT',
  Invoice = 'INVOICE',
  OnDelivery = 'ON_DELIVERY',
}

export enum PaymentStatus {
  Canceled = 'CANCELED',
  CaptureCompleted = 'CAPTURE_COMPLETED',
  CaptureFailed = 'CAPTURE_FAILED',
  CardAuthorizationFailed = 'CARD_AUTHORIZATION_FAILED',
  CardAuthorized = 'CARD_AUTHORIZED',
  Pending = 'PENDING',
  Unavailable = 'UNAVAILABLE',
}

export type PaymentTerminalAddress = {
  __typename: 'PaymentTerminalAddress'
  redirectUrl: Scalars['String']
  transactionId: Scalars['String']
}

export type Point = {
  __typename: 'Point'
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type Product = {
  __typename: 'Product'
  allergens: Maybe<Array<Allergen>>
  approxPrice: Maybe<Scalars['Boolean']>
  availabilities: Maybe<Array<ProductAvailabilities>>
  /** @deprecated Use salesUniteCom field */
  basicQuantityUnit: Maybe<Scalars['String']>
  brandName: Maybe<Scalars['String']>
  categories: Maybe<Array<Scalars['String']>>
  category: Maybe<Scalars['String']>
  categoryId: Maybe<Scalars['String']>
  collectingPriority: Maybe<Scalars['Float']>
  /** @deprecated Prefer pricing.comparisonPrice */
  comparisonPrice: Maybe<Scalars['Float']>
  comparisonUnit: Maybe<Scalars['String']>
  consumerPackageSize: Maybe<Scalars['Float']>
  consumerPackageUnit: Maybe<Scalars['String']>
  countryName: Maybe<CountryName>
  countryOfMainRawMaterial: Maybe<Scalars['String']>
  /** @deprecated Prefer pricing.depositPrice */
  depositPrice: Maybe<Scalars['Float']>
  description: Maybe<Scalars['String']>
  descriptionShort: Maybe<Scalars['String']>
  ean: Maybe<Scalars['String']>
  frozen: Maybe<Scalars['Boolean']>
  hierarchyPath: Maybe<Array<HierarchyPathItem>>
  /**
   * The product id is currently mapped to "product.ean", but should be changed to "product.sokId".
   * @warn This is not unique across all products, so use it together with "product.storeId"
   */
  id: Scalars['ID']
  inStore: Maybe<Scalars['Boolean']>
  ingredientStatement: Maybe<Scalars['String']>
  isAgeLimitedByAlcohol: Maybe<Scalars['Boolean']>
  isGlobalFallback: Maybe<Scalars['Boolean']>
  isNewProduct: Maybe<Scalars['Boolean']>
  localized: Maybe<LocalizedProductInfo>
  location: Maybe<ProductLocation>
  marketingMessage: Maybe<Scalars['String']>
  measurement: Maybe<ProductMeasurement>
  modified: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  nutrientClaims: Maybe<Array<NutrientClaim>>
  nutrients: Maybe<Array<Nutrient>>
  nutrientsPerServing: Maybe<Array<NutrientPerServing>>
  nutrientsRaw: Maybe<Array<NutrientRaw>>
  onSale: Maybe<Scalars['Boolean']>
  packagingLabelCodes: Maybe<Array<Scalars['String']>>
  packagingLabels: Maybe<Array<Scalars['String']>>
  packagingMarkedLabelAccreditationCodes: Maybe<Array<Scalars['String']>>
  /** @deprecated Prefer pricing.currentPrice */
  price: Maybe<Scalars['Float']>
  priceUnit: Maybe<Scalars['String']>
  pricing: Maybe<ProductPricing>
  productDetails: ProductDetails
  productType: Maybe<ProductType>
  quantityMultiplier: Maybe<Scalars['Float']>
  salesUniteCom: Maybe<SalesUnit>
  slug: Maybe<Scalars['String']>
  /**
   * The globally unique id of the product, mostly for internal usage. Customers should not need
   * to see this.
   * @warn this should not be optional after migrating to Product pipeline v3
   */
  sokId: Maybe<Scalars['ID']>
  stock: Maybe<Scalars['Float']>
  store: Maybe<Store>
  storeAvailability: Maybe<Array<Product>>
  storeId: Maybe<Scalars['String']>
  storeSpecificData: Array<StoreSpecificData>
  supplierName: Maybe<Scalars['String']>
  taxPercentage: Maybe<Scalars['Float']>
  tuhtiId: Maybe<Scalars['String']>
  /** @deprecated Use measurement object instead */
  weight: Maybe<Scalars['Float']>
  /** @deprecated Use measurement object instead */
  weightUnit: Maybe<Scalars['String']>
}

export type ProductAvailabilitiesArgs = {
  date: InputMaybe<Scalars['String']>
}

export type ProductStoreAvailabilityArgs = {
  onlyIfFallback: InputMaybe<Scalars['Boolean']>
}

export type ProductAvailabilities = {
  __typename: 'ProductAvailabilities'
  date: Maybe<Scalars['String']>
  label: Maybe<ProductAvailabilityLabel>
  score: Maybe<Scalars['Float']>
}

export enum ProductAvailabilityLabel {
  Available = 'AVAILABLE',
  LimitedAvailability = 'LIMITED_AVAILABILITY',
  OutOfStock = 'OUT_OF_STOCK',
}

export type ProductDetails = {
  __typename: 'ProductDetails'
  safetyInformation: Maybe<SafetyInformation>
}

export type ProductList = {
  __typename: 'ProductList'
  from: Maybe<Scalars['Int']>
  items: Maybe<Array<Product>>
  limit: Maybe<Scalars['Int']>
  searchProvider: Maybe<SearchProvider>
  structuredFacets: Maybe<Array<ObjectFacet | StringFacet>>
  total: Maybe<Scalars['Int']>
}

export type ProductLocation = {
  __typename: 'ProductLocation'
  aisle: Maybe<Scalars['String']>
  coordinate: Maybe<Array<Scalars['Float']>>
  floor: Maybe<Scalars['Int']>
  module: Maybe<Scalars['String']>
  shelf: Maybe<Scalars['String']>
}

export type ProductMeasurement = {
  __typename: 'ProductMeasurement'
  grossWeight: Maybe<Scalars['Float']>
  grossWeightUnit: Maybe<Scalars['String']>
  netWeight: Maybe<Scalars['Float']>
  netWeightUnit: Maybe<Scalars['String']>
  pceApproxWeight: Maybe<Scalars['Float']>
}

export type ProductPricing = {
  __typename: 'ProductPricing'
  /**
   * The currently active campaign price, or null when no active campaign.
   * Also returns null when "isGlobalFallback" is true.
   * @warn The "lowest30DayPrice" must be displayed to customers alongside this.
   */
  campaignPrice: Maybe<Scalars['Float']>
  /**
   * The ISO timestamp after which the current campaign price will no longer be valid,
   * or null when no active campaign.
   */
  campaignPriceValidUntil: Maybe<Scalars['String']>
  /**
   * The price customers can use to compare prices of similar products,
   * for example by kilogram or piece.
   */
  comparisonPrice: Scalars['Float']
  /**
   * The current price the product should be sold to customers,
   * including any discounts from active campaigns.
   */
  currentPrice: Scalars['Float']
  /** The possible deposit price of the product, or null when not applicable. */
  depositPrice: Maybe<Scalars['Float']>
  /**
   * The lowest price of the product in the last 30 days, or null when "isGlobalFallback" is true.
   * @warn This must displayed to customers alongside the "campaignPrice".
   */
  lowest30DayPrice: Maybe<Scalars['Float']>
  /** The regular price of the product, *not* including any discounts from active campaigns. */
  regularPrice: Scalars['Float']
}

export enum ProductType {
  Coupon = 'COUPON',
  DeliveryProduct = 'DELIVERY_PRODUCT',
  Deposit = 'DEPOSIT',
  PackagingProduct = 'PACKAGING_PRODUCT',
  Product = 'PRODUCT',
  ServiceFee = 'SERVICE_FEE',
}

export type ProductUserEventResponse = {
  __typename: 'ProductUserEventResponse'
  success: Scalars['Boolean']
}

export enum ProductUserEventType {
  AddToCart = 'ADD_TO_CART',
  ProductClick = 'PRODUCT_CLICK',
  Purchase = 'PURCHASE',
}

export type PushNotificationDevice = {
  __typename: 'PushNotificationDevice'
  deviceToken: Scalars['String']
  name: Maybe<Scalars['String']>
}

export type Query = {
  __typename: 'Query'
  /**
   * For query input, return list of autocompleted physical addresses. Addresses *do not* include
   * geolocation coordinates.
   *
   * @param query The input that should be autocompleted
   * @param countryCode Limit address lookup inside a specific country, e.g. FIN
   * @param lang Change language of data, for example Finnish addresses in Swedish language
   */
  addressAutocomplete: Maybe<Array<AddressAutocompleteResult>>
  /**
   * For query input, return list of suggestions for physical addresses.
   *
   * @param query The input that should be autosuggested
   * @param countryCode Limit address lookup inside a specific country, e.g. FIN
   * @param lang Change language of data, for example Finnish addresses in Swedish language
   */
  addressAutosuggest: Maybe<Array<AddressGeocodeResult>>
  /**
   * For query input, return a physical address with geolocation coordinates.
   * Typically there's only one result, unless the query input is ambiguous.
   *
   * @param query the adddress input
   * @param countryCode Limit address lookup inside a specific country, e.g. FIN
   * @param lang Change language of data, for example Finnish addresses in Swedish language
   */
  addressGeocode: Maybe<Array<AddressGeocodeResult>>
  addressValidate: AddressValidateResult
  authTokens: Maybe<AuthTokens>
  authenticationRedirectPath: Maybe<Scalars['String']>
  authenticationTokens: Maybe<AuthenticationTokens>
  /**
   * Get batch recommendations of the requested type.
   * @param recommendation type
   */
  batchProductRecommendation: Maybe<BatchRecommendation>
  bonusInfo: Maybe<BonusInfo>
  bonusWidgetToken: Maybe<BonusWidgetToken>
  cartItem: Maybe<ClientCartItem>
  cartItems: Array<ClientCartItem>
  citrusAd: Maybe<CitrusAd>
  /**
   * Get complementary recommendations for a list of eans (like a shopping cart), optionally focused on a
   * single specific ean (for example when on a product page, doesn't have to be included in the list!).
   *
   * When focusing on a specific ean, the list of eans will be taken into account on a lower priority.
   *
   * @param eans the list of eans to get complementary recommendations on
   * @param focusOnEan the single product to focus on, for example when on a product page
   * @param storeId the id of the store to use for catalog
   * @param limit the maximum number of recommendations to return
   *
   * @example <caption>Get complementary products for a cart</caption>
   * query complementaryProducts(eans: cart.map(item => item.ean), storeId: cart.storeId ) {
   *   name
   *   ean
   * }
   *
   * @example <caption>Get complementary products for a specific product, taking cart into account</caption>
   * query complementaryProducts(focusOnEan: product.ean, eans: cart.map(item => item.ean), storeId: cart.storeId ) {
   *   name
   *   ean
   * }
   */
  complementaryProducts: Array<Product>
  customerDetails: Maybe<CustomerDetailsResponse>
  customerType: Maybe<Scalars['String']>
  deliveryArea: Maybe<DeliveryArea>
  deliveryAreaPins: Maybe<DeliveryAreaPinsResponse>
  deliveryAreas: Maybe<Array<DeliveryArea>>
  deliveryDetailsInfo: Maybe<DeliveryDetailsInfo>
  deliverySlot: Maybe<DeliverySlot>
  deliverySlotReservation: DeliverySlotReservationResponse
  deliveryStore: Maybe<DeliveryStore>
  domainOrder: DomainOrder
  /** Check if there are any type of slots available in given date range (1-40 days) */
  homeDeliverySlotAvailabilitiesForPostalCode: Array<DayAvailability>
  /** Search for available slots which deliver to area of given postal code (1-7 days) */
  homeDeliverySlotsForPostalCode: Array<HomeDeliverySlotsInStore>
  order: Maybe<Order>
  orderEditActive: Maybe<OrderEditActiveResponse>
  paymentDetails: Maybe<PaymentDetailsResponse>
  /**
   * @throws:
   * - ProductNotFoundError [404]
   */
  product: Maybe<Product>
  recipe: Maybe<Recipe>
  /** Returns an address for given set of coordinates. Argument lang should be in shape of "fi-FI" */
  reverseGeocode: Maybe<DeliveryAreaAddress>
  /**
   * Capacity calculation for given cart. Total capacity is two 40L (64cm * 32cm * 20cm) shopping bags.
   * Products that don't fit by it's dimension into a single shopping bag are rejected.
   * Cart total max weight is set to 20 KG.
   */
  robotCartCapacity: CartCapacity
  /**
   * @throws:
   *   - RobotDeliveryUnavailableError [400], when given coordinats are out of service area
   */
  robotNearestDeliveryPoint: Maybe<DeliveryLocation>
  /** @deprecated Use searchHomeDeliveryAreas and searchPickupDeliveryAreas instead. */
  searchDeliveryAreas: Maybe<DeliveryAreaSearchResponse>
  searchHomeDeliveryAreas: Maybe<DeliveryAreaSearchResponse>
  searchPickupDeliveryAreas: Maybe<DeliveryAreaSearchResponse>
  searchStores: Maybe<SearchStoresResponse>
  selectedAreaId: Maybe<Scalars['ID']>
  selectedBrand: Maybe<Scalars['String']>
  selectedStoreId: Maybe<Scalars['ID']>
  serviceFee: Maybe<ServiceFee>
  serviceSubscriptions: Array<ServiceSubscription>
  store: Maybe<Store>
  stores: Maybe<Array<StoreInfo>>
  userFavorites: FavoritesList
  /** @deprecated With shoppinglist multiple favorites list architecture no longer needed */
  userFavoritesList: Maybe<FavoritesList>
  /** @deprecated With shoppinglist multiple favorites list architecture no longer needed */
  userFavoritesLists: Maybe<FavoritesLists>
  userNotificationPreferences: Maybe<UserNotificationPreferences>
  userOrder: Maybe<UserOrderWithCartItems>
  userOrders: Array<UserOrder>
  userPaymentCards: Maybe<PaymentCards>
  userProductRecommendation: Maybe<CartRecommendation>
  userProfile: Maybe<UserProfile>
  userProfileGuidedEntry: Scalars['String']
}

export type QueryAddressAutocompleteArgs = {
  countryCode: Scalars['String']
  lang: InputMaybe<Scalars['String']>
  query: Scalars['String']
}

export type QueryAddressAutosuggestArgs = {
  at: InputMaybe<Location>
  countryCode: Scalars['String']
  lang: InputMaybe<Scalars['String']>
  limit: InputMaybe<Scalars['Int']>
  query: Scalars['String']
}

export type QueryAddressGeocodeArgs = {
  countryCode: InputMaybe<Scalars['String']>
  lang: InputMaybe<Scalars['String']>
  query: Scalars['String']
}

export type QueryAddressValidateArgs = {
  countryCode: InputMaybe<Scalars['String']>
  lang: InputMaybe<Scalars['String']>
  query: Scalars['String']
}

export type QueryAuthTokensArgs = {
  refreshToken: InputMaybe<Scalars['String']>
}

export type QueryBatchProductRecommendationArgs = {
  type: BatchRecommendationTypeEnum
}

export type QueryCartItemArgs = {
  id: InputMaybe<Scalars['ID']>
}

export type QueryCitrusAdArgs = {
  brand: Scalars['String']
  placement: Scalars['String']
  sessionId: Scalars['String']
  slotId: Scalars['String']
  useCustomerId: Scalars['Boolean']
}

export type QueryComplementaryProductsArgs = {
  eans: Array<Scalars['ID']>
  focusOnEan: InputMaybe<Scalars['ID']>
  limit: InputMaybe<Scalars['Int']>
  storeId: Scalars['ID']
}

export type QueryDeliveryAreaArgs = {
  id: Scalars['ID']
}

export type QueryDeliveryAreaPinsArgs = {
  domains: InputMaybe<Array<Domain>>
}

export type QueryDeliveryAreasArgs = {
  brand: InputMaybe<Scalars['String']>
  deliveryMethod: InputMaybe<DeliveryMethod>
  domains: InputMaybe<Array<Domain>>
  postalCode: InputMaybe<Scalars['String']>
}

export type QueryDeliverySlotArgs = {
  id: Scalars['ID']
}

export type QueryDeliverySlotReservationArgs = {
  reservationId: Scalars['ID']
}

export type QueryHomeDeliverySlotAvailabilitiesForPostalCodeArgs = {
  endDate: Scalars['String']
  postalCode: Scalars['String']
  startDate: Scalars['String']
}

export type QueryHomeDeliverySlotsForPostalCodeArgs = {
  endDate: Scalars['String']
  postalCode: Scalars['String']
  startDate: Scalars['String']
}

export type QueryOrderArgs = {
  id: Scalars['ID']
  linkVerificationToken: InputMaybe<Scalars['String']>
}

export type QueryProductArgs = {
  fallbackToGlobal: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  includeAgeLimitedByAlcohol: InputMaybe<Scalars['Boolean']>
  storeId: InputMaybe<Scalars['ID']>
}

export type QueryRecipeArgs = {
  id: Scalars['ID']
}

export type QueryReverseGeocodeArgs = {
  lang: InputMaybe<Scalars['String']>
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type QueryRobotCartCapacityArgs = {
  items: Array<CartItemForCapacityInput>
  storeId: Scalars['String']
}

export type QueryRobotNearestDeliveryPointArgs = {
  to: Location
}

export type QuerySearchDeliveryAreasArgs = {
  boundingBox: InputMaybe<BoundingBox>
  brands: InputMaybe<Array<InputMaybe<StoreBrand>>>
  deliveryMethod: InputMaybe<DeliveryMethod>
  domains: InputMaybe<Array<InputMaybe<Domain>>>
  freetext: InputMaybe<Scalars['String']>
  homeDeliveryType: InputMaybe<Array<HomeDeliveryType>>
  location: InputMaybe<Location>
  postalCode: InputMaybe<Scalars['String']>
  storeId: InputMaybe<Scalars['ID']>
  visibilityGroups: InputMaybe<Array<Scalars['String']>>
}

export type QuerySearchHomeDeliveryAreasArgs = {
  brands: InputMaybe<Array<InputMaybe<StoreBrand>>>
  domains: InputMaybe<Array<InputMaybe<Domain>>>
  freetext: InputMaybe<Scalars['String']>
  from: InputMaybe<Scalars['Int']>
  homeDeliveryType: InputMaybe<Array<HomeDeliveryType>>
  location: InputMaybe<Location>
  pageSize: InputMaybe<Scalars['Int']>
  postalCode: InputMaybe<Scalars['String']>
  storeId: InputMaybe<Scalars['ID']>
  visibilityGroups: InputMaybe<Array<Scalars['String']>>
}

export type QuerySearchPickupDeliveryAreasArgs = {
  boundingBox: InputMaybe<BoundingBox>
  brands: InputMaybe<Array<InputMaybe<StoreBrand>>>
  domains: InputMaybe<Array<InputMaybe<Domain>>>
  freetext: InputMaybe<Scalars['String']>
  from: InputMaybe<Scalars['Int']>
  location: InputMaybe<Location>
  pageSize: InputMaybe<Scalars['Int']>
  storeId: InputMaybe<Scalars['ID']>
  visibilityGroups: InputMaybe<Array<Scalars['String']>>
}

export type QuerySearchStoresArgs = {
  _dangerousLoadAllOverrideForSitemap: InputMaybe<Scalars['Boolean']>
  brand: InputMaybe<StoreBrand>
  country: InputMaybe<CountryCode>
  cursor: InputMaybe<Scalars['String']>
  domains: InputMaybe<Array<Domain>>
  query: InputMaybe<Scalars['String']>
}

export type QueryServiceSubscriptionsArgs = {
  serviceTypes: Array<ServiceSubscriptionServiceType>
}

export type QueryStoreArgs = {
  id: Scalars['ID']
}

export type QueryStoresArgs = {
  brand: InputMaybe<Scalars['String']>
  domains: InputMaybe<Array<Domain>>
}

export type QueryUserFavoritesListArgs = {
  listId: Scalars['ID']
}

export type QueryUserOrderArgs = {
  id: Scalars['ID']
}

export type QueryUserOrdersArgs = {
  dataSources: InputMaybe<Array<UserOrderDataSource>>
  domain: InputMaybe<Domain>
  includeSBonusOrders: InputMaybe<Scalars['Boolean']>
  limit: InputMaybe<Scalars['Int']>
}

export type QueryUserProductRecommendationArgs = {
  type: CartRecommendationType
}

export type Recipe = {
  __typename: 'Recipe'
  categories: Maybe<RecipeCategories>
  cookTime: Maybe<Scalars['Int']>
  description: Maybe<Scalars['String']>
  externalUrl: Scalars['String']
  id: Scalars['ID']
  imageUrl: Maybe<Scalars['String']>
  ingredients: Array<RecipeIngredient>
  instructions: Array<RecipeInstruction>
  name: Scalars['String']
  /** @deprecated Not supported anymore. Use yield and yieldType fields instead because this is missing the type. */
  portions: Maybe<Scalars['Int']>
  publisher: Maybe<Scalars['String']>
  slug: Scalars['String']
  yield: Scalars['Int']
  yieldType: RecipeYieldType
}

export type RecipeCategories = {
  __typename: 'RecipeCategories'
  contentConcept: Maybe<Array<Scalars['String']>>
  course: Maybe<Array<Scalars['String']>>
  cuisine: Maybe<Array<Scalars['String']>>
  diet: Maybe<Array<Scalars['String']>>
  foodType: Maybe<Array<Scalars['String']>>
  ingredientCategory: Maybe<Array<Scalars['String']>>
  meal: Maybe<Array<Scalars['String']>>
  preparation: Maybe<Array<Scalars['String']>>
  theme: Maybe<Array<Scalars['String']>>
}

export type RecipeIngredient = {
  __typename: 'RecipeIngredient'
  amountType: Maybe<Scalars['String']>
  eans: Maybe<Array<Scalars['String']>>
  groupName: Maybe<Scalars['String']>
  ingredientTitle: Maybe<Scalars['String']>
  maxAmount: Maybe<Scalars['Float']>
  minAmount: Maybe<Scalars['Float']>
  name: Scalars['String']
  products: Maybe<Array<Product>>
}

export type RecipeIngredientProductsArgs = {
  fallbackToGlobal: InputMaybe<Scalars['Boolean']>
  storeId: Scalars['ID']
}

export type RecipeInstruction = {
  __typename: 'RecipeInstruction'
  body: Scalars['String']
}

export enum RecipeYieldType {
  Piece = 'PIECE',
  Portion = 'PORTION',
  Unit = 'UNIT',
}

export type RemoveUserPaymentCardResponse = {
  __typename: 'RemoveUserPaymentCardResponse'
  id: Scalars['ID']
}

export type RenameUserPaymentCardResponse = {
  __typename: 'RenameUserPaymentCardResponse'
  id: Scalars['ID']
  name: Scalars['String']
}

export type ResetOrderPaymentCardResponse = {
  __typename: 'ResetOrderPaymentCardResponse'
  id: Scalars['ID']
}

export type RobotAvailability = {
  __typename: 'RobotAvailability'
  eta: Scalars['Int']
  status: RobotAvailabilityStatus
}

export enum RobotAvailabilityStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
}

export type SafetyInformation = {
  __typename: 'SafetyInformation'
  caption: Maybe<Scalars['String']>
  dangerClauses: Array<SafetyInformationClause>
  safetyClauses: Array<SafetyInformationClause>
  warningSymbols: Array<WarningSymbol>
}

export type SafetyInformationClause = {
  __typename: 'SafetyInformationClause'
  code: Scalars['String']
  text: Scalars['String']
}

export enum SalesUnit {
  Kg = 'KG',
  Kpl = 'KPL',
}

export type SaveUserPaymentCardResponse = {
  __typename: 'SaveUserPaymentCardResponse'
  id: Scalars['ID']
}

export enum SearchProvider {
  Elasticsearch = 'elasticsearch',
  Loop54 = 'loop54',
}

export type SearchStoresResponse = {
  __typename: 'SearchStoresResponse'
  cursor: Maybe<Scalars['String']>
  maybeHasMore: Maybe<Scalars['Boolean']>
  stores: Array<StoreInfo>
  totalCount: Scalars['Int']
}

export type ServiceFee = {
  __typename: 'ServiceFee'
  ean: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  price: Maybe<Scalars['Int']>
}

/**
 * The "Service" products are optional fees customer support can apply to an order, for example 15 EUR for creating an order over the phone.
 * These used to share the same type with real Products, but now they have their own type with only a subset of the "Product" fields.
 */
export type ServiceProduct = {
  __typename: 'ServiceProduct'
  ean: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  price: Scalars['Float']
  productType: ProductType
  /** @deprecated This field is not populated, only included for backwards-compatibility with the Product type */
  storeId: Maybe<Scalars['String']>
}

export type ServiceSubscription = {
  __typename: 'ServiceSubscription'
  /** Is the user allowed to change the current activation state */
  changeAllowed: Maybe<Scalars['Boolean']>
  serviceName: Maybe<Scalars['String']>
  serviceType: ServiceSubscriptionServiceType
  /** Service activation for this customer */
  subscribed: Scalars['Boolean']
  /** Date when this service was activated */
  subscriptionStartDate: Maybe<Scalars['String']>
}

export enum ServiceSubscriptionServiceType {
  AbcMobile = 'ABC_MOBILE',
  AnalyticsAndProfilingBarring = 'ANALYTICS_AND_PROFILING_BARRING',
  AoPostiBarring = 'AO_POSTI_BARRING',
  BalanceTerminalLastUsed = 'BALANCE_TERMINAL_LAST_USED',
  BenefitsAccountBalance = 'BENEFITS_ACCOUNT_BALANCE',
  BenefitsAccountBalanceSnavi = 'BENEFITS_ACCOUNT_BALANCE_SNAVI',
  CommonRegisterDigili = 'COMMON_REGISTER_DIGILI',
  CommonRegisterSpankki = 'COMMON_REGISTER_SPANKKI',
  EreceiptArchive = 'ERECEIPT_ARCHIVE',
  EreceiptPaku = 'ERECEIPT_PAKU',
  MarketingMailBarring = 'MARKETING_MAIL_BARRING',
  ProductPurchaseDataCollectionBarring = 'PRODUCT_PURCHASE_DATA_COLLECTION_BARRING',
  ResearchSurveyBarring = 'RESEARCH_SURVEY_BARRING',
  SmobiiliLastUsed = 'SMOBIILI_LAST_USED',
  TelephoneMarketingBarring = 'TELEPHONE_MARKETING_BARRING',
  TelephoneRobinsonBarring = 'TELEPHONE_ROBINSON_BARRING',
  ThirdPartyTargetingBarring = 'THIRD_PARTY_TARGETING_BARRING',
  WarrantyReceiptFeature = 'WARRANTY_RECEIPT_FEATURE',
  WebAoPosti = 'WEB_AO_POSTI',
}

export enum ServiceType {
  AppHosted = 'APP_HOSTED',
  PaymentGatewayHosted = 'PAYMENT_GATEWAY_HOSTED',
}

export type SetDefaultUserPaymentCardResponse = {
  __typename: 'SetDefaultUserPaymentCardResponse'
  id: Scalars['ID']
}

export enum SlotAvailability {
  Available = 'AVAILABLE',
  Full = 'FULL',
}

export enum SlotRecurrence {
  ModifiedWeekly = 'MODIFIED_WEEKLY',
  Once = 'ONCE',
  Weekly = 'WEEKLY',
}

export enum SortKey {
  ComparisonPrice = 'comparisonPrice',
  Name = 'name',
  Price = 'price',
  Score = 'score',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type Store = {
  __typename: 'Store'
  availablePaymentMethods: Maybe<Array<PaymentMethod>>
  brand: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  coOperative: Maybe<CoOperative>
  contactInfo: Maybe<StoreContactInfo>
  discountProduct: Maybe<DiscountProduct>
  domains: Array<Domain>
  feedback: Maybe<StoreFeedback>
  id: Scalars['ID']
  location: Maybe<StoreLocation>
  /** List lowest prices for available delivery methods. */
  lowestDeliveryPrices: Maybe<Array<Maybe<StoreLowestDeliveryPrice>>>
  /**
   * List the delivery and packaging material fees when ordering from this store. This used to return the real "Product"
   * type but is now separated with only a subset of the fields available. For backwards-compatibility this returns a list of
   * single "MandatoryProduct" type, but internally the data is split into "DeliveryProducts" and "PackagingMaterialProducts".
   */
  mandatoryProducts: Maybe<Array<MandatoryProduct>>
  name: Scalars['String']
  navigation: Maybe<Array<NavigationItem>>
  postalCode: Maybe<Scalars['String']>
  products: Maybe<ProductList>
  /** List the available service fees for the store, that customer support can apply to an order. */
  serviceProducts: Maybe<Array<ServiceProduct>>
  services: Maybe<Array<StoreService>>
  shortName: Maybe<Scalars['String']>
  slug: Scalars['String']
  street: Maybe<Scalars['String']>
  weeklyOpeningHours: Maybe<Array<StoreWeeklyOpeningTime>>
}

export type StoreDiscountProductArgs = {
  discountCode: InputMaybe<Scalars['String']>
}

export type StoreMandatoryProductsArgs = {
  deliveryMethod: InputMaybe<DeliveryMethod>
  deliverySlotId: InputMaybe<Scalars['String']>
  orderId: InputMaybe<Scalars['String']>
  packagingMaterialEan: InputMaybe<Scalars['String']>
  reservationId: InputMaybe<Scalars['String']>
}

export type StoreProductsArgs = {
  eans: InputMaybe<Array<Scalars['String']>>
  fallbackToGlobal: InputMaybe<Scalars['Boolean']>
  filters: InputMaybe<Array<Filters>>
  from: InputMaybe<Scalars['Int']>
  generatedSessionId: InputMaybe<Scalars['String']>
  hierarchyId: InputMaybe<Scalars['String']>
  includeAgeLimitedByAlcohol: InputMaybe<Scalars['Boolean']>
  limit: InputMaybe<Scalars['Int']>
  loop54DirectSearch: InputMaybe<Scalars['Boolean']>
  order: InputMaybe<SortOrder>
  orderBy: InputMaybe<SortKey>
  queryString: InputMaybe<Scalars['String']>
  searchProvider: InputMaybe<SearchProvider>
  slug: InputMaybe<Scalars['String']>
  structuredFacets: InputMaybe<Array<StructuredFacetInput>>
  useRandomId: InputMaybe<Scalars['Boolean']>
}

export type StoreAddress = {
  __typename: 'StoreAddress'
  country: Maybe<Country>
  municipality: Municipality
  postcode: Scalars['String']
  postcodeName: Maybe<LocalizableText>
  street: LocalizableText
}

export enum StoreBrand {
  Abc = 'ABC',
  Alepa = 'ALEPA',
  Eprisma = 'EPRISMA',
  Herkku = 'HERKKU',
  MestarinHerkku = 'MESTARIN_HERKKU',
  Prisma = 'PRISMA',
  Sale = 'SALE',
  SokosHerkku = 'SOKOS_HERKKU',
  SMarket = 'S_MARKET',
}

export type StoreContactInfo = {
  __typename: 'StoreContactInfo'
  email: Maybe<Scalars['String']>
  phoneNumber: Maybe<StorePhoneNumber>
}

export type StoreDayOpeningTime = {
  __typename: 'StoreDayOpeningTime'
  date: Scalars['String']
  day: DayLabel
  exceptional: Maybe<Scalars['Boolean']>
  message: Maybe<LocalizedText>
  mode: StoreOpeningTimeRangeLabel
  ranges: Maybe<Array<StoreOpeningTimeRange>>
}

export type StoreFeedback = {
  __typename: 'StoreFeedback'
  url: Maybe<LocalizableText>
}

export type StoreInfo = {
  __typename: 'StoreInfo'
  availablePaymentMethods: Maybe<Array<PaymentMethod>>
  brand: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  contactInfo: Maybe<StoreContactInfo>
  domains: Array<Domain>
  feedback: Maybe<StoreFeedback>
  id: Scalars['ID']
  location: Maybe<StoreLocation>
  name: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
  services: Maybe<Array<StoreService>>
  shortName: Maybe<Scalars['String']>
  slug: Maybe<Scalars['String']>
  street: Maybe<Scalars['String']>
  weeklyOpeningHours: Maybe<Array<StoreWeeklyOpeningTime>>
}

export type StoreLocation = {
  __typename: 'StoreLocation'
  address: Maybe<StoreAddress>
  coordinates: Maybe<Coordinates>
}

export type StoreLowestDeliveryPrice = {
  __typename: 'StoreLowestDeliveryPrice'
  areaId: Scalars['String']
  deliveryMethod: DeliveryMethod
  description: Maybe<Scalars['String']>
  isFastTrack: Scalars['Boolean']
  name: Maybe<Scalars['String']>
  price: Scalars['Float']
}

export enum StoreOpeningStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export type StoreOpeningTimeRange = {
  __typename: 'StoreOpeningTimeRange'
  close: Scalars['String']
  closeOnSameDay: Maybe<Scalars['Boolean']>
  open: Scalars['String']
}

export enum StoreOpeningTimeRangeLabel {
  AllDay = 'ALL_DAY',
  ByReservation = 'BY_RESERVATION',
  Closed = 'CLOSED',
  Range = 'RANGE',
}

export type StorePhoneNumber = {
  __typename: 'StorePhoneNumber'
  callCharge: Maybe<LocalizableText>
  callChargeGroup: Maybe<Scalars['String']>
  number: Scalars['String']
}

export type StoreService = {
  __typename: 'StoreService'
  code: Scalars['String']
  name: Maybe<LocalizableText>
}

export type StoreSpecificData = {
  __typename: 'StoreSpecificData'
  newProduct: Scalars['Boolean']
  placeOfBusinessId: Scalars['ID']
}

export type StoreWeeklyOpeningTime = {
  __typename: 'StoreWeeklyOpeningTime'
  openingTimes: Array<StoreDayOpeningTime>
  weekNumber: Scalars['Int']
}

export type StringFacet = IStructuredFacet & {
  __typename: 'StringFacet'
  key: FacetKey
  stringValue: Array<Scalars['String']>
}

export type StructuredFacetInput = {
  key: FacetKey
  order: InputMaybe<SortOrder>
}

export type UpdateAuthenticationTokensResponse = {
  __typename: 'UpdateAuthenticationTokensResponse'
  accessToken: Maybe<Scalars['String']>
  idToken: Maybe<Scalars['String']>
  refreshToken: Maybe<Scalars['String']>
}

export type UpdateCustomerInfoResponse = {
  __typename: 'UpdateCustomerInfoResponse'
  companyName: Maybe<Scalars['String']>
  customerType: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  stockmannCardNumber: Maybe<Scalars['String']>
}

export type UpdateDeliveryDetailsResponse = {
  __typename: 'UpdateDeliveryDetailsResponse'
  additionalInfo: Maybe<Scalars['String']>
  address: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  deliveryDate: Maybe<Scalars['String']>
  deliveryMethod: Maybe<DeliveryMethod>
  deliverySlotId: Maybe<Scalars['ID']>
  deliverySlotPrice: Maybe<Scalars['Float']>
  deliveryTime: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
}

export type UpdatePersonalInfoResponse = {
  __typename: 'UpdatePersonalInfoResponse'
  comment: Maybe<Scalars['String']>
  companyName: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  invoiceNumber: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
}

export enum UserNotificationMediumEnum {
  PushNotification = 'PUSH_NOTIFICATION',
}

export type UserNotificationPreferences = {
  __typename: 'UserNotificationPreferences'
  marketing: UserNotificationSubscription
  transactional: UserNotificationSubscription
}

export type UserNotificationSubscription = {
  __typename: 'UserNotificationSubscription'
  enabled: Maybe<Scalars['Boolean']>
  pushNotification: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export enum UserNotificationSubscriptionEnum {
  Marketing = 'MARKETING',
  Transactional = 'TRANSACTIONAL',
}

export type UserOrder = {
  __typename: 'UserOrder'
  createdAt: Scalars['String']
  customer: Maybe<Customer>
  dataSource: UserOrderDataSource
  deliveryDate: Scalars['String']
  deliveryMethod: DeliveryMethod
  deliverySlotClosed: Maybe<Scalars['Boolean']>
  /**
   * The ISO timestamp after which new orders can't be sent to slot.
   * Note modification cutoff can be at different time.
   * Can be missing if slot information can't be fetced.
   * For example "2023-02-09T09:00:00+03:00".
   */
  deliverySlotClosingTimestamp: Maybe<Scalars['String']>
  /**
   * The ISO timestamp after which an order created in the slot can no longer be modified or cancelled.
   * Can be missing if slot information can't be fetced.
   * This can differ from slot's modificationTimestamp if extra time is given for modifications,
   * for example for fixing payment card.
   * This time can change after modification fixing problems is sent.
   * For example "2023-02-09T09:00:00+03:00".
   */
  deliverySlotModificationTimestamp: Maybe<Scalars['String']>
  deliveryTime: Maybe<Scalars['String']>
  homeDeliveryType: Maybe<HomeDeliveryType>
  id: Scalars['String']
  /**
   * Can this order be canceled?
   * In most cases, this matches slot's isModifiable, but there are cases where they don't match
   */
  isCancelable: Scalars['Boolean']
  isFastTrack: Maybe<Scalars['Boolean']>
  /** Can this order be modified? */
  isModifiable: Scalars['Boolean']
  modifiedAt: Maybe<Scalars['String']>
  orderNumber: Scalars['String']
  orderStatus: OrderStatus
  paymentMethod: Maybe<PaymentMethod>
  paymentStatus: Maybe<PaymentStatus>
  pickupLocation: Maybe<Scalars['String']>
  storeId: Scalars['String']
  storeName: Maybe<Scalars['String']>
  totalCost: Scalars['Float']
  trackingUrl: Maybe<Scalars['String']>
  userId: Maybe<Scalars['String']>
}

export type UserOrderCartItem = {
  __typename: 'UserOrderCartItem'
  additionalInfo: Maybe<Scalars['String']>
  basicQuantityUnit: Maybe<Scalars['String']>
  ean: Scalars['String']
  id: Scalars['ID']
  itemCount: Scalars['Float']
  name: Scalars['String']
  price: Maybe<Scalars['Float']>
  priceUnit: Maybe<Scalars['String']>
  productType: ProductType
  replace: Maybe<Scalars['Boolean']>
}

export enum UserOrderDataSource {
  /** @deprecated Foodie does not exist */
  Foodie = 'FOODIE',
  SKaupat = 'S_KAUPAT',
}

export type UserOrderWithCartItems = {
  __typename: 'UserOrderWithCartItems'
  cartItems: Array<UserOrderCartItem>
  createdAt: Scalars['String']
  customer: Maybe<Customer>
  dataSource: UserOrderDataSource
  deliveryDate: Scalars['String']
  deliveryMethod: DeliveryMethod
  deliverySlotClosed: Maybe<Scalars['Boolean']>
  deliverySlotClosingTimestamp: Maybe<Scalars['String']>
  deliverySlotModificationTimestamp: Maybe<Scalars['String']>
  deliveryTime: Maybe<Scalars['String']>
  id: Scalars['String']
  isFastTrack: Maybe<Scalars['Boolean']>
  isModifiable: Scalars['Boolean']
  modifiedAt: Maybe<Scalars['String']>
  orderNumber: Scalars['String']
  orderStatus: OrderStatus
  paymentMethod: Maybe<PaymentMethod>
  paymentStatus: Maybe<PaymentStatus>
  pickupLocation: Maybe<Scalars['String']>
  storeId: Scalars['String']
  storeName: Maybe<Scalars['String']>
  totalCost: Scalars['Float']
  userId: Maybe<Scalars['String']>
}

export type UserProfile = {
  __typename: 'UserProfile'
  address: Maybe<Address>
  company: Maybe<Company>
  customerType: CustomerType
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  isEmailVerified: Maybe<Scalars['Boolean']>
  isPhoneNumberVerified: Maybe<Scalars['Boolean']>
  lastName: Maybe<Scalars['String']>
  /** Salesforce PersonContactId */
  personContactId: Maybe<Scalars['String']>
  phoneNumber: Maybe<Scalars['String']>
  /** @deprecated Not in use, remove client usage */
  tags: Maybe<Array<Scalars['String']>>
  /** @deprecated Will be removed, use sub from accessToken instead */
  userId: Scalars['String']
}

export type WarningSymbol = {
  __typename: 'WarningSymbol'
  code: WarningSymbolCode
  description: Scalars['String']
}

export enum WarningSymbolCode {
  Ghs01 = 'GHS01',
  Ghs02 = 'GHS02',
  Ghs03 = 'GHS03',
  Ghs04 = 'GHS04',
  Ghs05 = 'GHS05',
  Ghs06 = 'GHS06',
  Ghs07 = 'GHS07',
  Ghs08 = 'GHS08',
  Ghs09 = 'GHS09',
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<
  TResult,
  TParent,
  TContext,
  TArgs
>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info?: GraphQLResolveInfo,
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info?: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info?: GraphQLResolveInfo,
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info?: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info?: GraphQLResolveInfo,
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info?: GraphQLResolveInfo,
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Address: ResolverTypeWrapper<Address>
  AddressAutocompleteResult: ResolverTypeWrapper<AddressAutocompleteResult>
  AddressGeocodeResult: ResolverTypeWrapper<AddressGeocodeResult>
  AddressGeocodeResultType: AddressGeocodeResultType
  AddressValidateResult: ResolverTypeWrapper<AddressValidateResult>
  AddressValidationError: AddressValidationError
  AlcoholSellingStatus: AlcoholSellingStatus
  Allergen: ResolverTypeWrapper<Allergen>
  AuthTokens: ResolverTypeWrapper<AuthTokens>
  AuthenticationTokens: ResolverTypeWrapper<AuthenticationTokens>
  AuthorizePaymentResponse: ResolverTypeWrapper<AuthorizePaymentResponse>
  AvailablePackagingMaterial: ResolverTypeWrapper<AvailablePackagingMaterial>
  BatchRecommendation: ResolverTypeWrapper<BatchRecommendation>
  BatchRecommendationProduct: ResolverTypeWrapper<BatchRecommendationProduct>
  BatchRecommendationTypeEnum: BatchRecommendationTypeEnum
  BonusInfo: ResolverTypeWrapper<BonusInfo>
  BonusWidgetToken: ResolverTypeWrapper<BonusWidgetToken>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  BoundingBox: BoundingBox
  CacheControlScope: CacheControlScope
  CartCapacity: ResolverTypeWrapper<CartCapacity>
  CartCapacityDimensionWarning: ResolverTypeWrapper<CartCapacityDimensionWarning>
  CartCapacityNumericWarning: ResolverTypeWrapper<CartCapacityNumericWarning>
  CartCapacityWarningType: CartCapacityWarningType
  CartItem: ResolverTypeWrapper<CartItem>
  CartItemCapacity: ResolverTypeWrapper<CartItemCapacity>
  CartItemCapacityStatus: CartItemCapacityStatus
  CartItemForCapacityInput: CartItemForCapacityInput
  CartItemInput: CartItemInput
  CartRecommendation: ResolverTypeWrapper<CartRecommendation>
  CartRecommendationProduct: ResolverTypeWrapper<CartRecommendationProduct>
  CartRecommendationType: CartRecommendationType
  CitrusAd: ResolverTypeWrapper<CitrusAd>
  CitrusAdBanner: ResolverTypeWrapper<CitrusAdBanner>
  CitrusAdUserEventType: CitrusAdUserEventType
  ClientCartItem: ResolverTypeWrapper<ClientCartItem>
  CoOperative: CoOperative
  CollatedDeliverySlot: ResolverTypeWrapper<CollatedDeliverySlot>
  Company: ResolverTypeWrapper<Company>
  Coordinates: ResolverTypeWrapper<Coordinates>
  Country: ResolverTypeWrapper<Country>
  CountryCode: CountryCode
  CountryName: ResolverTypeWrapper<CountryName>
  Customer: ResolverTypeWrapper<Customer>
  CustomerDetailsResponse: ResolverTypeWrapper<CustomerDetailsResponse>
  CustomerInput: CustomerInput
  CustomerType: CustomerType
  DayAvailability: ResolverTypeWrapper<DayAvailability>
  DayLabel: DayLabel
  DeleteUserDataResult: ResolverTypeWrapper<DeleteUserDataResult>
  DeliveryArea: ResolverTypeWrapper<DeliveryArea>
  DeliveryAreaAddress: ResolverTypeWrapper<DeliveryAreaAddress>
  DeliveryAreaPin: ResolverTypeWrapper<DeliveryAreaPin>
  DeliveryAreaPinsResponse: ResolverTypeWrapper<DeliveryAreaPinsResponse>
  DeliveryAreaSearchResponse: ResolverTypeWrapper<DeliveryAreaSearchResponse>
  DeliveryDetailsInfo: ResolverTypeWrapper<DeliveryDetailsInfo>
  DeliveryDistrict: ResolverTypeWrapper<DeliveryDistrict>
  DeliveryLocation: ResolverTypeWrapper<DeliveryLocation>
  DeliveryMethod: DeliveryMethod
  DeliverySlot: ResolverTypeWrapper<DeliverySlot>
  DeliverySlotReservationResponse: ResolverTypeWrapper<DeliverySlotReservationResponse>
  DeliveryStore: ResolverTypeWrapper<DeliveryStore>
  DeliveryTimeStatus: DeliveryTimeStatus
  DeviceType: DeviceType
  DiscountProduct: ResolverTypeWrapper<DiscountProduct>
  DiscountProductType: DiscountProductType
  Domain: Domain
  DomainCustomer: ResolverTypeWrapper<DomainCustomer>
  DomainDelivery: ResolverTypeWrapper<DomainDelivery>
  DomainOrder: ResolverTypeWrapper<DomainOrder>
  DomainPayment: ResolverTypeWrapper<DomainPayment>
  EanProduct: ResolversTypes['CartRecommendationProduct']
  ExternalOrdersPOSData: ResolverTypeWrapper<ExternalOrdersPosData>
  ExternalOrdersPOSRoyaltyTransation: ResolverTypeWrapper<ExternalOrdersPosRoyaltyTransation>
  ExternalOrdersPOSShipment: ResolverTypeWrapper<ExternalOrdersPosShipment>
  FacetKey: FacetKey
  FastTrackOpeningHours: ResolverTypeWrapper<FastTrackOpeningHours>
  FavoriteItem: ResolverTypeWrapper<FavoriteItem>
  FavoritesList: ResolverTypeWrapper<FavoritesList>
  FavoritesListDeleteResult: ResolverTypeWrapper<FavoritesListDeleteResult>
  FavoritesListPatchInput: FavoritesListPatchInput
  FavoritesLists: ResolverTypeWrapper<FavoritesLists>
  FavoritesOperationDataInput: FavoritesOperationDataInput
  FavoritesOperationInput: FavoritesOperationInput
  FavoritesOperationOperationEnum: FavoritesOperationOperationEnum
  FavoritesOperationResult: ResolverTypeWrapper<FavoritesOperationResult>
  Filters: Filters
  Float: ResolverTypeWrapper<Scalars['Float']>
  Hierarchy: ResolverTypeWrapper<Hierarchy>
  HierarchyPathItem: ResolverTypeWrapper<HierarchyPathItem>
  HomeDeliverySlot: ResolverTypeWrapper<HomeDeliverySlot>
  HomeDeliverySlotsInStore: ResolverTypeWrapper<HomeDeliverySlotsInStore>
  HomeDeliveryType: HomeDeliveryType
  ICartCapacityWarning:
    | ResolversTypes['CartCapacityDimensionWarning']
    | ResolversTypes['CartCapacityNumericWarning']
  ID: ResolverTypeWrapper<Scalars['ID']>
  IHomeDeliverySlot: ResolversTypes['HomeDeliverySlot']
  IStructuredFacet: ResolversTypes['ObjectFacet'] | ResolversTypes['StringFacet']
  Int: ResolverTypeWrapper<Scalars['Int']>
  LocalizableText: ResolverTypeWrapper<LocalizableText>
  LocalizedProductInfo: ResolverTypeWrapper<LocalizedProductInfo>
  LocalizedProductInfoFields: ResolverTypeWrapper<LocalizedProductInfoFields>
  LocalizedText: ResolverTypeWrapper<LocalizedText>
  Location: Location
  MandatoryProduct: ResolverTypeWrapper<MandatoryProduct>
  MaterialType: MaterialType
  Municipality: ResolverTypeWrapper<Municipality>
  Mutation: ResolverTypeWrapper<{}>
  Navigation: ResolverTypeWrapper<Navigation>
  NavigationItem: ResolverTypeWrapper<NavigationItem>
  NextDeliverySlot: ResolverTypeWrapper<NextDeliverySlot>
  Nutrient: ResolverTypeWrapper<Nutrient>
  NutrientClaim: ResolverTypeWrapper<NutrientClaim>
  NutrientPerServing: ResolverTypeWrapper<NutrientPerServing>
  NutrientRaw: ResolverTypeWrapper<NutrientRaw>
  ObjectFacet: ResolverTypeWrapper<ObjectFacet>
  ObjectFacetValue: ResolverTypeWrapper<ObjectFacetValue>
  Order: ResolverTypeWrapper<Order>
  OrderBy: OrderBy
  OrderEditActiveResponse: ResolverTypeWrapper<OrderEditActiveResponse>
  OrderInput: OrderInput
  OrderStatus: OrderStatus
  PaymentCard: ResolverTypeWrapper<PaymentCard>
  PaymentCardType: PaymentCardType
  PaymentCards: ResolverTypeWrapper<PaymentCards>
  PaymentDetailsResponse: ResolverTypeWrapper<PaymentDetailsResponse>
  PaymentMethod: PaymentMethod
  PaymentStatus: PaymentStatus
  PaymentTerminalAddress: ResolverTypeWrapper<PaymentTerminalAddress>
  Point: ResolverTypeWrapper<Point>
  Product: ResolverTypeWrapper<Product>
  ProductAvailabilities: ResolverTypeWrapper<ProductAvailabilities>
  ProductAvailabilityLabel: ProductAvailabilityLabel
  ProductDetails: ResolverTypeWrapper<ProductDetails>
  ProductList: ResolverTypeWrapper<ProductList>
  ProductLocation: ResolverTypeWrapper<ProductLocation>
  ProductMeasurement: ResolverTypeWrapper<ProductMeasurement>
  ProductPricing: ResolverTypeWrapper<ProductPricing>
  ProductType: ProductType
  ProductUserEventResponse: ResolverTypeWrapper<ProductUserEventResponse>
  ProductUserEventType: ProductUserEventType
  PushNotificationDevice: ResolverTypeWrapper<PushNotificationDevice>
  Query: ResolverTypeWrapper<{}>
  Recipe: ResolverTypeWrapper<Recipe>
  RecipeCategories: ResolverTypeWrapper<RecipeCategories>
  RecipeIngredient: ResolverTypeWrapper<RecipeIngredient>
  RecipeInstruction: ResolverTypeWrapper<RecipeInstruction>
  RecipeYieldType: RecipeYieldType
  RemoveUserPaymentCardResponse: ResolverTypeWrapper<RemoveUserPaymentCardResponse>
  RenameUserPaymentCardResponse: ResolverTypeWrapper<RenameUserPaymentCardResponse>
  ResetOrderPaymentCardResponse: ResolverTypeWrapper<ResetOrderPaymentCardResponse>
  RobotAvailability: ResolverTypeWrapper<RobotAvailability>
  RobotAvailabilityStatus: RobotAvailabilityStatus
  SafetyInformation: ResolverTypeWrapper<SafetyInformation>
  SafetyInformationClause: ResolverTypeWrapper<SafetyInformationClause>
  SalesUnit: SalesUnit
  SaveUserPaymentCardResponse: ResolverTypeWrapper<SaveUserPaymentCardResponse>
  SearchProvider: SearchProvider
  SearchStoresResponse: ResolverTypeWrapper<SearchStoresResponse>
  ServiceFee: ResolverTypeWrapper<ServiceFee>
  ServiceProduct: ResolverTypeWrapper<ServiceProduct>
  ServiceSubscription: ResolverTypeWrapper<ServiceSubscription>
  ServiceSubscriptionServiceType: ServiceSubscriptionServiceType
  ServiceType: ServiceType
  SetDefaultUserPaymentCardResponse: ResolverTypeWrapper<SetDefaultUserPaymentCardResponse>
  SlotAvailability: SlotAvailability
  SlotRecurrence: SlotRecurrence
  SortKey: SortKey
  SortOrder: SortOrder
  Store: ResolverTypeWrapper<Store>
  StoreAddress: ResolverTypeWrapper<StoreAddress>
  StoreBrand: StoreBrand
  StoreContactInfo: ResolverTypeWrapper<StoreContactInfo>
  StoreDayOpeningTime: ResolverTypeWrapper<StoreDayOpeningTime>
  StoreFeedback: ResolverTypeWrapper<StoreFeedback>
  StoreInfo: ResolverTypeWrapper<StoreInfo>
  StoreLocation: ResolverTypeWrapper<StoreLocation>
  StoreLowestDeliveryPrice: ResolverTypeWrapper<StoreLowestDeliveryPrice>
  StoreOpeningStatus: StoreOpeningStatus
  StoreOpeningTimeRange: ResolverTypeWrapper<StoreOpeningTimeRange>
  StoreOpeningTimeRangeLabel: StoreOpeningTimeRangeLabel
  StorePhoneNumber: ResolverTypeWrapper<StorePhoneNumber>
  StoreService: ResolverTypeWrapper<StoreService>
  StoreSpecificData: ResolverTypeWrapper<StoreSpecificData>
  StoreWeeklyOpeningTime: ResolverTypeWrapper<StoreWeeklyOpeningTime>
  String: ResolverTypeWrapper<Scalars['String']>
  StringFacet: ResolverTypeWrapper<StringFacet>
  StructuredFacetInput: StructuredFacetInput
  UpdateAuthenticationTokensResponse: ResolverTypeWrapper<UpdateAuthenticationTokensResponse>
  UpdateCustomerInfoResponse: ResolverTypeWrapper<UpdateCustomerInfoResponse>
  UpdateDeliveryDetailsResponse: ResolverTypeWrapper<UpdateDeliveryDetailsResponse>
  UpdatePersonalInfoResponse: ResolverTypeWrapper<UpdatePersonalInfoResponse>
  UserNotificationMediumEnum: UserNotificationMediumEnum
  UserNotificationPreferences: ResolverTypeWrapper<UserNotificationPreferences>
  UserNotificationSubscription: ResolverTypeWrapper<UserNotificationSubscription>
  UserNotificationSubscriptionEnum: UserNotificationSubscriptionEnum
  UserOrder: ResolverTypeWrapper<UserOrder>
  UserOrderCartItem: ResolverTypeWrapper<UserOrderCartItem>
  UserOrderDataSource: UserOrderDataSource
  UserOrderWithCartItems: ResolverTypeWrapper<UserOrderWithCartItems>
  UserProfile: ResolverTypeWrapper<UserProfile>
  Void: ResolverTypeWrapper<Scalars['Void']>
  WarningSymbol: ResolverTypeWrapper<WarningSymbol>
  WarningSymbolCode: WarningSymbolCode
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Address: Address
  AddressAutocompleteResult: AddressAutocompleteResult
  AddressGeocodeResult: AddressGeocodeResult
  AddressValidateResult: AddressValidateResult
  Allergen: Allergen
  AuthTokens: AuthTokens
  AuthenticationTokens: AuthenticationTokens
  AuthorizePaymentResponse: AuthorizePaymentResponse
  AvailablePackagingMaterial: AvailablePackagingMaterial
  BatchRecommendation: BatchRecommendation
  BatchRecommendationProduct: BatchRecommendationProduct
  BonusInfo: BonusInfo
  BonusWidgetToken: BonusWidgetToken
  Boolean: Scalars['Boolean']
  BoundingBox: BoundingBox
  CartCapacity: CartCapacity
  CartCapacityDimensionWarning: CartCapacityDimensionWarning
  CartCapacityNumericWarning: CartCapacityNumericWarning
  CartItem: CartItem
  CartItemCapacity: CartItemCapacity
  CartItemForCapacityInput: CartItemForCapacityInput
  CartItemInput: CartItemInput
  CartRecommendation: CartRecommendation
  CartRecommendationProduct: CartRecommendationProduct
  CitrusAd: CitrusAd
  CitrusAdBanner: CitrusAdBanner
  ClientCartItem: ClientCartItem
  CollatedDeliverySlot: CollatedDeliverySlot
  Company: Company
  Coordinates: Coordinates
  Country: Country
  CountryName: CountryName
  Customer: Customer
  CustomerDetailsResponse: CustomerDetailsResponse
  CustomerInput: CustomerInput
  DayAvailability: DayAvailability
  DeleteUserDataResult: DeleteUserDataResult
  DeliveryArea: DeliveryArea
  DeliveryAreaAddress: DeliveryAreaAddress
  DeliveryAreaPin: DeliveryAreaPin
  DeliveryAreaPinsResponse: DeliveryAreaPinsResponse
  DeliveryAreaSearchResponse: DeliveryAreaSearchResponse
  DeliveryDetailsInfo: DeliveryDetailsInfo
  DeliveryDistrict: DeliveryDistrict
  DeliveryLocation: DeliveryLocation
  DeliverySlot: DeliverySlot
  DeliverySlotReservationResponse: DeliverySlotReservationResponse
  DeliveryStore: DeliveryStore
  DiscountProduct: DiscountProduct
  DomainCustomer: DomainCustomer
  DomainDelivery: DomainDelivery
  DomainOrder: DomainOrder
  DomainPayment: DomainPayment
  EanProduct: ResolversParentTypes['CartRecommendationProduct']
  ExternalOrdersPOSData: ExternalOrdersPosData
  ExternalOrdersPOSRoyaltyTransation: ExternalOrdersPosRoyaltyTransation
  ExternalOrdersPOSShipment: ExternalOrdersPosShipment
  FastTrackOpeningHours: FastTrackOpeningHours
  FavoriteItem: FavoriteItem
  FavoritesList: FavoritesList
  FavoritesListDeleteResult: FavoritesListDeleteResult
  FavoritesListPatchInput: FavoritesListPatchInput
  FavoritesLists: FavoritesLists
  FavoritesOperationDataInput: FavoritesOperationDataInput
  FavoritesOperationInput: FavoritesOperationInput
  FavoritesOperationResult: FavoritesOperationResult
  Filters: Filters
  Float: Scalars['Float']
  Hierarchy: Hierarchy
  HierarchyPathItem: HierarchyPathItem
  HomeDeliverySlot: HomeDeliverySlot
  HomeDeliverySlotsInStore: HomeDeliverySlotsInStore
  ICartCapacityWarning:
    | ResolversParentTypes['CartCapacityDimensionWarning']
    | ResolversParentTypes['CartCapacityNumericWarning']
  ID: Scalars['ID']
  IHomeDeliverySlot: ResolversParentTypes['HomeDeliverySlot']
  IStructuredFacet: ResolversParentTypes['ObjectFacet'] | ResolversParentTypes['StringFacet']
  Int: Scalars['Int']
  LocalizableText: LocalizableText
  LocalizedProductInfo: LocalizedProductInfo
  LocalizedProductInfoFields: LocalizedProductInfoFields
  LocalizedText: LocalizedText
  Location: Location
  MandatoryProduct: MandatoryProduct
  Municipality: Municipality
  Mutation: {}
  Navigation: Navigation
  NavigationItem: NavigationItem
  NextDeliverySlot: NextDeliverySlot
  Nutrient: Nutrient
  NutrientClaim: NutrientClaim
  NutrientPerServing: NutrientPerServing
  NutrientRaw: NutrientRaw
  ObjectFacet: ObjectFacet
  ObjectFacetValue: ObjectFacetValue
  Order: Order
  OrderBy: OrderBy
  OrderEditActiveResponse: OrderEditActiveResponse
  OrderInput: OrderInput
  PaymentCard: PaymentCard
  PaymentCards: PaymentCards
  PaymentDetailsResponse: PaymentDetailsResponse
  PaymentTerminalAddress: PaymentTerminalAddress
  Point: Point
  Product: Product
  ProductAvailabilities: ProductAvailabilities
  ProductDetails: ProductDetails
  ProductList: ProductList
  ProductLocation: ProductLocation
  ProductMeasurement: ProductMeasurement
  ProductPricing: ProductPricing
  ProductUserEventResponse: ProductUserEventResponse
  PushNotificationDevice: PushNotificationDevice
  Query: {}
  Recipe: Recipe
  RecipeCategories: RecipeCategories
  RecipeIngredient: RecipeIngredient
  RecipeInstruction: RecipeInstruction
  RemoveUserPaymentCardResponse: RemoveUserPaymentCardResponse
  RenameUserPaymentCardResponse: RenameUserPaymentCardResponse
  ResetOrderPaymentCardResponse: ResetOrderPaymentCardResponse
  RobotAvailability: RobotAvailability
  SafetyInformation: SafetyInformation
  SafetyInformationClause: SafetyInformationClause
  SaveUserPaymentCardResponse: SaveUserPaymentCardResponse
  SearchStoresResponse: SearchStoresResponse
  ServiceFee: ServiceFee
  ServiceProduct: ServiceProduct
  ServiceSubscription: ServiceSubscription
  SetDefaultUserPaymentCardResponse: SetDefaultUserPaymentCardResponse
  Store: Store
  StoreAddress: StoreAddress
  StoreContactInfo: StoreContactInfo
  StoreDayOpeningTime: StoreDayOpeningTime
  StoreFeedback: StoreFeedback
  StoreInfo: StoreInfo
  StoreLocation: StoreLocation
  StoreLowestDeliveryPrice: StoreLowestDeliveryPrice
  StoreOpeningTimeRange: StoreOpeningTimeRange
  StorePhoneNumber: StorePhoneNumber
  StoreService: StoreService
  StoreSpecificData: StoreSpecificData
  StoreWeeklyOpeningTime: StoreWeeklyOpeningTime
  String: Scalars['String']
  StringFacet: StringFacet
  StructuredFacetInput: StructuredFacetInput
  UpdateAuthenticationTokensResponse: UpdateAuthenticationTokensResponse
  UpdateCustomerInfoResponse: UpdateCustomerInfoResponse
  UpdateDeliveryDetailsResponse: UpdateDeliveryDetailsResponse
  UpdatePersonalInfoResponse: UpdatePersonalInfoResponse
  UserNotificationPreferences: UserNotificationPreferences
  UserNotificationSubscription: UserNotificationSubscription
  UserOrder: UserOrder
  UserOrderCartItem: UserOrderCartItem
  UserOrderWithCartItems: UserOrderWithCartItems
  UserProfile: UserProfile
  Void: Scalars['Void']
  WarningSymbol: WarningSymbol
}

export type ClientDirectiveArgs = {}

export type ClientDirectiveResolver<
  Result,
  Parent,
  ContextType = Context,
  Args = ClientDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type AddressResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address'],
> = {
  apartmentNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  postalCity: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  postalCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  streetAddress: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddressAutocompleteResultResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AddressAutocompleteResult'] = ResolversParentTypes['AddressAutocompleteResult'],
> = {
  block: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  building: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  countryCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  countryName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  county: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  district: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hereId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  houseNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  label: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  postalCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  state: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  stateCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  street: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subblock: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subdistrict: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddressGeocodeResultResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AddressGeocodeResult'] = ResolversParentTypes['AddressGeocodeResult'],
> = {
  block: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  building: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city: Resolver<ResolversTypes['String'], ParentType, ContextType>
  countryCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  countryName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  county: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  district: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hereId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  houseNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  label: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  latitude: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  longitude: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  postalCode: Resolver<ResolversTypes['String'], ParentType, ContextType>
  resultType: Resolver<Maybe<ResolversTypes['AddressGeocodeResultType']>, ParentType, ContextType>
  state: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  stateCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  street: Resolver<ResolversTypes['String'], ParentType, ContextType>
  subblock: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subdistrict: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AddressValidateResultResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AddressValidateResult'] = ResolversParentTypes['AddressValidateResult'],
> = {
  errors: Resolver<Array<ResolversTypes['AddressValidationError']>, ParentType, ContextType>
  isValid: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AllergenResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Allergen'] = ResolversParentTypes['Allergen'],
> = {
  allergenTypeCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  allergenTypeText: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  levelOfContainmentCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthTokensResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AuthTokens'] = ResolversParentTypes['AuthTokens'],
> = {
  accessToken: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  idToken: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  refreshToken: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthenticationTokensResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AuthenticationTokens'] = ResolversParentTypes['AuthenticationTokens'],
> = {
  accessToken: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  idToken: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  modified: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  refreshToken: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthorizePaymentResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AuthorizePaymentResponse'] = ResolversParentTypes['AuthorizePaymentResponse'],
> = {
  authorized: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  orderId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AvailablePackagingMaterialResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['AvailablePackagingMaterial'] = ResolversParentTypes['AvailablePackagingMaterial'],
> = {
  ean: Resolver<ResolversTypes['String'], ParentType, ContextType>
  materialPrice: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  materialType: Resolver<ResolversTypes['MaterialType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatchRecommendationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['BatchRecommendation'] = ResolversParentTypes['BatchRecommendation'],
> = {
  products: Resolver<
    Maybe<ResolversTypes['ProductList']>,
    ParentType,
    ContextType,
    RequireFields<BatchRecommendationProductsArgs, 'storeId'>
  >
  raw: Resolver<Array<ResolversTypes['BatchRecommendationProduct']>, ParentType, ContextType>
  runId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BatchRecommendationProductResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['BatchRecommendationProduct'] = ResolversParentTypes['BatchRecommendationProduct'],
> = {
  ean: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sok_id: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BonusInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['BonusInfo'] = ResolversParentTypes['BonusInfo'],
> = {
  membershipNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BonusWidgetTokenResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['BonusWidgetToken'] = ResolversParentTypes['BonusWidgetToken'],
> = {
  token: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CartCapacityResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartCapacity'] = ResolversParentTypes['CartCapacity'],
> = {
  capacity: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  items: Resolver<Array<ResolversTypes['CartItemCapacity']>, ParentType, ContextType>
  rejected: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  warnings: Resolver<Array<ResolversTypes['ICartCapacityWarning']>, ParentType, ContextType>
  weight: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CartCapacityDimensionWarningResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartCapacityDimensionWarning'] = ResolversParentTypes['CartCapacityDimensionWarning'],
> = {
  dimensionUnit: Resolver<ResolversTypes['String'], ParentType, ContextType>
  dimensions: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>
  type: Resolver<ResolversTypes['CartCapacityWarningType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CartCapacityNumericWarningResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartCapacityNumericWarning'] = ResolversParentTypes['CartCapacityNumericWarning'],
> = {
  integerValue: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  type: Resolver<ResolversTypes['CartCapacityWarningType'], ParentType, ContextType>
  unit: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CartItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartItem'] = ResolversParentTypes['CartItem'],
> = {
  additionalInfo: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  basicQuantityUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  brand: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  collectingPriority: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  ean: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  itemCount: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  price: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  priceUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  productType: Resolver<Maybe<ResolversTypes['ProductType']>, ParentType, ContextType>
  replace: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CartItemCapacityResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartItemCapacity'] = ResolversParentTypes['CartItemCapacity'],
> = {
  capacity: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>
  itemCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status: Resolver<ResolversTypes['CartItemCapacityStatus'], ParentType, ContextType>
  volume: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  weight: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CartRecommendationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartRecommendation'] = ResolversParentTypes['CartRecommendation'],
> = {
  amounts: Resolver<Array<ResolversTypes['CartRecommendationProduct']>, ParentType, ContextType>
  products: Resolver<
    Maybe<ResolversTypes['ProductList']>,
    ParentType,
    ContextType,
    RequireFields<CartRecommendationProductsArgs, 'storeId'>
  >
  runId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CartRecommendationProductResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CartRecommendationProduct'] = ResolversParentTypes['CartRecommendationProduct'],
> = {
  amount: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  ean: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CitrusAdResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CitrusAd'] = ResolversParentTypes['CitrusAd'],
> = {
  banners: Resolver<Maybe<Array<Maybe<ResolversTypes['CitrusAdBanner']>>>, ParentType, ContextType>
  contentStandardId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slotId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CitrusAdBannerResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CitrusAdBanner'] = ResolversParentTypes['CitrusAdBanner'],
> = {
  backgroundColour: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  backgroundImage: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  backgroundImagePosition: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  bannerText: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  bannerTextColour: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  citrusAdId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  citrusCampaignName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  citrusContentStandardId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  citrusExpiry: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  citrusSlotId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contentStandardId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaFlag: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  ctaLink: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaText: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ctaTextAccessibility: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  gtins: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>
  headingText: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  heroImage: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  heroImageAltText: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  position: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  secondaryBackgroundImage: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  secondaryBackgroundImagePosition: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  secondaryHeroImage: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  secondaryHeroImageAltText: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  secondaryHeroMode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sellerId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slotId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClientCartItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ClientCartItem'] = ResolversParentTypes['ClientCartItem'],
> = {
  additionalInfo: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  approxPrice: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  basicQuantityUnit: Resolver<ResolversTypes['String'], ParentType, ContextType>
  campaignPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  campaignPriceValidUntil: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  comparisonPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  comparisonUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  countryName: Resolver<ResolversTypes['CountryName'], ParentType, ContextType>
  ean: Resolver<ResolversTypes['String'], ParentType, ContextType>
  frozen: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  inStoreSelection: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isAgeLimitedByAlcohol: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  itemCount: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  lowest30DayPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  mainCategoryName: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  packagingLabelCodes: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  price: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  priceUnit: Resolver<ResolversTypes['String'], ParentType, ContextType>
  productType: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>
  quantityMultiplier: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  regularPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  replace: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CollatedDeliverySlotResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CollatedDeliverySlot'] = ResolversParentTypes['CollatedDeliverySlot'],
> = {
  date: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deliveryTimes: Resolver<Array<ResolversTypes['DeliverySlot']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company'],
> = {
  identityCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  invoiceId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CoordinatesResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Coordinates'] = ResolversParentTypes['Coordinates'],
> = {
  lat: Resolver<ResolversTypes['String'], ParentType, ContextType>
  lon: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CountryResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country'],
> = {
  code: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CountryNameResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CountryName'] = ResolversParentTypes['CountryName'],
> = {
  fi: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer'],
> = {
  address: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  addressLine1: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  addressLine2: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  bonusCard: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contact: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  invoiceAddress: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  invoiceCity: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  invoiceCompanyName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  invoiceFirstName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  invoiceLastName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  invoiceNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  invoicePhone: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  invoicePostalCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  newsletter: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  phone: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  postalCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sMembershipCardLastName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sMembershipCardPostalCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sMembershipNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CustomerDetailsResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['CustomerDetailsResponse'] = ResolversParentTypes['CustomerDetailsResponse'],
> = {
  comment: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phone: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DayAvailabilityResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DayAvailability'] = ResolversParentTypes['DayAvailability'],
> = {
  available: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  date: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeleteUserDataResultResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeleteUserDataResult'] = ResolversParentTypes['DeleteUserDataResult'],
> = {
  success: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeliveryAreaResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliveryArea'] = ResolversParentTypes['DeliveryArea'],
> = {
  address: Resolver<Maybe<ResolversTypes['DeliveryAreaAddress']>, ParentType, ContextType>
  alcoholSellingAllowed: Resolver<ResolversTypes['AlcoholSellingStatus'], ParentType, ContextType>
  areaId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  availablePackagingMaterials: Resolver<
    Maybe<Array<ResolversTypes['AvailablePackagingMaterial']>>,
    ParentType,
    ContextType
  >
  deliveryAreaPolygon: Resolver<Maybe<Array<ResolversTypes['Point']>>, ParentType, ContextType>
  deliveryMethod: Resolver<ResolversTypes['DeliveryMethod'], ParentType, ContextType>
  deliverySlots: Resolver<
    Maybe<Array<ResolversTypes['CollatedDeliverySlot']>>,
    ParentType,
    ContextType,
    Partial<DeliveryAreaDeliverySlotsArgs>
  >
  description: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  distance: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    Partial<DeliveryAreaDistanceArgs>
  >
  districts: Resolver<Maybe<Array<ResolversTypes['DeliveryDistrict']>>, ParentType, ContextType>
  dynamicPricing: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  fastTrackOpeningHours: Resolver<
    Maybe<ResolversTypes['FastTrackOpeningHours']>,
    ParentType,
    ContextType
  >
  homeDeliveryType: Resolver<Maybe<ResolversTypes['HomeDeliveryType']>, ParentType, ContextType>
  isFastTrack: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isRemote: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  location: Resolver<Maybe<ResolversTypes['DeliveryLocation']>, ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  nextAvailableDeliverySlot: Resolver<
    Maybe<ResolversTypes['DeliverySlot']>,
    ParentType,
    ContextType,
    Partial<DeliveryAreaNextAvailableDeliverySlotArgs>
  >
  nextDeliverySlot: Resolver<
    Maybe<ResolversTypes['NextDeliverySlot']>,
    ParentType,
    ContextType,
    Partial<DeliveryAreaNextDeliverySlotArgs>
  >
  postalCodes: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  price: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  reservationEnabled: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  robotAvailability: Resolver<
    Maybe<ResolversTypes['RobotAvailability']>,
    ParentType,
    ContextType,
    RequireFields<DeliveryAreaRobotAvailabilityArgs, 'to'>
  >
  store: Resolver<Maybe<ResolversTypes['StoreInfo']>, ParentType, ContextType>
  storeId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  visibilityGroup: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeliveryAreaAddressResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliveryAreaAddress'] = ResolversParentTypes['DeliveryAreaAddress'],
> = {
  city: Resolver<ResolversTypes['String'], ParentType, ContextType>
  postalCode: Resolver<ResolversTypes['String'], ParentType, ContextType>
  street: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeliveryAreaPinResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliveryAreaPin'] = ResolversParentTypes['DeliveryAreaPin'],
> = {
  areaId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  brand: Resolver<ResolversTypes['String'], ParentType, ContextType>
  location: Resolver<ResolversTypes['Point'], ParentType, ContextType>
  placeOfBusinessId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeliveryAreaPinsResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliveryAreaPinsResponse'] = ResolversParentTypes['DeliveryAreaPinsResponse'],
> = {
  pins: Resolver<Maybe<Array<ResolversTypes['DeliveryAreaPin']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeliveryAreaSearchResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliveryAreaSearchResponse'] = ResolversParentTypes['DeliveryAreaSearchResponse'],
> = {
  areas: Resolver<Maybe<Array<ResolversTypes['DeliveryArea']>>, ParentType, ContextType>
  total: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeliveryDetailsInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliveryDetailsInfo'] = ResolversParentTypes['DeliveryDetailsInfo'],
> = {
  additionalInfo: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  address: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deliveryDate: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deliveryMethod: Resolver<Maybe<ResolversTypes['DeliveryMethod']>, ParentType, ContextType>
  deliverySlotId: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  deliverySlotPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  deliveryTime: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  postalCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeliveryDistrictResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliveryDistrict'] = ResolversParentTypes['DeliveryDistrict'],
> = {
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  postalCode: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeliveryLocationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliveryLocation'] = ResolversParentTypes['DeliveryLocation'],
> = {
  address: Resolver<
    Maybe<ResolversTypes['DeliveryAreaAddress']>,
    ParentType,
    ContextType,
    Partial<DeliveryLocationAddressArgs>
  >
  distance: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  distanceFromStore: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType,
    RequireFields<DeliveryLocationDistanceFromStoreArgs, 'storeId'>
  >
  latitude: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  longitude: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  robotAvailability: Resolver<
    Maybe<ResolversTypes['RobotAvailability']>,
    ParentType,
    ContextType,
    RequireFields<DeliveryLocationRobotAvailabilityArgs, 'areaId'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeliverySlotResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliverySlot'] = ResolversParentTypes['DeliverySlot'],
> = {
  alcoholSellingAllowed: Resolver<ResolversTypes['AlcoholSellingStatus'], ParentType, ContextType>
  areaId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  availability: Resolver<ResolversTypes['SlotAvailability'], ParentType, ContextType>
  closingTimestamp: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deliveryMethod: Resolver<ResolversTypes['DeliveryMethod'], ParentType, ContextType>
  endDateTime: Resolver<ResolversTypes['String'], ParentType, ContextType>
  estimatedFastTrackTime: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  estimatedRemainingFastTrackTime: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  isClosed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isFastTrack: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isModifiable: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  length: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  modificationTimestamp: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  price: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  slotId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startDateTime: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startTime: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  time: Resolver<ResolversTypes['String'], ParentType, ContextType>
  validFrom: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeliverySlotReservationResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliverySlotReservationResponse'] = ResolversParentTypes['DeliverySlotReservationResponse'],
> = {
  deliveryArea: Resolver<ResolversTypes['DeliveryArea'], ParentType, ContextType>
  deliverySlot: Resolver<ResolversTypes['DeliverySlot'], ParentType, ContextType>
  expiresAt: Resolver<ResolversTypes['String'], ParentType, ContextType>
  reservationId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DeliveryStoreResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DeliveryStore'] = ResolversParentTypes['DeliveryStore'],
> = {
  areaId: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  availablePaymentMethods: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PaymentMethod']>>>,
    ParentType,
    ContextType
  >
  brand: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DiscountProductResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DiscountProduct'] = ResolversParentTypes['DiscountProduct'],
> = {
  discountAmount: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  discountType: Resolver<ResolversTypes['DiscountProductType'], ParentType, ContextType>
  ean: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isValid: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DomainCustomerResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DomainCustomer'] = ResolversParentTypes['DomainCustomer'],
> = {
  cardType: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phone: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  stockmannCardNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type: Resolver<ResolversTypes['CustomerType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DomainDeliveryResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DomainDelivery'] = ResolversParentTypes['DomainDelivery'],
> = {
  address: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  postalCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DomainOrderResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DomainOrder'] = ResolversParentTypes['DomainOrder'],
> = {
  additionalInfo: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cartItems: Resolver<Array<ResolversTypes['ClientCartItem']>, ParentType, ContextType>
  comment: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  customer: Resolver<ResolversTypes['DomainCustomer'], ParentType, ContextType>
  deliveryAddress: Resolver<ResolversTypes['DomainDelivery'], ParentType, ContextType>
  deliveryAreaId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deliverySlotId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  discountCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  orderStatus: Resolver<Maybe<ResolversTypes['OrderStatus']>, ParentType, ContextType>
  payment: Resolver<ResolversTypes['DomainPayment'], ParentType, ContextType>
  reservationId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  storeId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DomainPaymentResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['DomainPayment'] = ResolversParentTypes['DomainPayment'],
> = {
  invoiceNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  paymentMethod: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>
  paymentStatus: Resolver<Maybe<ResolversTypes['PaymentStatus']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EanProductResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['EanProduct'] = ResolversParentTypes['EanProduct'],
> = {
  __resolveType: TypeResolveFn<'CartRecommendationProduct', ParentType, ContextType>
  ean: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type ExternalOrdersPosDataResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ExternalOrdersPOSData'] = ResolversParentTypes['ExternalOrdersPOSData'],
> = {
  accountID: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  accountingStoreID: Resolver<ResolversTypes['String'], ParentType, ContextType>
  loyaltyTransactionTotals: Resolver<
    Maybe<Array<ResolversTypes['ExternalOrdersPOSRoyaltyTransation']>>,
    ParentType,
    ContextType
  >
  ownerBenefit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  registerID: Resolver<ResolversTypes['String'], ParentType, ContextType>
  registerTransNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>
  shipment: Resolver<
    Maybe<Array<ResolversTypes['ExternalOrdersPOSShipment']>>,
    ParentType,
    ContextType
  >
  signature: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  storeCode: Resolver<ResolversTypes['String'], ParentType, ContextType>
  storeID: Resolver<ResolversTypes['String'], ParentType, ContextType>
  totalAmount: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactionCode: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactionDateTime: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactionID: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type: Resolver<ResolversTypes['String'], ParentType, ContextType>
  uniqueTransactionID: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  versionNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>
  webStoreID: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExternalOrdersPosRoyaltyTransationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ExternalOrdersPOSRoyaltyTransation'] = ResolversParentTypes['ExternalOrdersPOSRoyaltyTransation'],
> = {
  loyaltyBenefitAmount: Resolver<ResolversTypes['String'], ParentType, ContextType>
  loyaltyCardID: Resolver<ResolversTypes['String'], ParentType, ContextType>
  paymentBenefitAmount: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sequenceID: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ExternalOrdersPosShipmentResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ExternalOrdersPOSShipment'] = ResolversParentTypes['ExternalOrdersPOSShipment'],
> = {
  billOfLadingNr: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  employeeID: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  infoText: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  orderID: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sentTime: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shipmentID: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status: Resolver<ResolversTypes['String'], ParentType, ContextType>
  statusDate: Resolver<ResolversTypes['String'], ParentType, ContextType>
  storeID: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FastTrackOpeningHoursResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FastTrackOpeningHours'] = ResolversParentTypes['FastTrackOpeningHours'],
> = {
  from: Resolver<ResolversTypes['String'], ParentType, ContextType>
  status: Resolver<ResolversTypes['StoreOpeningStatus'], ParentType, ContextType>
  to: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FavoriteItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FavoriteItem'] = ResolversParentTypes['FavoriteItem'],
> = {
  count: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt: Resolver<ResolversTypes['String'], ParentType, ContextType>
  ean: Resolver<ResolversTypes['String'], ParentType, ContextType>
  modifiedAt: Resolver<ResolversTypes['String'], ParentType, ContextType>
  product: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<FavoriteItemProductArgs, 'storeId'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FavoritesListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FavoritesList'] = ResolversParentTypes['FavoritesList'],
> = {
  createdAt: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isDefault: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  items: Resolver<Array<ResolversTypes['FavoriteItem']>, ParentType, ContextType>
  modifiedAt: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  products: Resolver<
    Maybe<Array<ResolversTypes['Product']>>,
    ParentType,
    ContextType,
    RequireFields<FavoritesListProductsArgs, 'storeId'>
  >
  slug: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FavoritesListDeleteResultResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FavoritesListDeleteResult'] = ResolversParentTypes['FavoritesListDeleteResult'],
> = {
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FavoritesListsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FavoritesLists'] = ResolversParentTypes['FavoritesLists'],
> = {
  favoritesLists: Resolver<Array<ResolversTypes['FavoritesList']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FavoritesOperationResultResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['FavoritesOperationResult'] = ResolversParentTypes['FavoritesOperationResult'],
> = {
  discardedCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  successfulCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HierarchyResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Hierarchy'] = ResolversParentTypes['Hierarchy'],
> = {
  count: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HierarchyPathItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['HierarchyPathItem'] = ResolversParentTypes['HierarchyPathItem'],
> = {
  hidden: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HomeDeliverySlotResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['HomeDeliverySlot'] = ResolversParentTypes['HomeDeliverySlot'],
> = {
  closingTime: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deliveryTimeEnd: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deliveryTimeStart: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isAlcoholSellingAllowed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isOrderModificationAllowed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  price: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HomeDeliverySlotsInStoreResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['HomeDeliverySlotsInStore'] = ResolversParentTypes['HomeDeliverySlotsInStore'],
> = {
  slots: Resolver<Array<ResolversTypes['HomeDeliverySlot']>, ParentType, ContextType>
  store: Resolver<ResolversTypes['StoreInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ICartCapacityWarningResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ICartCapacityWarning'] = ResolversParentTypes['ICartCapacityWarning'],
> = {
  __resolveType: TypeResolveFn<
    'CartCapacityDimensionWarning' | 'CartCapacityNumericWarning',
    ParentType,
    ContextType
  >
  type: Resolver<ResolversTypes['CartCapacityWarningType'], ParentType, ContextType>
}

export type IHomeDeliverySlotResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['IHomeDeliverySlot'] = ResolversParentTypes['IHomeDeliverySlot'],
> = {
  __resolveType: TypeResolveFn<'HomeDeliverySlot', ParentType, ContextType>
  closingTime: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deliveryTimeEnd: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deliveryTimeStart: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isAlcoholSellingAllowed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isOrderModificationAllowed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  price: Resolver<ResolversTypes['Float'], ParentType, ContextType>
}

export type IStructuredFacetResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['IStructuredFacet'] = ResolversParentTypes['IStructuredFacet'],
> = {
  __resolveType: TypeResolveFn<'ObjectFacet' | 'StringFacet', ParentType, ContextType>
  key: Resolver<ResolversTypes['FacetKey'], ParentType, ContextType>
}

export type LocalizableTextResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LocalizableText'] = ResolversParentTypes['LocalizableText'],
> = {
  default: Resolver<ResolversTypes['String'], ParentType, ContextType>
  en: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  et: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fi: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sv: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocalizedProductInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LocalizedProductInfo'] = ResolversParentTypes['LocalizedProductInfo'],
> = {
  en: Resolver<Maybe<ResolversTypes['LocalizedProductInfoFields']>, ParentType, ContextType>
  fi: Resolver<Maybe<ResolversTypes['LocalizedProductInfoFields']>, ParentType, ContextType>
  sv: Resolver<Maybe<ResolversTypes['LocalizedProductInfoFields']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocalizedProductInfoFieldsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LocalizedProductInfoFields'] = ResolversParentTypes['LocalizedProductInfoFields'],
> = {
  allergens: Resolver<Maybe<Array<ResolversTypes['Allergen']>>, ParentType, ContextType>
  description: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ingredientStatement: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  storageGuideForConsumer: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userGuideForConsumer: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocalizedTextResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['LocalizedText'] = ResolversParentTypes['LocalizedText'],
> = {
  en: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  et: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fi: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sv: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MandatoryProductResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['MandatoryProduct'] = ResolversParentTypes['MandatoryProduct'],
> = {
  ean: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  productType: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>
  storeId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MunicipalityResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Municipality'] = ResolversParentTypes['Municipality'],
> = {
  code: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['LocalizableText']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  addUserNotificationSubcription: Resolver<
    Maybe<ResolversTypes['UserNotificationSubscription']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddUserNotificationSubcriptionArgs, 'mediums' | 'type'>
  >
  addUserPushNotificationDevice: Resolver<
    Maybe<ResolversTypes['PushNotificationDevice']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddUserPushNotificationDeviceArgs, 'deviceToken'>
  >
  authorizePayment: Resolver<
    Maybe<ResolversTypes['AuthorizePaymentResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationAuthorizePaymentArgs, 'orderId'>
  >
  cancelOrder: Resolver<
    Maybe<ResolversTypes['Order']>,
    ParentType,
    ContextType,
    RequireFields<MutationCancelOrderArgs, 'id'>
  >
  cancelOrderModification: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    Partial<MutationCancelOrderModificationArgs>
  >
  clearAuthenticationRedirectPath: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >
  clearAuthenticationTokens: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  clearCartAndOrderDetails: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationClearCartAndOrderDetailsArgs>
  >
  createDeliverySlotReservation: Resolver<
    ResolversTypes['DeliverySlotReservationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateDeliverySlotReservationArgs, 'deliverySlotId'>
  >
  createOrder: Resolver<
    Maybe<ResolversTypes['Order']>,
    ParentType,
    ContextType,
    Partial<MutationCreateOrderArgs>
  >
  createPayment: Resolver<
    Maybe<ResolversTypes['PaymentTerminalAddress']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreatePaymentArgs, 'device' | 'orderId'>
  >
  createUserFavoritesList: Resolver<
    Maybe<ResolversTypes['FavoritesList']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserFavoritesListArgs, 'name'>
  >
  deleteAllUserData: Resolver<
    Maybe<ResolversTypes['DeleteUserDataResult']>,
    ParentType,
    ContextType,
    Partial<MutationDeleteAllUserDataArgs>
  >
  deleteUserFavoritesList: Resolver<
    Maybe<ResolversTypes['FavoritesListDeleteResult']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserFavoritesListArgs, 'listId'>
  >
  isOptimisticResponse: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  refreshDeliverySlotReservation: Resolver<
    ResolversTypes['DeliverySlotReservationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRefreshDeliverySlotReservationArgs, 'reservationId'>
  >
  releaseDeliverySlotReservation: Resolver<
    Maybe<ResolversTypes['Void']>,
    ParentType,
    ContextType,
    RequireFields<MutationReleaseDeliverySlotReservationArgs, 'reservationId'>
  >
  removeUserNotificationSubscription: Resolver<
    Maybe<ResolversTypes['UserNotificationSubscription']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserNotificationSubscriptionArgs, 'mediums' | 'type'>
  >
  removeUserPaymentCard: Resolver<
    Maybe<ResolversTypes['RemoveUserPaymentCardResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserPaymentCardArgs, 'cardId'>
  >
  removeUserPushNotificationDevice: Resolver<
    Maybe<ResolversTypes['PushNotificationDevice']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserPushNotificationDeviceArgs, 'deviceToken'>
  >
  renameUserPaymentCard: Resolver<
    Maybe<ResolversTypes['RenameUserPaymentCardResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationRenameUserPaymentCardArgs, 'cardId' | 'name'>
  >
  resetDeliveryStore: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  resetOrderPaymentCard: Resolver<
    Maybe<ResolversTypes['ResetOrderPaymentCardResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationResetOrderPaymentCardArgs, 'id'>
  >
  saveUserPaymentCard: Resolver<
    Maybe<ResolversTypes['SaveUserPaymentCardResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationSaveUserPaymentCardArgs, 'orderId'>
  >
  sendCitrusAdUserEvents: Resolver<
    Maybe<ResolversTypes['Void']>,
    ParentType,
    ContextType,
    RequireFields<MutationSendCitrusAdUserEventsArgs, 'adId' | 'eventType' | 'sessionId'>
  >
  sendGiosgChatMetadata: Resolver<
    Maybe<ResolversTypes['Void']>,
    ParentType,
    ContextType,
    RequireFields<MutationSendGiosgChatMetadataArgs, 'visitorId'>
  >
  sendProductUserEvents: Resolver<
    Maybe<ResolversTypes['ProductUserEventResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationSendProductUserEventsArgs, 'eventType' | 'productIds' | 'sessionId'>
  >
  setDefaultUserPaymentCard: Resolver<
    Maybe<ResolversTypes['SetDefaultUserPaymentCardResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetDefaultUserPaymentCardArgs, 'cardId'>
  >
  setDeliveryStore: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationSetDeliveryStoreArgs>
  >
  startOrderEdit: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationStartOrderEditArgs>
  >
  stopOrderEdit: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  updateAuthenticationRedirectPath: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    Partial<MutationUpdateAuthenticationRedirectPathArgs>
  >
  updateAuthenticationTokens: Resolver<
    Maybe<ResolversTypes['UpdateAuthenticationTokensResponse']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateAuthenticationTokensArgs,
      'accessToken' | 'idToken' | 'refreshToken'
    >
  >
  updateCustomerInfo: Resolver<
    Maybe<ResolversTypes['UpdateCustomerInfoResponse']>,
    ParentType,
    ContextType,
    Partial<MutationUpdateCustomerInfoArgs>
  >
  updateCustomerType: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationUpdateCustomerTypeArgs>
  >
  updateDeliveryDetails: Resolver<
    Maybe<ResolversTypes['UpdateDeliveryDetailsResponse']>,
    ParentType,
    ContextType,
    Partial<MutationUpdateDeliveryDetailsArgs>
  >
  updateOrder: Resolver<
    Maybe<ResolversTypes['Order']>,
    ParentType,
    ContextType,
    Partial<MutationUpdateOrderArgs>
  >
  updatePaymentMethodDetails: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationUpdatePaymentMethodDetailsArgs>
  >
  updatePersonalInfo: Resolver<
    Maybe<ResolversTypes['UpdatePersonalInfoResponse']>,
    ParentType,
    ContextType,
    Partial<MutationUpdatePersonalInfoArgs>
  >
  updateSelectedAreaId: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationUpdateSelectedAreaIdArgs>
  >
  updateSelectedBrand: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationUpdateSelectedBrandArgs>
  >
  updateSelectedStoreId: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationUpdateSelectedStoreIdArgs>
  >
  updateStockmannCardNumber: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    Partial<MutationUpdateStockmannCardNumberArgs>
  >
  updateUserFavoritesList: Resolver<
    Maybe<ResolversTypes['FavoritesList']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserFavoritesListArgs, 'listId' | 'values'>
  >
  updateUserFavoritesListItems: Resolver<
    Maybe<ResolversTypes['FavoritesOperationResult']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserFavoritesListItemsArgs, 'operations'>
  >
  updateUserNotificationSubscription: Resolver<
    Maybe<ResolversTypes['UserNotificationSubscription']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserNotificationSubscriptionArgs, 'enabled' | 'mediums' | 'type'>
  >
  userFavoritesAddItem: Resolver<
    ResolversTypes['FavoritesList'],
    ParentType,
    ContextType,
    RequireFields<MutationUserFavoritesAddItemArgs, 'ean'>
  >
  userFavoritesRemoveItem: Resolver<
    ResolversTypes['FavoritesList'],
    ParentType,
    ContextType,
    RequireFields<MutationUserFavoritesRemoveItemArgs, 'ean'>
  >
}

export type NavigationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Navigation'] = ResolversParentTypes['Navigation'],
> = {
  navigation: Resolver<Maybe<Array<ResolversTypes['NavigationItem']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['NavigationItem'] = ResolversParentTypes['NavigationItem'],
> = {
  children: Resolver<Maybe<Array<ResolversTypes['NavigationItem']>>, ParentType, ContextType>
  hidden: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  itemCount: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  redirectedSlugs: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  sequence: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  type: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NextDeliverySlotResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['NextDeliverySlot'] = ResolversParentTypes['NextDeliverySlot'],
> = {
  alcoholSellingAllowed: Resolver<ResolversTypes['AlcoholSellingStatus'], ParentType, ContextType>
  availability: Resolver<ResolversTypes['SlotAvailability'], ParentType, ContextType>
  endDateTime: Resolver<ResolversTypes['String'], ParentType, ContextType>
  estimatedFastTrackTime: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  isClosed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isFastTrack: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  price: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  slotId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startDateTime: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NutrientResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Nutrient'] = ResolversParentTypes['Nutrient'],
> = {
  kcal: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  kj: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ri: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  value: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NutrientClaimResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['NutrientClaim'] = ResolversParentTypes['NutrientClaim'],
> = {
  nutritionalClaimElement: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  nutritionalClaimType: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NutrientPerServingResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['NutrientPerServing'] = ResolversParentTypes['NutrientPerServing'],
> = {
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  unit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  value: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NutrientRawResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['NutrientRaw'] = ResolversParentTypes['NutrientRaw'],
> = {
  measurementPrecisionCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  measurementUnitCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  nutrientTypeCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  quantityContained: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ObjectFacetResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ObjectFacet'] = ResolversParentTypes['ObjectFacet'],
> = {
  key: Resolver<ResolversTypes['FacetKey'], ParentType, ContextType>
  objectValue: Resolver<Array<ResolversTypes['ObjectFacetValue']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ObjectFacetValueResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ObjectFacetValue'] = ResolversParentTypes['ObjectFacetValue'],
> = {
  doc_count: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  value: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order'],
> = {
  additionalInfo: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  capturedPOSData: Resolver<Maybe<ResolversTypes['ExternalOrdersPOSData']>, ParentType, ContextType>
  capturedTotalInCents: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  cartItems: Resolver<Maybe<Array<ResolversTypes['CartItem']>>, ParentType, ContextType>
  comment: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  createdAt: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  customer: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>
  deliveryDate: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deliveryMethod: Resolver<Maybe<ResolversTypes['DeliveryMethod']>, ParentType, ContextType>
  deliveryPoint: Resolver<Maybe<ResolversTypes['DeliveryLocation']>, ParentType, ContextType>
  deliverySlot: Resolver<Maybe<ResolversTypes['DeliverySlot']>, ParentType, ContextType>
  deliverySlotId: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  deliveryTime: Resolver<ResolversTypes['String'], ParentType, ContextType>
  discountCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  handler: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  homeDeliveryType: Resolver<Maybe<ResolversTypes['HomeDeliveryType']>, ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isCancelable: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isFastTrack: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isModifiable: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  linkVerificationToken: Resolver<ResolversTypes['String'], ParentType, ContextType>
  modifiedAt: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  orderNumber: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  orderStatus: Resolver<Maybe<ResolversTypes['OrderStatus']>, ParentType, ContextType>
  paymentId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  paymentMethod: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>
  paymentStatus: Resolver<Maybe<ResolversTypes['PaymentStatus']>, ParentType, ContextType>
  pickupLocation: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  storeId: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  trackingUrl: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OrderEditActiveResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['OrderEditActiveResponse'] = ResolversParentTypes['OrderEditActiveResponse'],
> = {
  orderId: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  orderStatus: Resolver<Maybe<ResolversTypes['OrderStatus']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaymentCardResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PaymentCard'] = ResolversParentTypes['PaymentCard'],
> = {
  expired: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  expiryDate: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  maskedCardNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type: Resolver<ResolversTypes['PaymentCardType'], ParentType, ContextType>
  userGeneratedName: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaymentCardsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PaymentCards'] = ResolversParentTypes['PaymentCards'],
> = {
  cards: Resolver<Array<ResolversTypes['PaymentCard']>, ParentType, ContextType>
  defaultPaymentCardId: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaymentDetailsResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PaymentDetailsResponse'] = ResolversParentTypes['PaymentDetailsResponse'],
> = {
  invoiceNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  paymentMethod: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>
  paymentStatus: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  savePaymentCard: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  selectedPaymentCardId: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  stockmannCardNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaymentTerminalAddressResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PaymentTerminalAddress'] = ResolversParentTypes['PaymentTerminalAddress'],
> = {
  redirectUrl: Resolver<ResolversTypes['String'], ParentType, ContextType>
  transactionId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PointResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Point'] = ResolversParentTypes['Point'],
> = {
  latitude: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  longitude: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product'],
> = {
  allergens: Resolver<Maybe<Array<ResolversTypes['Allergen']>>, ParentType, ContextType>
  approxPrice: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  availabilities: Resolver<
    Maybe<Array<ResolversTypes['ProductAvailabilities']>>,
    ParentType,
    ContextType,
    Partial<ProductAvailabilitiesArgs>
  >
  basicQuantityUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  brandName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  categories: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  category: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  categoryId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  collectingPriority: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  comparisonPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  comparisonUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  consumerPackageSize: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  consumerPackageUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  countryName: Resolver<Maybe<ResolversTypes['CountryName']>, ParentType, ContextType>
  countryOfMainRawMaterial: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  depositPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  description: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  descriptionShort: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ean: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  frozen: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  hierarchyPath: Resolver<
    Maybe<Array<ResolversTypes['HierarchyPathItem']>>,
    ParentType,
    ContextType
  >
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  inStore: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  ingredientStatement: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isAgeLimitedByAlcohol: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isGlobalFallback: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isNewProduct: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  localized: Resolver<Maybe<ResolversTypes['LocalizedProductInfo']>, ParentType, ContextType>
  location: Resolver<Maybe<ResolversTypes['ProductLocation']>, ParentType, ContextType>
  marketingMessage: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  measurement: Resolver<Maybe<ResolversTypes['ProductMeasurement']>, ParentType, ContextType>
  modified: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  nutrientClaims: Resolver<Maybe<Array<ResolversTypes['NutrientClaim']>>, ParentType, ContextType>
  nutrients: Resolver<Maybe<Array<ResolversTypes['Nutrient']>>, ParentType, ContextType>
  nutrientsPerServing: Resolver<
    Maybe<Array<ResolversTypes['NutrientPerServing']>>,
    ParentType,
    ContextType
  >
  nutrientsRaw: Resolver<Maybe<Array<ResolversTypes['NutrientRaw']>>, ParentType, ContextType>
  onSale: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  packagingLabelCodes: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  packagingLabels: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  packagingMarkedLabelAccreditationCodes: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >
  price: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  priceUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  pricing: Resolver<Maybe<ResolversTypes['ProductPricing']>, ParentType, ContextType>
  productDetails: Resolver<ResolversTypes['ProductDetails'], ParentType, ContextType>
  productType: Resolver<Maybe<ResolversTypes['ProductType']>, ParentType, ContextType>
  quantityMultiplier: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  salesUniteCom: Resolver<Maybe<ResolversTypes['SalesUnit']>, ParentType, ContextType>
  slug: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sokId: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  stock: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  store: Resolver<Maybe<ResolversTypes['Store']>, ParentType, ContextType>
  storeAvailability: Resolver<
    Maybe<Array<ResolversTypes['Product']>>,
    ParentType,
    ContextType,
    Partial<ProductStoreAvailabilityArgs>
  >
  storeId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  storeSpecificData: Resolver<Array<ResolversTypes['StoreSpecificData']>, ParentType, ContextType>
  supplierName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  taxPercentage: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  tuhtiId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  weight: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  weightUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductAvailabilitiesResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProductAvailabilities'] = ResolversParentTypes['ProductAvailabilities'],
> = {
  date: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  label: Resolver<Maybe<ResolversTypes['ProductAvailabilityLabel']>, ParentType, ContextType>
  score: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductDetailsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProductDetails'] = ResolversParentTypes['ProductDetails'],
> = {
  safetyInformation: Resolver<Maybe<ResolversTypes['SafetyInformation']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductListResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProductList'] = ResolversParentTypes['ProductList'],
> = {
  from: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  items: Resolver<Maybe<Array<ResolversTypes['Product']>>, ParentType, ContextType>
  limit: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  searchProvider: Resolver<Maybe<ResolversTypes['SearchProvider']>, ParentType, ContextType>
  structuredFacets: Resolver<
    Maybe<Array<ResolversTypes['IStructuredFacet']>>,
    ParentType,
    ContextType
  >
  total: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductLocationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProductLocation'] = ResolversParentTypes['ProductLocation'],
> = {
  aisle: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  coordinate: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>
  floor: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  module: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  shelf: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductMeasurementResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProductMeasurement'] = ResolversParentTypes['ProductMeasurement'],
> = {
  grossWeight: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  grossWeightUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  netWeight: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  netWeightUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  pceApproxWeight: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductPricingResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProductPricing'] = ResolversParentTypes['ProductPricing'],
> = {
  campaignPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  campaignPriceValidUntil: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  comparisonPrice: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  currentPrice: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  depositPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  lowest30DayPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  regularPrice: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductUserEventResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ProductUserEventResponse'] = ResolversParentTypes['ProductUserEventResponse'],
> = {
  success: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PushNotificationDeviceResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['PushNotificationDevice'] = ResolversParentTypes['PushNotificationDevice'],
> = {
  deviceToken: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type QueryResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  addressAutocomplete: Resolver<
    Maybe<Array<ResolversTypes['AddressAutocompleteResult']>>,
    ParentType,
    ContextType,
    RequireFields<QueryAddressAutocompleteArgs, 'countryCode' | 'query'>
  >
  addressAutosuggest: Resolver<
    Maybe<Array<ResolversTypes['AddressGeocodeResult']>>,
    ParentType,
    ContextType,
    RequireFields<QueryAddressAutosuggestArgs, 'countryCode' | 'query'>
  >
  addressGeocode: Resolver<
    Maybe<Array<ResolversTypes['AddressGeocodeResult']>>,
    ParentType,
    ContextType,
    RequireFields<QueryAddressGeocodeArgs, 'query'>
  >
  addressValidate: Resolver<
    ResolversTypes['AddressValidateResult'],
    ParentType,
    ContextType,
    RequireFields<QueryAddressValidateArgs, 'query'>
  >
  authTokens: Resolver<
    Maybe<ResolversTypes['AuthTokens']>,
    ParentType,
    ContextType,
    Partial<QueryAuthTokensArgs>
  >
  authenticationRedirectPath: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  authenticationTokens: Resolver<
    Maybe<ResolversTypes['AuthenticationTokens']>,
    ParentType,
    ContextType
  >
  batchProductRecommendation: Resolver<
    Maybe<ResolversTypes['BatchRecommendation']>,
    ParentType,
    ContextType,
    RequireFields<QueryBatchProductRecommendationArgs, 'type'>
  >
  bonusInfo: Resolver<Maybe<ResolversTypes['BonusInfo']>, ParentType, ContextType>
  bonusWidgetToken: Resolver<Maybe<ResolversTypes['BonusWidgetToken']>, ParentType, ContextType>
  cartItem: Resolver<
    Maybe<ResolversTypes['ClientCartItem']>,
    ParentType,
    ContextType,
    Partial<QueryCartItemArgs>
  >
  cartItems: Resolver<Array<ResolversTypes['ClientCartItem']>, ParentType, ContextType>
  citrusAd: Resolver<
    Maybe<ResolversTypes['CitrusAd']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryCitrusAdArgs,
      'brand' | 'placement' | 'sessionId' | 'slotId' | 'useCustomerId'
    >
  >
  complementaryProducts: Resolver<
    Array<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<QueryComplementaryProductsArgs, 'eans' | 'storeId'>
  >
  customerDetails: Resolver<
    Maybe<ResolversTypes['CustomerDetailsResponse']>,
    ParentType,
    ContextType
  >
  customerType: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deliveryArea: Resolver<
    Maybe<ResolversTypes['DeliveryArea']>,
    ParentType,
    ContextType,
    RequireFields<QueryDeliveryAreaArgs, 'id'>
  >
  deliveryAreaPins: Resolver<
    Maybe<ResolversTypes['DeliveryAreaPinsResponse']>,
    ParentType,
    ContextType,
    Partial<QueryDeliveryAreaPinsArgs>
  >
  deliveryAreas: Resolver<
    Maybe<Array<ResolversTypes['DeliveryArea']>>,
    ParentType,
    ContextType,
    Partial<QueryDeliveryAreasArgs>
  >
  deliveryDetailsInfo: Resolver<
    Maybe<ResolversTypes['DeliveryDetailsInfo']>,
    ParentType,
    ContextType
  >
  deliverySlot: Resolver<
    Maybe<ResolversTypes['DeliverySlot']>,
    ParentType,
    ContextType,
    RequireFields<QueryDeliverySlotArgs, 'id'>
  >
  deliverySlotReservation: Resolver<
    ResolversTypes['DeliverySlotReservationResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryDeliverySlotReservationArgs, 'reservationId'>
  >
  deliveryStore: Resolver<Maybe<ResolversTypes['DeliveryStore']>, ParentType, ContextType>
  domainOrder: Resolver<ResolversTypes['DomainOrder'], ParentType, ContextType>
  homeDeliverySlotAvailabilitiesForPostalCode: Resolver<
    Array<ResolversTypes['DayAvailability']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryHomeDeliverySlotAvailabilitiesForPostalCodeArgs,
      'endDate' | 'postalCode' | 'startDate'
    >
  >
  homeDeliverySlotsForPostalCode: Resolver<
    Array<ResolversTypes['HomeDeliverySlotsInStore']>,
    ParentType,
    ContextType,
    RequireFields<QueryHomeDeliverySlotsForPostalCodeArgs, 'endDate' | 'postalCode' | 'startDate'>
  >
  order: Resolver<
    Maybe<ResolversTypes['Order']>,
    ParentType,
    ContextType,
    RequireFields<QueryOrderArgs, 'id'>
  >
  orderEditActive: Resolver<
    Maybe<ResolversTypes['OrderEditActiveResponse']>,
    ParentType,
    ContextType
  >
  paymentDetails: Resolver<Maybe<ResolversTypes['PaymentDetailsResponse']>, ParentType, ContextType>
  product: Resolver<
    Maybe<ResolversTypes['Product']>,
    ParentType,
    ContextType,
    RequireFields<QueryProductArgs, 'id'>
  >
  recipe: Resolver<
    Maybe<ResolversTypes['Recipe']>,
    ParentType,
    ContextType,
    RequireFields<QueryRecipeArgs, 'id'>
  >
  reverseGeocode: Resolver<
    Maybe<ResolversTypes['DeliveryAreaAddress']>,
    ParentType,
    ContextType,
    RequireFields<QueryReverseGeocodeArgs, 'latitude' | 'longitude'>
  >
  robotCartCapacity: Resolver<
    ResolversTypes['CartCapacity'],
    ParentType,
    ContextType,
    RequireFields<QueryRobotCartCapacityArgs, 'items' | 'storeId'>
  >
  robotNearestDeliveryPoint: Resolver<
    Maybe<ResolversTypes['DeliveryLocation']>,
    ParentType,
    ContextType,
    RequireFields<QueryRobotNearestDeliveryPointArgs, 'to'>
  >
  searchDeliveryAreas: Resolver<
    Maybe<ResolversTypes['DeliveryAreaSearchResponse']>,
    ParentType,
    ContextType,
    Partial<QuerySearchDeliveryAreasArgs>
  >
  searchHomeDeliveryAreas: Resolver<
    Maybe<ResolversTypes['DeliveryAreaSearchResponse']>,
    ParentType,
    ContextType,
    Partial<QuerySearchHomeDeliveryAreasArgs>
  >
  searchPickupDeliveryAreas: Resolver<
    Maybe<ResolversTypes['DeliveryAreaSearchResponse']>,
    ParentType,
    ContextType,
    Partial<QuerySearchPickupDeliveryAreasArgs>
  >
  searchStores: Resolver<
    Maybe<ResolversTypes['SearchStoresResponse']>,
    ParentType,
    ContextType,
    Partial<QuerySearchStoresArgs>
  >
  selectedAreaId: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  selectedBrand: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  selectedStoreId: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  serviceFee: Resolver<Maybe<ResolversTypes['ServiceFee']>, ParentType, ContextType>
  serviceSubscriptions: Resolver<
    Array<ResolversTypes['ServiceSubscription']>,
    ParentType,
    ContextType,
    RequireFields<QueryServiceSubscriptionsArgs, 'serviceTypes'>
  >
  store: Resolver<
    Maybe<ResolversTypes['Store']>,
    ParentType,
    ContextType,
    RequireFields<QueryStoreArgs, 'id'>
  >
  stores: Resolver<
    Maybe<Array<ResolversTypes['StoreInfo']>>,
    ParentType,
    ContextType,
    Partial<QueryStoresArgs>
  >
  userFavorites: Resolver<ResolversTypes['FavoritesList'], ParentType, ContextType>
  userFavoritesList: Resolver<
    Maybe<ResolversTypes['FavoritesList']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserFavoritesListArgs, 'listId'>
  >
  userFavoritesLists: Resolver<Maybe<ResolversTypes['FavoritesLists']>, ParentType, ContextType>
  userNotificationPreferences: Resolver<
    Maybe<ResolversTypes['UserNotificationPreferences']>,
    ParentType,
    ContextType
  >
  userOrder: Resolver<
    Maybe<ResolversTypes['UserOrderWithCartItems']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserOrderArgs, 'id'>
  >
  userOrders: Resolver<
    Array<ResolversTypes['UserOrder']>,
    ParentType,
    ContextType,
    Partial<QueryUserOrdersArgs>
  >
  userPaymentCards: Resolver<Maybe<ResolversTypes['PaymentCards']>, ParentType, ContextType>
  userProductRecommendation: Resolver<
    Maybe<ResolversTypes['CartRecommendation']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserProductRecommendationArgs, 'type'>
  >
  userProfile: Resolver<Maybe<ResolversTypes['UserProfile']>, ParentType, ContextType>
  userProfileGuidedEntry: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type RecipeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Recipe'] = ResolversParentTypes['Recipe'],
> = {
  categories: Resolver<Maybe<ResolversTypes['RecipeCategories']>, ParentType, ContextType>
  cookTime: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  description: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  externalUrl: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ingredients: Resolver<Array<ResolversTypes['RecipeIngredient']>, ParentType, ContextType>
  instructions: Resolver<Array<ResolversTypes['RecipeInstruction']>, ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  portions: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  publisher: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug: Resolver<ResolversTypes['String'], ParentType, ContextType>
  yield: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  yieldType: Resolver<ResolversTypes['RecipeYieldType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecipeCategoriesResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RecipeCategories'] = ResolversParentTypes['RecipeCategories'],
> = {
  contentConcept: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  course: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  cuisine: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  diet: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  foodType: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  ingredientCategory: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  meal: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  preparation: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  theme: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecipeIngredientResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RecipeIngredient'] = ResolversParentTypes['RecipeIngredient'],
> = {
  amountType: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  eans: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  groupName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ingredientTitle: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  maxAmount: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  minAmount: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  products: Resolver<
    Maybe<Array<ResolversTypes['Product']>>,
    ParentType,
    ContextType,
    RequireFields<RecipeIngredientProductsArgs, 'storeId'>
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecipeInstructionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RecipeInstruction'] = ResolversParentTypes['RecipeInstruction'],
> = {
  body: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RemoveUserPaymentCardResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RemoveUserPaymentCardResponse'] = ResolversParentTypes['RemoveUserPaymentCardResponse'],
> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RenameUserPaymentCardResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RenameUserPaymentCardResponse'] = ResolversParentTypes['RenameUserPaymentCardResponse'],
> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ResetOrderPaymentCardResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ResetOrderPaymentCardResponse'] = ResolversParentTypes['ResetOrderPaymentCardResponse'],
> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RobotAvailabilityResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['RobotAvailability'] = ResolversParentTypes['RobotAvailability'],
> = {
  eta: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  status: Resolver<ResolversTypes['RobotAvailabilityStatus'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SafetyInformationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SafetyInformation'] = ResolversParentTypes['SafetyInformation'],
> = {
  caption: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  dangerClauses: Resolver<Array<ResolversTypes['SafetyInformationClause']>, ParentType, ContextType>
  safetyClauses: Resolver<Array<ResolversTypes['SafetyInformationClause']>, ParentType, ContextType>
  warningSymbols: Resolver<Array<ResolversTypes['WarningSymbol']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SafetyInformationClauseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SafetyInformationClause'] = ResolversParentTypes['SafetyInformationClause'],
> = {
  code: Resolver<ResolversTypes['String'], ParentType, ContextType>
  text: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SaveUserPaymentCardResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SaveUserPaymentCardResponse'] = ResolversParentTypes['SaveUserPaymentCardResponse'],
> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchStoresResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SearchStoresResponse'] = ResolversParentTypes['SearchStoresResponse'],
> = {
  cursor: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  maybeHasMore: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  stores: Resolver<Array<ResolversTypes['StoreInfo']>, ParentType, ContextType>
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceFeeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ServiceFee'] = ResolversParentTypes['ServiceFee'],
> = {
  ean: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  price: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceProductResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ServiceProduct'] = ResolversParentTypes['ServiceProduct'],
> = {
  ean: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  productType: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>
  storeId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ServiceSubscriptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['ServiceSubscription'] = ResolversParentTypes['ServiceSubscription'],
> = {
  changeAllowed: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  serviceName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  serviceType: Resolver<ResolversTypes['ServiceSubscriptionServiceType'], ParentType, ContextType>
  subscribed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  subscriptionStartDate: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SetDefaultUserPaymentCardResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['SetDefaultUserPaymentCardResponse'] = ResolversParentTypes['SetDefaultUserPaymentCardResponse'],
> = {
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['Store'] = ResolversParentTypes['Store'],
> = {
  availablePaymentMethods: Resolver<
    Maybe<Array<ResolversTypes['PaymentMethod']>>,
    ParentType,
    ContextType
  >
  brand: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  coOperative: Resolver<Maybe<ResolversTypes['CoOperative']>, ParentType, ContextType>
  contactInfo: Resolver<Maybe<ResolversTypes['StoreContactInfo']>, ParentType, ContextType>
  discountProduct: Resolver<
    Maybe<ResolversTypes['DiscountProduct']>,
    ParentType,
    ContextType,
    Partial<StoreDiscountProductArgs>
  >
  domains: Resolver<Array<ResolversTypes['Domain']>, ParentType, ContextType>
  feedback: Resolver<Maybe<ResolversTypes['StoreFeedback']>, ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  location: Resolver<Maybe<ResolversTypes['StoreLocation']>, ParentType, ContextType>
  lowestDeliveryPrices: Resolver<
    Maybe<Array<Maybe<ResolversTypes['StoreLowestDeliveryPrice']>>>,
    ParentType,
    ContextType
  >
  mandatoryProducts: Resolver<
    Maybe<Array<ResolversTypes['MandatoryProduct']>>,
    ParentType,
    ContextType,
    Partial<StoreMandatoryProductsArgs>
  >
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  navigation: Resolver<Maybe<Array<ResolversTypes['NavigationItem']>>, ParentType, ContextType>
  postalCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  products: Resolver<
    Maybe<ResolversTypes['ProductList']>,
    ParentType,
    ContextType,
    Partial<StoreProductsArgs>
  >
  serviceProducts: Resolver<Maybe<Array<ResolversTypes['ServiceProduct']>>, ParentType, ContextType>
  services: Resolver<Maybe<Array<ResolversTypes['StoreService']>>, ParentType, ContextType>
  shortName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug: Resolver<ResolversTypes['String'], ParentType, ContextType>
  street: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  weeklyOpeningHours: Resolver<
    Maybe<Array<ResolversTypes['StoreWeeklyOpeningTime']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreAddressResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StoreAddress'] = ResolversParentTypes['StoreAddress'],
> = {
  country: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>
  municipality: Resolver<ResolversTypes['Municipality'], ParentType, ContextType>
  postcode: Resolver<ResolversTypes['String'], ParentType, ContextType>
  postcodeName: Resolver<Maybe<ResolversTypes['LocalizableText']>, ParentType, ContextType>
  street: Resolver<ResolversTypes['LocalizableText'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreContactInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StoreContactInfo'] = ResolversParentTypes['StoreContactInfo'],
> = {
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phoneNumber: Resolver<Maybe<ResolversTypes['StorePhoneNumber']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreDayOpeningTimeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StoreDayOpeningTime'] = ResolversParentTypes['StoreDayOpeningTime'],
> = {
  date: Resolver<ResolversTypes['String'], ParentType, ContextType>
  day: Resolver<ResolversTypes['DayLabel'], ParentType, ContextType>
  exceptional: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  message: Resolver<Maybe<ResolversTypes['LocalizedText']>, ParentType, ContextType>
  mode: Resolver<ResolversTypes['StoreOpeningTimeRangeLabel'], ParentType, ContextType>
  ranges: Resolver<Maybe<Array<ResolversTypes['StoreOpeningTimeRange']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreFeedbackResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StoreFeedback'] = ResolversParentTypes['StoreFeedback'],
> = {
  url: Resolver<Maybe<ResolversTypes['LocalizableText']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreInfoResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StoreInfo'] = ResolversParentTypes['StoreInfo'],
> = {
  availablePaymentMethods: Resolver<
    Maybe<Array<ResolversTypes['PaymentMethod']>>,
    ParentType,
    ContextType
  >
  brand: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  contactInfo: Resolver<Maybe<ResolversTypes['StoreContactInfo']>, ParentType, ContextType>
  domains: Resolver<Array<ResolversTypes['Domain']>, ParentType, ContextType>
  feedback: Resolver<Maybe<ResolversTypes['StoreFeedback']>, ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  location: Resolver<Maybe<ResolversTypes['StoreLocation']>, ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  postalCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  services: Resolver<Maybe<Array<ResolversTypes['StoreService']>>, ParentType, ContextType>
  shortName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  street: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  weeklyOpeningHours: Resolver<
    Maybe<Array<ResolversTypes['StoreWeeklyOpeningTime']>>,
    ParentType,
    ContextType
  >
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreLocationResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StoreLocation'] = ResolversParentTypes['StoreLocation'],
> = {
  address: Resolver<Maybe<ResolversTypes['StoreAddress']>, ParentType, ContextType>
  coordinates: Resolver<Maybe<ResolversTypes['Coordinates']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreLowestDeliveryPriceResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StoreLowestDeliveryPrice'] = ResolversParentTypes['StoreLowestDeliveryPrice'],
> = {
  areaId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deliveryMethod: Resolver<ResolversTypes['DeliveryMethod'], ParentType, ContextType>
  description: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isFastTrack: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  price: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreOpeningTimeRangeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StoreOpeningTimeRange'] = ResolversParentTypes['StoreOpeningTimeRange'],
> = {
  close: Resolver<ResolversTypes['String'], ParentType, ContextType>
  closeOnSameDay: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  open: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StorePhoneNumberResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StorePhoneNumber'] = ResolversParentTypes['StorePhoneNumber'],
> = {
  callCharge: Resolver<Maybe<ResolversTypes['LocalizableText']>, ParentType, ContextType>
  callChargeGroup: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  number: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreServiceResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StoreService'] = ResolversParentTypes['StoreService'],
> = {
  code: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name: Resolver<Maybe<ResolversTypes['LocalizableText']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreSpecificDataResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StoreSpecificData'] = ResolversParentTypes['StoreSpecificData'],
> = {
  newProduct: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  placeOfBusinessId: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StoreWeeklyOpeningTimeResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StoreWeeklyOpeningTime'] = ResolversParentTypes['StoreWeeklyOpeningTime'],
> = {
  openingTimes: Resolver<Array<ResolversTypes['StoreDayOpeningTime']>, ParentType, ContextType>
  weekNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StringFacetResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['StringFacet'] = ResolversParentTypes['StringFacet'],
> = {
  key: Resolver<ResolversTypes['FacetKey'], ParentType, ContextType>
  stringValue: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateAuthenticationTokensResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UpdateAuthenticationTokensResponse'] = ResolversParentTypes['UpdateAuthenticationTokensResponse'],
> = {
  accessToken: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  idToken: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  refreshToken: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateCustomerInfoResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UpdateCustomerInfoResponse'] = ResolversParentTypes['UpdateCustomerInfoResponse'],
> = {
  companyName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  customerType: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phone: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  stockmannCardNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdateDeliveryDetailsResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UpdateDeliveryDetailsResponse'] = ResolversParentTypes['UpdateDeliveryDetailsResponse'],
> = {
  additionalInfo: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  address: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  city: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deliveryDate: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deliveryMethod: Resolver<Maybe<ResolversTypes['DeliveryMethod']>, ParentType, ContextType>
  deliverySlotId: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>
  deliverySlotPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  deliveryTime: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  postalCode: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpdatePersonalInfoResponseResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UpdatePersonalInfoResponse'] = ResolversParentTypes['UpdatePersonalInfoResponse'],
> = {
  comment: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  companyName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  invoiceNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  lastName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phone: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserNotificationPreferencesResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserNotificationPreferences'] = ResolversParentTypes['UserNotificationPreferences'],
> = {
  marketing: Resolver<ResolversTypes['UserNotificationSubscription'], ParentType, ContextType>
  transactional: Resolver<ResolversTypes['UserNotificationSubscription'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserNotificationSubscriptionResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserNotificationSubscription'] = ResolversParentTypes['UserNotificationSubscription'],
> = {
  enabled: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  pushNotification: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  type: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserOrderResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserOrder'] = ResolversParentTypes['UserOrder'],
> = {
  createdAt: Resolver<ResolversTypes['String'], ParentType, ContextType>
  customer: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>
  dataSource: Resolver<ResolversTypes['UserOrderDataSource'], ParentType, ContextType>
  deliveryDate: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deliveryMethod: Resolver<ResolversTypes['DeliveryMethod'], ParentType, ContextType>
  deliverySlotClosed: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  deliverySlotClosingTimestamp: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deliverySlotModificationTimestamp: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  deliveryTime: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  homeDeliveryType: Resolver<Maybe<ResolversTypes['HomeDeliveryType']>, ParentType, ContextType>
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isCancelable: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isFastTrack: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isModifiable: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  modifiedAt: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  orderNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>
  orderStatus: Resolver<ResolversTypes['OrderStatus'], ParentType, ContextType>
  paymentMethod: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>
  paymentStatus: Resolver<Maybe<ResolversTypes['PaymentStatus']>, ParentType, ContextType>
  pickupLocation: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  storeId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  storeName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totalCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  trackingUrl: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserOrderCartItemResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserOrderCartItem'] = ResolversParentTypes['UserOrderCartItem'],
> = {
  additionalInfo: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  basicQuantityUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  ean: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  itemCount: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>
  price: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  priceUnit: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  productType: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>
  replace: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserOrderWithCartItemsResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserOrderWithCartItems'] = ResolversParentTypes['UserOrderWithCartItems'],
> = {
  cartItems: Resolver<Array<ResolversTypes['UserOrderCartItem']>, ParentType, ContextType>
  createdAt: Resolver<ResolversTypes['String'], ParentType, ContextType>
  customer: Resolver<Maybe<ResolversTypes['Customer']>, ParentType, ContextType>
  dataSource: Resolver<ResolversTypes['UserOrderDataSource'], ParentType, ContextType>
  deliveryDate: Resolver<ResolversTypes['String'], ParentType, ContextType>
  deliveryMethod: Resolver<ResolversTypes['DeliveryMethod'], ParentType, ContextType>
  deliverySlotClosed: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  deliverySlotClosingTimestamp: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deliverySlotModificationTimestamp: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >
  deliveryTime: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isFastTrack: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isModifiable: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  modifiedAt: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  orderNumber: Resolver<ResolversTypes['String'], ParentType, ContextType>
  orderStatus: Resolver<ResolversTypes['OrderStatus'], ParentType, ContextType>
  paymentMethod: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>
  paymentStatus: Resolver<Maybe<ResolversTypes['PaymentStatus']>, ParentType, ContextType>
  pickupLocation: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  storeId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  storeName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totalCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  userId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserProfileResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['UserProfile'] = ResolversParentTypes['UserProfile'],
> = {
  address: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>
  company: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>
  customerType: Resolver<ResolversTypes['CustomerType'], ParentType, ContextType>
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  firstName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isEmailVerified: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isPhoneNumberVerified: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  lastName: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  personContactId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phoneNumber: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  tags: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  userId: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface VoidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Void'], any> {
  name: 'Void'
}

export type WarningSymbolResolvers<
  ContextType = Context,
  ParentType extends ResolversParentTypes['WarningSymbol'] = ResolversParentTypes['WarningSymbol'],
> = {
  code: Resolver<ResolversTypes['WarningSymbolCode'], ParentType, ContextType>
  description: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = Context> = {
  Address: AddressResolvers<ContextType>
  AddressAutocompleteResult: AddressAutocompleteResultResolvers<ContextType>
  AddressGeocodeResult: AddressGeocodeResultResolvers<ContextType>
  AddressValidateResult: AddressValidateResultResolvers<ContextType>
  Allergen: AllergenResolvers<ContextType>
  AuthTokens: AuthTokensResolvers<ContextType>
  AuthenticationTokens: AuthenticationTokensResolvers<ContextType>
  AuthorizePaymentResponse: AuthorizePaymentResponseResolvers<ContextType>
  AvailablePackagingMaterial: AvailablePackagingMaterialResolvers<ContextType>
  BatchRecommendation: BatchRecommendationResolvers<ContextType>
  BatchRecommendationProduct: BatchRecommendationProductResolvers<ContextType>
  BonusInfo: BonusInfoResolvers<ContextType>
  BonusWidgetToken: BonusWidgetTokenResolvers<ContextType>
  CartCapacity: CartCapacityResolvers<ContextType>
  CartCapacityDimensionWarning: CartCapacityDimensionWarningResolvers<ContextType>
  CartCapacityNumericWarning: CartCapacityNumericWarningResolvers<ContextType>
  CartItem: CartItemResolvers<ContextType>
  CartItemCapacity: CartItemCapacityResolvers<ContextType>
  CartRecommendation: CartRecommendationResolvers<ContextType>
  CartRecommendationProduct: CartRecommendationProductResolvers<ContextType>
  CitrusAd: CitrusAdResolvers<ContextType>
  CitrusAdBanner: CitrusAdBannerResolvers<ContextType>
  ClientCartItem: ClientCartItemResolvers<ContextType>
  CollatedDeliverySlot: CollatedDeliverySlotResolvers<ContextType>
  Company: CompanyResolvers<ContextType>
  Coordinates: CoordinatesResolvers<ContextType>
  Country: CountryResolvers<ContextType>
  CountryName: CountryNameResolvers<ContextType>
  Customer: CustomerResolvers<ContextType>
  CustomerDetailsResponse: CustomerDetailsResponseResolvers<ContextType>
  DayAvailability: DayAvailabilityResolvers<ContextType>
  DeleteUserDataResult: DeleteUserDataResultResolvers<ContextType>
  DeliveryArea: DeliveryAreaResolvers<ContextType>
  DeliveryAreaAddress: DeliveryAreaAddressResolvers<ContextType>
  DeliveryAreaPin: DeliveryAreaPinResolvers<ContextType>
  DeliveryAreaPinsResponse: DeliveryAreaPinsResponseResolvers<ContextType>
  DeliveryAreaSearchResponse: DeliveryAreaSearchResponseResolvers<ContextType>
  DeliveryDetailsInfo: DeliveryDetailsInfoResolvers<ContextType>
  DeliveryDistrict: DeliveryDistrictResolvers<ContextType>
  DeliveryLocation: DeliveryLocationResolvers<ContextType>
  DeliverySlot: DeliverySlotResolvers<ContextType>
  DeliverySlotReservationResponse: DeliverySlotReservationResponseResolvers<ContextType>
  DeliveryStore: DeliveryStoreResolvers<ContextType>
  DiscountProduct: DiscountProductResolvers<ContextType>
  DomainCustomer: DomainCustomerResolvers<ContextType>
  DomainDelivery: DomainDeliveryResolvers<ContextType>
  DomainOrder: DomainOrderResolvers<ContextType>
  DomainPayment: DomainPaymentResolvers<ContextType>
  EanProduct: EanProductResolvers<ContextType>
  ExternalOrdersPOSData: ExternalOrdersPosDataResolvers<ContextType>
  ExternalOrdersPOSRoyaltyTransation: ExternalOrdersPosRoyaltyTransationResolvers<ContextType>
  ExternalOrdersPOSShipment: ExternalOrdersPosShipmentResolvers<ContextType>
  FastTrackOpeningHours: FastTrackOpeningHoursResolvers<ContextType>
  FavoriteItem: FavoriteItemResolvers<ContextType>
  FavoritesList: FavoritesListResolvers<ContextType>
  FavoritesListDeleteResult: FavoritesListDeleteResultResolvers<ContextType>
  FavoritesLists: FavoritesListsResolvers<ContextType>
  FavoritesOperationResult: FavoritesOperationResultResolvers<ContextType>
  Hierarchy: HierarchyResolvers<ContextType>
  HierarchyPathItem: HierarchyPathItemResolvers<ContextType>
  HomeDeliverySlot: HomeDeliverySlotResolvers<ContextType>
  HomeDeliverySlotsInStore: HomeDeliverySlotsInStoreResolvers<ContextType>
  ICartCapacityWarning: ICartCapacityWarningResolvers<ContextType>
  IHomeDeliverySlot: IHomeDeliverySlotResolvers<ContextType>
  IStructuredFacet: IStructuredFacetResolvers<ContextType>
  LocalizableText: LocalizableTextResolvers<ContextType>
  LocalizedProductInfo: LocalizedProductInfoResolvers<ContextType>
  LocalizedProductInfoFields: LocalizedProductInfoFieldsResolvers<ContextType>
  LocalizedText: LocalizedTextResolvers<ContextType>
  MandatoryProduct: MandatoryProductResolvers<ContextType>
  Municipality: MunicipalityResolvers<ContextType>
  Mutation: MutationResolvers<ContextType>
  Navigation: NavigationResolvers<ContextType>
  NavigationItem: NavigationItemResolvers<ContextType>
  NextDeliverySlot: NextDeliverySlotResolvers<ContextType>
  Nutrient: NutrientResolvers<ContextType>
  NutrientClaim: NutrientClaimResolvers<ContextType>
  NutrientPerServing: NutrientPerServingResolvers<ContextType>
  NutrientRaw: NutrientRawResolvers<ContextType>
  ObjectFacet: ObjectFacetResolvers<ContextType>
  ObjectFacetValue: ObjectFacetValueResolvers<ContextType>
  Order: OrderResolvers<ContextType>
  OrderEditActiveResponse: OrderEditActiveResponseResolvers<ContextType>
  PaymentCard: PaymentCardResolvers<ContextType>
  PaymentCards: PaymentCardsResolvers<ContextType>
  PaymentDetailsResponse: PaymentDetailsResponseResolvers<ContextType>
  PaymentTerminalAddress: PaymentTerminalAddressResolvers<ContextType>
  Point: PointResolvers<ContextType>
  Product: ProductResolvers<ContextType>
  ProductAvailabilities: ProductAvailabilitiesResolvers<ContextType>
  ProductDetails: ProductDetailsResolvers<ContextType>
  ProductList: ProductListResolvers<ContextType>
  ProductLocation: ProductLocationResolvers<ContextType>
  ProductMeasurement: ProductMeasurementResolvers<ContextType>
  ProductPricing: ProductPricingResolvers<ContextType>
  ProductUserEventResponse: ProductUserEventResponseResolvers<ContextType>
  PushNotificationDevice: PushNotificationDeviceResolvers<ContextType>
  Query: QueryResolvers<ContextType>
  Recipe: RecipeResolvers<ContextType>
  RecipeCategories: RecipeCategoriesResolvers<ContextType>
  RecipeIngredient: RecipeIngredientResolvers<ContextType>
  RecipeInstruction: RecipeInstructionResolvers<ContextType>
  RemoveUserPaymentCardResponse: RemoveUserPaymentCardResponseResolvers<ContextType>
  RenameUserPaymentCardResponse: RenameUserPaymentCardResponseResolvers<ContextType>
  ResetOrderPaymentCardResponse: ResetOrderPaymentCardResponseResolvers<ContextType>
  RobotAvailability: RobotAvailabilityResolvers<ContextType>
  SafetyInformation: SafetyInformationResolvers<ContextType>
  SafetyInformationClause: SafetyInformationClauseResolvers<ContextType>
  SaveUserPaymentCardResponse: SaveUserPaymentCardResponseResolvers<ContextType>
  SearchStoresResponse: SearchStoresResponseResolvers<ContextType>
  ServiceFee: ServiceFeeResolvers<ContextType>
  ServiceProduct: ServiceProductResolvers<ContextType>
  ServiceSubscription: ServiceSubscriptionResolvers<ContextType>
  SetDefaultUserPaymentCardResponse: SetDefaultUserPaymentCardResponseResolvers<ContextType>
  Store: StoreResolvers<ContextType>
  StoreAddress: StoreAddressResolvers<ContextType>
  StoreContactInfo: StoreContactInfoResolvers<ContextType>
  StoreDayOpeningTime: StoreDayOpeningTimeResolvers<ContextType>
  StoreFeedback: StoreFeedbackResolvers<ContextType>
  StoreInfo: StoreInfoResolvers<ContextType>
  StoreLocation: StoreLocationResolvers<ContextType>
  StoreLowestDeliveryPrice: StoreLowestDeliveryPriceResolvers<ContextType>
  StoreOpeningTimeRange: StoreOpeningTimeRangeResolvers<ContextType>
  StorePhoneNumber: StorePhoneNumberResolvers<ContextType>
  StoreService: StoreServiceResolvers<ContextType>
  StoreSpecificData: StoreSpecificDataResolvers<ContextType>
  StoreWeeklyOpeningTime: StoreWeeklyOpeningTimeResolvers<ContextType>
  StringFacet: StringFacetResolvers<ContextType>
  UpdateAuthenticationTokensResponse: UpdateAuthenticationTokensResponseResolvers<ContextType>
  UpdateCustomerInfoResponse: UpdateCustomerInfoResponseResolvers<ContextType>
  UpdateDeliveryDetailsResponse: UpdateDeliveryDetailsResponseResolvers<ContextType>
  UpdatePersonalInfoResponse: UpdatePersonalInfoResponseResolvers<ContextType>
  UserNotificationPreferences: UserNotificationPreferencesResolvers<ContextType>
  UserNotificationSubscription: UserNotificationSubscriptionResolvers<ContextType>
  UserOrder: UserOrderResolvers<ContextType>
  UserOrderCartItem: UserOrderCartItemResolvers<ContextType>
  UserOrderWithCartItems: UserOrderWithCartItemsResolvers<ContextType>
  UserProfile: UserProfileResolvers<ContextType>
  Void: GraphQLScalarType
  WarningSymbol: WarningSymbolResolvers<ContextType>
}

export type DirectiveResolvers<ContextType = Context> = {
  client: ClientDirectiveResolver<any, any, ContextType>
}

import { gql } from '@apollo/client/core'
export const typeDefs = gql`
  schema {
    query: Query
    mutation: Mutation
  }
  "Indicates that the client should resolve the field value locally as part of a query, e.g. in a React component"
  directive @client on FIELD
  type Address {
    apartmentNumber: String
    postalCity: String
    postalCode: String
    streetAddress: String
  }
  type AddressAutocompleteResult {
    block: String
    building: String
    city: String
    countryCode: String
    countryName: String
    county: String
    district: String
    """
    Unique id of address, from HERE Geocoding and Search
    """
    hereId: String!
    houseNumber: String
    label: String
    postalCode: String
    state: String
    stateCode: String
    street: String
    subblock: String
    subdistrict: String
    """
    The localized display name of this result item.
    """
    title: String!
  }
  type AddressGeocodeResult {
    block: String
    building: String
    city: String!
    countryCode: String
    countryName: String
    county: String
    district: String
    """
    Unique id of address, from HERE Geocoding and Search
    """
    hereId: String!
    houseNumber: String
    label: String
    latitude: Float!
    longitude: Float!
    postalCode: String!
    resultType: AddressGeocodeResultType
    state: String
    stateCode: String
    street: String!
    subblock: String
    subdistrict: String
    """
    The localized display name of this result item.
    """
    title: String!
  }
  enum AddressGeocodeResultType {
    addressBlock
    administrativeArea
    houseNumber
    intersection
    locality
    place
    postalCodePoint
    street
  }
  type AddressValidateResult {
    errors: [AddressValidationError!]!
    isValid: Boolean!
  }
  enum AddressValidationError {
    BuildingNumberNotFound
    NoResultFromThirdPartyAPI
    NonParseableAddress
    StreetAddressNotFound
  }
  enum AlcoholSellingStatus {
    ALLOWED
    NOT_ALLOWED
  }
  type Allergen {
    allergenTypeCode: String
    allergenTypeText: String
    levelOfContainmentCode: String
  }
  type AuthTokens {
    accessToken: String
    idToken: String
    refreshToken: String
  }
  type AuthenticationTokens {
    accessToken: String
    idToken: String
    modified: Float
    refreshToken: String
  }
  type AuthorizePaymentResponse {
    authorized: Boolean
    orderId: String
  }
  type AvailablePackagingMaterial {
    ean: String!
    materialPrice: Float!
    materialType: MaterialType!
  }
  type BatchRecommendation {
    products(storeId: String!): ProductList
    raw: [BatchRecommendationProduct!]!
    runId: String!
  }
  type BatchRecommendationProduct {
    ean: String!
    sok_id: String!
  }
  enum BatchRecommendationTypeEnum {
    CART
    SEASONAL
    YOU_MAY_NEED
  }
  type BonusInfo {
    membershipNumber: String
  }
  input BoundingBox {
    bottomRight: Location!
    topLeft: Location!
  }
  enum CacheControlScope {
    PRIVATE
    PUBLIC
  }
  type CartCapacity {
    """
    Capacity is [0, n].
    If capacity < 1; there is still space for more products.
    If capacity = 1; cart is full.
    If capacity > 1; then capacity - 1 = exceeded capacity.
    """
    capacity: Float!
    items: [CartItemCapacity!]!
    """
    Cart ids of products that don't fit into the selected container dimensions or have missing measurement data.
    """
    rejected: [String!]!
    warnings: [ICartCapacityWarning!]!
    """
    Total weight of the cart in kilograms (KG).
    """
    weight: Float!
  }
  type CartCapacityDimensionWarning implements ICartCapacityWarning {
    dimensionUnit: String!
    dimensions: [Int!]!
    type: CartCapacityWarningType!
  }
  type CartCapacityNumericWarning implements ICartCapacityWarning {
    integerValue: Int!
    type: CartCapacityWarningType!
    unit: String!
  }
  enum CartCapacityWarningType {
    MAX_CAPACITY_EXCEEDED
    MAX_WEIGHT_EXCEEDED
    OVER_SIZED_PRODUCTS
    SOME_PRODUCTS_ARE_MISSING_DIMENSIONS
  }
  type CartItem {
    additionalInfo: String
    basicQuantityUnit: String
    brand: String
    collectingPriority: Int
    ean: String
    id: ID!
    itemCount: String
    name: String
    price: Float!
    priceUnit: String
    productType: ProductType
    replace: Boolean
  }
  type CartItemCapacity {
    capacity: Float!
    id: String!
    itemCount: Int!
    status: CartItemCapacityStatus!
    volume: Float!
    weight: Float!
  }
  enum CartItemCapacityStatus {
    EXCEEDS_CONTAINER_DIMENSIONS
    OK
    OK_BUT_NO_MEASUREMENTS
  }
  input CartItemForCapacityInput {
    id: ID!
    itemCount: Float!
  }
  input CartItemInput {
    additionalInfo: String
    basicQuantityUnit: String
    brand: String
    collectingPriority: Int
    ean: String
    id: ID!
    itemCount: String
    name: String
    price: Float!
    priceUnit: String
    productType: ProductType
    replace: Boolean
  }
  type CartRecommendation {
    amounts: [CartRecommendationProduct!]!
    products(
      from: Int
      limit: Int
      order: SortOrder
      orderBy: SortKey
      storeId: String!
    ): ProductList
    runId: String!
  }
  type CartRecommendationProduct implements EanProduct {
    amount: Int!
    ean: String!
  }
  enum CartRecommendationType {
    CART
    TRY
    YOU_MAY_NEED
  }
  type CitrusAd {
    banners: [CitrusAdBanner]
    contentStandardId: String
    slotId: String
  }
  """
  CitrusAd Banner X return items: https://developers.citrusad.com/integration/reference/banner-x-reference-1
  """
  type CitrusAdBanner {
    backgroundColour: String
    backgroundImage: String
    backgroundImagePosition: String
    bannerText: String
    bannerTextColour: String
    citrusAdId: String!
    citrusCampaignName: String
    citrusContentStandardId: String
    citrusExpiry: String
    citrusSlotId: String
    contentStandardId: String
    ctaFlag: Boolean
    ctaLink: String
    ctaText: String
    ctaTextAccessibility: String
    gtins: [String]
    headingText: String
    heroImage: String
    heroImageAltText: String
    position: Int
    secondaryBackgroundImage: String
    secondaryBackgroundImagePosition: String
    secondaryHeroImage: String
    secondaryHeroImageAltText: String
    secondaryHeroMode: String
    sellerId: String
    slotId: String
  }
  enum CitrusAdUserEventType {
    CLICK
    IMPRESSION
    PURCHASE
  }
  type ClientCartItem {
    additionalInfo: String
    approxPrice: Boolean!
    basicQuantityUnit: String!
    campaignPrice: Float
    campaignPriceValidUntil: String
    comparisonPrice: Float
    comparisonUnit: String
    countryName: CountryName!
    ean: String!
    frozen: Boolean
    id: ID!
    inStoreSelection: Boolean!
    isAgeLimitedByAlcohol: Boolean
    itemCount: Float!
    lowest30DayPrice: Float
    mainCategoryName: String!
    name: String!
    packagingLabelCodes: [String!]
    price: Float!
    priceUnit: String!
    productType: ProductType!
    quantityMultiplier: Float!
    regularPrice: Float
    replace: Boolean!
  }
  enum CoOperative {
    ARINA
    EEKOO
    EEPEE
    HAMEENMAA
    HOK
    KESKIMAA
    KEULA
    KOILLISMAA
    KPO
    KSO
    MAAKUNTA
    PEEASSA
    PEREMARKET
    PKO
    POK
    SATAKUNTA
    SSO
    SUUR_SAVO
    TOK
    VBO
  }
  type CollatedDeliverySlot {
    date: String!
    deliveryTimes: [DeliverySlot!]!
  }
  type Company {
    identityCode: String
    invoiceId: String
    name: String
  }
  type Coordinates {
    lat: String!
    lon: String!
  }
  type Country {
    code: String!
  }
  enum CountryCode {
    ET
    FI
  }
  type CountryName {
    fi: String
  }
  type Customer {
    address: String
    """
    Address part that can be translated to roof top coordinates e.g street address "Työmiehenrata 15"
    """
    addressLine1: String
    """
    More detailed address part like apartment number, building, block etc..
    """
    addressLine2: String
    bonusCard: String
    city: String
    companyName: String
    contact: Boolean
    email: String
    firstName: String
    invoiceAddress: String
    invoiceCity: String
    invoiceCompanyName: String
    invoiceFirstName: String
    invoiceLastName: String
    invoiceNumber: String
    invoicePhone: String
    invoicePostalCode: String
    lastName: String
    newsletter: Boolean
    phone: String
    postalCode: String
    sMembershipCardLastName: String
    sMembershipCardPostalCode: String
    sMembershipNumber: String
  }
  type CustomerDetailsResponse {
    comment: String
    companyName: String
    email: String
    firstName: String
    lastName: String
    phone: String
  }
  input CustomerInput {
    address: String
    """
    Address part that can be translated to roof top coordinates e.g street address "Työmiehenrata 15"
    """
    addressLine1: String
    """
    More detailed address part like apartment number, building, block etc..
    """
    addressLine2: String
    bonusCard: String
    city: String
    companyName: String
    contact: Boolean
    email: String!
    firstName: String!
    invoiceAddress: String
    invoiceCity: String
    invoiceCompanyName: String
    invoiceFirstName: String
    invoiceLastName: String
    invoiceNumber: String
    invoicePhone: String
    invoicePostalCode: String
    lastName: String!
    newsletter: Boolean
    phone: String!
    postalCode: String
    sMembershipCardLastName: String
    sMembershipCardPostalCode: String
    sMembershipNumber: String
  }
  enum CustomerType {
    b2b
    b2c
  }
  type DayAvailability {
    """
    Whether or not there is a available slot for the date
    """
    available: Boolean!
    """
    Date for the slot availability
    Format: 2023-01-01
    """
    date: String!
  }
  enum DayLabel {
    FRI
    MON
    SAT
    SUN
    THU
    TUE
    WED
  }
  type DeleteUserDataResult {
    success: Boolean!
  }
  type DeliveryArea {
    address: DeliveryAreaAddress
    alcoholSellingAllowed: AlcoholSellingStatus!
    areaId: String!
    availablePackagingMaterials: [AvailablePackagingMaterial!]
    """
    For Home Delivery Area the delivery area polygon.
    Currently in use only for Robot deliveries.
    """
    deliveryAreaPolygon: [Point!]
    deliveryMethod: DeliveryMethod!
    deliverySlots(
      endDate: String
      orderId: ID
      reservationId: ID
      startDate: String
    ): [CollatedDeliverySlot!]
    description: String
    """
    Geospatial distance from deliveryarea to \`to\`  in meters
    User location will be resolved by either from context or by \`to\` parameter.
    If non of the previous is provided the return value will be null.

    NOTES:
    1. searchHomeDeliveryTypes \`location\` parameter is set the context.
    2. nextDeliverySlot \`to\` parameter is set to the context. \`distance\` has resolved after \`nextDeliverySlot\` i.e after in the Query.
    """
    distance(to: Location): Int
    districts: [DeliveryDistrict!]
    dynamicPricing: Boolean!
      @deprecated(
        reason: "This is only to enable a feature in clients. Will be removed in near future."
      )
    """
    Only available for fast track slots.

    fastTrackOpeningHours: { from: ISODATE, to: ISODATE, status: OPEN/CLOSED }
    16:00pm
    fastTrackOpeningHours: { from: ISODATE-TODAY-9:00, to: ISODATE-TODAY-21:00, status: OPEN }
    22:00pm
    fastTrackOpeningHours: { from: ISODATE-TOMORROW-9:00, to: ISODATE-TOMORROW-21:00, status: CLOSED }
    """
    fastTrackOpeningHours: FastTrackOpeningHours
    homeDeliveryType: HomeDeliveryType
    isFastTrack: Boolean!
    isRemote: Boolean
    location: DeliveryLocation
    name: String!
    """
    @param rangeDays Duration of days to look for the next slot. Default is 14 days. Minimum is 1 and maximum 31
    """
    nextAvailableDeliverySlot(rangeDays: Int): DeliverySlot
      @deprecated(reason: "Use nextDeliverySlot in public-schema")
    nextDeliverySlot(to: Location): NextDeliverySlot
    postalCodes: [String!]
    price: Float! @deprecated(reason: "Will be removed from public-api")
    reservationEnabled: Boolean!
      @deprecated(
        reason: "This is only to enable a feature in clients. Will be removed in near future."
      )
    """
    Use this to query robot availability and ETA
    @throws:
      - RobotDeliveryUnavailableError [400], when given coordinats are out of service area
    """
    robotAvailability(to: Location!): RobotAvailability
    store: StoreInfo
    storeId: String!
    """
    Parameter to restrict delivery area visibility
    """
    visibilityGroup: String
  }
  type DeliveryAreaAddress {
    city: String!
    postalCode: String!
    street: String!
  }
  type DeliveryAreaPin {
    areaId: String!
    brand: String!
    location: Point!
    placeOfBusinessId: String!
  }
  type DeliveryAreaPinsResponse {
    pins: [DeliveryAreaPin!]
  }
  type DeliveryAreaSearchResponse {
    areas: [DeliveryArea!]
    total: Int
  }
  type DeliveryDetailsInfo {
    additionalInfo: String
    address: String
    city: String
    deliveryDate: String
    deliveryMethod: DeliveryMethod
    deliverySlotId: ID
    deliverySlotPrice: Float
    deliveryTime: String
    postalCode: String
  }
  type DeliveryDistrict {
    city: String
    postalCode: String!
  }
  type DeliveryLocation {
    address(lang: String): DeliveryAreaAddress
    distance: Float
    distanceFromStore(storeId: ID!): Int
    latitude: Float!
    longitude: Float!
    robotAvailability(areaId: ID!): RobotAvailability
  }
  enum DeliveryMethod {
    HOME_DELIVERY
    PICKUP
  }
  type DeliverySlot {
    alcoholSellingAllowed: AlcoholSellingStatus!
    areaId: String!
    availability: SlotAvailability!
    """
    When will the slot be closed? I.E. When is the last moment to send new orders?
    Format: 2021-02-07T19:00:00+02:00
    """
    closingTimestamp: String
    deliveryMethod: DeliveryMethod!
    """
    Format: ISO DateTime
    """
    endDateTime: String!
    """
    Estimated total time in minutes
    """
    estimatedFastTrackTime: Int
    """
    Estimated remaining time until delivery in minutes
    """
    estimatedRemainingFastTrackTime: Int
    """
    After slot is closed, new orders are no longer accepted
    """
    isClosed: Boolean!
    isFastTrack: Boolean!
    """
    If slot is not modifiable, it doesn't generally accept modifications.
    Order has separate isModifiable field that can have different value depending on the order state
    """
    isModifiable: Boolean!
    """
    Slot length in minutes
    """
    length: Int!
    """
    When is the modification cutoff? I.E. When is the last moment to send modifications?
    Format: 2021-02-07T19:00:00+02:00
    """
    modificationTimestamp: String
    price: Float!
    slotId: String!
    """
    Format: ISO DateTime
    """
    startDateTime: String!
    """
    In minutes since midnight
    """
    startTime: Int!
    """
    Human-readable time slot. E.g. 10:00-13:00
    """
    time: String!
    validFrom: String! @deprecated(reason: "will be removed from public schema")
  }
  type DeliverySlotReservationResponse {
    deliveryArea: DeliveryArea!
    deliverySlot: DeliverySlot!
    """
    Format: 2021-02-07T19:00:00+02:00
    """
    expiresAt: String!
    reservationId: String!
  }
  type DeliveryStore {
    areaId: ID
    availablePaymentMethods: [PaymentMethod]
    brand: String
    id: ID!
    name: String
  }
  enum DeliveryTimeStatus {
    AVAILABLE
    FULL
  }
  enum DeviceType {
    DESKTOP
    MOBILE
  }
  type DiscountProduct {
    discountAmount: Float!
    discountType: DiscountProductType!
    ean: String!
    id: String!
    isValid: Boolean!
    name: String!
  }
  enum DiscountProductType {
    FIXED_AMOUNT
    FIXED_DELIVERY_PRICE
  }
  enum Domain {
    ABC
    EPRISMA
    HERKKU
    S_KAUPAT
  }
  type DomainCustomer {
    cardType: String
    companyName: String
    email: String
    firstName: String
    lastName: String
    phone: String
    stockmannCardNumber: String
    type: CustomerType!
  }
  type DomainDelivery {
    address: String
    city: String
    postalCode: String
  }
  type DomainOrder {
    additionalInfo: String
    cartItems: [ClientCartItem!]!
    comment: String
    customer: DomainCustomer!
    deliveryAddress: DomainDelivery!
    deliveryAreaId: String
    deliverySlotId: String
    discountCode: String
    id: String
    orderStatus: OrderStatus
    payment: DomainPayment!
    reservationId: String
    storeId: String
  }
  type DomainPayment {
    invoiceNumber: String
    paymentMethod: PaymentMethod
    paymentStatus: PaymentStatus
  }
  interface EanProduct {
    ean: String!
  }
  type ExternalOrdersPOSData {
    accountID: String
    accountingStoreID: String!
    loyaltyTransactionTotals: [ExternalOrdersPOSRoyaltyTransation!]
    ownerBenefit: String
    registerID: String!
    registerTransNumber: String!
    shipment: [ExternalOrdersPOSShipment!]
    signature: String
    storeCode: String!
    storeID: String!
    totalAmount: String!
    transactionCode: String!
    transactionDateTime: String!
    transactionID: String!
    type: String!
    uniqueTransactionID: String
    versionNumber: String!
    webStoreID: String!
  }
  type ExternalOrdersPOSRoyaltyTransation {
    loyaltyBenefitAmount: String!
    loyaltyCardID: String!
    paymentBenefitAmount: String!
    sequenceID: String!
  }
  type ExternalOrdersPOSShipment {
    billOfLadingNr: String
    employeeID: String
    infoText: String
    orderID: String!
    sentTime: String
    shipmentID: String!
    status: String!
    statusDate: String!
    storeID: String!
  }
  enum FacetKey {
    brandName
    category
    labels
    supplierName
  }
  type FastTrackOpeningHours {
    from: String!
    status: StoreOpeningStatus!
    to: String!
  }
  type FavoriteItem {
    count: String
    createdAt: String!
    ean: String!
    modifiedAt: String!
    product(storeId: ID!): Product
  }
  type FavoritesList {
    createdAt: String!
    id: String!
    isDefault: Boolean!
    items: [FavoriteItem!]!
    modifiedAt: String!
    name: String!
    products(
      fallbackToGlobal: Boolean
      includeAgeLimitedByAlcohol: Boolean
      storeId: ID!
    ): [Product!]
    slug: String!
  }
  type FavoritesListDeleteResult {
    id: String!
  }
  input FavoritesListPatchInput {
    isDefault: Boolean
    name: String
  }
  type FavoritesLists {
    favoritesLists: [FavoritesList!]!
  }
  input FavoritesOperationDataInput {
    count: String
    ean: String
    listId: String!
  }
  input FavoritesOperationInput {
    data: FavoritesOperationDataInput!
    operation: FavoritesOperationOperationEnum!
  }
  enum FavoritesOperationOperationEnum {
    DELETE
    PUT
    SET_DEFAULT_LIST
  }
  type FavoritesOperationResult {
    discardedCount: Int!
    successfulCount: Int!
  }
  input Filters {
    key: FacetKey!
    value: [String!]!
  }
  type Hierarchy {
    count: Int
    name: String
    slug: String
  }
  type HierarchyPathItem {
    hidden: Boolean!
    id: String!
    name: String!
    slug: String!
  }
  type HomeDeliverySlot implements IHomeDeliverySlot {
    closingTime: String!
    deliveryTimeEnd: String!
    deliveryTimeStart: String!
    id: String!
    isAlcoholSellingAllowed: Boolean!
    isOrderModificationAllowed: Boolean!
    price: Float!
  }
  type HomeDeliverySlotsInStore {
    slots: [HomeDeliverySlot!]!
    store: StoreInfo!
  }
  enum HomeDeliveryType {
    NORMAL
    ROBOT
  }
  interface ICartCapacityWarning {
    type: CartCapacityWarningType!
  }
  interface IHomeDeliverySlot {
    """
    When the slot will be closed and no more orders can be added to it
    Format: 2023-01-01T12:00:00+02:00
    """
    closingTime: String!
    """
    When order delivery time window end
    Format: 2023-01-01T12:00:00+02:00
    """
    deliveryTimeEnd: String!
    """
    When order delivery time window starts
    Format: 2023-01-01T12:00:00+02:00
    """
    deliveryTimeStart: String!
    """
    Unique identifier for a slot
    Format: 2023-01-01:90ac047a-4b03-4292-adfd-476c0efe1404
    """
    id: String!
    """
    Whether or not alcohol selling is allowed for this slot
    """
    isAlcoholSellingAllowed: Boolean!
    """
    Whether or not orders added to this slot can be modified after they've been placed (set the NEW order status)
    """
    isOrderModificationAllowed: Boolean!
    """
    Slot price in EUR
    """
    price: Float!
  }
  interface IStructuredFacet {
    key: FacetKey!
  }
  type LocalizableText {
    default: String!
    en: String
    et: String
    fi: String
    sv: String
  }
  type LocalizedProductInfo {
    en: LocalizedProductInfoFields
    fi: LocalizedProductInfoFields
    sv: LocalizedProductInfoFields
  }
  type LocalizedProductInfoFields {
    allergens: [Allergen!]
    description: String
    ingredientStatement: String
    name: String
    storageGuideForConsumer: String
    userGuideForConsumer: String
  }
  type LocalizedText {
    en: String
    et: String
    fi: String
    sv: String
  }
  input Location {
    latitude: Float!
    longitude: Float!
  }
  """
  Combined type for "Delivery Fee" products and "Packaging Material" products. These used to share the
  same type with real Products, but now they have their own type with only a subset of the "Product" fields.
  """
  type MandatoryProduct {
    ean: String!
    id: ID!
    name: String!
    price: Float!
    productType: ProductType!
    storeId: String
      @deprecated(
        reason: "This field is not populated, only included for backwards-compatibility with the Product type"
      )
  }
  enum MaterialType {
    CARDBOARD_BOX
    DEPOSIT_BAG
    STANDARD_PACKAGING
  }
  type Municipality {
    code: String!
    name: LocalizableText
  }
  type Mutation {
    """
    No specific errors
    """
    addUserNotificationSubcription(
      mediums: [UserNotificationMediumEnum!]!
      type: UserNotificationSubscriptionEnum!
    ): UserNotificationSubscription
    """
    No specific errors
    """
    addUserPushNotificationDevice(deviceToken: String!, name: String): PushNotificationDevice
    """
    @throws:
    - AuthorizePaymentError [500]
    - SlotFullError [409]
    - SlotClosedError [409]
    - ReservationNotFoundError [404](reservation has been expired)
    """
    authorizePayment(orderId: ID!): AuthorizePaymentResponse
    """
    @throws:
    - OrderNotFoundError [404]
    """
    cancelOrder(id: ID!): Order
    cancelOrderModification(isLoggedIn: Boolean): Boolean!
    clearAuthenticationRedirectPath: Boolean
    clearAuthenticationTokens: Boolean
    clearCartAndOrderDetails(isLoggedIn: Boolean): Boolean
    """
    Creates a temporary reservation into a slot.

    @param deliverySlotId ID of the delivery slot where the reservation is made.
    @throws:
    - BadRequestError [400]
    - NotFoundError [404]
    - SlotFullError [409]
    - SlotClosedError [409]
    """
    createDeliverySlotReservation(deliverySlotId: ID!): DeliverySlotReservationResponse!
    """
    @throws:
    - MissingSlotId [400]
    - PostalCodeNotSupportedError [400]
    - SlotFullError [409]
    - SlotClosedError [409]
    - DiscountCodeExpiredError [404]
    - DiscountCodeNotFoundError[404]
    """
    createOrder(order: OrderInput): Order
    """
    @throws:
    - CreatePaymentError [500]
    - PaymentCardNotFoundError [404]
    - SlotClosedError [409]
    """
    createPayment(
      cardId: ID
      customWebstoreRedirectUrl: String
      device: DeviceType!
      orderId: ID!
      serviceType: ServiceType
      shouldSavePaymentCard: Boolean
    ): PaymentTerminalAddress
    createUserFavoritesList(name: String!): FavoritesList
      @deprecated(reason: "With shoppinglist multiple favorites list architecture no longer needed")
    """
    @throws
    - OrderCannotBeDeletedError
    """
    deleteAllUserData(retainUserProfile: Boolean): DeleteUserDataResult
    """
    @throws
    - DefaultListCannotBeDeletedError
    """
    deleteUserFavoritesList(listId: String!): FavoritesListDeleteResult
      @deprecated(reason: "With shoppinglist multiple favorites list architecture no longer needed")
    isOptimisticResponse: Boolean @client
    refreshDeliverySlotReservation(reservationId: ID!): DeliverySlotReservationResponse!
    releaseDeliverySlotReservation(reservationId: ID!): Void
    """
    No specific errors
    """
    removeUserNotificationSubscription(
      mediums: [UserNotificationMediumEnum!]!
      type: UserNotificationSubscriptionEnum!
    ): UserNotificationSubscription
    """
    @throws:
    - UnableToRemovePaymentCardError [500]
    """
    removeUserPaymentCard(cardId: ID!): RemoveUserPaymentCardResponse
    """
    No specific errors
    """
    removeUserPushNotificationDevice(deviceToken: String!): PushNotificationDevice
    """
    @throws:
    - UnableToRenamePaymentCardError [500]
    """
    renameUserPaymentCard(cardId: ID!, name: String!): RenameUserPaymentCardResponse
    resetDeliveryStore: Boolean
    """
    @throws:
    - UnableToResetPaymentCardForOrderError [500]
    - UserIdMismatchError [403]
    - OrderNotFoundError [404]
    """
    resetOrderPaymentCard(id: ID!): ResetOrderPaymentCardResponse
    """
    @throws:
    - UnableToSavePaymentCardError [500]
    """
    saveUserPaymentCard(orderId: ID!): SaveUserPaymentCardResponse
    sendCitrusAdUserEvents(
      adId: String!
      eventType: CitrusAdUserEventType!
      orderId: String
      sessionId: String!
    ): Void
    """
    Sends chat metadata to Giosg service

    @param visitorId - Giosg visitor id
    @param storeId - Currently selected store id
    @throws:
    - BadRequestError [400]
    """
    sendGiosgChatMetadata(storeId: String, visitorId: String!): Void
    """
    @throws:
    - BadRequestError [400]
    """
    sendProductUserEvents(
      eventType: ProductUserEventType!
      productIds: [String!]!
      sessionId: String!
    ): ProductUserEventResponse
    """
    @throws:
    - UnableToSetDefaultPaymentCardError [500]
    """
    setDefaultUserPaymentCard(cardId: ID!): SetDefaultUserPaymentCardResponse
    setDeliveryStore(
      areaId: ID
      availablePaymentMethods: [PaymentMethod]
      brand: String
      id: ID
      name: String
    ): Boolean
    startOrderEdit(orderId: ID, orderStatus: OrderStatus): Boolean
    stopOrderEdit: Boolean
    updateAuthenticationRedirectPath(authRedirectPath: String): String
    updateAuthenticationTokens(
      accessToken: String!
      idToken: String!
      refreshToken: String!
    ): UpdateAuthenticationTokensResponse
    updateCustomerInfo(
      companyName: String
      customerType: String
      email: String
      firstName: String
      lastName: String
      phone: String
    ): UpdateCustomerInfoResponse
    updateCustomerType(customerType: String): Boolean
    updateDeliveryDetails(
      additionalInfo: String
      address: String
      city: String
      deliveryDate: String
      deliveryMethod: DeliveryMethod
      deliverySlotId: ID
      deliverySlotPrice: Float
      deliveryTime: String
      postalCode: String
    ): UpdateDeliveryDetailsResponse
    """
    @throws:
    - MissingSlotId [400]
    - PostalCodeNotSupportedError [400]
    - SlotFullError [409]
    - SlotClosedError [409]
    - DiscountCodeExpiredError [404]
    - DiscountCodeNotFoundError[404]
    """
    updateOrder(id: String, order: OrderInput): Order
    updatePaymentMethodDetails(
      invoiceNumber: String
      paymentMethod: PaymentMethod
      paymentStatus: String
      savePaymentCard: Boolean
      selectedPaymentCardId: ID
      stockmannCardNumber: String
    ): Boolean
    updatePersonalInfo(
      comment: String
      companyName: String
      email: String
      firstName: String
      lastName: String
      phone: String
    ): UpdatePersonalInfoResponse
    updateSelectedAreaId(selectedAreaId: ID): Boolean
    updateSelectedBrand(selectedBrand: String): Boolean
    updateSelectedStoreId(selectedStoreId: ID): Boolean
    updateStockmannCardNumber(stockmannCardNumber: String): Boolean
    """
    @throws
    - MissingUpdateValuesError
    """
    updateUserFavoritesList(listId: String!, values: FavoritesListPatchInput!): FavoritesList
      @deprecated(reason: "With shoppinglist multiple favorites list architecture no longer needed")
    updateUserFavoritesListItems(operations: [FavoritesOperationInput!]!): FavoritesOperationResult
      @deprecated(reason: "With shoppinglist multiple favorites list architecture no longer needed")
    """
    No specific errors
    """
    updateUserNotificationSubscription(
      enabled: Boolean!
      mediums: [UserNotificationMediumEnum!]!
      type: UserNotificationSubscriptionEnum!
    ): UserNotificationSubscription
    userFavoritesAddItem(ean: String!): FavoritesList!
    userFavoritesRemoveItem(ean: String!): FavoritesList!
  }
  type Navigation {
    navigation: [NavigationItem!]
  }
  type NavigationItem {
    children: [NavigationItem!]
    hidden: Boolean
    id: String
    itemCount: Int
    name: String
    redirectedSlugs: [String!]!
    sequence: String
    slug: String
    type: String
  }
  """
  More condensed version of delivery slot which is compatible with the slots
  that we store in in deliveryareas elastic search. The goal in the future
  would be to bring the ordinary DeliverySlot closer to his model as well
  because it contains a lot of extra fields.
  """
  type NextDeliverySlot {
    alcoholSellingAllowed: AlcoholSellingStatus!
    availability: SlotAvailability!
    """
    Format: ISO DateTime
    """
    endDateTime: String!
    """
    Estimated time in minutes
    """
    estimatedFastTrackTime: Int
    isClosed: Boolean!
    isFastTrack: Boolean!
    price: Float!
    slotId: String!
    """
    Format: ISO DateTime
    """
    startDateTime: String!
  }
  type Nutrient {
    kcal: Float
    kj: Float
    name: String
    ri: String
    value: String
  }
  type NutrientClaim {
    nutritionalClaimElement: String
    nutritionalClaimType: String
  }
  type NutrientPerServing {
    name: String
    unit: String
    value: Float
  }
  type NutrientRaw {
    measurementPrecisionCode: String
    measurementUnitCode: String
    nutrientTypeCode: String
    quantityContained: Float
  }
  type ObjectFacet implements IStructuredFacet {
    key: FacetKey!
    objectValue: [ObjectFacetValue!]!
  }
  type ObjectFacetValue {
    doc_count: Int!
    name: String!
    value: String!
  }
  type Order {
    additionalInfo: String
    capturedPOSData: ExternalOrdersPOSData
    capturedTotalInCents: Int
    cartItems: [CartItem!]
    comment: String
    createdAt: String
    customer: Customer
    deliveryDate: String!
    deliveryMethod: DeliveryMethod
    """
    Client-supplied destination where the robot order should be delivered
    """
    deliveryPoint: DeliveryLocation
    deliverySlot: DeliverySlot
    deliverySlotId: ID!
    deliveryTime: String!
    discountCode: String
    handler: String
    homeDeliveryType: HomeDeliveryType
    id: ID!
    """
    Can this order be canceled?
    In most cases, this matches slot's isModifiable, but there are cases where they don't match
    """
    isCancelable: Boolean!
    isFastTrack: Boolean
    """
    Can this order be modified?
    """
    isModifiable: Boolean!
    linkVerificationToken: String!
    modifiedAt: String
    orderNumber: Int
    orderStatus: OrderStatus
    paymentId: String
    paymentMethod: PaymentMethod
    paymentStatus: PaymentStatus
    pickupLocation: String
    storeId: ID!
    trackingUrl: String
  }
  input OrderBy {
    key: SortKey
    order: SortOrder
  }
  type OrderEditActiveResponse {
    orderId: ID
    orderStatus: OrderStatus
  }
  input OrderInput {
    additionalInfo: String
    cartItems: [CartItemInput!]
    comment: String
    customer: CustomerInput!
    """
    Client-supplied destination where the robot order should be delivered
    """
    deliveryPoint: Location
    deliverySlotId: ID!
    discountCode: String
    paymentMethod: PaymentMethod!
    reservationId: ID
    storeId: ID!
  }
  enum OrderStatus {
    CANCELLED
    DONE
    IN_PROGRESS
    MODIFIED
    NEW
    OPEN
  }
  type PaymentCard {
    expired: Boolean!
    expiryDate: String!
    id: ID!
    maskedCardNumber: String!
    name: String!
    type: PaymentCardType!
    userGeneratedName: String!
  }
  enum PaymentCardType {
    AMERICAN_EXPRESS
    MASTERCARD
    S_BUSINESS
    S_VISA
    UNKNOWN
    VISA
  }
  type PaymentCards {
    cards: [PaymentCard!]!
    defaultPaymentCardId: ID
  }
  type PaymentDetailsResponse {
    invoiceNumber: String
    paymentMethod: PaymentMethod
    paymentStatus: String
    savePaymentCard: Boolean
    selectedPaymentCardId: ID
    stockmannCardNumber: String
  }
  enum PaymentMethod {
    CARD_PAYMENT
    INVOICE
    ON_DELIVERY
  }
  enum PaymentStatus {
    CANCELED
    CAPTURE_COMPLETED
    CAPTURE_FAILED
    CARD_AUTHORIZATION_FAILED
    CARD_AUTHORIZED
    PENDING
    UNAVAILABLE
  }
  type PaymentTerminalAddress {
    redirectUrl: String!
    transactionId: String!
  }
  type Point {
    latitude: Float!
    longitude: Float!
  }
  type Product {
    allergens: [Allergen!]
    approxPrice: Boolean
    availabilities(date: String): [ProductAvailabilities!]
    basicQuantityUnit: String @deprecated(reason: "Use salesUniteCom field")
    brandName: String
    categories: [String!]
    category: String
    categoryId: String
    collectingPriority: Float
    comparisonPrice: Float @deprecated(reason: "Prefer pricing.comparisonPrice")
    comparisonUnit: String
    consumerPackageSize: Float
    consumerPackageUnit: String
    countryName: CountryName
    countryOfMainRawMaterial: String
    depositPrice: Float @deprecated(reason: "Prefer pricing.depositPrice")
    description: String
    descriptionShort: String
    ean: String
    frozen: Boolean
    hierarchyPath: [HierarchyPathItem!]
    """
    The product id is currently mapped to "product.ean", but should be changed to "product.sokId".
    @warn This is not unique across all products, so use it together with "product.storeId"
    """
    id: ID!
    inStore: Boolean
    ingredientStatement: String
    isAgeLimitedByAlcohol: Boolean
    isGlobalFallback: Boolean
    isNewProduct: Boolean
    localized: LocalizedProductInfo
    location: ProductLocation
    marketingMessage: String
    measurement: ProductMeasurement
    modified: String
    name: String
    nutrientClaims: [NutrientClaim!]
    nutrients: [Nutrient!]
    nutrientsPerServing: [NutrientPerServing!]
    nutrientsRaw: [NutrientRaw!]
    onSale: Boolean
    packagingLabelCodes: [String!]
    packagingLabels: [String!]
    packagingMarkedLabelAccreditationCodes: [String!]
    price: Float @deprecated(reason: "Prefer pricing.currentPrice")
    priceUnit: String
    pricing: ProductPricing
    productDetails: ProductDetails!
    productType: ProductType
    quantityMultiplier: Float
    salesUniteCom: SalesUnit
    slug: String
    """
    The globally unique id of the product, mostly for internal usage. Customers should not need
    to see this.
    @warn this should not be optional after migrating to Product pipeline v3
    """
    sokId: ID
    stock: Float
    store: Store
    storeAvailability(onlyIfFallback: Boolean): [Product!]
    storeId: String
    storeSpecificData: [StoreSpecificData!]!
    supplierName: String
    taxPercentage: Float
    tuhtiId: String
    weight: Float @deprecated(reason: "Use measurement object instead")
    weightUnit: String @deprecated(reason: "Use measurement object instead")
  }
  type ProductAvailabilities {
    date: String
    label: ProductAvailabilityLabel
    score: Float
  }
  enum ProductAvailabilityLabel {
    AVAILABLE
    LIMITED_AVAILABILITY
    OUT_OF_STOCK
  }
  type ProductDetails {
    safetyInformation: SafetyInformation
  }
  type ProductList {
    from: Int
    items: [Product!]
    limit: Int
    searchProvider: SearchProvider
    structuredFacets: [IStructuredFacet!]
    total: Int
  }
  type ProductLocation {
    aisle: String
    coordinate: [Float!]
    floor: Int
    module: String
    shelf: String
  }
  type ProductMeasurement {
    grossWeight: Float
    grossWeightUnit: String
    netWeight: Float
    netWeightUnit: String
    pceApproxWeight: Float
  }
  type ProductPricing {
    """
    The currently active campaign price, or null when no active campaign.
    Also returns null when "isGlobalFallback" is true.
    @warn The "lowest30DayPrice" must be displayed to customers alongside this.
    """
    campaignPrice: Float
    """
    The ISO timestamp after which the current campaign price will no longer be valid,
    or null when no active campaign.
    """
    campaignPriceValidUntil: String
    """
    The price customers can use to compare prices of similar products,
    for example by kilogram or piece.
    """
    comparisonPrice: Float!
    """
    The current price the product should be sold to customers,
    including any discounts from active campaigns.
    """
    currentPrice: Float!
    """
    The possible deposit price of the product, or null when not applicable.
    """
    depositPrice: Float
    """
    The lowest price of the product in the last 30 days, or null when "isGlobalFallback" is true.
    @warn This must displayed to customers alongside the "campaignPrice".
    """
    lowest30DayPrice: Float
    """
    The regular price of the product, *not* including any discounts from active campaigns.
    """
    regularPrice: Float!
  }
  enum ProductType {
    COUPON
    DELIVERY_PRODUCT
    DEPOSIT
    PACKAGING_PRODUCT
    PRODUCT
    SERVICE_FEE
  }
  type ProductUserEventResponse {
    success: Boolean!
  }
  enum ProductUserEventType {
    ADD_TO_CART
    PRODUCT_CLICK
    PURCHASE
  }
  type PushNotificationDevice {
    deviceToken: String!
    name: String
  }
  type Query {
    """
    For query input, return list of autocompleted physical addresses. Addresses *do not* include
    geolocation coordinates.

    @param query The input that should be autocompleted
    @param countryCode Limit address lookup inside a specific country, e.g. FIN
    @param lang Change language of data, for example Finnish addresses in Swedish language
    """
    addressAutocomplete(
      countryCode: String!
      lang: String
      query: String!
    ): [AddressAutocompleteResult!]
    """
    For query input, return list of suggestions for physical addresses.

    @param query The input that should be autosuggested
    @param countryCode Limit address lookup inside a specific country, e.g. FIN
    @param lang Change language of data, for example Finnish addresses in Swedish language
    """
    addressAutosuggest(
      at: Location
      countryCode: String!
      lang: String
      limit: Int
      query: String!
    ): [AddressGeocodeResult!]
    """
    For query input, return a physical address with geolocation coordinates.
    Typically there's only one result, unless the query input is ambiguous.

    @param query the adddress input
    @param countryCode Limit address lookup inside a specific country, e.g. FIN
    @param lang Change language of data, for example Finnish addresses in Swedish language
    """
    addressGeocode(countryCode: String, lang: String, query: String!): [AddressGeocodeResult!]
    addressValidate(countryCode: String, lang: String, query: String!): AddressValidateResult!
    authTokens(refreshToken: String): AuthTokens
    authenticationRedirectPath: String
    authenticationTokens: AuthenticationTokens
    """
    Get batch recommendations of the requested type.
    @param recommendation type
    """
    batchProductRecommendation(type: BatchRecommendationTypeEnum!): BatchRecommendation
    bonusInfo: BonusInfo
    cartItem(id: ID): ClientCartItem
    cartItems: [ClientCartItem!]!
    citrusAd(
      brand: String!
      placement: String!
      sessionId: String!
      slotId: String!
      useCustomerId: Boolean!
    ): CitrusAd
    """
    Get complementary recommendations for a list of eans (like a shopping cart), optionally focused on a
    single specific ean (for example when on a product page, doesn't have to be included in the list!).

    When focusing on a specific ean, the list of eans will be taken into account on a lower priority.

    @param eans the list of eans to get complementary recommendations on
    @param focusOnEan the single product to focus on, for example when on a product page
    @param storeId the id of the store to use for catalog
    @param limit the maximum number of recommendations to return

    @example <caption>Get complementary products for a cart</caption>
    query complementaryProducts(eans: cart.map(item => item.ean), storeId: cart.storeId ) {
      name
      ean
    }

    @example <caption>Get complementary products for a specific product, taking cart into account</caption>
    query complementaryProducts(focusOnEan: product.ean, eans: cart.map(item => item.ean), storeId: cart.storeId ) {
      name
      ean
    }
    """
    complementaryProducts(eans: [ID!]!, focusOnEan: ID, limit: Int, storeId: ID!): [Product!]!
    customerDetails: CustomerDetailsResponse
    customerType: String
    deliveryArea(id: ID!): DeliveryArea
    deliveryAreaPins(domains: [Domain!]): DeliveryAreaPinsResponse
    deliveryAreas(
      brand: String
      deliveryMethod: DeliveryMethod
      domains: [Domain!]
      postalCode: String
    ): [DeliveryArea!]
    deliveryDetailsInfo: DeliveryDetailsInfo
    deliverySlot(id: ID!): DeliverySlot
    deliverySlotReservation(reservationId: ID!): DeliverySlotReservationResponse!
    deliveryStore: DeliveryStore
    domainOrder: DomainOrder!
    """
    Check if there are any type of slots available in given date range (1-40 days)
    """
    homeDeliverySlotAvailabilitiesForPostalCode(
      """
      Search slots until the end of the date
      Format: 2023-01-02
      """
      endDate: String!
      """
      Search slots which deliver to area of postal code
      Format: 00100
      """
      postalCode: String!
      """
      Search slots beginning from date
      Format: 2023-01-01
      """
      startDate: String!
    ): [DayAvailability!]!
    """
    Search for available slots which deliver to area of given postal code (1-7 days)
    """
    homeDeliverySlotsForPostalCode(
      """
      Search slots until the end of the date
      Format: 2023-01-02
      """
      endDate: String!
      """
      Search slots which deliver to area of postal code
      Format: 00100
      """
      postalCode: String!
      """
      Search slots beginning from date
      Format: 2023-01-01
      """
      startDate: String!
    ): [HomeDeliverySlotsInStore!]!
    order(id: ID!, linkVerificationToken: String): Order
    orderEditActive: OrderEditActiveResponse
    paymentDetails: PaymentDetailsResponse
    """
    @throws:
    - ProductNotFoundError [404]
    """
    product(
      fallbackToGlobal: Boolean
      id: ID!
      includeAgeLimitedByAlcohol: Boolean
      storeId: ID
    ): Product
    recipe(id: ID!): Recipe
    """
    Returns an address for given set of coordinates. Argument lang should be in shape of "fi-FI"
    """
    reverseGeocode(lang: String, latitude: Float!, longitude: Float!): DeliveryAreaAddress
    """
    Capacity calculation for given cart. Total capacity is two 40L (64cm * 32cm * 20cm) shopping bags.
    Products that don't fit by it's dimension into a single shopping bag are rejected.
    Cart total max weight is set to 20 KG.
    """
    robotCartCapacity(items: [CartItemForCapacityInput!]!, storeId: String!): CartCapacity!
    """
    @throws:
      - RobotDeliveryUnavailableError [400], when given coordinats are out of service area
    """
    robotNearestDeliveryPoint(to: Location!): DeliveryLocation
    searchDeliveryAreas(
      boundingBox: BoundingBox
      brands: [StoreBrand]
      deliveryMethod: DeliveryMethod
      domains: [Domain]
      freetext: String
      homeDeliveryType: [HomeDeliveryType!]
      location: Location
      postalCode: String
      storeId: ID
      visibilityGroups: [String!]
    ): DeliveryAreaSearchResponse
      @deprecated(reason: "Use searchHomeDeliveryAreas and searchPickupDeliveryAreas instead.")
    searchHomeDeliveryAreas(
      brands: [StoreBrand]
      domains: [Domain]
      freetext: String
      from: Int
      homeDeliveryType: [HomeDeliveryType!]
      location: Location
      pageSize: Int
      postalCode: String
      storeId: ID
      visibilityGroups: [String!]
    ): DeliveryAreaSearchResponse
    searchPickupDeliveryAreas(
      boundingBox: BoundingBox
      brands: [StoreBrand]
      domains: [Domain]
      freetext: String
      from: Int
      location: Location
      pageSize: Int
      storeId: ID
      visibilityGroups: [String!]
    ): DeliveryAreaSearchResponse
    searchStores(
      """
      Used by sitemap generation
      """
      _dangerousLoadAllOverrideForSitemap: Boolean
      brand: StoreBrand
      country: CountryCode
      cursor: String
      domains: [Domain!]
      query: String
    ): SearchStoresResponse
    selectedAreaId: ID
    selectedBrand: String
    selectedStoreId: ID
    serviceFee: ServiceFee
    serviceSubscriptions(serviceTypes: [ServiceSubscriptionServiceType!]!): [ServiceSubscription!]!
    store(id: ID!): Store
    stores(brand: String, domains: [Domain!]): [StoreInfo!]
    userFavorites: FavoritesList!
    userFavoritesList(listId: ID!): FavoritesList
      @deprecated(reason: "With shoppinglist multiple favorites list architecture no longer needed")
    userFavoritesLists: FavoritesLists
      @deprecated(reason: "With shoppinglist multiple favorites list architecture no longer needed")
    userNotificationPreferences: UserNotificationPreferences
    userOrder(id: ID!): UserOrderWithCartItems
    userOrders(
      dataSources: [UserOrderDataSource!]
      domain: Domain
      includeSBonusOrders: Boolean
      """
      Limit queried items per datasource element
      """
      limit: Int
    ): [UserOrder!]!
    userPaymentCards: PaymentCards
    userProductRecommendation(type: CartRecommendationType!): CartRecommendation
    userProfile: UserProfile
    userProfileGuidedEntry: String!
  }
  type Recipe {
    categories: RecipeCategories
    cookTime: Int
    description: String
    externalUrl: String!
    id: ID!
    imageUrl: String
    ingredients: [RecipeIngredient!]!
    instructions: [RecipeInstruction!]!
    name: String!
    portions: Int
      @deprecated(
        reason: "Not supported anymore. Use yield and yieldType fields instead because this is missing the type."
      )
    publisher: String
    slug: String!
    yield: Int!
    yieldType: RecipeYieldType!
  }
  type RecipeCategories {
    contentConcept: [String!]
    course: [String!]
    cuisine: [String!]
    diet: [String!]
    foodType: [String!]
    ingredientCategory: [String!]
    meal: [String!]
    preparation: [String!]
    theme: [String!]
  }
  type RecipeIngredient {
    amountType: String
    eans: [String!]
    groupName: String
    ingredientTitle: String
    maxAmount: Float
    minAmount: Float
    name: String!
    products(fallbackToGlobal: Boolean, storeId: ID!): [Product!]
  }
  type RecipeInstruction {
    body: String!
  }
  enum RecipeYieldType {
    PIECE
    PORTION
    UNIT
  }
  type RemoveUserPaymentCardResponse {
    id: ID!
  }
  type RenameUserPaymentCardResponse {
    id: ID!
    name: String!
  }
  type ResetOrderPaymentCardResponse {
    id: ID!
  }
  type RobotAvailability {
    eta: Int!
    status: RobotAvailabilityStatus!
  }
  enum RobotAvailabilityStatus {
    AVAILABLE
    UNAVAILABLE
  }
  type SafetyInformation {
    caption: String
    dangerClauses: [SafetyInformationClause!]!
    safetyClauses: [SafetyInformationClause!]!
    warningSymbols: [WarningSymbol!]!
  }
  type SafetyInformationClause {
    code: String!
    text: String!
  }
  enum SalesUnit {
    KG
    KPL
  }
  type SaveUserPaymentCardResponse {
    id: ID!
  }
  enum SearchProvider {
    elasticsearch
    loop54
  }
  type SearchStoresResponse {
    cursor: String
    maybeHasMore: Boolean
    stores: [StoreInfo!]!
    totalCount: Int!
  }
  type ServiceFee {
    ean: String
    id: Int
    name: String
    price: Int
  }
  """
  The "Service" products are optional fees customer support can apply to an order, for example 15 EUR for creating an order over the phone.
  These used to share the same type with real Products, but now they have their own type with only a subset of the "Product" fields.
  """
  type ServiceProduct {
    ean: String!
    id: ID!
    name: String!
    price: Float!
    productType: ProductType!
    storeId: String
      @deprecated(
        reason: "This field is not populated, only included for backwards-compatibility with the Product type"
      )
  }
  type ServiceSubscription {
    """
    Is the user allowed to change the current activation state
    """
    changeAllowed: Boolean
    serviceName: String
    serviceType: ServiceSubscriptionServiceType!
    """
    Service activation for this customer
    """
    subscribed: Boolean!
    """
    Date when this service was activated
    """
    subscriptionStartDate: String
  }
  enum ServiceSubscriptionServiceType {
    ABC_MOBILE
    ANALYTICS_AND_PROFILING_BARRING
    AO_POSTI_BARRING
    BALANCE_TERMINAL_LAST_USED
    BENEFITS_ACCOUNT_BALANCE
    BENEFITS_ACCOUNT_BALANCE_SNAVI
    COMMON_REGISTER_DIGILI
    COMMON_REGISTER_SPANKKI
    ERECEIPT_ARCHIVE
    ERECEIPT_PAKU
    MARKETING_MAIL_BARRING
    PRODUCT_PURCHASE_DATA_COLLECTION_BARRING
    RESEARCH_SURVEY_BARRING
    SMOBIILI_LAST_USED
    TELEPHONE_MARKETING_BARRING
    TELEPHONE_ROBINSON_BARRING
    THIRD_PARTY_TARGETING_BARRING
    WARRANTY_RECEIPT_FEATURE
    WEB_AO_POSTI
  }
  enum ServiceType {
    APP_HOSTED
    PAYMENT_GATEWAY_HOSTED
  }
  type SetDefaultUserPaymentCardResponse {
    id: ID!
  }
  enum SlotAvailability {
    AVAILABLE
    FULL
  }
  enum SlotRecurrence {
    MODIFIED_WEEKLY
    ONCE
    WEEKLY
  }
  enum SortKey {
    comparisonPrice
    name
    price
    score
  }
  enum SortOrder {
    asc
    desc
  }
  type Store {
    availablePaymentMethods: [PaymentMethod!]
    brand: String
    city: String
    coOperative: CoOperative
    contactInfo: StoreContactInfo
    discountProduct(discountCode: String): DiscountProduct
    domains: [Domain!]!
    feedback: StoreFeedback
    id: ID!
    location: StoreLocation
    """
    List lowest prices for available delivery methods.
    """
    lowestDeliveryPrices: [StoreLowestDeliveryPrice]
    """
    List the delivery and packaging material fees when ordering from this store. This used to return the real "Product"
    type but is now separated with only a subset of the fields available. For backwards-compatibility this returns a list of
    single "MandatoryProduct" type, but internally the data is split into "DeliveryProducts" and "PackagingMaterialProducts".
    """
    mandatoryProducts(
      deliveryMethod: DeliveryMethod
      deliverySlotId: String
      orderId: String
      packagingMaterialEan: String
      reservationId: String
    ): [MandatoryProduct!]
    name: String!
    navigation: [NavigationItem!]
    postalCode: String
    products(
      eans: [String!]
      fallbackToGlobal: Boolean
      filters: [Filters!]
      from: Int
      generatedSessionId: String
      hierarchyId: String
      includeAgeLimitedByAlcohol: Boolean
      limit: Int
      loop54DirectSearch: Boolean
      order: SortOrder
      orderBy: SortKey
      queryString: String
      searchProvider: SearchProvider
      slug: String
      structuredFacets: [StructuredFacetInput!]
      useRandomId: Boolean
    ): ProductList
    """
    List the available service fees for the store, that customer support can apply to an order.
    """
    serviceProducts: [ServiceProduct!]
    services: [StoreService!]
    shortName: String
    slug: String!
    street: String
    weeklyOpeningHours: [StoreWeeklyOpeningTime!]
  }
  type StoreAddress {
    country: Country
    municipality: Municipality!
    postcode: String!
    postcodeName: LocalizableText
    street: LocalizableText!
  }
  enum StoreBrand {
    ABC
    ALEPA
    EPRISMA
    HERKKU
    MESTARIN_HERKKU
    PRISMA
    SALE
    SOKOS_HERKKU
    S_MARKET
  }
  type StoreContactInfo {
    email: String
    phoneNumber: StorePhoneNumber
  }
  type StoreDayOpeningTime {
    date: String!
    day: DayLabel!
    exceptional: Boolean
    message: LocalizedText
    mode: StoreOpeningTimeRangeLabel!
    ranges: [StoreOpeningTimeRange!]
  }
  type StoreFeedback {
    url: LocalizableText
  }
  type StoreInfo {
    availablePaymentMethods: [PaymentMethod!]
    brand: String
    city: String
    contactInfo: StoreContactInfo
    domains: [Domain!]!
    feedback: StoreFeedback
    id: ID!
    location: StoreLocation
    name: String
    postalCode: String
    services: [StoreService!]
    shortName: String
    slug: String
    street: String
    weeklyOpeningHours: [StoreWeeklyOpeningTime!]
  }
  type StoreLocation {
    address: StoreAddress
    coordinates: Coordinates
  }
  type StoreLowestDeliveryPrice {
    areaId: String!
    deliveryMethod: DeliveryMethod!
    description: String
    isFastTrack: Boolean!
    name: String
    price: Float!
  }
  enum StoreOpeningStatus {
    CLOSED
    OPEN
  }
  type StoreOpeningTimeRange {
    close: String!
    closeOnSameDay: Boolean
    open: String!
  }
  enum StoreOpeningTimeRangeLabel {
    ALL_DAY
    BY_RESERVATION
    CLOSED
    RANGE
  }
  type StorePhoneNumber {
    callCharge: LocalizableText
    callChargeGroup: String
    number: String!
  }
  type StoreService {
    code: String!
    name: LocalizableText
  }
  type StoreSpecificData {
    newProduct: Boolean!
    placeOfBusinessId: ID!
  }
  type StoreWeeklyOpeningTime {
    openingTimes: [StoreDayOpeningTime!]!
    weekNumber: Int!
  }
  type StringFacet implements IStructuredFacet {
    key: FacetKey!
    stringValue: [String!]!
  }
  input StructuredFacetInput {
    key: FacetKey!
    order: SortOrder
  }
  type UpdateAuthenticationTokensResponse {
    accessToken: String
    idToken: String
    refreshToken: String
  }
  type UpdateCustomerInfoResponse {
    companyName: String
    customerType: String
    email: String
    firstName: String
    lastName: String
    phone: String
    stockmannCardNumber: String
  }
  type UpdateDeliveryDetailsResponse {
    additionalInfo: String
    address: String
    city: String
    deliveryDate: String
    deliveryMethod: DeliveryMethod
    deliverySlotId: ID
    deliverySlotPrice: Float
    deliveryTime: String
    postalCode: String
  }
  type UpdatePersonalInfoResponse {
    comment: String
    companyName: String
    email: String
    firstName: String
    invoiceNumber: String
    lastName: String
    phone: String
  }
  enum UserNotificationMediumEnum {
    PUSH_NOTIFICATION
  }
  type UserNotificationPreferences {
    marketing: UserNotificationSubscription!
    transactional: UserNotificationSubscription!
  }
  type UserNotificationSubscription {
    enabled: Boolean
    pushNotification: Boolean
    type: String!
  }
  enum UserNotificationSubscriptionEnum {
    MARKETING
    TRANSACTIONAL
  }
  type UserOrder {
    createdAt: String!
    customer: Customer
    dataSource: UserOrderDataSource!
    deliveryDate: String!
    deliveryMethod: DeliveryMethod!
    deliverySlotClosed: Boolean
    """
    The ISO timestamp after which new orders can't be sent to slot.
    Note modification cutoff can be at different time.
    Can be missing if slot information can't be fetced.
    For example "2023-02-09T09:00:00+03:00".
    """
    deliverySlotClosingTimestamp: String
    """
    The ISO timestamp after which an order created in the slot can no longer be modified or cancelled.
    Can be missing if slot information can't be fetced.
    This can differ from slot's modificationTimestamp if extra time is given for modifications,
    for example for fixing payment card.
    This time can change after modification fixing problems is sent.
    For example "2023-02-09T09:00:00+03:00".
    """
    deliverySlotModificationTimestamp: String
    deliveryTime: String
    homeDeliveryType: HomeDeliveryType
    id: String!
    """
    Can this order be canceled?
    In most cases, this matches slot's isModifiable, but there are cases where they don't match
    """
    isCancelable: Boolean!
    isFastTrack: Boolean
    """
    Can this order be modified?
    """
    isModifiable: Boolean!
    modifiedAt: String
    orderNumber: String!
    orderStatus: OrderStatus!
    paymentMethod: PaymentMethod
    paymentStatus: PaymentStatus
    pickupLocation: String
    storeId: String!
    storeName: String
    totalCost: Float!
    trackingUrl: String
    userId: String
  }
  type UserOrderCartItem {
    additionalInfo: String
    basicQuantityUnit: String
    ean: String!
    id: ID!
    itemCount: Float!
    name: String!
    price: Float
    priceUnit: String
    productType: ProductType!
    replace: Boolean
  }
  enum UserOrderDataSource {
    FOODIE @deprecated(reason: "Foodie does not exist")
    S_KAUPAT
  }
  type UserOrderWithCartItems {
    cartItems: [UserOrderCartItem!]!
    createdAt: String!
    customer: Customer
    dataSource: UserOrderDataSource!
    deliveryDate: String!
    deliveryMethod: DeliveryMethod!
    deliverySlotClosed: Boolean
    deliverySlotClosingTimestamp: String
    deliverySlotModificationTimestamp: String
    deliveryTime: String
    id: String!
    isFastTrack: Boolean
    isModifiable: Boolean!
    modifiedAt: String
    orderNumber: String!
    orderStatus: OrderStatus!
    paymentMethod: PaymentMethod
    paymentStatus: PaymentStatus
    pickupLocation: String
    storeId: String!
    storeName: String
    totalCost: Float!
    userId: String
  }
  type UserProfile {
    address: Address
    company: Company
    customerType: CustomerType!
    email: String
    firstName: String
    isEmailVerified: Boolean
    isPhoneNumberVerified: Boolean
    lastName: String
    """
    Salesforce PersonContactId
    """
    personContactId: String
    phoneNumber: String
    tags: [String!] @deprecated(reason: "Not in use, remove client usage")
    userId: String! @deprecated(reason: "Will be removed, use sub from accessToken instead")
  }
  scalar Void
  type WarningSymbol {
    code: WarningSymbolCode!
    description: String!
  }
  enum WarningSymbolCode {
    GHS01
    GHS02
    GHS03
    GHS04
    GHS05
    GHS06
    GHS07
    GHS08
    GHS09
  }
`
