import styled, { css } from 'styled-components'

export const StyledHeading = styled.span`
  ${({ theme }) => css(theme.variants.paragraph2.regular)};
  margin-top: ${({ theme }) => theme.spacings.medium};
`

export const StyledSubHeading = styled.span`
  ${({ theme }) => css(theme.variants.body2.regular)};
  margin-top: ${({ theme }) => theme.spacings.small};
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  margin-left: ${({ theme }) => theme.spacings.small};
  margin-right: ${({ theme }) => theme.spacings.small};
  text-align: center;
`
