import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'

import { DeliverySlotReservationStatus, deliverySlotReservationStatusVar } from '../status'
import { clearReservationId } from '../utils/local-storage'

/**
 * Returns a callback for clearing the currently reserved delivery slot from Apollo cache,
 * mostly useful after creating an order.
 */
export const useClearDeliverySlotReservationFromCache = () => {
  const apolloClient = useApolloClient()

  return useCallback(
    (reservationId: string) => {
      apolloClient.cache.modify({
        id: apolloClient.cache.identify({
          __typename: 'DeliverySlotReservationResponse',
          reservationId,
        }),
        /**
         * Delete entire Reservation from cache using cache.modify().
         * This broadcasts updates to all active queries, unlike
         * cache.evict().
         *
         * @see https://www.apollographql.com/docs/react/api/cache/InMemoryCache/#delete
         */
        fields: (_, details) => details.DELETE,
      })

      deliverySlotReservationStatusVar(DeliverySlotReservationStatus.OK)
      clearReservationId()
    },
    [apolloClient.cache],
  )
}
