import type { MutationResolvers } from 'types/gql/type-defs'

import { GetCustomerDetailsDocument, GetCustomerTypeDocument } from '@shared/gql/document-nodes'
import { asCustomerType } from 'domain/customer'
import { emptyCustomerDetails } from 'services/App/empty-initial-states'
import { DaoService } from 'services/LocalStorage'

export const updateCustomerInfo: MutationResolvers['updateCustomerInfo'] = (_, args, { cache }) => {
  const cachedCustomerDetails = cache.readQuery({
    query: GetCustomerDetailsDocument,
  })?.customerDetails

  const customerDetails = {
    ...emptyCustomerDetails(),
    ...cachedCustomerDetails,
    ...args,
  }

  const customerType = asCustomerType(args.customerType)

  cache.writeQuery({
    query: GetCustomerTypeDocument,
    data: {
      __typename: 'Query',
      customerType,
    },
  })

  DaoService.CustomerDAO.saveCustomerType(customerType)

  cache.writeQuery({
    query: GetCustomerDetailsDocument,
    data: {
      __typename: 'Query',
      customerDetails,
    },
  })

  DaoService.CustomerDAO.saveCustomerData(customerDetails)

  return null
}

export const updatePersonalInfo: MutationResolvers['updatePersonalInfo'] = (_, args, { cache }) => {
  const cachedCustomerDetails = cache.readQuery({
    query: GetCustomerDetailsDocument,
  })?.customerDetails

  const customerDetails = {
    ...emptyCustomerDetails(),
    ...cachedCustomerDetails,
    ...args,
  }

  cache.writeQuery({
    query: GetCustomerDetailsDocument,
    data: {
      __typename: 'Query',
      customerDetails,
    },
  })

  DaoService.CustomerDAO.saveCustomerData(customerDetails)

  return null
}

export const updateCustomerType: MutationResolvers['updateCustomerType'] = (_, args, { cache }) => {
  const customerType = asCustomerType(args.customerType)

  cache.writeQuery({
    query: GetCustomerTypeDocument,
    data: {
      __typename: 'Query',
      customerType,
    },
  })

  DaoService.CustomerDAO.saveCustomerType(customerType)

  return null
}
