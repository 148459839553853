import type { ApolloLink, Observable } from '@apollo/client'
import type { GraphQLError } from 'graphql'

import { fromPromise } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { IS_BROWSER } from 'utils/is-browser'

import { fetchRefreshedTokens, getCachedTokens } from './token-utils'

const refreshErrorCodes = ['UNAUTHENTICATED', 'http_401']

interface LogCapture {
  captureException: (error: Error) => void
  captureMessage: (message: string) => void
}

const isAuthenticationError = (error: GraphQLError): boolean =>
  refreshErrorCodes.includes(error.extensions?.code as string)

const PENDING_REFRESH_WAIT_PERIOD = 10000

export const errorLink = (errorCapture: LogCapture): ApolloLink => {
  let pendingRefresh: Observable<void> | null

  return onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { message, extensions } = err
        if (isAuthenticationError(err) && IS_BROWSER) {
          const { authenticationTokens } = getCachedTokens({ operation }) || {}
          if (authenticationTokens?.refreshToken) {
            // Fetch refresh tokens and retry.
            pendingRefresh =
              pendingRefresh ||
              fromPromise(
                fetchRefreshedTokens({
                  authenticationTokens,
                  operation,
                  message,
                }).finally(() => {
                  // Let's wait a while before clearing the pendingRefresh to allow any
                  // currently ongoing requests to still use the existing refresh observable.
                  setTimeout(() => (pendingRefresh = null), PENDING_REFRESH_WAIT_PERIOD)
                }),
              )

            return pendingRefresh.flatMap(() => forward(operation))
          }
        } else {
          errorCapture.captureMessage(message)
          console.error(`[GraphQl error]: ${message}, code: ${extensions?.code}`)
        }
      }
    }

    if (networkError) {
      errorCapture.captureException(networkError)
      console.error(`[Network error]: ${networkError}`)
    }
  })
}
