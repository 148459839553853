import type { ReactiveVar } from '@apollo/client'
import type { Brand } from '@shared/domain/brand'
import type { I18nLang } from '@shared/domain/i18n'
import type { IncidentValidationError } from 'domain/error-messages/error-parse'
import type { ValidationError } from 'validation/validators'

import { makeVar } from '@apollo/client'

export const loadingOverlayVar: ReactiveVar<boolean> = makeVar<boolean>(false)

export const refreshTokenExpired: ReactiveVar<boolean> = makeVar<boolean>(false)

export const searchVisibleVar: ReactiveVar<boolean> = makeVar<boolean>(false)

export const errorModalVar: ReactiveVar<IncidentValidationError | ValidationError | null> = makeVar<
  IncidentValidationError | ValidationError | null
>(null)

export const orderSubmittedVar = makeVar<boolean>(false)

export const navigationVisibleVar = makeVar<boolean>(false)

export const overrideBrandVar: ReactiveVar<Brand | null> = makeVar<Brand | null>(null)

export const favoritesLoginPromptVisibleVar = makeVar<boolean>(false)

export const paymentCardInitializedVar = makeVar(false)

export const showGlobalLoadingIndicatorVar = makeVar(false)

export const localizationVar = makeVar<I18nLang | null>(null)

export const isClientSideVar = makeVar(false)

export const fallbackSearchProviderVar = makeVar<string | null>(null)
