import type { FC } from 'react'

import { useMutation } from '@apollo/client'
import { useHasServiceConsent } from '@s-group/react-usercentrics'
import { getConfig } from '@shared/config'
import { SendGiosgChatMetadataDocument } from '@shared/gql/document-nodes'
import { GiosgConsentGateButton } from 'components/giosg/GiosgConsentGate'
import { ServiceId } from 'config/usercentrics'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { useRunOnce } from 'utils/hooks/use-run-once'
import { commonLogger } from 'utils/log/common-logger'

const { giosg: giosgConfig } = getConfig()

// Hash/fragment used to trigger opening the chat when it appears in the URL.
const OPEN_CHAT_HASH = '#open-chat'

type GiosgCallback = () => void
type Giosg = (cb: GiosgCallback) => void
type GiosgChat = {
  unpackedVisitorCid?: string
  on: (event: string, callback: () => Promise<void>) => void
  off: (event: string, callback: () => Promise<void>) => void
}

declare global {
  interface Window {
    _giosg?: Giosg
    giosg: GiosgChat
  }
}

const giosgScript = `
(function(w, t, f) {
  var s='script',o='_giosg',h='https://service.giosg.com',e,n;e=t.createElement(s);e.async=1;e.src=h+'/live2/'+f;
  w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};w[o]._c=f;w[o]._h=h;n=t.getElementsByTagName(s)[0];n.parentNode.insertBefore(e,n);
})(window,document,"${giosgConfig.organizationId}");
`

/**
 * The Giosg script tag, or null if user consent is not given.
 * @see https://docs.giosg.com/getting_started/how_giosg_script_works/
 */
export const GiosgScript: FC = () => {
  useOpenChatLinks()

  const [visitorId, setVisitorId] = useState('')
  const [sendGiosgChatMetadata] = useMutation(SendGiosgChatMetadataDocument)
  // NOTE: The requirement for the default storeId is different for giosg integration.
  const { selectedStoreId: storeId } = useStoreId()

  const hasConsent = useHasServiceConsent(ServiceId.Giosg)
  const router = useRouter()

  const isSummaryPage = router.asPath.startsWith('/yhteenveto')
  const isProductDetailPage = router.asPath.startsWith('/tuote')
  const isShoppinglistViews = router.asPath.startsWith('/ostoslistat')

  useEffect(() => {
    if (hasConsent && !visitorId) {
      const giosgInterval = setInterval(() => {
        if (window?.giosg?.unpackedVisitorCid) {
          setVisitorId(window.giosg.unpackedVisitorCid)
          clearInterval(giosgInterval)
        }
      }, 250)
      return () => clearInterval(giosgInterval)
    }
  }, [hasConsent, visitorId])

  useEffect(() => {
    if (hasConsent && visitorId) {
      const sendChatHandler = async () => {
        try {
          await sendGiosgChatMetadata({
            variables: { visitorId, storeId: storeId || null },
          })
        } catch (error) {
          commonLogger.errorSync({ error })
        }
      }
      window.giosg.on('chatwindow:open', sendChatHandler)
      return () => window.giosg.off('chatwindow:open', sendChatHandler)
    }
  }, [sendGiosgChatMetadata, visitorId, hasConsent, storeId])

  const bottomMargin = isSummaryPage || isProductDetailPage || isShoppinglistViews ? '80px' : '20px'
  return hasConsent ? (
    <Script id="giosg-script">{giosgScript}</Script>
  ) : (
    <GiosgConsentGateButton bottomMargin={bottomMargin} />
  )
}

// Selectors for the chat buttons for the hook below; bot chat button, consent flow button, giosg chat button.
const CHAT_BUTTON_SELECTORS = [
  '.wp-exclude-emoji .needsclick button',
  '#GiosgChatButton',
  '#giosg_chat_now_button',
]

const handleOpenChatHash = (url: string) => {
  if (url.endsWith(OPEN_CHAT_HASH)) {
    CHAT_BUTTON_SELECTORS.some((selector) => {
      const button: HTMLElement | null = document.querySelector(selector)
      if (button) {
        button.click()
        return true
      }
      return false
    })
  }
}

const useOpenChatLinks = () => {
  // This hook listens to changes in router fragment/hash, and when we're dealing with #open-chat
  // we'll look for one of the CHAT_BUTTON_SELECTORS in the DOM and click it. The button elements are
  // injected by giosg and vary in each environment. This covers also direct links as well as navigations
  // to paths that carry the fragment.
  const router = useRouter()
  useEffect(() => {
    router.events.on('hashChangeStart', handleOpenChatHash)
    return () => {
      router.events.off('hashChangeStart', handleOpenChatHash)
    }
  }, [router.events])
  // Best effort: Handle inbound direct links from elsewhere, 3 sec delay is just to let giosg boot up.
  useRunOnce(() => setTimeout(() => handleOpenChatHash(router.asPath), 3000))
}
