import type { Pricing } from './product-list'
import type { ClientCartItem, Product } from '@shared/gql/document-nodes'

import { ProductType } from '@shared/gql/document-nodes'

import { getMainCategoryName } from './get-main-category-name'
import { MAX_CARTITEM_COUNT } from './product'

export const mapProductToCartItem = (
  product: Pick<
    Product,
    | 'name'
    | 'id'
    | 'ean'
    | 'priceUnit'
    | 'price'
    | 'approxPrice'
    | 'basicQuantityUnit'
    | 'inStore'
    | 'quantityMultiplier'
    | 'productType'
    | 'isAgeLimitedByAlcohol'
    | 'countryName'
    | 'frozen'
    | 'packagingLabelCodes'
    | 'comparisonPrice'
    | 'comparisonUnit'
    // hierarchyPath is typed somehow wrong in product query vs some other, not the same object and missing irrelevant properties
  > & { hierarchyPath: { name: string }[] | null } & Pricing,
): ClientCartItem => {
  return {
    id: product.id,
    ean: product.ean as string,
    name: product.name as string,
    itemCount: 1,
    priceUnit: product.priceUnit as string,
    price: product.price as number,
    replace: true,
    additionalInfo: '',
    approxPrice: product.approxPrice as boolean,
    basicQuantityUnit: product.basicQuantityUnit as string,
    inStoreSelection: product.inStore || true,
    quantityMultiplier: product.quantityMultiplier || 1,
    mainCategoryName: getMainCategoryName(product),
    productType: ProductType.Product,
    isAgeLimitedByAlcohol: product.isAgeLimitedByAlcohol,
    countryName: {
      fi: product.countryName?.fi ?? null,
      __typename: 'CountryName',
    },
    frozen: product.frozen,
    packagingLabelCodes: product.packagingLabelCodes,
    comparisonPrice: product.comparisonPrice,
    comparisonUnit: product.comparisonUnit,
    campaignPrice: product.pricing?.campaignPrice ?? null,
    lowest30DayPrice: product.pricing?.lowest30DayPrice ?? null,
    campaignPriceValidUntil: product.pricing?.campaignPriceValidUntil ?? null,
    regularPrice: product.pricing?.regularPrice ?? null,
    __typename: 'ClientCartItem',
  }
}

export const setCartItemCount =
  (itemCount: number) =>
  (x: ClientCartItem): ClientCartItem => ({
    ...x,
    itemCount: Math.min(itemCount, MAX_CARTITEM_COUNT),
  })

export const mergeCartItems = (a: ClientCartItem[], b: ClientCartItem[]): ClientCartItem[] =>
  Object.values(
    [...a, ...b].reduce<Record<string, ClientCartItem>>(mergeCartItemIfExistsInList, {}),
  ).filter((item) => item.itemCount > 0)

const mergeCartItemIfExistsInList = (
  itemByIdList: Record<string, ClientCartItem>,
  item: ClientCartItem,
) => ({
  ...itemByIdList,
  [item.ean]: itemByIdList[item.ean] ? mergeCartItem(itemByIdList[item.ean], item) : item,
})

const mergeCartItem = (a: ClientCartItem, b: ClientCartItem): ClientCartItem => ({
  ...a,
  itemCount: Math.min(a.itemCount + b.itemCount, MAX_CARTITEM_COUNT),
})
