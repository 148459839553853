import { useQuery } from '@apollo/client'
import {
  OrderTrackingGetOrderIdDocument,
  OrderTrackingGetOrderStatusByIdDocument,
} from '@shared/gql/document-nodes'
import { useEffect } from 'react'
import { useLinkVerificationToken } from 'services/LinkVerificationToken/use-link-verification-token'

import { dataLayer } from '../tag-manager'

const trackOrderEdit = (data: { orderNumber: number } | null) =>
  dataLayer({
    event: 'pushData',
    customDimensions: data
      ? {
          // do not spread incoming data
          // there may be additional data incoming in the payload and we do not want it to end up in analytics
          isOrderEditActive: true,
          activeOrderNumber: data.orderNumber,
        }
      : {
          isOrderEditActive: false,
          activeOrderNumber: null,
        },
  })

export const useOrderEditTracker = () => {
  const orderIdQuery = useQuery(OrderTrackingGetOrderIdDocument)
  const linkVerificationToken = useLinkVerificationToken()
  const orderStatusQuery = useQuery(OrderTrackingGetOrderStatusByIdDocument, {
    skip: !orderIdQuery.data?.domainOrder.id,
    variables: {
      orderId: orderIdQuery.data?.domainOrder.id || '',
      linkVerificationToken,
    },
  })

  useEffect(() => {
    trackOrderEdit(
      orderStatusQuery.data?.order?.orderNumber
        ? {
            orderNumber: orderStatusQuery.data?.order?.orderNumber,
          }
        : null,
    )
  }, [orderIdQuery.data?.domainOrder.id, orderStatusQuery.data])
}
