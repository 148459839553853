import type { OrderStatus } from '@shared/gql/document-nodes'

import { useQuery } from '@apollo/client'
import { IsOrderEditActiveDocument } from '@shared/gql/document-nodes'

export interface OrderEditState {
  orderId: string
  isOrderEditActive: boolean
  orderStatus: OrderStatus | null
}
export const useGetOrderEditState = (): OrderEditState => {
  const { data } = useQuery(IsOrderEditActiveDocument)
  if (!data || !data.orderEditActive || !data.orderEditActive.orderId) {
    return { orderId: '', isOrderEditActive: false, orderStatus: null }
  }
  return {
    orderId: data.orderEditActive.orderId as string,
    isOrderEditActive: !!data?.orderEditActive?.orderId,
    orderStatus: data.orderEditActive.orderStatus,
  }
}
