import { useQuery } from '@apollo/client'
import { GetSelectedStoreIdDocument } from '@shared/gql/document-nodes'
import { useEffect } from 'react'
import { writeSelectedStoreCookie } from 'utils/cookie'

/** Update the selected store id from Apollo to browser cookie. */
export const useSelectedStoreCookie = (): void => {
  const selectedStoreId = useQuery(GetSelectedStoreIdDocument).data?.selectedStoreId

  useEffect(() => {
    void writeSelectedStoreCookie(selectedStoreId)
  }, [selectedStoreId])
}
