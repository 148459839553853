import { useQuery, useReactiveVar } from '@apollo/client'
import { DeliveryMethod, GetDeliveryDetailsInfoDocument } from '@shared/gql/document-nodes'
import { pendingDeliveryMethodVar } from 'services/Delivery/cache'
import { useSelectedDeliveryAreaDetails } from 'utils/hooks/use-selected-delivery-area-name'

type GetInitialDeliverySelections = {
  called: boolean
  initialDeliveryMethod: DeliveryMethod
  initialSearchTerm: string
  loading: boolean
}

export const useGetInitialDeliverySelections = (): GetInitialDeliverySelections => {
  const { data: deliveryDetailsInfoData } = useQuery(GetDeliveryDetailsInfoDocument)
  const pendingDeliveryMethod = useReactiveVar(pendingDeliveryMethodVar)
  const currentDeliveryMethod = deliveryDetailsInfoData?.deliveryDetailsInfo?.deliveryMethod
  const initialDeliveryMethod = currentDeliveryMethod ?? pendingDeliveryMethod

  const { called, city, loading } = useSelectedDeliveryAreaDetails()

  /**
   * Prefer customer's filled-in postal code for home deliveries,
   * and city of previously-selected delivery area for pickup
   */
  const initialSearchTerm =
    (currentDeliveryMethod === DeliveryMethod.Pickup
      ? city
      : deliveryDetailsInfoData?.deliveryDetailsInfo?.postalCode) ?? ''

  return {
    called,
    initialDeliveryMethod,
    initialSearchTerm,
    loading,
  }
}
