import * as z from 'zod'

import { I18nLang } from '../domain/i18n'
import { CountryCode } from '../gql/document-nodes'

export const Stage = z.union([
  z.literal('production'),
  z.literal('qa'),
  z.literal('test'),
  z.literal('preview'),
  z.literal('dev'),
])

export type Stage = z.TypeOf<typeof Stage>

export const Config = z.object({
  stage: Stage,
  buildId: z.string(),

  api: z.object({
    url: z.string(),
  }),

  apq: z.object({
    enabled: z.boolean(),
    useGet: z.boolean(),
  }),

  auth: z.object({
    addBonusCardUrl: z.string(),
    authorizationUrl: z.string(),
    sIdProfilePageUrl: z.string(),
  }),

  consoleLogger: z.object({
    enabled: z.boolean(),
  }),

  contentful: z.object({
    accessToken: z.string(),
    environment: z.string(),
    host: z.string(),
    space: z.string(),
    toolbox: z.boolean(),
    livePreview: z.boolean(),
  }),

  domain: z.object({
    country: z.nativeEnum(CountryCode),
    defaultLocale: z.nativeEnum(I18nLang),
    defaultStoreId: z.string(),
    isSIdEnabled: z.boolean(),
    localTimezone: z.string(),
  }),

  facebook: z.object({
    facebookDomainVerification: z.string(),
  }),

  featureFlags: z.object({
    collapsibleSidepanel: z.boolean(),
    dynatrace: z.boolean(),
    productAvailabilities: z.boolean(),
    serviceBannerFeatureFlag: z.boolean(),
    shoppingLists: z.boolean(),
    shoppingListsIntroduction: z.boolean(),
    slotReservationInAllStoresForLoggedInUsers: z.boolean(),
    slotReservationWithLogin: z.boolean(),
  }),

  giosg: z.object({
    organizationId: z.string(),
  }),

  google: z.object({
    tagManagerId: z.string().optional(),
  }),

  sentry: z.object({
    enabled: z.boolean(),
    publicDsn: z.string(),
  }),

  sOstoslistaApi: z.object({
    url: z.string(),
  }),
})

export type Config = z.TypeOf<typeof Config>
