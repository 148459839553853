import { makeVar, useReactiveVar } from '@apollo/client'
import { SearchProvider } from '@shared/gql/document-nodes'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useURLSearchParams } from 'utils/hooks/use-url-search-params'

export const LOOP54_OVERRIDE_KEY = 'useLoop54'

/** This is defaulted to Elasticsearch for SSR search on category pages. */
const searchProviderVar = makeVar<SearchProvider>(SearchProvider.Loop54)

/**
 * Call this hook once in the top level of the app, and use the
 * `useSelectSearchProvider` hook to access the current value.
 */
export const useSelectSearchProvider = (): SearchProvider | null => {
  const router = useRouter()
  const { removeParams } = useURLSearchParams({ push: false })

  useEffect(() => {
    const loop54OverridParam =
      typeof router?.query?.useLoop54 === 'string' && router.query.useLoop54

    // missing value like `?useLoop54` is same as `?useLoop54=true`
    const loop54EnabledByUrl = loop54OverridParam === '' || loop54OverridParam === 'true'
    const loop54DisabledByUrl = loop54OverridParam === 'false'

    if (loop54EnabledByUrl) {
      sessionStorage?.removeItem?.(LOOP54_OVERRIDE_KEY)
    } else if (loop54DisabledByUrl) {
      sessionStorage?.setItem?.(LOOP54_OVERRIDE_KEY, 'false')
    }

    // Remove param in any case, if it exists
    if (loop54OverridParam !== false) {
      removeParams([LOOP54_OVERRIDE_KEY, undefined])
    }

    const loop54DisabledByStorage = sessionStorage?.getItem?.(LOOP54_OVERRIDE_KEY) === 'false'

    if (loop54DisabledByStorage) {
      searchProviderVar(SearchProvider.Elasticsearch)
    } else {
      searchProviderVar(SearchProvider.Loop54)
    }
  }, [removeParams, router.query.useLoop54])

  return useReactiveVar(searchProviderVar)
}

/**
 * The `useSelectSearchProvider` hook contains all logic for selecting
 * either Elasticsearch or Loop54 based on the current A/B test group.
 *
 * This hook returns the current value.
 */
export const useCurrentSearchProvider = (): SearchProvider => {
  return useReactiveVar(searchProviderVar)
}
