import type {
  CartItem,
  ClientCartItem,
  Product,
  UserOrderCartItem,
} from '@shared/gql/document-nodes'

import { pipe } from 'fp-ts/function'
import { find, map, prop, sortBy } from 'ramda'

import {
  mapCartItemsAsCartItemInput,
  mapUserOrderCartItemsAsCartItemInput,
} from './backend-to-frontend-order-mapper'
import { mapProductToClientCartItem } from './product-to-client-cart-item-mapper'

export const mapUserOrderCartItemsToClientCartItems = (
  cartItems: UserOrderCartItem[],
  productListCartItems: Product[],
): ClientCartItem[] => {
  return pipe(
    cartItems,
    mapUserOrderCartItemsAsCartItemInput,
    map(
      (cartItem: ClientCartItem): ClientCartItem =>
        mapProductToClientCartItem(
          cartItem,
          find((p: Product) => p.id === cartItem.id)(productListCartItems),
        ),
    ),
    sortBy<ClientCartItem>(prop('inStoreSelection')),
  )
}

export const mapCartItemsToClientCartItems = (
  cartItems: CartItem[],
  productListCartItems: Product[],
): ClientCartItem[] => {
  return pipe(
    cartItems,
    mapCartItemsAsCartItemInput,
    map(
      (cartItem: ClientCartItem): ClientCartItem =>
        mapProductToClientCartItem(
          cartItem,
          find((p: Product) => p.id === cartItem.id)(productListCartItems),
        ),
    ),
    sortBy<ClientCartItem>(prop('inStoreSelection')),
  )
}
