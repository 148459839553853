import { Stage } from '../config/schema'

const isStage = (stage?: string): stage is Stage => Stage.safeParse(stage).success

export type Environment = `skaupat-${Stage}`

export const parseEnvironment = (environment?: string): [undefined, Stage] => {
  const [, stage] = environment?.split('-') ?? ''
  if (isStage(stage)) {
    return [undefined, stage]
  }

  const options = Stage.options.map((option) => `"${option.value}"`)

  throw new Error(
    `process.env.ENVIRONMENT has incorrect value "${environment}". The syntax is \`skaupat-\${stage}\` where stage is one of ${options}`,
  )
}
