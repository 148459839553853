import type { Stage } from '@shared/config/schema'
import type { FC } from 'react'

import { useHasServiceConsent } from '@s-group/react-usercentrics'
import { getConfig } from '@shared/config'
import { ServiceId } from 'config/usercentrics'
import Script from 'next/script'

const { featureFlags, stage } = getConfig()

// Using dynatrace RUM javascript tag url
const SRC: Record<Stage, string> = {
  dev: '',
  preview: '',
  test: 'https://monitoring-sapm.s-cloud.fi/jstag/managed/664dc9cb-f164-4757-93f9-99eae03ac317/12ea215a55cea75_complete.js',
  qa: 'https://monitoring-sapm.s-cloud.fi/jstag/managed/664dc9cb-f164-4757-93f9-99eae03ac317/cbcb268a077ad920_complete.js',
  production:
    'https://monitoring-sapm.s-cloud.fi/jstag/managed/9c1872aa-7ca4-4f3e-95d0-af1a2c57de9c/839bc85a9cc0800e_complete.js',
}

// Dynatrace cookies consent based on usercentrics CMP
export const DynatraceScript: FC = () => {
  const hasConsent = useHasServiceConsent(ServiceId.Dynatrace)
  const src = SRC[stage]
  if (hasConsent && featureFlags.dynatrace && src) {
    return <Script src={src} crossOrigin="anonymous" />
  }
  return null
}
