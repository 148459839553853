import type { IncidentValidationError } from 'domain/error-messages/error-parse'
import type { FC } from 'react'
import type { ValidationError } from 'validation/validators'

import { useReactiveVar } from '@apollo/client'
import { Button, ButtonSize, ButtonType, Col, Modal, Row } from '@sok/design-system'
import {
  isError,
  isModalError,
  isUnkownError,
  shouldDisplayWithIncidentCode,
} from 'domain/error-messages/error-messages'
import { isIncidentValidationError } from 'domain/error-messages/error-parse'
import { errorModalVar } from 'lib/apollo/reactive-vars'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ContentError } from './ContentError'

export const ErrorModalContainer: FC = () => {
  const error = useReactiveVar(errorModalVar)
  const handleClose = () => errorModalVar(null)

  return error ? <ErrorModal error={error} onClose={handleClose} /> : null
}

export const ErrorModal: FC<{
  error: IncidentValidationError | ValidationError
  onClose: () => void
}> = ({ error, onClose }) => {
  const { t } = useTranslation()

  const shouldDisplayIncidentErrorCode =
    isIncidentValidationError(error) &&
    (shouldDisplayWithIncidentCode(error.message) || isUnkownError(error.message))

  /**
   * @todo Open Modals using service from `services/Modal`
   * @see https://jira.sok.fi/browse/VOIK-9959
   */
  return (
    <Modal
      closeLabel={t('Close')}
      data-test-id="error-modal"
      visible
      footer={
        <Row justify="center">
          <Col>
            <Button
              data-test-id="error-modal-close"
              onClick={onClose}
              size={ButtonSize.LARGE}
              type={ButtonType.PRIMARY}
              text={t('Close')}
            />
          </Col>
        </Row>
      }
      onCancel={onClose}
    >
      <StyledErrorModalContent>
        <ErrorMessage error={error} />
        {shouldDisplayIncidentErrorCode ? <DisplayIncidentCode error={error} /> : null}
      </StyledErrorModalContent>
    </Modal>
  )
}

const ErrorMessage: FC<{ error: IncidentValidationError | ValidationError }> = ({ error }) => {
  const { t } = useTranslation()

  if (isModalError(error.message)) {
    return (
      <ContentError
        title={t(`errorMessages_${error.message}_title`)}
        description={t(`errorMessages_${error.message}_description`)}
      />
    )
  }

  if (isError(error.message)) {
    return <ContentError title={t(`errorMessages_${error.message}`)} />
  }

  return <ContentError title={t('errorMessages_UnknownError')} />
}

const DisplayIncidentCode: FC<{ error: IncidentValidationError }> = ({ error }) => {
  const { t } = useTranslation()
  return (
    <Row data-test-id="incident-code">
      <Col>
        <div>
          <Row>
            <Col>
              <p>{t('ErrorIncidentCodeDescription')}</p>
            </Col>
          </Row>
          <StyledErrorIncedientContent>
            <h4 data-test-id="error-incident-code-title">{t('ErrorIncidentCode')}</h4>
            <h2 data-test-id="error-incident-code">{error.data.xCorrelationIncidentCode}</h2>
          </StyledErrorIncedientContent>
        </div>
      </Col>
    </Row>
  )
}

const StyledErrorIncedientContent = styled.div(({ theme }) => ({
  marginTop: theme.spacings.medium,
}))

const StyledErrorModalContent = styled.div`
  max-width: 600px;
  margin: auto;
  padding: 2em;
  text-align: center;
  white-space: pre-line;
`
