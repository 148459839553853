import type { FC } from 'react'

import { useReactiveVar } from '@apollo/client'
import { showGlobalLoadingIndicatorVar } from 'lib/apollo/reactive-vars'
import { useRouter } from 'next/router'
import { transparentize } from 'polished'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css, keyframes } from 'styled-components'
import { Z_INDEX } from 'styles/layout'

const loadingAnimation = keyframes({
  '0%': { backgroundPositionX: '-100vw' },
  '50%': { backgroundPositionX: 0 },
  '100%': { backgroundPositionX: '100vw' },
})

interface Props {
  className?: string
}

const _GlobalLoadingIndicator: FC<Props> = ({ className }) => {
  const isLoading = useRef(false)
  const [showLoading, setShowLoading] = useState(false)
  const router = useRouter()
  const { t } = useTranslation()
  const interval = useRef<number>()
  const showGlobalLoadingIndicator = useReactiveVar(showGlobalLoadingIndicatorVar)

  // Register and unregister Next.js route changes
  useEffect(() => {
    // Start showing the loading indicator
    const showLoadingIndicator = () => {
      isLoading.current = true
      setShowLoading(true)

      // Poll every second whether the loading indicator
      // should still be visible. This prevents the
      // indicator from disappearing mid-animation.
      interval.current = window.setInterval(() => {
        if (!isLoading.current) {
          setShowLoading(false)
          window.clearInterval(interval.current)
        }
      }, 1000)
    }

    // Tell the poller above to no longer display indicator
    const hideLoadingIndicator = () => {
      isLoading.current = false
    }

    router.events.on('routeChangeStart', showLoadingIndicator)
    router.events.on('routeChangeComplete', hideLoadingIndicator)
    router.events.on('routeChangeError', hideLoadingIndicator)

    return () => {
      isLoading.current = false

      router.events.off('routeChangeStart', showLoadingIndicator)
      router.events.off('routeChangeComplete', hideLoadingIndicator)
      router.events.off('routeChangeError', hideLoadingIndicator)
    }
  }, [router.events])

  return showLoading || showGlobalLoadingIndicator ? (
    <div
      aria-label={t('Page is loading...')}
      aria-live="polite"
      className={className}
      data-test-id="global-loading-indicator"
    />
  ) : null
}

_GlobalLoadingIndicator.displayName = 'GlobalLoadingIndicator'

export const GlobalLoadingIndicator = styled(_GlobalLoadingIndicator)(
  {
    backgroundPositionX: '-100vw ',
    backgroundSize: '100vw',
    height: 4,
    left: 0,
    position: 'fixed',
    top: 1,
    width: '100%',
    zIndex: Z_INDEX.globalLoadingIndicator,
  },
  ({ theme }) => {
    const { primary } = theme.colors
    const transparent = transparentize(1, primary)

    return {
      background: `linear-gradient(to right, ${transparent}, ${primary} 50%, ${transparent} 100%) no-repeat`,
    }
  },
  // Keyframes do not work inside object styles :/
  css`
    animation: ${loadingAnimation} 1.5s ease-in infinite;
  `,
)
