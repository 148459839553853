import type { DateTime } from 'luxon'

import {
  useHasServiceConsent,
  useHasUserInteracted,
  useIsFailed,
} from '@s-group/react-usercentrics'
import { ServiceId } from 'config/usercentrics'
import { getDateTimeNow } from 'domain/date-time'
import { useContext, useEffect } from 'react'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'
import { IS_BROWSER } from 'utils/is-browser'

import { persistUserExperiment } from './cache'
import { ExperimentContext } from './context'

const getVariant = (variantCount: number) => Math.floor(variantCount * Math.random())

export const useHasExperimentConsent = () => {
  const hasUserInteractedWithConsent = useHasUserInteracted()
  // not actually using google optimize. checks that we have personalisation consent
  const hasServiceConsent = useHasServiceConsent(ServiceId.GoogleOptimize)

  return hasUserInteractedWithConsent ? hasServiceConsent : null
}

const allExperimentsDisabled =
  IS_BROWSER && 'DISABLE_EXPERIMENTS' in window && window.DISABLE_EXPERIMENTS

/**
  Get the active variant for given A/B test experiment.
  - `null` during server side render and before consent status is known
  - Default variant (first variant) when user gave no consent or experiment has expired
  - Otherwise returns variant stored in cookie (or picks random variant and store it in cookie if no cookie is set)
*/
export const useExperimentVariant = <T>(
  experiment: string,
  variants: T[],
  expirationDate: DateTime,
): T | null => {
  const isClientSide = useIsClientSide()
  const hasServiceConsent = useHasExperimentConsent()
  const isUsercentricsInitBlocked = useIsFailed()

  const experimentContext = useContext(ExperimentContext)

  const variant = experimentContext.experiments?.[experiment]?.variant
  const isExperimentDisabled =
    allExperimentsDisabled ||
    getDateTimeNow() > expirationDate ||
    hasServiceConsent === false ||
    isUsercentricsInitBlocked

  useEffect(() => {
    if (
      !isExperimentDisabled &&
      variant === undefined &&
      hasServiceConsent &&
      experimentContext.experiments
    ) {
      const newVariant = getVariant(variants.length)
      experimentContext.setExperiments((state) => ({
        ...state,
        [experiment]: { variant: newVariant },
      }))
      persistUserExperiment(experiment, newVariant, expirationDate)
    }
  }, [
    experiment,
    experimentContext,
    expirationDate,
    hasServiceConsent,
    isExperimentDisabled,
    variant,
    variants.length,
  ])

  if (!isClientSide) {
    return null
  }

  if (isExperimentDisabled) {
    return variants[0]
  }

  if (variant === undefined || hasServiceConsent === null) {
    return null
  }

  return variants[variant] ?? variants[0]
}
