import type { ValidationError } from 'validation/validators'

import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as t from 'io-ts'

import { unknownError } from './unknown-error'

// error with an incident code payload, occurs in backend
// provides a short xCorrelationIncidentCode that user can provide to customer service
// matches errors which do not necessarily have defined error type in payload,
// or error type is not one of TypedErrorMessage
const IncidentErrorPayload = t.type({
  code: t.string,
  xCorrelationId: t.string,
  xCorrelationIncidentCode: t.string,
  errorType: t.string,
})
type IncidentErrorPayload = t.TypeOf<typeof IncidentErrorPayload>

const parseError = (x: unknown | null): O.Option<unknown> =>
  pipe(
    x,
    O.fromNullable,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    O.map((error) => JSON.parse(JSON.stringify(error))),
  )

const NetworkErrorType = t.type({
  networkError: t.type({
    result: t.type({
      errors: t.array(
        t.type({
          extensions: IncidentErrorPayload,
        }),
      ),
    }),
  }),
})

const maybeNetworkError = (x: unknown): O.Option<IncidentErrorPayload> =>
  pipe(
    x,
    NetworkErrorType.decode,
    O.fromEither,
    O.chain((e) => O.fromNullable(e.networkError.result.errors[0]?.extensions)),
  )

const GraphQLErrorType = t.type({
  graphQLErrors: t.array(
    t.type({
      extensions: IncidentErrorPayload,
    }),
  ),
})

const maybeGraphqlError = (x: unknown): O.Option<IncidentErrorPayload> =>
  pipe(
    x,
    GraphQLErrorType.decode,
    O.fromEither,
    O.chain(O.fromPredicate((e) => e.graphQLErrors.length > 0)),
    O.map((e) => e.graphQLErrors[0]?.extensions),
    O.chain(O.fromNullable),
  )

const maybeIncidentCode = (x: unknown | null): O.Option<IncidentErrorPayload> =>
  pipe(
    x,
    parseError,
    O.chain((e) => {
      const isNetworkError = maybeNetworkError(e)
      if (O.isSome(isNetworkError)) {
        return isNetworkError
      }

      const isGqlError = maybeGraphqlError(e)
      if (O.isSome(isGqlError)) {
        return isGqlError
      }

      return O.none
    }),
  )

export interface IncidentValidationError extends ValidationError {
  tag: 'IncidentError'
  data: IncidentErrorPayload
}
export const isIncidentValidationError = (
  x: IncidentValidationError | ValidationError,
): x is IncidentValidationError => x.tag === 'IncidentError'

const mapToValidation = (e: IncidentErrorPayload): IncidentValidationError => ({
  tag: 'IncidentError',
  message: e.errorType,
  data: e,
  path: [],
})

const maybeIncidentError = (x: unknown): O.Option<IncidentValidationError> =>
  pipe(x, maybeIncidentCode, O.map(mapToValidation))

export const parseErrors = (errors: unknown[]): ValidationError | IncidentValidationError =>
  errors
    .map(maybeIncidentError)
    .filter(O.isSome)
    .map((x) => x.value)
    .shift() || unknownError()
