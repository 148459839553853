import type { FC } from 'react'

import { DeliveryMethod } from '@shared/gql/document-nodes'
import { Tab, TabPane } from '@sok/design-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  position: sticky;
  top: 0;
  z-index: 2;

  .ant-tabs-nav {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    margin: 0 !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    justify-content: center;
    width: 100%;
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
`

const StyledTabText = styled.span(({ theme }) => theme.variants.body2.medium)

interface DeliveryMethodRadioProps {
  onSelectDeliveryMethod: (deliveryMethod: DeliveryMethod) => void
  selectedDeliveryMethod: DeliveryMethod
}

export const DeliveryMethodRadio: FC<DeliveryMethodRadioProps> = ({
  onSelectDeliveryMethod,
  selectedDeliveryMethod,
}) => {
  const { t } = useTranslation()
  return (
    <StyledContainer>
      <Tab
        activeKey={selectedDeliveryMethod}
        disableLine
        moreIcon={null} // the default more icon causes the component to jiggle in Safari
        onChange={(activeKey) => onSelectDeliveryMethod(activeKey as DeliveryMethod)}
        regularTabs
        size="large"
      >
        <TabPane
          tab={<StyledTabText>{t('Pickup points')}</StyledTabText>}
          key={DeliveryMethod.Pickup}
        />
        <TabPane
          tab={<StyledTabText>{t('Home delivery')}</StyledTabText>}
          key={DeliveryMethod.HomeDelivery}
        />
      </Tab>
    </StyledContainer>
  )
}
