import { useEffect } from 'react'
import { useGetCurrentProfile } from 'utils/hooks/account/use-get-current-profile'

import { trackLoginStatus } from '../login'

export const useLoginTracker = () => {
  const userProfile = useGetCurrentProfile()
  // Signal tracking data layer when user is logged in
  useEffect((): void => {
    if (userProfile) {
      trackLoginStatus(userProfile)
    } else {
      trackLoginStatus(null)
    }
  }, [userProfile])
}
