import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { useStoreId } from 'utils/hooks/store/use-store-id'

/**
 * Callback to evict the current `Store.products` from Apollo cache,
 * forcing a network request.
 */
export const useEvictSearchCache = (): (() => void) => {
  const apolloClient = useApolloClient()
  const { storeId } = useStoreId()

  return useCallback(() => {
    const store = apolloClient.cache.identify({ __typename: 'Store', id: storeId })
    if (store) {
      apolloClient.cache.modify({
        id: store,
        fields: {
          products: (_, details) => details.DELETE,
        },
      })
    }
  }, [apolloClient.cache, storeId])
}
