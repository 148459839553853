import { trackCustomEvent } from 'utils/tracking/custom-events/custom-events'
import { EventAction, EventCategory } from 'utils/tracking/interfaces/data-layer-events'
import { dataLayer } from 'utils/tracking/tag-manager'

export const trackOpenTimeSlotSelector = (): void => {
  trackCustomEvent({
    category: EventCategory.NAVIGATION_ELEMENTS,
    action: EventAction.CLICK,
    label: `main-header_time-slot-selector`,
  })
}

export const trackSelectedSlotIsClosed = (): void => {
  trackCustomEvent({
    category: EventCategory.ECOMMERCE,
    action: EventAction.SHOW_ERROR_MESSAGE,
    label: 'selected-timeslot-has-closed',
  })
}

export const trackDeliverySlotReserved = (reservationId: string): void => {
  dataLayer({
    event: 'pushData',
    customDimensions: {
      reservationId,
    },
  })

  trackCustomEvent({
    category: EventCategory.DELIVERY_SELECTIONS,
    action: EventAction.DELIVERY_SLOT_RESERVATION,
    label: 'slot-reserved',
  })
}

export const trackDeliverySlotRefreshed = (isAutomatic: boolean): void => {
  trackCustomEvent({
    category: EventCategory.DELIVERY_SELECTIONS,
    action: EventAction.DELIVERY_SLOT_EXTENSION,
    label: isAutomatic ? 'automatic' : 'manual',
  })
}

export enum DeliverySlotReleaseReason {
  MANUAL = 'manual' /** user manually cancelled reservation when prompted (due to empty cart) */,
  STORE_CHANGE = 'store-change' /** user selected new delivery area, and previous time was cancelled */,
  TIME_CHANGE = 'time-change' /** user selected new time, and previous was cancelled */,
  LOGIN_EXPIRED = 'login-expired' /** user had reservation while their auth expired */,
  ORDER_EDIT_STARTED = 'order-edit-started' /** user had slot reservation when starting to edit an order */,
  ORDER_EDIT_CANCELLED = 'order-edit-cancelled' /** user selected new slot while editing, but cancelled edit */,
  ORDER_COPIED_AS_TEMPLATE = 'order-copied-as-template' /** user had slot reservation when copying order as new order */,
}

export const trackDeliverySlotReleased = (reason: DeliverySlotReleaseReason): void => {
  trackCustomEvent({
    category: EventCategory.DELIVERY_SELECTIONS,
    action: EventAction.DELIVERY_SLOT_RELEASE,
    label: reason,
  })
}

export const trackDeliverySlotWarningShown = (isAlreadyExpired: boolean): void => {
  trackCustomEvent({
    category: EventCategory.DELIVERY_SELECTIONS,
    action: EventAction.DELIVERY_SLOT_EXPIRATION_MESSAGE,
    label: isAlreadyExpired ? 'already-expired' : 'about-to-expire',
  })
}
