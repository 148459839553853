import type { DeliveryOption } from 'domain/delivery-option'
import type { FC, FormEventHandler } from 'react'

import { Button, Loader } from '@s-group/design-system-components'
import { SDS_BRAND_COLOR_ELEMENT_INVERSE_GREY } from '@s-group/design-system-tokens/web/tokens/s-kaupat/colors.es6'
import { type DeliveryMethod, HomeDeliveryType } from '@shared/gql/document-nodes'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { DeliveryOptionsList } from './DeliveryOptionsList'

const StyledDeliveryOptionsList = styled.div(({ theme }) => ({
  flexGrow: 1,
  marginBottom: theme.spacings.small,
}))

const StyledButtonContainer = styled.div<{ isVisible: boolean }>(({ isVisible, theme }) => ({
  backgroundColor: theme.colors.white,
  opacity: isVisible ? 1 : 0,
  paddingBottom: theme.spacings.small,
  transition: 'opacity 100ms ease-in-out',
  visibility: isVisible ? 'visible' : 'hidden',
  [Button]: {
    width: '100%',
  },
}))

const StyledForm = styled.form({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,

  [StyledButtonContainer]: {
    bottom: 0,
    marginTop: 'auto',
    position: 'sticky',
  },
})

interface DeliveryOptionsFormProps {
  activeDeliveryMethod: DeliveryMethod
  deliveryOptions: DeliveryOption[]
  initiallySelectedDeliveryOption: DeliveryOption | null
  isDeliveryOptionsLoading: boolean
  inputsDisabled: boolean
  isConfirmingSelection: boolean
  onSubmit: (selectedDeliveryOption: DeliveryOption) => void
}

export const DeliveryOptionsForm: FC<DeliveryOptionsFormProps> = ({
  activeDeliveryMethod,
  deliveryOptions,
  initiallySelectedDeliveryOption,
  isDeliveryOptionsLoading,
  inputsDisabled,
  isConfirmingSelection,
  onSubmit,
}) => {
  const { t } = useTranslation()

  const [selectedOption, setSelectedOption] = useState(initiallySelectedDeliveryOption)

  /**
   * Select initially selected option (from storage) if local state hasn't been touched yet.
   * Also, when adjusting search, remove selected pending selection if it's no longer in the
   * visible list by returning to initial selection.
   */
  useEffect(() => {
    if (!selectedOption) {
      if (initiallySelectedDeliveryOption) setSelectedOption(initiallySelectedDeliveryOption)
      return
    }

    const isSelectedVisible = deliveryOptions.some((o) => o.areaId === selectedOption.areaId)
    if (!isSelectedVisible) {
      setSelectedOption(initiallySelectedDeliveryOption)
    }
  }, [deliveryOptions, initiallySelectedDeliveryOption, selectedOption])

  const handleSelectDeliveryOption = useCallback(
    (selectedDeliveryOption: DeliveryOption) => {
      if (!inputsDisabled) setSelectedOption(selectedDeliveryOption)
    },
    [inputsDisabled],
  )

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault()
      if (selectedOption) {
        onSubmit(selectedOption)
      }
    },
    [onSubmit, selectedOption],
  )

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledDeliveryOptionsList
        data-test-id={`delivery-options-list${isDeliveryOptionsLoading ? '-loading' : ''}`}
        role="listbox"
      >
        <DeliveryOptionsList
          deliveryOptions={deliveryOptions}
          initiallySelectedDeliveryOption={
            activeDeliveryMethod === initiallySelectedDeliveryOption?.deliveryMethod
              ? initiallySelectedDeliveryOption
              : null
          }
          isDeliveryOptionsLoading={isDeliveryOptionsLoading}
          onSelectDeliveryOption={handleSelectDeliveryOption}
          selectedDeliveryOption={
            deliveryOptions.find(
              (deliveryOption) => deliveryOption.areaId === selectedOption?.areaId,
            ) ?? null
          }
        />
      </StyledDeliveryOptionsList>
      <StyledButtonContainer
        isVisible={!!selectedOption && selectedOption.homeDeliveryType !== HomeDeliveryType.Robot}
      >
        <Button
          disabled={inputsDisabled}
          data-test-id="select-delivery-option-button"
          type="submit"
          primary
          rounding="small"
        >
          {t('Choose')}
          {isConfirmingSelection && (
            <Loader
              aria-label="Loading"
              color={SDS_BRAND_COLOR_ELEMENT_INVERSE_GREY}
              size="small"
              type="circle"
              style={{ marginLeft: '4px' }}
            />
          )}
        </Button>
      </StyledButtonContainer>
    </StyledForm>
  )
}
