import { useEffect, useRef } from 'react'

export const useRunOnce = (func: () => void): void => {
  const executed = useRef(false)
  useEffect(() => {
    if (!executed.current) {
      executed.current = true
      func()
    }
  }, [executed, func])
}
