import type { ProductList } from '@shared/gql/document-nodes'

import { useApolloClient, useQuery } from '@apollo/client'
import { GetAllClientCartItemsDocument } from '@shared/gql/document-nodes'
import { useEffect, useState } from 'react'
import { shoppingCartMutations } from 'services/ClientCart/mutations'
import { useAlcoholAllowedForDeliveryArea } from 'utils/hooks/delivery/use-alcohol-allowed'
import { useStoreId } from 'utils/hooks/store/use-store-id'
import { usePrevious } from 'utils/hooks/use-previous'
import { useSkippableProductInfoQuery } from 'utils/hooks/use-skippable-product-info-query'

export const useUpdateCartItemsOnStoreChange = (): void => {
  const [storeChange, setStoreChange] = useState(false)

  const { data: cartItemsData } = useQuery(GetAllClientCartItemsDocument)
  const clientCartItems = cartItemsData?.cartItems || []
  const { selectedStoreId } = useStoreId()

  const prevStore = usePrevious(selectedStoreId)

  const alcoholSellingAllowed = useAlcoholAllowedForDeliveryArea()

  useEffect(() => {
    if (prevStore !== selectedStoreId) {
      setStoreChange(true)
    }
  }, [setStoreChange, prevStore, selectedStoreId])

  const { data: productData, loading: eanLoading } = useSkippableProductInfoQuery({
    eans: clientCartItems?.map((ci) => ci?.ean),
    includeAgeLimitedByAlcohol: !!alcoholSellingAllowed,
    includeAvailabilities: false /** cart will query these separately */,
    selectedStoreId,
  })

  const storeProductsLoaded = storeChange && !eanLoading
  const currentClient = useApolloClient()

  useEffect(() => {
    const effect = async (): Promise<void> => {
      if (storeChange && storeProductsLoaded && productData) {
        await shoppingCartMutations.updateClientCartItemsOnStoreChange({
          productList: productData.store?.products as ProductList,
          apolloClient: currentClient,
        })
      }
    }

    effect()
  }, [productData, storeProductsLoaded, storeChange, currentClient])
}
