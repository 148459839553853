// eslint-disable-next-line no-restricted-imports
import type { DataLayerArgs } from 'react-gtm-module'

import { getServicesBaseInfo, hasServiceConsent } from '@s-group/react-usercentrics'
import { ServiceId } from 'config/usercentrics'
// eslint-disable-next-line no-restricted-imports
import TagManager from 'react-gtm-module'

let tempDataLayer: DataLayerArgs[] = []

let hasGtmConsent = false

/** export for tests */
export const _getHasGTMConsent = () => {
  const services = getServicesBaseInfo()
  const gtmService = services.find((service) => service.id === ServiceId.GoogleTagManager) || null
  hasGtmConsent = hasServiceConsent(gtmService)
  return hasGtmConsent
}

/** Push to GTM dataLayer if user has given consent */
export const dataLayer = (args: DataLayerArgs['dataLayer']): void => {
  if (hasGtmConsent || _getHasGTMConsent()) {
    TagManager.dataLayer({ dataLayer: args })
  } else {
    tempDataLayer.push({ dataLayer: args })
  }
}

/** Initialize GTM if user has given consent */
export const initializeTagManager = (tagManagerId: string): void => {
  if (hasGtmConsent || _getHasGTMConsent()) {
    TagManager.initialize({ gtmId: tagManagerId })
    /** Flush temp items to dataLayer  */
    tempDataLayer.forEach((dataLayerArgs) => {
      TagManager.dataLayer(dataLayerArgs)
    })
    /** Reset temp items */
    tempDataLayer = []
  }
}
