import { useQuery } from '@apollo/client'
import { GetLocalAuthenticationTokensDocument } from '@shared/gql/document-nodes'

interface LocallyCachedAuthorizationTokens {
  accessToken?: string | null
  refreshToken?: string | null
  idToken?: string | null
}

export const useGetCachedAuthorizationTokens = (): LocallyCachedAuthorizationTokens | undefined => {
  const { data, loading } = useQuery(GetLocalAuthenticationTokensDocument)
  if (!data || loading) {
    return undefined
  }
  const { accessToken, refreshToken, idToken } = data.authenticationTokens || {}
  return { accessToken, refreshToken, idToken }
}
