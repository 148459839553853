import type { GetDeliveryAreaQuery } from '@shared/gql/document-nodes'

export const sortResponseDeliverySlotsAndTimes = (
  data: GetDeliveryAreaQuery,
): GetDeliveryAreaQuery => {
  if (data?.deliveryArea?.deliverySlots) {
    return {
      ...data,
      deliveryArea: {
        ...data.deliveryArea,
        deliverySlots: sortDeliverySlots(data.deliveryArea.deliverySlots).map((x) => ({
          ...x,
          deliveryTimes: sortDeliveryTimes(x.deliveryTimes),
        })),
      },
    }
  }

  return data
}

const sortDeliverySlots = <
  T extends {
    date: string
  },
>(
  xs: T[],
): T[] =>
  [...xs].sort((a, b) => {
    switch (true) {
      case a.date > b.date:
        return 1
      case a.date < b.date:
        return -1
      default:
        return 0
    }
  })

const sortDeliveryTimes = <
  T extends {
    startDateTime: string
  },
>(
  xs: T[],
): T[] =>
  [...xs].sort((a, b) => {
    switch (true) {
      case a.startDateTime > b.startDateTime:
        return 1
      case a.startDateTime < b.startDateTime:
        return -1
      default:
        return 0
    }
  })
