import { IS_BROWSER } from '@s-group/react-usercentrics'

export const shouldSkipDeliveryLogin = () =>
  IS_BROWSER && localStorage.getItem('login-skip') === 'true'

export const setShouldSkipDeliveryLogin = () =>
  IS_BROWSER ? localStorage.setItem('login-skip', 'true') : null

export const removeSkipDeliveryLoginToken = () =>
  IS_BROWSER && localStorage.removeItem('login-skip')
