import { OrderStatus, ProductType } from '@shared/gql/document-nodes'
import * as z from 'zod'

/** When bumping this the next time, change it to a simple number, e.g 1. The only purpose of the version is to increment it. */
export const CART_SCHEMA_VERSION = '1.2.17'

export const CartSchema = z.object({
  cacheVersion: z.literal(CART_SCHEMA_VERSION),
  cart: z.object({
    cartItems: z.array(
      z.object({
        __typename: z.literal('ClientCartItem'),
        id: z.string(),
        ean: z.string(),
        name: z.string(),
        itemCount: z.number(),
        approxPrice: z.boolean(),
        basicQuantityUnit: z.string(),
        inStoreSelection: z.boolean(),
        mainCategoryName: z.string(),
        price: z.number(),
        priceUnit: z.string(),
        quantityMultiplier: z.number(),
        replace: z.boolean(),
        countryName: z.object({
          fi: z.string().nullable(),
          __typename: z.literal('CountryName'),
        }),
        productType: z.nativeEnum(ProductType),

        campaignPrice: z.number().nullable().optional(),
        lowest30DayPrice: z.number().nullable().optional(),
        campaignPriceValidUntil: z.string().nullable().optional(),
        regularPrice: z.number().nullable().optional(),
        additionalInfo: z.string().nullable(),
        isAgeLimitedByAlcohol: z.boolean().nullable(),
        frozen: z.boolean().nullable(),
        packagingLabelCodes: z.string().array().nullable(),
        comparisonPrice: z.number().nullable(),
        comparisonUnit: z.string().nullable(),
      }),
    ),
  }),
  orderEditActive: z
    .object({
      __typename: z.literal('OrderEditActiveResponse'),
      orderId: z.string().nullable(),
      orderStatus: z.nativeEnum(OrderStatus).nullable(),
    })
    .nullable(),
})
