import { useQuery } from '@apollo/client'
import { RemoteUserFavoritesListDocument } from '@shared/gql/document-nodes'
import { remoteData } from 'domain/remote-data'
import { pipe } from 'fp-ts/function'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'

export const useGetFavorites = () => {
  const isAuthenticated = useIsLoggedIn()
  const query = useQuery(RemoteUserFavoritesListDocument, {
    skip: !isAuthenticated,
  })

  return pipe(
    query,
    remoteData.from,
    remoteData.map((x) => x?.userFavorites.items || []),
    remoteData.nonNullable,
  )
}
