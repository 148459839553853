import { useQuery } from '@apollo/client'
import { GetSelectedStoreIdDocument, RemoteStoreNameDocument } from '@shared/gql/document-nodes'

interface SelectedStoreName {
  storeName?: string
}

export const useSelectedStoreName = (): SelectedStoreName => {
  const { data: selectedStore } = useQuery(GetSelectedStoreIdDocument)
  const { data: storeData } = useQuery(RemoteStoreNameDocument, {
    variables: {
      id: selectedStore?.selectedStoreId || '',
    },
    skip: !selectedStore?.selectedStoreId,
  })

  return {
    storeName: storeData?.store?.name ?? undefined,
  }
}
