import type { Maybe } from '@shared/gql/document-nodes'

import { CustomerType, PaymentMethod } from '@shared/gql/document-nodes'

const isOndeliveryOrCardPayment = (
  x: PaymentMethod,
): x is PaymentMethod.OnDelivery | PaymentMethod.CardPayment =>
  x === PaymentMethod.CardPayment || x === PaymentMethod.OnDelivery

export const selectDefaultPaymentMethod = ({
  availablePaymentMethods,
  customerType,
}: {
  availablePaymentMethods: Maybe<PaymentMethod[]> | undefined
  customerType: CustomerType | null
}): PaymentMethod.CardPayment | PaymentMethod.OnDelivery | null => {
  if (!availablePaymentMethods || customerType === CustomerType.B2b) {
    return null
  }
  const paymentMethodsForPrivateCustomer = availablePaymentMethods.filter(isOndeliveryOrCardPayment)
  return paymentMethodsForPrivateCustomer.length === 1 ? paymentMethodsForPrivateCustomer[0] : null
}
