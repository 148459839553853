import type { FC } from 'react'

import { DeliveryMethod } from '@shared/gql/document-nodes'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import {
  StyledDeliveryError,
  StyledImage,
  StyledInfoLabel,
  StyledInfoLabelBold,
} from './styled-no-delivery'

interface Props {
  deliveryMethod: DeliveryMethod
}

export const DeliveryOptionsListPlaceholder: FC<Props> = ({ deliveryMethod }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const isHomeDelivery = deliveryMethod === DeliveryMethod.HomeDelivery

  const logo = isHomeDelivery ? theme.logos.delivery : theme.logos.pickup

  return (
    <StyledDeliveryError data-test-id="delivery-options-list-placeholder">
      <StyledImage src={logo} role="presentation" />

      <StyledInfoLabelBold>
        {t(isHomeDelivery ? 'Search by postal code' : 'Search with city or store name')}
      </StyledInfoLabelBold>
      <StyledInfoLabel>
        {t(isHomeDelivery ? 'Search by postal code info' : 'Search with city or store name info')}
      </StyledInfoLabel>
    </StyledDeliveryError>
  )
}
