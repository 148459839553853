import { useMutation, useReactiveVar } from '@apollo/client'
import { useIsOpen } from '@s-group/react-usercentrics'
import { CancelOrderModificationDocument } from '@shared/gql/document-nodes'
import { Button, ButtonType, ConfirmModal, minWidthFromTheme } from '@sok/design-system'
import { refreshTokenExpired } from 'lib/apollo/reactive-vars'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useExpireSession } from 'services/Account/mutation/use-expire-session'
import {
  useCancelDeliverySlotReservation,
  useDeliverySlotReservationId,
} from 'services/DeliverySlot/hooks'
import { DeliverySlotReleaseReason } from 'services/DeliverySlot/tracking'
import styled from 'styled-components'
import { useGetProfileActionHandlers } from 'utils/hooks/account/use-get-profile-action-handlers'
import { useGetOrderEditState } from 'utils/order/use-get-order-edit-state'

// This modal needs to be prioritized over other ones.
// Design System has default of z-index 1000 for modals.
const Z_INDEX = 9999

const StyledFooterButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40%;

  .sok-btn:not(:first-child) {
    margin: ${({ theme }) => `${theme.spacings.small} 0 0 0`};
  }

  ${({ theme }) => minWidthFromTheme(theme).desktop} {
    padding-bottom: 0;
  }
`

export const RefreshTokenExpiredModal = () => {
  const { t } = useTranslation()
  const expireSession = useExpireSession()
  const refreshExpired = useReactiveVar(refreshTokenExpired)
  const isUsercentricsOpen = useIsOpen()
  const nextRouter = useRouter()
  const orderEditState = useGetOrderEditState()
  const { onLoginClick } = useGetProfileActionHandlers()
  const [cancelOrderModification] = useMutation(CancelOrderModificationDocument)

  const reservationId = useDeliverySlotReservationId()
  const cancelDeliverySlotReservation = useCancelDeliverySlotReservation()

  const onLoginClickHandler = async () => {
    await expireSession()
    onLoginClick()
  }

  const onContinueAsGuest = async () => {
    await expireSession()
    if (orderEditState.isOrderEditActive) {
      await Promise.all([
        cancelOrderModification(),
        /** reservation exists during editing if the timeslot has been  changed */
        reservationId
          ? cancelDeliverySlotReservation(reservationId, DeliverySlotReleaseReason.LOGIN_EXPIRED)
          : undefined,
      ])
      await nextRouter.push('/tilaus/[orderId]', `/tilaus/${orderEditState.orderId}`)
    }
  }

  return (
    <ConfirmModal
      zIndex={Z_INDEX}
      /** ConfirmModal captures focus from the CMP modal even though it opens below it */
      visible={refreshExpired && !isUsercentricsOpen}
      title={t('Session has expired')}
      hideCloseIcon
      footer={
        <StyledFooterButtons>
          <Button
            key="login"
            type={ButtonType.PRIMARY}
            onClick={onLoginClickHandler}
            text={t('Log in with S-ID')}
          />
          <Button
            key="cancelOrderEdit"
            type={ButtonType.DEFAULT}
            onClick={onContinueAsGuest}
            text={t('Continue as guest')}
          />
        </StyledFooterButtons>
      }
    >
      <div>
        {orderEditState.isOrderEditActive
          ? t('Your session has expired, order modification...')
          : t('Your session has expired...')}
      </div>
    </ConfirmModal>
  )
}
