/* eslint-disable no-console */
import type { ErrorLogEntry, LogEntry, Logger } from './types'

interface LogMessage {
  logFn: (message: string, data: unknown) => void
  logEntry: LogEntry
}

const logMessage = ({ logFn, logEntry }: LogMessage): void => {
  const { message, data } = logEntry
  logFn(message, data)
}

const logError = ({ message, data, error }: ErrorLogEntry): void => {
  console.error(message, { data, error })
}

export const consoleLogger: Logger = {
  debug: async (logEntry: LogEntry): Promise<void> => {
    logMessage({ logFn: console.debug, logEntry })
  },
  debugSync: (logEntry: LogEntry): void => {
    logMessage({ logFn: console.debug, logEntry })
  },
  info: async (logEntry: LogEntry): Promise<void> => {
    logMessage({ logFn: console.info, logEntry })
  },
  infoSync: (logEntry: LogEntry): void => {
    logMessage({ logFn: console.info, logEntry })
  },
  warn: async (logEntry: LogEntry): Promise<void> => {
    logMessage({ logFn: console.warn, logEntry })
  },
  warnSync: (logEntry: LogEntry): void => {
    logMessage({ logFn: console.warn, logEntry })
  },
  error: async (logEntry: ErrorLogEntry): Promise<void> => {
    logError(logEntry)
  },
  errorSync: (logEntry: ErrorLogEntry): void => {
    logError(logEntry)
  },
}
