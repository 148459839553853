export type Masker = <T>(key: string, value: T) => T | string

const fromArray =
  (maskers: Masker[]): Masker =>
  <T>(key: string, value: T | string): T | string =>
    maskers.reduce((accumulator, currentMasker) => currentMasker(key, accumulator), value)

export const maskObjectValues = <T>(entry: T, maskers: Masker[]): T => {
  const jsonString = JSON.stringify(entry, fromArray(maskers))
  return JSON.parse(jsonString) as T
}
