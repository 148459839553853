import type { Maybe } from '@shared/gql/document-nodes'

import { DeliveryMethod, HomeDeliveryType } from '@shared/gql/document-nodes'

export const getDeliveryMethodString = (
  deliveryMethod: DeliveryMethod,
  isFastTrack: Maybe<boolean> = false,
  homeDeliveryType: Maybe<HomeDeliveryType> = HomeDeliveryType.Normal,
): string => {
  switch (deliveryMethod) {
    case DeliveryMethod.HomeDelivery: {
      if (isFastTrack) {
        if (homeDeliveryType === HomeDeliveryType.Robot) {
          return 'Robot delivery'
        } else {
          return 'Home delivery FAST_TRACK'
        }
      }
      return 'Home delivery'
    }
    case DeliveryMethod.Pickup: {
      return isFastTrack ? 'Pickup FAST_TRACK' : 'Pickup'
    }
  }
}
