import type { ApolloClient } from '@apollo/client'

import { Brand } from '@shared/domain/brand'
import {
  CustomerType,
  GetCustomerDetailsDocument,
  GetCustomerTypeDocument,
  GetDeliveryDetailsInfoDocument,
  GetDeliveryStoreDocument,
  GetLocalAuthenticationTokensDocument,
  GetPaymentDetailsDocument,
  GetSelectedAreaIdDocument,
  GetSelectedBrandDocument,
  GetSelectedStoreIdDocument,
  IsOrderEditActiveDocument,
} from '@shared/gql/document-nodes'
import { getDiffInDays } from 'domain/date-time'
import { useEffect } from 'react'
import {
  emptyAuthenticationTokens,
  emptyCustomerDetails,
  emptyDeliveryDetailsInfo,
  emptyOrderEditActive,
  emptyPaymentDetails,
} from 'services/App/empty-initial-states'
import { shoppingCartVar } from 'services/ClientCart/cache'
import { DaoService } from 'services/LocalStorage'

const refreshCustomerDetails = (apolloClient: ApolloClient<object>): void => {
  const cachedCustomerData = DaoService.CustomerDAO.loadCustomerData()

  apolloClient.writeQuery({
    query: GetCustomerDetailsDocument,
    data: {
      __typename: 'Query',
      customerDetails: cachedCustomerData?.customerData?.customerDetails || emptyCustomerDetails(),
    },
  })

  apolloClient.writeQuery({
    query: GetCustomerTypeDocument,
    data: {
      __typename: 'Query',
      customerType: cachedCustomerData?.customerData?.customerType || CustomerType.B2c,
    },
  })
}

const refreshDeliveryDetails = (apolloClient: ApolloClient<object>): void => {
  const clientCachedDeliveryData = DaoService.DeliveryDAO.loadDeliveryData()

  const deliveryDetailsInfo =
    clientCachedDeliveryData?.deliveryData.deliveryDetailsInfo || emptyDeliveryDetailsInfo()

  /** If selected delivery slot is in the past, clear it from the UI. */
  if (deliveryDetailsInfo.deliveryDate && getDiffInDays(deliveryDetailsInfo.deliveryDate) < 0) {
    const defaults = emptyDeliveryDetailsInfo()

    const clearedDeliveryDetails = {
      ...deliveryDetailsInfo,
      deliveryDate: defaults.deliveryDate,
      deliverySlotId: defaults.deliverySlotId,
      deliverySlotPrice: defaults.deliverySlotPrice,
      deliveryTime: defaults.deliveryTime,
    }

    DaoService.DeliveryDAO.saveDeliveryDetails(clearedDeliveryDetails)

    apolloClient.writeQuery({
      query: GetDeliveryDetailsInfoDocument,
      data: {
        __typename: 'Query',
        deliveryDetailsInfo: clearedDeliveryDetails,
      },
    })
  } else {
    apolloClient.writeQuery({
      query: GetDeliveryDetailsInfoDocument,
      data: {
        __typename: 'Query',
        deliveryDetailsInfo,
      },
    })
  }

  apolloClient.writeQuery({
    query: GetSelectedAreaIdDocument,
    data: {
      __typename: 'Query',
      selectedAreaId: clientCachedDeliveryData?.deliveryData.selectedAreaId || '',
    },
  })
}

const refreshSessionDetails = (apolloClient: ApolloClient<object>): void => {
  const clientCachedSessionData = DaoService.SessionDAO.loadSessionData()

  apolloClient.writeQuery({
    query: GetLocalAuthenticationTokensDocument,
    data: {
      __typename: 'Query',
      authenticationTokens:
        clientCachedSessionData?.clientSession.authTokens || emptyAuthenticationTokens(),
    },
  })
}

const refreshPaymentDetails = (apolloClient: ApolloClient<object>): void => {
  const clientCachedPaymentData = DaoService.PaymentDAO.loadPaymentData()

  apolloClient.writeQuery({
    query: GetPaymentDetailsDocument,
    data: {
      __typename: 'Query',
      paymentDetails: clientCachedPaymentData?.paymentData.paymentDetails || emptyPaymentDetails(),
    },
  })
}

const refreshStoreDetails = (apolloClient: ApolloClient<object>): void => {
  const clientCachedStoreData = DaoService.StoreDAO.loadDeliveryStore()

  apolloClient.writeQuery({
    query: GetSelectedBrandDocument,
    data: {
      __typename: 'Query',
      selectedBrand: clientCachedStoreData?.storeData.selectedBrand || Brand.Eprisma,
    },
  })

  apolloClient.writeQuery({
    query: GetDeliveryStoreDocument,
    data: {
      __typename: 'Query',
      deliveryStore: clientCachedStoreData?.storeData.deliveryStore || null,
    },
  })

  apolloClient.writeQuery({
    query: GetSelectedStoreIdDocument,
    data: {
      __typename: 'Query',
      selectedStoreId: clientCachedStoreData?.storeData.storeId || '',
    },
  })
}

const refreshCartDetails = (apolloClient: ApolloClient<object>): void => {
  const clientCachedCartData = DaoService.CartDAO.loadCartData()

  apolloClient.writeQuery({
    query: IsOrderEditActiveDocument,
    data: {
      __typename: 'Query',
      orderEditActive: clientCachedCartData?.orderEditActive || emptyOrderEditActive(),
    },
  })

  shoppingCartVar(DaoService.CartDAO.loadCartItems())
}

const refreshFunctions = {
  [DaoService.CartDAO.localStorageKey]: refreshCartDetails,
  [DaoService.DeliveryDAO.localStorageKey]: refreshDeliveryDetails,
  [DaoService.CustomerDAO.localStorageKey]: refreshCustomerDetails,
  [DaoService.PaymentDAO.localStorageKey]: refreshPaymentDetails,
  [DaoService.StoreDAO.localStorageKey]: refreshStoreDetails,
  [DaoService.SessionDAO.localStorageKey]: refreshSessionDetails,
}

export const useRefreshOnce = (apolloClient: ApolloClient<object>): void => {
  useEffect(() => {
    for (const refreshFunction of Object.values(refreshFunctions)) {
      refreshFunction(apolloClient)
    }
  }, [apolloClient])
}

export const useLocalStorageRefresh = (apolloClient: ApolloClient<object>): void => {
  useEffect(() => {
    const refreshFunction = (event: StorageEvent) => {
      if (event.key && refreshFunctions[event.key]) {
        refreshFunctions[event.key](apolloClient)
      }
    }

    window.addEventListener('storage', refreshFunction)

    return () => {
      window.removeEventListener('storage', refreshFunction)
    }
  }, [apolloClient])
}
