import type { ProductAvailabilityLabel } from '@shared/gql/document-nodes'

export interface CustomEvent {
  category: EventCategory
  action: EventAction
  label: string | null
  value?: number
}

/** GA4 customEvent with eventName + params */
export interface GA4CustomEvent {
  eventName: string
  eventParams?: { [key: string]: string | number }
}

export interface VirtualPageview {
  path: string
  query?: string
}

export type GAProductAvailabilityLabel = ProductAvailabilityLabel | 'UNKNOWN' | null

export interface GAProduct {
  availabilityLabel?: GAProductAvailabilityLabel
  category?: string
  id: string
  name: string
  price: string // unit price
}

export interface GAListProduct extends GAProduct {
  quantity: number
}

export interface GAActionField {
  id: string
  revenue?: number
  coupon?: string
}

export interface GAPurchase {
  actionField: GAActionField
  products: GAProduct[]
}

export enum OrderState {
  NEW = 'new',
  MODIFICATION = 'modification',
}

export enum EventAction {
  LOGIN = 'login',
  LOGOUT = 'logout',
  CLICK = 'click',
  OPEN_CHAT = 'open-chat',
  OPEN = 'open',
  CLOSE = 'close',
  NO_RESULTS = 'no-results',
  ADD = 'add',
  REMOVE = 'remove',
  SUBMIT = 'submit',
  SCROLL = 'scroll',
  ERROR_FETCHING_DATA = 'error-fetching-data',
  ORDER_CANCEL = 'order-cancel', // specific to ecommerce category events
  ORDER_TRANSACTION = 'order-transaction', // specific to ecommerce category events
  ORDER_MODIFICATION = 'order-modification', // specific to ecommerce category events
  COPY_ORDER_AS_TEMPLATE = 'copy-order-as-template',
  COPY_ORDER_HISTORY_ORDER_CART_ITEMS = 'copy-order-cart-items',
  OPEN_ORDER_HISTORY_ORDER_DETAILS = 'open-order-details',
  NO_ORDER_HISTORY = 'no-order-history',
  INVALID_TIMESLOT_MODAL = 'invalid-timeslot-modal',
  SHOW_ERROR_MESSAGE = 'show-error-message',
  CLICK_ERROR_MESSAGE = 'click-error-message',
  FAVORITE_LIST_DELETE = 'list-delete',
  FAVORITE_LIST_EDIT = 'list-edit',
  FAVORITE_LIST_CREATE = 'list-create',
  OPEN_PRODUCT_INFO_TAB = 'view-product-information',
  OPEN_PRODUCT_NUTRIENTS_TAB = 'view-product-nutritional-value',
  OPEN_PRODUCT_SHELF_LOCATION_TAB = 'view-product-shelf-location',
  OPEN_PRODUCT_FEATURES_TAB = 'view-product-features',
  PRODUCT_REPLACEMENT = 'product-replacement',

  DELIVERY_SLOT_RESERVATION = 'delivery-slot-reservation',
  DELIVERY_SLOT_EXTENSION = 'delivery-slot-extension',
  DELIVERY_SLOT_EXPIRATION_MESSAGE = 'delivery-slot-expiration-message',
  DELIVERY_SLOT_RELEASE = 'delivery-slot-release',
}

export enum EventCategory {
  CUSTOMER_CHAT = 'customer-chat',
  ECOMMERCE = 'ecommerce',
  SEARCH = 'search',
  DELIVERY_SELECTIONS = 'delivery-selections',
  PRODUCT_RESULTS = 'product-results',
  NAVIGATION_ELEMENTS = 'navigation-elements',
  CONTENT_LINKS = 'content-links',
  SCROLLING = 'scrolling',
  ERROR = 'error',
  USER_LOGINS = 'user-logins',
  ORDER = 'order-actions',
  ORDER_HISTORY = 'order-history',
  ALCOHOL_PRODUCTS = 'alcohol-products',
  CHECKOUT_ERROR_MESSAGE = 'checkout-error-message',
  FAVORITE_PRODUCTS = 'favorite-products',
  SEARCH_RESULTS = 'search-results',
  PRODUCT_PAGES = 'product-pages',
  RECIPE_ACTIONS = 'recipe-actions',
  SHOPPING_CART_ELEMENTS = 'shopping-cart-elements',
}

/**
 * These are used to provide context for tracked components,
 * especially when same component is used in multiple use cases.
 * Should usually match the parent component or page of component in question.
 */
export enum TrackingContext {
  AUTOSUGGEST_SEARCHBOX = 'autosuggest-searchbox',
  CART_COLUMN = 'cart-column',
  FEATURED_PRODUCTS_LIST = 'featured-products-list',
  PRODUCT_DETAIL_PAGE = 'product-detail-page',
  PRODUCTLIST_ITEM = 'productlist-item',
  PRODUCT_CATEGORY = 'product-category',
  SHOPPING_CART = 'shopping-cart',
  SEARCH_RESULTS = 'search-results',
  FEATURED_PRODUCTS_CAROUSEL = 'featured-products-carousel',
  PRODUCT_GRID_FOR_UPSELL_CART_PAGE = 'product-grid-for-upsell-cart-page',
  RECOMMENDATIONS = 'smart-cart-recommendation',
  FAVORITES = 'favorites',
  ALCOHOL_CONFLICT_MODAL = 'alcohol-conflict-modal',
  RECIPE_DETAIL_PAGE = 'recipe-detail-page',
  RECOMMENDED_PRODUCTS_CAROUSEL = 'recommended-products-carousel',
  SHOPPINGLISTS_ALL = 'shoppinglists-all',
  SHOPPINGLIST = 'shoppinglist',
  SHOPPINGLISTS_SEARCH_PRODUCTS_MODAL = 'shoppinglists-search-products-modal',
  CHECKOUT_SUMMARY = 'checkout-summary',
  COPY_PREVIOUS_ORDER = 'copy-previous-order',
}

export enum DeliveryDialogSteps {
  SELECT_METHOD_AND_STORE = 'select-delivery-method',
  SELECT_DELIVERY_TIME = 'select-delivery-time',
  SELECT_DELIVERY_TIME_RESERVATION_ERROR = 'delivery-time-reservation-error',
  SELECT_DELIVERY_TIME_ALCOHOL_CONFLICT = 'delivery-time-alcohol-conflict',
}

export enum CheckoutSteps {
  REVIEW_ORDER = 1,
  CHECKOUT = 2,
}

export enum PromotionPosition {
  FRONTPAGE_HERO = 'frontpage-hero',
  FRONTPAGE_HERO_1 = 'frontpage-hero-1',
  FRONTPAGE_HERO_2 = 'frontpage-hero-2',
  FRONTPAGE_HERO_3 = 'frontpage-hero-3',
  FRONTPAGE_HERO_CAROUSEL_1 = 'hero-carousel-1',
  FRONTPAGE_HERO_CAROUSEL_2 = 'hero-carousel-2',
  FRONTPAGE_HERO_CAROUSEL_3 = 'hero-carousel-3',
  FRONTPAGE_HERO_CAROUSEL_4 = 'hero-carousel-4',
  FRONTPAGE_HERO_CAROUSEL_5 = 'hero-carousel-5',
  FRONTPAGE_HERO_CAROUSEL_6 = 'hero-carousel-6',
  FRONTPAGE_HERO_CAROUSEL_7 = 'hero-carousel-7',
  FRONTPAGE_HERO_CAROUSEL_8 = 'hero-carousel-8',
  FRONTPAGE_HERO_CAROUSEL_9 = 'hero-carousel-9',
  FRONTPAGE_HERO_CAROUSEL_10 = 'hero-carousel-10',
  /**
   * Should be used with a dynamic number suffix in `ArticleGridCard`.
   *
   * @example `${PromotionPosition.ARTICLE_GRID_PREFIX_}${index + 1}`
   * // article-grid-promotions-1
   */
  ARTICLE_GRID_PREFIX_ = 'article-grid-promotions-',
  HORIZONTAL_GRID_1 = 'horizontal-promotions-grid-1',
  HORIZONTAL_GRID_2 = 'horizontal-promotions-grid-2',
  HORIZONTAL_GRID_3 = 'horizontal-promotions-grid-3',
}
