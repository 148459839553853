import type { Modify } from '../local-storage'
import type { AuthenticationTokens } from '@shared/gql/document-nodes'
import type { TypeOf } from 'zod'

import { SESSION_SCHEMA_VERSION, SessionSchema } from '@shared/domain/local-storage'

import { LocalStorageService } from '../local-storage'

const schemaValidator = (data: TypeOf<typeof SessionSchema>) => SessionSchema.parse(data)

const KEY_CLIENT_SESSION = 'client-data'

const newEmptyLocalStorageClientSessionData = (): TypeOf<typeof SessionSchema> => {
  return {
    cacheVersion: SESSION_SCHEMA_VERSION,
    clientSession: {
      authTokens: null,
    },
    authRedirectPath: null,
  }
}

const modifySessionData = (modifier: Modify<TypeOf<typeof SessionSchema>>) => {
  LocalStorageService.modify<TypeOf<typeof SessionSchema>>(
    modifier,
    newEmptyLocalStorageClientSessionData,
    KEY_CLIENT_SESSION,
    schemaValidator,
  )
}

const saveAuthRedirectPath = (authRedirectPath: string | null) => {
  modifySessionData((current) => ({
    ...current,
    authRedirectPath,
  }))
}

const saveAuthTokens = (authTokens: AuthenticationTokens) => {
  modifySessionData((current) => ({
    ...current,
    clientSession: {
      ...current.clientSession,
      authTokens,
    },
  }))
}

const clearAuthTokens = () => {
  modifySessionData((current) => ({
    ...current,
    clientSession: {
      ...current.clientSession,
      authTokens: null,
    },
  }))
}

const clearAuthRedirectPath = () => {
  modifySessionData((current) => ({
    ...current,
    authRedirectPath: null,
  }))
}

const loadSessionData = (): TypeOf<typeof SessionSchema> | null => {
  return LocalStorageService.load<TypeOf<typeof SessionSchema>>(KEY_CLIENT_SESSION, schemaValidator)
}

const reset = (): void => {
  LocalStorageService.clear(KEY_CLIENT_SESSION)
}

export const SessionDAO = {
  clearAuthRedirectPath,
  clearAuthTokens,
  loadSessionData,
  localStorageKey: KEY_CLIENT_SESSION,
  reset,
  saveAuthRedirectPath,
  saveAuthTokens,
}
