import { useQuery } from '@apollo/client'
import { GetProductInfoByEansDocument } from '@shared/gql/document-nodes'
import { useMemo } from 'react'
import { isNonNullable } from 'utils/nullable'

import { useDeliveryDate } from './delivery/use-delivery-date'

interface InputProps {
  eans?: (string | undefined | null)[] | null
  fallbackToGlobal?: boolean
  includeAgeLimitedByAlcohol?: boolean
  includeAvailabilities?: boolean | null
  selectedStoreId?: string | null
}

export const useSkippableProductInfoQuery = (input: InputProps) => {
  const availabilityDate = useDeliveryDate()

  const variables = useMemo(
    () => ({
      availabilityDate,
      eans: input.eans?.filter(isNonNullable) || [],
      fallbackToGlobal: input.fallbackToGlobal ?? null,
      id: input.selectedStoreId as string,
      includeAgeLimitedByAlcohol: input.includeAgeLimitedByAlcohol ?? false,
      includeAvailabilities: input.includeAvailabilities,
    }),
    [
      availabilityDate,
      input.eans,
      input.fallbackToGlobal,
      input.includeAgeLimitedByAlcohol,
      input.includeAvailabilities,
      input.selectedStoreId,
    ],
  )

  const response = useQuery(GetProductInfoByEansDocument, {
    skip: !input.selectedStoreId || variables.eans.length === 0,
    variables,
  })

  return response
}
