import { createContext, useContext } from 'react'

interface ModalContext<T, K = void> {
  onSubmit: (x: T) => K
  onCancel: () => void
}

export const ModalContext = createContext<ModalContext<unknown> | null>(null)

export const useModal = <T, K = void>() => {
  const ctx = useContext(ModalContext) as ModalContext<T, K>
  if (!ctx) {
    throw new Error('Must be wrapped inside ModalContext')
  }
  return ctx
}
