import type { Modify } from '../local-storage'
import type { DeliveryDetailsInfo } from '@shared/gql/document-nodes'
import type { TypeOf } from 'zod'

import { DELIVERY_SCHEMA_VERSION, DeliverySchema } from '@shared/domain/local-storage'

import { LocalStorageService } from '../local-storage'

const KEY_DELIVERY_DATA = 'delivery-data'

const schemaValidator = (data: TypeOf<typeof DeliverySchema>) => DeliverySchema.parse(data)

const newEmptyLocalStorageDeliveryData = (): TypeOf<typeof DeliverySchema> => {
  return {
    cacheVersion: DELIVERY_SCHEMA_VERSION,
    deliveryData: {
      deliveryDetailsInfo: null,
      selectedAreaId: null,
    },
  }
}

const modifyDeliveryData = (modifier: Modify<TypeOf<typeof DeliverySchema>>) => {
  LocalStorageService.modify<TypeOf<typeof DeliverySchema>>(
    modifier,
    newEmptyLocalStorageDeliveryData,
    KEY_DELIVERY_DATA,
    schemaValidator,
  )
}

const saveDeliveryDetails = (deliveryDetailsInfo: DeliveryDetailsInfo): void => {
  modifyDeliveryData((current) => ({
    ...current,
    deliveryData: {
      ...current.deliveryData,
      deliveryDetailsInfo,
    },
  }))
}

const saveSelectedAreaId = (selectedAreaId: string | null): void => {
  modifyDeliveryData((current) => ({
    ...current,
    deliveryData: {
      ...current.deliveryData,
      selectedAreaId,
    },
  }))
}

const loadDeliveryData = (): TypeOf<typeof DeliverySchema> | null =>
  LocalStorageService.load<TypeOf<typeof DeliverySchema>>(KEY_DELIVERY_DATA, schemaValidator)

const reset = (): void => {
  LocalStorageService.clear(KEY_DELIVERY_DATA)
}

export const DeliveryDAO = {
  loadDeliveryData,
  localStorageKey: KEY_DELIVERY_DATA,
  reset,
  saveDeliveryDetails,
  saveSelectedAreaId,
}
