import type { ApolloError } from '@apollo/client'
import type { RemoteData } from 'domain/remote-data'

import { useQuery, useReactiveVar } from '@apollo/client'
import { RemoteStoreAvailabilityByEanDocument } from '@shared/gql/document-nodes'
import { useMemo } from 'react'

import { pendingProductEanVar, storeAvailabilityFilterVar } from '../cache'

/**
 * This hook is used to filter delivery areas to include only those stores that sell a specific product.
 * This feature is automatically enabled on Product pages, by setting the current product EAN to
 * `pendingProductEanVar`. When navigating away from that page, the pending EAN is cleared.
 *
 * The `storeAvailabilityFilterVar` controls the state of the checkbox for toggling this feature on/off.
 * It's by default on, but the checkbox is only visible on product pages (when pending EAN is set).
 * The checkbox can be found in the `DeliveryModal`.
 *
 * If the feature is turned off by clicking the checkbox, it'll stay off (during runtime), unless
 * navigating to a product page that is not sold in the current store, and opening the delivery modal
 * from the warning box ("click here to see stores selling this product").
 */
export const useStoreAvailabilityByPendingProductEan = (): RemoteData<ApolloError, Set<string>> => {
  const pendingEan = pendingProductEanVar()
  const enabled = useReactiveVar(storeAvailabilityFilterVar)

  const skip = !pendingEan || !enabled
  const { data, error, loading } = useQuery(RemoteStoreAvailabilityByEanDocument, {
    variables: { ean: pendingEan || '' },
    skip,
  })

  return useMemo<RemoteData<ApolloError, Set<string>>>(() => {
    const storeIds = data?.product?.storeAvailability?.flatMap((product) =>
      product.storeId ? product.storeId.toString() : [],
    )

    if (skip) {
      return { type: 'NOT_ASKED' }
    }

    if (loading) {
      return { type: 'LOADING' }
    }

    if (error) {
      return { type: 'FAILURE', error }
    }

    return { type: 'SUCCESS', data: new Set(storeIds) }
  }, [data?.product?.storeAvailability, error, loading, skip])
}
