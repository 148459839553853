import type { ClientCartItem } from '@shared/gql/document-nodes'

import { makeVar } from '@apollo/client'

export const shoppingCartVar = makeVar<ClientCartItem[]>([])

export const pendingClientCartItemsVar = makeVar<ClientCartItem[] | null>(null)

interface ProductIdAndAmount {
  amount: number
  productId: string
  storeId?: string
}

export const pendingProductVar = makeVar<ProductIdAndAmount | null>(null)
