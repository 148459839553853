import type { GetInitialDataQuery } from '@shared/gql/document-nodes'

import { Brand } from '@shared/domain/brand'
import { CustomerType } from '@shared/gql/document-nodes'

import {
  emptyAuthenticationTokens,
  emptyCustomerDetails,
  emptyDeliveryDetailsInfo,
  emptyOrderEditActive,
  emptyPaymentDetails,
} from './empty-initial-states'

export const getInitialData = (initial?: Partial<GetInitialDataQuery>): GetInitialDataQuery => ({
  __typename: 'Query' as const,

  customerDetails: emptyCustomerDetails(),

  customerType: CustomerType.B2c,

  deliveryDetailsInfo: emptyDeliveryDetailsInfo(),

  deliveryStore: null,

  orderEditActive: emptyOrderEditActive(),

  paymentDetails: emptyPaymentDetails(),

  selectedBrand: initial?.selectedBrand || Brand.Eprisma,

  selectedStoreId: initial?.selectedStoreId || null,

  selectedAreaId: null,

  authenticationTokens: emptyAuthenticationTokens(),
})
