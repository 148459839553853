import type { ClientCartItem } from '@shared/gql/document-nodes'

import { ProductType } from '@shared/gql/document-nodes'

const isMissing = (cartItem: ClientCartItem): boolean =>
  cartItem.productType !== ProductType.ServiceFee && cartItem.inStoreSelection === false

export const getMissingItems = (cartItems: ClientCartItem[]): ClientCartItem[] =>
  cartItems.filter(isMissing)

export const sortMissingOnTop = (a: ClientCartItem, b: ClientCartItem): number => {
  if (a.inStoreSelection === b.inStoreSelection) return 0
  return !a.inStoreSelection ? -1 : 1
}

export const filterPurchaseableCartItems = <
  T extends { productType: ProductType; inStoreSelection: boolean },
>(
  cartItems: T[],
): T[] =>
  cartItems
    .filter((cartItem) => cartItem.productType === ProductType.Product)
    .filter((cartItem) => cartItem.inStoreSelection)
