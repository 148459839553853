import { useApolloClient } from '@apollo/client'
import {
  CreateDeliverySlotReservationDocument,
  DeliverySlotReservationDocument,
} from '@shared/gql/document-nodes'
import { useCallback } from 'react'

import { DeliverySlotReleaseReason, trackDeliverySlotReserved } from '../tracking'
import { saveReservationId } from '../utils/local-storage'
import { useCancelDeliverySlotReservation } from './use-cancel-delivery-slot-reservation'
import { useDeliverySlotReservationId } from './use-delivery-slot-reservation-id'

type ReserveDeliverySlotCallback = (deliverySlotId: string) => Promise<void>

/**
 * Returns a callback for reservering aspecific
 * delivery slot by `deliverySlotId`
 */
export const useReserveDeliverySlot = (): ReserveDeliverySlotCallback => {
  const previousReservationId = useDeliverySlotReservationId()
  const cancelDeliverySlotReservation = useCancelDeliverySlotReservation()
  const apolloClient = useApolloClient()

  return useCallback(
    async (deliverySlotId: string) => {
      const [reservationResult] = await Promise.all([
        apolloClient.mutate({
          mutation: CreateDeliverySlotReservationDocument,
          variables: {
            deliverySlotId,
          },
        }),
        /** cancel previous reservation, if any */
        previousReservationId
          ? cancelDeliverySlotReservation(
              previousReservationId,
              DeliverySlotReleaseReason.TIME_CHANGE,
            )
          : undefined,
      ])

      const newReservationId = reservationResult.data?.createDeliverySlotReservation?.reservationId
      if (!newReservationId) return

      /** Query reservation info after reservation */
      await apolloClient.query({
        query: DeliverySlotReservationDocument,
        variables: {
          reservationId: newReservationId,
        },
      })

      trackDeliverySlotReserved(newReservationId)

      saveReservationId(newReservationId)
    },
    [apolloClient, cancelDeliverySlotReservation, previousReservationId],
  )
}
