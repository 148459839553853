import { trackCustomEvent } from 'utils/tracking/custom-events/custom-events'

import { EventAction, EventCategory, TrackingContext } from './interfaces/data-layer-events'
import { dataLayer } from './tag-manager'

export const trackAlcoholConflictSeen = (): void => {
  trackCustomEvent({
    category: EventCategory.ALCOHOL_PRODUCTS,
    action: EventAction.INVALID_TIMESLOT_MODAL,
    label: 'modal-visibility',
  })
}

export const trackAlcoholNewTimeClicked = (): void => {
  trackCustomEvent({
    category: EventCategory.ALCOHOL_PRODUCTS,
    action: EventAction.INVALID_TIMESLOT_MODAL,
    label: 'new-timeslot-click',
  })
}

export const trackAlcoholRemovedClicked = (alcoholItems: { name: string; id: string }[]): void => {
  trackCustomEvent({
    category: EventCategory.ALCOHOL_PRODUCTS,
    action: EventAction.INVALID_TIMESLOT_MODAL,
    label: 'remove-products-click',
  })

  dataLayer({
    event: 'remove',
    customDimensions: {
      productListContext: TrackingContext.ALCOHOL_CONFLICT_MODAL,
    },
    ecommerce: {
      remove: {
        products: alcoholItems.map(({ id, name }) => ({ id, name })),
      },
    },
  })
}

export enum CheckoutAlcoholMessage {
  NoProductsLeftInCart,
  AlcoholWillBeRemoved,
  ProvideId,
}

export const trackAlcoholCheckout = (
  message: CheckoutAlcoholMessage,
  isClickEvent = false,
): void => {
  trackCustomEvent({
    category: EventCategory.CHECKOUT_ERROR_MESSAGE,
    action: isClickEvent ? EventAction.CLICK_ERROR_MESSAGE : EventAction.SHOW_ERROR_MESSAGE,
    label: `alcohol-error-message-${message}`,
  })
}
