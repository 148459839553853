import type { FieldFunctionOptions } from '@apollo/client'
import type { Maybe, Product } from '@shared/gql/document-nodes'

type ProductList = Readonly<Maybe<Product>[]>

export const mergeProducts = (
  existing: ProductList = [],
  incoming: ProductList,
  options: FieldFunctionOptions,
): ProductList => {
  /** The `FieldFunctionOptions` doesn't support parameters */
  const from = typeof options?.variables?.from === 'number' ? options.variables.from : 0

  /** Resets when variables like `filters` or `queryString` change */
  if (from === 0) return incoming
  /**
   * For whatever reason, returning `[...existing, ...incoming]`
   * doesn't work. This style is adopted from here:
   * @see https://github.com/apollographql/apollo-client/blob/cd6f4061693d66091384b43f57180dc88529c00b/src/utilities/policies/pagination.ts#L33-L49
   */
  const merged = [...existing]
  for (const [i, item] of incoming.entries()) {
    merged[from + i] = item
  }

  return merged
}
