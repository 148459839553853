import type { FC } from 'react'

export const RecommendationsNavIcon: FC = () => (
  <svg fill="none" height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m17.9375 1.69978c1.1007-.00212 1.6682-.02592 2.1178.6696l3.571 7.09009c.1485.22979.3847.38869.6536.43964l9.2982 1.63999c.9375.1875 1.4063 1.6136.6563 2.5l-6.5313 6.7492c-.1786.1972-.2712.4574-.2575.723l.945 8.1489c.042.8112-.4336 1.2656-.8476 1.5546l-7.9048-4.517c-.2693-.1712-.6041-.2036-.9012-.0873l-8.2415 3.2268c-.73069.2861-1.49115-.3425-1.34772-1.114l1.47522-7.935c.0615-.3307-.0474-.6703-.2899-.9035l-5.2424-5.0415c-.56623-.5445-.31231-1.4993.44961-1.6906l8.11529-2.0379c.3234-.0812.5851-.3183.6977-.6322z"
      fill="#cea204"
    />
    <path
      d="m17.1093 2.24536c.3711-.72725 1.4103-.72725 1.7814 0l4.124 8.08104c.1453.2847.418.4828.7337.533l8.9599 1.425c.8064.1283 1.1275 1.1166.5505 1.6943l-6.4111 6.4193c-.2259.2262-.3301.5467-.2803.8625l1.4135 8.9617c.1273.8065-.7134 1.4173-1.4412 1.0471l-8.0863-4.1136c-.2849-.145-.6219-.145-.9068 0l-8.08634 4.1136c-.72773.3702-1.56842-.2406-1.44121-1.0471l1.41351-8.9617c.0498-.3158-.05434-.6363-.28023-.8625l-6.41113-6.4193c-.57697-.5777-.25585-1.566.55049-1.6943l8.95991-1.425c.3157-.0502.5884-.2483.7337-.533z"
      fill="#f5bf00"
    />
  </svg>
)

export const FavoritesNavIcon: FC = () => (
  <svg height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m18 8.12s-1-5.12-7-5.12c-5 0-8 5-8 10 0 8 12 20 15 20s15-12 15-20c0-5-3-10-8-10-6 0-7 5.12-7 5.12z"
      fill="#e5504e"
    />
    <path
      d="m20 32c-3 0-15-12-15-20 0-3.43 1.41-6.86 3.91-8.67-3.73 1.17-5.91 5.41-5.91 9.67 0 8 12 20 15 20 .53 0 1.34-.38 2.31-1.04-.11.03-.21.04-.31.04z"
      fill="#bf2228"
    />
  </svg>
)
export const ShoppinglistsNavIcon: FC = () => (
  <svg width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        d="M29.042 33.356H9.782c-1.408 0-2.54-1.19-2.54-2.668V2.668C7.242 1.19 8.374 0 9.782 0h19.26c1.407 0 2.54 1.19 2.54 2.669v28.019c0 1.479-1.133 2.668-2.54 2.668Z"
        fill="#F1F1F1"
      />
      <path
        d="M26.537 36H7.277c-1.407 0-2.54-1.19-2.54-2.668V5.312c0-1.478 1.133-2.668 2.54-2.668h19.26c1.407 0 2.54 1.19 2.54 2.668v28.02c0 1.478-1.133 2.668-2.54 2.668Z"
        fill="#fff"
        stroke="#DBDBDB"
        strokeWidth=".867"
        strokeMiterlimit="10"
      />
      <path
        d="M25.568 8.202H12.34a.269.269 0 0 1-.265-.278V7.37c0-.156.117-.278.265-.278h13.238c.148 0 .265.122.265.278v.567a.283.283 0 0 1-.275.266ZM18.287 10.427H12.34a.269.269 0 0 1-.265-.278v-.556c0-.156.117-.278.265-.278h5.936c.149 0 .265.122.265.278v.545a.264.264 0 0 1-.254.29Z"
        fill="#484848"
      />
      <path
        opacity=".6"
        d="M25.568 14.875H12.34a.269.269 0 0 1-.265-.277v-.568c0-.155.117-.278.265-.278h13.238c.148 0 .265.123.265.278v.568c-.01.155-.127.277-.275.277ZM18.287 17.099H12.34a.269.269 0 0 1-.265-.278v-.545c0-.156.117-.278.265-.278h5.936c.149 0 .265.122.265.278v.545a.26.26 0 0 1-.254.278Z"
        fill="#484848"
      />
      <path
        opacity=".4"
        d="M25.568 21.546H12.34a.269.269 0 0 1-.265-.278v-.567c0-.155.117-.278.265-.278h13.238c.148 0 .265.123.265.278v.567c-.01.156-.127.278-.275.278ZM18.287 23.77H12.34a.269.269 0 0 1-.265-.279v-.544c0-.156.117-.278.265-.278h5.936c.149 0 .265.122.265.277v.545a.26.26 0 0 1-.254.278Z"
        fill="#484848"
      />
      <path
        opacity=".2"
        d="M25.568 28.217H12.34a.269.269 0 0 1-.265-.278v-.567c0-.156.117-.278.265-.278h13.238c.148 0 .265.122.265.278v.567c-.01.156-.127.278-.275.278ZM18.287 30.442H12.34a.269.269 0 0 1-.265-.279v-.544c0-.156.117-.278.265-.278h5.936c.149 0 .265.122.265.278v.544a.26.26 0 0 1-.254.279Z"
        fill="#484848"
      />
      <path
        d="M9.134 8.316c.014-.372.672-1.106 1.313-1.229.051.525-1.313 1.23-1.313 1.23Z"
        fill="#79C582"
      />
      <path
        d="M9.552 8.144c.636.213.774.783.657 1.052-.257.595-.657.79-1.184.658-.527-.132-.784-.722-.527-1.316.166-.38.659-.526 1.054-.394Z"
        fill="#E12E25"
      />
      <path
        d="m9.708 8.064.096.034a.029.029 0 0 1 .017.036l-.227.3-.077.101.155-.45c.002-.017.02-.027.036-.021Z"
        fill="#7E2E1E"
      />
      <path
        d="M9.782 8.43a.253.253 0 0 0-.231.011.276.276 0 0 0-.198-.184c-.05-.01-.07.066-.02.076.081.019.148.09.153.175.002.034.042.054.067.028a.172.172 0 0 1 .19-.038c.02.01.042.005.054-.014.01-.017.004-.045-.015-.054Z"
        fill="#D62B27"
      />
      <path
        d="M7.408 8.485c.537-.402 1.085-.192 1.24.058.342.55.275.99-.133 1.348-.407.358-1.039.233-1.38-.316-.22-.354-.06-.84.273-1.09Z"
        fill="#EC483F"
      />
      <path
        d="m7.43 8.309.083-.06a.03.03 0 0 1 .04.007l.116.358.04.122-.283-.385a.03.03 0 0 1 .005-.042Z"
        fill="#7E2E1E"
      />
      <path
        d="M7.774 8.458a.254.254 0 0 0-.123.197.275.275 0 0 0-.263.059c-.037.034.014.095.05.06a.197.197 0 0 1 .232-.027c.028.016.069-.004.061-.04a.17.17 0 0 1 .077-.177c.019-.011.029-.032.019-.053-.009-.017-.035-.03-.053-.019Z"
        fill="#D62B27"
      />
      <path
        d="M10.014 27.595c.194.205-.248.69-.35.803-.102.112-1.059 1.081-1.37 1.34-.31.258-.608.165-.685.083-.078-.082-.166-.32.174-.695.34-.374 1.23-1.268 1.472-1.452.243-.183.642-.203.759-.08Z"
        fill="#E39864"
      />
      <path
        d="M10.016 27.599c.029.03.046.07.05.112a.335.335 0 0 1-.013.12.883.883 0 0 1-.095.21c-.079.13-.173.247-.272.358-.1.111-.205.215-.308.321l-.314.315c-.211.207-.422.415-.643.611-.055.05-.11.098-.171.141a.746.746 0 0 1-.2.099.58.58 0 0 1-.223.024.363.363 0 0 1-.216-.089.28.28 0 0 0 .103.057.467.467 0 0 0 .113.021.558.558 0 0 0 .217-.031.742.742 0 0 0 .192-.101c.058-.044.112-.094.167-.144.215-.201.426-.409.636-.617.21-.208.421-.416.627-.629.1-.108.197-.222.277-.348a.866.866 0 0 0 .101-.204.34.34 0 0 0 .017-.115.183.183 0 0 0-.045-.111Z"
        fill="#AD7F2D"
      />
      <path
        d="M9.707 28.13c.002-.053-.16-.13-.256-.15-.13-.026-.379-.008-.38.028-.002.05.244.09.324.103.08.013.31.051.312.02ZM9.27 28.617c.003-.055-.159-.131-.255-.15-.131-.027-.38-.01-.38.027-.003.05.243.09.324.103.08.013.31.051.312.02ZM8.692 29.174c.002-.055-.16-.131-.256-.15-.131-.027-.38-.01-.38.027-.003.05.243.09.324.103.08.013.31.05.312.02Z"
        fill="#FFCEB7"
      />
      <path
        d="M8.342 21.229c.734-.104 1.097.38 1.179.752.081.372-.008 1.072-.525 1.32-.518.246-1.28.282-1.602-.469-.322-.75.033-1.474.948-1.603Z"
        fill="#EE7B74"
      />
      <path
        d="M8.331 21.472c-.288.011-.323-.106-.36-.223-.035-.117-.007-.213.046-.231.052-.018.084.273.19.237.105-.036.021-.31.18-.363.157-.055.03.291.095.312.065.021.163-.228.28-.225.118.002-.143.48-.43.493Z"
        fill="#49A3A1"
      />
      <path
        d="M7.758 22.471a.601.601 0 0 1 .006-.647c.023-.034-.028-.073-.051-.038a.671.671 0 0 0-.003.727c.023.035.071-.007.048-.042Z"
        fill="#EAAFAD"
      />
      <path
        d="M9.944 15.772c-.277.16-2.56 1.192-2.715.92-.156-.27 1.873-1.743 2.15-1.904a.571.571 0 0 1 .785.2.577.577 0 0 1-.22.784Z"
        fill="#E58341"
      />
      <path
        d="m10.8 14.325.13.227a.04.04 0 0 1-.014.054l-.78.454a.04.04 0 0 1-.055-.014l-.05-.09a.04.04 0 0 1 .008-.05l.701-.592a.04.04 0 0 1 .06.01Z"
        fill="#548C76"
      />
      <path
        d="m11.098 14.665-.098-.17a.03.03 0 0 0-.041-.01l-.823.477.043.076.902-.33a.03.03 0 0 0 .017-.043Z"
        fill="#5E967E"
      />
      <path
        d="m10.726 14.555.054.094a.031.031 0 0 1-.012.043l-.667.387-.043-.076.622-.456c.016-.011.037-.008.046.008Z"
        fill="#6FAA91"
      />
      <path
        d="m9.192 15.634.208.368c.021.037.079.004.057-.033l-.207-.368c-.022-.038-.079-.005-.058.033ZM9.01 15.784c.059.098.115.198.17.3.02.036.077.003.057-.034a6.435 6.435 0 0 0-.17-.3c-.023-.036-.08-.003-.058.034ZM8.497 16.136l.103.18c.009.015.03.021.045.012.015-.01.022-.03.013-.046-.034-.06-.07-.12-.103-.18a.034.034 0 0 0-.046-.012c-.015.011-.021.03-.012.046Z"
        fill="#D6702B"
      />
      <path
        d="M9.707 15.073c-.277.16-2.56 1.192-2.715.921-.156-.271 1.873-1.743 2.15-1.904a.571.571 0 0 1 .785.2.578.578 0 0 1-.22.783Z"
        fill="#F19552"
      />
      <path
        d="m10.562 13.626.13.228a.04.04 0 0 1-.015.053l-.78.454a.039.039 0 0 1-.054-.014l-.051-.09a.04.04 0 0 1 .009-.05l.7-.591a.04.04 0 0 1 .06.01Z"
        fill="#548C76"
      />
      <path
        d="m10.86 13.966-.097-.17a.03.03 0 0 0-.042-.01l-.822.478.043.075.902-.33a.03.03 0 0 0 .016-.043Z"
        fill="#5E967E"
      />
      <path
        d="m10.489 13.857.054.094a.031.031 0 0 1-.012.042l-.667.387-.043-.075.622-.457a.03.03 0 0 1 .046.008Z"
        fill="#6FAA91"
      />
      <path
        d="m8.953 14.935.208.369c.02.036.078.003.057-.033l-.208-.369c-.02-.037-.077-.004-.057.033ZM8.772 15.087c.06.099.116.198.17.3.02.037.078.004.057-.033a6.438 6.438 0 0 0-.17-.3c-.021-.037-.078-.004-.057.033ZM8.259 15.436l.103.18c.008.015.03.021.045.012.015-.01.022-.03.012-.046l-.103-.18a.034.034 0 0 0-.045-.011c-.015.01-.022.03-.012.045Z"
        fill="#D6702B"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const ShoppinglistsNavIcon16: FC = () => (
  <svg height="16" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.51524 16.5494L9.18857 17.2282L6.21048 20.2301L4.67429 18.6816L5.34762 18.0029L6.21048 18.8726L8.51524 16.5494ZM9.67714 20.2474H18.4219V19.3229H9.67714V20.2474ZM8.51524 12.3197L9.18857 12.9984L6.21048 16.0003L4.67429 14.4509L5.34762 13.7722L6.21048 14.6429L8.51524 12.3197ZM9.67714 16.0166H18.4219V15.0922H9.67714V16.0166ZM8.51524 8.08896L9.18857 8.76864L6.21048 11.7696L4.67429 10.2211L5.34762 9.5424L6.21048 10.4122L8.51524 8.08896ZM9.67714 11.7869H18.4219V10.8624H9.67714V11.7869ZM21.0476 23.04H2.95238V3.36H5.80952V5.76H6.7619V3.36H9.61905V5.76H10.5714V3.36H13.4286V5.76H14.381V3.36H17.2381V5.76H18.1905V3.36H21.0476V23.04ZM18.1905 2.4V0H17.2381V2.4H14.381V0H13.4286V2.4H10.5714V0H9.61905V2.4H6.7619V0H5.80952V2.4H2V24H22V2.4H18.1905Z"
      fill="#484848"
    />
  </svg>
)

export const OrderHistoryNavIcon: FC = () => (
  <svg fill="none" height="36" viewBox="0 0 36 36" width="36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m18.0003 33c8.2111 0 14.8675-6.7157 14.8675-15 0-8.28427-6.6564-15-14.8675-15-8.21107 0-14.86749 6.71573-14.86749 15 0 8.2843 6.65642 15 14.86749 15z"
      fill="#00a651"
    />
    <path
      d="m17.9202 30.8879c7.0008 0 12.6761-5.7698 12.6761-12.8873s-5.6753-12.88732-12.6761-12.88732-12.67606 5.76982-12.67606 12.88732 5.67526 12.8873 12.67606 12.8873z"
      fill="#fff"
    />
    <path
      d="m28.9078 18.2109c0-6.0673-4.9186-10.98588-10.9859-10.98588 0 0 .0008 4.91858.0008 10.98588l-.0008-.1199c6.0673 0 10.7529.1199 10.9859.1199z"
      fill="#f6f6f6"
    />
    <path
      d="m17.9993 19.3849c.7147 0 1.2942-.5846 1.2942-1.3057 0-.7212-.5795-1.3058-1.2942-1.3058-.7148 0-1.2942.5846-1.2942 1.3058 0 .7211.5794 1.3057 1.2942 1.3057z"
      fill="#a5a5a5"
    />
    <rect fill="#979797" height="6.76056" rx=".422535" width=".84507" x="17.498" y="11.4531" />
    <path
      d="m27.3403 17.9546c.0002.2463-.2001.4473-.4474.4489l-8.3669.055c-.2473.0016-.4479-.1967-.4481-.443s.2001-.4472.4474-.4488l8.3669-.055c.2473-.0017.4479.1967.4481.4429z"
      fill="#a5a5a5"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <rect fill="#c4c4c4" height="1.69014" rx=".422535" width=".84507" x="17.5" y="6.80078" />
    <rect fill="#c4c4c4" height="1.69014" rx=".422535" width=".84507" x="17.5" y="27.5039" />
    <g fill="#f1f1f1">
      <rect
        height="1.69014"
        rx=".422535"
        transform="matrix(.8660254 .5 -.5 .8660254 7.147263 -10.494267)"
        width=".84507"
        x="23.1562"
        y="8.08984"
      />
      <rect
        height="1.69014"
        rx=".422535"
        transform="matrix(.8660254 .5 -.5 .8660254 14.727459 -2.916825)"
        width=".84507"
        x="12.8066"
        y="26.0234"
      />
      <rect
        height="1.69014"
        rx=".422535"
        transform="matrix(.5 .8660254 -.8660254 .5 24.151289 -17.76086)"
        width=".84507"
        x="27.457"
        y="12.0352"
      />
      <rect
        height="1.69014"
        rx=".422535"
        transform="matrix(.5 .8660254 -.8660254 .5 24.154498 2.944382)"
        width=".84507"
        x="9.52734"
        y="22.3906"
      />
    </g>
    <rect
      fill="#c4c4c4"
      height="1.69014"
      rx=".422535"
      transform="matrix(0 1 -1 0 46.9063 -11.3281)"
      width=".84507"
      x="29.1172"
      y="17.7891"
    />
    <rect
      fill="#c4c4c4"
      height="1.69014"
      rx=".422535"
      transform="matrix(0 1 -1 0 26.20316 9.37504)"
      width=".84507"
      x="8.41406"
      y="17.7891"
    />
    <rect
      fill="#f1f1f1"
      height="1.69014"
      rx=".422535"
      transform="matrix(-.5 .8660254 -.8660254 -.5 62.051848 11.057149)"
      width=".84507"
      x="27.834"
      y="23.4414"
    />
    <rect
      fill="#f1f1f1"
      height="1.69014"
      rx=".422535"
      transform="matrix(-.5 .8660254 -.8660254 -.5 26.189609 11.059022)"
      width=".84507"
      x="9.90234"
      y="13.0898"
    />
    <rect
      fill="#f1f1f1"
      height="1.69014"
      rx=".422535"
      transform="matrix(-.8660254 .5 -.5 -.8660254 58.438962 39.833527)"
      width=".84507"
      x="23.8828"
      y="27.7461"
    />
    <rect
      fill="#f1f1f1"
      height="1.69014"
      rx=".422535"
      transform="matrix(-.8660254 .5 -.5 -.8660254 30.155813 11.544774)"
      width=".84507"
      x="13.5312"
      y="9.8125"
    />
  </svg>
)
