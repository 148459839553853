// import { useRouter } from 'next/router'
// import { useEffect } from 'react'
// import { openDeliveryModal, openSelectDeliverySlotModal } from 'services/Delivery/cache'

// import { useSelectedDeliveryAreaDetails } from './use-selected-delivery-area-name'
// import { useURLSearchParams } from './use-url-search-params'

export const URL_PARAM_PICKUP = 'nouto'
// const URL_PARAM_PICKUP_TIME = 'noutoaika'

/**
 * Allows opening of slot or store selection based on url params
 */
export const useDeliveryModalOpener = (): void => {
  // const router = useRouter()
  // const urlParams = router.asPath
  // const { removeParams } = useURLSearchParams({ push: false, shallow: false })
  // const selectedDeliveryAreaDetails = useSelectedDeliveryAreaDetails()
  // useEffect(() => {
  //   if (urlParams.includes(URL_PARAM_PICKUP_TIME)) {
  //     // Is user has selected store and it is not fastrack that does not have deliverytimes
  //     if (selectedDeliveryAreaDetails.storeName && !selectedDeliveryAreaDetails.isFastTrack) {
  //       // Open slot selection
  //       openSelectDeliverySlotModal()
  //     } else {
  //       // Otherwise open store selection
  //       openDeliveryModal()
  //     }
  //     removeParams([URL_PARAM_PICKUP_TIME, undefined])
  //   }
  //   if (urlParams.includes(URL_PARAM_PICKUP)) {
  //     openDeliveryModal()
  //   }
  // }, [
  //   selectedDeliveryAreaDetails.isFastTrack,
  //   selectedDeliveryAreaDetails.storeName,
  //   removeParams,
  //   urlParams,
  // ])
}
