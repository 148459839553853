import { DeliveryMethod } from '@shared/gql/document-nodes'
import * as z from 'zod'

/** When bumping this the next time, change it to a simple number, e.g 1. The only purpose of the version is to increment it. */
export const DELIVERY_SCHEMA_VERSION = '1.2.17'

export const DeliverySchema = z.object({
  cacheVersion: z.literal(DELIVERY_SCHEMA_VERSION),
  deliveryData: z.object({
    deliveryDetailsInfo: z
      .object({
        __typename: z.literal('DeliveryDetailsInfo'),
        additionalInfo: z.string().nullable(),
        address: z.string().nullable(),
        city: z.string().nullable(),
        deliveryDate: z.string().nullable(),
        deliveryMethod: z.nativeEnum(DeliveryMethod).nullable(),
        deliveryTime: z.string().nullable(),
        deliverySlotId: z.string().nullable(),
        postalCode: z.string().nullable(),
        deliverySlotPrice: z.number().nonnegative().nullable(),
      })
      .nullable(),
    selectedAreaId: z.string().nullable(),
  }),
})
