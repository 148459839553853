import type { DeliverySlotReservation } from '../types'
import type { RemoteData } from 'domain/remote-data'

import { useQuery } from '@apollo/client'
import { DeliverySlotReservationDocument } from '@shared/gql/document-nodes'
import { useMemo } from 'react'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'

import { DeliverySlotReservationStatus, deliverySlotReservationStatusVar } from '../status'
import { useDeliverySlotReservationId } from './use-delivery-slot-reservation-id'
import { useIsDeliverySlotReservationEnabled } from './use-is-delivery-slot-reservation-enabled'

/** Returns info about the currently reserved delivery slot, if any */
export const useDeliverySlotReservation = (): RemoteData<Error, DeliverySlotReservation> => {
  const isClientSide = useIsClientSide()
  const reservationId = useDeliverySlotReservationId()
  const isDeliverySlotReservationEnabled = useIsDeliverySlotReservationEnabled()

  const queryResult = useQuery(DeliverySlotReservationDocument, {
    onError() {
      deliverySlotReservationStatusVar(DeliverySlotReservationStatus.AlreadyExpired)
    },
    skip: !reservationId || !isDeliverySlotReservationEnabled,
    ssr: false,
    variables: { reservationId: reservationId || '' },
  })

  return useMemo(() => {
    if (!isClientSide) {
      return { type: 'LOADING' }
    }

    if (!reservationId || !queryResult.called) {
      return { type: 'NOT_ASKED' }
    }

    if (queryResult.loading) {
      return { type: 'LOADING' }
    }

    if (queryResult.error || !queryResult.data) {
      return {
        type: 'FAILURE',
        error: queryResult.error ?? new Error('Missing delivery slot reservation'),
      }
    }

    return { type: 'SUCCESS', data: queryResult.data.deliverySlotReservation }
  }, [
    isClientSide,
    queryResult.called,
    queryResult.data,
    queryResult.error,
    queryResult.loading,
    reservationId,
  ])
}
