import type { CurrentUser } from 'domain/current-user'

import { useQuery } from '@apollo/client'
import { GetLocalAuthenticationTokensDocument } from '@shared/gql/document-nodes'
import jwt_decode from 'jwt-decode'
import { useMemo } from 'react'

import { useIsClientSide } from '../use-is-client-side'

export const useGetCurrentProfile = (): CurrentUser | null => {
  const isClientSide = useIsClientSide()
  const { data } = useQuery(GetLocalAuthenticationTokensDocument, { ssr: false })
  const accessToken = data?.authenticationTokens?.accessToken

  return useMemo(() => {
    if (!isClientSide || !accessToken) {
      return null
    }

    try {
      return jwt_decode<CurrentUser>(accessToken)
    } catch {
      return null
    }
  }, [accessToken, isClientSide])
}
