import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'

/** Returns the current full URL without any search parameters */
export const useCanonicalUrl = (): string => {
  const router = useRouter()
  const theme = useTheme()

  return useMemo(() => {
    const path = router.asPath.split(/[?#]/)[0]
    const href = theme?.seoTags?.canonical
    /* Return only base pathname if there's no canonical */
    return href ? new URL(path, href).toString() : path
  }, [router.asPath, theme?.seoTags?.canonical])
}
