import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { clearCart } from 'services/ClientCart/mutations/clear-cart'
import { DaoService } from 'services/LocalStorage'

import { writeInitialData } from './write-initial-data-to-apollo'

export const onApolloClientReset = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  return async (): Promise<void> => {
    DaoService.clearAllData()
    clearCart()
    writeInitialData(apolloClient.cache)
  }
}
