import { makeVar, useReactiveVar } from '@apollo/client'
import jwt_decode from 'jwt-decode'

const LINK_VERIFICATION_TOKEN = 'lvt'

export const useLinkVerificationToken = (): string | null =>
  useReactiveVar(linkVerificationTokenVar)

const filterInvalidJwt = (jwt_string: string | null) => {
  // Backend provided tokens should always work, this is to prevent injections in the app.
  if (jwt_string) {
    try {
      // Decode and check a variable that we know should exist.
      const jwt = jwt_decode<{ nonce: string }>(jwt_string)
      if (jwt.nonce) return jwt_string
    } catch {
      // Give no indication as to why the token is ignored.
    }
  }
  return null
}

export const linkVerificationTokenVar = makeVar<string | null>(
  filterInvalidJwt(
    typeof window !== 'undefined' ? localStorage.getItem(LINK_VERIFICATION_TOKEN) : null,
  ),
)

export const setLinkVerificationToken = (linkVerificationToken: string) => {
  const jwt = filterInvalidJwt(linkVerificationToken)
  if (jwt) localStorage.setItem(LINK_VERIFICATION_TOKEN, jwt)
  else localStorage.removeItem(LINK_VERIFICATION_TOKEN)
  linkVerificationTokenVar(jwt)
}
