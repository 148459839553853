import type { ClientCartItem } from '@shared/gql/document-nodes'
import type { TypedTypePolicies } from 'types/gql/apollo-helpers'

import { Brand } from '@shared/domain/brand'
import { shoppingCartVar } from 'services/ClientCart/cache'
import { DaoService } from 'services/LocalStorage'

import {
  deliverySlotReservation,
  DeliverySlotReservationResponse,
} from './type-policies/delivery-slot-reservation'
import { domainOrder } from './type-policies/domain-order'
import { HierarchyPathItem } from './type-policies/hierarchy-path-item'
import { NavigationItem } from './type-policies/navigation-item'
import { NextDeliverySlot } from './type-policies/next-delivery-slot'
import { Product } from './type-policies/product'
import { ProductList } from './type-policies/product-list'
import { Recipe, RecipeIngredient } from './type-policies/recipe'
import { Store } from './type-policies/store'

export const typePolicies: TypedTypePolicies = {
  DeliveryArea: {
    keyFields: ['areaId'],
  },
  DeliverySlot: {
    keyFields: ['slotId'],
  },
  DeliverySlotReservationResponse,
  FavoritesList: {
    fields: {
      items: {
        merge: false,
      },
    },
  },
  HierarchyPathItem,
  NavigationItem,
  NextDeliverySlot,
  Product,
  ProductList,
  Recipe,
  RecipeIngredient,
  Store,
  Query: {
    fields: {
      deliverySlot: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'DeliverySlot',
            slotId: args?.id,
          })
        },
      },

      domainOrder,
      selectedBrand: {
        read(existing): string | null {
          return existing || Brand.Eprisma
        },
      },
      cartItems: {
        read(): ClientCartItem[] {
          return shoppingCartVar() ?? []
        },
      },
      cartItem: {
        read(existing, { args }): ClientCartItem {
          const currentShoppingCart = shoppingCartVar()
          return currentShoppingCart?.find((item) => item.id === args?.id) || existing
        },
      },
      deliverySlotReservation,
      authenticationRedirectPath: {
        read(): string | null {
          return DaoService.SessionDAO.loadSessionData()?.authRedirectPath || null
        },
      },
      product: {
        /** @see https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects */
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Product',
            id: args?.id,
            /** `Product.storeId: number` while `Store.id: string`... */
            storeId: args?.storeId ? parseInt(args.storeId, 10) : null,
          })
        },
      },
      searchStores: {
        keyArgs: ['brand', 'query'],
      },
    },
  },
  SearchStoresResponse: {
    fields: {
      stores: {
        merge(existing = [], incoming) {
          return [...existing, ...incoming]
        },
      },
    },
  },
  UserOrderCartItem: {
    keyFields: ['id', 'price', 'basicQuantityUnit'],
  },
  FavoriteItem: {
    keyFields: ['ean'],
  },
}
