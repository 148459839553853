import type { ThemedObjectStyleFunction } from 'domain/styled'

import { SDS_FONT_FAMILY_PRIMARY } from '@s-group/design-system-tokens/web/tokens/font.es6'
import { minWidthFromTheme } from '@sok/design-system'
import styled from 'styled-components'
import { LAYOUT_MAX_WIDTH, Z_INDEX } from 'styles/layout'

export const buttonPlacement: ThemedObjectStyleFunction<{ bottomMargin: string }> = ({
  bottomMargin,
  theme,
}) => ({
  bottom: bottomMargin,
  left: `calc((100% - min(100vw, ${LAYOUT_MAX_WIDTH}px)) / 2 + 40px)`,
  position: 'fixed',
  zIndex: Z_INDEX.menu,

  [minWidthFromTheme(theme).desktop]: {
    bottom: theme.spacings.xLarge,
  },
})

export const StyledChatButton = styled.button<{ bottomMargin: string }>(
  buttonPlacement,
  ({ theme }) => ({
    backgroundColor: theme.color.white,
    borderRadius: 500,
    border: 0,
    boxShadow: 'rgba(36, 34, 54, 0.2) 0px 2px 6px 0px',
    boxSizing: 'border-box',
    color: theme.color.actionVariant,
    cursor: 'pointer',
    fontFamily: `${SDS_FONT_FAMILY_PRIMARY}, 'Roboto', Arial, sans-serif`,
    fontSize: 14,
    fontWeight: 500,
    height: 40,
    lineHeight: 1,
    margin: 0,
    minWidth: 80,
    paddingLeft: 16,
    paddingRight: 16,

    '&:focus': {
      outline: 0,
      backgroundColor: theme.color.white,
    },
  }),
)

export const StyledSpan = styled.span`
  padding-bottom: 1px;
  font-size: 0.625rem;
  padding-left: 1.5px;
`
