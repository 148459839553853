import type { DeliveryDetailsInfo } from '@shared/gql/document-nodes'
import type { Slot } from 'domain/delivery-details'
import type { HTMLMotionProps, PanInfo } from 'framer-motion'
import type { FC } from 'react'

import { Button } from '@s-group/design-system-components'
import { IconNavigationChevronLeft, IconNavigationChevronRight } from '@s-group/design-system-icons'
import { BrandStoreLogo } from 'components/BrandStoreLogo'
import { animate, motion, useMotionValue, useMotionValueEvent } from 'framer-motion'
import { DateTime } from 'luxon'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css, useTheme } from 'styled-components'
import { useRunOnce } from 'utils/hooks/use-run-once'

import { type CalendarDays, getFirstDateWithSlots } from './calendar'
import { StyledNoSlots, StyledSlot } from './Slot'

interface DeliveryTimeSlotSelectorProps {
  className?: string
  cartContainsAlcohol: boolean
  loading: boolean
  calendar: CalendarDays
  deliveryDetailsInfo: DeliveryDetailsInfo
  onSlotSelect: (slot: Slot) => void
  selectedSlotDate?: string
  selectedSlotId?: string
}

// Must be a better way to extend props of motion.divs?
type DayProps = Pick<HTMLMotionProps<'div'>, 'drag' | 'onDragEnd' | 'dragElastic' | 'style'> & {
  className?: string
  hasSlots: boolean
  isoDate: string
  containsSelectedSlot: boolean
  isSelected: boolean
  onSelect: (date: DateTime, offset: number) => void
  onFocus: (date: DateTime, offset: number) => void
}

const _Day: FC<DayProps> = ({
  className,
  isoDate,
  containsSelectedSlot,
  isSelected,
  onSelect,
  hasSlots,
  onFocus,
  ...framerProps
}) => {
  const date = DateTime.fromISO(isoDate, { locale: 'fi' })
  const dayElement = useRef<HTMLDivElement>(null)
  const classes = [
    className,
    `day-${date.weekday}`,
    containsSelectedSlot ? 'contains-selected' : '',
    isSelected ? 'selected' : '',
    hasSlots ? 'has-slots' : '',
  ].join(' ')
  useRunOnce(() => {
    // Implements initial scrolling to the slot which has the selected slot.
    if (isSelected) {
      onSelect(date, dayElement.current?.offsetLeft || 0)
    }
  })
  return (
    <motion.div
      ref={dayElement}
      className={classes}
      {...framerProps}
      data-test-id={'date-selector'}
      data-has-slots={hasSlots}
      data-is-selected={isSelected}
      data-date={isoDate}
    >
      <h6>{date.weekdayShort}</h6>
      <div>
        <input
          id={`select-${isoDate}`}
          type="radio"
          checked={isSelected}
          onChange={() => onSelect(date, dayElement.current?.offsetLeft || 0)}
          onFocus={() => onFocus(date, dayElement.current?.offsetLeft || 0)}
          value={isoDate}
        />
        <label htmlFor={`select-${isoDate}`}>{date.day}</label>
      </div>
    </motion.div>
  )
}

const toFormattedRelativeDate = (date: DateTime) => {
  const diffDays = Math.ceil(date.diffNow('days').days)
  return `${diffDays < 2 ? date.toRelativeCalendar() : date.weekdayLong} ${date.toFormat('d.M.')}`
}

const _DeliveryTimeSlotSelector = ({
  className,
  calendar,
  onSlotSelect,
  selectedSlotId,
  selectedSlotDate,
  cartContainsAlcohol,
}: DeliveryTimeSlotSelectorProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const firstDateWithSlots = getFirstDateWithSlots(calendar)
  const [selectedDate, setSelectedDateValue] = useState(selectedSlotDate || firstDateWithSlots)

  const scrollPosition = useMotionValue(0)
  const viewAreaRef = useRef<HTMLDivElement>(null)
  const listEndMarker = useRef<HTMLSpanElement>(null)
  const selectedDayAsDateTime = DateTime.fromISO(selectedDate, { locale: 'fi' }).startOf('day')
  const selectedDayAsString = toFormattedRelativeDate(selectedDayAsDateTime)

  const scrollTo = (position: number, duration = 0.3) => {
    const viewAreaWidth = viewAreaRef.current?.clientWidth || 0
    // NOTE: viewAreaRef.current?.scrollWidth has issues with scrolled elements in Safari.
    // Here we are explicitly getting the scrollable area width with a special element.
    const scrollableAreaWidth = listEndMarker.current?.offsetLeft || 0
    const maxScrollableArea = -(scrollableAreaWidth - viewAreaWidth)
    const newPosition = Math.ceil(Math.min(0, Math.max(maxScrollableArea, position)))
    viewAreaRef.current?.scrollTo({ left: 0 })
    animate(scrollPosition, newPosition, {
      type: 'tween',
      duration,
    })
  }

  const [shownPage, setShownPage] = useState<'FIRST' | 'MIDDLE' | 'LAST'>('FIRST')

  useMotionValueEvent(scrollPosition, 'animationComplete', () => {
    const viewAreaWidth = viewAreaRef.current?.clientWidth || 0
    const scrollableAreaWidth = listEndMarker.current?.offsetLeft || 0
    const maxScrollableArea = -(scrollableAreaWidth - viewAreaWidth)
    if (scrollPosition.get() === 0) {
      setShownPage('FIRST')
    } else if (scrollPosition.get() <= maxScrollableArea) {
      setShownPage('LAST')
    } else if (shownPage !== 'MIDDLE') {
      setShownPage('MIDDLE')
    }
  })

  const setSelectedDate = (dayToSelect: DateTime, offset: number) => {
    const dateAsISO = dayToSelect.toISODate()
    if (dateAsISO) {
      setSelectedDateValue(dateAsISO)
      focusSelectedDate(dayToSelect, offset)
    }
  }

  const focusSelectedDate = (_dayToSelect: DateTime, offset: number) => {
    const viewAreaWidth = viewAreaRef.current?.clientWidth || 0
    const page = Math.floor(offset / viewAreaWidth)
    scrollTo(-page * viewAreaWidth)
  }

  const scrollToPreviousWeek = () => {
    const viewAreaWidth = viewAreaRef.current?.clientWidth || 0
    scrollTo(scrollPosition.get() + viewAreaWidth)
  }

  const scrollToNextWeek = () => {
    const viewAreaWidth = viewAreaRef.current?.clientWidth || 0
    scrollTo(scrollPosition.get() - viewAreaWidth)
  }

  const resetScroll = () => scrollTo(scrollPosition.get())

  const handleEndDrag = (_e: Event, dragProps: PanInfo) => {
    const clientWidth = viewAreaRef.current?.clientWidth || 0
    const { offset } = dragProps
    if (offset.x > clientWidth / 5) {
      scrollToPreviousWeek()
    } else if (offset.x < -clientWidth / 5) {
      scrollToNextWeek()
    } else {
      resetScroll()
    }
  }

  const selectedDateHasSlots = calendar[selectedDate]?.length > 0

  return (
    <div className={className} data-test-id="delivery-time-slot-selector">
      <StyledHeader>
        <h4>{selectedDayAsString}</h4>
        <Button
          disabled={shownPage === 'FIRST'}
          className="prev"
          rounding="small"
          data-test-id="date-with-pagination-prev"
          onClick={scrollToPreviousWeek}
        >
          <IconNavigationChevronLeft color={theme.colors.primary800} />
        </Button>
        <StyledWeekScroller ref={viewAreaRef}>
          {Object.keys(calendar).map((date) => (
            <Day
              // vv Motion vv
              drag="x"
              onDragEnd={handleEndDrag}
              dragElastic={0.3}
              key={`date-${date}`}
              style={{
                x: scrollPosition,
              }}
              // ^^ Motion ^^
              hasSlots={calendar[date].length > 0}
              containsSelectedSlot={date === selectedSlotDate}
              isoDate={date}
              isSelected={date === selectedDate}
              onSelect={setSelectedDate}
              onFocus={focusSelectedDate}
            />
          ))}
          <span className="listEnd" ref={listEndMarker} />
        </StyledWeekScroller>
        <Button
          disabled={shownPage === 'LAST'}
          className="next"
          rounding="small"
          data-test-id="date-with-pagination-next"
          onClick={scrollToNextWeek}
        >
          <IconNavigationChevronRight size="24" color={theme.colors.primary800} />
        </Button>
      </StyledHeader>

      <StyledSlots data-test-id="slots-container" data-slot-date={selectedDate}>
        {selectedDateHasSlots ? (
          calendar[selectedDate].map((store) => (
            <div key={store.name}>
              <StyledStoreHeader>
                {store.brand && <BrandStoreLogo brand={store.brand} alt={store.name} />}
                <h5>{store.name}</h5>
              </StyledStoreHeader>
              <StyledSlotList>
                {store.slots.map((slot) => (
                  <StyledSlot
                    key={`slot-${slot.slotId}`}
                    slot={slot}
                    isSelected={selectedSlotId === slot.slotId}
                    cartContainsAlcohol={cartContainsAlcohol}
                    onSlotSelect={(selected) => onSlotSelect(selected.slotData)}
                  />
                ))}
              </StyledSlotList>
            </div>
          ))
        ) : (
          <StyledNoSlots>{t('Not available')}</StyledNoSlots>
        )}
      </StyledSlots>
    </div>
  )
}

export const DeliveryTimeSlotSelector = styled(_DeliveryTimeSlotSelector)(
  ({ theme }) => css`
    user-select: none;
    overflow-y: hidden;
    display: grid;
    height: 100%;
    grid-template-rows: auto auto 1fr;

    @media (max-width: ${theme.breakpoints.tablet}) {
      max-width: 440px;
      margin-left: auto;
      margin-right: auto;
    }

    .listEnd {
      width: 0;
      height: 0;
    }
  `,
)

const StyledHeader = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-areas:
      'day day day'
      'prev calendar next';
    grid-template-columns: auto 1fr auto;

    justify-content: center;
    align-items: center;
    padding: 0;

    h4 {
      grid-area: day;
      color: ${theme.colors.SDS_BRAND_COLOR_TEXT_STRONGER_GREY};
      font-weight: ${theme.fontWeights.medium};
      text-align: center;
      padding: 1rem 0;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    .prev {
      grid-area: prev;
    }

    .next {
      grid-area: next;
    }

    button {
      margin: 0.4rem;
      border: none;
      height: 100%;
      min-width: 24px;
      max-width: 24px;
      padding: 0;
    }
  `,
)

const StyledWeekScroller = styled.div`
  grid-area: calendar;
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  overflow-x: hidden;
  left: 0;
  top: 0;
  background: white;
`

const StyledSlots = styled.div`
  overflow-y: auto;
`

const StyledSlotList = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledStoreHeader = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    min-height: 32px;
    justify-content: flex-start;
    align-items: center;
    > * {
      padding-right: ${theme.spacings.xSmall};
    }
    h5 {
      color: ${theme.colors.grey700};
      font-weight: ${theme.fontWeights.regular};
    }
    max-height: 2rem;
    overflow: hidden;
  `,
)

const Day = styled(_Day)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    min-width: calc(100% / 5);
    height: 74px;

    div {
      label,
      input {
        width: 32px;
        height: 32px;
      }
    }

    @media (min-width: ${theme.breakpoints.smallMobile}) {
      min-width: calc(100% / 6);
    }

    @media (min-width: ${theme.breakpoints.mobile}) {
      min-width: calc(100% / 7);
      div {
        label,
        input {
          width: 40px;
          height: 40px;
        }
      }
    }

    &.contains-selected::after {
      content: '';
      background: ${theme.colors.SDS_BRAND_COLOR_BACKGROUND_STRONG_PRIMARY};
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      position: absolute;
      bottom: 0px;
    }

    h6 {
      font-weight: ${theme.fontWeights.medium};
      &:first-letter {
        text-transform: uppercase;
      }
    }

    &.day-6,
    &.day-7 {
      h6 {
        font-weight: ${theme.fontWeights.regular};
        color: ${theme.colors.SDS_BRAND_COLOR_TEXT_STRONG_GREY};
      }
    }

    div {
      position: relative;
      height: 100%;

      input {
        display: absolute;
        left: 0;
        bottom: 10px;
        opacity: 0;
      }

      label {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        bottom: 10px;
        cursor: pointer;
        border-radius: 50%;
        color: ${theme.colors.SDS_BRAND_COLOR_TEXT_STRONGER_GREY};
        background-color: ${theme.colors.white};
        border: 1px solid ${theme.colors.SDS_BRAND_COLOR_BORDER_WEAK_GREY};
      }
    }

    &.has-slots label {
      border: none;
      background-color: ${theme.colors.SDS_BRAND_COLOR_BACKGROUND_WEAK_PRIMARY};
    }

    &.selected {
      label {
        border: none;
        font-weight: ${theme.fontWeights.medium};
        color: ${theme.colors.SDS_BRAND_COLOR_TEXT_INVERSE_GREY};
        background-color: ${theme.colors.primary800};
      }

      h6 {
        font-weight: ${theme.fontWeights.bold};
        color: ${theme.colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY};
      }
    }

    div {
      input:focus + label {
        box-shadow: 0 0 0 0.125rem #ffffff;
        outline: 0.125rem solid ${theme.colors.SDS_BRAND_COLOR_BORDER_STRONG_PRIMARY};
        outline-offset: 0.125rem;
      }
    }
  `,
)
