import { CartDAO, CustomerDAO, DeliveryDAO, PaymentDAO, SessionDAO, StoreDAO } from './dao'

const clearAllData = (): void => {
  CartDAO.reset()
  CustomerDAO.reset()
  DeliveryDAO.reset()
  SessionDAO.reset()
  StoreDAO.reset()
  PaymentDAO.reset()
}

export const DaoService = {
  CartDAO,
  DeliveryDAO,
  SessionDAO,
  StoreDAO,
  CustomerDAO,
  PaymentDAO,
  clearAllData,
}
