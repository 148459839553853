import type { FC, ReactNode } from 'react'

import { createContext } from 'react'

import { RefreshTokenExpiredModal } from './RefreshTokenExpiredModal'

const AccountContext = createContext(null)

export const AccountContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <AccountContext.Provider value={null}>
      <RefreshTokenExpiredModal />
      {children}
    </AccountContext.Provider>
  )
}
