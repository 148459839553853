import type { ReactiveVar } from '@apollo/client'

import { makeVar } from '@apollo/client'
import { DeliveryMethod } from '@shared/gql/document-nodes'
import { DeliverySelectionStage } from 'types/global'

export const pendingDeliveryMethodVar = makeVar<DeliveryMethod>(DeliveryMethod.Pickup)

/**
 * When Delivery selection modal is opened with a pending EAN,
 * the UI should offer to display only stores that carry the product.
 * This is done from the Product page.
 */
export const pendingProductEanVar = makeVar<string | null>(null)
export const storeAvailabilityFilterVar = makeVar(true)

export const geolocationEnabledVar = makeVar(false)
export const geolocationLoadingVar = makeVar(false)
export const geolocationPositionVar = makeVar<GeolocationPosition | null>(null)
export const geolocationErrorVar = makeVar<GeolocationPositionError | null>(null)

type DeliveryModalState = {
  open: boolean
  stage: DeliverySelectionStage
}

export const deliveryModalStateVar: ReactiveVar<DeliveryModalState> = makeVar<DeliveryModalState>({
  open: false,
  stage: DeliverySelectionStage.SELECT_METHOD_AND_STORE,
})

const setDeliveryModalState = (stateChanges: Partial<DeliveryModalState>) =>
  deliveryModalStateVar({ ...deliveryModalStateVar(), ...stateChanges })

export const openDeliveryModal = (initialStage?: DeliverySelectionStage) =>
  initialStage !== undefined
    ? setDeliveryModalState({ open: true, stage: initialStage })
    : setDeliveryModalState({ open: true })

export const setDeliveryModalStage = (newStage: DeliverySelectionStage) =>
  setDeliveryModalState({ stage: newStage })

export const closeDeliveryModal = () => setDeliveryModalState({ open: false })

export const openSelectDeliveryMethodAndStoreModal = () =>
  openDeliveryModal(DeliverySelectionStage.SELECT_METHOD_AND_STORE)

export const openSelectDeliverySlotModal = () =>
  openDeliveryModal(DeliverySelectionStage.SELECT_DELIVERY_TIME)

export const openTimeAlcoholConflictModal = () =>
  openDeliveryModal(DeliverySelectionStage.SELECT_DELIVERY_TIME_ALCOHOL_CONFLICT)
