import type { ApolloCache, NormalizedCacheObject } from '@apollo/client'
import type { GetInitialDataQuery } from '@shared/gql/document-nodes'

import { GetInitialDataDocument } from '@shared/gql/document-nodes'
import { getInitialData } from 'services/App/initial-data'

interface NormalizedInitialDataState extends NormalizedCacheObject {
  ROOT_QUERY: Partial<GetInitialDataQuery>
}

export const isInitialData = (
  state?: NormalizedCacheObject | null,
): state is NormalizedInitialDataState => state?.ROOT_QUERY?.__typename === 'Query'

export const writeInitialData = (
  cache: ApolloCache<NormalizedCacheObject>,
  initialState?: NormalizedCacheObject | null,
): void => {
  const initial = isInitialData(initialState) ? initialState.ROOT_QUERY : undefined
  cache.writeQuery({ query: GetInitialDataDocument, data: getInitialData(initial) })
}
