import type { Dispatch, FC, SetStateAction } from 'react'

import { Button } from '@s-group/design-system-components'
import { LocateFilled24, LocateOutline24 } from '@sok/design-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface GeolocationPromptProps {
  className?: string
  onClose: () => void
  onToggleEnabled: Dispatch<SetStateAction<boolean>>
}

const _GeolocationPrompt: FC<GeolocationPromptProps> = ({
  className,
  onClose,
  onToggleEnabled,
}) => {
  const { t } = useTranslation()

  const handleClick = () => {
    onToggleEnabled((current) => !current)
    onClose()
  }

  return (
    <div className={className} data-test-id="geolocation-prompt">
      <LocateFilled24 />

      <p>{t`Geolocation legal`}</p>

      <Button data-test-id="confirm" onClick={handleClick} primary sizing="small" rounding="small">
        {t`Use Geolocation`}
      </Button>
      <Button data-test-id="cancel" onClick={onClose} sizing="small" rounding="small">
        {t('Cancel')}
      </Button>
    </div>
  )
}

export const GeolocationPrompt = styled(_GeolocationPrompt)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  svg: {
    color: theme.color.action,
    marginBottom: theme.spacings.medium,
  },

  p: {
    fontSize: 'small',
    width: '90%' /** When the legal wall of text looks just right */,
  },

  button: {
    marginTop: theme.spacings.small,

    '& + &': {
      marginTop: theme.spacings.xxxSmall,
    },
  },
}))

interface GeolocationButtonProps {
  className?: string
  enabled: boolean
  onClick: () => void
}

const _GeolocationButton: FC<GeolocationButtonProps> = ({ className, enabled, onClick }) => {
  const { t } = useTranslation()

  return (
    <Button
      aria-pressed={enabled ? 'true' : 'false'}
      aria-label={t`Use Geolocation`}
      className={className}
      data-test-id="geolocation-prompt-toggle"
      onClick={onClick}
      title={t`Use Geolocation`}
      icon={enabled ? <LocateFilled24 /> : <LocateOutline24 />}
      plain
      sizing="small"
    ></Button>
  )
}

export const GeolocationButton = styled(_GeolocationButton)(({ theme }) => ({
  svg: {
    /** icon looks slightly off */
    transform: 'translate(-1px, 1px)',
  },

  '&[aria-pressed="true"]': {
    color: theme.color.action,
  },
}))
