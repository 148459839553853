import type { GetServerSidePropsContext } from 'next'

import { IS_BROWSER } from 'utils/is-browser'

/** Get the current User-Agent, optionally from the Next.js server-side context */
export const getUserAgent = (context?: GetServerSidePropsContext): string | undefined =>
  (IS_BROWSER && window.navigator.userAgent) || context?.req?.headers['user-agent']

/** True if the current User-Agent is an older version of Internet Explorer */
export const isOlderIE = (userAgent?: string): boolean =>
  !!userAgent && /(MSIE|Trident\/)/.test(userAgent)
