import type { SO_PartialListItem } from '@shared/domain/s-ostoslista'
import type {
  ClientCartItem,
  GetProductInfoByEansQuery,
  GetProductInfoByEansQueryVariables,
} from '@shared/gql/document-nodes'

import { useApolloClient, useReactiveVar } from '@apollo/client'
import { GetProductInfoByEansDocument } from '@shared/gql/document-nodes'
import { mapProductToCartItem, mergeCartItems } from 'domain/map-products-to-cart-items'
import { shoppingCartVar } from 'services/ClientCart/cache'
import { useSetCartItems } from 'services/ClientCart/mutations/set-cart-items'
import { useAlcoholAllowedForDeliveryArea } from 'utils/hooks/delivery/use-alcohol-allowed'
import { isNonNullable } from 'utils/nullable'

export const useAddListToCartQuery = () => {
  const getProductData = useGetProductList()
  const existingShoppingCart = useReactiveVar(shoppingCartVar)
  const setCartItems = useSetCartItems()

  return async (storeId: string, list: SO_PartialListItem[]) => {
    const eans = list.map((item) => item.sokId)
    const products = await getProductData(eans, storeId)

    const cartItems = (products.items || [])
      .filter(isNonNullable)
      .map((x) => ({
        ...x,
        campaignPrice: null,
        lowest30DayPrice: null,
        campaignPriceValidUntil: null,
      }))
      .map(mapProductToCartItem)
    const newCartItems = margeCartItemsWithShoppingListItems(cartItems, list)
    const compiledItems = mergeCartItems(existingShoppingCart, newCartItems)

    const createUndoFunc = (addedCartItems: ClientCartItem[]) => () => {
      setCartItems(
        mergeCartItems(
          shoppingCartVar(),
          addedCartItems.map(({ itemCount, ...rest }) => ({
            itemCount: -itemCount,
            ...rest,
          })),
        ),
      )
    }

    return setCartItems(compiledItems).then(() => createUndoFunc(newCartItems))
  }
}

type ProductType = NonNullable<
  NonNullable<NonNullable<GetProductInfoByEansQuery['store']>['products']>
>

export const useGetProductList = () => {
  const apolloClient = useApolloClient()
  const alcoholSellingAllowed = useAlcoholAllowedForDeliveryArea()
  return async (eans: string[], storeId: string): Promise<ProductType> => {
    const query = await apolloClient
      .query<GetProductInfoByEansQuery, GetProductInfoByEansQueryVariables>({
        query: GetProductInfoByEansDocument,
        variables: {
          availabilityDate: null,
          eans,
          fallbackToGlobal: true,
          id: storeId,
          includeAgeLimitedByAlcohol: !!alcoholSellingAllowed,
          includeAvailabilities: false /** cart will load these separately */,
        },
      })
      .then((data) => data.data.store?.products)

    if (!query) {
      throw new Error('Fetching product data failed')
    }

    return query
  }
}

const margeCartItemsWithShoppingListItems = (a: ClientCartItem[], b: SO_PartialListItem[]) =>
  b
    .map((listItem) => {
      const cartItem = a.find((x) => x.ean === listItem.sokId)
      if (cartItem) {
        return mapShoppingListItemWithCartItem(cartItem, listItem)
      }
      return null
    })
    .filter(isNonNullable)

const mapShoppingListItemWithCartItem = (
  a: ClientCartItem,
  b: SO_PartialListItem,
): ClientCartItem => ({
  ...a,
  additionalInfo: b.commentForPicker,
  replace: b.isReplaceable,
  itemCount: b.quantity,
  campaignPrice: null,
  lowest30DayPrice: null,
  campaignPriceValidUntil: null,
})
