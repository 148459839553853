import type { Experiments } from './types'
import type { DateTime } from 'luxon'

import Cookie from 'cookie'

const EXPERIMENT_COOKIE_PREFIX = 's-kaupat-exp'

export const getPersistedUserExperiments = (cookieString: string): Experiments => {
  const cookies = Cookie.parse(cookieString)

  return Object.entries(cookies).reduce((acc, [name, value]) => {
    if (name.startsWith(EXPERIMENT_COOKIE_PREFIX)) {
      const experiment = name.replace(`${EXPERIMENT_COOKIE_PREFIX}-`, '')
      return { ...acc, [experiment]: { variant: value } }
    }

    return acc
  }, {})
}

export const persistUserExperiment = (
  experiment: string,
  variant: number | null,
  expirationDate: DateTime,
) => {
  document.cookie = Cookie.serialize(`${EXPERIMENT_COOKIE_PREFIX}-${experiment}`, String(variant), {
    expires: expirationDate.toJSDate(),
    path: '/',
    sameSite: 'lax',
  })
}
