import type { ReactNode } from 'react'

import { makeVar } from '@apollo/client'
import { v4 as uuid4 } from 'uuid'

export interface Modal<T, K = void> {
  id: string
  modal: ReactNode
  onSubmit: (x: T) => K
  onCancel: () => void
  close: () => void
}

// we do not know the payload type yet, thus its unknown
// how to type better?
const modalsVar = makeVar<Modal<unknown>[]>([])

const close = (id: string) => {
  const state = modalsVar()
  modalsVar([...state.filter((modal) => modal.id !== id)])
}

type OpenOptions<T, K> = {
  id?: string
  onCancel?: () => void
  onSubmit?: (value: T) => K
}

const open = <T, K = void>(modal: ReactNode, options: OpenOptions<T, K> = {}) => {
  const id = options.id ?? uuid4()
  const state = modalsVar()

  /** Do not re-open same Modal */
  if (state.some((x) => x.id === id)) return id

  modalsVar([
    ...state,
    {
      id,
      modal,
      onSubmit: (x: T) => {
        close(id)
        options.onSubmit?.(x)
      },
      onCancel: () => {
        close(id)
        options.onCancel?.()
      },
      close: () => close(id),
    },
  ] as Modal<unknown>[]) // cast because of reasons

  return id
}

export const ModalService = {
  state: modalsVar,
  open,
  close,
}
