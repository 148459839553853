import type { IconName } from './icons/icon-name'

import { makeVar, useReactiveVar } from '@apollo/client'
import { type FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { setMainAppInteractivity } from 'services/set-main-app-interactivity'
import styled, { css, keyframes, useTheme } from 'styled-components'

import { Icon } from './icons'

const FullscreenLoaderVar = makeVar<{ iconName: IconName | null; visible: boolean }>({
  iconName: null,
  visible: false,
})

/**
 * display full screen loader to disable ui while something crucial is going on
 * for example updating payment method for order
 */
export const useFullscreenLoader = () => {
  const { visible, iconName } = useReactiveVar(FullscreenLoaderVar)

  useEffect(() => {
    setMainAppInteractivity(!visible)
  }, [visible])

  return {
    show: (name: IconName) => {
      FullscreenLoaderVar({ iconName: name, visible: true })
    },
    hide: () => {
      FullscreenLoaderVar({ iconName: null, visible: false })
    },
    visible,
    iconName,
  }
}

const _FullscreenLoader: FC<{ className?: string }> = ({ className }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { visible, iconName } = useFullscreenLoader()

  if (!visible || !iconName) {
    return null
  }

  return (
    <div className={className}>
      <LoadingIcon name={iconName} width={64} fillColor={theme.color.white} />
      <h2>{t('Please wait')}</h2>
    </div>
  )
}

const loadingBubble = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5 }
`

const animation = css`
  animation: ${loadingBubble} 1s infinite ease-in-out both;
`

const LoadingIcon = styled(Icon)({}, animation)

export const FullscreenLoader = styled(_FullscreenLoader)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.colors.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  zIndex: 10, // because of reasons when eg. links have z-index on the order page

  h2: {
    ...theme.sizes.m,
    fontWeight: theme.fontWeights.light,
    marginTop: theme.spacings.medium,
    color: theme.color.primaryBrand,
  },
  [Icon]: {
    alignItems: 'center',
    backgroundColor: theme.color.primaryBrand,
    borderRadius: '50%',
    display: 'flex',
    flexShrink: 0,
    height: 64,
    justifyContent: 'center',
    width: 64,
  },
}))
