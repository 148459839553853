import type { EuroCents } from '@shared/domain/euro-cents'
import type { ClientCartItem } from '@shared/gql/document-nodes'

import { useReactiveVar } from '@apollo/client'
import { getCartItemsTotal } from 'domain/get-cart-items-total'
import { pipe } from 'fp-ts/function'
import { shoppingCartVar } from 'services/ClientCart/cache'
import { filterPurchaseableCartItems } from 'utils/cart/filter-missing-items'

export const useGetCartTotal = (): EuroCents => {
  const cartItems = useReactiveVar(shoppingCartVar)
  return pipe(cartItems, filterPurchaseableCartItems, getCartItemsTotal)
}

export const useGetCartAlcoholItems = (): ClientCartItem[] => {
  const cartItems = useReactiveVar(shoppingCartVar)
  return cartItems.filter((cartItem) => cartItem.isAgeLimitedByAlcohol)
}
