export const PERSONALLY_IDENTIFIABLE_FIELDS = [
  'accessToken',
  'additionalInfo',
  'address',
  'bonusCard',
  'city',
  'comment',
  'companyName',
  'contact',
  'contactEmail',
  'contactPhoneNumber',
  'email',
  'firstName',
  'idToken',
  'invoiceAddress',
  'invoiceCity',
  'invoiceCompanyName',
  'invoiceFirstName',
  'invoiceLastName',
  'invoiceNumber',
  'invoicePhone',
  'invoicePostalCode',
  'lastName',
  'membershipNumber',
  'orderNumber',
  'phone',
  'phoneNumber',
  'postalCode',
  'primaryEmail',
  'primaryPhoneNumber',
  'refreshToken',
  'sMembershipCardLastName',
  'sMembershipCardNumber',
  'sMembershipCardPostalCode',
  'sMembershipNumber',
  'stockmannCardNumber',
  'userId',
]
