import { useMutation } from '@apollo/client'
import {
  DeliverySlotReservationDocument,
  RefreshDeliverySlotReservationDocument,
} from '@shared/gql/document-nodes'
import { deliverySlotReservation } from 'lib/apollo/type-policies/delivery-slot-reservation'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useClientCartTotalCount } from 'services/ClientCart/hooks/use-client-cart-total-count'
import { useIsSessionActive } from 'utils/hooks/use-is-session-active'

import { DeliverySlotReservationStatus, deliverySlotReservationStatusVar } from '../status'
import { trackDeliverySlotRefreshed } from '../tracking'
import { useDeliverySlotReservation } from './use-delivery-slot-reservation'

// 5m threshold when refreshing slots
export const REFRESH_THRESHOLD = 5 * 60 * 1000

/**
 * Given a reserved delivery slot, refresh its expiration time
 * when the user is active and has items in their cart.
 */
export const useRefreshDeliverySlotReservation = () => {
  const isSessionActive = useIsSessionActive()

  const hasItemsInCart = useClientCartTotalCount() > 0

  const remoteReservation = useDeliverySlotReservation()

  const timeout = useRef<number>()
  const [shouldRefresh, setShouldRefresh] = useState(false)

  /**
   * Create a timeout from slot reservation expiry timestamp,
   * so that it can be refreshed before expiration. This
   * only toggles the `shouldRefresh` state for the next effect.
   */
  useEffect(() => {
    if (remoteReservation.type === 'SUCCESS') {
      window.clearTimeout(timeout.current)

      /** Token should be refreshed if expiriation time is less the REFRESH_THRESHOLD time */
      const expiresIn = new Date(remoteReservation.data.expiresAt).getTime() - new Date().getTime()
      const time = Math.max(0, expiresIn - REFRESH_THRESHOLD)

      timeout.current = window.setTimeout(() => {
        setShouldRefresh(true)
      }, time)
    } else {
      window.clearTimeout(timeout.current)
      timeout.current = undefined
    }

    return () => {
      window.clearTimeout(timeout.current)
      timeout.current = undefined
    }
  }, [remoteReservation])

  const [refreshDeliverySlotReservation] = useMutation(RefreshDeliverySlotReservationDocument)

  const triggerRefresh = useCallback(
    async (isAutomatic: boolean) => {
      setShouldRefresh(false)

      if (remoteReservation.type === 'SUCCESS') {
        const reservationId = remoteReservation.data.reservationId
        await refreshDeliverySlotReservation({
          variables: { reservationId },
          onCompleted: () => {
            trackDeliverySlotRefreshed(isAutomatic)
            deliverySlotReservationStatusVar(DeliverySlotReservationStatus.OK)
          },
          onError: () => {
            deliverySlotReservationStatusVar(DeliverySlotReservationStatus.AlreadyExpired)
          },
          /**
           * After refreshing the reservation, trigger refetch of reservation status
           * so that the `useDeliverySlotReservation` returns updated data.
           *
           * @warn This *intentionally* creates an infinite loop
           */
          refetchQueries: [
            { query: DeliverySlotReservationDocument, variables: { reservationId } },
          ],
        })
      }
    },
    [refreshDeliverySlotReservation, remoteReservation],
  )

  useEffect(() => {
    if (!deliverySlotReservation || remoteReservation.type === 'FAILURE' || !shouldRefresh) return

    if (isSessionActive && hasItemsInCart) {
      /**
       * Refresh slot reservation if:
       *   - user session is active
       *   - user has items in cart
       */
      triggerRefresh(true)
    } else {
      deliverySlotReservationStatusVar(DeliverySlotReservationStatus.AboutToExpire)
    }
  }, [hasItemsInCart, isSessionActive, remoteReservation.type, shouldRefresh, triggerRefresh])

  return triggerRefresh
}
