import styled from 'styled-components'
import { Z_INDEX } from 'styles/layout'

const SNACKBAR_PORTAL_CLASSNAME = 'sok-snackbars'

export const SnackbarPortal = styled.div.attrs({
  className: SNACKBAR_PORTAL_CLASSNAME,
  ['data-test-id']: SNACKBAR_PORTAL_CLASSNAME,
})`
  position: fixed;
  bottom: ${({ theme }) => theme.spacings.small};
  left: ${({ theme }) => theme.spacings.small};
  z-index: ${Z_INDEX.snackbar};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    right: ${({ theme }) => theme.spacings.small};
  }
`

export const getSnackbarPortal = (isClientSide: boolean): Element | null => {
  if (!isClientSide) return null
  return document.getElementsByClassName(SNACKBAR_PORTAL_CLASSNAME)[0]
}
