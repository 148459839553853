import type { FC } from 'react'

import { BORDER_RADIUS } from 'components/Button/border-radius'
import { SkeletonLoader } from 'components/SkeletonLoader'
import styled from 'styled-components'

const NUMBER_OF_PLACEHOLDERS = 4

const _DeliveryOptionsLoading: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={className}>
      {new Array(NUMBER_OF_PLACEHOLDERS).fill(null).map((_, index) => (
        <SkeletonLoader
          key={index}
          height={48 /** delivery option height */}
          style={BORDER_RADIUS}
        />
      ))}
    </div>
  )
}

export const DeliveryOptionsLoading = styled(_DeliveryOptionsLoading)({
  /** These styles are pretty "artesan" but have been adjusted so that it looks good */
  '> div + div': { marginTop: 36 },
  '> div:first-child': { marginTop: 24 },
})
