import type { TypedTypePolicies } from 'types/gql/apollo-helpers'

type StorePolicy = NonNullable<TypedTypePolicies['Store']>

/** Merge new Products into a Store when searching */
export const Store: StorePolicy = {
  keyFields: ['id'],
  fields: {
    mandatoryProducts: {
      /** These are specific to the current store, no need to merge when changing store */
      merge: false,
    },
    products: {
      /**
       * All variables that should form the cache-key.
       * Notably the `from` and `limit` are not included,
       * because they only control pagination of the "same" search.
       */
      keyArgs: [
        'eans',
        'facets',
        'filters',
        'generatedSessionId',
        'includeAgeLimitedByAlcohol',
        'loop54DirectSearch',
        'order',
        'orderBy',
        'queryString',
        'searchProvider',
        'slug',
        'storeId',
        'useRandomId',
      ],
      merge: true,
    },
    serviceProducts: {
      /** These are specific to the current store, no need to merge when changing store */
      merge: false,
    },
  },
}
