import type { MutationResolvers } from 'types/gql/type-defs'

import { GetDeliveryDetailsInfoDocument } from '@shared/gql/document-nodes'
import { emptyDeliveryDetailsInfo } from 'services/App/empty-initial-states'
import { DaoService } from 'services/LocalStorage'

export const updateDeliveryDetails: MutationResolvers['updateDeliveryDetails'] = (
  _,
  args,
  { cache },
) => {
  const cachedDeliveryDetailsInfo = cache.readQuery({
    query: GetDeliveryDetailsInfoDocument,
  })?.deliveryDetailsInfo

  const deliveryDetailsInfo = {
    ...emptyDeliveryDetailsInfo(),
    ...cachedDeliveryDetailsInfo,
    ...args,
  }

  cache.writeQuery({
    query: GetDeliveryDetailsInfoDocument,
    data: {
      __typename: 'Query' as const,
      deliveryDetailsInfo,
    },
  })

  DaoService.DeliveryDAO.saveDeliveryDetails(deliveryDetailsInfo)

  return null
}
