import type { SnackbarProps } from './Snackbar'
import type { ReactiveVar } from '@apollo/client'

import { makeVar, useReactiveVar } from '@apollo/client'
import { favoritesLoginPromptVisibleVar } from 'lib/apollo/reactive-vars'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetProfileActionHandlers } from 'utils/hooks/account/use-get-profile-action-handlers'
import { v4 as uuidv4 } from 'uuid'

import { Snackbar } from './Snackbar'
import { SnackbarPortal } from './SnackbarPortal'

interface SnackbarItem extends SnackbarProps {
  id: string
  text: string
}

const snackbarQueue: ReactiveVar<SnackbarItem[]> = makeVar<SnackbarItem[]>([])

const removeSnackbar = (id: string) => () =>
  snackbarQueue(snackbarQueue().filter((x) => x.id !== id))

export const addSnackbar = (snackbarItem: Omit<SnackbarItem, 'onHide' | 'id'>) => {
  const id = uuidv4()
  snackbarQueue([
    ...snackbarQueue(),
    {
      ...snackbarItem,
      id,
      onHide: removeSnackbar(id),
    },
  ])
}

const hideFavoritesLoginPrompt = () => favoritesLoginPromptVisibleVar(false)

export const GlobalSnackbars = () => {
  const { t } = useTranslation()
  const showFavoritesLoginPrompt = useReactiveVar(favoritesLoginPromptVisibleVar)
  const { onLoginClick } = useGetProfileActionHandlers()
  return (
    <>
      {showFavoritesLoginPrompt && (
        <Snackbar
          onHide={hideFavoritesLoginPrompt}
          actionText={t('Log in or create account')}
          onAction={onLoginClick}
          autoHideTimeout={10000}
        >
          {t('Favorite lists require authentication')}
        </Snackbar>
      )}
    </>
  )
}

/**
 * Renders a `SnackbarPortal` and any `Snackbar`s added
 * via the `addSnackbar` utility function.
 */
export const Snackbars = memo(() => {
  const snackbars = useReactiveVar(snackbarQueue)
  return (
    <>
      <SnackbarPortal />
      {snackbars.map(({ id, text, onHide, actionText, onAction, autoHideTimeout }) => (
        <Snackbar
          key={id}
          onHide={onHide}
          actionText={actionText}
          onAction={onAction}
          autoHideTimeout={autoHideTimeout}
        >
          {text}
        </Snackbar>
      ))}
    </>
  )
})
Snackbars.displayName = 'Snackbars'
