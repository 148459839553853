import type { ApolloClient, ReactiveVar } from '@apollo/client'
import type { ClientCartItem, Product, ProductList } from '@shared/gql/document-nodes'

import { pipe } from 'fp-ts/function'
import { find, map, prop, sortBy } from 'ramda'
import { DaoService } from 'services/LocalStorage'
import { mapProductToClientCartItem } from 'utils/mappers/product-to-client-cart-item-mapper'

interface updateClientCartItemsOnStoreChangeParams {
  productList: ProductList
  // See node_modules/@apollo/client/react/hooks/useApolloClient.d.ts:2
  // eslint-disable-next-line @typescript-eslint/ban-types
  apolloClient: ApolloClient<object>
}

const updateCartItemsWithStoreData = (
  cartItems: ClientCartItem[],
  productList: ProductList,
): ClientCartItem[] => {
  const productListCartItems = (productList.items as Product[]) || []
  return pipe(
    cartItems,
    map(
      (cartItem: ClientCartItem): ClientCartItem =>
        mapProductToClientCartItem(
          cartItem,
          find((p: Product) => p.id === cartItem.id)(productListCartItems),
        ),
    ),
    sortBy<ClientCartItem>(prop('inStoreSelection')),
  )
}

export const updateClientCartItemsOnStoreChange = (
  shoppingCartVar: ReactiveVar<ClientCartItem[]>,
): ((params: updateClientCartItemsOnStoreChangeParams) => Promise<void>) => {
  return async (params) => {
    const existingShoppingCart = shoppingCartVar()
    const newCart = updateCartItemsWithStoreData(existingShoppingCart, params.productList)
    if (params.apolloClient) {
      await DaoService.CartDAO.saveCartItems(newCart, params.apolloClient)
    }
    shoppingCartVar(newCart)
  }
}
