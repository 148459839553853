import type { FC } from 'react'
import type { CSSProperties } from 'styled-components'

import styled, { css, keyframes } from 'styled-components'

interface SkeletonLoaderProps {
  circle?: boolean
  className?: string
  height?: CSSProperties['height']
  style?: CSSProperties
  width?: CSSProperties['width']
}

const WIDTH = 500

const animation = keyframes({
  '0%': { left: -WIDTH },
  '100%': { left: '100%' },
})

const _SkeletonLoader: FC<SkeletonLoaderProps> = ({ className, style, width, height }) => (
  <div className={className} style={{ ...style, width, height }} />
)

export const SkeletonLoader = styled(_SkeletonLoader)(
  ({ height, style, width }) => ({
    height: undefined,
    style: { ...style, height, width },
    width: undefined,
  }),
  ({ circle = false, theme }) => ({
    backgroundColor: theme.colors.grey200,
    borderRadius: circle ? '50%' : 4,
    height: '1em',
    lineHeight: 1,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',

    '::before': {
      backgroundImage:
        'linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0))',
      content: '""',
      height: '100%',
      left: -WIDTH,
      position: 'absolute',
      top: '0',
      width: WIDTH,
    },
  }),
  /** Keyframes do not work inside object styles :/ */
  css`
    ::before {
      animation: ${animation} 1.2s ease-in-out infinite;
    }
  `,
)
