import type { ErrorLogEntry, LogEntry, Logger } from './types'

import { getConfig } from '@shared/config'

import { consoleLogger } from './console-logger'
import { sentryLogger } from './sentry-logger'

const { sentry, consoleLogger: consoleLoggerConfig } = getConfig()

const loggers: Logger[] = []

if (consoleLoggerConfig.enabled) {
  loggers.push(consoleLogger)
}

if (sentry.enabled) {
  loggers.push(sentryLogger)
}

export const commonLogger: Logger = {
  debug: async (entry: LogEntry): Promise<void> => {
    // eslint-disable-next-line testing-library/no-debugging-utils
    await Promise.all(loggers.map((l) => l.debug(entry)))
  },
  debugSync: (entry: LogEntry): void => {
    loggers.forEach((l) => l.debugSync(entry))
  },
  info: async (entry: LogEntry): Promise<void> => {
    await Promise.all(loggers.map((l) => l.info(entry)))
  },
  infoSync: (entry: LogEntry): void => {
    loggers.forEach((l) => l.infoSync(entry))
  },
  warn: async (entry: LogEntry): Promise<void> => {
    await Promise.all(loggers.map((l) => l.warn(entry)))
  },
  warnSync: (entry: LogEntry): void => {
    loggers.forEach((l) => l.warnSync(entry))
  },
  error: async (entry: ErrorLogEntry): Promise<void> => {
    await Promise.all(loggers.map((l) => l.error(entry)))
  },
  errorSync: (entry: ErrorLogEntry): void => {
    loggers.forEach((l) => l.errorSync(entry))
  },
}
