import Cookie from 'cookie'
import { DateTime } from 'luxon'

export const SELECTED_STORE_COOKIE = 's-kaupat-selected-store'

export const writeSelectedStoreCookie = async (id?: string | null): Promise<void> => {
  if (typeof window === 'undefined') {
    throw new Error('`writeSelectedStoreCookie` should not be used server-side')
  }

  /**
   * Wait until Safari grants storage access
   * @see https://stackoverflow.com/questions/66324724/safari-set-cookies-not-working-for-first-party-cookie
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/requestStorageAccess
   */
  if ('requestStorageAccess' in document) {
    try {
      await document.requestStorageAccess()
    } catch {} // eslint-disable-line no-empty
  }

  const currentCookieValue = Cookie.parse(document.cookie)[SELECTED_STORE_COOKIE]

  /** Remove existing cookie when store is not selected by making it expire yesterday */
  if (!id && currentCookieValue) {
    document.cookie = Cookie.serialize(SELECTED_STORE_COOKIE, '', {
      expires: DateTime.now().minus({ days: 1 }).toJSDate(),
      path: '/',
      sameSite: 'lax',
    })
  }

  /** Always set current store cookie to keep the expiration date fresh */
  if (id) {
    document.cookie = Cookie.serialize(SELECTED_STORE_COOKIE, id, {
      expires: DateTime.now().plus({ days: 30 }).toJSDate(),
      path: '/',
      /** Lax cookies are included in redirects from different origins, eg Google Search */
      sameSite: 'lax',
    })
  }
}
