/* eslint-disable import/no-default-export */

import type { OpenGraphObject } from './types'

import { colors as sdsColors } from '@s-group/design-system-tokens/web/tokens/s-kaupat/theme'
import { getConfig } from '@shared/config'
import { Brand } from '@shared/domain/brand'
import { neutral, SBonus, Themes } from '@sok/design-system'
import { SKAUPAT_HREF_PRODUCTION as url } from 'constants/href'
import { transparentize } from 'polished'

import {
  borderRadius,
  color,
  CONTENTFUL_MEDIA_ROOT,
  defaultSeoTags,
  extraBreakpoints,
  favIconUrl,
  logos,
  screenHeight,
} from './global'

const { domain } = getConfig()

const openGraphImageUrl = `${CONTENTFUL_MEDIA_ROOT}/4sdgPx3wHjD6J9vCgmk90C/b1be4c171a1421b12f8ef7e50f974501/S-kaupat_Link_1200x630.jpg`

const sKaupatColor = {
  ...color,
  action: neutral.colors.primary500,
  actionVariant: neutral.colors.primary800,
  disabled: neutral.colors.primary100,
  fadedBrand: transparentize(0.7, neutral.colors.white),
  highlight200: neutral.colors.primary200,
  highlight50: neutral.colors.primary50,
  highlight500: neutral.colors.primary500,
  highlight600: neutral.colors.primary600,
  highlight700: neutral.colors.primary700,
  highlightFocusColor: neutral.colors.primary200,
  highlightHoverColor: neutral.colors.primary500,
  info: neutral.colors.secondary,
  inputFocusShadowColor: neutral.colors.primary200,
  inputHoverColor: neutral.colors.primary500,
  modBanner: neutral.colors.info800,
  overlay: transparentize(0.3, neutral.colors.primary900),
  primary700: neutral.colors.secondary700,
  primaryBrand: neutral.colors.primary500,
  primaryBrandLight: neutral.colors.secondary600,
  primaryBrandVariant: neutral.colors.secondary800,
  secondary700: neutral.colors.secondary700,
  successBanner: neutral.colors.success,
  white: neutral.colors.white,
}

const title = 'S-kaupat ruoan verkkokauppa'
const brandName = Brand.Skaupat
const designSystem = Themes.NEUTRAL

const description =
  'S-kaupat on tuttu ruokakauppasi verkossa – tee tilaus sinne, missä sinäkin olet.'

const headerIconWidth = {
  desktop: '100px',
  mobile: '80px',
}

const openGraph: OpenGraphObject = {
  title,
  description,
  locale: 'fi_FI',
  type: 'website',
  images: [
    {
      url: openGraphImageUrl,
      alt: title,
    },
  ],
}

const seoTags = {
  ...defaultSeoTags,
  title,
  description,
  canonical: url,
  openGraph,
}

export default {
  color: sKaupatColor,
  borderRadius,
  logos,
  favIconUrl,
  seoTags,
  screenHeight,
  brandName,
  designSystem,
  defaultStoreId: domain.defaultStoreId,
  headerIconWidth,
  colors: { ...neutral.colors, ...sdsColors },
  fontWeights: SBonus.weights,
  breakpoints: { ...neutral.breakpoints, ...extraBreakpoints },
  variants: SBonus.variants,
  spacings: neutral.spacings,
  sizes: SBonus.sizes,
}
