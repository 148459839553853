import type { TypeOf } from 'zod'

import { Config } from './schema'

type FeatureFlagConfig = TypeOf<typeof Config>['featureFlags']

const getOverrides = (): Partial<FeatureFlagConfig> => {
  if (typeof window === 'undefined') {
    return {}
  }

  // safe to cast as object.keys provide keys but...
  const keys = Object.keys(Config.shape.featureFlags.shape) as (keyof FeatureFlagConfig)[]

  return keys.reduce((p, key) => {
    const value = localStorage.getItem(key)
    if (value !== null) {
      p[key] = value === 'true'
    }

    return p
  }, {} as Partial<FeatureFlagConfig>)
}

export const applyFeatureFlagOverrides = (config: FeatureFlagConfig): FeatureFlagConfig => ({
  ...config,
  ...getOverrides(),
})

const createOverride = (key: keyof FeatureFlagConfig, value: boolean) => {
  if (typeof window !== 'undefined' && 'localStorage' in window) {
    localStorage.setItem(key, `${value}`)
  }
}

const removeOverride = (key: keyof FeatureFlagConfig) => {
  if (typeof window !== 'undefined' && 'localStorage' in window) {
    localStorage.removeItem(key)
  }
}

export const featureFlagOverride = {
  set: createOverride,
  remove: removeOverride,
}
