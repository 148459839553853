import { PaymentMethod, PaymentStatus } from '@shared/gql/document-nodes'
import * as t from 'io-ts'

export const isPaymentStatus = (x: string | null | undefined): x is PaymentStatus => {
  switch (x) {
    case 'PENDING':
    case 'CARD_AUTHORIZED':
    case 'CAPTURE_COMPLETED':
    case 'UNAVAILABLE':
    case 'CARD_AUTHORIZATION_FAILED':
    case 'CAPTURE_FAILED':
    case 'CANCELED':
      return true
    default:
      return false
  }
}

export enum PaymentError {
  SAVE_USER_PAYMENT_CARD = 'SAVE_USER_PAYMENT_CARD',
  AUTHORIZE_PAYMENT = 'AUTHORIZE_PAYMENT',
  AUTHORIZE_PAYMENT_USER_ERROR = 'AUTHORIZE_PAYMENT_USER_ERROR',
  AUTHORIZE_PAYMENT_SLOT_RESERVATION_NOT_FOUND = 'AUTHORIZE_PAYMENT_SLOT_RESERVATION_NOT_FOUND',
  AUTHORIZE_PAYMENT_SLOT_IS_FULL = 'SLOT_IS_FULL_ERROR',
}

export enum PaymentResponseCode {
  TERMINAL_OK = 'OK',
  TERMINAL_CANCEL = 'Cancel',
}

export const PaymentStatusType = t.union([
  t.literal(PaymentStatus.Pending),
  t.literal(PaymentStatus.Canceled),
  t.literal(PaymentStatus.CaptureCompleted),
  t.literal(PaymentStatus.CaptureFailed),
  t.literal(PaymentStatus.CardAuthorizationFailed),
  t.literal(PaymentStatus.CardAuthorized),
  t.literal(PaymentStatus.Unavailable),
])

export const PaymentMethodType = t.union([
  t.literal(PaymentMethod.Invoice),
  t.literal(PaymentMethod.CardPayment),
  t.literal(PaymentMethod.OnDelivery),
])
export type PaymentMethodType = t.TypeOf<typeof PaymentMethodType>
