import { makeVar } from '@apollo/client'

export enum DeliverySlotReservationStatus {
  /** Either valid reservation, or not reserved at all; no need to show warnings  */
  OK,
  /** Reservation is dangerously close to being expired */
  AboutToExpire,
  /** Reservation is already expired and no longer valid */
  AlreadyExpired,
}

export const deliverySlotReservationStatusVar = makeVar<DeliverySlotReservationStatus>(
  DeliverySlotReservationStatus.OK,
)
