import type { ThemedObjectStyleFunction } from 'domain/styled'

import { Alert, Checkbox, CheckboxSize, SearchOutline16 } from '@sok/design-system'
import { BORDER_RADIUS } from 'components/Button/border-radius'
import styled from 'styled-components'

import { GeolocationButton } from './GeolocationButton'

export const StoreAvailabilityCheck = styled(Checkbox).attrs({ size: CheckboxSize.SMALL })({})

export const StyledSearchIcon = styled(SearchOutline16)({})

const buttonStyles: ThemedObjectStyleFunction = ({ theme }) => ({
  alignItems: 'center',
  background: 'none',
  ...BORDER_RADIUS,
  border: 'none',
  color: 'inherit',
  cursor: 'pointer',
  display: 'flex',
  height: '2rem',
  justifyContent: 'center',
  width: '2rem',

  '&:focus-within': {
    background: theme.color.action,
    outline: 'none',

    'svg path': {
      color: theme.colors.white,
    },
  },

  '&:active': {
    background: theme.color.actionVariant,

    'svg path': {
      color: theme.colors.white,
    },
  },
})

export const StyledClearButton = styled.button(buttonStyles)

export const StyledSearchInputContainer = styled.div<{ clearButtonVisible: boolean }>(
  ({ clearButtonVisible, theme }) => ({
    position: 'relative',
    display: 'flex',

    '> .geolocation-popover': {
      width: '100%',
    },

    /** Awkward placement on top of the input component */
    [`${StyledSearchIcon}, ${GeolocationButton}, ${StyledClearButton}`]: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },

    [StyledSearchIcon]: {
      left: theme.spacings.small,
    },

    [GeolocationButton]: {
      right: clearButtonVisible ? theme.spacings.xxLarge : theme.spacings.small,
    },

    [StyledClearButton]: {
      right: theme.spacings.xxSmall,
    },
  }),
)

export const StyledInputLabelContainer = styled.div(
  {
    alignItems: 'baseline',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    'label:first-of-type': {
      display: 'none',
    },
  },
  ({ theme }) => ({
    [StoreAvailabilityCheck]: {
      paddingBottom: theme.spacings.small,
    },
  }),
)

export const StyledSearchInput = styled.input(({ theme }) => ({
  appearance: 'none',
  backgroundColor: theme.colors.grey200,
  border: 'none',
  ...BORDER_RADIUS,
  color: 'inherit',
  fontSize: 'inherit',
  height: theme.sizes.xxl.fontSize,
  padding: `0 4rem 0 2.5rem` /** icons on top of input */,
  width: '100%',

  '&:focus-within': {
    backgroundColor: theme.colors.white,
    boxShadow: `inset 0 0 0 1px ${theme.color.action}`,
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'progress',
  },
}))

export const StyledAlert = styled(Alert)`
  margin-top: ${({ theme }) => theme.spacings.small};
  flex: 1;
  width: 100%;
`
