import { getConfig } from '@shared/config'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'
import { useSelectedDeliveryAreaDetails } from 'utils/hooks/use-selected-delivery-area-name'

const { featureFlags } = getConfig()

/**
 * @returns `true` when delivery slots should be reserved. Temporarily tied to
 * delivery areas with dynamic pricing enabled.
 *
 * @deprecated this is a temporary feature flag for dynamic pricing pilot
 */
export const useIsDeliverySlotReservationEnabled = (): boolean => {
  const isLoggedIn = useIsLoggedIn()
  const { dynamicPricing, isFastTrack } = useSelectedDeliveryAreaDetails()

  const isSlotReservationWithLoginEnabled = featureFlags.slotReservationWithLogin
  const isSlotReservationInAllStoresEnabled =
    featureFlags.slotReservationInAllStoresForLoggedInUsers

  // should be removed after full rollout
  const isSlotReservationEnabled = !!dynamicPricing || isSlotReservationInAllStoresEnabled

  return isSlotReservationWithLoginEnabled
    ? isLoggedIn && isSlotReservationEnabled
    : !!dynamicPricing && !isFastTrack
}
