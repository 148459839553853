import type { FC } from 'react'

import { DeliveryMethod } from '@shared/gql/document-nodes'
import { Button, ButtonSize, ButtonType, minWidthFromTheme, Modal } from '@sok/design-system'
import { PageTitle, StyledParagraph } from 'components/common/Titles'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { Z_INDEX } from 'styles/layout'
import { Notice } from 'views/delivery-modal/Notice'

interface Props {
  className?: string
  deliveryMethod?: DeliveryMethod
  isAlreadyExpired: boolean
  onClose: () => void
  onConfirm: () => void
  open?: boolean
}

const _DeliverySloReservationWarningModal: FC<Props> = ({
  className,
  deliveryMethod,
  isAlreadyExpired,
  onClose,
  onConfirm,
  open,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const isHomeDelivery = deliveryMethod === DeliveryMethod.HomeDelivery

  /**
   * @todo Open Modals using service from `services/Modal`
   * @see https://jira.sok.fi/browse/VOIK-9959
   */
  return (
    <Modal
      data-test-id="delivery-slot-expiration-warning"
      hideCloseIcon
      visible={!!open}
      className={className}
      zIndex={Z_INDEX.reservationWarning}
    >
      <img
        alt=""
        height={128}
        role="presentation"
        src={isAlreadyExpired ? theme.logos.calendarExpired : theme.logos.calendarTimeRunningOut}
        width={128}
      />

      <PageTitle>
        {isAlreadyExpired
          ? t(isHomeDelivery ? 'Reserved delivery time expired' : 'Reserved pickup time expired')
          : t(
              isHomeDelivery
                ? 'Reservation for home delivery about to expire'
                : 'Reservation for pickup about to expire',
            )}
      </PageTitle>

      {!isAlreadyExpired && <Notice message={t('Delivery slot reservation info')} />}

      <footer>
        <Button
          data-test-id="confirm"
          onClick={onConfirm}
          type={ButtonType.PRIMARY}
          size={ButtonSize.LARGE}
        >
          {isAlreadyExpired
            ? t(isHomeDelivery ? 'Select delivery time' : 'Select pickup time')
            : t('Refresh slot reservation')}
        </Button>

        <Button
          data-test-id="cancel"
          onClick={onClose}
          type={ButtonType.DEFAULT}
          size={ButtonSize.LARGE}
        >
          {t(isAlreadyExpired ? 'Close' : 'Cancel slot reservation')}
        </Button>
      </footer>
    </Modal>
  )
}

export const DeliverySloReservationWarningModal = styled(_DeliverySloReservationWarningModal)(
  ({ theme }) => ({
    '.modal-content': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',

      [PageTitle]: {
        margin: `${theme.spacings.large} 0 ${theme.spacings.xxSmall}`,
        maxWidth: 480 /** when the title looks just right */,
      },

      [StyledParagraph]: {
        marginBottom: theme.spacings.xxLarge,
      },

      [Notice]: {
        maxWidth: 480,
      },

      footer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 'auto',
        maxWidth: 256,
        width: '100%',

        button: {
          width: '100%',

          '& + button': {
            marginTop: theme.spacings.xxSmall,
          },
        },
      },

      [minWidthFromTheme(theme).tablet]: {
        footer: {
          marginTop: 'initial',
        },
      },
    },
  }),
)
