import type { FC, ReactNode } from 'react'

import { BrandStoreLogo } from 'components/BrandStoreLogo'
import styled, { useTheme } from 'styled-components'

const StyledPageContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.color.fontBlack};
  background-color: ${({ theme }) => theme.color.successBanner};
  height: 100vh;
`
const StyledContentContainer = styled.div`
  padding: 48px;
  text-align: center;
`
const StyledHeader = styled.h1``
const StyledLogo = styled.img`
  margin: 16px 0;
`
const StyledBrandLogo = styled(BrandStoreLogo)({
  margin: '16px 0',
})
export const StyledMessage = styled.p`
  margin: 24px 0px;
  line-height: ${({ theme }) => theme.sizes.xs.lineHeight};
`
export const StyledList = styled.ul`
  margin-top: 24px;
`
export const StyledListItem = styled.li`
  display: inline-block;
  margin: 10px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`
export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.actionVariant};
`

interface NotShowingPageProps {
  icon?: string
  title: string
  children?: ReactNode
}

export const NotShowingPage: FC<NotShowingPageProps> = ({ icon, title, children }) => {
  const theme = useTheme()

  return (
    <StyledPageContainer>
      <StyledContentContainer>
        <StyledLogo src={icon} role="presentation" />
        <StyledHeader>{title}</StyledHeader>
        {children}
        <StyledBrandLogo brand={theme.brandName} />
      </StyledContentContainer>
    </StyledPageContainer>
  )
}
