import type { GetDeliveryAreaQuery } from '@shared/gql/document-nodes'
import type { Slot } from 'domain/delivery-details'

import { type Brand, getBrandByBrandName } from '@shared/domain/brand'
import { EuroCents } from '@shared/domain/euro-cents'
import { toFormattedTime } from 'domain/date-time'
import { DateTime } from 'luxon'
import { range } from 'ramda'
import { getAlcoholSellingAllowed } from 'utils/delivery/get-alcohol-selling-allowed'

export type CalendarSlot = {
  slotId: string
  dateTime: DateTime
  formattedPrice: string
  formattedStartAndEndTime: string
  isAlcoholSellingAllowed: boolean
  isModifiable: boolean
  slotData: Slot
}

type CalendarStore = {
  storeId: string
  name: string
  brand: Brand | null
  slots: CalendarSlot[]
}

export type CalendarDays = Record<string, CalendarStore[]>

type Unpacked<T> = T extends (infer U)[] ? U : T

type DeliveryTimeSlot = Unpacked<
  Unpacked<
    NonNullable<NonNullable<GetDeliveryAreaQuery['deliveryArea']>['deliverySlots']>
  >['deliveryTimes']
>

const toCalendarSlot = (slot: DeliveryTimeSlot): CalendarSlot => ({
  slotId: slot.slotId,
  formattedPrice: EuroCents.fromEuros(slot.price).format(),
  formattedStartAndEndTime: `${toFormattedTime(slot.startDateTime)}-${toFormattedTime(
    slot.endDateTime,
  )}`,
  dateTime: DateTime.fromISO(slot.startDateTime) || DateTime.now(),
  isAlcoholSellingAllowed: getAlcoholSellingAllowed(slot.alcoholSellingAllowed),
  isModifiable: slot.isModifiable,
  slotData: slot,
})

/**
 * Create CalendarDays object with dates as keys starting from the given date and
 * ending to the given date + 7 * numberOfWeeks days.
 * @example
 * populateCalendar(DateTime.fromISO('2000-01-01'), 2)
 * { '2000-01-01': [], '2000-01-02': [], ..., '2000-01-14': []}
 */
export const populateCalendar = (startingFrom: DateTime, numberOfDays: number): CalendarDays =>
  range(0, numberOfDays).reduce(
    (calendar, _, days) => ({
      ...calendar,
      [startingFrom.plus({ days }).toISODate() || '']: [],
    }),
    {},
  )

function calendarSlotCompare(a: CalendarSlot, b: CalendarSlot) {
  return a.dateTime < b.dateTime ? -1 : 1
}

/**
 * Takes initial calendar data and merges the given Query results data to it
 */
export const mergeCalendar = (
  areas: NonNullable<GetDeliveryAreaQuery['deliveryArea']>[],
  initial: CalendarDays,
): CalendarDays =>
  areas.reduce(
    (result, deliveryArea) =>
      (deliveryArea.deliverySlots || []).reduce<CalendarDays>((calendarConstruct, deliverySlot) => {
        // Try to ignore any bad data.
        try {
          const date = DateTime.fromISO(deliverySlot.date).toISODate() || ''
          const deliveryTimes = deliverySlot?.deliveryTimes || []

          if (deliveryTimes.length === 0) {
            return calendarConstruct
          }
          const storesAndSlotsForDate = [
            {
              name: deliveryArea.store?.name || deliveryArea.name,
              storeId: deliveryArea.storeId,
              brand: getBrandByBrandName(deliveryArea.store?.brand),
              slots: deliveryTimes.map(toCalendarSlot).sort(calendarSlotCompare),
            },
            ...(calendarConstruct[date] || []).filter(
              (store) => store.storeId !== deliveryArea.storeId,
            ),
          ]
          return {
            ...calendarConstruct,
            [date]: storesAndSlotsForDate,
          }
        } catch (e) {
          return calendarConstruct
        }
      }, result),
    initial,
  )

/**
 * Finds first day with slots, defaults to todays date.
 */
export const getFirstDateWithSlots = (calendar: CalendarDays) =>
  Object.keys(calendar).find((date) => calendar[date].length > 0) ||
  (DateTime.now().toISODate() as string)
