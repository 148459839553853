import type { TypedTypePolicies } from 'types/gql/apollo-helpers'

import { mergeProducts } from '../merge-products'

/** Support paginated lazy query for searching products from a store */
export const ProductList: NonNullable<TypedTypePolicies['ProductList']> = {
  fields: {
    items: {
      merge: mergeProducts,
    },
  },
}
