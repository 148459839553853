import type { FC } from 'react'

import { useQuery, useReactiveVar } from '@apollo/client'
import { GetDeliveryDetailsInfoDocument } from '@shared/gql/document-nodes'
import { useCallback, useEffect, useState } from 'react'
import { openDeliveryModal } from 'services/Delivery/cache'
import { DeliverySelectionStage } from 'types/global'

import {
  useCancelDeliverySlotReservation,
  useClearDeliverySlotReservationFromCache,
  useDeliverySlotReservationId,
} from '../hooks'
import { useRefreshDeliverySlotReservation } from '../hooks/use-refresh-delivery-slot-reservation'
import { DeliverySlotReservationStatus, deliverySlotReservationStatusVar } from '../status'
import { DeliverySlotReleaseReason, trackDeliverySlotWarningShown } from '../tracking'
import { DeliverySloReservationWarningModal } from './DeliverySloReservationWarningModal'

export const DeliverySlotReservationTracker: FC = () => {
  const deliveryMethod =
    useQuery(GetDeliveryDetailsInfoDocument).data?.deliveryDetailsInfo?.deliveryMethod ?? undefined

  const reservationId = useDeliverySlotReservationId()

  const cancelDeliverySlotReservation = useCancelDeliverySlotReservation()
  const clearDeliverySlotReservationFromCache = useClearDeliverySlotReservationFromCache()
  const triggerRefresh = useRefreshDeliverySlotReservation()

  const reservationStatus = useReactiveVar(deliverySlotReservationStatusVar)

  const [isOpen, setIsOpen] = useState(false)

  const isAlreadyExpired = reservationStatus === DeliverySlotReservationStatus.AlreadyExpired

  useEffect(() => {
    if (reservationId && reservationStatus !== DeliverySlotReservationStatus.OK) {
      setIsOpen(true)
      trackDeliverySlotWarningShown(isAlreadyExpired)
    }
  }, [isAlreadyExpired, reservationId, reservationStatus])

  const handleClose = useCallback(() => {
    setIsOpen(false)

    if (!reservationId) return
    if (isAlreadyExpired) {
      /**
       * Clear already-expired reservation from Apollo cache and localStorage
       */
      clearDeliverySlotReservationFromCache(reservationId)
    } else {
      /**
       * Cancel about-to-expire reservation from remote to free up capacity.
       * This should be done when customer has reserved a time but is not
       * actively ordering (only browsing).
       */
      cancelDeliverySlotReservation(reservationId, DeliverySlotReleaseReason.MANUAL)
    }
  }, [
    cancelDeliverySlotReservation,
    clearDeliverySlotReservationFromCache,
    isAlreadyExpired,
    reservationId,
  ])

  const handleConfirm = useCallback(() => {
    setIsOpen(false)

    if (isAlreadyExpired) {
      if (reservationId) {
        clearDeliverySlotReservationFromCache(reservationId)
      }

      /**
       * Prompt customer to reserve a new delivery slot. We could also
       * try to automatically reserve a new time for the previous slot,
       * but so far it hasn't been thought of as necessary.
       */
      openDeliveryModal(DeliverySelectionStage.SELECT_DELIVERY_TIME)
    } else {
      /**
       * Confirm button should manually refresh the reservation
       */
      triggerRefresh(false)
    }
  }, [clearDeliverySlotReservationFromCache, isAlreadyExpired, reservationId, triggerRefresh])

  return (
    <>
      <DeliverySloReservationWarningModal
        isAlreadyExpired={isAlreadyExpired}
        deliveryMethod={deliveryMethod}
        onClose={handleClose}
        onConfirm={handleConfirm}
        open={isOpen}
      />
    </>
  )
}
