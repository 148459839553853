import type { ReservedDeliverySlotFragment } from '@shared/gql/document-nodes'
import type { RemoteData } from 'domain/remote-data'

import { useQuery } from '@apollo/client'
import { DeliverySlotReservationViewFromDeliverySlotDocument } from '@shared/gql/document-nodes'
import { useMemo } from 'react'
import { useGetSelectedDeliverySlot } from 'services/Delivery/hooks/use-get-delivery-slot'
import { useGetSelectedSlotId } from 'utils/hooks/use-get-selected-slot-id'
import { useGetOrderEditState } from 'utils/order/use-get-order-edit-state'

import { useDeliverySlotReservation } from './use-delivery-slot-reservation'
import { useIsDeliverySlotReservationEnabled } from './use-is-delivery-slot-reservation-enabled'

/**
 * Return info about the currently selected delivery slot in the following order:
 * 1. reservation, if any (falling back deprecated implementation if not enabled)
 * 2. currently edited order's slot, if any
 *
 * Reservation is preferred to support changing the time slot when editing an order.
 */
export const useSelectedDeliverySlot = (): RemoteData<Error, ReservedDeliverySlotFragment> => {
  const isDeliverySlotReservationEnabled = useIsDeliverySlotReservationEnabled()
  const remoteReservation = useDeliverySlotReservation()
  const selectedSlotId = useGetSelectedSlotId()
  const deprecatedSelectedSlot = useGetSelectedDeliverySlot()
  const { isOrderEditActive } = useGetOrderEditState()

  /** This is used to get the "selected slot" of the currently-edited order */
  const queryResult = useQuery(DeliverySlotReservationViewFromDeliverySlotDocument, {
    skip: !isOrderEditActive || !selectedSlotId,
    ssr: false,
    variables: {
      slotId: selectedSlotId || '',
    },
  })

  return useMemo(() => {
    /** short-circuit to previous implementation */
    if (!isDeliverySlotReservationEnabled) {
      return deprecatedSelectedSlot
    }

    switch (remoteReservation.type) {
      case 'LOADING':
      case 'FAILURE': {
        return remoteReservation
      }
      case 'SUCCESS': {
        return { type: 'SUCCESS', data: remoteReservation.data.deliverySlot }
      }
    }

    if (!queryResult.called) {
      return { type: 'NOT_ASKED' }
    }

    if (queryResult.loading) {
      return { type: 'LOADING' }
    }

    if (queryResult.error || !queryResult.data?.deliverySlot) {
      return {
        type: 'FAILURE',
        error: queryResult.error ?? new Error('Missing delivery slot'),
      }
    }

    return {
      type: 'SUCCESS',
      data: queryResult.data.deliverySlot,
    }
  }, [
    deprecatedSelectedSlot,
    isDeliverySlotReservationEnabled,
    queryResult.called,
    queryResult.data?.deliverySlot,
    queryResult.error,
    queryResult.loading,
    remoteReservation,
  ])
}
