import type { DeliveryOption } from 'domain/delivery-option'
import type { FC } from 'react'

import { getConfig } from '@shared/config'
import { DeliveryMethod } from '@shared/gql/document-nodes'
import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import {
  closeDeliveryModal,
  pendingDeliveryMethodVar,
  setDeliveryModalStage,
} from 'services/Delivery/cache'
import { useGetDeliverySlotsFromAreaLazyQuery } from 'services/Delivery/hooks/use-get-delivery-slots-from-area-query'
import { useSelectDeliverySlot } from 'services/DeliverySlot/hooks/use-select-delivery-slot'
import { handleRequestErrors } from 'services/error-handler'
import { addSnackbar } from 'services/Snackbar/Snackbars'
import { DeliverySelectionStage } from 'types/global'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'
import { useStoreChange } from 'utils/hooks/store/use-store-change'

import { sortResponseDeliverySlotsAndTimes } from '../time/sort-delivery-slot-times'
import { DeliveryMethodRadio } from './DeliveryMethodRadio'
import { DeliveryOptionSelectorTabContainer } from './DeliveryOptionSelectorTabContainer'
import { useGetInitialDeliverySelections } from './use-get-initial-delivery-selections'

const { featureFlags } = getConfig()

export const DeliveryOptionSelectorContainer: FC = () => {
  const { initialDeliveryMethod } = useGetInitialDeliverySelections()
  const [activeDeliveryMethod, setActiveDeliveryMethod] = useState(initialDeliveryMethod)

  const onStoreChange = useStoreChange()
  const onSlotSelect = useSelectDeliverySlot()
  const getFirstFastTrackSlot = useGetFirstFastTrackSlot()
  const [isConfirmingSelection, setIsConfirmingSelection] = useState(false)
  const isLoggedIn = useIsLoggedIn()
  const onSelect = (searchTerm: string) => async (x: DeliveryOption) => {
    setIsConfirmingSelection(true)
    await onStoreChange(mapToStoreChange(searchTerm)(x))
    /**
     * Automatically select first Fast Track Slot to skip the UI and move
     * directly to either error (in this view) or confirmation screen.
     */
    if (x.isFastTrack) {
      try {
        await getFirstFastTrackSlot(x.areaId).then((slot) => {
          if (slot) {
            if (slot.isClosed) {
              addSnackbar({
                text: t('Fast Track not available', { context: x.deliveryMethod }),
                actionText: t('Choose store'),
                onAction: () =>
                  setDeliveryModalStage(DeliverySelectionStage.SELECT_METHOD_AND_STORE),
              })
            } else {
              onSlotSelect(slot)
              const context =
                featureFlags.slotReservationInAllStoresForLoggedInUsers && isLoggedIn
                  ? `AUTHENTICATED_FASTTRACK_${x.deliveryMethod}`
                  : `UNAUTHENTICATED_FASTTRACK_${x.deliveryMethod}`
              addSnackbar({
                text: t('SlotSelected', {
                  context,
                }),
              })
            }
            closeDeliveryModal()
          } else {
            addSnackbar({
              text: t('Fast Track not available', { context: x.deliveryMethod }),
              actionText: t('Choose store'),
              onAction: () => setDeliveryModalStage(DeliverySelectionStage.SELECT_METHOD_AND_STORE),
            })
          }
        })
      } catch (e) {
        handleRequestErrors([e])
      }
    } else {
      setDeliveryModalStage(DeliverySelectionStage.SELECT_DELIVERY_TIME)
    }
    setIsConfirmingSelection(false)
  }

  const handleSelectDeliveryMethod = useCallback(
    (newDeliveryMethod: DeliveryMethod) => {
      setActiveDeliveryMethod(newDeliveryMethod)
    },
    [setActiveDeliveryMethod],
  )

  useEffect(() => {
    pendingDeliveryMethodVar(activeDeliveryMethod)
  }, [activeDeliveryMethod])

  return (
    <>
      <DeliveryMethodRadio
        onSelectDeliveryMethod={handleSelectDeliveryMethod}
        selectedDeliveryMethod={activeDeliveryMethod}
      />
      <DeliveryOptionSelectorTabContainer
        inputsDisabled={isConfirmingSelection}
        activeDeliveryMethod={activeDeliveryMethod}
        deliveryMethod={DeliveryMethod.HomeDelivery}
        onSelect={onSelect}
        isConfirmingSelection={isConfirmingSelection}
      />
      <DeliveryOptionSelectorTabContainer
        inputsDisabled={isConfirmingSelection}
        activeDeliveryMethod={activeDeliveryMethod}
        deliveryMethod={DeliveryMethod.Pickup}
        onSelect={onSelect}
        isConfirmingSelection={isConfirmingSelection}
      />
    </>
  )
}

const mapToStoreChange = (searchTerm: string) => (selectedDeliveryOption: DeliveryOption) => {
  /**
   * Re-use home delivery area's district city, if one was found matching
   * the searched postal code. Fall back to delivery area's own city
   */
  const city =
    (selectedDeliveryOption.deliveryMethod === DeliveryMethod.HomeDelivery &&
      selectedDeliveryOption.districts?.find((d) => d.postalCode === searchTerm)?.city) ||
    selectedDeliveryOption?.address?.city ||
    null

  /** search term is the postal code when searching for home delivery areas */
  const postalCode =
    (selectedDeliveryOption.deliveryMethod === DeliveryMethod.HomeDelivery && searchTerm) || ''

  return {
    isFastTrack: selectedDeliveryOption.isFastTrack,
    areaId: selectedDeliveryOption.areaId,
    city,
    postalCode,
    selectedStore: selectedDeliveryOption.store,
  }
}

const useGetFirstFastTrackSlot = () => {
  const fetchSlotFromArea = useGetDeliverySlotsFromAreaLazyQuery(new Date(), new Date())
  return (selectedAreaId: string) =>
    fetchSlotFromArea(selectedAreaId).then(({ data }) => {
      const deliverySlotsData = data ? sortResponseDeliverySlotsAndTimes(data) : null

      const firstFastTrackSlot =
        deliverySlotsData?.deliveryArea?.deliverySlots?.[0]?.deliveryTimes?.[0] || null

      return firstFastTrackSlot
    })
}
