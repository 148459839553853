import type { Modal } from './modal-service'

import { useReactiveVar } from '@apollo/client'
import { useIsOpen } from '@s-group/react-usercentrics'
import { useRouter } from 'next/router'
import { Fragment, useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'
import { deliveryModalStateVar } from 'services/Delivery/cache'
import { setMainAppInteractivity } from 'services/set-main-app-interactivity'

import { ModalContext } from './context'
import { ModalService } from './modal-service'

export const ModalRoot = () => {
  const state: Modal<unknown, void>[] | null = useReactiveVar(ModalService.state)
  const router = useRouter()
  const isUserCentricsModalOpen = useIsOpen()
  const isDeliveryModalOpen = useReactiveVar(deliveryModalStateVar).open
  // Creating portal root element for modals
  const isClientSide = typeof document !== 'undefined'
  const el = useRef(isClientSide ? document.createElement('div') : null)
  useEffect(() => createElementPortal(el.current), [el])

  const closeAllModals = useCallback(() => state?.forEach((x) => x.close()), [state])

  // close all modals on route change
  useEffect(() => {
    if (router && router.events) {
      router.events.on('routeChangeStart', closeAllModals)
    }
    return () => {
      if (router && router.events) {
        router.events.off('routeChangeStart', closeAllModals)
      }
    }
  }, [closeAllModals, router])

  const handleEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && state && state?.length) {
        state[state?.length - 1].onCancel()
      }
    },
    [state],
  )

  // Pressing ESC will close the topmost modal on stack
  useEffect(() => {
    window.addEventListener('keyup', handleEscape)
    return () => window.removeEventListener('keyup', handleEscape)
  }, [handleEscape])

  // if we have modals open, main application element should be non-interactive for screen readers
  useEffect(() => setMainAppInteractivity(state?.length < 1), [state?.length])

  const modal = state ? state[state?.length - 1] : null
  return !isDeliveryModalOpen && !isUserCentricsModalOpen && isClientSide && el.current && modal
    ? createPortal(
        <RemoveScroll enabled={!!state?.length}>
          <ModalContext.Provider
            key={modal.id}
            value={{
              onCancel: modal.onCancel,
              onSubmit: modal.onSubmit,
            }}
          >
            <Fragment key={modal.id}>{modal.modal}</Fragment>
          </ModalContext.Provider>
        </RemoveScroll>,
        el.current,
      )
    : null
}

const createElementPortal = (el: HTMLElement | null) => {
  if (el) {
    document.body.appendChild(el)
    el.id = 'modal-root'
  }
  return () => {
    if (el) {
      document.body.removeChild(el)
    }
  }
}
