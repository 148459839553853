import type { RemoteData } from 'domain/remote-data'

import { useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'
import {
  geolocationEnabledVar,
  geolocationErrorVar,
  geolocationLoadingVar,
  geolocationPositionVar,
} from 'services/Delivery/cache'

import { getCurrentPosition } from './use-geolocation-position/get-current-position'

/** Get current geolocation position, if enabled and available. */
export const useGeolocationPosition = (): RemoteData<
  GeolocationPositionError | null,
  GeolocationPosition
> => {
  const enabled = useReactiveVar(geolocationEnabledVar)
  const error = useReactiveVar(geolocationErrorVar)
  const loading = useReactiveVar(geolocationLoadingVar)
  const position = useReactiveVar(geolocationPositionVar)

  useEffect(() => {
    let canceled = false
    if (enabled) {
      geolocationLoadingVar(true)

      getCurrentPosition({ enableHighAccuracy: true, timeout: 5000 })
        .then((currentPosition) => {
          if (!canceled) {
            geolocationErrorVar(null)
            geolocationPositionVar(currentPosition)
          }
        })
        .catch((e) => {
          if (!canceled) {
            geolocationErrorVar(e)
            geolocationEnabledVar(false)
          }
        })
        .finally(() => {
          if (!canceled) {
            geolocationLoadingVar(false)
          }
        })
    }

    return () => void (canceled = true)
  }, [enabled])

  if (!enabled) {
    return { type: 'NOT_ASKED' }
  }

  if (loading) {
    return { type: 'LOADING' }
  }

  if (error || !position) {
    return { type: 'FAILURE', error }
  }

  return { type: 'SUCCESS', data: position }
}
