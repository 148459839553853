import type { ClientCartItem, Product } from '@shared/gql/document-nodes'

import { getMainCategoryName } from 'domain/get-main-category-name'
import { calculateItemCount } from 'domain/item-count'
import { defaultTo } from 'ramda'

export const mapProductToClientCartItem = (
  cartItem: ClientCartItem,
  storeProduct?: Product,
): ClientCartItem => {
  const itemCount = calculateItemCount(cartItem, storeProduct)
  return {
    __typename: 'ClientCartItem',
    ean: cartItem.ean,
    id: cartItem.id,
    replace: cartItem.replace,
    itemCount,
    additionalInfo: defaultTo(null, cartItem.additionalInfo),
    productType: cartItem.productType,
    inStoreSelection: !!storeProduct,
    price: defaultTo(cartItem.price, storeProduct?.price),
    approxPrice: defaultTo(cartItem.approxPrice, storeProduct?.approxPrice),
    basicQuantityUnit: defaultTo(cartItem.basicQuantityUnit, storeProduct?.basicQuantityUnit),
    name: defaultTo(cartItem.name, storeProduct?.name),
    priceUnit: defaultTo(cartItem.priceUnit, storeProduct?.priceUnit),
    quantityMultiplier: defaultTo(cartItem.quantityMultiplier, storeProduct?.quantityMultiplier),
    mainCategoryName: storeProduct
      ? getMainCategoryName(storeProduct) || cartItem.mainCategoryName
      : cartItem.mainCategoryName,
    isAgeLimitedByAlcohol: defaultTo(
      cartItem.isAgeLimitedByAlcohol,
      storeProduct?.isAgeLimitedByAlcohol,
    ),
    countryName: {
      fi: defaultTo(cartItem.countryName?.fi, storeProduct?.countryName?.fi),
      __typename: 'CountryName',
    },
    frozen: defaultTo(cartItem.frozen, storeProduct?.frozen),
    packagingLabelCodes: defaultTo(cartItem.packagingLabelCodes, storeProduct?.packagingLabelCodes),
    comparisonPrice: defaultTo(cartItem.comparisonPrice, storeProduct?.comparisonPrice),
    comparisonUnit: defaultTo(cartItem.comparisonUnit, storeProduct?.comparisonUnit),
    campaignPrice: defaultTo(cartItem.campaignPrice, storeProduct?.pricing?.campaignPrice),
    lowest30DayPrice: defaultTo(cartItem.lowest30DayPrice, storeProduct?.pricing?.lowest30DayPrice),
    regularPrice: defaultTo(cartItem.regularPrice, storeProduct?.pricing?.regularPrice),
    campaignPriceValidUntil: defaultTo(
      cartItem.campaignPriceValidUntil,
      storeProduct?.pricing?.campaignPriceValidUntil,
    ),
  }
}
