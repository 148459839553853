import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useOnRouteChangeStart = (onRouteChangeStart: (path?: string) => void): void => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChangeStart = (path: string): void => {
      // Skip triggering on initial render with SSG https://github.com/vercel/next.js/issues/11639
      if (router.isReady) {
        onRouteChangeStart(path)
      }
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [router, onRouteChangeStart])
}
