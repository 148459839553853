import type { Brand } from '@shared/domain/brand'
import type { GetContentfulContent, ServiceBannerEntry } from 'types/contentful'
import type {
  IPageLayoutFields,
  IServiceBannersFields,
  ISiteFields,
  IStorePageContentFields,
} from 'types/contentful/generated'

import { noindex } from 'utils/content-pages/robots'
import { commonLogger } from 'utils/log/common-logger'

import { createClient } from './create-client'

export interface ContentfulStateProp {
  contentfulState: GetContentfulContent | null
}

export const getContentfulContent = async (brand: Brand): Promise<GetContentfulContent> => {
  try {
    const [siteResult, serviceBannersResult] = await Promise.all([
      getSiteContent(brand),
      getServiceBannerContent(),
    ])

    const footer = siteResult?.footer?.fields || null
    const serviceBanners =
      serviceBannersResult?.serviceBannerItems?.map<ServiceBannerEntry>((items) => ({
        banner: items.fields,
        id: items.sys.id,
      })) || []

    return {
      footer,
      frontPage: siteResult?.frontPage?.fields || null,
      serviceBanners,
    }
  } catch (error) {
    await commonLogger.error({
      error,
      message: 'Failed to get site content',
      data: { brand },
    })

    return { footer: null, frontPage: null, serviceBanners: null }
  }
}

const getSiteContent = async (brand: Brand): Promise<ISiteFields | null> => {
  try {
    const contentfulClient = createClient()
    const result = await contentfulClient.getEntries<ISiteFields>({
      content_type: 'site',
      include: 7,
      'fields.brand': brand,
    })

    if (!result?.items?.length) {
      return null
    }

    return result.items[0].fields || null
  } catch (error) {
    await commonLogger.error({
      error,
      message: 'Failed to get contentful site content',
      data: { brand },
    })
    return null
  }
}

const getServiceBannerContent = async (): Promise<IServiceBannersFields | null> => {
  try {
    const contentfulClient = createClient()
    const result = await contentfulClient.getEntries<IServiceBannersFields>({
      content_type: 'serviceBanners',
      include: 1,
    })

    if (!result?.items?.length) {
      return null
    }

    return result.items[0].fields || null
  } catch (error) {
    await commonLogger.error({
      error,
      message: 'Failed to get service information banner content',
    })
    return null
  }
}

export const getPageLayoutContent = async (options: {
  name?: string
  path?: string
}): Promise<IPageLayoutFields | null> => {
  const { name, path } = options

  if (!name && !path) {
    return null
  }

  try {
    const contentfulClient = createClient()
    const result = await contentfulClient.getEntries<IPageLayoutFields>({
      content_type: 'pageLayout',
      include: 7,
      'fields.path': path,
      'fields.name': name,
    })

    return result.items?.[0]?.fields || null
  } catch (error) {
    await commonLogger.error({
      error,
      message: 'Failed to get page layout',
      data: { name, path },
    })

    return null
  }
}

export const getStorePageContent = async (brand: Brand) => {
  try {
    const contentfulClient = createClient()
    const result = await contentfulClient.getEntries<IStorePageContentFields>({
      content_type: 'storePageContent',
      'fields.brand': brand,
      include: 7,
    })

    return result.items?.[0]?.fields || null
  } catch (error) {
    await commonLogger.error({
      error,
      message: 'Failed to get store page content',
      data: { brand },
    })

    return null
  }
}

export const getPageLayoutPaths = async (): Promise<{ path: string; updatedAt: string }[]> => {
  const contentfulClient = createClient()
  const result = await contentfulClient.getEntries<IPageLayoutFields>({
    content_type: 'pageLayout',
    limit: 1000,
  })

  return result.items
    .filter(
      (entry) =>
        entry.fields.path &&
        !noindex(entry.fields.robotsMetaTags) &&
        entry.fields.pageType !== 'front-page',
    )
    .map((entry) => ({
      path: entry.fields.path || '',
      updatedAt: entry.sys.updatedAt,
    }))
}
