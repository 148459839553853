import type { IncidentValidationError } from 'domain/error-messages/error-parse'
import type { ValidationError } from 'validation/validators'

import { isError } from 'domain/error-messages/error-messages'
import { trackSelectedSlotIsClosed } from 'services/DeliverySlot/tracking'

import { trackCustomEvent } from './custom-events/custom-events'
import { EventAction, EventCategory } from './interfaces/data-layer-events'

export const trackError = (error: IncidentValidationError | ValidationError): void => {
  const type = error.data?.errorType
  if (type && isError(type)) {
    switch (type) {
      case 'deliverySlotIsClosed':
        trackSelectedSlotIsClosed()
        return
      case 'deliverySlotAvailability':
        trackCustomEvent({
          category: EventCategory.ECOMMERCE,
          action: EventAction.SHOW_ERROR_MESSAGE,
          label: 'selected-timeslot-not-available',
        })
    }
  }
}
