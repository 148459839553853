import type {
  CartItem,
  ClientCartItem,
  Customer,
  CustomerInput,
  UserOrderCartItem,
} from '@shared/gql/document-nodes'

import { ProductType } from '@shared/gql/document-nodes'
import { defaultTo } from 'ramda'

export const mapCartItemsAsCartItemInput = (cartItems: CartItem[]): ClientCartItem[] => {
  const mappingResult = cartItems.map((cartItem): ClientCartItem => {
    const ean = defaultTo('', cartItem.ean)
    return {
      __typename: 'ClientCartItem',
      additionalInfo: cartItem.additionalInfo,
      id: cartItem.id,
      // Assumption since this information isn't stored at the date of creation
      inStoreSelection: true,
      replace: cartItem.replace as boolean,
      // Check if we need to figure this out
      approxPrice: false,
      basicQuantityUnit: cartItem.basicQuantityUnit as string,
      ean,
      itemCount: Number(defaultTo('0', cartItem.itemCount)),
      name: defaultTo('', cartItem.name),
      price: cartItem.price,
      priceUnit: cartItem.priceUnit as string,
      // Check if we need to figure this out
      quantityMultiplier: 1,
      mainCategoryName: '',
      productType: defaultTo(ProductType.Product, cartItem.productType),
      isAgeLimitedByAlcohol: false,
      countryName: {
        fi: '',
        __typename: 'CountryName',
      },
      frozen: false,
      packagingLabelCodes: null,
      /* The following requires mapping from backend */
      comparisonPrice: null,
      comparisonUnit: null,
      campaignPrice: null,
      lowest30DayPrice: null,
      campaignPriceValidUntil: null,
      regularPrice: null,
    }
  })
  return mappingResult
}

export const mapUserOrderCartItemsAsCartItemInput = (
  cartItems: UserOrderCartItem[],
): ClientCartItem[] => {
  const mappingResult = cartItems.map((cartItem): ClientCartItem => {
    const ean = defaultTo('', cartItem.ean)
    return {
      __typename: 'ClientCartItem',
      additionalInfo: cartItem.additionalInfo,
      id: cartItem.id,
      // Assumption since this information isn't stored at the date of creation
      inStoreSelection: true,
      replace: cartItem.replace ?? true,
      // Check if we need to figure this out
      approxPrice: false,
      basicQuantityUnit: defaultTo('', cartItem.basicQuantityUnit),
      ean,
      itemCount: Number(defaultTo('0', cartItem.itemCount)),
      name: defaultTo('', cartItem.name),
      price: defaultTo(0, cartItem.price),
      priceUnit: defaultTo('', cartItem.basicQuantityUnit),
      // Check if we need to figure this out
      quantityMultiplier: 1,
      mainCategoryName: '',
      productType: defaultTo(ProductType.Product, cartItem.productType),
      isAgeLimitedByAlcohol: false,
      countryName: {
        fi: '',
        __typename: 'CountryName',
      },
      frozen: false,
      packagingLabelCodes: null,
      campaignPrice: null,
      lowest30DayPrice: null,
      campaignPriceValidUntil: null,
      regularPrice: null,
      /* The following requires mapping from backend */
      comparisonPrice: null,
      comparisonUnit: null,
    }
  })
  return mappingResult
}

export const mapCustomerToCustomerInput = (customer: Customer): CustomerInput => ({
  address: customer.address,
  bonusCard: customer.bonusCard,
  city: customer.city,
  companyName: customer.companyName,
  contact: customer.contact,
  email: customer.email as string,
  firstName: customer.firstName as string,
  invoiceAddress: customer.invoiceAddress,
  invoiceCity: customer.invoiceCity,
  invoiceCompanyName: customer.invoiceCompanyName,
  invoiceFirstName: customer.invoiceFirstName,
  invoiceLastName: customer.invoiceLastName,
  invoiceNumber: customer.invoiceNumber,
  invoicePhone: customer.invoicePhone,
  invoicePostalCode: customer.invoicePostalCode,
  lastName: customer.lastName as string,
  newsletter: customer.newsletter,
  phone: customer.phone as string,
  postalCode: customer.postalCode,
  sMembershipCardLastName: customer.sMembershipCardLastName,
  sMembershipCardPostalCode: customer.sMembershipCardPostalCode,
  sMembershipNumber: customer.sMembershipNumber,
})
