import { ApolloError } from '@apollo/client'

export const getApolloErrorCode = (error?: Error) =>
  (error instanceof ApolloError && (error.graphQLErrors?.[0]?.extensions?.code as string)) || ''

export const getApolloErrorType = (error?: Error) =>
  (error instanceof ApolloError && (error.graphQLErrors?.[0]?.extensions?.errorType as string)) ||
  ''

export const isApollo404Error = (error?: unknown): boolean =>
  error instanceof ApolloError && getApolloErrorCode(error) === 'http_404'
