import type { ApolloClient } from '@apollo/client'
import type {
  GetMandatoryProductsQuery,
  GetMandatoryProductsQueryVariables,
  Maybe,
} from '@shared/gql/document-nodes'

import { DeliveryMethod, GetMandatoryProductsDocument } from '@shared/gql/document-nodes'

enum ProductType {
  HomeDelivery = 'Kotiinkuljetus',
  PickUp = 'Nouto',
}

export const getDeliverySlotPrice = async (
  apolloClient: ApolloClient<unknown>,
  storeId: Maybe<string>,
  deliveryMethod: Maybe<DeliveryMethod>,
  deliverySlotId: Maybe<string>,
  orderId: Maybe<string>,
  reservationId: Maybe<string>,
): Promise<number> => {
  if (!storeId) {
    throw new Error(`Store id is missing`)
  }

  if (!deliveryMethod) {
    throw new Error(`Delivery method is missing`)
  }

  if (!deliverySlotId) {
    throw new Error(`Delivery slot id is missing`)
  }

  let _: never

  const mandatoryProductsData = await apolloClient.query<
    GetMandatoryProductsQuery,
    GetMandatoryProductsQueryVariables
  >({
    query: GetMandatoryProductsDocument,
    variables: {
      id: storeId,
      deliveryMethod,
      deliverySlotId,
      orderId,
      reservationId,
    },
    fetchPolicy: 'network-only' /** Always fetch prices from remote */,
  })

  const homeDeliveryPrice = mandatoryProductsData.data.store?.mandatoryProducts?.find(
    (product) => product?.name === ProductType.HomeDelivery,
  )?.price as number

  const pickUpPrice = mandatoryProductsData.data.store?.mandatoryProducts?.find(
    (product) => product?.name === ProductType.PickUp,
  )?.price as number

  switch (deliveryMethod) {
    case DeliveryMethod.HomeDelivery: {
      return homeDeliveryPrice
    }

    case DeliveryMethod.Pickup: {
      return pickUpPrice
    }

    default: {
      _ = deliveryMethod
      return _
    }
  }
}
