import type { Modify } from '../local-storage'
import type { ApolloClient } from '@apollo/client'
import type {
  ClientCartItem,
  GetSelectedStoreIdQuery,
  OrderEditActiveResponse,
} from '@shared/gql/document-nodes'
import type { RemoteClientCartItem } from 'services/ClientCart/utils/get-client-cart-item'
import type { TypeOf } from 'zod'

import { getConfig } from '@shared/config'
import { CART_SCHEMA_VERSION, CartSchema } from '@shared/domain/local-storage'
import { GetSelectedStoreIdDocument, ProductType } from '@shared/gql/document-nodes'
import { getMainCategoryName } from 'domain/get-main-category-name'
import { getClientCartItem } from 'services/ClientCart/utils/get-client-cart-item'
import { removeNullableFields } from 'utils/remove-nullable-fields'

import { LocalStorageService } from '../local-storage'

const { domain } = getConfig()

const schemaValidator = (data: TypeOf<typeof CartSchema>) => CartSchema.parse(data)

const KEY_CART_DATA = 'cart-data'

const newEmptyLocalStorageCartData = (): TypeOf<typeof CartSchema> => {
  return {
    cacheVersion: CART_SCHEMA_VERSION,
    cart: {
      cartItems: [],
    },
    orderEditActive: null,
  }
}

const modifyCartData = (modifier: Modify<TypeOf<typeof CartSchema>>) => {
  LocalStorageService.modify<TypeOf<typeof CartSchema>>(
    modifier,
    newEmptyLocalStorageCartData,
    KEY_CART_DATA,
    schemaValidator,
  )
}

const saveOrderEdit = (orderEdit: OrderEditActiveResponse) => {
  modifyCartData((current) => ({
    ...current,
    orderEditActive: orderEdit,
  }))
}

const isClientCartItem = (x: ClientCartItem | RemoteClientCartItem | null): x is ClientCartItem =>
  x?.__typename === 'ClientCartItem'

const isRemoteClientCartItem = (
  x: ClientCartItem | RemoteClientCartItem | null,
): x is RemoteClientCartItem => x?.__typename === 'Product'

const saveCartItems = async (
  cartItems: ClientCartItem[],
  apolloClient: ApolloClient<object>,
): Promise<void> => {
  const serviceProducts = cartItems.filter((item) => item.productType === ProductType.ServiceFee)
  const packagingProducts = cartItems.filter(
    (item) => item.productType === ProductType.PackagingProduct,
  )
  const clientCartItems = cartItems.filter((item) => item.productType === ProductType.Product)

  // Products are cached by their id and storeId, but cart items do not have any...
  const storeId =
    apolloClient.cache.readQuery<GetSelectedStoreIdQuery>({
      query: GetSelectedStoreIdDocument,
    })?.selectedStoreId || domain.defaultStoreId

  const decoratedItems: ClientCartItem[] = await Promise.all(
    clientCartItems.map((partialCartItem) =>
      getClientCartItem(apolloClient, partialCartItem.id, storeId)
        .catch(() => null)
        .then((result) => {
          const mainCategoryName =
            partialCartItem?.mainCategoryName ||
            (isClientCartItem(result) ? result.mainCategoryName : getMainCategoryName(result))

          const additionalInfo =
            partialCartItem?.additionalInfo ||
            (isClientCartItem(result) && result?.additionalInfo) ||
            ''

          const replace =
            partialCartItem?.replace ?? (isClientCartItem(result) && result?.replace) ?? true

          /**
           * The `inStoreSelection` field doesn't exist on remote Products or CartItems.
           * if the result here is from remote, it is "in store selection" unless it's
           * a global fallback.
           *
           * @see https://jira.sok.fi/browse/VOIK-8770
           */
          const inStoreSelection = isRemoteClientCartItem(result)
            ? !result.isGlobalFallback
            : partialCartItem.inStoreSelection

          return {
            ...partialCartItem,
            ...removeNullableFields(result ?? {}),
            __typename: 'ClientCartItem' as const,
            /** The following fields should be overridden from incoming partialCartItems */
            additionalInfo,
            inStoreSelection,
            itemCount: partialCartItem.itemCount,
            mainCategoryName,
            replace,
            campaignPrice: partialCartItem.campaignPrice ?? null,
            lowest30DayPrice: partialCartItem.lowest30DayPrice ?? null,
            campaignPriceValidUntil: partialCartItem.campaignPriceValidUntil ?? null,
          }
        }),
    ),
  )

  const incorrectTypesFound = decoratedItems.find((item) => item.__typename !== 'ClientCartItem')

  if (incorrectTypesFound) {
    throw new Error(
      `Incorrect types are being stored: Typename=${incorrectTypesFound.__typename}, (${incorrectTypesFound.name}, ${incorrectTypesFound.id})`,
    )
  }

  modifyCartData((current) => ({
    ...current,
    cart: {
      ...current.cart,
      cartItems: [...serviceProducts, ...packagingProducts, ...decoratedItems],
    },
  }))
}

const loadCartItems = (): ClientCartItem[] => {
  const data = LocalStorageService.load<TypeOf<typeof CartSchema>>(KEY_CART_DATA, schemaValidator)
  return (
    data?.cart.cartItems.map((x) => ({
      ...x,
      campaignPrice: x.campaignPrice ?? null,
      campaignPriceValidUntil: x.campaignPriceValidUntil ?? null,
      lowest30DayPrice: x.lowest30DayPrice ?? null,
      regularPrice: x.regularPrice ?? null,
    })) || []
  )
}

const loadCartData = (): TypeOf<typeof CartSchema> | null => {
  return LocalStorageService.load<TypeOf<typeof CartSchema>>(KEY_CART_DATA, schemaValidator)
}

const clearCart = (): void => {
  modifyCartData((current) => ({
    ...current,
    cart: {
      ...current.cart,
      cartItems: [],
    },
  }))
}

const reset = (): void => {
  LocalStorageService.clear(KEY_CART_DATA)
}

export const CartDAO = {
  clearCart,
  loadCartData,
  loadCartItems,
  localStorageKey: KEY_CART_DATA,
  reset,
  saveCartItems,
  saveOrderEdit,
}
