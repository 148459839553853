export const SIDE_PANEL_VISIBILITY_KEY = 'sidepanel_visibility'

export const persistSidepanelVisibility = (value: boolean) => {
  localStorage.setItem(SIDE_PANEL_VISIBILITY_KEY, String(value))
}

export const readPersistedSidepanelVisibility = (): boolean | null => {
  const value = localStorage.getItem(SIDE_PANEL_VISIBILITY_KEY)

  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
  }

  return null
}
