import { ProductType } from '@shared/gql/document-nodes'

interface CartItemLike {
  productType: ProductType | null
  isAgeLimitedByAlcohol: boolean | null
}

interface PurchaseableProduct {
  productType: ProductType.Product
  isAgeLimitedByAlcohol: boolean | null
}

const isPurchaseableProduct = (item: CartItemLike): item is PurchaseableProduct =>
  item.productType === ProductType.Product

const isAlcoholProduct = (item: CartItemLike): boolean =>
  isPurchaseableProduct(item) && item.isAgeLimitedByAlcohol === true

export const containsAlcoholProducts = (productItems: CartItemLike[]): boolean =>
  productItems.some(isAlcoholProduct)

const isNotAlcoholProduct = (item: CartItemLike): boolean =>
  isPurchaseableProduct(item) && item.isAgeLimitedByAlcohol !== true

export const containsOtherThanAlcoholProducts = (productItems: CartItemLike[]): boolean =>
  productItems.some(isNotAlcoholProduct)

export const filterOutAlcoholProducts = <T extends CartItemLike>(productItems: T[]): T[] =>
  productItems.filter((product) =>
    isPurchaseableProduct(product) ? isNotAlcoholProduct(product) : true,
  )
