import type { NormalizedCacheObject } from '@apollo/client'

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { captureException, captureMessage } from '@sentry/nextjs'
import { getConfig } from '@shared/config'
import apolloLogger from 'apollo-link-logger'
import sha256 from 'crypto-js/sha256'
import { resolvers } from 'services/resolvers'
import { typeDefs as sOstosListaTypeDefs } from 'types/gql/sostoslista-type-defs'
import { typeDefs } from 'types/gql/type-defs'
import { IS_BROWSER } from 'utils/is-browser'

import { authorizationLink } from './middleware/authorization-link'
import { errorLink } from './middleware/error-link'
import { httpLink } from './middleware/http-link'
import { retryLink } from './middleware/retry-link'
import { onApolloClientReset } from './on-apollo-client-reset'
import { SOSTOSLISTA_CLIENT_NAME } from './s-ostoslista-client-name'
import { typePolicies } from './type-policies'
import { writeInitialData } from './write-initial-data-to-apollo'

const { apq, sOstoslistaApi, stage } = getConfig()

const isProduction = process.env.NODE_ENV === 'production'

const voikukkaApiLink = ApolloLink.from(
  [
    stage === 'dev' ? apolloLogger : [],
    retryLink,
    errorLink({ captureMessage, captureException }),
    authorizationLink,
    apq.enabled
      ? createPersistedQueryLink({
          sha256: (message) => sha256(message).toString(),
          useGETForHashedQueries: apq.useGet,
        })
      : [],
    httpLink, // This must be the last one in the link list.
  ].flat(),
)

const shopplingListsApiLink = ApolloLink.from([
  errorLink({ captureMessage, captureException }),
  authorizationLink,
  new HttpLink({
    uri: sOstoslistaApi.url,
  }),
])

export const createApolloClient = (
  initialState?: NormalizedCacheObject | null,
): ApolloClient<NormalizedCacheObject> => {
  const cache = new InMemoryCache({ typePolicies }).restore(initialState || {})
  writeInitialData(cache, initialState)

  const apolloClient = new ApolloClient({
    ssrMode: !IS_BROWSER, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.split(
      (op) => op.getContext().clientName === SOSTOSLISTA_CLIENT_NAME,
      shopplingListsApiLink,
      voikukkaApiLink,
    ),
    cache,
    connectToDevTools: !isProduction,
    resolvers,
    /**
     * Do not include typeDefs into production bundle
     * @see https://www.apollographql.com/docs/react/local-state/client-side-schema/
     */
    typeDefs: isProduction ? undefined : [typeDefs, sOstosListaTypeDefs],
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
      },
      query: {
        fetchPolicy: 'cache-first',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
  })

  // We will reset the store at least during logout.
  // Therefore we need to re-initialize the cache with default data.
  apolloClient.onResetStore(onApolloClientReset(apolloClient))

  return apolloClient
}
