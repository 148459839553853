export const USERCENTRICS_SETTINGS_ID = 'Yv591VkI5'

export const USERCENTRICS_WINDOW_EVENT_NAME = 'ucEvent'

export enum ServiceId {
  CrazyEgg = 'BQmw0hqWW',
  DoubleClickFloodlight = 'IzsBtUXGV',
  Dynatrace = 'ci8XuAsoZ',
  FacebookPixel = 'i5vncCgcQ',
  Giosg = 'oci6Y5EqG',
  GoogleAds = 'VVLbafIsj',
  GoogleAnalytics4 = 'SeTo8y6oZ',
  GoogleAnalyticsAdFeatures = 'YoPzr-yrw',
  GoogleAnalytics = 'qpCyEMgA_',
  GoogleOptimize = 'zmHsLbhO1',
  GoogleTagManager = 'GkxomOJSe',
  Hotjar = '_Y0b4d72q',
  Loop54 = 'wzZweLjrI',
  Nosto = 'MKwkdKel8',
  Salesforce = 'cAwRyMjpZ',
  SCID = 'xQh55pmQ7',
  Sentry = 'TyWjZ913t',
  SnowPlow = 't_UUeh1Vw',
  UsercentricsConsentManagementPlatform = 'x5XFlRncB',
  VisualWebsiteOptimizerVWO = 'u17B6NZYb',
  CitrusAd = 'mSsCwhaaz',
}
