export interface WrapperProps {
  navigationVisible?: boolean
}

export interface EditOrder {
  notification: boolean
}

export enum DeliverySelectionStage {
  SELECT_METHOD_AND_STORE,
  SELECT_DELIVERY_TIME,
  SELECT_DELIVERY_TIME_RESERVATION_ERROR,
  SELECT_DELIVERY_TIME_ALCOHOL_CONFLICT,
}

type feelings = 'hate' | 'dislike' | 'fine' | 'like' | 'love'
type freeTextInputs = Record<feelings, string>

interface giosgAPI {
  views: unknown
  elements: unknown
  urlParams: Record<string, string>
  dataFields: Record<string, string>
  store: {
    data: {
      dataFields: Record<string, string>
    }
  }
}

declare global {
  interface Window {
    giosgNpsHandler: undefined | ((buttonClicked: feelings, freeTextInputs: freeTextInputs) => void)
    giosgAPIConnector: undefined | ((giosgAPI: giosgAPI) => void)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    embedded_svc: undefined | any
  }
}

export interface WithTestId {
  'data-test-id'?: string
}
