import type { ApolloError, MutationResult, OperationVariables, QueryResult } from '@apollo/client'

import { isNonNullable } from 'utils/nullable'

export type RemoteData<E, D> =
  | { type: 'NOT_ASKED' }
  | { type: 'LOADING' }
  | { type: 'FAILURE'; error: E }
  | { type: 'SUCCESS'; data: D }
export type RemoteDataQuery<T> = RemoteData<ApolloError | Error, T>

const fold =
  <R, E, D>(
    notAsked: () => R,
    loading: () => R,
    failure: (error: E) => R,
    success: (data: D) => R,
  ) =>
  (remoteData: RemoteData<E, D>): R => {
    switch (remoteData.type) {
      case 'NOT_ASKED':
        return notAsked()
      case 'LOADING':
        return loading()
      case 'FAILURE':
        return failure(remoteData.error)
      case 'SUCCESS':
        return success(remoteData.data)
    }
  }

const from = <Data, Variables extends OperationVariables>(
  x: MutationResult<Data> | QueryResult<Data, Variables>,
): RemoteData<ApolloError | Error, Data | null> => {
  const { loading, error, data, called } = x

  if (!called) {
    return {
      type: 'NOT_ASKED',
    }
  }

  if (loading) {
    return {
      type: 'LOADING',
    }
  }

  if (error) {
    return {
      type: 'FAILURE',
      error,
    }
  }

  return {
    type: 'SUCCESS',
    data: data ? data : null,
  }
}

type Mapper = <E, A, B>(mapper: (data: A) => B) => (x: RemoteData<E, A>) => RemoteData<E, B>
const map: Mapper =
  <A, B>(mapper: (data: A) => B) =>
  <E>(x: RemoteData<E, A>): RemoteData<E, B> => {
    if (x.type === 'SUCCESS') {
      return {
        type: 'SUCCESS',
        data: mapper(x.data),
      }
    }

    return x
  }

const nonNullable = <A>(x: RemoteData<Error, A>): RemoteData<Error, NonNullable<A>> => {
  if (x.type === 'SUCCESS') {
    if (isNonNullable(x.data)) {
      return {
        type: 'SUCCESS',
        data: x.data,
      }
    }

    return {
      type: 'FAILURE',
      error: new Error('No data received'),
    }
  }

  return x
}

const toNullable = <A>(x: RemoteData<Error, A>): A | null => (x.type === 'SUCCESS' ? x.data : null)
const defaultTo =
  <A, B>(b: B) =>
  (x: RemoteData<Error, A>): A | B =>
    x.type === 'SUCCESS' ? x.data : b

export const remoteData = {
  map,
  from,
  fold,
  nonNullable,
  toNullable,
  defaultTo,
}
