import type { CustomerType } from '@shared/gql/document-nodes'

import { dataLayer } from './tag-manager'

export const trackLoginStatus = (
  profile: { customerId: string; customerType: CustomerType } | null,
): void =>
  dataLayer({
    event: 'pushData',
    customDimensions: profile
      ? {
          loggedIn: true,
          customerId: profile.customerId,
          customerType: profile.customerType,
        }
      : {
          loggedIn: false,
        },
  })
