import type { DeliveryDetailsInfo } from '@shared/gql/document-nodes'
import type { FC, PropsWithChildren } from 'react'
import type { useGetSelectedDeliverySlot } from 'services/Delivery/hooks/use-get-delivery-slot'
import type { useSelectedDeliverySlot } from 'services/DeliverySlot/hooks'

import { IconStatusInfoFilled } from '@s-group/design-system-icons'
import { getConfig } from '@shared/config'
import { NextLink } from 'components/NextLink'
import { toFormattedDate } from 'domain/date-time'
import { Trans, useTranslation } from 'react-i18next'
import { useIsDeliverySlotReservationEnabled } from 'services/DeliverySlot/hooks'
import styled, { css, useTheme } from 'styled-components'
import { useGetProfileActionHandlers } from 'utils/hooks/account/use-get-profile-action-handlers'
import { useIsLoggedIn } from 'utils/hooks/account/use-is-logged-in'

const { featureFlags } = getConfig()

export const Notifications: FC<{
  selectedSlotQuery: ReturnType<typeof useSelectedDeliverySlot | typeof useGetSelectedDeliverySlot>
  deliveryDetailsInfo: DeliveryDetailsInfo
  reservationError?: boolean
  isSlotSelected: boolean
}> = ({ deliveryDetailsInfo, selectedSlotQuery, reservationError, isSlotSelected }) => {
  const { t } = useTranslation()
  const { onLoginClick } = useGetProfileActionHandlers()
  const isLoggedIn = useIsLoggedIn()
  const isDeliverySlotReservationEnabled = useIsDeliverySlotReservationEnabled()
  const deliveryReservationError =
    isDeliverySlotReservationEnabled && reservationError && t('Reservation failed message')

  const deliverySlotClosedError =
    selectedSlotQuery.type === 'SUCCESS' &&
    selectedSlotQuery.data?.isClosed &&
    t('Sorry, your earlier delivery slot is no longer available', {
      deliveryDay: deliveryDetailsInfo?.deliveryDate
        ? toFormattedDate(deliveryDetailsInfo.deliveryDate, {
            weekday: false,
            year: false,
          })
        : '',
      deliveryTime: deliveryDetailsInfo.deliveryTime,
    })

  const deliverySelectionErrorString = deliveryReservationError || deliverySlotClosedError

  if (deliverySelectionErrorString) {
    return (
      <Notifier type="ERROR" data-test-id="delivery-time-selection-error">
        {deliverySelectionErrorString}
      </Notifier>
    )
  }

  return (
    (featureFlags.slotReservationInAllStoresForLoggedInUsers &&
      (isLoggedIn ? (
        <Notifier>
          {isSlotSelected ? (
            <Trans>
              Your reservation will be freed when you are <strong>inactive for 15 minutes</strong>.
            </Trans>
          ) : (
            t('We will reserve the time for you.')
          )}
        </Notifier>
      ) : (
        <Notifier>
          {t('Want to reserve your time?')}{' '}
          <NextLink as="button" onClick={onLoginClick}>
            {t('Want to reserve your time? CTA Action_LOGIN')}
          </NextLink>
        </Notifier>
      ))) ||
    null
  )
}

const _Notifier: FC<PropsWithChildren<{ className?: string; type?: 'ERROR' }>> = ({
  className,
  type,
  children,
}) => {
  const theme = useTheme()
  return (
    <div className={className}>
      {type === 'ERROR' && (
        <IconStatusInfoFilled color={theme.colors.SDS_BRAND_COLOR_ELEMENT_ERROR} />
      )}
      <div>{children}</div>
    </div>
  )
}

const Notifier = styled(_Notifier)(
  ({ type, theme }) => css`
    text-align: ${type === 'ERROR' ? 'left' : 'center'};
    margin: 0.5rem 0rem 0rem 0rem;
    ${theme.sizes.xxs}
    color: ${type === 'ERROR'
      ? theme.colors.SDS_BRAND_COLOR_TEXT_ERROR
      : theme.colors.SDS_BRAND_COLOR_TEXT_STRONGER_GREY};
    ${NextLink} {
      background: none;
      border: none;
      font-weight: ${theme.fontWeights.bold};
      cursor: pointer;
      display: inline;
      font-size: inherit;
    }
    display: flex;
    justify-content: space-around;
    span {
      flex: 0;
      align-items: center;
      margin-right: 8px;
    }
    div {
      flex: 1;
    }
  `,
)
