import type { DeliveryOption } from 'domain/delivery-option'
import type { FC } from 'react'

import { IconCommerceDeliveryFast, IconCommerceDeliveryRobot } from '@s-group/design-system-icons'
import { DeliveryMethod, HomeDeliveryType } from '@shared/gql/document-nodes'
import { QuickPickupOutline16 } from '@sok/design-system'
import { AlcoholNotAvailableIcon } from 'components/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const AlcoholNotAllowedBadge: FC<{ deliveryMethod: DeliveryMethod }> = ({ deliveryMethod }) => {
  const { t } = useTranslation()
  const title = t(
    deliveryMethod === DeliveryMethod.HomeDelivery
      ? 'Alcoholic items are only available for pickup'
      : 'Alcohol products not sold in this pickup point',
  )

  return (
    <i aria-label={title} title={title}>
      <AlcoholNotAvailableIcon />
    </i>
  )
}

const FastTrackBadge: FC<Pick<DeliveryOption, 'deliveryMethod' | 'homeDeliveryType'>> = ({
  deliveryMethod,
  homeDeliveryType,
}) => {
  const { t } = useTranslation()
  const title = t(deliveryMethod, { context: 'FAST_TRACK' })

  const HomeDeliveryIcon =
    homeDeliveryType === HomeDeliveryType.Robot
      ? IconCommerceDeliveryRobot
      : IconCommerceDeliveryFast

  return (
    <i aria-label={title} title={title}>
      {deliveryMethod === DeliveryMethod.HomeDelivery ? (
        <HomeDeliveryIcon />
      ) : (
        <QuickPickupOutline16 />
      )}
    </i>
  )
}

interface Props
  extends Pick<DeliveryOption, 'deliveryMethod' | 'isFastTrack' | 'homeDeliveryType'> {
  alcoholSellingNotAllowed?: boolean
  className?: string
}

const _OptionLabels: FC<Props> = ({
  alcoholSellingNotAllowed,
  className,
  deliveryMethod,
  isFastTrack,
  homeDeliveryType,
}) => {
  if (!isFastTrack && !alcoholSellingNotAllowed) return null

  return (
    <ul className={className}>
      {isFastTrack && (
        <li>
          <FastTrackBadge deliveryMethod={deliveryMethod} homeDeliveryType={homeDeliveryType} />
        </li>
      )}

      {alcoholSellingNotAllowed && (
        <li>
          <AlcoholNotAllowedBadge deliveryMethod={deliveryMethod} />
        </li>
      )}
    </ul>
  )
}

export const OptionLabels = styled(_OptionLabels)(({ theme }) => ({
  color: 'inherit',
  display: 'flex',
  gap: theme.spacings.xxSmall,
  listStyle: 'none',

  i: {
    display: 'flex',
  },
}))
