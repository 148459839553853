import type { LinkProps } from 'next/link'
import type { AnchorHTMLAttributes, ReactNode } from 'react'

import Link from 'next/link'
import { forwardRef } from 'react'
import styled from 'styled-components'

interface Props extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  children?: ReactNode
  'data-test-id'?: string
  href: LinkProps['href']
  hrefAs?: LinkProps['as']
  external?: boolean
  shallow?: boolean
}

const _NextLink = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      children,
      'data-test-id': testId,
      href,
      hrefAs,
      external = false,
      target,
      rel,
      shallow,
      ...rest
    },
    ref,
  ) => {
    return (
      <Link as={hrefAs} href={href} passHref shallow={shallow}>
        <a
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          data-test-id={testId}
          ref={ref}
          rel={rel ?? external ? 'noopener noreferrer' : undefined}
          target={target ?? external ? '_blank' : undefined}
        >
          {children}
        </a>
      </Link>
    )
  },
)

_NextLink.displayName = 'NextLink'

/**
 * An SPA-compatible <a> component which uses `next/link` underneath.
 *
 * @example <caption>Basic usage</caption>
 * <NextLink href="/">Osta ruokaa</NextLink>
 *
 * @example <caption>With Router query</caption>
 * <NextLink href="/sivu/[slug]" hrefAs="/sivu/ajankohtaista">Ajankohtaista</NextLink>
 */
export const NextLink = styled(_NextLink)(({ theme }) => ({
  color: theme.color.action,

  '&:hover': {
    color: theme.color.highlightHoverColor,
  },

  '&:focus': {
    color: theme.color.highlightFocusColor,
  },

  '&:active': {
    color: theme.color.actionVariant,
  },
}))
