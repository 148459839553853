import type { FC } from 'react'

import { useQuery, useReactiveVar } from '@apollo/client'
import { DeliveryMethod, GetDeliveryDetailsInfoDocument } from '@shared/gql/document-nodes'
import { Modal } from '@sok/design-system'
import { useRouter } from 'next/router'
import { memo } from 'react'
import { isMobileSafari } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { pendingClientCartItemsVar } from 'services/ClientCart/cache'
import { closeDeliveryModal, deliveryModalStateVar } from 'services/Delivery/cache'
import styled, { css } from 'styled-components'
import { DeliverySelectionStage } from 'types/global'
import { useUpdateCartItemsOnStoreChange } from 'utils/cart/use-update-cart-items-on-store-change'
import { useOnRouteChangeStart } from 'utils/hooks/use-on-route-change-start'
import { URL_PARAM_PICKUP } from 'utils/hooks/use-open-delivery-modal'
import { useURLSearchParams } from 'utils/hooks/use-url-search-params'
import { useSelectStoreFromUrlParams } from 'utils/store/select-store-from-url-params'

import { DeliverySelectedContent } from './DeliveryModalContent'
import { DeliveryOptionSelectorContainer } from './method-and-store/DeliveryOptionSelectorContainer'
import { useDeliveryModalStageTracker } from './use-delivery-modal-stage-tracker'

const CustomModal = styled(Modal)(
  ({ theme }) => css`
    @media (min-width: ${theme.breakpoints.tablet}) {
      max-width: 480px;
    }

    .modal-header {
      margin-bottom: ${theme.spacings.small};
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      padding: 0;
    }

    /* Some customers have issues with slots not showing, this should at least make one slot visible */
    min-height: 440px;
  `,
)

/**
 * @todo Open Modals using service from `services/Modal`
 * @see https://jira.sok.fi/browse/VOIK-9959
 */
const DeliveryModal: FC = () => {
  const { t } = useTranslation()
  const deliverySelectionStage = useReactiveVar(deliveryModalStateVar).stage
  const title = useDeliveryModalTitle()

  // Fix issue on mobile safari where the modal would be cropped
  // if the app advertisment banner is visible
  const viewPortDelta = isMobileSafari
    ? window.document.documentElement.clientHeight - window.innerHeight
    : undefined

  useUpdateCartItemsOnStoreChange()
  useDeliveryModalStageTracker(deliverySelectionStage)

  const { removeParams } = useURLSearchParams({ push: false })

  const handleCancel = () => {
    closeDeliveryModal()
    pendingClientCartItemsVar(null)
    removeParams([URL_PARAM_PICKUP, undefined])
  }

  return (
    <CustomModal
      closeLabel={t('Close')}
      data-test-id="delivery-select-modal"
      disableFocusTrap
      footer={null}
      onCancel={handleCancel}
      style={{
        maxHeight: viewPortDelta ? `calc(100% - ${viewPortDelta}px)` : undefined,
      }}
      title={t(title)}
      visible
    >
      {deliverySelectionStage === DeliverySelectionStage.SELECT_METHOD_AND_STORE ? (
        <DeliveryOptionSelectorContainer />
      ) : (
        <DeliverySelectedContent stage={deliverySelectionStage} />
      )}
    </CustomModal>
  )
}

const getBasePath = (asPath?: string) => asPath?.split(/[?#]/)[0] || ''

export const DeliveryModalContainer = memo(() => {
  useSelectStoreFromUrlParams()
  const isDeliveryModalOpen = useReactiveVar(deliveryModalStateVar).open

  const router = useRouter()
  useOnRouteChangeStart((path) => {
    if (getBasePath(router.asPath) !== getBasePath(path)) {
      closeDeliveryModal()
    }
  })

  return isDeliveryModalOpen ? <DeliveryModal /> : null
})

DeliveryModalContainer.displayName = 'DeliveryModalContainer'

const useDeliveryModalTitle = (): string => {
  const { data } = useQuery(GetDeliveryDetailsInfoDocument)
  const deliveryDetailsInfo = data?.deliveryDetailsInfo
  const deliverySelectionStage = useReactiveVar(deliveryModalStateVar).stage
  if (!deliveryDetailsInfo) {
    throw new Error('Illegal state. deliveryDetailsInfo is null')
  }
  const { deliveryMethod } = deliveryDetailsInfo

  if (!deliveryMethod) {
    return 'Select delivery method'
  }

  const isHomeDelivery = deliveryMethod === DeliveryMethod.HomeDelivery

  switch (deliverySelectionStage) {
    case DeliverySelectionStage.SELECT_METHOD_AND_STORE:
      return 'Select delivery method'

    case DeliverySelectionStage.SELECT_DELIVERY_TIME:
    case DeliverySelectionStage.SELECT_DELIVERY_TIME_RESERVATION_ERROR:
      return isHomeDelivery ? 'Select delivery time' : 'Select pickup time'

    case DeliverySelectionStage.SELECT_DELIVERY_TIME_ALCOHOL_CONFLICT:
      return ''
  }
}
