import { useEffect } from 'react'
import { DeliverySelectionStage } from 'types/global'
import { trackCustomEvent } from 'utils/tracking/custom-events/custom-events'
import {
  DeliveryDialogSteps,
  EventAction,
  EventCategory,
} from 'utils/tracking/interfaces/data-layer-events'

const STAGE_TO_STEP: Record<DeliverySelectionStage, DeliveryDialogSteps> = {
  [DeliverySelectionStage.SELECT_METHOD_AND_STORE]: DeliveryDialogSteps.SELECT_METHOD_AND_STORE,
  [DeliverySelectionStage.SELECT_DELIVERY_TIME]: DeliveryDialogSteps.SELECT_DELIVERY_TIME,
  [DeliverySelectionStage.SELECT_DELIVERY_TIME_RESERVATION_ERROR]:
    DeliveryDialogSteps.SELECT_DELIVERY_TIME_RESERVATION_ERROR,
  [DeliverySelectionStage.SELECT_DELIVERY_TIME_ALCOHOL_CONFLICT]:
    DeliveryDialogSteps.SELECT_DELIVERY_TIME_ALCOHOL_CONFLICT,
}

export const useDeliveryModalStageTracker = (stage: DeliverySelectionStage): void => {
  useEffect((): void => {
    trackCustomEvent({
      category: EventCategory.DELIVERY_SELECTIONS,
      action: EventAction.OPEN,
      label: STAGE_TO_STEP[stage],
    })
  }, [stage])
}
