import { useQuery } from '@apollo/client'
import { GetLocalAuthenticationTokensDocument } from '@shared/gql/document-nodes'

import { useIsClientSide } from '../use-is-client-side'

export const useIsLoggedIn = (): boolean => {
  const isClientSide = useIsClientSide()
  const { data: localAccessTokens } = useQuery(GetLocalAuthenticationTokensDocument, { ssr: false })
  return isClientSide && !!localAccessTokens?.authenticationTokens?.accessToken
}
