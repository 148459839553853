import type { CacheVersion } from '@shared/domain/local-storage'
import type { TypeOf, ZodType } from 'zod'

import { getConfig } from '@shared/config'
import { IS_BROWSER } from 'utils/is-browser'
import { commonLogger } from 'utils/log/common-logger'
import { ZodError } from 'zod'

const { stage } = getConfig()

export type SchemaBase = TypeOf<typeof CacheVersion>

export type ZodTypeParser = ZodType['parse']

export const SAVE_ERROR_MSG =
  'Schema being written does not match with local storage schema. Local storage schema is not up to date.'

export const LOAD_ERROR_MSG = 'Persisted schema in local storage differs. Stale data detected.'

const save = <T extends SchemaBase>(data: T, storageKey: string, zodTypeParser: ZodTypeParser) => {
  if (!IS_BROWSER || !window.localStorage) return null

  try {
    const parsed = zodTypeParser(data)
    window.localStorage.setItem(storageKey, JSON.stringify(parsed))
  } catch (error) {
    if (error instanceof ZodError) {
      commonLogger.errorSync({
        message: SAVE_ERROR_MSG,
        error: error.issues,
      })
    }

    if (stage === 'dev') {
      throw error
    }
  }
}

const load = <T extends SchemaBase>(storageKey: string, zodTypeParser: ZodTypeParser): T | null => {
  if (!IS_BROWSER || !window.localStorage) return null

  const data = window.localStorage.getItem(storageKey)
  if (!data) return null

  try {
    return zodTypeParser(JSON.parse(data))
  } catch (error) {
    if (error instanceof ZodError) {
      commonLogger.warnSync({
        message: LOAD_ERROR_MSG,
        data: error.issues,
      })
    }

    return null
  }
}

export type Modify<T> = (current: T) => T

const modify = <T extends SchemaBase>(
  mutate: Modify<T>,
  getInitialState: () => T,
  storageKey: string,
  zodTypeParser: ZodTypeParser,
): void => {
  const initialState = load<T>(storageKey, zodTypeParser) ?? getInitialState()
  save(mutate(initialState), storageKey, zodTypeParser)
}

const clear = (key: string) => {
  if (!IS_BROWSER || !window.localStorage) return null
  window.localStorage.removeItem(key)
}

export const LocalStorageService = {
  clear,
  load,
  modify,
  save,
}
