import styled, { css } from 'styled-components'

export const StyledDeliveryError = styled.div(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacings.xxLarge,
  textAlign: 'center',
}))

export const StyledInfoLabelBold = styled.p`
  ${({ theme }) => css(theme.variants.paragraph1.regular)};
  margin-top: ${({ theme }) => theme.spacings.medium};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.sizes.s.fontSize};
    margin-top: ${({ theme }) => theme.spacings.small};
  }
`

export const StyledInfoLabel = styled.p(({ theme }) => ({
  ...theme.variants.body1.regular,
  marginTop: theme.spacings.small,
  maxWidth: 360,
}))

export const StyledImage = styled.img`
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 50%;
  }
`
