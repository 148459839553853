import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Void: any
}

export type Address = {
  __typename: 'Address'
  apartmentNumber: Maybe<Scalars['String']>
  postalCity: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
  streetAddress: Maybe<Scalars['String']>
}

export type AddressAutocompleteResult = {
  __typename: 'AddressAutocompleteResult'
  block: Maybe<Scalars['String']>
  building: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  countryCode: Maybe<Scalars['String']>
  countryName: Maybe<Scalars['String']>
  county: Maybe<Scalars['String']>
  district: Maybe<Scalars['String']>
  /** Unique id of address, from HERE Geocoding and Search */
  hereId: Scalars['String']
  houseNumber: Maybe<Scalars['String']>
  label: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
  state: Maybe<Scalars['String']>
  stateCode: Maybe<Scalars['String']>
  street: Maybe<Scalars['String']>
  subblock: Maybe<Scalars['String']>
  subdistrict: Maybe<Scalars['String']>
  /** The localized display name of this result item. */
  title: Scalars['String']
}

export type AddressGeocodeResult = {
  __typename: 'AddressGeocodeResult'
  block: Maybe<Scalars['String']>
  building: Maybe<Scalars['String']>
  city: Scalars['String']
  countryCode: Maybe<Scalars['String']>
  countryName: Maybe<Scalars['String']>
  county: Maybe<Scalars['String']>
  district: Maybe<Scalars['String']>
  /** Unique id of address, from HERE Geocoding and Search */
  hereId: Scalars['String']
  houseNumber: Maybe<Scalars['String']>
  label: Maybe<Scalars['String']>
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  postalCode: Scalars['String']
  resultType: Maybe<AddressGeocodeResultType>
  state: Maybe<Scalars['String']>
  stateCode: Maybe<Scalars['String']>
  street: Scalars['String']
  subblock: Maybe<Scalars['String']>
  subdistrict: Maybe<Scalars['String']>
  /** The localized display name of this result item. */
  title: Scalars['String']
}

export enum AddressGeocodeResultType {
  AddressBlock = 'addressBlock',
  AdministrativeArea = 'administrativeArea',
  HouseNumber = 'houseNumber',
  Intersection = 'intersection',
  Locality = 'locality',
  Place = 'place',
  PostalCodePoint = 'postalCodePoint',
  Street = 'street',
}

export type AddressValidateResult = {
  __typename: 'AddressValidateResult'
  errors: Array<AddressValidationError>
  isValid: Scalars['Boolean']
}

export enum AddressValidationError {
  BuildingNumberNotFound = 'BuildingNumberNotFound',
  NoResultFromThirdPartyApi = 'NoResultFromThirdPartyAPI',
  NonParseableAddress = 'NonParseableAddress',
  StreetAddressNotFound = 'StreetAddressNotFound',
}

export enum AlcoholSellingStatus {
  Allowed = 'ALLOWED',
  NotAllowed = 'NOT_ALLOWED',
}

export type Allergen = {
  __typename: 'Allergen'
  allergenTypeCode: Maybe<Scalars['String']>
  allergenTypeText: Maybe<Scalars['String']>
  levelOfContainmentCode: Maybe<Scalars['String']>
}

export type AuthTokens = {
  __typename: 'AuthTokens'
  accessToken: Maybe<Scalars['String']>
  idToken: Maybe<Scalars['String']>
  refreshToken: Maybe<Scalars['String']>
}

export type AuthenticationTokens = {
  __typename: 'AuthenticationTokens'
  accessToken: Maybe<Scalars['String']>
  idToken: Maybe<Scalars['String']>
  modified: Maybe<Scalars['Float']>
  refreshToken: Maybe<Scalars['String']>
}

export type AuthorizePaymentResponse = {
  __typename: 'AuthorizePaymentResponse'
  authorized: Maybe<Scalars['Boolean']>
  orderId: Maybe<Scalars['String']>
}

export type AvailablePackagingMaterial = {
  __typename: 'AvailablePackagingMaterial'
  ean: Scalars['String']
  materialPrice: Scalars['Float']
  materialType: MaterialType
}

export type BatchRecommendation = {
  __typename: 'BatchRecommendation'
  products: Maybe<ProductList>
  raw: Array<BatchRecommendationProduct>
  runId: Scalars['String']
}

export type BatchRecommendationProductsArgs = {
  storeId: Scalars['String']
}

export type BatchRecommendationProduct = {
  __typename: 'BatchRecommendationProduct'
  ean: Scalars['String']
  sok_id: Scalars['String']
}

export enum BatchRecommendationTypeEnum {
  Cart = 'CART',
  Seasonal = 'SEASONAL',
  YouMayNeed = 'YOU_MAY_NEED',
}

export type BonusInfo = {
  __typename: 'BonusInfo'
  membershipNumber: Maybe<Scalars['String']>
}

export type BonusWidgetToken = {
  __typename: 'BonusWidgetToken'
  token: Scalars['String']
}

export type BoundingBox = {
  bottomRight: Location
  topLeft: Location
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CartCapacity = {
  __typename: 'CartCapacity'
  /**
   * Capacity is [0, n].
   * If capacity < 1; there is still space for more products.
   * If capacity = 1; cart is full.
   * If capacity > 1; then capacity - 1 = exceeded capacity.
   */
  capacity: Scalars['Float']
  items: Array<CartItemCapacity>
  /** Cart ids of products that don't fit into the selected container dimensions or have missing measurement data. */
  rejected: Array<Scalars['String']>
  warnings: Array<CartCapacityDimensionWarning | CartCapacityNumericWarning>
  /** Total weight of the cart in kilograms (KG). */
  weight: Scalars['Float']
}

export type CartCapacityDimensionWarning = ICartCapacityWarning & {
  __typename: 'CartCapacityDimensionWarning'
  dimensionUnit: Scalars['String']
  dimensions: Array<Scalars['Int']>
  type: CartCapacityWarningType
}

export type CartCapacityNumericWarning = ICartCapacityWarning & {
  __typename: 'CartCapacityNumericWarning'
  integerValue: Scalars['Int']
  type: CartCapacityWarningType
  unit: Scalars['String']
}

export enum CartCapacityWarningType {
  MaxCapacityExceeded = 'MAX_CAPACITY_EXCEEDED',
  MaxWeightExceeded = 'MAX_WEIGHT_EXCEEDED',
  OverSizedProducts = 'OVER_SIZED_PRODUCTS',
  SomeProductsAreMissingDimensions = 'SOME_PRODUCTS_ARE_MISSING_DIMENSIONS',
}

export type CartItem = {
  __typename: 'CartItem'
  additionalInfo: Maybe<Scalars['String']>
  basicQuantityUnit: Maybe<Scalars['String']>
  brand: Maybe<Scalars['String']>
  collectingPriority: Maybe<Scalars['Int']>
  ean: Maybe<Scalars['String']>
  id: Scalars['ID']
  itemCount: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  price: Scalars['Float']
  priceUnit: Maybe<Scalars['String']>
  productType: Maybe<ProductType>
  replace: Maybe<Scalars['Boolean']>
}

export type CartItemCapacity = {
  __typename: 'CartItemCapacity'
  capacity: Scalars['Float']
  id: Scalars['String']
  itemCount: Scalars['Int']
  status: CartItemCapacityStatus
  volume: Scalars['Float']
  weight: Scalars['Float']
}

export enum CartItemCapacityStatus {
  ExceedsContainerDimensions = 'EXCEEDS_CONTAINER_DIMENSIONS',
  Ok = 'OK',
  OkButNoMeasurements = 'OK_BUT_NO_MEASUREMENTS',
}

export type CartItemForCapacityInput = {
  id: Scalars['ID']
  itemCount: Scalars['Float']
}

export type CartItemInput = {
  additionalInfo?: InputMaybe<Scalars['String']>
  basicQuantityUnit?: InputMaybe<Scalars['String']>
  brand?: InputMaybe<Scalars['String']>
  collectingPriority?: InputMaybe<Scalars['Int']>
  ean?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  itemCount?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  price: Scalars['Float']
  priceUnit?: InputMaybe<Scalars['String']>
  productType?: InputMaybe<ProductType>
  replace?: InputMaybe<Scalars['Boolean']>
}

export type CartRecommendation = {
  __typename: 'CartRecommendation'
  amounts: Array<CartRecommendationProduct>
  products: Maybe<ProductList>
  runId: Scalars['String']
}

export type CartRecommendationProductsArgs = {
  from: InputMaybe<Scalars['Int']>
  limit: InputMaybe<Scalars['Int']>
  order: InputMaybe<SortOrder>
  orderBy: InputMaybe<SortKey>
  storeId: Scalars['String']
}

export type CartRecommendationProduct = EanProduct & {
  __typename: 'CartRecommendationProduct'
  amount: Scalars['Int']
  ean: Scalars['String']
}

export enum CartRecommendationType {
  Cart = 'CART',
  Try = 'TRY',
  YouMayNeed = 'YOU_MAY_NEED',
}

export type CitrusAd = {
  __typename: 'CitrusAd'
  banners: Maybe<Array<Maybe<CitrusAdBanner>>>
  contentStandardId: Maybe<Scalars['String']>
  slotId: Maybe<Scalars['String']>
}

/** CitrusAd Banner X return items: https://developers.citrusad.com/integration/reference/banner-x-reference-1 */
export type CitrusAdBanner = {
  __typename: 'CitrusAdBanner'
  backgroundColour: Maybe<Scalars['String']>
  backgroundImage: Maybe<Scalars['String']>
  backgroundImagePosition: Maybe<Scalars['String']>
  bannerText: Maybe<Scalars['String']>
  bannerTextColour: Maybe<Scalars['String']>
  citrusAdId: Scalars['String']
  citrusCampaignName: Maybe<Scalars['String']>
  citrusContentStandardId: Maybe<Scalars['String']>
  citrusExpiry: Maybe<Scalars['String']>
  citrusSlotId: Maybe<Scalars['String']>
  contentStandardId: Maybe<Scalars['String']>
  ctaFlag: Maybe<Scalars['Boolean']>
  ctaLink: Maybe<Scalars['String']>
  ctaText: Maybe<Scalars['String']>
  ctaTextAccessibility: Maybe<Scalars['String']>
  gtins: Maybe<Array<Maybe<Scalars['String']>>>
  headingText: Maybe<Scalars['String']>
  heroImage: Maybe<Scalars['String']>
  heroImageAltText: Maybe<Scalars['String']>
  position: Maybe<Scalars['Int']>
  secondaryBackgroundImage: Maybe<Scalars['String']>
  secondaryBackgroundImagePosition: Maybe<Scalars['String']>
  secondaryHeroImage: Maybe<Scalars['String']>
  secondaryHeroImageAltText: Maybe<Scalars['String']>
  secondaryHeroMode: Maybe<Scalars['String']>
  sellerId: Maybe<Scalars['String']>
  slotId: Maybe<Scalars['String']>
}

export enum CitrusAdUserEventType {
  Click = 'CLICK',
  Impression = 'IMPRESSION',
  Purchase = 'PURCHASE',
}

export type ClientCartItem = {
  __typename: 'ClientCartItem'
  additionalInfo: Maybe<Scalars['String']>
  approxPrice: Scalars['Boolean']
  basicQuantityUnit: Scalars['String']
  campaignPrice: Maybe<Scalars['Float']>
  campaignPriceValidUntil: Maybe<Scalars['String']>
  comparisonPrice: Maybe<Scalars['Float']>
  comparisonUnit: Maybe<Scalars['String']>
  countryName: CountryName
  ean: Scalars['String']
  frozen: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  inStoreSelection: Scalars['Boolean']
  isAgeLimitedByAlcohol: Maybe<Scalars['Boolean']>
  itemCount: Scalars['Float']
  lowest30DayPrice: Maybe<Scalars['Float']>
  mainCategoryName: Scalars['String']
  name: Scalars['String']
  packagingLabelCodes: Maybe<Array<Scalars['String']>>
  price: Scalars['Float']
  priceUnit: Scalars['String']
  productType: ProductType
  quantityMultiplier: Scalars['Float']
  regularPrice: Maybe<Scalars['Float']>
  replace: Scalars['Boolean']
}

export enum CoOperative {
  Arina = 'ARINA',
  Eekoo = 'EEKOO',
  Eepee = 'EEPEE',
  Hameenmaa = 'HAMEENMAA',
  Hok = 'HOK',
  Keskimaa = 'KESKIMAA',
  Keula = 'KEULA',
  Koillismaa = 'KOILLISMAA',
  Kpo = 'KPO',
  Kso = 'KSO',
  Maakunta = 'MAAKUNTA',
  Peeassa = 'PEEASSA',
  Peremarket = 'PEREMARKET',
  Pko = 'PKO',
  Pok = 'POK',
  Satakunta = 'SATAKUNTA',
  Sso = 'SSO',
  SuurSavo = 'SUUR_SAVO',
  Tok = 'TOK',
  Vbo = 'VBO',
}

export type CollatedDeliverySlot = {
  __typename: 'CollatedDeliverySlot'
  date: Scalars['String']
  deliveryTimes: Array<DeliverySlot>
}

export type Company = {
  __typename: 'Company'
  identityCode: Maybe<Scalars['String']>
  invoiceId: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
}

export type Coordinates = {
  __typename: 'Coordinates'
  lat: Scalars['String']
  lon: Scalars['String']
}

export type Country = {
  __typename: 'Country'
  code: Scalars['String']
}

export enum CountryCode {
  Et = 'ET',
  Fi = 'FI',
}

export type CountryName = {
  __typename: 'CountryName'
  fi: Maybe<Scalars['String']>
}

export type Customer = {
  __typename: 'Customer'
  address: Maybe<Scalars['String']>
  /** Address part that can be translated to roof top coordinates e.g street address "Työmiehenrata 15" */
  addressLine1: Maybe<Scalars['String']>
  /** More detailed address part like apartment number, building, block etc.. */
  addressLine2: Maybe<Scalars['String']>
  bonusCard: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  companyName: Maybe<Scalars['String']>
  contact: Maybe<Scalars['Boolean']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  invoiceAddress: Maybe<Scalars['String']>
  invoiceCity: Maybe<Scalars['String']>
  invoiceCompanyName: Maybe<Scalars['String']>
  invoiceFirstName: Maybe<Scalars['String']>
  invoiceLastName: Maybe<Scalars['String']>
  invoiceNumber: Maybe<Scalars['String']>
  invoicePhone: Maybe<Scalars['String']>
  invoicePostalCode: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  newsletter: Maybe<Scalars['Boolean']>
  phone: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
  sMembershipCardLastName: Maybe<Scalars['String']>
  sMembershipCardPostalCode: Maybe<Scalars['String']>
  sMembershipNumber: Maybe<Scalars['String']>
}

export type CustomerDetailsResponse = {
  __typename: 'CustomerDetailsResponse'
  comment: Maybe<Scalars['String']>
  companyName: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
}

export type CustomerInput = {
  address?: InputMaybe<Scalars['String']>
  /** Address part that can be translated to roof top coordinates e.g street address "Työmiehenrata 15" */
  addressLine1?: InputMaybe<Scalars['String']>
  /** More detailed address part like apartment number, building, block etc.. */
  addressLine2?: InputMaybe<Scalars['String']>
  bonusCard?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  contact?: InputMaybe<Scalars['Boolean']>
  email: Scalars['String']
  firstName: Scalars['String']
  invoiceAddress?: InputMaybe<Scalars['String']>
  invoiceCity?: InputMaybe<Scalars['String']>
  invoiceCompanyName?: InputMaybe<Scalars['String']>
  invoiceFirstName?: InputMaybe<Scalars['String']>
  invoiceLastName?: InputMaybe<Scalars['String']>
  invoiceNumber?: InputMaybe<Scalars['String']>
  invoicePhone?: InputMaybe<Scalars['String']>
  invoicePostalCode?: InputMaybe<Scalars['String']>
  lastName: Scalars['String']
  newsletter?: InputMaybe<Scalars['Boolean']>
  phone: Scalars['String']
  postalCode?: InputMaybe<Scalars['String']>
  sMembershipCardLastName?: InputMaybe<Scalars['String']>
  sMembershipCardPostalCode?: InputMaybe<Scalars['String']>
  sMembershipNumber?: InputMaybe<Scalars['String']>
}

export enum CustomerType {
  B2b = 'b2b',
  B2c = 'b2c',
}

export type DayAvailability = {
  __typename: 'DayAvailability'
  /** Whether or not there is a available slot for the date */
  available: Scalars['Boolean']
  /**
   * Date for the slot availability
   * Format: 2023-01-01
   */
  date: Scalars['String']
}

export enum DayLabel {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED',
}

export type DeleteUserDataResult = {
  __typename: 'DeleteUserDataResult'
  success: Scalars['Boolean']
}

export type DeliveryArea = {
  __typename: 'DeliveryArea'
  address: Maybe<DeliveryAreaAddress>
  alcoholSellingAllowed: AlcoholSellingStatus
  areaId: Scalars['String']
  availablePackagingMaterials: Maybe<Array<AvailablePackagingMaterial>>
  /**
   * For Home Delivery Area the delivery area polygon.
   * Currently in use only for Robot deliveries.
   */
  deliveryAreaPolygon: Maybe<Array<Point>>
  deliveryMethod: DeliveryMethod
  deliverySlots: Maybe<Array<CollatedDeliverySlot>>
  description: Maybe<Scalars['String']>
  /**
   * Geospatial distance from deliveryarea to `to`  in meters
   * User location will be resolved by either from context or by `to` parameter.
   * If non of the previous is provided the return value will be null.
   *
   * NOTES:
   * 1. searchHomeDeliveryTypes `location` parameter is set the context.
   * 2. nextDeliverySlot `to` parameter is set to the context. `distance` has resolved after `nextDeliverySlot` i.e after in the Query.
   */
  distance: Maybe<Scalars['Int']>
  districts: Maybe<Array<DeliveryDistrict>>
  /** @deprecated This is only to enable a feature in clients. Will be removed in near future. */
  dynamicPricing: Scalars['Boolean']
  /**
   * Only available for fast track slots.
   *
   * fastTrackOpeningHours: { from: ISODATE, to: ISODATE, status: OPEN/CLOSED }
   * 16:00pm
   * fastTrackOpeningHours: { from: ISODATE-TODAY-9:00, to: ISODATE-TODAY-21:00, status: OPEN }
   * 22:00pm
   * fastTrackOpeningHours: { from: ISODATE-TOMORROW-9:00, to: ISODATE-TOMORROW-21:00, status: CLOSED }
   */
  fastTrackOpeningHours: Maybe<FastTrackOpeningHours>
  homeDeliveryType: Maybe<HomeDeliveryType>
  isFastTrack: Scalars['Boolean']
  isRemote: Maybe<Scalars['Boolean']>
  location: Maybe<DeliveryLocation>
  name: Scalars['String']
  /**
   * @param rangeDays Duration of days to look for the next slot. Default is 14 days. Minimum is 1 and maximum 31
   * @deprecated Use nextDeliverySlot in public-schema
   */
  nextAvailableDeliverySlot: Maybe<DeliverySlot>
  nextDeliverySlot: Maybe<NextDeliverySlot>
  postalCodes: Maybe<Array<Scalars['String']>>
  /** @deprecated Will be removed from public-api */
  price: Scalars['Float']
  /** @deprecated This is only to enable a feature in clients. Will be removed in near future. */
  reservationEnabled: Scalars['Boolean']
  /**
   * Use this to query robot availability and ETA
   * @throws:
   *   - RobotDeliveryUnavailableError [400], when given coordinats are out of service area
   */
  robotAvailability: Maybe<RobotAvailability>
  store: Maybe<StoreInfo>
  storeId: Scalars['String']
  /** Parameter to restrict delivery area visibility */
  visibilityGroup: Maybe<Scalars['String']>
}

export type DeliveryAreaDeliverySlotsArgs = {
  endDate: InputMaybe<Scalars['String']>
  orderId: InputMaybe<Scalars['ID']>
  reservationId: InputMaybe<Scalars['ID']>
  startDate: InputMaybe<Scalars['String']>
}

export type DeliveryAreaDistanceArgs = {
  to: InputMaybe<Location>
}

export type DeliveryAreaNextAvailableDeliverySlotArgs = {
  rangeDays: InputMaybe<Scalars['Int']>
}

export type DeliveryAreaNextDeliverySlotArgs = {
  to: InputMaybe<Location>
}

export type DeliveryAreaRobotAvailabilityArgs = {
  to: Location
}

export type DeliveryAreaAddress = {
  __typename: 'DeliveryAreaAddress'
  city: Scalars['String']
  postalCode: Scalars['String']
  street: Scalars['String']
}

export type DeliveryAreaPin = {
  __typename: 'DeliveryAreaPin'
  areaId: Scalars['String']
  brand: Scalars['String']
  location: Point
  placeOfBusinessId: Scalars['String']
}

export type DeliveryAreaPinsResponse = {
  __typename: 'DeliveryAreaPinsResponse'
  pins: Maybe<Array<DeliveryAreaPin>>
}

export type DeliveryAreaSearchResponse = {
  __typename: 'DeliveryAreaSearchResponse'
  areas: Maybe<Array<DeliveryArea>>
  total: Maybe<Scalars['Int']>
}

export type DeliveryDetailsInfo = {
  __typename: 'DeliveryDetailsInfo'
  additionalInfo: Maybe<Scalars['String']>
  address: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  deliveryDate: Maybe<Scalars['String']>
  deliveryMethod: Maybe<DeliveryMethod>
  deliverySlotId: Maybe<Scalars['ID']>
  deliverySlotPrice: Maybe<Scalars['Float']>
  deliveryTime: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
}

export type DeliveryDistrict = {
  __typename: 'DeliveryDistrict'
  city: Maybe<Scalars['String']>
  postalCode: Scalars['String']
}

export type DeliveryLocation = {
  __typename: 'DeliveryLocation'
  address: Maybe<DeliveryAreaAddress>
  distance: Maybe<Scalars['Float']>
  distanceFromStore: Maybe<Scalars['Int']>
  latitude: Scalars['Float']
  longitude: Scalars['Float']
  robotAvailability: Maybe<RobotAvailability>
}

export type DeliveryLocationAddressArgs = {
  lang: InputMaybe<Scalars['String']>
}

export type DeliveryLocationDistanceFromStoreArgs = {
  storeId: Scalars['ID']
}

export type DeliveryLocationRobotAvailabilityArgs = {
  areaId: Scalars['ID']
}

export enum DeliveryMethod {
  HomeDelivery = 'HOME_DELIVERY',
  Pickup = 'PICKUP',
}

export type DeliverySlot = {
  __typename: 'DeliverySlot'
  alcoholSellingAllowed: AlcoholSellingStatus
  areaId: Scalars['String']
  availability: SlotAvailability
  /**
   * When will the slot be closed? I.E. When is the last moment to send new orders?
   * Format: 2021-02-07T19:00:00+02:00
   */
  closingTimestamp: Maybe<Scalars['String']>
  deliveryMethod: DeliveryMethod
  /** Format: ISO DateTime */
  endDateTime: Scalars['String']
  /** Estimated total time in minutes */
  estimatedFastTrackTime: Maybe<Scalars['Int']>
  /** Estimated remaining time until delivery in minutes */
  estimatedRemainingFastTrackTime: Maybe<Scalars['Int']>
  /** After slot is closed, new orders are no longer accepted */
  isClosed: Scalars['Boolean']
  isFastTrack: Scalars['Boolean']
  /**
   * If slot is not modifiable, it doesn't generally accept modifications.
   * Order has separate isModifiable field that can have different value depending on the order state
   */
  isModifiable: Scalars['Boolean']
  /** Slot length in minutes */
  length: Scalars['Int']
  /**
   * When is the modification cutoff? I.E. When is the last moment to send modifications?
   * Format: 2021-02-07T19:00:00+02:00
   */
  modificationTimestamp: Maybe<Scalars['String']>
  price: Scalars['Float']
  slotId: Scalars['String']
  /** Format: ISO DateTime */
  startDateTime: Scalars['String']
  /** In minutes since midnight */
  startTime: Scalars['Int']
  /** Human-readable time slot. E.g. 10:00-13:00 */
  time: Scalars['String']
  /** @deprecated will be removed from public schema */
  validFrom: Scalars['String']
}

export type DeliverySlotReservationResponse = {
  __typename: 'DeliverySlotReservationResponse'
  deliveryArea: DeliveryArea
  deliverySlot: DeliverySlot
  /** Format: 2021-02-07T19:00:00+02:00 */
  expiresAt: Scalars['String']
  reservationId: Scalars['String']
}

export type DeliveryStore = {
  __typename: 'DeliveryStore'
  areaId: Maybe<Scalars['ID']>
  availablePaymentMethods: Maybe<Array<Maybe<PaymentMethod>>>
  brand: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Maybe<Scalars['String']>
}

export enum DeliveryTimeStatus {
  Available = 'AVAILABLE',
  Full = 'FULL',
}

export enum DeviceType {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE',
}

export type DiscountProduct = {
  __typename: 'DiscountProduct'
  discountAmount: Scalars['Float']
  discountType: DiscountProductType
  ean: Scalars['String']
  id: Scalars['String']
  isValid: Scalars['Boolean']
  name: Scalars['String']
}

export enum DiscountProductType {
  FixedAmount = 'FIXED_AMOUNT',
  FixedDeliveryPrice = 'FIXED_DELIVERY_PRICE',
}

export enum Domain {
  Abc = 'ABC',
  Eprisma = 'EPRISMA',
  Herkku = 'HERKKU',
  SKaupat = 'S_KAUPAT',
}

export type DomainCustomer = {
  __typename: 'DomainCustomer'
  cardType: Maybe<Scalars['String']>
  companyName: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  stockmannCardNumber: Maybe<Scalars['String']>
  type: CustomerType
}

export type DomainDelivery = {
  __typename: 'DomainDelivery'
  address: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
}

export type DomainOrder = {
  __typename: 'DomainOrder'
  additionalInfo: Maybe<Scalars['String']>
  cartItems: Array<ClientCartItem>
  comment: Maybe<Scalars['String']>
  customer: DomainCustomer
  deliveryAddress: DomainDelivery
  deliveryAreaId: Maybe<Scalars['String']>
  deliverySlotId: Maybe<Scalars['String']>
  discountCode: Maybe<Scalars['String']>
  id: Maybe<Scalars['String']>
  orderStatus: Maybe<OrderStatus>
  payment: DomainPayment
  reservationId: Maybe<Scalars['String']>
  storeId: Maybe<Scalars['String']>
}

export type DomainPayment = {
  __typename: 'DomainPayment'
  invoiceNumber: Maybe<Scalars['String']>
  paymentMethod: Maybe<PaymentMethod>
  paymentStatus: Maybe<PaymentStatus>
}

export type EanProduct = {
  ean: Scalars['String']
}

export type ExternalOrdersPosData = {
  __typename: 'ExternalOrdersPOSData'
  accountID: Maybe<Scalars['String']>
  accountingStoreID: Scalars['String']
  loyaltyTransactionTotals: Maybe<Array<ExternalOrdersPosRoyaltyTransation>>
  ownerBenefit: Maybe<Scalars['String']>
  registerID: Scalars['String']
  registerTransNumber: Scalars['String']
  shipment: Maybe<Array<ExternalOrdersPosShipment>>
  signature: Maybe<Scalars['String']>
  storeCode: Scalars['String']
  storeID: Scalars['String']
  totalAmount: Scalars['String']
  transactionCode: Scalars['String']
  transactionDateTime: Scalars['String']
  transactionID: Scalars['String']
  type: Scalars['String']
  uniqueTransactionID: Maybe<Scalars['String']>
  versionNumber: Scalars['String']
  webStoreID: Scalars['String']
}

export type ExternalOrdersPosRoyaltyTransation = {
  __typename: 'ExternalOrdersPOSRoyaltyTransation'
  loyaltyBenefitAmount: Scalars['String']
  loyaltyCardID: Scalars['String']
  paymentBenefitAmount: Scalars['String']
  sequenceID: Scalars['String']
}

export type ExternalOrdersPosShipment = {
  __typename: 'ExternalOrdersPOSShipment'
  billOfLadingNr: Maybe<Scalars['String']>
  employeeID: Maybe<Scalars['String']>
  infoText: Maybe<Scalars['String']>
  orderID: Scalars['String']
  sentTime: Maybe<Scalars['String']>
  shipmentID: Scalars['String']
  status: Scalars['String']
  statusDate: Scalars['String']
  storeID: Scalars['String']
}

export enum FacetKey {
  BrandName = 'brandName',
  Category = 'category',
  Labels = 'labels',
  SupplierName = 'supplierName',
}

export type FastTrackOpeningHours = {
  __typename: 'FastTrackOpeningHours'
  from: Scalars['String']
  status: StoreOpeningStatus
  to: Scalars['String']
}

export type FavoriteItem = {
  __typename: 'FavoriteItem'
  count: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  ean: Scalars['String']
  modifiedAt: Scalars['String']
  product: Maybe<Product>
}

export type FavoriteItemProductArgs = {
  storeId: Scalars['ID']
}

export type FavoritesList = {
  __typename: 'FavoritesList'
  createdAt: Scalars['String']
  id: Scalars['String']
  isDefault: Scalars['Boolean']
  items: Array<FavoriteItem>
  modifiedAt: Scalars['String']
  name: Scalars['String']
  products: Maybe<Array<Product>>
  slug: Scalars['String']
}

export type FavoritesListProductsArgs = {
  fallbackToGlobal: InputMaybe<Scalars['Boolean']>
  includeAgeLimitedByAlcohol: InputMaybe<Scalars['Boolean']>
  storeId: Scalars['ID']
}

export type FavoritesListDeleteResult = {
  __typename: 'FavoritesListDeleteResult'
  id: Scalars['String']
}

export type FavoritesListPatchInput = {
  isDefault?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
}

export type FavoritesLists = {
  __typename: 'FavoritesLists'
  favoritesLists: Array<FavoritesList>
}

export type FavoritesOperationDataInput = {
  count?: InputMaybe<Scalars['String']>
  ean?: InputMaybe<Scalars['String']>
  listId: Scalars['String']
}

export type FavoritesOperationInput = {
  data: FavoritesOperationDataInput
  operation: FavoritesOperationOperationEnum
}

export enum FavoritesOperationOperationEnum {
  Delete = 'DELETE',
  Put = 'PUT',
  SetDefaultList = 'SET_DEFAULT_LIST',
}

export type FavoritesOperationResult = {
  __typename: 'FavoritesOperationResult'
  discardedCount: Scalars['Int']
  successfulCount: Scalars['Int']
}

export type Filters = {
  key: FacetKey
  value: Array<Scalars['String']>
}

export type Hierarchy = {
  __typename: 'Hierarchy'
  count: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  slug: Maybe<Scalars['String']>
}

export type HierarchyPathItem = {
  __typename: 'HierarchyPathItem'
  hidden: Scalars['Boolean']
  id: Scalars['String']
  name: Scalars['String']
  slug: Scalars['String']
}

export type HomeDeliverySlot = IHomeDeliverySlot & {
  __typename: 'HomeDeliverySlot'
  closingTime: Scalars['String']
  deliveryTimeEnd: Scalars['String']
  deliveryTimeStart: Scalars['String']
  id: Scalars['String']
  isAlcoholSellingAllowed: Scalars['Boolean']
  isOrderModificationAllowed: Scalars['Boolean']
  price: Scalars['Float']
}

export type HomeDeliverySlotsInStore = {
  __typename: 'HomeDeliverySlotsInStore'
  slots: Array<HomeDeliverySlot>
  store: StoreInfo
}

export enum HomeDeliveryType {
  Normal = 'NORMAL',
  Robot = 'ROBOT',
}

export type ICartCapacityWarning = {
  type: CartCapacityWarningType
}

export type IHomeDeliverySlot = {
  /**
   * When the slot will be closed and no more orders can be added to it
   * Format: 2023-01-01T12:00:00+02:00
   */
  closingTime: Scalars['String']
  /**
   * When order delivery time window end
   * Format: 2023-01-01T12:00:00+02:00
   */
  deliveryTimeEnd: Scalars['String']
  /**
   * When order delivery time window starts
   * Format: 2023-01-01T12:00:00+02:00
   */
  deliveryTimeStart: Scalars['String']
  /**
   * Unique identifier for a slot
   * Format: 2023-01-01:90ac047a-4b03-4292-adfd-476c0efe1404
   */
  id: Scalars['String']
  /** Whether or not alcohol selling is allowed for this slot */
  isAlcoholSellingAllowed: Scalars['Boolean']
  /** Whether or not orders added to this slot can be modified after they've been placed (set the NEW order status) */
  isOrderModificationAllowed: Scalars['Boolean']
  /** Slot price in EUR */
  price: Scalars['Float']
}

export type IStructuredFacet = {
  key: FacetKey
}

export type LocalizableText = {
  __typename: 'LocalizableText'
  default: Scalars['String']
  en: Maybe<Scalars['String']>
  et: Maybe<Scalars['String']>
  fi: Maybe<Scalars['String']>
  sv: Maybe<Scalars['String']>
}

export type LocalizedProductInfo = {
  __typename: 'LocalizedProductInfo'
  en: Maybe<LocalizedProductInfoFields>
  fi: Maybe<LocalizedProductInfoFields>
  sv: Maybe<LocalizedProductInfoFields>
}

export type LocalizedProductInfoFields = {
  __typename: 'LocalizedProductInfoFields'
  allergens: Maybe<Array<Allergen>>
  description: Maybe<Scalars['String']>
  ingredientStatement: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  storageGuideForConsumer: Maybe<Scalars['String']>
  userGuideForConsumer: Maybe<Scalars['String']>
}

export type LocalizedText = {
  __typename: 'LocalizedText'
  en: Maybe<Scalars['String']>
  et: Maybe<Scalars['String']>
  fi: Maybe<Scalars['String']>
  sv: Maybe<Scalars['String']>
}

export type Location = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

/**
 * Combined type for "Delivery Fee" products and "Packaging Material" products. These used to share the
 * same type with real Products, but now they have their own type with only a subset of the "Product" fields.
 */
export type MandatoryProduct = {
  __typename: 'MandatoryProduct'
  ean: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  price: Scalars['Float']
  productType: ProductType
  /** @deprecated This field is not populated, only included for backwards-compatibility with the Product type */
  storeId: Maybe<Scalars['String']>
}

export enum MaterialType {
  CardboardBox = 'CARDBOARD_BOX',
  DepositBag = 'DEPOSIT_BAG',
  StandardPackaging = 'STANDARD_PACKAGING',
}

export type Municipality = {
  __typename: 'Municipality'
  code: Scalars['String']
  name: Maybe<LocalizableText>
}

export type Mutation = {
  __typename: 'Mutation'
  /** No specific errors */
  addUserNotificationSubcription: Maybe<UserNotificationSubscription>
  /** No specific errors */
  addUserPushNotificationDevice: Maybe<PushNotificationDevice>
  /**
   * @throws:
   * - AuthorizePaymentError [500]
   * - SlotFullError [409]
   * - SlotClosedError [409]
   * - ReservationNotFoundError [404](reservation has been expired)
   */
  authorizePayment: Maybe<AuthorizePaymentResponse>
  /**
   * @throws:
   * - OrderNotFoundError [404]
   */
  cancelOrder: Maybe<Order>
  cancelOrderModification: Scalars['Boolean']
  clearAuthenticationRedirectPath: Maybe<Scalars['Boolean']>
  clearAuthenticationTokens: Maybe<Scalars['Boolean']>
  clearCartAndOrderDetails: Maybe<Scalars['Boolean']>
  /**
   * Creates a temporary reservation into a slot.
   *
   * @param deliverySlotId ID of the delivery slot where the reservation is made.
   * @throws:
   * - BadRequestError [400]
   * - NotFoundError [404]
   * - SlotFullError [409]
   * - SlotClosedError [409]
   */
  createDeliverySlotReservation: DeliverySlotReservationResponse
  /**
   * @throws:
   * - MissingSlotId [400]
   * - PostalCodeNotSupportedError [400]
   * - SlotFullError [409]
   * - SlotClosedError [409]
   * - DiscountCodeExpiredError [404]
   * - DiscountCodeNotFoundError[404]
   */
  createOrder: Maybe<Order>
  /**
   * @throws:
   * - CreatePaymentError [500]
   * - PaymentCardNotFoundError [404]
   * - SlotClosedError [409]
   */
  createPayment: Maybe<PaymentTerminalAddress>
  /** @deprecated With shoppinglist multiple favorites list architecture no longer needed */
  createUserFavoritesList: Maybe<FavoritesList>
  /**
   * @throws
   * - OrderCannotBeDeletedError
   */
  deleteAllUserData: Maybe<DeleteUserDataResult>
  /**
   * @throws
   * - DefaultListCannotBeDeletedError
   * @deprecated With shoppinglist multiple favorites list architecture no longer needed
   */
  deleteUserFavoritesList: Maybe<FavoritesListDeleteResult>
  isOptimisticResponse: Maybe<Scalars['Boolean']>
  refreshDeliverySlotReservation: DeliverySlotReservationResponse
  releaseDeliverySlotReservation: Maybe<Scalars['Void']>
  /** No specific errors */
  removeUserNotificationSubscription: Maybe<UserNotificationSubscription>
  /**
   * @throws:
   * - UnableToRemovePaymentCardError [500]
   */
  removeUserPaymentCard: Maybe<RemoveUserPaymentCardResponse>
  /** No specific errors */
  removeUserPushNotificationDevice: Maybe<PushNotificationDevice>
  /**
   * @throws:
   * - UnableToRenamePaymentCardError [500]
   */
  renameUserPaymentCard: Maybe<RenameUserPaymentCardResponse>
  resetDeliveryStore: Maybe<Scalars['Boolean']>
  /**
   * @throws:
   * - UnableToResetPaymentCardForOrderError [500]
   * - UserIdMismatchError [403]
   * - OrderNotFoundError [404]
   */
  resetOrderPaymentCard: Maybe<ResetOrderPaymentCardResponse>
  /**
   * @throws:
   * - UnableToSavePaymentCardError [500]
   */
  saveUserPaymentCard: Maybe<SaveUserPaymentCardResponse>
  sendCitrusAdUserEvents: Maybe<Scalars['Void']>
  /**
   * Sends chat metadata to Giosg service
   *
   * @param visitorId - Giosg visitor id
   * @param storeId - Currently selected store id
   * @throws:
   * - BadRequestError [400]
   */
  sendGiosgChatMetadata: Maybe<Scalars['Void']>
  /**
   * @throws:
   * - BadRequestError [400]
   */
  sendProductUserEvents: Maybe<ProductUserEventResponse>
  /**
   * @throws:
   * - UnableToSetDefaultPaymentCardError [500]
   */
  setDefaultUserPaymentCard: Maybe<SetDefaultUserPaymentCardResponse>
  setDeliveryStore: Maybe<Scalars['Boolean']>
  startOrderEdit: Maybe<Scalars['Boolean']>
  stopOrderEdit: Maybe<Scalars['Boolean']>
  updateAuthenticationRedirectPath: Maybe<Scalars['String']>
  updateAuthenticationTokens: Maybe<UpdateAuthenticationTokensResponse>
  updateCustomerInfo: Maybe<UpdateCustomerInfoResponse>
  updateCustomerType: Maybe<Scalars['Boolean']>
  updateDeliveryDetails: Maybe<UpdateDeliveryDetailsResponse>
  /**
   * @throws:
   * - MissingSlotId [400]
   * - PostalCodeNotSupportedError [400]
   * - SlotFullError [409]
   * - SlotClosedError [409]
   * - DiscountCodeExpiredError [404]
   * - DiscountCodeNotFoundError[404]
   */
  updateOrder: Maybe<Order>
  updatePaymentMethodDetails: Maybe<Scalars['Boolean']>
  updatePersonalInfo: Maybe<UpdatePersonalInfoResponse>
  updateSelectedAreaId: Maybe<Scalars['Boolean']>
  updateSelectedBrand: Maybe<Scalars['Boolean']>
  updateSelectedStoreId: Maybe<Scalars['Boolean']>
  updateStockmannCardNumber: Maybe<Scalars['Boolean']>
  /**
   * @throws
   * - MissingUpdateValuesError
   * @deprecated With shoppinglist multiple favorites list architecture no longer needed
   */
  updateUserFavoritesList: Maybe<FavoritesList>
  /** @deprecated With shoppinglist multiple favorites list architecture no longer needed */
  updateUserFavoritesListItems: Maybe<FavoritesOperationResult>
  /** No specific errors */
  updateUserNotificationSubscription: Maybe<UserNotificationSubscription>
  userFavoritesAddItem: FavoritesList
  userFavoritesRemoveItem: FavoritesList
}

export type MutationAddUserNotificationSubcriptionArgs = {
  mediums: Array<UserNotificationMediumEnum>
  type: UserNotificationSubscriptionEnum
}

export type MutationAddUserPushNotificationDeviceArgs = {
  deviceToken: Scalars['String']
  name: InputMaybe<Scalars['String']>
}

export type MutationAuthorizePaymentArgs = {
  orderId: Scalars['ID']
}

export type MutationCancelOrderArgs = {
  id: Scalars['ID']
}

export type MutationCancelOrderModificationArgs = {
  isLoggedIn: InputMaybe<Scalars['Boolean']>
}

export type MutationClearCartAndOrderDetailsArgs = {
  isLoggedIn: InputMaybe<Scalars['Boolean']>
}

export type MutationCreateDeliverySlotReservationArgs = {
  deliverySlotId: Scalars['ID']
}

export type MutationCreateOrderArgs = {
  order: InputMaybe<OrderInput>
}

export type MutationCreatePaymentArgs = {
  cardId: InputMaybe<Scalars['ID']>
  customWebstoreRedirectUrl: InputMaybe<Scalars['String']>
  device: DeviceType
  orderId: Scalars['ID']
  serviceType: InputMaybe<ServiceType>
  shouldSavePaymentCard: InputMaybe<Scalars['Boolean']>
}

export type MutationCreateUserFavoritesListArgs = {
  name: Scalars['String']
}

export type MutationDeleteAllUserDataArgs = {
  retainUserProfile: InputMaybe<Scalars['Boolean']>
}

export type MutationDeleteUserFavoritesListArgs = {
  listId: Scalars['String']
}

export type MutationRefreshDeliverySlotReservationArgs = {
  reservationId: Scalars['ID']
}

export type MutationReleaseDeliverySlotReservationArgs = {
  reservationId: Scalars['ID']
}

export type MutationRemoveUserNotificationSubscriptionArgs = {
  mediums: Array<UserNotificationMediumEnum>
  type: UserNotificationSubscriptionEnum
}

export type MutationRemoveUserPaymentCardArgs = {
  cardId: Scalars['ID']
}

export type MutationRemoveUserPushNotificationDeviceArgs = {
  deviceToken: Scalars['String']
}

export type MutationRenameUserPaymentCardArgs = {
  cardId: Scalars['ID']
  name: Scalars['String']
}

export type MutationResetOrderPaymentCardArgs = {
  id: Scalars['ID']
}

export type MutationSaveUserPaymentCardArgs = {
  orderId: Scalars['ID']
}

export type MutationSendCitrusAdUserEventsArgs = {
  adId: Scalars['String']
  eventType: CitrusAdUserEventType
  orderId: InputMaybe<Scalars['String']>
  sessionId: Scalars['String']
}

export type MutationSendGiosgChatMetadataArgs = {
  storeId: InputMaybe<Scalars['String']>
  visitorId: Scalars['String']
}

export type MutationSendProductUserEventsArgs = {
  eventType: ProductUserEventType
  productIds: Array<Scalars['String']>
  sessionId: Scalars['String']
}

export type MutationSetDefaultUserPaymentCardArgs = {
  cardId: Scalars['ID']
}

export type MutationSetDeliveryStoreArgs = {
  areaId: InputMaybe<Scalars['ID']>
  availablePaymentMethods: InputMaybe<Array<InputMaybe<PaymentMethod>>>
  brand: InputMaybe<Scalars['String']>
  id: InputMaybe<Scalars['ID']>
  name: InputMaybe<Scalars['String']>
}

export type MutationStartOrderEditArgs = {
  orderId: InputMaybe<Scalars['ID']>
  orderStatus: InputMaybe<OrderStatus>
}

export type MutationUpdateAuthenticationRedirectPathArgs = {
  authRedirectPath: InputMaybe<Scalars['String']>
}

export type MutationUpdateAuthenticationTokensArgs = {
  accessToken: Scalars['String']
  idToken: Scalars['String']
  refreshToken: Scalars['String']
}

export type MutationUpdateCustomerInfoArgs = {
  companyName: InputMaybe<Scalars['String']>
  customerType: InputMaybe<Scalars['String']>
  email: InputMaybe<Scalars['String']>
  firstName: InputMaybe<Scalars['String']>
  lastName: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
}

export type MutationUpdateCustomerTypeArgs = {
  customerType: InputMaybe<Scalars['String']>
}

export type MutationUpdateDeliveryDetailsArgs = {
  additionalInfo: InputMaybe<Scalars['String']>
  address: InputMaybe<Scalars['String']>
  city: InputMaybe<Scalars['String']>
  deliveryDate: InputMaybe<Scalars['String']>
  deliveryMethod: InputMaybe<DeliveryMethod>
  deliverySlotId: InputMaybe<Scalars['ID']>
  deliverySlotPrice: InputMaybe<Scalars['Float']>
  deliveryTime: InputMaybe<Scalars['String']>
  postalCode: InputMaybe<Scalars['String']>
}

export type MutationUpdateOrderArgs = {
  id: InputMaybe<Scalars['String']>
  order: InputMaybe<OrderInput>
}

export type MutationUpdatePaymentMethodDetailsArgs = {
  invoiceNumber: InputMaybe<Scalars['String']>
  paymentMethod: InputMaybe<PaymentMethod>
  paymentStatus: InputMaybe<Scalars['String']>
  savePaymentCard: InputMaybe<Scalars['Boolean']>
  selectedPaymentCardId: InputMaybe<Scalars['ID']>
  stockmannCardNumber: InputMaybe<Scalars['String']>
}

export type MutationUpdatePersonalInfoArgs = {
  comment: InputMaybe<Scalars['String']>
  companyName: InputMaybe<Scalars['String']>
  email: InputMaybe<Scalars['String']>
  firstName: InputMaybe<Scalars['String']>
  lastName: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
}

export type MutationUpdateSelectedAreaIdArgs = {
  selectedAreaId: InputMaybe<Scalars['ID']>
}

export type MutationUpdateSelectedBrandArgs = {
  selectedBrand: InputMaybe<Scalars['String']>
}

export type MutationUpdateSelectedStoreIdArgs = {
  selectedStoreId: InputMaybe<Scalars['ID']>
}

export type MutationUpdateStockmannCardNumberArgs = {
  stockmannCardNumber: InputMaybe<Scalars['String']>
}

export type MutationUpdateUserFavoritesListArgs = {
  listId: Scalars['String']
  values: FavoritesListPatchInput
}

export type MutationUpdateUserFavoritesListItemsArgs = {
  operations: Array<FavoritesOperationInput>
}

export type MutationUpdateUserNotificationSubscriptionArgs = {
  enabled: Scalars['Boolean']
  mediums: Array<UserNotificationMediumEnum>
  type: UserNotificationSubscriptionEnum
}

export type MutationUserFavoritesAddItemArgs = {
  ean: Scalars['String']
}

export type MutationUserFavoritesRemoveItemArgs = {
  ean: Scalars['String']
}

export type Navigation = {
  __typename: 'Navigation'
  navigation: Maybe<Array<NavigationItem>>
}

export type NavigationItem = {
  __typename: 'NavigationItem'
  children: Maybe<Array<NavigationItem>>
  hidden: Maybe<Scalars['Boolean']>
  id: Maybe<Scalars['String']>
  itemCount: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  redirectedSlugs: Array<Scalars['String']>
  sequence: Maybe<Scalars['String']>
  slug: Maybe<Scalars['String']>
  type: Maybe<Scalars['String']>
}

/**
 * More condensed version of delivery slot which is compatible with the slots
 * that we store in in deliveryareas elastic search. The goal in the future
 * would be to bring the ordinary DeliverySlot closer to his model as well
 * because it contains a lot of extra fields.
 */
export type NextDeliverySlot = {
  __typename: 'NextDeliverySlot'
  alcoholSellingAllowed: AlcoholSellingStatus
  availability: SlotAvailability
  /** Format: ISO DateTime */
  endDateTime: Scalars['String']
  /** Estimated time in minutes */
  estimatedFastTrackTime: Maybe<Scalars['Int']>
  isClosed: Scalars['Boolean']
  isFastTrack: Scalars['Boolean']
  price: Scalars['Float']
  slotId: Scalars['String']
  /** Format: ISO DateTime */
  startDateTime: Scalars['String']
}

export type Nutrient = {
  __typename: 'Nutrient'
  kcal: Maybe<Scalars['Float']>
  kj: Maybe<Scalars['Float']>
  name: Maybe<Scalars['String']>
  ri: Maybe<Scalars['String']>
  value: Maybe<Scalars['String']>
}

export type NutrientClaim = {
  __typename: 'NutrientClaim'
  nutritionalClaimElement: Maybe<Scalars['String']>
  nutritionalClaimType: Maybe<Scalars['String']>
}

export type NutrientPerServing = {
  __typename: 'NutrientPerServing'
  name: Maybe<Scalars['String']>
  unit: Maybe<Scalars['String']>
  value: Maybe<Scalars['Float']>
}

export type NutrientRaw = {
  __typename: 'NutrientRaw'
  measurementPrecisionCode: Maybe<Scalars['String']>
  measurementUnitCode: Maybe<Scalars['String']>
  nutrientTypeCode: Maybe<Scalars['String']>
  quantityContained: Maybe<Scalars['Float']>
}

export type ObjectFacet = IStructuredFacet & {
  __typename: 'ObjectFacet'
  key: FacetKey
  objectValue: Array<ObjectFacetValue>
}

export type ObjectFacetValue = {
  __typename: 'ObjectFacetValue'
  doc_count: Scalars['Int']
  name: Scalars['String']
  value: Scalars['String']
}

export type Order = {
  __typename: 'Order'
  additionalInfo: Maybe<Scalars['String']>
  capturedPOSData: Maybe<ExternalOrdersPosData>
  capturedTotalInCents: Maybe<Scalars['Int']>
  cartItems: Maybe<Array<CartItem>>
  comment: Maybe<Scalars['String']>
  createdAt: Maybe<Scalars['String']>
  customer: Maybe<Customer>
  deliveryDate: Scalars['String']
  deliveryMethod: Maybe<DeliveryMethod>
  /**  Client-supplied destination where the robot order should be delivered  */
  deliveryPoint: Maybe<DeliveryLocation>
  deliverySlot: Maybe<DeliverySlot>
  deliverySlotId: Scalars['ID']
  deliveryTime: Scalars['String']
  discountCode: Maybe<Scalars['String']>
  handler: Maybe<Scalars['String']>
  homeDeliveryType: Maybe<HomeDeliveryType>
  id: Scalars['ID']
  /**
   * Can this order be canceled?
   * In most cases, this matches slot's isModifiable, but there are cases where they don't match
   */
  isCancelable: Scalars['Boolean']
  isFastTrack: Maybe<Scalars['Boolean']>
  /** Can this order be modified? */
  isModifiable: Scalars['Boolean']
  linkVerificationToken: Scalars['String']
  modifiedAt: Maybe<Scalars['String']>
  orderNumber: Maybe<Scalars['Int']>
  orderStatus: Maybe<OrderStatus>
  paymentId: Maybe<Scalars['String']>
  paymentMethod: Maybe<PaymentMethod>
  paymentStatus: Maybe<PaymentStatus>
  pickupLocation: Maybe<Scalars['String']>
  storeId: Scalars['ID']
  trackingUrl: Maybe<Scalars['String']>
}

export type OrderBy = {
  key?: InputMaybe<SortKey>
  order?: InputMaybe<SortOrder>
}

export type OrderEditActiveResponse = {
  __typename: 'OrderEditActiveResponse'
  orderId: Maybe<Scalars['ID']>
  orderStatus: Maybe<OrderStatus>
}

export type OrderInput = {
  additionalInfo?: InputMaybe<Scalars['String']>
  cartItems?: InputMaybe<Array<CartItemInput>>
  comment?: InputMaybe<Scalars['String']>
  customer: CustomerInput
  /**  Client-supplied destination where the robot order should be delivered  */
  deliveryPoint?: InputMaybe<Location>
  deliverySlotId: Scalars['ID']
  discountCode?: InputMaybe<Scalars['String']>
  paymentMethod: PaymentMethod
  reservationId?: InputMaybe<Scalars['ID']>
  storeId: Scalars['ID']
}

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Modified = 'MODIFIED',
  New = 'NEW',
  Open = 'OPEN',
}

export type PaymentCard = {
  __typename: 'PaymentCard'
  expired: Scalars['Boolean']
  expiryDate: Scalars['String']
  id: Scalars['ID']
  maskedCardNumber: Scalars['String']
  name: Scalars['String']
  type: PaymentCardType
  userGeneratedName: Scalars['String']
}

export enum PaymentCardType {
  AmericanExpress = 'AMERICAN_EXPRESS',
  Mastercard = 'MASTERCARD',
  SBusiness = 'S_BUSINESS',
  SVisa = 'S_VISA',
  Unknown = 'UNKNOWN',
  Visa = 'VISA',
}

export type PaymentCards = {
  __typename: 'PaymentCards'
  cards: Array<PaymentCard>
  defaultPaymentCardId: Maybe<Scalars['ID']>
}

export type PaymentDetailsResponse = {
  __typename: 'PaymentDetailsResponse'
  invoiceNumber: Maybe<Scalars['String']>
  paymentMethod: Maybe<PaymentMethod>
  paymentStatus: Maybe<Scalars['String']>
  savePaymentCard: Maybe<Scalars['Boolean']>
  selectedPaymentCardId: Maybe<Scalars['ID']>
  stockmannCardNumber: Maybe<Scalars['String']>
}

export enum PaymentMethod {
  CardPayment = 'CARD_PAYMENT',
  Invoice = 'INVOICE',
  OnDelivery = 'ON_DELIVERY',
}

export enum PaymentStatus {
  Canceled = 'CANCELED',
  CaptureCompleted = 'CAPTURE_COMPLETED',
  CaptureFailed = 'CAPTURE_FAILED',
  CardAuthorizationFailed = 'CARD_AUTHORIZATION_FAILED',
  CardAuthorized = 'CARD_AUTHORIZED',
  Pending = 'PENDING',
  Unavailable = 'UNAVAILABLE',
}

export type PaymentTerminalAddress = {
  __typename: 'PaymentTerminalAddress'
  redirectUrl: Scalars['String']
  transactionId: Scalars['String']
}

export type Point = {
  __typename: 'Point'
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type Product = {
  __typename: 'Product'
  allergens: Maybe<Array<Allergen>>
  approxPrice: Maybe<Scalars['Boolean']>
  availabilities: Maybe<Array<ProductAvailabilities>>
  /** @deprecated Use salesUniteCom field */
  basicQuantityUnit: Maybe<Scalars['String']>
  brandName: Maybe<Scalars['String']>
  categories: Maybe<Array<Scalars['String']>>
  category: Maybe<Scalars['String']>
  categoryId: Maybe<Scalars['String']>
  collectingPriority: Maybe<Scalars['Float']>
  /** @deprecated Prefer pricing.comparisonPrice */
  comparisonPrice: Maybe<Scalars['Float']>
  comparisonUnit: Maybe<Scalars['String']>
  consumerPackageSize: Maybe<Scalars['Float']>
  consumerPackageUnit: Maybe<Scalars['String']>
  countryName: Maybe<CountryName>
  countryOfMainRawMaterial: Maybe<Scalars['String']>
  /** @deprecated Prefer pricing.depositPrice */
  depositPrice: Maybe<Scalars['Float']>
  description: Maybe<Scalars['String']>
  descriptionShort: Maybe<Scalars['String']>
  ean: Maybe<Scalars['String']>
  frozen: Maybe<Scalars['Boolean']>
  hierarchyPath: Maybe<Array<HierarchyPathItem>>
  /**
   * The product id is currently mapped to "product.ean", but should be changed to "product.sokId".
   * @warn This is not unique across all products, so use it together with "product.storeId"
   */
  id: Scalars['ID']
  inStore: Maybe<Scalars['Boolean']>
  ingredientStatement: Maybe<Scalars['String']>
  isAgeLimitedByAlcohol: Maybe<Scalars['Boolean']>
  isGlobalFallback: Maybe<Scalars['Boolean']>
  isNewProduct: Maybe<Scalars['Boolean']>
  localized: Maybe<LocalizedProductInfo>
  location: Maybe<ProductLocation>
  marketingMessage: Maybe<Scalars['String']>
  measurement: Maybe<ProductMeasurement>
  modified: Maybe<Scalars['String']>
  name: Maybe<Scalars['String']>
  nutrientClaims: Maybe<Array<NutrientClaim>>
  nutrients: Maybe<Array<Nutrient>>
  nutrientsPerServing: Maybe<Array<NutrientPerServing>>
  nutrientsRaw: Maybe<Array<NutrientRaw>>
  onSale: Maybe<Scalars['Boolean']>
  packagingLabelCodes: Maybe<Array<Scalars['String']>>
  packagingLabels: Maybe<Array<Scalars['String']>>
  packagingMarkedLabelAccreditationCodes: Maybe<Array<Scalars['String']>>
  /** @deprecated Prefer pricing.currentPrice */
  price: Maybe<Scalars['Float']>
  priceUnit: Maybe<Scalars['String']>
  pricing: Maybe<ProductPricing>
  productDetails: ProductDetails
  productType: Maybe<ProductType>
  quantityMultiplier: Maybe<Scalars['Float']>
  salesUniteCom: Maybe<SalesUnit>
  slug: Maybe<Scalars['String']>
  /**
   * The globally unique id of the product, mostly for internal usage. Customers should not need
   * to see this.
   * @warn this should not be optional after migrating to Product pipeline v3
   */
  sokId: Maybe<Scalars['ID']>
  stock: Maybe<Scalars['Float']>
  store: Maybe<Store>
  storeAvailability: Maybe<Array<Product>>
  storeId: Maybe<Scalars['String']>
  storeSpecificData: Array<StoreSpecificData>
  supplierName: Maybe<Scalars['String']>
  taxPercentage: Maybe<Scalars['Float']>
  tuhtiId: Maybe<Scalars['String']>
  /** @deprecated Use measurement object instead */
  weight: Maybe<Scalars['Float']>
  /** @deprecated Use measurement object instead */
  weightUnit: Maybe<Scalars['String']>
}

export type ProductAvailabilitiesArgs = {
  date: InputMaybe<Scalars['String']>
}

export type ProductStoreAvailabilityArgs = {
  onlyIfFallback: InputMaybe<Scalars['Boolean']>
}

export type ProductAvailabilities = {
  __typename: 'ProductAvailabilities'
  date: Maybe<Scalars['String']>
  label: Maybe<ProductAvailabilityLabel>
  score: Maybe<Scalars['Float']>
}

export enum ProductAvailabilityLabel {
  Available = 'AVAILABLE',
  LimitedAvailability = 'LIMITED_AVAILABILITY',
  OutOfStock = 'OUT_OF_STOCK',
}

export type ProductDetails = {
  __typename: 'ProductDetails'
  safetyInformation: Maybe<SafetyInformation>
}

export type ProductList = {
  __typename: 'ProductList'
  from: Maybe<Scalars['Int']>
  items: Maybe<Array<Product>>
  limit: Maybe<Scalars['Int']>
  searchProvider: Maybe<SearchProvider>
  structuredFacets: Maybe<Array<ObjectFacet | StringFacet>>
  total: Maybe<Scalars['Int']>
}

export type ProductLocation = {
  __typename: 'ProductLocation'
  aisle: Maybe<Scalars['String']>
  coordinate: Maybe<Array<Scalars['Float']>>
  floor: Maybe<Scalars['Int']>
  module: Maybe<Scalars['String']>
  shelf: Maybe<Scalars['String']>
}

export type ProductMeasurement = {
  __typename: 'ProductMeasurement'
  grossWeight: Maybe<Scalars['Float']>
  grossWeightUnit: Maybe<Scalars['String']>
  netWeight: Maybe<Scalars['Float']>
  netWeightUnit: Maybe<Scalars['String']>
  pceApproxWeight: Maybe<Scalars['Float']>
}

export type ProductPricing = {
  __typename: 'ProductPricing'
  /**
   * The currently active campaign price, or null when no active campaign.
   * Also returns null when "isGlobalFallback" is true.
   * @warn The "lowest30DayPrice" must be displayed to customers alongside this.
   */
  campaignPrice: Maybe<Scalars['Float']>
  /**
   * The ISO timestamp after which the current campaign price will no longer be valid,
   * or null when no active campaign.
   */
  campaignPriceValidUntil: Maybe<Scalars['String']>
  /**
   * The price customers can use to compare prices of similar products,
   * for example by kilogram or piece.
   */
  comparisonPrice: Scalars['Float']
  /**
   * The current price the product should be sold to customers,
   * including any discounts from active campaigns.
   */
  currentPrice: Scalars['Float']
  /** The possible deposit price of the product, or null when not applicable. */
  depositPrice: Maybe<Scalars['Float']>
  /**
   * The lowest price of the product in the last 30 days, or null when "isGlobalFallback" is true.
   * @warn This must displayed to customers alongside the "campaignPrice".
   */
  lowest30DayPrice: Maybe<Scalars['Float']>
  /** The regular price of the product, *not* including any discounts from active campaigns. */
  regularPrice: Scalars['Float']
}

export enum ProductType {
  Coupon = 'COUPON',
  DeliveryProduct = 'DELIVERY_PRODUCT',
  Deposit = 'DEPOSIT',
  PackagingProduct = 'PACKAGING_PRODUCT',
  Product = 'PRODUCT',
  ServiceFee = 'SERVICE_FEE',
}

export type ProductUserEventResponse = {
  __typename: 'ProductUserEventResponse'
  success: Scalars['Boolean']
}

export enum ProductUserEventType {
  AddToCart = 'ADD_TO_CART',
  ProductClick = 'PRODUCT_CLICK',
  Purchase = 'PURCHASE',
}

export type PushNotificationDevice = {
  __typename: 'PushNotificationDevice'
  deviceToken: Scalars['String']
  name: Maybe<Scalars['String']>
}

export type Query = {
  __typename: 'Query'
  /**
   * For query input, return list of autocompleted physical addresses. Addresses *do not* include
   * geolocation coordinates.
   *
   * @param query The input that should be autocompleted
   * @param countryCode Limit address lookup inside a specific country, e.g. FIN
   * @param lang Change language of data, for example Finnish addresses in Swedish language
   */
  addressAutocomplete: Maybe<Array<AddressAutocompleteResult>>
  /**
   * For query input, return list of suggestions for physical addresses.
   *
   * @param query The input that should be autosuggested
   * @param countryCode Limit address lookup inside a specific country, e.g. FIN
   * @param lang Change language of data, for example Finnish addresses in Swedish language
   */
  addressAutosuggest: Maybe<Array<AddressGeocodeResult>>
  /**
   * For query input, return a physical address with geolocation coordinates.
   * Typically there's only one result, unless the query input is ambiguous.
   *
   * @param query the adddress input
   * @param countryCode Limit address lookup inside a specific country, e.g. FIN
   * @param lang Change language of data, for example Finnish addresses in Swedish language
   */
  addressGeocode: Maybe<Array<AddressGeocodeResult>>
  addressValidate: AddressValidateResult
  authTokens: Maybe<AuthTokens>
  authenticationRedirectPath: Maybe<Scalars['String']>
  authenticationTokens: Maybe<AuthenticationTokens>
  /**
   * Get batch recommendations of the requested type.
   * @param recommendation type
   */
  batchProductRecommendation: Maybe<BatchRecommendation>
  bonusInfo: Maybe<BonusInfo>
  bonusWidgetToken: Maybe<BonusWidgetToken>
  cartItem: Maybe<ClientCartItem>
  cartItems: Array<ClientCartItem>
  citrusAd: Maybe<CitrusAd>
  /**
   * Get complementary recommendations for a list of eans (like a shopping cart), optionally focused on a
   * single specific ean (for example when on a product page, doesn't have to be included in the list!).
   *
   * When focusing on a specific ean, the list of eans will be taken into account on a lower priority.
   *
   * @param eans the list of eans to get complementary recommendations on
   * @param focusOnEan the single product to focus on, for example when on a product page
   * @param storeId the id of the store to use for catalog
   * @param limit the maximum number of recommendations to return
   *
   * @example <caption>Get complementary products for a cart</caption>
   * query complementaryProducts(eans: cart.map(item => item.ean), storeId: cart.storeId ) {
   *   name
   *   ean
   * }
   *
   * @example <caption>Get complementary products for a specific product, taking cart into account</caption>
   * query complementaryProducts(focusOnEan: product.ean, eans: cart.map(item => item.ean), storeId: cart.storeId ) {
   *   name
   *   ean
   * }
   */
  complementaryProducts: Array<Product>
  customerDetails: Maybe<CustomerDetailsResponse>
  customerType: Maybe<Scalars['String']>
  deliveryArea: Maybe<DeliveryArea>
  deliveryAreaPins: Maybe<DeliveryAreaPinsResponse>
  deliveryAreas: Maybe<Array<DeliveryArea>>
  deliveryDetailsInfo: Maybe<DeliveryDetailsInfo>
  deliverySlot: Maybe<DeliverySlot>
  deliverySlotReservation: DeliverySlotReservationResponse
  deliveryStore: Maybe<DeliveryStore>
  domainOrder: DomainOrder
  /** Check if there are any type of slots available in given date range (1-40 days) */
  homeDeliverySlotAvailabilitiesForPostalCode: Array<DayAvailability>
  /** Search for available slots which deliver to area of given postal code (1-7 days) */
  homeDeliverySlotsForPostalCode: Array<HomeDeliverySlotsInStore>
  order: Maybe<Order>
  orderEditActive: Maybe<OrderEditActiveResponse>
  paymentDetails: Maybe<PaymentDetailsResponse>
  /**
   * @throws:
   * - ProductNotFoundError [404]
   */
  product: Maybe<Product>
  recipe: Maybe<Recipe>
  /** Returns an address for given set of coordinates. Argument lang should be in shape of "fi-FI" */
  reverseGeocode: Maybe<DeliveryAreaAddress>
  /**
   * Capacity calculation for given cart. Total capacity is two 40L (64cm * 32cm * 20cm) shopping bags.
   * Products that don't fit by it's dimension into a single shopping bag are rejected.
   * Cart total max weight is set to 20 KG.
   */
  robotCartCapacity: CartCapacity
  /**
   * @throws:
   *   - RobotDeliveryUnavailableError [400], when given coordinats are out of service area
   */
  robotNearestDeliveryPoint: Maybe<DeliveryLocation>
  /** @deprecated Use searchHomeDeliveryAreas and searchPickupDeliveryAreas instead. */
  searchDeliveryAreas: Maybe<DeliveryAreaSearchResponse>
  searchHomeDeliveryAreas: Maybe<DeliveryAreaSearchResponse>
  searchPickupDeliveryAreas: Maybe<DeliveryAreaSearchResponse>
  searchStores: Maybe<SearchStoresResponse>
  selectedAreaId: Maybe<Scalars['ID']>
  selectedBrand: Maybe<Scalars['String']>
  selectedStoreId: Maybe<Scalars['ID']>
  serviceFee: Maybe<ServiceFee>
  serviceSubscriptions: Array<ServiceSubscription>
  store: Maybe<Store>
  stores: Maybe<Array<StoreInfo>>
  userFavorites: FavoritesList
  /** @deprecated With shoppinglist multiple favorites list architecture no longer needed */
  userFavoritesList: Maybe<FavoritesList>
  /** @deprecated With shoppinglist multiple favorites list architecture no longer needed */
  userFavoritesLists: Maybe<FavoritesLists>
  userNotificationPreferences: Maybe<UserNotificationPreferences>
  userOrder: Maybe<UserOrderWithCartItems>
  userOrders: Array<UserOrder>
  userPaymentCards: Maybe<PaymentCards>
  userProductRecommendation: Maybe<CartRecommendation>
  userProfile: Maybe<UserProfile>
  userProfileGuidedEntry: Scalars['String']
}

export type QueryAddressAutocompleteArgs = {
  countryCode: Scalars['String']
  lang: InputMaybe<Scalars['String']>
  query: Scalars['String']
}

export type QueryAddressAutosuggestArgs = {
  at: InputMaybe<Location>
  countryCode: Scalars['String']
  lang: InputMaybe<Scalars['String']>
  limit: InputMaybe<Scalars['Int']>
  query: Scalars['String']
}

export type QueryAddressGeocodeArgs = {
  countryCode: InputMaybe<Scalars['String']>
  lang: InputMaybe<Scalars['String']>
  query: Scalars['String']
}

export type QueryAddressValidateArgs = {
  countryCode: InputMaybe<Scalars['String']>
  lang: InputMaybe<Scalars['String']>
  query: Scalars['String']
}

export type QueryAuthTokensArgs = {
  refreshToken: InputMaybe<Scalars['String']>
}

export type QueryBatchProductRecommendationArgs = {
  type: BatchRecommendationTypeEnum
}

export type QueryCartItemArgs = {
  id: InputMaybe<Scalars['ID']>
}

export type QueryCitrusAdArgs = {
  brand: Scalars['String']
  placement: Scalars['String']
  sessionId: Scalars['String']
  slotId: Scalars['String']
  useCustomerId: Scalars['Boolean']
}

export type QueryComplementaryProductsArgs = {
  eans: Array<Scalars['ID']>
  focusOnEan: InputMaybe<Scalars['ID']>
  limit: InputMaybe<Scalars['Int']>
  storeId: Scalars['ID']
}

export type QueryDeliveryAreaArgs = {
  id: Scalars['ID']
}

export type QueryDeliveryAreaPinsArgs = {
  domains: InputMaybe<Array<Domain>>
}

export type QueryDeliveryAreasArgs = {
  brand: InputMaybe<Scalars['String']>
  deliveryMethod: InputMaybe<DeliveryMethod>
  domains: InputMaybe<Array<Domain>>
  postalCode: InputMaybe<Scalars['String']>
}

export type QueryDeliverySlotArgs = {
  id: Scalars['ID']
}

export type QueryDeliverySlotReservationArgs = {
  reservationId: Scalars['ID']
}

export type QueryHomeDeliverySlotAvailabilitiesForPostalCodeArgs = {
  endDate: Scalars['String']
  postalCode: Scalars['String']
  startDate: Scalars['String']
}

export type QueryHomeDeliverySlotsForPostalCodeArgs = {
  endDate: Scalars['String']
  postalCode: Scalars['String']
  startDate: Scalars['String']
}

export type QueryOrderArgs = {
  id: Scalars['ID']
  linkVerificationToken: InputMaybe<Scalars['String']>
}

export type QueryProductArgs = {
  fallbackToGlobal: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  includeAgeLimitedByAlcohol: InputMaybe<Scalars['Boolean']>
  storeId: InputMaybe<Scalars['ID']>
}

export type QueryRecipeArgs = {
  id: Scalars['ID']
}

export type QueryReverseGeocodeArgs = {
  lang: InputMaybe<Scalars['String']>
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type QueryRobotCartCapacityArgs = {
  items: Array<CartItemForCapacityInput>
  storeId: Scalars['String']
}

export type QueryRobotNearestDeliveryPointArgs = {
  to: Location
}

export type QuerySearchDeliveryAreasArgs = {
  boundingBox: InputMaybe<BoundingBox>
  brands: InputMaybe<Array<InputMaybe<StoreBrand>>>
  deliveryMethod: InputMaybe<DeliveryMethod>
  domains: InputMaybe<Array<InputMaybe<Domain>>>
  freetext: InputMaybe<Scalars['String']>
  homeDeliveryType: InputMaybe<Array<HomeDeliveryType>>
  location: InputMaybe<Location>
  postalCode: InputMaybe<Scalars['String']>
  storeId: InputMaybe<Scalars['ID']>
  visibilityGroups: InputMaybe<Array<Scalars['String']>>
}

export type QuerySearchHomeDeliveryAreasArgs = {
  brands: InputMaybe<Array<InputMaybe<StoreBrand>>>
  domains: InputMaybe<Array<InputMaybe<Domain>>>
  freetext: InputMaybe<Scalars['String']>
  from: InputMaybe<Scalars['Int']>
  homeDeliveryType: InputMaybe<Array<HomeDeliveryType>>
  location: InputMaybe<Location>
  pageSize: InputMaybe<Scalars['Int']>
  postalCode: InputMaybe<Scalars['String']>
  storeId: InputMaybe<Scalars['ID']>
  visibilityGroups: InputMaybe<Array<Scalars['String']>>
}

export type QuerySearchPickupDeliveryAreasArgs = {
  boundingBox: InputMaybe<BoundingBox>
  brands: InputMaybe<Array<InputMaybe<StoreBrand>>>
  domains: InputMaybe<Array<InputMaybe<Domain>>>
  freetext: InputMaybe<Scalars['String']>
  from: InputMaybe<Scalars['Int']>
  location: InputMaybe<Location>
  pageSize: InputMaybe<Scalars['Int']>
  storeId: InputMaybe<Scalars['ID']>
  visibilityGroups: InputMaybe<Array<Scalars['String']>>
}

export type QuerySearchStoresArgs = {
  _dangerousLoadAllOverrideForSitemap: InputMaybe<Scalars['Boolean']>
  brand: InputMaybe<StoreBrand>
  country: InputMaybe<CountryCode>
  cursor: InputMaybe<Scalars['String']>
  domains: InputMaybe<Array<Domain>>
  query: InputMaybe<Scalars['String']>
}

export type QueryServiceSubscriptionsArgs = {
  serviceTypes: Array<ServiceSubscriptionServiceType>
}

export type QueryStoreArgs = {
  id: Scalars['ID']
}

export type QueryStoresArgs = {
  brand: InputMaybe<Scalars['String']>
  domains: InputMaybe<Array<Domain>>
}

export type QueryUserFavoritesListArgs = {
  listId: Scalars['ID']
}

export type QueryUserOrderArgs = {
  id: Scalars['ID']
}

export type QueryUserOrdersArgs = {
  dataSources: InputMaybe<Array<UserOrderDataSource>>
  domain: InputMaybe<Domain>
  includeSBonusOrders: InputMaybe<Scalars['Boolean']>
  limit: InputMaybe<Scalars['Int']>
}

export type QueryUserProductRecommendationArgs = {
  type: CartRecommendationType
}

export type Recipe = {
  __typename: 'Recipe'
  categories: Maybe<RecipeCategories>
  cookTime: Maybe<Scalars['Int']>
  description: Maybe<Scalars['String']>
  externalUrl: Scalars['String']
  id: Scalars['ID']
  imageUrl: Maybe<Scalars['String']>
  ingredients: Array<RecipeIngredient>
  instructions: Array<RecipeInstruction>
  name: Scalars['String']
  /** @deprecated Not supported anymore. Use yield and yieldType fields instead because this is missing the type. */
  portions: Maybe<Scalars['Int']>
  publisher: Maybe<Scalars['String']>
  slug: Scalars['String']
  yield: Scalars['Int']
  yieldType: RecipeYieldType
}

export type RecipeCategories = {
  __typename: 'RecipeCategories'
  contentConcept: Maybe<Array<Scalars['String']>>
  course: Maybe<Array<Scalars['String']>>
  cuisine: Maybe<Array<Scalars['String']>>
  diet: Maybe<Array<Scalars['String']>>
  foodType: Maybe<Array<Scalars['String']>>
  ingredientCategory: Maybe<Array<Scalars['String']>>
  meal: Maybe<Array<Scalars['String']>>
  preparation: Maybe<Array<Scalars['String']>>
  theme: Maybe<Array<Scalars['String']>>
}

export type RecipeIngredient = {
  __typename: 'RecipeIngredient'
  amountType: Maybe<Scalars['String']>
  eans: Maybe<Array<Scalars['String']>>
  groupName: Maybe<Scalars['String']>
  ingredientTitle: Maybe<Scalars['String']>
  maxAmount: Maybe<Scalars['Float']>
  minAmount: Maybe<Scalars['Float']>
  name: Scalars['String']
  products: Maybe<Array<Product>>
}

export type RecipeIngredientProductsArgs = {
  fallbackToGlobal: InputMaybe<Scalars['Boolean']>
  storeId: Scalars['ID']
}

export type RecipeInstruction = {
  __typename: 'RecipeInstruction'
  body: Scalars['String']
}

export enum RecipeYieldType {
  Piece = 'PIECE',
  Portion = 'PORTION',
  Unit = 'UNIT',
}

export type RemoveUserPaymentCardResponse = {
  __typename: 'RemoveUserPaymentCardResponse'
  id: Scalars['ID']
}

export type RenameUserPaymentCardResponse = {
  __typename: 'RenameUserPaymentCardResponse'
  id: Scalars['ID']
  name: Scalars['String']
}

export type ResetOrderPaymentCardResponse = {
  __typename: 'ResetOrderPaymentCardResponse'
  id: Scalars['ID']
}

export type RobotAvailability = {
  __typename: 'RobotAvailability'
  eta: Scalars['Int']
  status: RobotAvailabilityStatus
}

export enum RobotAvailabilityStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
}

export type SafetyInformation = {
  __typename: 'SafetyInformation'
  caption: Maybe<Scalars['String']>
  dangerClauses: Array<SafetyInformationClause>
  safetyClauses: Array<SafetyInformationClause>
  warningSymbols: Array<WarningSymbol>
}

export type SafetyInformationClause = {
  __typename: 'SafetyInformationClause'
  code: Scalars['String']
  text: Scalars['String']
}

export enum SalesUnit {
  Kg = 'KG',
  Kpl = 'KPL',
}

export type SaveUserPaymentCardResponse = {
  __typename: 'SaveUserPaymentCardResponse'
  id: Scalars['ID']
}

export enum SearchProvider {
  Elasticsearch = 'elasticsearch',
  Loop54 = 'loop54',
}

export type SearchStoresResponse = {
  __typename: 'SearchStoresResponse'
  cursor: Maybe<Scalars['String']>
  maybeHasMore: Maybe<Scalars['Boolean']>
  stores: Array<StoreInfo>
  totalCount: Scalars['Int']
}

export type ServiceFee = {
  __typename: 'ServiceFee'
  ean: Maybe<Scalars['String']>
  id: Maybe<Scalars['Int']>
  name: Maybe<Scalars['String']>
  price: Maybe<Scalars['Int']>
}

/**
 * The "Service" products are optional fees customer support can apply to an order, for example 15 EUR for creating an order over the phone.
 * These used to share the same type with real Products, but now they have their own type with only a subset of the "Product" fields.
 */
export type ServiceProduct = {
  __typename: 'ServiceProduct'
  ean: Scalars['String']
  id: Scalars['ID']
  name: Scalars['String']
  price: Scalars['Float']
  productType: ProductType
  /** @deprecated This field is not populated, only included for backwards-compatibility with the Product type */
  storeId: Maybe<Scalars['String']>
}

export type ServiceSubscription = {
  __typename: 'ServiceSubscription'
  /** Is the user allowed to change the current activation state */
  changeAllowed: Maybe<Scalars['Boolean']>
  serviceName: Maybe<Scalars['String']>
  serviceType: ServiceSubscriptionServiceType
  /** Service activation for this customer */
  subscribed: Scalars['Boolean']
  /** Date when this service was activated */
  subscriptionStartDate: Maybe<Scalars['String']>
}

export enum ServiceSubscriptionServiceType {
  AbcMobile = 'ABC_MOBILE',
  AnalyticsAndProfilingBarring = 'ANALYTICS_AND_PROFILING_BARRING',
  AoPostiBarring = 'AO_POSTI_BARRING',
  BalanceTerminalLastUsed = 'BALANCE_TERMINAL_LAST_USED',
  BenefitsAccountBalance = 'BENEFITS_ACCOUNT_BALANCE',
  BenefitsAccountBalanceSnavi = 'BENEFITS_ACCOUNT_BALANCE_SNAVI',
  CommonRegisterDigili = 'COMMON_REGISTER_DIGILI',
  CommonRegisterSpankki = 'COMMON_REGISTER_SPANKKI',
  EreceiptArchive = 'ERECEIPT_ARCHIVE',
  EreceiptPaku = 'ERECEIPT_PAKU',
  MarketingMailBarring = 'MARKETING_MAIL_BARRING',
  ProductPurchaseDataCollectionBarring = 'PRODUCT_PURCHASE_DATA_COLLECTION_BARRING',
  ResearchSurveyBarring = 'RESEARCH_SURVEY_BARRING',
  SmobiiliLastUsed = 'SMOBIILI_LAST_USED',
  TelephoneMarketingBarring = 'TELEPHONE_MARKETING_BARRING',
  TelephoneRobinsonBarring = 'TELEPHONE_ROBINSON_BARRING',
  ThirdPartyTargetingBarring = 'THIRD_PARTY_TARGETING_BARRING',
  WarrantyReceiptFeature = 'WARRANTY_RECEIPT_FEATURE',
  WebAoPosti = 'WEB_AO_POSTI',
}

export enum ServiceType {
  AppHosted = 'APP_HOSTED',
  PaymentGatewayHosted = 'PAYMENT_GATEWAY_HOSTED',
}

export type SetDefaultUserPaymentCardResponse = {
  __typename: 'SetDefaultUserPaymentCardResponse'
  id: Scalars['ID']
}

export enum SlotAvailability {
  Available = 'AVAILABLE',
  Full = 'FULL',
}

export enum SlotRecurrence {
  ModifiedWeekly = 'MODIFIED_WEEKLY',
  Once = 'ONCE',
  Weekly = 'WEEKLY',
}

export enum SortKey {
  ComparisonPrice = 'comparisonPrice',
  Name = 'name',
  Price = 'price',
  Score = 'score',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type Store = {
  __typename: 'Store'
  availablePaymentMethods: Maybe<Array<PaymentMethod>>
  brand: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  coOperative: Maybe<CoOperative>
  contactInfo: Maybe<StoreContactInfo>
  discountProduct: Maybe<DiscountProduct>
  domains: Array<Domain>
  feedback: Maybe<StoreFeedback>
  id: Scalars['ID']
  location: Maybe<StoreLocation>
  /** List lowest prices for available delivery methods. */
  lowestDeliveryPrices: Maybe<Array<Maybe<StoreLowestDeliveryPrice>>>
  /**
   * List the delivery and packaging material fees when ordering from this store. This used to return the real "Product"
   * type but is now separated with only a subset of the fields available. For backwards-compatibility this returns a list of
   * single "MandatoryProduct" type, but internally the data is split into "DeliveryProducts" and "PackagingMaterialProducts".
   */
  mandatoryProducts: Maybe<Array<MandatoryProduct>>
  name: Scalars['String']
  navigation: Maybe<Array<NavigationItem>>
  postalCode: Maybe<Scalars['String']>
  products: Maybe<ProductList>
  /** List the available service fees for the store, that customer support can apply to an order. */
  serviceProducts: Maybe<Array<ServiceProduct>>
  services: Maybe<Array<StoreService>>
  shortName: Maybe<Scalars['String']>
  slug: Scalars['String']
  street: Maybe<Scalars['String']>
  weeklyOpeningHours: Maybe<Array<StoreWeeklyOpeningTime>>
}

export type StoreDiscountProductArgs = {
  discountCode: InputMaybe<Scalars['String']>
}

export type StoreMandatoryProductsArgs = {
  deliveryMethod: InputMaybe<DeliveryMethod>
  deliverySlotId: InputMaybe<Scalars['String']>
  orderId: InputMaybe<Scalars['String']>
  packagingMaterialEan: InputMaybe<Scalars['String']>
  reservationId: InputMaybe<Scalars['String']>
}

export type StoreProductsArgs = {
  eans: InputMaybe<Array<Scalars['String']>>
  fallbackToGlobal: InputMaybe<Scalars['Boolean']>
  filters: InputMaybe<Array<Filters>>
  from: InputMaybe<Scalars['Int']>
  generatedSessionId: InputMaybe<Scalars['String']>
  hierarchyId: InputMaybe<Scalars['String']>
  includeAgeLimitedByAlcohol: InputMaybe<Scalars['Boolean']>
  limit: InputMaybe<Scalars['Int']>
  loop54DirectSearch: InputMaybe<Scalars['Boolean']>
  order: InputMaybe<SortOrder>
  orderBy: InputMaybe<SortKey>
  queryString: InputMaybe<Scalars['String']>
  searchProvider: InputMaybe<SearchProvider>
  slug: InputMaybe<Scalars['String']>
  structuredFacets: InputMaybe<Array<StructuredFacetInput>>
  useRandomId: InputMaybe<Scalars['Boolean']>
}

export type StoreAddress = {
  __typename: 'StoreAddress'
  country: Maybe<Country>
  municipality: Municipality
  postcode: Scalars['String']
  postcodeName: Maybe<LocalizableText>
  street: LocalizableText
}

export enum StoreBrand {
  Abc = 'ABC',
  Alepa = 'ALEPA',
  Eprisma = 'EPRISMA',
  Herkku = 'HERKKU',
  MestarinHerkku = 'MESTARIN_HERKKU',
  Prisma = 'PRISMA',
  Sale = 'SALE',
  SokosHerkku = 'SOKOS_HERKKU',
  SMarket = 'S_MARKET',
}

export type StoreContactInfo = {
  __typename: 'StoreContactInfo'
  email: Maybe<Scalars['String']>
  phoneNumber: Maybe<StorePhoneNumber>
}

export type StoreDayOpeningTime = {
  __typename: 'StoreDayOpeningTime'
  date: Scalars['String']
  day: DayLabel
  exceptional: Maybe<Scalars['Boolean']>
  message: Maybe<LocalizedText>
  mode: StoreOpeningTimeRangeLabel
  ranges: Maybe<Array<StoreOpeningTimeRange>>
}

export type StoreFeedback = {
  __typename: 'StoreFeedback'
  url: Maybe<LocalizableText>
}

export type StoreInfo = {
  __typename: 'StoreInfo'
  availablePaymentMethods: Maybe<Array<PaymentMethod>>
  brand: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  contactInfo: Maybe<StoreContactInfo>
  domains: Array<Domain>
  feedback: Maybe<StoreFeedback>
  id: Scalars['ID']
  location: Maybe<StoreLocation>
  name: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
  services: Maybe<Array<StoreService>>
  shortName: Maybe<Scalars['String']>
  slug: Maybe<Scalars['String']>
  street: Maybe<Scalars['String']>
  weeklyOpeningHours: Maybe<Array<StoreWeeklyOpeningTime>>
}

export type StoreLocation = {
  __typename: 'StoreLocation'
  address: Maybe<StoreAddress>
  coordinates: Maybe<Coordinates>
}

export type StoreLowestDeliveryPrice = {
  __typename: 'StoreLowestDeliveryPrice'
  areaId: Scalars['String']
  deliveryMethod: DeliveryMethod
  description: Maybe<Scalars['String']>
  isFastTrack: Scalars['Boolean']
  name: Maybe<Scalars['String']>
  price: Scalars['Float']
}

export enum StoreOpeningStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export type StoreOpeningTimeRange = {
  __typename: 'StoreOpeningTimeRange'
  close: Scalars['String']
  closeOnSameDay: Maybe<Scalars['Boolean']>
  open: Scalars['String']
}

export enum StoreOpeningTimeRangeLabel {
  AllDay = 'ALL_DAY',
  ByReservation = 'BY_RESERVATION',
  Closed = 'CLOSED',
  Range = 'RANGE',
}

export type StorePhoneNumber = {
  __typename: 'StorePhoneNumber'
  callCharge: Maybe<LocalizableText>
  callChargeGroup: Maybe<Scalars['String']>
  number: Scalars['String']
}

export type StoreService = {
  __typename: 'StoreService'
  code: Scalars['String']
  name: Maybe<LocalizableText>
}

export type StoreSpecificData = {
  __typename: 'StoreSpecificData'
  newProduct: Scalars['Boolean']
  placeOfBusinessId: Scalars['ID']
}

export type StoreWeeklyOpeningTime = {
  __typename: 'StoreWeeklyOpeningTime'
  openingTimes: Array<StoreDayOpeningTime>
  weekNumber: Scalars['Int']
}

export type StringFacet = IStructuredFacet & {
  __typename: 'StringFacet'
  key: FacetKey
  stringValue: Array<Scalars['String']>
}

export type StructuredFacetInput = {
  key: FacetKey
  order?: InputMaybe<SortOrder>
}

export type UpdateAuthenticationTokensResponse = {
  __typename: 'UpdateAuthenticationTokensResponse'
  accessToken: Maybe<Scalars['String']>
  idToken: Maybe<Scalars['String']>
  refreshToken: Maybe<Scalars['String']>
}

export type UpdateCustomerInfoResponse = {
  __typename: 'UpdateCustomerInfoResponse'
  companyName: Maybe<Scalars['String']>
  customerType: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
  stockmannCardNumber: Maybe<Scalars['String']>
}

export type UpdateDeliveryDetailsResponse = {
  __typename: 'UpdateDeliveryDetailsResponse'
  additionalInfo: Maybe<Scalars['String']>
  address: Maybe<Scalars['String']>
  city: Maybe<Scalars['String']>
  deliveryDate: Maybe<Scalars['String']>
  deliveryMethod: Maybe<DeliveryMethod>
  deliverySlotId: Maybe<Scalars['ID']>
  deliverySlotPrice: Maybe<Scalars['Float']>
  deliveryTime: Maybe<Scalars['String']>
  postalCode: Maybe<Scalars['String']>
}

export type UpdatePersonalInfoResponse = {
  __typename: 'UpdatePersonalInfoResponse'
  comment: Maybe<Scalars['String']>
  companyName: Maybe<Scalars['String']>
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  invoiceNumber: Maybe<Scalars['String']>
  lastName: Maybe<Scalars['String']>
  phone: Maybe<Scalars['String']>
}

export enum UserNotificationMediumEnum {
  PushNotification = 'PUSH_NOTIFICATION',
}

export type UserNotificationPreferences = {
  __typename: 'UserNotificationPreferences'
  marketing: UserNotificationSubscription
  transactional: UserNotificationSubscription
}

export type UserNotificationSubscription = {
  __typename: 'UserNotificationSubscription'
  enabled: Maybe<Scalars['Boolean']>
  pushNotification: Maybe<Scalars['Boolean']>
  type: Scalars['String']
}

export enum UserNotificationSubscriptionEnum {
  Marketing = 'MARKETING',
  Transactional = 'TRANSACTIONAL',
}

export type UserOrder = {
  __typename: 'UserOrder'
  createdAt: Scalars['String']
  customer: Maybe<Customer>
  dataSource: UserOrderDataSource
  deliveryDate: Scalars['String']
  deliveryMethod: DeliveryMethod
  deliverySlotClosed: Maybe<Scalars['Boolean']>
  /**
   * The ISO timestamp after which new orders can't be sent to slot.
   * Note modification cutoff can be at different time.
   * Can be missing if slot information can't be fetced.
   * For example "2023-02-09T09:00:00+03:00".
   */
  deliverySlotClosingTimestamp: Maybe<Scalars['String']>
  /**
   * The ISO timestamp after which an order created in the slot can no longer be modified or cancelled.
   * Can be missing if slot information can't be fetced.
   * This can differ from slot's modificationTimestamp if extra time is given for modifications,
   * for example for fixing payment card.
   * This time can change after modification fixing problems is sent.
   * For example "2023-02-09T09:00:00+03:00".
   */
  deliverySlotModificationTimestamp: Maybe<Scalars['String']>
  deliveryTime: Maybe<Scalars['String']>
  homeDeliveryType: Maybe<HomeDeliveryType>
  id: Scalars['String']
  /**
   * Can this order be canceled?
   * In most cases, this matches slot's isModifiable, but there are cases where they don't match
   */
  isCancelable: Scalars['Boolean']
  isFastTrack: Maybe<Scalars['Boolean']>
  /** Can this order be modified? */
  isModifiable: Scalars['Boolean']
  modifiedAt: Maybe<Scalars['String']>
  orderNumber: Scalars['String']
  orderStatus: OrderStatus
  paymentMethod: Maybe<PaymentMethod>
  paymentStatus: Maybe<PaymentStatus>
  pickupLocation: Maybe<Scalars['String']>
  storeId: Scalars['String']
  storeName: Maybe<Scalars['String']>
  totalCost: Scalars['Float']
  trackingUrl: Maybe<Scalars['String']>
  userId: Maybe<Scalars['String']>
}

export type UserOrderCartItem = {
  __typename: 'UserOrderCartItem'
  additionalInfo: Maybe<Scalars['String']>
  basicQuantityUnit: Maybe<Scalars['String']>
  ean: Scalars['String']
  id: Scalars['ID']
  itemCount: Scalars['Float']
  name: Scalars['String']
  price: Maybe<Scalars['Float']>
  priceUnit: Maybe<Scalars['String']>
  productType: ProductType
  replace: Maybe<Scalars['Boolean']>
}

export enum UserOrderDataSource {
  /** @deprecated Foodie does not exist */
  Foodie = 'FOODIE',
  SKaupat = 'S_KAUPAT',
}

export type UserOrderWithCartItems = {
  __typename: 'UserOrderWithCartItems'
  cartItems: Array<UserOrderCartItem>
  createdAt: Scalars['String']
  customer: Maybe<Customer>
  dataSource: UserOrderDataSource
  deliveryDate: Scalars['String']
  deliveryMethod: DeliveryMethod
  deliverySlotClosed: Maybe<Scalars['Boolean']>
  deliverySlotClosingTimestamp: Maybe<Scalars['String']>
  deliverySlotModificationTimestamp: Maybe<Scalars['String']>
  deliveryTime: Maybe<Scalars['String']>
  id: Scalars['String']
  isFastTrack: Maybe<Scalars['Boolean']>
  isModifiable: Scalars['Boolean']
  modifiedAt: Maybe<Scalars['String']>
  orderNumber: Scalars['String']
  orderStatus: OrderStatus
  paymentMethod: Maybe<PaymentMethod>
  paymentStatus: Maybe<PaymentStatus>
  pickupLocation: Maybe<Scalars['String']>
  storeId: Scalars['String']
  storeName: Maybe<Scalars['String']>
  totalCost: Scalars['Float']
  userId: Maybe<Scalars['String']>
}

export type UserProfile = {
  __typename: 'UserProfile'
  address: Maybe<Address>
  company: Maybe<Company>
  customerType: CustomerType
  email: Maybe<Scalars['String']>
  firstName: Maybe<Scalars['String']>
  isEmailVerified: Maybe<Scalars['Boolean']>
  isPhoneNumberVerified: Maybe<Scalars['Boolean']>
  lastName: Maybe<Scalars['String']>
  /** Salesforce PersonContactId */
  personContactId: Maybe<Scalars['String']>
  phoneNumber: Maybe<Scalars['String']>
  /** @deprecated Not in use, remove client usage */
  tags: Maybe<Array<Scalars['String']>>
  /** @deprecated Will be removed, use sub from accessToken instead */
  userId: Scalars['String']
}

export type WarningSymbol = {
  __typename: 'WarningSymbol'
  code: WarningSymbolCode
  description: Scalars['String']
}

export enum WarningSymbolCode {
  Ghs01 = 'GHS01',
  Ghs02 = 'GHS02',
  Ghs03 = 'GHS03',
  Ghs04 = 'GHS04',
  Ghs05 = 'GHS05',
  Ghs06 = 'GHS06',
  Ghs07 = 'GHS07',
  Ghs08 = 'GHS08',
  Ghs09 = 'GHS09',
}

export type RemoteNavigationQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoteNavigationQuery = { __typename: 'Query' } & {
  store: Maybe<
    { __typename: 'Store' } & Pick<Store, 'id'> & {
        navigation: Maybe<
          Array<
            { __typename: 'NavigationItem' } & Pick<
              NavigationItem,
              'id' | 'name' | 'slug' | 'type'
            > & {
                children: Maybe<
                  Array<
                    { __typename: 'NavigationItem' } & Pick<
                      NavigationItem,
                      'id' | 'name' | 'slug' | 'type'
                    > & {
                        children: Maybe<
                          Array<
                            { __typename: 'NavigationItem' } & Pick<
                              NavigationItem,
                              'id' | 'name' | 'slug' | 'type'
                            >
                          >
                        >
                      }
                  >
                >
              }
          >
        >
      }
  >
}

export type UserFavoritesListProducFragmentFragment = { __typename: 'Product' } & Pick<
  Product,
  | 'isGlobalFallback'
  | 'isAgeLimitedByAlcohol'
  | 'slug'
  | 'frozen'
  | 'productType'
  | 'inStore'
  | 'packagingLabels'
  | 'packagingLabelCodes'
  | 'id'
  | 'ean'
  | 'name'
  | 'price'
  | 'storeId'
  | 'approxPrice'
  | 'basicQuantityUnit'
  | 'comparisonPrice'
  | 'comparisonUnit'
  | 'consumerPackageSize'
  | 'consumerPackageUnit'
  | 'priceUnit'
  | 'quantityMultiplier'
  | 'brandName'
  | 'description'
  | 'ingredientStatement'
  | 'supplierName'
> & {
    measurement: Maybe<
      { __typename: 'ProductMeasurement' } & Pick<ProductMeasurement, 'pceApproxWeight'>
    >
    hierarchyPath: Maybe<
      Array<{ __typename: 'HierarchyPathItem' } & Pick<HierarchyPathItem, 'name' | 'id' | 'slug'>>
    >
    countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
    nutrients: Maybe<Array<{ __typename: 'Nutrient' } & Pick<Nutrient, 'name' | 'ri' | 'value'>>>
    nutrientsPerServing: Maybe<
      Array<
        { __typename: 'NutrientPerServing' } & Pick<NutrientPerServing, 'name' | 'value' | 'unit'>
      >
    >
    pricing: Maybe<
      { __typename: 'ProductPricing' } & Pick<
        ProductPricing,
        'campaignPrice' | 'lowest30DayPrice' | 'campaignPriceValidUntil' | 'regularPrice'
      >
    >
  }

export type RemoteUserFavoritesListQueryVariables = Exact<{ [key: string]: never }>

export type RemoteUserFavoritesListQuery = { __typename: 'Query' } & {
  userFavorites: { __typename: 'FavoritesList' } & {
    items: Array<{ __typename: 'FavoriteItem' } & Pick<FavoriteItem, 'ean'>>
  }
}

export type RemoteUserFavoriteProductsQueryVariables = Exact<{
  storeId: Scalars['ID']
}>

export type RemoteUserFavoriteProductsQuery = { __typename: 'Query' } & {
  userFavorites: { __typename: 'FavoritesList' } & {
    items: Array<
      { __typename: 'FavoriteItem' } & Pick<FavoriteItem, 'ean'> & {
          product: Maybe<
            { __typename: 'Product' } & Pick<
              Product,
              | 'isGlobalFallback'
              | 'isAgeLimitedByAlcohol'
              | 'slug'
              | 'frozen'
              | 'productType'
              | 'inStore'
              | 'packagingLabels'
              | 'packagingLabelCodes'
              | 'id'
              | 'ean'
              | 'name'
              | 'price'
              | 'storeId'
              | 'approxPrice'
              | 'basicQuantityUnit'
              | 'comparisonPrice'
              | 'comparisonUnit'
              | 'consumerPackageSize'
              | 'consumerPackageUnit'
              | 'priceUnit'
              | 'quantityMultiplier'
              | 'brandName'
              | 'description'
              | 'ingredientStatement'
              | 'supplierName'
            > & {
                measurement: Maybe<
                  { __typename: 'ProductMeasurement' } & Pick<ProductMeasurement, 'pceApproxWeight'>
                >
                hierarchyPath: Maybe<
                  Array<
                    { __typename: 'HierarchyPathItem' } & Pick<
                      HierarchyPathItem,
                      'name' | 'id' | 'slug'
                    >
                  >
                >
                countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
                nutrients: Maybe<
                  Array<{ __typename: 'Nutrient' } & Pick<Nutrient, 'name' | 'ri' | 'value'>>
                >
                nutrientsPerServing: Maybe<
                  Array<
                    { __typename: 'NutrientPerServing' } & Pick<
                      NutrientPerServing,
                      'name' | 'value' | 'unit'
                    >
                  >
                >
                pricing: Maybe<
                  { __typename: 'ProductPricing' } & Pick<
                    ProductPricing,
                    | 'campaignPrice'
                    | 'lowest30DayPrice'
                    | 'campaignPriceValidUntil'
                    | 'regularPrice'
                  >
                >
              }
          >
        }
    >
  }
}

export type RemoteAddFavoriteMutationVariables = Exact<{
  ean: Scalars['String']
}>

export type RemoteAddFavoriteMutation = { __typename: 'Mutation' } & {
  userFavoritesAddItem: { __typename: 'FavoritesList' } & Pick<FavoritesList, 'modifiedAt'>
}

export type RemoteRemoveFavoriteMutationVariables = Exact<{
  ean: Scalars['String']
}>

export type RemoteRemoveFavoriteMutation = { __typename: 'Mutation' } & {
  userFavoritesRemoveItem: { __typename: 'FavoritesList' } & Pick<FavoritesList, 'modifiedAt'>
}

export type UpdateAuthenticationRedirectPathMutationVariables = Exact<{
  authRedirectPath: InputMaybe<Scalars['String']>
}>

export type UpdateAuthenticationRedirectPathMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'updateAuthenticationRedirectPath'
>

export type ClearAuthenticationRedirectPathMutationVariables = Exact<{ [key: string]: never }>

export type ClearAuthenticationRedirectPathMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'clearAuthenticationRedirectPath'
>

export type UpdateAuthenticationTokensMutationVariables = Exact<{
  accessToken: Scalars['String']
  refreshToken: Scalars['String']
  idToken: Scalars['String']
}>

export type UpdateAuthenticationTokensMutation = { __typename: 'Mutation' } & {
  updateAuthenticationTokens: Maybe<
    { __typename: 'UpdateAuthenticationTokensResponse' } & Pick<
      UpdateAuthenticationTokensResponse,
      'accessToken' | 'refreshToken' | 'idToken'
    >
  >
}

export type ClearAuthenticationTokensMutationVariables = Exact<{ [key: string]: never }>

export type ClearAuthenticationTokensMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'clearAuthenticationTokens'
>

export type GetAuthenticationRedirectPathQueryVariables = Exact<{ [key: string]: never }>

export type GetAuthenticationRedirectPathQuery = { __typename: 'Query' } & Pick<
  Query,
  'authenticationRedirectPath'
>

export type GetLocalAuthenticationTokensQueryVariables = Exact<{ [key: string]: never }>

export type GetLocalAuthenticationTokensQuery = { __typename: 'Query' } & {
  authenticationTokens: Maybe<
    { __typename: 'AuthenticationTokens' } & Pick<
      AuthenticationTokens,
      'accessToken' | 'refreshToken' | 'idToken' | 'modified'
    >
  >
}

export type LocalUserProfileQueryVariables = Exact<{ [key: string]: never }>

export type LocalUserProfileQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & Pick<DomainOrder, 'comment'> & {
      customer: { __typename: 'DomainCustomer' } & Pick<
        DomainCustomer,
        | 'type'
        | 'firstName'
        | 'lastName'
        | 'phone'
        | 'email'
        | 'companyName'
        | 'stockmannCardNumber'
      >
    }
}

export type GetBonusInfoDataQueryVariables = Exact<{ [key: string]: never }>

export type GetBonusInfoDataQuery = { __typename: 'Query' } & {
  bonusInfo: Maybe<{ __typename: 'BonusInfo' } & Pick<BonusInfo, 'membershipNumber'>>
}

export type GetUserProfileDataQueryVariables = Exact<{ [key: string]: never }>

export type GetUserProfileDataQuery = { __typename: 'Query' } & {
  userProfile: Maybe<
    { __typename: 'UserProfile' } & Pick<
      UserProfile,
      | 'firstName'
      | 'lastName'
      | 'email'
      | 'phoneNumber'
      | 'userId'
      | 'customerType'
      | 'tags'
      | 'isEmailVerified'
      | 'isPhoneNumberVerified'
    > & {
        address: Maybe<
          { __typename: 'Address' } & Pick<
            Address,
            'apartmentNumber' | 'streetAddress' | 'postalCity' | 'postalCode'
          >
        >
        company: Maybe<
          { __typename: 'Company' } & Pick<Company, 'identityCode' | 'invoiceId' | 'name'>
        >
      }
  >
}

export type GetRemoteAuthenticationTokensQueryVariables = Exact<{
  refreshToken: InputMaybe<Scalars['String']>
}>

export type GetRemoteAuthenticationTokensQuery = { __typename: 'Query' } & {
  authTokens: Maybe<
    { __typename: 'AuthTokens' } & Pick<AuthTokens, 'accessToken' | 'idToken' | 'refreshToken'>
  >
}

export type RemoteUserProfileQueryVariables = Exact<{ [key: string]: never }>

export type RemoteUserProfileQuery = { __typename: 'Query' } & {
  bonusInfo: Maybe<{ __typename: 'BonusInfo' } & Pick<BonusInfo, 'membershipNumber'>>
  userProfile: Maybe<
    { __typename: 'UserProfile' } & Pick<
      UserProfile,
      'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'userId' | 'customerType' | 'tags'
    > & {
        address: Maybe<
          { __typename: 'Address' } & Pick<
            Address,
            'apartmentNumber' | 'streetAddress' | 'postalCity' | 'postalCode'
          >
        >
        company: Maybe<{ __typename: 'Company' } & Pick<Company, 'name' | 'invoiceId'>>
      }
  >
}

export type RemoteUserCompanyInfoQueryVariables = Exact<{ [key: string]: never }>

export type RemoteUserCompanyInfoQuery = { __typename: 'Query' } & {
  userProfile: Maybe<
    { __typename: 'UserProfile' } & {
      company: Maybe<{ __typename: 'Company' } & Pick<Company, 'name' | 'invoiceId'>>
    }
  >
}

export type GetInitialDataQueryVariables = Exact<{ [key: string]: never }>

export type GetInitialDataQuery = { __typename: 'Query' } & Pick<
  Query,
  'customerType' | 'selectedBrand' | 'selectedStoreId' | 'selectedAreaId'
> & {
    customerDetails: Maybe<
      { __typename: 'CustomerDetailsResponse' } & Pick<
        CustomerDetailsResponse,
        'firstName' | 'lastName' | 'phone' | 'email' | 'companyName' | 'comment'
      >
    >
    deliveryDetailsInfo: Maybe<
      { __typename: 'DeliveryDetailsInfo' } & Pick<
        DeliveryDetailsInfo,
        | 'additionalInfo'
        | 'address'
        | 'city'
        | 'deliveryDate'
        | 'deliveryMethod'
        | 'deliverySlotId'
        | 'deliverySlotPrice'
        | 'deliveryTime'
        | 'postalCode'
      >
    >
    deliveryStore: Maybe<
      { __typename: 'DeliveryStore' } & Pick<DeliveryStore, 'id' | 'name' | 'brand' | 'areaId'>
    >
    orderEditActive: Maybe<
      { __typename: 'OrderEditActiveResponse' } & Pick<
        OrderEditActiveResponse,
        'orderId' | 'orderStatus'
      >
    >
    paymentDetails: Maybe<
      { __typename: 'PaymentDetailsResponse' } & Pick<
        PaymentDetailsResponse,
        | 'paymentMethod'
        | 'invoiceNumber'
        | 'stockmannCardNumber'
        | 'paymentStatus'
        | 'selectedPaymentCardId'
        | 'savePaymentCard'
      >
    >
    authenticationTokens: Maybe<
      { __typename: 'AuthenticationTokens' } & Pick<
        AuthenticationTokens,
        'accessToken' | 'refreshToken' | 'idToken' | 'modified'
      >
    >
  }

export type CitrusAdQueryVariables = Exact<{
  placement: Scalars['String']
  slotId: Scalars['String']
  sessionId: Scalars['String']
  brand: Scalars['String']
  useCustomerId: Scalars['Boolean']
}>

export type CitrusAdQuery = { __typename: 'Query' } & {
  citrusAd: Maybe<
    { __typename: 'CitrusAd' } & Pick<CitrusAd, 'contentStandardId' | 'slotId'> & {
        banners: Maybe<
          Array<
            Maybe<
              { __typename: 'CitrusAdBanner' } & Pick<
                CitrusAdBanner,
                | 'citrusAdId'
                | 'citrusCampaignName'
                | 'backgroundImage'
                | 'heroImage'
                | 'ctaLink'
                | 'ctaText'
                | 'headingText'
                | 'bannerText'
                | 'position'
                | 'gtins'
              >
            >
          >
        >
      }
  >
}

export type SendCitrusAdUserEventMutationVariables = Exact<{
  sessionId: Scalars['String']
  adId: Scalars['String']
  eventType: CitrusAdUserEventType
  orderId: InputMaybe<Scalars['String']>
}>

export type SendCitrusAdUserEventMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'sendCitrusAdUserEvents'
>

export type ClientCartItemFieldsFragment = { __typename: 'ClientCartItem' } & Pick<
  ClientCartItem,
  | 'additionalInfo'
  | 'approxPrice'
  | 'basicQuantityUnit'
  | 'comparisonPrice'
  | 'comparisonUnit'
  | 'ean'
  | 'frozen'
  | 'id'
  | 'inStoreSelection'
  | 'isAgeLimitedByAlcohol'
  | 'itemCount'
  | 'mainCategoryName'
  | 'name'
  | 'packagingLabelCodes'
  | 'price'
  | 'priceUnit'
  | 'productType'
  | 'quantityMultiplier'
  | 'replace'
  | 'campaignPrice'
  | 'lowest30DayPrice'
  | 'campaignPriceValidUntil'
  | 'regularPrice'
> & { countryName: { __typename: 'CountryName' } & Pick<CountryName, 'fi'> }

export type GetAllClientCartItemsQueryVariables = Exact<{ [key: string]: never }>

export type GetAllClientCartItemsQuery = { __typename: 'Query' } & {
  cartItems: Array<
    { __typename: 'ClientCartItem' } & Pick<
      ClientCartItem,
      | 'additionalInfo'
      | 'approxPrice'
      | 'basicQuantityUnit'
      | 'comparisonPrice'
      | 'comparisonUnit'
      | 'ean'
      | 'frozen'
      | 'id'
      | 'inStoreSelection'
      | 'isAgeLimitedByAlcohol'
      | 'itemCount'
      | 'mainCategoryName'
      | 'name'
      | 'packagingLabelCodes'
      | 'price'
      | 'priceUnit'
      | 'productType'
      | 'quantityMultiplier'
      | 'replace'
      | 'campaignPrice'
      | 'lowest30DayPrice'
      | 'campaignPriceValidUntil'
      | 'regularPrice'
    > & { countryName: { __typename: 'CountryName' } & Pick<CountryName, 'fi'> }
  >
}

export type GetClientCartItemCountsQueryVariables = Exact<{ [key: string]: never }>

export type GetClientCartItemCountsQuery = { __typename: 'Query' } & {
  cartItems: Array<{ __typename: 'ClientCartItem' } & Pick<ClientCartItem, 'id' | 'itemCount'>>
}

export type GetClientCartItemQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetClientCartItemQuery = { __typename: 'Query' } & {
  cartItem: Maybe<
    { __typename: 'ClientCartItem' } & Pick<
      ClientCartItem,
      | 'additionalInfo'
      | 'approxPrice'
      | 'basicQuantityUnit'
      | 'comparisonPrice'
      | 'comparisonUnit'
      | 'ean'
      | 'frozen'
      | 'id'
      | 'inStoreSelection'
      | 'isAgeLimitedByAlcohol'
      | 'itemCount'
      | 'mainCategoryName'
      | 'name'
      | 'packagingLabelCodes'
      | 'price'
      | 'priceUnit'
      | 'productType'
      | 'quantityMultiplier'
      | 'replace'
      | 'campaignPrice'
      | 'lowest30DayPrice'
      | 'campaignPriceValidUntil'
      | 'regularPrice'
    > & { countryName: { __typename: 'CountryName' } & Pick<CountryName, 'fi'> }
  >
}

export type GetClientCartItemCountQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetClientCartItemCountQuery = { __typename: 'Query' } & {
  cartItem: Maybe<{ __typename: 'ClientCartItem' } & Pick<ClientCartItem, 'id' | 'itemCount'>>
}

export type ClientCartItemViewFromProductFragment = { __typename: 'Product' } & Pick<
  Product,
  | 'id'
  | 'ean'
  | 'name'
  | 'priceUnit'
  | 'price'
  | 'approxPrice'
  | 'basicQuantityUnit'
  | 'quantityMultiplier'
  | 'isAgeLimitedByAlcohol'
  | 'frozen'
  | 'packagingLabelCodes'
  | 'comparisonPrice'
  | 'comparisonUnit'
  | 'storeId'
  | 'isGlobalFallback'
> & {
    countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
    hierarchyPath: Maybe<
      Array<{ __typename: 'HierarchyPathItem' } & Pick<HierarchyPathItem, 'id' | 'name' | 'slug'>>
    >
    pricing: Maybe<
      { __typename: 'ProductPricing' } & Pick<
        ProductPricing,
        'campaignPrice' | 'lowest30DayPrice' | 'campaignPriceValidUntil' | 'regularPrice'
      >
    >
  }

export type RemoteClientCartItemFromProductQueryVariables = Exact<{
  id: Scalars['ID']
  storeId: Scalars['ID']
}>

export type RemoteClientCartItemFromProductQuery = { __typename: 'Query' } & {
  product: Maybe<
    { __typename: 'Product' } & Pick<
      Product,
      | 'id'
      | 'ean'
      | 'name'
      | 'priceUnit'
      | 'price'
      | 'approxPrice'
      | 'basicQuantityUnit'
      | 'quantityMultiplier'
      | 'isAgeLimitedByAlcohol'
      | 'frozen'
      | 'packagingLabelCodes'
      | 'comparisonPrice'
      | 'comparisonUnit'
      | 'storeId'
      | 'isGlobalFallback'
    > & {
        countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
        hierarchyPath: Maybe<
          Array<
            { __typename: 'HierarchyPathItem' } & Pick<HierarchyPathItem, 'id' | 'name' | 'slug'>
          >
        >
        pricing: Maybe<
          { __typename: 'ProductPricing' } & Pick<
            ProductPricing,
            'campaignPrice' | 'lowest30DayPrice' | 'campaignPriceValidUntil' | 'regularPrice'
          >
        >
      }
  >
}

export type ComplementaryProductsQueryVariables = Exact<{
  focusOnEan: InputMaybe<Scalars['ID']>
  eans: Array<Scalars['ID']> | Scalars['ID']
  storeId: Scalars['ID']
  limit: InputMaybe<Scalars['Int']>
}>

export type ComplementaryProductsQuery = { __typename: 'Query' } & {
  complementaryProducts: Array<
    { __typename: 'Product' } & Pick<
      Product,
      | 'brandName'
      | 'slug'
      | 'isAgeLimitedByAlcohol'
      | 'frozen'
      | 'packagingLabels'
      | 'packagingLabelCodes'
      | 'isGlobalFallback'
      | 'id'
      | 'ean'
      | 'name'
      | 'price'
      | 'storeId'
      | 'approxPrice'
      | 'basicQuantityUnit'
      | 'comparisonPrice'
      | 'comparisonUnit'
      | 'consumerPackageSize'
      | 'consumerPackageUnit'
      | 'priceUnit'
      | 'quantityMultiplier'
    > & {
        hierarchyPath: Maybe<
          Array<
            { __typename: 'HierarchyPathItem' } & Pick<HierarchyPathItem, 'id' | 'name' | 'slug'>
          >
        >
        countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
        pricing: Maybe<
          { __typename: 'ProductPricing' } & Pick<
            ProductPricing,
            'campaignPrice' | 'lowest30DayPrice' | 'campaignPriceValidUntil' | 'regularPrice'
          >
        >
      }
  >
}

export type UpdatePersonalInfoMutationVariables = Exact<{
  firstName: InputMaybe<Scalars['String']>
  lastName: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
  email: InputMaybe<Scalars['String']>
  companyName: InputMaybe<Scalars['String']>
  comment: InputMaybe<Scalars['String']>
}>

export type UpdatePersonalInfoMutation = { __typename: 'Mutation' } & {
  updatePersonalInfo: Maybe<
    { __typename: 'UpdatePersonalInfoResponse' } & Pick<
      UpdatePersonalInfoResponse,
      'firstName' | 'lastName' | 'phone' | 'email' | 'companyName' | 'comment'
    >
  >
}

export type UpdateCustomerInfoMutationVariables = Exact<{
  customerType: InputMaybe<Scalars['String']>
  firstName: InputMaybe<Scalars['String']>
  lastName: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
  email: InputMaybe<Scalars['String']>
  companyName: InputMaybe<Scalars['String']>
}>

export type UpdateCustomerInfoMutation = { __typename: 'Mutation' } & {
  updateCustomerInfo: Maybe<
    { __typename: 'UpdateCustomerInfoResponse' } & Pick<
      UpdateCustomerInfoResponse,
      'customerType' | 'firstName' | 'lastName' | 'phone' | 'email' | 'companyName'
    >
  >
}

export type UpdateCustomerInfoTemplateMutationVariables = Exact<{
  customerType: InputMaybe<Scalars['String']>
  firstName: InputMaybe<Scalars['String']>
  lastName: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
  email: InputMaybe<Scalars['String']>
  companyName: InputMaybe<Scalars['String']>
  stockmannCardNumber: InputMaybe<Scalars['String']>
}>

export type UpdateCustomerInfoTemplateMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'updateStockmannCardNumber'
> & {
    updateCustomerInfo: Maybe<
      { __typename: 'UpdateCustomerInfoResponse' } & Pick<
        UpdateCustomerInfoResponse,
        'customerType' | 'firstName' | 'lastName' | 'phone' | 'email' | 'companyName'
      >
    >
  }

export type GetCustomerDetailsQueryVariables = Exact<{ [key: string]: never }>

export type GetCustomerDetailsQuery = { __typename: 'Query' } & {
  customerDetails: Maybe<
    { __typename: 'CustomerDetailsResponse' } & Pick<
      CustomerDetailsResponse,
      'firstName' | 'lastName' | 'phone' | 'email' | 'companyName' | 'comment'
    >
  >
}

export type GetCustomerTypeQueryVariables = Exact<{ [key: string]: never }>

export type GetCustomerTypeQuery = { __typename: 'Query' } & Pick<Query, 'customerType'>

export type UpdateDeliveryDetailsMutationVariables = Exact<{
  deliveryDate: InputMaybe<Scalars['String']>
  deliveryTime: InputMaybe<Scalars['String']>
  deliverySlotPrice: InputMaybe<Scalars['Float']>
  deliveryMethod: InputMaybe<DeliveryMethod>
  deliverySlotId: InputMaybe<Scalars['ID']>
  address: InputMaybe<Scalars['String']>
  postalCode: InputMaybe<Scalars['String']>
  city: InputMaybe<Scalars['String']>
  additionalInfo: InputMaybe<Scalars['String']>
}>

export type UpdateDeliveryDetailsMutation = { __typename: 'Mutation' } & {
  updateDeliveryDetails: Maybe<
    { __typename: 'UpdateDeliveryDetailsResponse' } & Pick<
      UpdateDeliveryDetailsResponse,
      | 'deliveryDate'
      | 'deliveryTime'
      | 'deliverySlotPrice'
      | 'deliverySlotId'
      | 'additionalInfo'
      | 'address'
      | 'postalCode'
      | 'city'
    >
  >
}

export type GetDeliveryDetailsInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetDeliveryDetailsInfoQuery = { __typename: 'Query' } & {
  deliveryDetailsInfo: Maybe<
    { __typename: 'DeliveryDetailsInfo' } & Pick<
      DeliveryDetailsInfo,
      | 'additionalInfo'
      | 'address'
      | 'city'
      | 'deliveryDate'
      | 'deliveryMethod'
      | 'deliverySlotId'
      | 'deliverySlotPrice'
      | 'deliveryTime'
      | 'postalCode'
    >
  >
}

export type GetSelectedDeliverySlotQueryVariables = Exact<{ [key: string]: never }>

export type GetSelectedDeliverySlotQuery = { __typename: 'Query' } & {
  deliveryDetailsInfo: Maybe<
    { __typename: 'DeliveryDetailsInfo' } & Pick<DeliveryDetailsInfo, 'deliverySlotId'>
  >
}

export type DeliveryOptionFragment = { __typename: 'DeliveryArea' } & Pick<
  DeliveryArea,
  | 'name'
  | 'areaId'
  | 'storeId'
  | 'price'
  | 'description'
  | 'deliveryMethod'
  | 'homeDeliveryType'
  | 'alcoholSellingAllowed'
  | 'isFastTrack'
> & {
    store: Maybe<
      { __typename: 'StoreInfo' } & Pick<
        StoreInfo,
        'name' | 'id' | 'brand' | 'city' | 'street' | 'postalCode' | 'availablePaymentMethods'
      >
    >
    districts: Maybe<
      Array<{ __typename: 'DeliveryDistrict' } & Pick<DeliveryDistrict, 'city' | 'postalCode'>>
    >
    address: Maybe<
      { __typename: 'DeliveryAreaAddress' } & Pick<
        DeliveryAreaAddress,
        'city' | 'postalCode' | 'street'
      >
    >
  }

export type SearchDeliveryAreasQueryVariables = Exact<{
  deliveryMethod: InputMaybe<DeliveryMethod>
  freetext: InputMaybe<Scalars['String']>
  location: InputMaybe<Location>
  postalCode: InputMaybe<Scalars['String']>
  storeId: Scalars['ID']
}>

export type SearchDeliveryAreasQuery = { __typename: 'Query' } & {
  searchDeliveryAreas: Maybe<
    { __typename: 'DeliveryAreaSearchResponse' } & {
      areas: Maybe<
        Array<
          { __typename: 'DeliveryArea' } & Pick<
            DeliveryArea,
            | 'name'
            | 'areaId'
            | 'storeId'
            | 'price'
            | 'description'
            | 'deliveryMethod'
            | 'homeDeliveryType'
            | 'alcoholSellingAllowed'
            | 'isFastTrack'
          > & {
              location: Maybe<
                { __typename: 'DeliveryLocation' } & Pick<DeliveryLocation, 'distance'>
              >
              nextDeliverySlot: Maybe<
                { __typename: 'NextDeliverySlot' } & Pick<
                  NextDeliverySlot,
                  | 'availability'
                  | 'estimatedFastTrackTime'
                  | 'isClosed'
                  | 'isFastTrack'
                  | 'price'
                  | 'slotId'
                  | 'startDateTime'
                >
              >
              store: Maybe<
                { __typename: 'StoreInfo' } & Pick<
                  StoreInfo,
                  | 'name'
                  | 'id'
                  | 'brand'
                  | 'city'
                  | 'street'
                  | 'postalCode'
                  | 'availablePaymentMethods'
                >
              >
              districts: Maybe<
                Array<
                  { __typename: 'DeliveryDistrict' } & Pick<DeliveryDistrict, 'city' | 'postalCode'>
                >
              >
              address: Maybe<
                { __typename: 'DeliveryAreaAddress' } & Pick<
                  DeliveryAreaAddress,
                  'city' | 'postalCode' | 'street'
                >
              >
            }
        >
      >
    }
  >
}

export type GetDeliveryAreaQueryVariables = Exact<{
  endDate: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  orderId: InputMaybe<Scalars['ID']>
  reservationId: InputMaybe<Scalars['ID']>
  startDate: InputMaybe<Scalars['String']>
}>

export type GetDeliveryAreaQuery = { __typename: 'Query' } & {
  deliveryArea: Maybe<
    { __typename: 'DeliveryArea' } & Pick<
      DeliveryArea,
      'name' | 'areaId' | 'storeId' | 'price' | 'description' | 'alcoholSellingAllowed'
    > & {
        deliverySlots: Maybe<
          Array<
            { __typename: 'CollatedDeliverySlot' } & Pick<CollatedDeliverySlot, 'date'> & {
                deliveryTimes: Array<
                  { __typename: 'DeliverySlot' } & Pick<
                    DeliverySlot,
                    | 'slotId'
                    | 'areaId'
                    | 'isClosed'
                    | 'availability'
                    | 'startDateTime'
                    | 'endDateTime'
                    | 'closingTimestamp'
                    | 'price'
                    | 'alcoholSellingAllowed'
                    | 'modificationTimestamp'
                    | 'isFastTrack'
                    | 'time'
                    | 'isModifiable'
                  >
                >
              }
          >
        >
        store: Maybe<
          { __typename: 'StoreInfo' } & Pick<
            StoreInfo,
            'name' | 'id' | 'brand' | 'city' | 'street' | 'availablePaymentMethods'
          >
        >
        districts: Maybe<
          Array<{ __typename: 'DeliveryDistrict' } & Pick<DeliveryDistrict, 'city' | 'postalCode'>>
        >
        address: Maybe<
          { __typename: 'DeliveryAreaAddress' } & Pick<
            DeliveryAreaAddress,
            'city' | 'postalCode' | 'street'
          >
        >
      }
  >
}

export type GetDeliveryAreaDetailsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetDeliveryAreaDetailsQuery = { __typename: 'Query' } & {
  deliveryArea: Maybe<
    { __typename: 'DeliveryArea' } & Pick<
      DeliveryArea,
      'areaId' | 'name' | 'isFastTrack' | 'dynamicPricing'
    > & {
        store: Maybe<{ __typename: 'StoreInfo' } & Pick<StoreInfo, 'id' | 'name'>>
        address: Maybe<{ __typename: 'DeliveryAreaAddress' } & Pick<DeliveryAreaAddress, 'city'>>
      }
  >
}

export type GetDeliveryAreaWithNextSlotQueryVariables = Exact<{
  id: Scalars['ID']
  includeNextSlot?: InputMaybe<Scalars['Boolean']>
}>

export type GetDeliveryAreaWithNextSlotQuery = { __typename: 'Query' } & {
  deliveryArea: Maybe<
    { __typename: 'DeliveryArea' } & Pick<
      DeliveryArea,
      'areaId' | 'name' | 'isFastTrack' | 'deliveryMethod'
    > & {
        store: Maybe<
          { __typename: 'StoreInfo' } & Pick<StoreInfo, 'id' | 'name' | 'shortName' | 'brand'>
        >
        nextDeliverySlot?: Maybe<
          { __typename: 'NextDeliverySlot' } & Pick<
            NextDeliverySlot,
            | 'availability'
            | 'estimatedFastTrackTime'
            | 'isClosed'
            | 'isFastTrack'
            | 'slotId'
            | 'startDateTime'
            | 'price'
          >
        >
      }
  >
}

export type GetDeliverySlotByIdQueryVariables = Exact<{
  slotId: Scalars['ID']
}>

export type GetDeliverySlotByIdQuery = { __typename: 'Query' } & {
  deliverySlot: Maybe<
    { __typename: 'DeliverySlot' } & Pick<
      DeliverySlot,
      | 'alcoholSellingAllowed'
      | 'areaId'
      | 'availability'
      | 'closingTimestamp'
      | 'deliveryMethod'
      | 'endDateTime'
      | 'estimatedFastTrackTime'
      | 'isClosed'
      | 'isFastTrack'
      | 'isModifiable'
      | 'modificationTimestamp'
      | 'price'
      | 'slotId'
      | 'startDateTime'
      | 'time'
    >
  >
}

export type CreateDeliverySlotReservationMutationVariables = Exact<{
  deliverySlotId: Scalars['ID']
}>

export type CreateDeliverySlotReservationMutation = { __typename: 'Mutation' } & {
  createDeliverySlotReservation: { __typename: 'DeliverySlotReservationResponse' } & Pick<
    DeliverySlotReservationResponse,
    'reservationId' | 'expiresAt'
  >
}

export type RefreshDeliverySlotReservationMutationVariables = Exact<{
  reservationId: Scalars['ID']
}>

export type RefreshDeliverySlotReservationMutation = { __typename: 'Mutation' } & {
  refreshDeliverySlotReservation: { __typename: 'DeliverySlotReservationResponse' } & Pick<
    DeliverySlotReservationResponse,
    'reservationId' | 'expiresAt'
  >
}

export type ReleaseDeliverySlotReservationMutationVariables = Exact<{
  reservationId: Scalars['ID']
}>

export type ReleaseDeliverySlotReservationMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'releaseDeliverySlotReservation'
>

export type ReservedDeliverySlotFragment = { __typename: 'DeliverySlot' } & Pick<
  DeliverySlot,
  | 'alcoholSellingAllowed'
  | 'availability'
  | 'deliveryMethod'
  | 'endDateTime'
  | 'estimatedFastTrackTime'
  | 'isClosed'
  | 'isFastTrack'
  | 'isModifiable'
  | 'modificationTimestamp'
  | 'price'
  | 'slotId'
  | 'startDateTime'
  | 'time'
>

export type DeliverySlotReservationQueryVariables = Exact<{
  reservationId: Scalars['ID']
}>

export type DeliverySlotReservationQuery = { __typename: 'Query' } & {
  deliverySlotReservation: { __typename: 'DeliverySlotReservationResponse' } & Pick<
    DeliverySlotReservationResponse,
    'reservationId' | 'expiresAt'
  > & {
      deliveryArea: { __typename: 'DeliveryArea' } & Pick<
        DeliveryArea,
        'alcoholSellingAllowed' | 'areaId'
      >
      deliverySlot: { __typename: 'DeliverySlot' } & Pick<
        DeliverySlot,
        | 'alcoholSellingAllowed'
        | 'availability'
        | 'deliveryMethod'
        | 'endDateTime'
        | 'estimatedFastTrackTime'
        | 'isClosed'
        | 'isFastTrack'
        | 'isModifiable'
        | 'modificationTimestamp'
        | 'price'
        | 'slotId'
        | 'startDateTime'
        | 'time'
      >
    }
}

export type DeliverySlotReservationViewFromDeliverySlotQueryVariables = Exact<{
  slotId: Scalars['ID']
}>

export type DeliverySlotReservationViewFromDeliverySlotQuery = { __typename: 'Query' } & {
  deliverySlot: Maybe<
    { __typename: 'DeliverySlot' } & Pick<
      DeliverySlot,
      | 'alcoholSellingAllowed'
      | 'availability'
      | 'deliveryMethod'
      | 'endDateTime'
      | 'estimatedFastTrackTime'
      | 'isClosed'
      | 'isFastTrack'
      | 'isModifiable'
      | 'modificationTimestamp'
      | 'price'
      | 'slotId'
      | 'startDateTime'
      | 'time'
    >
  >
}

export type SendGiosgChatMetadataMutationVariables = Exact<{
  visitorId: Scalars['String']
  storeId: InputMaybe<Scalars['String']>
}>

export type SendGiosgChatMetadataMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'sendGiosgChatMetadata'
>

export type CancelOrderModificationMutationVariables = Exact<{
  isLoggedIn: InputMaybe<Scalars['Boolean']>
}>

export type CancelOrderModificationMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'clearCartAndOrderDetails' | 'resetDeliveryStore'
>

export type SetExistingOrderAsEditableOrderMutationVariables = Exact<{
  orderId: InputMaybe<Scalars['ID']>
  deliveryDate: InputMaybe<Scalars['String']>
  deliveryTime: InputMaybe<Scalars['String']>
  address: InputMaybe<Scalars['String']>
  postalCode: InputMaybe<Scalars['String']>
  city: InputMaybe<Scalars['String']>
  additionalInfo: InputMaybe<Scalars['String']>
  paymentMethod: InputMaybe<PaymentMethod>
  comment: InputMaybe<Scalars['String']>
  deliveryMethod: InputMaybe<DeliveryMethod>
  deliverySlotId: InputMaybe<Scalars['ID']>
  customer: InputMaybe<CustomerInput>
  selectedStoreId: InputMaybe<Scalars['ID']>
  firstName: InputMaybe<Scalars['String']>
  lastName: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
  email: InputMaybe<Scalars['String']>
  companyName: InputMaybe<Scalars['String']>
  invoiceNumber: InputMaybe<Scalars['String']>
  stockmannCardNumber: InputMaybe<Scalars['String']>
  paymentStatus: InputMaybe<Scalars['String']>
  orderStatus: InputMaybe<OrderStatus>
  selectedAreaId: InputMaybe<Scalars['String']>
  selectedStoreName: InputMaybe<Scalars['String']>
  selectedBrand: InputMaybe<Scalars['String']>
  areaId: InputMaybe<Scalars['ID']>
  storeName: InputMaybe<Scalars['String']>
  storeBrand: InputMaybe<Scalars['String']>
  deliverySlotPrice: InputMaybe<Scalars['Float']>
  customerType: InputMaybe<Scalars['String']>
  selectedPaymentCardId: InputMaybe<Scalars['ID']>
  savePaymentCard: InputMaybe<Scalars['Boolean']>
}>

export type SetExistingOrderAsEditableOrderMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  | 'startOrderEdit'
  | 'updateSelectedBrand'
  | 'updateSelectedStoreId'
  | 'updateSelectedAreaId'
  | 'setDeliveryStore'
  | 'updateCustomerType'
  | 'updatePaymentMethodDetails'
> & {
    updateDeliveryDetails: Maybe<
      { __typename: 'UpdateDeliveryDetailsResponse' } & Pick<
        UpdateDeliveryDetailsResponse,
        | 'deliveryDate'
        | 'deliveryTime'
        | 'deliverySlotPrice'
        | 'deliveryMethod'
        | 'deliverySlotId'
        | 'additionalInfo'
        | 'address'
        | 'postalCode'
        | 'city'
      >
    >
    updatePersonalInfo: Maybe<
      { __typename: 'UpdatePersonalInfoResponse' } & Pick<
        UpdatePersonalInfoResponse,
        'firstName' | 'lastName' | 'phone' | 'email' | 'companyName' | 'comment'
      >
    >
  }

export type SetExistingOrderAsOrderTemplateMutationVariables = Exact<{
  orderId: InputMaybe<Scalars['ID']>
  deliveryDate: InputMaybe<Scalars['String']>
  deliveryTime: InputMaybe<Scalars['String']>
  address: InputMaybe<Scalars['String']>
  postalCode: InputMaybe<Scalars['String']>
  city: InputMaybe<Scalars['String']>
  additionalInfo: InputMaybe<Scalars['String']>
  paymentMethod: InputMaybe<PaymentMethod>
  comment: InputMaybe<Scalars['String']>
  deliveryMethod: InputMaybe<DeliveryMethod>
  deliverySlotId: InputMaybe<Scalars['ID']>
  customer: InputMaybe<CustomerInput>
  selectedStoreId: InputMaybe<Scalars['ID']>
  firstName: InputMaybe<Scalars['String']>
  lastName: InputMaybe<Scalars['String']>
  phone: InputMaybe<Scalars['String']>
  email: InputMaybe<Scalars['String']>
  companyName: InputMaybe<Scalars['String']>
  invoiceNumber: InputMaybe<Scalars['String']>
  stockmannCardNumber: InputMaybe<Scalars['String']>
  paymentStatus: InputMaybe<Scalars['String']>
  selectedAreaId: InputMaybe<Scalars['String']>
  selectedStoreName: InputMaybe<Scalars['String']>
  areaId: InputMaybe<Scalars['ID']>
  storeName: InputMaybe<Scalars['String']>
  storeBrand: InputMaybe<Scalars['String']>
  deliverySlotPrice: InputMaybe<Scalars['Float']>
  customerType: InputMaybe<Scalars['String']>
  selectedPaymentCardId: InputMaybe<Scalars['ID']>
  savePaymentCard: InputMaybe<Scalars['Boolean']>
}>

export type SetExistingOrderAsOrderTemplateMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  | 'updateSelectedStoreId'
  | 'updateSelectedAreaId'
  | 'setDeliveryStore'
  | 'updateCustomerType'
  | 'updatePaymentMethodDetails'
  | 'stopOrderEdit'
> & {
    updateDeliveryDetails: Maybe<
      { __typename: 'UpdateDeliveryDetailsResponse' } & Pick<
        UpdateDeliveryDetailsResponse,
        | 'deliveryDate'
        | 'deliveryTime'
        | 'deliveryMethod'
        | 'deliverySlotId'
        | 'additionalInfo'
        | 'address'
        | 'postalCode'
        | 'city'
      >
    >
    updatePersonalInfo: Maybe<
      { __typename: 'UpdatePersonalInfoResponse' } & Pick<
        UpdatePersonalInfoResponse,
        'firstName' | 'lastName' | 'phone' | 'email' | 'companyName' | 'comment'
      >
    >
  }

export type StartOrderEditMutationVariables = Exact<{
  orderId: InputMaybe<Scalars['ID']>
  orderStatus: InputMaybe<OrderStatus>
}>

export type StartOrderEditMutation = { __typename: 'Mutation' } & Pick<Mutation, 'startOrderEdit'>

export type StopOrderEditMutationVariables = Exact<{ [key: string]: never }>

export type StopOrderEditMutation = { __typename: 'Mutation' } & Pick<Mutation, 'stopOrderEdit'>

export type ClearCartAndOrderDetailsMutationVariables = Exact<{
  isLoggedIn: InputMaybe<Scalars['Boolean']>
}>

export type ClearCartAndOrderDetailsMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'clearCartAndOrderDetails'
>

export type CreateOrderMutationVariables = Exact<{
  order: InputMaybe<OrderInput>
}>

export type CreateOrderMutation = { __typename: 'Mutation' } & {
  createOrder: Maybe<
    { __typename: 'Order' } & Pick<
      Order,
      | 'id'
      | 'linkVerificationToken'
      | 'deliveryDate'
      | 'orderNumber'
      | 'deliveryMethod'
      | 'discountCode'
      | 'paymentMethod'
      | 'paymentStatus'
      | 'deliveryTime'
      | 'deliverySlotId'
      | 'additionalInfo'
      | 'comment'
      | 'storeId'
      | 'orderStatus'
    > & {
        customer: Maybe<
          { __typename: 'Customer' } & Pick<
            Customer,
            | 'firstName'
            | 'lastName'
            | 'address'
            | 'postalCode'
            | 'city'
            | 'email'
            | 'companyName'
            | 'invoiceNumber'
            | 'phone'
            | 'bonusCard'
            | 'sMembershipNumber'
            | 'sMembershipCardLastName'
            | 'sMembershipCardPostalCode'
          >
        >
        cartItems: Maybe<
          Array<
            { __typename: 'CartItem' } & Pick<
              CartItem,
              | 'id'
              | 'ean'
              | 'itemCount'
              | 'price'
              | 'name'
              | 'replace'
              | 'additionalInfo'
              | 'basicQuantityUnit'
              | 'priceUnit'
            >
          >
        >
      }
  >
}

export type UpdateOrderMutationVariables = Exact<{
  id: InputMaybe<Scalars['String']>
  order: InputMaybe<OrderInput>
}>

export type UpdateOrderMutation = { __typename: 'Mutation' } & {
  updateOrder: Maybe<
    { __typename: 'Order' } & Pick<
      Order,
      | 'id'
      | 'linkVerificationToken'
      | 'deliveryDate'
      | 'orderNumber'
      | 'deliveryMethod'
      | 'paymentMethod'
      | 'paymentStatus'
      | 'discountCode'
      | 'deliveryTime'
      | 'deliverySlotId'
      | 'additionalInfo'
      | 'comment'
      | 'storeId'
      | 'orderStatus'
    > & {
        customer: Maybe<
          { __typename: 'Customer' } & Pick<
            Customer,
            | 'firstName'
            | 'lastName'
            | 'address'
            | 'postalCode'
            | 'city'
            | 'email'
            | 'companyName'
            | 'invoiceNumber'
            | 'phone'
            | 'bonusCard'
            | 'sMembershipNumber'
            | 'sMembershipCardLastName'
            | 'sMembershipCardPostalCode'
          >
        >
        cartItems: Maybe<
          Array<
            { __typename: 'CartItem' } & Pick<
              CartItem,
              | 'id'
              | 'ean'
              | 'itemCount'
              | 'price'
              | 'name'
              | 'replace'
              | 'additionalInfo'
              | 'basicQuantityUnit'
              | 'priceUnit'
            >
          >
        >
      }
  >
}

export type CancelOrderMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type CancelOrderMutation = { __typename: 'Mutation' } & {
  cancelOrder: Maybe<{ __typename: 'Order' } & Pick<Order, 'id' | 'orderStatus'>>
}

export type ResetOrderPaymentCardMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ResetOrderPaymentCardMutation = { __typename: 'Mutation' } & {
  resetOrderPaymentCard: Maybe<
    { __typename: 'ResetOrderPaymentCardResponse' } & Pick<ResetOrderPaymentCardResponse, 'id'>
  >
}

export type IsOrderEditActiveQueryVariables = Exact<{ [key: string]: never }>

export type IsOrderEditActiveQuery = { __typename: 'Query' } & {
  orderEditActive: Maybe<
    { __typename: 'OrderEditActiveResponse' } & Pick<
      OrderEditActiveResponse,
      'orderId' | 'orderStatus'
    >
  >
}

export type GetOrderDataQueryVariables = Exact<{ [key: string]: never }>

export type GetOrderDataQuery = { __typename: 'Query' } & Pick<
  Query,
  'selectedStoreId' | 'customerType'
> & {
    paymentDetails: Maybe<
      { __typename: 'PaymentDetailsResponse' } & Pick<
        PaymentDetailsResponse,
        | 'paymentMethod'
        | 'invoiceNumber'
        | 'stockmannCardNumber'
        | 'paymentStatus'
        | 'selectedPaymentCardId'
        | 'savePaymentCard'
      >
    >
    cartItems: Array<
      { __typename: 'ClientCartItem' } & Pick<
        ClientCartItem,
        | 'id'
        | 'ean'
        | 'name'
        | 'itemCount'
        | 'priceUnit'
        | 'price'
        | 'replace'
        | 'additionalInfo'
        | 'approxPrice'
        | 'basicQuantityUnit'
        | 'inStoreSelection'
        | 'quantityMultiplier'
        | 'productType'
        | 'isAgeLimitedByAlcohol'
      >
    >
    customerDetails: Maybe<
      { __typename: 'CustomerDetailsResponse' } & Pick<
        CustomerDetailsResponse,
        'firstName' | 'lastName' | 'phone' | 'email' | 'companyName' | 'comment'
      >
    >
    deliveryDetailsInfo: Maybe<
      { __typename: 'DeliveryDetailsInfo' } & Pick<
        DeliveryDetailsInfo,
        | 'additionalInfo'
        | 'address'
        | 'city'
        | 'deliveryDate'
        | 'deliveryMethod'
        | 'deliverySlotId'
        | 'deliverySlotPrice'
        | 'deliveryTime'
        | 'postalCode'
      >
    >
  }

export type GetOrderByIdQueryVariables = Exact<{
  id: Scalars['ID']
  linkVerificationToken: InputMaybe<Scalars['String']>
}>

export type GetOrderByIdQuery = { __typename: 'Query' } & {
  order: Maybe<
    { __typename: 'Order' } & Pick<
      Order,
      | 'id'
      | 'linkVerificationToken'
      | 'deliveryDate'
      | 'deliveryMethod'
      | 'paymentMethod'
      | 'paymentStatus'
      | 'deliveryTime'
      | 'deliverySlotId'
      | 'additionalInfo'
      | 'orderStatus'
      | 'comment'
      | 'discountCode'
      | 'orderNumber'
      | 'isModifiable'
      | 'isCancelable'
      | 'storeId'
      | 'isFastTrack'
    > & {
        deliverySlot: Maybe<
          { __typename: 'DeliverySlot' } & Pick<
            DeliverySlot,
            | 'areaId'
            | 'closingTimestamp'
            | 'deliveryMethod'
            | 'endDateTime'
            | 'estimatedFastTrackTime'
            | 'isClosed'
            | 'isFastTrack'
            | 'price'
            | 'slotId'
            | 'startDateTime'
            | 'modificationTimestamp'
            | 'isModifiable'
          >
        >
        customer: Maybe<
          { __typename: 'Customer' } & Pick<
            Customer,
            | 'firstName'
            | 'lastName'
            | 'address'
            | 'postalCode'
            | 'city'
            | 'email'
            | 'companyName'
            | 'invoiceNumber'
            | 'phone'
            | 'bonusCard'
            | 'invoiceAddress'
            | 'invoiceCity'
            | 'invoiceCompanyName'
            | 'invoiceFirstName'
            | 'invoiceLastName'
            | 'invoicePhone'
            | 'invoicePostalCode'
            | 'sMembershipNumber'
            | 'sMembershipCardLastName'
            | 'sMembershipCardPostalCode'
          >
        >
        cartItems: Maybe<
          Array<
            { __typename: 'CartItem' } & Pick<
              CartItem,
              | 'id'
              | 'ean'
              | 'itemCount'
              | 'price'
              | 'name'
              | 'replace'
              | 'additionalInfo'
              | 'basicQuantityUnit'
              | 'priceUnit'
              | 'brand'
              | 'productType'
            >
          >
        >
      }
  >
}

export type GetOrderHistoryDataQueryVariables = Exact<{
  domain: InputMaybe<Domain>
  dataSources: InputMaybe<Array<UserOrderDataSource> | UserOrderDataSource>
}>

export type GetOrderHistoryDataQuery = { __typename: 'Query' } & {
  userOrders: Array<
    { __typename: 'UserOrder' } & Pick<
      UserOrder,
      | 'dataSource'
      | 'deliveryDate'
      | 'deliveryMethod'
      | 'deliverySlotClosed'
      | 'deliverySlotModificationTimestamp'
      | 'deliveryTime'
      | 'homeDeliveryType'
      | 'id'
      | 'isFastTrack'
      | 'isModifiable'
      | 'isCancelable'
      | 'orderNumber'
      | 'orderStatus'
      | 'paymentMethod'
      | 'paymentStatus'
      | 'storeName'
      | 'totalCost'
    >
  >
}

export type GetUserOrderByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetUserOrderByIdQuery = { __typename: 'Query' } & {
  userOrder: Maybe<
    { __typename: 'UserOrderWithCartItems' } & Pick<
      UserOrderWithCartItems,
      | 'id'
      | 'createdAt'
      | 'storeName'
      | 'storeId'
      | 'paymentMethod'
      | 'paymentStatus'
      | 'deliveryDate'
      | 'deliveryMethod'
      | 'deliverySlotClosed'
      | 'deliverySlotClosingTimestamp'
      | 'deliverySlotModificationTimestamp'
      | 'deliveryTime'
      | 'modifiedAt'
      | 'orderNumber'
      | 'orderStatus'
      | 'totalCost'
      | 'userId'
      | 'isModifiable'
      | 'dataSource'
    > & {
        customer: Maybe<{ __typename: 'Customer' } & Pick<Customer, 'firstName' | 'lastName'>>
        cartItems: Array<
          { __typename: 'UserOrderCartItem' } & Pick<
            UserOrderCartItem,
            | 'id'
            | 'ean'
            | 'itemCount'
            | 'name'
            | 'basicQuantityUnit'
            | 'productType'
            | 'price'
            | 'priceUnit'
            | 'additionalInfo'
            | 'replace'
          >
        >
      }
  >
}

export type RemoteGetRecentOrdersQueryVariables = Exact<{
  domain: InputMaybe<Domain>
  dataSources: InputMaybe<Array<UserOrderDataSource> | UserOrderDataSource>
  limit: InputMaybe<Scalars['Int']>
}>

export type RemoteGetRecentOrdersQuery = { __typename: 'Query' } & {
  userOrders: Array<
    { __typename: 'UserOrder' } & Pick<
      UserOrder,
      | 'id'
      | 'createdAt'
      | 'storeName'
      | 'storeId'
      | 'deliveryDate'
      | 'deliveryMethod'
      | 'deliveryTime'
      | 'totalCost'
      | 'orderStatus'
      | 'isModifiable'
      | 'isFastTrack'
      | 'homeDeliveryType'
    >
  >
}

export type UpdatePaymentMethodDetailsMutationVariables = Exact<{
  paymentMethod: InputMaybe<PaymentMethod>
  invoiceNumber: InputMaybe<Scalars['String']>
  stockmannCardNumber: InputMaybe<Scalars['String']>
  paymentStatus: InputMaybe<Scalars['String']>
  selectedPaymentCardId: InputMaybe<Scalars['ID']>
  savePaymentCard: InputMaybe<Scalars['Boolean']>
}>

export type UpdatePaymentMethodDetailsMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'updatePaymentMethodDetails'
>

export type CreatePaymentMutationVariables = Exact<{
  orderId: Scalars['ID']
  device: DeviceType
  customWebstoreRedirectUrl: InputMaybe<Scalars['String']>
  cardId: InputMaybe<Scalars['ID']>
  shouldSavePaymentCard: InputMaybe<Scalars['Boolean']>
}>

export type CreatePaymentMutation = { __typename: 'Mutation' } & {
  createPayment: Maybe<
    { __typename: 'PaymentTerminalAddress' } & Pick<PaymentTerminalAddress, 'redirectUrl'>
  >
}

export type AuthorizePaymentMutationVariables = Exact<{
  orderId: Scalars['ID']
}>

export type AuthorizePaymentMutation = { __typename: 'Mutation' } & {
  authorizePayment: Maybe<
    { __typename: 'AuthorizePaymentResponse' } & Pick<
      AuthorizePaymentResponse,
      'orderId' | 'authorized'
    >
  >
}

export type SaveUserPaymentCardMutationVariables = Exact<{
  orderId: Scalars['ID']
}>

export type SaveUserPaymentCardMutation = { __typename: 'Mutation' } & {
  saveUserPaymentCard: Maybe<
    { __typename: 'SaveUserPaymentCardResponse' } & Pick<SaveUserPaymentCardResponse, 'id'>
  >
}

export type RemoveUserPaymentCardMutationVariables = Exact<{
  cardId: Scalars['ID']
}>

export type RemoveUserPaymentCardMutation = { __typename: 'Mutation' } & {
  removeUserPaymentCard: Maybe<
    { __typename: 'RemoveUserPaymentCardResponse' } & Pick<RemoveUserPaymentCardResponse, 'id'>
  >
}

export type RenameUserPaymentCardMutationVariables = Exact<{
  cardId: Scalars['ID']
  name: Scalars['String']
}>

export type RenameUserPaymentCardMutation = { __typename: 'Mutation' } & {
  renameUserPaymentCard: Maybe<
    { __typename: 'RenameUserPaymentCardResponse' } & Pick<
      RenameUserPaymentCardResponse,
      'name' | 'id'
    >
  >
}

export type SetDefaultUserPaymentCardMutationVariables = Exact<{
  cardId: Scalars['ID']
}>

export type SetDefaultUserPaymentCardMutation = { __typename: 'Mutation' } & {
  setDefaultUserPaymentCard: Maybe<
    { __typename: 'SetDefaultUserPaymentCardResponse' } & Pick<
      SetDefaultUserPaymentCardResponse,
      'id'
    >
  >
}

export type GetPaymentDetailsQueryVariables = Exact<{ [key: string]: never }>

export type GetPaymentDetailsQuery = { __typename: 'Query' } & {
  paymentDetails: Maybe<
    { __typename: 'PaymentDetailsResponse' } & Pick<
      PaymentDetailsResponse,
      | 'paymentMethod'
      | 'invoiceNumber'
      | 'stockmannCardNumber'
      | 'paymentStatus'
      | 'selectedPaymentCardId'
      | 'savePaymentCard'
    >
  >
}

export type GetAvailablePaymentMethodsQueryVariables = Exact<{
  storeId: Scalars['ID']
}>

export type GetAvailablePaymentMethodsQuery = { __typename: 'Query' } & {
  store: Maybe<{ __typename: 'Store' } & Pick<Store, 'id' | 'availablePaymentMethods'>>
}

export type GetUserPaymentCardsQueryVariables = Exact<{ [key: string]: never }>

export type GetUserPaymentCardsQuery = { __typename: 'Query' } & {
  userPaymentCards: Maybe<
    { __typename: 'PaymentCards' } & Pick<PaymentCards, 'defaultPaymentCardId'> & {
        cards: Array<
          { __typename: 'PaymentCard' } & Pick<
            PaymentCard,
            | 'id'
            | 'maskedCardNumber'
            | 'name'
            | 'expiryDate'
            | 'type'
            | 'userGeneratedName'
            | 'expired'
          >
        >
      }
  >
}

export type PaymentCardNameWithIdFragment = { __typename: 'PaymentCard' } & Pick<
  PaymentCard,
  'id' | 'userGeneratedName'
>

export type CampaignPricesFragment = { __typename: 'Product' } & {
  pricing: Maybe<
    { __typename: 'ProductPricing' } & Pick<
      ProductPricing,
      'campaignPrice' | 'lowest30DayPrice' | 'campaignPriceValidUntil' | 'regularPrice'
    >
  >
}

export type CoreFieldsFragment = { __typename: 'Product' } & Pick<
  Product,
  'id' | 'ean' | 'name' | 'price' | 'storeId'
> & {
    pricing: Maybe<
      { __typename: 'ProductPricing' } & Pick<
        ProductPricing,
        'campaignPrice' | 'lowest30DayPrice' | 'campaignPriceValidUntil' | 'regularPrice'
      >
    >
  }

export type UnitsFragment = { __typename: 'Product' } & Pick<
  Product,
  | 'approxPrice'
  | 'basicQuantityUnit'
  | 'comparisonPrice'
  | 'comparisonUnit'
  | 'consumerPackageSize'
  | 'consumerPackageUnit'
  | 'priceUnit'
  | 'quantityMultiplier'
>

export type CategorizationFragment = { __typename: 'Product' } & Pick<Product, 'brandName'> & {
    hierarchyPath: Maybe<
      Array<{ __typename: 'HierarchyPathItem' } & Pick<HierarchyPathItem, 'name' | 'id' | 'slug'>>
    >
  }

export type DetailsFragment = { __typename: 'Product' } & Pick<
  Product,
  'description' | 'ingredientStatement' | 'supplierName'
> & {
    countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
    nutrients: Maybe<Array<{ __typename: 'Nutrient' } & Pick<Nutrient, 'name' | 'ri' | 'value'>>>
    nutrientsPerServing: Maybe<
      Array<
        { __typename: 'NutrientPerServing' } & Pick<NutrientPerServing, 'name' | 'value' | 'unit'>
      >
    >
  }

export type ProductCategoryFragment = { __typename: 'HierarchyPathItem' } & Pick<
  HierarchyPathItem,
  'id' | 'name' | 'slug'
>

export type ProductNutrientsFragment = { __typename: 'Nutrient' } & Pick<
  Nutrient,
  'name' | 'ri' | 'value'
>

export type ProductNutrientsPerServingFragment = { __typename: 'NutrientPerServing' } & Pick<
  NutrientPerServing,
  'name' | 'value' | 'unit'
>

export type ProductAvailabilityFragment = { __typename: 'Product' } & {
  availabilities?: Maybe<
    Array<
      { __typename: 'ProductAvailabilities' } & Pick<
        ProductAvailabilities,
        'date' | 'label' | 'score'
      >
    >
  >
}

export type PriceSpecificProductFieldsFragment = { __typename: 'Product' } & Pick<
  Product,
  | 'approxPrice'
  | 'comparisonPrice'
  | 'comparisonUnit'
  | 'id'
  | 'price'
  | 'priceUnit'
  | 'storeId'
  | 'depositPrice'
>

export type LocationSpeficProductFieldsFragment = { __typename: 'Product' } & {
  location: Maybe<{ __typename: 'ProductLocation' } & Pick<ProductLocation, 'aisle' | 'floor'>>
}

export type GlobalProductFieldsFragment = { __typename: 'Product' } & Pick<
  Product,
  | 'basicQuantityUnit'
  | 'brandName'
  | 'consumerPackageSize'
  | 'consumerPackageUnit'
  | 'countryOfMainRawMaterial'
  | 'description'
  | 'ean'
  | 'frozen'
  | 'packagingLabelCodes'
  | 'id'
  | 'ingredientStatement'
  | 'isAgeLimitedByAlcohol'
  | 'isGlobalFallback'
  | 'name'
  | 'quantityMultiplier'
  | 'slug'
  | 'storeId'
  | 'supplierName'
> & {
    countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
    hierarchyPath: Maybe<
      Array<{ __typename: 'HierarchyPathItem' } & Pick<HierarchyPathItem, 'id' | 'name' | 'slug'>>
    >
    nutrients: Maybe<Array<{ __typename: 'Nutrient' } & Pick<Nutrient, 'name' | 'ri' | 'value'>>>
    nutrientsPerServing: Maybe<
      Array<
        { __typename: 'NutrientPerServing' } & Pick<NutrientPerServing, 'name' | 'value' | 'unit'>
      >
    >
    pricing: Maybe<
      { __typename: 'ProductPricing' } & Pick<
        ProductPricing,
        'campaignPrice' | 'lowest30DayPrice' | 'campaignPriceValidUntil' | 'regularPrice'
      >
    >
  }

export type LocalizedFieldsFragment = { __typename: 'Product' } & {
  localized: Maybe<
    { __typename: 'LocalizedProductInfo' } & {
      fi: Maybe<
        { __typename: 'LocalizedProductInfoFields' } & Pick<
          LocalizedProductInfoFields,
          | 'name'
          | 'description'
          | 'ingredientStatement'
          | 'userGuideForConsumer'
          | 'storageGuideForConsumer'
        > & {
            allergens: Maybe<
              Array<
                { __typename: 'Allergen' } & Pick<
                  Allergen,
                  'allergenTypeCode' | 'allergenTypeText' | 'levelOfContainmentCode'
                >
              >
            >
          }
      >
      sv: Maybe<
        { __typename: 'LocalizedProductInfoFields' } & Pick<
          LocalizedProductInfoFields,
          | 'name'
          | 'description'
          | 'ingredientStatement'
          | 'userGuideForConsumer'
          | 'storageGuideForConsumer'
        > & {
            allergens: Maybe<
              Array<
                { __typename: 'Allergen' } & Pick<
                  Allergen,
                  'allergenTypeCode' | 'allergenTypeText' | 'levelOfContainmentCode'
                >
              >
            >
          }
      >
    }
  >
}

export type GetProductInfoByEansQueryVariables = Exact<{
  id: Scalars['ID']
  eans: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  fallbackToGlobal: InputMaybe<Scalars['Boolean']>
  includeAgeLimitedByAlcohol: InputMaybe<Scalars['Boolean']>
  availabilityDate: InputMaybe<Scalars['String']>
  includeAvailabilities?: InputMaybe<Scalars['Boolean']>
}>

export type GetProductInfoByEansQuery = { __typename: 'Query' } & {
  store: Maybe<
    { __typename: 'Store' } & Pick<Store, 'id'> & {
        products: Maybe<
          { __typename: 'ProductList' } & {
            items: Maybe<
              Array<
                { __typename: 'Product' } & Pick<
                  Product,
                  | 'isAgeLimitedByAlcohol'
                  | 'slug'
                  | 'frozen'
                  | 'isGlobalFallback'
                  | 'packagingLabels'
                  | 'packagingLabelCodes'
                  | 'productType'
                  | 'inStore'
                  | 'id'
                  | 'ean'
                  | 'name'
                  | 'price'
                  | 'storeId'
                  | 'approxPrice'
                  | 'basicQuantityUnit'
                  | 'comparisonPrice'
                  | 'comparisonUnit'
                  | 'consumerPackageSize'
                  | 'consumerPackageUnit'
                  | 'priceUnit'
                  | 'quantityMultiplier'
                  | 'brandName'
                  | 'description'
                  | 'ingredientStatement'
                  | 'supplierName'
                > & {
                    countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
                    measurement: Maybe<
                      { __typename: 'ProductMeasurement' } & Pick<
                        ProductMeasurement,
                        'pceApproxWeight'
                      >
                    >
                    hierarchyPath: Maybe<
                      Array<
                        { __typename: 'HierarchyPathItem' } & Pick<
                          HierarchyPathItem,
                          'name' | 'id' | 'slug'
                        >
                      >
                    >
                    nutrients: Maybe<
                      Array<{ __typename: 'Nutrient' } & Pick<Nutrient, 'name' | 'ri' | 'value'>>
                    >
                    nutrientsPerServing: Maybe<
                      Array<
                        { __typename: 'NutrientPerServing' } & Pick<
                          NutrientPerServing,
                          'name' | 'value' | 'unit'
                        >
                      >
                    >
                    availabilities?: Maybe<
                      Array<
                        { __typename: 'ProductAvailabilities' } & Pick<
                          ProductAvailabilities,
                          'date' | 'label' | 'score'
                        >
                      >
                    >
                    pricing: Maybe<
                      { __typename: 'ProductPricing' } & Pick<
                        ProductPricing,
                        | 'campaignPrice'
                        | 'lowest30DayPrice'
                        | 'campaignPriceValidUntil'
                        | 'regularPrice'
                      >
                    >
                  }
              >
            >
          }
        >
      }
  >
}

export type RemoteProductAvailabilitiesQueryVariables = Exact<{
  storeId: Scalars['ID']
  productIds: Array<Scalars['String']> | Scalars['String']
  availabilityDate: InputMaybe<Scalars['String']>
  includeAvailabilities?: InputMaybe<Scalars['Boolean']>
}>

export type RemoteProductAvailabilitiesQuery = { __typename: 'Query' } & {
  store: Maybe<
    { __typename: 'Store' } & Pick<Store, 'id'> & {
        products: Maybe<
          { __typename: 'ProductList' } & {
            items: Maybe<
              Array<
                { __typename: 'Product' } & Pick<Product, 'id' | 'storeId'> & {
                    availabilities?: Maybe<
                      Array<
                        { __typename: 'ProductAvailabilities' } & Pick<
                          ProductAvailabilities,
                          'date' | 'label' | 'score'
                        >
                      >
                    >
                  }
              >
            >
          }
        >
      }
  >
}

export type RemoteProductInfoQueryVariables = Exact<{
  ean: Scalars['ID']
  storeId: Scalars['ID']
  includeAgeLimitedByAlcohol?: InputMaybe<Scalars['Boolean']>
  includeGlobalFallback?: InputMaybe<Scalars['Boolean']>
  availabilityDate: InputMaybe<Scalars['String']>
  includeAvailabilities?: InputMaybe<Scalars['Boolean']>
}>

export type RemoteProductInfoQuery = { __typename: 'Query' } & {
  product: Maybe<
    { __typename: 'Product' } & Pick<
      Product,
      | 'packagingLabels'
      | 'packagingLabelCodes'
      | 'basicQuantityUnit'
      | 'brandName'
      | 'consumerPackageSize'
      | 'consumerPackageUnit'
      | 'countryOfMainRawMaterial'
      | 'description'
      | 'ean'
      | 'frozen'
      | 'id'
      | 'ingredientStatement'
      | 'isAgeLimitedByAlcohol'
      | 'isGlobalFallback'
      | 'name'
      | 'quantityMultiplier'
      | 'slug'
      | 'storeId'
      | 'supplierName'
      | 'approxPrice'
      | 'comparisonPrice'
      | 'comparisonUnit'
      | 'price'
      | 'priceUnit'
      | 'depositPrice'
    > & {
        countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
        hierarchyPath: Maybe<
          Array<
            { __typename: 'HierarchyPathItem' } & Pick<HierarchyPathItem, 'id' | 'name' | 'slug'>
          >
        >
        nutrients: Maybe<
          Array<{ __typename: 'Nutrient' } & Pick<Nutrient, 'name' | 'ri' | 'value'>>
        >
        nutrientsPerServing: Maybe<
          Array<
            { __typename: 'NutrientPerServing' } & Pick<
              NutrientPerServing,
              'name' | 'value' | 'unit'
            >
          >
        >
        availabilities?: Maybe<
          Array<
            { __typename: 'ProductAvailabilities' } & Pick<
              ProductAvailabilities,
              'date' | 'label' | 'score'
            >
          >
        >
        localized: Maybe<
          { __typename: 'LocalizedProductInfo' } & {
            fi: Maybe<
              { __typename: 'LocalizedProductInfoFields' } & Pick<
                LocalizedProductInfoFields,
                | 'name'
                | 'description'
                | 'ingredientStatement'
                | 'userGuideForConsumer'
                | 'storageGuideForConsumer'
              > & {
                  allergens: Maybe<
                    Array<
                      { __typename: 'Allergen' } & Pick<
                        Allergen,
                        'allergenTypeCode' | 'allergenTypeText' | 'levelOfContainmentCode'
                      >
                    >
                  >
                }
            >
            sv: Maybe<
              { __typename: 'LocalizedProductInfoFields' } & Pick<
                LocalizedProductInfoFields,
                | 'name'
                | 'description'
                | 'ingredientStatement'
                | 'userGuideForConsumer'
                | 'storageGuideForConsumer'
              > & {
                  allergens: Maybe<
                    Array<
                      { __typename: 'Allergen' } & Pick<
                        Allergen,
                        'allergenTypeCode' | 'allergenTypeText' | 'levelOfContainmentCode'
                      >
                    >
                  >
                }
            >
          }
        >
        location: Maybe<
          { __typename: 'ProductLocation' } & Pick<ProductLocation, 'aisle' | 'floor'>
        >
        pricing: Maybe<
          { __typename: 'ProductPricing' } & Pick<
            ProductPricing,
            'campaignPrice' | 'lowest30DayPrice' | 'campaignPriceValidUntil' | 'regularPrice'
          >
        >
      }
  >
}

export type SendProductUserEventMutationVariables = Exact<{
  sessionId: Scalars['String']
  productIds: Array<Scalars['String']> | Scalars['String']
  eventType: ProductUserEventType
}>

export type SendProductUserEventMutation = { __typename: 'Mutation' } & {
  sendProductUserEvents: Maybe<
    { __typename: 'ProductUserEventResponse' } & Pick<ProductUserEventResponse, 'success'>
  >
}

export type RecipeIngredientFragment = { __typename: 'RecipeIngredient' } & Pick<
  RecipeIngredient,
  'amountType' | 'groupName' | 'eans' | 'ingredientTitle' | 'maxAmount' | 'minAmount' | 'name'
>

export type GetRecipeByIdQueryVariables = Exact<{
  id: Scalars['ID']
  fallbackToGlobal: InputMaybe<Scalars['Boolean']>
  storeId: Scalars['ID']
  availabilityDate: InputMaybe<Scalars['String']>
  includeAvailabilities?: InputMaybe<Scalars['Boolean']>
}>

export type GetRecipeByIdQuery = { __typename: 'Query' } & {
  recipe: Maybe<
    { __typename: 'Recipe' } & Pick<
      Recipe,
      | 'cookTime'
      | 'id'
      | 'imageUrl'
      | 'yield'
      | 'yieldType'
      | 'name'
      | 'publisher'
      | 'slug'
      | 'externalUrl'
    > & {
        categories: Maybe<{ __typename: 'RecipeCategories' } & Pick<RecipeCategories, 'diet'>>
        ingredients: Array<
          { __typename: 'RecipeIngredient' } & Pick<
            RecipeIngredient,
            | 'amountType'
            | 'groupName'
            | 'eans'
            | 'ingredientTitle'
            | 'maxAmount'
            | 'minAmount'
            | 'name'
          > & {
              products: Maybe<
                Array<
                  { __typename: 'Product' } & Pick<
                    Product,
                    | 'basicQuantityUnit'
                    | 'brandName'
                    | 'consumerPackageSize'
                    | 'consumerPackageUnit'
                    | 'countryOfMainRawMaterial'
                    | 'description'
                    | 'ean'
                    | 'frozen'
                    | 'packagingLabelCodes'
                    | 'id'
                    | 'ingredientStatement'
                    | 'isAgeLimitedByAlcohol'
                    | 'isGlobalFallback'
                    | 'name'
                    | 'quantityMultiplier'
                    | 'slug'
                    | 'storeId'
                    | 'supplierName'
                    | 'approxPrice'
                    | 'comparisonPrice'
                    | 'comparisonUnit'
                    | 'price'
                    | 'priceUnit'
                    | 'depositPrice'
                  > & {
                      countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
                      hierarchyPath: Maybe<
                        Array<
                          { __typename: 'HierarchyPathItem' } & Pick<
                            HierarchyPathItem,
                            'id' | 'name' | 'slug'
                          >
                        >
                      >
                      nutrients: Maybe<
                        Array<{ __typename: 'Nutrient' } & Pick<Nutrient, 'name' | 'ri' | 'value'>>
                      >
                      nutrientsPerServing: Maybe<
                        Array<
                          { __typename: 'NutrientPerServing' } & Pick<
                            NutrientPerServing,
                            'name' | 'value' | 'unit'
                          >
                        >
                      >
                      availabilities?: Maybe<
                        Array<
                          { __typename: 'ProductAvailabilities' } & Pick<
                            ProductAvailabilities,
                            'date' | 'label' | 'score'
                          >
                        >
                      >
                      pricing: Maybe<
                        { __typename: 'ProductPricing' } & Pick<
                          ProductPricing,
                          | 'campaignPrice'
                          | 'lowest30DayPrice'
                          | 'campaignPriceValidUntil'
                          | 'regularPrice'
                        >
                      >
                    }
                >
              >
            }
        >
        instructions: Array<{ __typename: 'RecipeInstruction' } & Pick<RecipeInstruction, 'body'>>
      }
  >
}

export type RemoteFilteredProductsQueryVariables = Exact<{
  facets: InputMaybe<Array<StructuredFacetInput> | StructuredFacetInput>
  filters: InputMaybe<Array<Filters> | Filters>
  from: InputMaybe<Scalars['Int']>
  generatedSessionId: InputMaybe<Scalars['String']>
  includeAgeLimitedByAlcohol: InputMaybe<Scalars['Boolean']>
  limit: InputMaybe<Scalars['Int']>
  loop54DirectSearch: InputMaybe<Scalars['Boolean']>
  order: InputMaybe<SortOrder>
  orderBy: InputMaybe<SortKey>
  queryString: Scalars['String']
  searchProvider: InputMaybe<SearchProvider>
  slug: InputMaybe<Scalars['String']>
  storeId: Scalars['ID']
  useRandomId: InputMaybe<Scalars['Boolean']>
  availabilityDate: InputMaybe<Scalars['String']>
  includeAvailabilities?: InputMaybe<Scalars['Boolean']>
  fallbackToGlobal: InputMaybe<Scalars['Boolean']>
}>

export type RemoteFilteredProductsQuery = { __typename: 'Query' } & {
  store: Maybe<
    { __typename: 'Store' } & Pick<Store, 'id'> & {
        products: Maybe<
          { __typename: 'ProductList' } & Pick<
            ProductList,
            'total' | 'from' | 'limit' | 'searchProvider'
          > & {
              items: Maybe<
                Array<
                  { __typename: 'Product' } & Pick<
                    Product,
                    | 'brandName'
                    | 'slug'
                    | 'isAgeLimitedByAlcohol'
                    | 'frozen'
                    | 'packagingLabels'
                    | 'packagingLabelCodes'
                    | 'isGlobalFallback'
                    | 'id'
                    | 'ean'
                    | 'name'
                    | 'price'
                    | 'storeId'
                    | 'approxPrice'
                    | 'basicQuantityUnit'
                    | 'comparisonPrice'
                    | 'comparisonUnit'
                    | 'consumerPackageSize'
                    | 'consumerPackageUnit'
                    | 'priceUnit'
                    | 'quantityMultiplier'
                  > & {
                      hierarchyPath: Maybe<
                        Array<
                          { __typename: 'HierarchyPathItem' } & Pick<
                            HierarchyPathItem,
                            'id' | 'name' | 'slug'
                          >
                        >
                      >
                      countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
                      availabilities?: Maybe<
                        Array<
                          { __typename: 'ProductAvailabilities' } & Pick<
                            ProductAvailabilities,
                            'date' | 'label' | 'score'
                          >
                        >
                      >
                      pricing: Maybe<
                        { __typename: 'ProductPricing' } & Pick<
                          ProductPricing,
                          | 'campaignPrice'
                          | 'lowest30DayPrice'
                          | 'campaignPriceValidUntil'
                          | 'regularPrice'
                        >
                      >
                    }
                >
              >
              structuredFacets: Maybe<
                Array<
                  | ({ __typename: 'ObjectFacet' } & Pick<ObjectFacet, 'key'> & {
                        objectValue: Array<
                          { __typename: 'ObjectFacetValue' } & Pick<
                            ObjectFacetValue,
                            'name' | 'value' | 'doc_count'
                          >
                        >
                      })
                  | ({ __typename: 'StringFacet' } & Pick<StringFacet, 'key' | 'stringValue'>)
                >
              >
            }
        >
      }
  >
}

export type RemoteAvailableStoreProductsQueryVariables = Exact<{
  storeId: Scalars['ID']
  from: Scalars['Int']
  limit: Scalars['Int']
}>

export type RemoteAvailableStoreProductsQuery = { __typename: 'Query' } & {
  store: Maybe<
    { __typename: 'Store' } & Pick<Store, 'id'> & {
        products: Maybe<
          { __typename: 'ProductList' } & {
            items: Maybe<
              Array<
                { __typename: 'Product' } & Pick<
                  Product,
                  'id' | 'storeId' | 'ean' | 'modified' | 'slug'
                >
              >
            >
          }
        >
      }
  >
}

export type RemoteStoreSearchAllQueryVariables = Exact<{ [key: string]: never }>

export type RemoteStoreSearchAllQuery = { __typename: 'Query' } & {
  searchStores: Maybe<
    { __typename: 'SearchStoresResponse' } & {
      stores: Array<{ __typename: 'StoreInfo' } & Pick<StoreInfo, 'slug' | 'id'>>
    }
  >
}

export type SetStoreSelectionTemplateMutationVariables = Exact<{
  additionalInfo: InputMaybe<Scalars['String']>
  address: InputMaybe<Scalars['String']>
  areaId: InputMaybe<Scalars['ID']>
  brand: InputMaybe<Scalars['String']>
  city: InputMaybe<Scalars['String']>
  deliveryDate: InputMaybe<Scalars['String']>
  deliveryMethod: InputMaybe<DeliveryMethod>
  deliverySlotId: InputMaybe<Scalars['ID']>
  deliveryTime: InputMaybe<Scalars['String']>
  id: InputMaybe<Scalars['ID']>
  name: InputMaybe<Scalars['String']>
  postalCode: InputMaybe<Scalars['String']>
  deliverySlotPrice: InputMaybe<Scalars['Float']>
}>

export type SetStoreSelectionTemplateMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'updateSelectedStoreId' | 'updateSelectedAreaId' | 'updateSelectedBrand' | 'setDeliveryStore'
> & {
    updateDeliveryDetails: Maybe<
      { __typename: 'UpdateDeliveryDetailsResponse' } & Pick<
        UpdateDeliveryDetailsResponse,
        | 'deliveryDate'
        | 'deliveryTime'
        | 'deliverySlotId'
        | 'additionalInfo'
        | 'address'
        | 'postalCode'
        | 'city'
      >
    >
  }

export type UpdateSelectedStoreIdMutationVariables = Exact<{
  selectedStoreId: InputMaybe<Scalars['ID']>
}>

export type UpdateSelectedStoreIdMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'updateSelectedStoreId'
>

export type UpdateSelectedAreaIdMutationVariables = Exact<{
  selectedAreaId: InputMaybe<Scalars['ID']>
}>

export type UpdateSelectedAreaIdMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'updateSelectedAreaId'
>

export type UpdateSelectedBrandMutationVariables = Exact<{
  selectedBrand: InputMaybe<Scalars['String']>
}>

export type UpdateSelectedBrandMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'updateSelectedBrand'
>

export type SetDeliveryStoreMutationVariables = Exact<{
  id: Scalars['ID']
  name: InputMaybe<Scalars['String']>
  brand: InputMaybe<Scalars['String']>
  areaId: InputMaybe<Scalars['ID']>
}>

export type SetDeliveryStoreMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'setDeliveryStore'
>

export type ResetDeliveryStoreMutationVariables = Exact<{ [key: string]: never }>

export type ResetDeliveryStoreMutation = { __typename: 'Mutation' } & Pick<
  Mutation,
  'resetDeliveryStore'
>

export type StoreIdAndNameFragment = { __typename: 'Store' } & Pick<Store, 'id' | 'name'>

export type StoreDayOpeningTimeFragment = { __typename: 'StoreDayOpeningTime' } & Pick<
  StoreDayOpeningTime,
  'date' | 'day' | 'exceptional' | 'mode'
> & {
    message: Maybe<{ __typename: 'LocalizedText' } & Pick<LocalizedText, 'fi'>>
    ranges: Maybe<
      Array<
        { __typename: 'StoreOpeningTimeRange' } & Pick<
          StoreOpeningTimeRange,
          'open' | 'close' | 'closeOnSameDay'
        >
      >
    >
  }

export type StoreOpeningTimesFragment = { __typename: 'Store' } & {
  weeklyOpeningHours: Maybe<
    Array<
      { __typename: 'StoreWeeklyOpeningTime' } & Pick<StoreWeeklyOpeningTime, 'weekNumber'> & {
          openingTimes: Array<
            { __typename: 'StoreDayOpeningTime' } & Pick<
              StoreDayOpeningTime,
              'date' | 'day' | 'exceptional' | 'mode'
            > & {
                message: Maybe<{ __typename: 'LocalizedText' } & Pick<LocalizedText, 'fi'>>
                ranges: Maybe<
                  Array<
                    { __typename: 'StoreOpeningTimeRange' } & Pick<
                      StoreOpeningTimeRange,
                      'open' | 'close' | 'closeOnSameDay'
                    >
                  >
                >
              }
          >
        }
    >
  >
}

export type StoreContactDetailsFragment = { __typename: 'Store' } & {
  contactInfo: Maybe<
    { __typename: 'StoreContactInfo' } & Pick<StoreContactInfo, 'email'> & {
        phoneNumber: Maybe<
          { __typename: 'StorePhoneNumber' } & Pick<
            StorePhoneNumber,
            'callChargeGroup' | 'number'
          > & {
              callCharge: Maybe<
                { __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>
              >
            }
        >
      }
  >
  location: Maybe<
    { __typename: 'StoreLocation' } & {
      address: Maybe<
        { __typename: 'StoreAddress' } & Pick<StoreAddress, 'postcode'> & {
            street: { __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>
            postcodeName: Maybe<
              { __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>
            >
          }
      >
      coordinates: Maybe<{ __typename: 'Coordinates' } & Pick<Coordinates, 'lat' | 'lon'>>
    }
  >
  feedback: Maybe<
    { __typename: 'StoreFeedback' } & {
      url: Maybe<{ __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>>
    }
  >
}

export type StoreServicesFragment = { __typename: 'Store' } & {
  services: Maybe<
    Array<
      { __typename: 'StoreService' } & Pick<StoreService, 'code'> & {
          name: Maybe<{ __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>>
        }
    >
  >
}

export type StoreLowestDeliveryPriceFragment = { __typename: 'StoreLowestDeliveryPrice' } & Pick<
  StoreLowestDeliveryPrice,
  'isFastTrack' | 'price' | 'description' | 'deliveryMethod' | 'areaId' | 'name'
>

export type StoreLowestDeliveryPricesFragment = { __typename: 'Store' } & {
  lowestDeliveryPrices: Maybe<
    Array<
      Maybe<
        { __typename: 'StoreLowestDeliveryPrice' } & Pick<
          StoreLowestDeliveryPrice,
          'isFastTrack' | 'price' | 'description' | 'deliveryMethod' | 'areaId' | 'name'
        >
      >
    >
  >
}

export type GetSelectedStoreIdQueryVariables = Exact<{ [key: string]: never }>

export type GetSelectedStoreIdQuery = { __typename: 'Query' } & Pick<Query, 'selectedStoreId'>

export type GetSelectedAreaIdQueryVariables = Exact<{ [key: string]: never }>

export type GetSelectedAreaIdQuery = { __typename: 'Query' } & Pick<Query, 'selectedAreaId'>

export type GetSelectedBrandQueryVariables = Exact<{ [key: string]: never }>

export type GetSelectedBrandQuery = { __typename: 'Query' } & Pick<Query, 'selectedBrand'>

export type GetDeliveryStoreQueryVariables = Exact<{ [key: string]: never }>

export type GetDeliveryStoreQuery = { __typename: 'Query' } & {
  deliveryStore: Maybe<
    { __typename: 'DeliveryStore' } & Pick<DeliveryStore, 'id' | 'name' | 'brand' | 'areaId'>
  >
}

export type GetBrandByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetBrandByIdQuery = { __typename: 'Query' } & {
  store: Maybe<{ __typename: 'Store' } & Pick<Store, 'brand' | 'id'>>
}

export type GetDeliverySlotAndStoreDataQueryVariables = Exact<{
  storeId: Scalars['ID']
  deliverySlotId: Scalars['ID']
}>

export type GetDeliverySlotAndStoreDataQuery = { __typename: 'Query' } & {
  deliverySlot: Maybe<{ __typename: 'DeliverySlot' } & Pick<DeliverySlot, 'areaId' | 'slotId'>>
  store: Maybe<{ __typename: 'Store' } & Pick<Store, 'brand' | 'name' | 'id'>>
}

export type StoreDeliveryMethodsQueryVariables = Exact<{
  storeId: InputMaybe<Scalars['ID']>
}>

export type StoreDeliveryMethodsQuery = { __typename: 'Query' } & {
  searchDeliveryAreas: Maybe<
    { __typename: 'DeliveryAreaSearchResponse' } & {
      areas: Maybe<
        Array<
          { __typename: 'DeliveryArea' } & Pick<
            DeliveryArea,
            'areaId' | 'deliveryMethod' | 'isFastTrack' | 'isRemote' | 'price' | 'description'
          >
        >
      >
    }
  >
}

export type RemoteStoreNameQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoteStoreNameQuery = { __typename: 'Query' } & {
  store: Maybe<{ __typename: 'Store' } & Pick<Store, 'id' | 'name'>>
}

export type RemoteStoreInfoQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoteStoreInfoQuery = { __typename: 'Query' } & {
  store: Maybe<
    { __typename: 'Store' } & Pick<
      Store,
      'slug' | 'brand' | 'domains' | 'coOperative' | 'id' | 'name'
    > & {
        contactInfo: Maybe<
          { __typename: 'StoreContactInfo' } & Pick<StoreContactInfo, 'email'> & {
              phoneNumber: Maybe<
                { __typename: 'StorePhoneNumber' } & Pick<
                  StorePhoneNumber,
                  'callChargeGroup' | 'number'
                > & {
                    callCharge: Maybe<
                      { __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>
                    >
                  }
              >
            }
        >
        location: Maybe<
          { __typename: 'StoreLocation' } & {
            address: Maybe<
              { __typename: 'StoreAddress' } & Pick<StoreAddress, 'postcode'> & {
                  street: { __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>
                  postcodeName: Maybe<
                    { __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>
                  >
                }
            >
            coordinates: Maybe<{ __typename: 'Coordinates' } & Pick<Coordinates, 'lat' | 'lon'>>
          }
        >
        feedback: Maybe<
          { __typename: 'StoreFeedback' } & {
            url: Maybe<{ __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>>
          }
        >
        services: Maybe<
          Array<
            { __typename: 'StoreService' } & Pick<StoreService, 'code'> & {
                name: Maybe<{ __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>>
              }
          >
        >
        weeklyOpeningHours: Maybe<
          Array<
            { __typename: 'StoreWeeklyOpeningTime' } & Pick<
              StoreWeeklyOpeningTime,
              'weekNumber'
            > & {
                openingTimes: Array<
                  { __typename: 'StoreDayOpeningTime' } & Pick<
                    StoreDayOpeningTime,
                    'date' | 'day' | 'exceptional' | 'mode'
                  > & {
                      message: Maybe<{ __typename: 'LocalizedText' } & Pick<LocalizedText, 'fi'>>
                      ranges: Maybe<
                        Array<
                          { __typename: 'StoreOpeningTimeRange' } & Pick<
                            StoreOpeningTimeRange,
                            'open' | 'close' | 'closeOnSameDay'
                          >
                        >
                      >
                    }
                >
              }
          >
        >
        lowestDeliveryPrices: Maybe<
          Array<
            Maybe<
              { __typename: 'StoreLowestDeliveryPrice' } & Pick<
                StoreLowestDeliveryPrice,
                'isFastTrack' | 'price' | 'description' | 'deliveryMethod' | 'areaId' | 'name'
              >
            >
          >
        >
      }
  >
}

export type RemoteStoreSearchQueryVariables = Exact<{
  brand: InputMaybe<StoreBrand>
  cursor: InputMaybe<Scalars['String']>
  query: InputMaybe<Scalars['String']>
}>

export type RemoteStoreSearchQuery = { __typename: 'Query' } & {
  searchStores: Maybe<
    { __typename: 'SearchStoresResponse' } & Pick<SearchStoresResponse, 'totalCount' | 'cursor'> & {
        stores: Array<
          { __typename: 'StoreInfo' } & Pick<
            StoreInfo,
            'id' | 'slug' | 'name' | 'brand' | 'domains'
          > & {
              location: Maybe<
                { __typename: 'StoreLocation' } & {
                  address: Maybe<
                    { __typename: 'StoreAddress' } & Pick<StoreAddress, 'postcode'> & {
                        street: { __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>
                        postcodeName: Maybe<
                          { __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>
                        >
                      }
                  >
                }
              >
              weeklyOpeningHours: Maybe<
                Array<
                  { __typename: 'StoreWeeklyOpeningTime' } & Pick<
                    StoreWeeklyOpeningTime,
                    'weekNumber'
                  > & {
                      openingTimes: Array<
                        { __typename: 'StoreDayOpeningTime' } & Pick<
                          StoreDayOpeningTime,
                          'date' | 'mode' | 'exceptional'
                        > & {
                            message: Maybe<
                              { __typename: 'LocalizedText' } & Pick<LocalizedText, 'fi'>
                            >
                            ranges: Maybe<
                              Array<
                                { __typename: 'StoreOpeningTimeRange' } & Pick<
                                  StoreOpeningTimeRange,
                                  'open' | 'close' | 'closeOnSameDay'
                                >
                              >
                            >
                          }
                      >
                    }
                >
              >
            }
        >
      }
  >
}

export type StoreInfoFragment = { __typename: 'StoreInfo' } & Pick<
  StoreInfo,
  'id' | 'slug' | 'name' | 'brand' | 'domains'
> & {
    location: Maybe<
      { __typename: 'StoreLocation' } & {
        address: Maybe<
          { __typename: 'StoreAddress' } & Pick<StoreAddress, 'postcode'> & {
              street: { __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>
              postcodeName: Maybe<
                { __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>
              >
            }
        >
      }
    >
    weeklyOpeningHours: Maybe<
      Array<
        { __typename: 'StoreWeeklyOpeningTime' } & Pick<StoreWeeklyOpeningTime, 'weekNumber'> & {
            openingTimes: Array<
              { __typename: 'StoreDayOpeningTime' } & Pick<
                StoreDayOpeningTime,
                'date' | 'mode' | 'exceptional'
              > & {
                  message: Maybe<{ __typename: 'LocalizedText' } & Pick<LocalizedText, 'fi'>>
                  ranges: Maybe<
                    Array<
                      { __typename: 'StoreOpeningTimeRange' } & Pick<
                        StoreOpeningTimeRange,
                        'open' | 'close' | 'closeOnSameDay'
                      >
                    >
                  >
                }
            >
          }
      >
    >
  }

export type StoreAddressFragment = { __typename: 'StoreAddress' } & Pick<
  StoreAddress,
  'postcode'
> & {
    street: { __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>
    postcodeName: Maybe<{ __typename: 'LocalizableText' } & Pick<LocalizableText, 'default'>>
  }

export type RemoteServiceSubscriptionsQueryVariables = Exact<{
  serviceTypes: Array<ServiceSubscriptionServiceType> | ServiceSubscriptionServiceType
}>

export type RemoteServiceSubscriptionsQuery = { __typename: 'Query' } & {
  serviceSubscriptions: Array<
    { __typename: 'ServiceSubscription' } & Pick<
      ServiceSubscription,
      'serviceType' | 'serviceName' | 'subscribed' | 'subscriptionStartDate' | 'changeAllowed'
    >
  >
}

export type GetDeliveryAreaAlcoholSellingStatusQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetDeliveryAreaAlcoholSellingStatusQuery = { __typename: 'Query' } & {
  deliveryArea: Maybe<
    { __typename: 'DeliveryArea' } & Pick<
      DeliveryArea,
      'areaId' | 'storeId' | 'alcoholSellingAllowed'
    >
  >
}

export type GetMandatoryProductsQueryVariables = Exact<{
  id: Scalars['ID']
  deliveryMethod: DeliveryMethod
  deliverySlotId: Scalars['String']
  orderId: InputMaybe<Scalars['String']>
  reservationId: InputMaybe<Scalars['String']>
}>

export type GetMandatoryProductsQuery = { __typename: 'Query' } & {
  store: Maybe<
    { __typename: 'Store' } & Pick<Store, 'id'> & {
        mandatoryProducts: Maybe<
          Array<
            { __typename: 'MandatoryProduct' } & Pick<
              MandatoryProduct,
              'ean' | 'id' | 'name' | 'price' | 'productType'
            >
          >
        >
      }
  >
}

export type GetDiscountCodeProductQueryVariables = Exact<{
  storeId: Scalars['ID']
  discountCode: InputMaybe<Scalars['String']>
}>

export type GetDiscountCodeProductQuery = { __typename: 'Query' } & {
  store: Maybe<
    { __typename: 'Store' } & Pick<Store, 'id'> & {
        discountProduct: Maybe<
          { __typename: 'DiscountProduct' } & Pick<
            DiscountProduct,
            'discountType' | 'id' | 'isValid' | 'ean' | 'name' | 'discountAmount'
          >
        >
      }
  >
}

export type OrderTrackingGetOrderStatusByIdQueryVariables = Exact<{
  orderId: Scalars['ID']
  linkVerificationToken: InputMaybe<Scalars['String']>
}>

export type OrderTrackingGetOrderStatusByIdQuery = { __typename: 'Query' } & {
  order: Maybe<{ __typename: 'Order' } & Pick<Order, 'id' | 'orderStatus' | 'orderNumber'>>
}

export type OrderTrackingGetOrderIdQueryVariables = Exact<{ [key: string]: never }>

export type OrderTrackingGetOrderIdQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & Pick<DomainOrder, 'id'>
}

export type GetTrackingSelectedStoreDataQueryVariables = Exact<{ [key: string]: never }>

export type GetTrackingSelectedStoreDataQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & Pick<DomainOrder, 'storeId' | 'deliverySlotId'>
}

export type RemoteGetTrackingSelectedStoreDataQueryVariables = Exact<{
  storeId: Scalars['ID']
}>

export type RemoteGetTrackingSelectedStoreDataQuery = { __typename: 'Query' } & {
  store: Maybe<{ __typename: 'Store' } & Pick<Store, 'name' | 'id'>>
}

export type RemoteGetTrackingSelectedDeliverySlotDataQueryVariables = Exact<{
  deliverySlotId: Scalars['ID']
}>

export type RemoteGetTrackingSelectedDeliverySlotDataQuery = { __typename: 'Query' } & {
  deliverySlot: Maybe<
    { __typename: 'DeliverySlot' } & Pick<
      DeliverySlot,
      'slotId' | 'deliveryMethod' | 'startDateTime' | 'endDateTime'
    >
  >
}

export type RemoteStoreCoopInfoQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoteStoreCoopInfoQuery = { __typename: 'Query' } & {
  store: Maybe<{ __typename: 'Store' } & Pick<Store, 'id' | 'coOperative'>>
}

export type AddressValidateQueryVariables = Exact<{
  countryCode: Scalars['String']
  lang: InputMaybe<Scalars['String']>
  query: Scalars['String']
}>

export type AddressValidateQuery = { __typename: 'Query' } & {
  addressValidate: { __typename: 'AddressValidateResult' } & Pick<
    AddressValidateResult,
    'isValid' | 'errors'
  >
}

export type RemoteStoreAvailabilityByEanQueryVariables = Exact<{
  ean: Scalars['ID']
}>

export type RemoteStoreAvailabilityByEanQuery = { __typename: 'Query' } & {
  product: Maybe<
    { __typename: 'Product' } & Pick<Product, 'id' | 'storeId'> & {
        storeAvailability: Maybe<Array<{ __typename: 'Product' } & Pick<Product, 'id' | 'storeId'>>>
      }
  >
}

export type LocalDomainOrderDeliveryMethodQueryVariables = Exact<{ [key: string]: never }>

export type LocalDomainOrderDeliveryMethodQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & Pick<
    DomainOrder,
    'additionalInfo' | 'storeId' | 'deliveryAreaId'
  > & {
      deliveryAddress: { __typename: 'DomainDelivery' } & Pick<
        DomainDelivery,
        'address' | 'city' | 'postalCode'
      >
    }
}

export type RemoteDeliveryAreaQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoteDeliveryAreaQuery = { __typename: 'Query' } & {
  deliveryArea: Maybe<
    { __typename: 'DeliveryArea' } & Pick<
      DeliveryArea,
      'name' | 'areaId' | 'price' | 'description' | 'deliveryMethod' | 'isFastTrack'
    > & {
        address: Maybe<
          { __typename: 'DeliveryAreaAddress' } & Pick<
            DeliveryAreaAddress,
            'city' | 'postalCode' | 'street'
          >
        >
        store: Maybe<{ __typename: 'StoreInfo' } & Pick<StoreInfo, 'name' | 'id' | 'brand'>>
      }
  >
}

export type LocalOverviewCardQueryVariables = Exact<{ [key: string]: never }>

export type LocalOverviewCardQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & Pick<DomainOrder, 'comment' | 'storeId'> & {
      cartItems: Array<
        { __typename: 'ClientCartItem' } & Pick<
          ClientCartItem,
          'id' | 'itemCount' | 'price' | 'productType' | 'inStoreSelection'
        >
      >
    }
}

export type LocalDomainOrderCartItemsQueryVariables = Exact<{ [key: string]: never }>

export type LocalDomainOrderCartItemsQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & {
    cartItems: Array<
      { __typename: 'ClientCartItem' } & Pick<
        ClientCartItem,
        | 'id'
        | 'ean'
        | 'name'
        | 'itemCount'
        | 'priceUnit'
        | 'price'
        | 'replace'
        | 'additionalInfo'
        | 'approxPrice'
        | 'basicQuantityUnit'
        | 'inStoreSelection'
        | 'quantityMultiplier'
        | 'mainCategoryName'
        | 'productType'
        | 'isAgeLimitedByAlcohol'
      >
    >
  }
}

export type PackagingMaterialsQueryVariables = Exact<{
  deliveryAreaId: Scalars['ID']
}>

export type PackagingMaterialsQuery = { __typename: 'Query' } & {
  cartItems: Array<{ __typename: 'ClientCartItem' } & Pick<ClientCartItem, 'ean' | 'productType'>>
  deliveryArea: Maybe<
    { __typename: 'DeliveryArea' } & Pick<DeliveryArea, 'areaId'> & {
        availablePackagingMaterials: Maybe<
          Array<
            { __typename: 'AvailablePackagingMaterial' } & Pick<
              AvailablePackagingMaterial,
              'ean' | 'materialType' | 'materialPrice'
            >
          >
        >
      }
  >
}

export type LocalDomainOrderPaymentQueryVariables = Exact<{ [key: string]: never }>

export type LocalDomainOrderPaymentQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & Pick<DomainOrder, 'storeId'> & {
      payment: { __typename: 'DomainPayment' } & Pick<
        DomainPayment,
        'paymentMethod' | 'invoiceNumber' | 'paymentStatus'
      >
    }
}

export type LocalDomainOrderPaymentModalQueryVariables = Exact<{ [key: string]: never }>

export type LocalDomainOrderPaymentModalQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & Pick<DomainOrder, 'storeId'> & {
      customer: { __typename: 'DomainCustomer' } & Pick<
        DomainCustomer,
        'type' | 'stockmannCardNumber'
      >
      payment: { __typename: 'DomainPayment' } & Pick<
        DomainPayment,
        'paymentMethod' | 'invoiceNumber'
      >
    }
}

export type RemoteStorePaymentMethodsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoteStorePaymentMethodsQuery = { __typename: 'Query' } & {
  store: Maybe<{ __typename: 'Store' } & Pick<Store, 'id' | 'availablePaymentMethods'>>
}

export type ServiceFeesQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ServiceFeesQuery = { __typename: 'Query' } & {
  cartItems: Array<{ __typename: 'ClientCartItem' } & Pick<ClientCartItem, 'id' | 'productType'>>
  store: Maybe<
    { __typename: 'Store' } & Pick<Store, 'id'> & {
        serviceProducts: Maybe<
          Array<
            { __typename: 'ServiceProduct' } & Pick<ServiceProduct, 'ean' | 'id' | 'name' | 'price'>
          >
        >
      }
  >
}

export type RemoteGetOrderSummaryQueryVariables = Exact<{
  storeId: Scalars['ID']
  deliverySlotId: Scalars['String']
  orderId: InputMaybe<Scalars['String']>
  packagingMaterialEan: InputMaybe<Scalars['String']>
  reservationId: InputMaybe<Scalars['String']>
}>

export type RemoteGetOrderSummaryQuery = { __typename: 'Query' } & {
  store: Maybe<
    { __typename: 'Store' } & Pick<Store, 'id'> & {
        mandatoryProducts: Maybe<
          Array<
            { __typename: 'MandatoryProduct' } & Pick<
              MandatoryProduct,
              'price' | 'name' | 'ean' | 'id' | 'productType'
            >
          >
        >
      }
  >
}

export type RemoteGetDiscountCodeForOrderQueryVariables = Exact<{
  orderId: Scalars['ID']
  linkVerificationToken: InputMaybe<Scalars['String']>
}>

export type RemoteGetDiscountCodeForOrderQuery = { __typename: 'Query' } & {
  order: Maybe<{ __typename: 'Order' } & Pick<Order, 'id' | 'discountCode'>>
}

export type GetLocalDomainOrderStateQueryVariables = Exact<{ [key: string]: never }>

export type GetLocalDomainOrderStateQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & Pick<DomainOrder, 'id' | 'orderStatus'>
}

export type GetLocalDomainOrderStoreQueryVariables = Exact<{ [key: string]: never }>

export type GetLocalDomainOrderStoreQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & Pick<DomainOrder, 'storeId'>
}

export type LocalSelectedDeliverySlotIdQueryVariables = Exact<{ [key: string]: never }>

export type LocalSelectedDeliverySlotIdQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & Pick<DomainOrder, 'deliverySlotId'>
}

export type RemoteDeliverySlotQueryVariables = Exact<{
  slotId: Scalars['ID']
}>

export type RemoteDeliverySlotQuery = { __typename: 'Query' } & {
  deliverySlot: Maybe<
    { __typename: 'DeliverySlot' } & Pick<
      DeliverySlot,
      | 'slotId'
      | 'areaId'
      | 'isClosed'
      | 'availability'
      | 'startDateTime'
      | 'endDateTime'
      | 'price'
      | 'time'
      | 'deliveryMethod'
      | 'alcoholSellingAllowed'
      | 'isModifiable'
      | 'modificationTimestamp'
      | 'isFastTrack'
      | 'estimatedFastTrackTime'
    >
  >
}

export type LocalDomainOrderQueryVariables = Exact<{ [key: string]: never }>

export type LocalDomainOrderQuery = { __typename: 'Query' } & {
  domainOrder: { __typename: 'DomainOrder' } & Pick<
    DomainOrder,
    | 'id'
    | 'discountCode'
    | 'orderStatus'
    | 'additionalInfo'
    | 'storeId'
    | 'comment'
    | 'reservationId'
    | 'deliverySlotId'
    | 'deliveryAreaId'
  > & {
      customer: { __typename: 'DomainCustomer' } & Pick<
        DomainCustomer,
        | 'type'
        | 'companyName'
        | 'email'
        | 'firstName'
        | 'lastName'
        | 'phone'
        | 'cardType'
        | 'stockmannCardNumber'
      >
      deliveryAddress: { __typename: 'DomainDelivery' } & Pick<
        DomainDelivery,
        'address' | 'city' | 'postalCode'
      >
      payment: { __typename: 'DomainPayment' } & Pick<
        DomainPayment,
        'paymentMethod' | 'invoiceNumber' | 'paymentStatus'
      >
      cartItems: Array<
        { __typename: 'ClientCartItem' } & Pick<
          ClientCartItem,
          | 'additionalInfo'
          | 'approxPrice'
          | 'basicQuantityUnit'
          | 'comparisonPrice'
          | 'comparisonUnit'
          | 'ean'
          | 'frozen'
          | 'id'
          | 'inStoreSelection'
          | 'isAgeLimitedByAlcohol'
          | 'itemCount'
          | 'mainCategoryName'
          | 'name'
          | 'packagingLabelCodes'
          | 'price'
          | 'priceUnit'
          | 'productType'
          | 'quantityMultiplier'
          | 'replace'
          | 'campaignPrice'
          | 'lowest30DayPrice'
          | 'campaignPriceValidUntil'
          | 'regularPrice'
        > & { countryName: { __typename: 'CountryName' } & Pick<CountryName, 'fi'> }
      >
    }
}

export type RemoteOrderDeliveryAreaDetailsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoteOrderDeliveryAreaDetailsQuery = { __typename: 'Query' } & {
  deliveryArea: Maybe<
    { __typename: 'DeliveryArea' } & Pick<DeliveryArea, 'areaId' | 'name' | 'description'> & {
        address: Maybe<{ __typename: 'DeliveryAreaAddress' } & Pick<DeliveryAreaAddress, 'street'>>
        store: Maybe<{ __typename: 'StoreInfo' } & Pick<StoreInfo, 'name' | 'street'>>
      }
  >
}

export type GetProductRecommendationsQueryVariables = Exact<{
  storeId: Scalars['String']
  includeRecentPurchases?: InputMaybe<Scalars['Boolean']>
  includePurchaseHistory?: InputMaybe<Scalars['Boolean']>
  includeOther?: InputMaybe<Scalars['Boolean']>
}>

export type GetProductRecommendationsQuery = { __typename: 'Query' } & {
  recentPurchases: Maybe<
    { __typename: 'CartRecommendation' } & Pick<CartRecommendation, 'runId'> & {
        products: Maybe<
          { __typename: 'ProductList' } & {
            items: Maybe<
              Array<
                { __typename: 'Product' } & Pick<
                  Product,
                  | 'packagingLabelCodes'
                  | 'packagingLabels'
                  | 'approxPrice'
                  | 'comparisonPrice'
                  | 'comparisonUnit'
                  | 'id'
                  | 'price'
                  | 'priceUnit'
                  | 'storeId'
                  | 'depositPrice'
                  | 'basicQuantityUnit'
                  | 'brandName'
                  | 'consumerPackageSize'
                  | 'consumerPackageUnit'
                  | 'countryOfMainRawMaterial'
                  | 'description'
                  | 'ean'
                  | 'frozen'
                  | 'ingredientStatement'
                  | 'isAgeLimitedByAlcohol'
                  | 'isGlobalFallback'
                  | 'name'
                  | 'quantityMultiplier'
                  | 'slug'
                  | 'supplierName'
                > & {
                    countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
                    hierarchyPath: Maybe<
                      Array<
                        { __typename: 'HierarchyPathItem' } & Pick<
                          HierarchyPathItem,
                          'id' | 'name' | 'slug'
                        >
                      >
                    >
                    nutrients: Maybe<
                      Array<{ __typename: 'Nutrient' } & Pick<Nutrient, 'name' | 'ri' | 'value'>>
                    >
                    nutrientsPerServing: Maybe<
                      Array<
                        { __typename: 'NutrientPerServing' } & Pick<
                          NutrientPerServing,
                          'name' | 'value' | 'unit'
                        >
                      >
                    >
                    pricing: Maybe<
                      { __typename: 'ProductPricing' } & Pick<
                        ProductPricing,
                        | 'campaignPrice'
                        | 'lowest30DayPrice'
                        | 'campaignPriceValidUntil'
                        | 'regularPrice'
                      >
                    >
                  }
              >
            >
          }
        >
      }
  >
  purchaseHistory: Maybe<
    { __typename: 'CartRecommendation' } & Pick<CartRecommendation, 'runId'> & {
        products: Maybe<
          { __typename: 'ProductList' } & {
            items: Maybe<
              Array<
                { __typename: 'Product' } & Pick<
                  Product,
                  | 'packagingLabelCodes'
                  | 'packagingLabels'
                  | 'approxPrice'
                  | 'comparisonPrice'
                  | 'comparisonUnit'
                  | 'id'
                  | 'price'
                  | 'priceUnit'
                  | 'storeId'
                  | 'depositPrice'
                  | 'basicQuantityUnit'
                  | 'brandName'
                  | 'consumerPackageSize'
                  | 'consumerPackageUnit'
                  | 'countryOfMainRawMaterial'
                  | 'description'
                  | 'ean'
                  | 'frozen'
                  | 'ingredientStatement'
                  | 'isAgeLimitedByAlcohol'
                  | 'isGlobalFallback'
                  | 'name'
                  | 'quantityMultiplier'
                  | 'slug'
                  | 'supplierName'
                > & {
                    countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
                    hierarchyPath: Maybe<
                      Array<
                        { __typename: 'HierarchyPathItem' } & Pick<
                          HierarchyPathItem,
                          'id' | 'name' | 'slug'
                        >
                      >
                    >
                    nutrients: Maybe<
                      Array<{ __typename: 'Nutrient' } & Pick<Nutrient, 'name' | 'ri' | 'value'>>
                    >
                    nutrientsPerServing: Maybe<
                      Array<
                        { __typename: 'NutrientPerServing' } & Pick<
                          NutrientPerServing,
                          'name' | 'value' | 'unit'
                        >
                      >
                    >
                    pricing: Maybe<
                      { __typename: 'ProductPricing' } & Pick<
                        ProductPricing,
                        | 'campaignPrice'
                        | 'lowest30DayPrice'
                        | 'campaignPriceValidUntil'
                        | 'regularPrice'
                      >
                    >
                  }
              >
            >
          }
        >
      }
  >
  other: Maybe<
    { __typename: 'CartRecommendation' } & Pick<CartRecommendation, 'runId'> & {
        products: Maybe<
          { __typename: 'ProductList' } & {
            items: Maybe<
              Array<
                { __typename: 'Product' } & Pick<
                  Product,
                  | 'packagingLabelCodes'
                  | 'packagingLabels'
                  | 'approxPrice'
                  | 'comparisonPrice'
                  | 'comparisonUnit'
                  | 'id'
                  | 'price'
                  | 'priceUnit'
                  | 'storeId'
                  | 'depositPrice'
                  | 'basicQuantityUnit'
                  | 'brandName'
                  | 'consumerPackageSize'
                  | 'consumerPackageUnit'
                  | 'countryOfMainRawMaterial'
                  | 'description'
                  | 'ean'
                  | 'frozen'
                  | 'ingredientStatement'
                  | 'isAgeLimitedByAlcohol'
                  | 'isGlobalFallback'
                  | 'name'
                  | 'quantityMultiplier'
                  | 'slug'
                  | 'supplierName'
                > & {
                    countryName: Maybe<{ __typename: 'CountryName' } & Pick<CountryName, 'fi'>>
                    hierarchyPath: Maybe<
                      Array<
                        { __typename: 'HierarchyPathItem' } & Pick<
                          HierarchyPathItem,
                          'id' | 'name' | 'slug'
                        >
                      >
                    >
                    nutrients: Maybe<
                      Array<{ __typename: 'Nutrient' } & Pick<Nutrient, 'name' | 'ri' | 'value'>>
                    >
                    nutrientsPerServing: Maybe<
                      Array<
                        { __typename: 'NutrientPerServing' } & Pick<
                          NutrientPerServing,
                          'name' | 'value' | 'unit'
                        >
                      >
                    >
                    pricing: Maybe<
                      { __typename: 'ProductPricing' } & Pick<
                        ProductPricing,
                        | 'campaignPrice'
                        | 'lowest30DayPrice'
                        | 'campaignPriceValidUntil'
                        | 'regularPrice'
                      >
                    >
                  }
              >
            >
          }
        >
      }
  >
}

export const CampaignPricesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CampaignPricesFragment, unknown>
export const CoreFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'coreFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoreFieldsFragment, unknown>
export const UnitsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'units' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UnitsFragment, unknown>
export const CategorizationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'categorization' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hierarchyPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CategorizationFragment, unknown>
export const ProductNutrientsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrients' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Nutrient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductNutrientsFragment, unknown>
export const ProductNutrientsPerServingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrientsPerServing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NutrientPerServing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductNutrientsPerServingFragment, unknown>
export const DetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'details' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productNutrients' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrientsPerServing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productNutrientsPerServing' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'supplierName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrients' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Nutrient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrientsPerServing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NutrientPerServing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DetailsFragment, unknown>
export const UserFavoritesListProducFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFavoritesListProducFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'coreFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'units' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'categorization' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'details' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isGlobalFallback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inStore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'measurement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pceApproxWeight' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrients' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Nutrient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrientsPerServing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NutrientPerServing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'coreFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'units' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'categorization' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hierarchyPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'details' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productNutrients' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrientsPerServing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productNutrientsPerServing' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'supplierName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFavoritesListProducFragmentFragment, unknown>
export const ClientCartItemFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientCartItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientCartItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inStoreSelection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainCategoryName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'replace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientCartItemFieldsFragment, unknown>
export const ClientCartItemViewFromProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientCartItemViewFromProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hierarchyPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isGlobalFallback' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientCartItemViewFromProductFragment, unknown>
export const DeliveryOptionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeliveryOption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryArea' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'homeDeliveryType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alcoholSellingAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availablePaymentMethods' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'districts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeliveryOptionFragment, unknown>
export const ReservedDeliverySlotFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReservedDeliverySlot' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliverySlot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alcoholSellingAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedFastTrackTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modificationTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReservedDeliverySlotFragment, unknown>
export const PaymentCardNameWithIdFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PaymentCardNameWithId' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentCard' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGeneratedName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PaymentCardNameWithIdFragment, unknown>
export const ProductAvailabilityFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productAvailability' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'availabilityDate' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeAvailabilities' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductAvailabilityFragment, unknown>
export const PriceSpecificProductFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'priceSpecificProductFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depositPrice' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PriceSpecificProductFieldsFragment, unknown>
export const LocationSpeficProductFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'locationSpeficProductFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'aisle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationSpeficProductFieldsFragment, unknown>
export const ProductCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productCategory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HierarchyPathItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductCategoryFragment, unknown>
export const GlobalProductFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'globalProductFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryOfMainRawMaterial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hierarchyPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productCategory' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isGlobalFallback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productNutrients' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrientsPerServing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productNutrientsPerServing' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supplierName' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productCategory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HierarchyPathItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrients' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Nutrient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrientsPerServing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NutrientPerServing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GlobalProductFieldsFragment, unknown>
export const LocalizedFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'localizedFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localized' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fi' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userGuideForConsumer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'storageGuideForConsumer' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allergens' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'allergenTypeCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'allergenTypeText' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'levelOfContainmentCode' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sv' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userGuideForConsumer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'storageGuideForConsumer' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allergens' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'allergenTypeCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'allergenTypeText' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'levelOfContainmentCode' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocalizedFieldsFragment, unknown>
export const RecipeIngredientFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'recipeIngredient' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RecipeIngredient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eans' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ingredientTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecipeIngredientFragment, unknown>
export const StoreIdAndNameFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeIdAndName' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreIdAndNameFragment, unknown>
export const StoreDayOpeningTimeFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeDayOpeningTime' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreDayOpeningTime' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exceptional' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ranges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closeOnSameDay' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreDayOpeningTimeFragment, unknown>
export const StoreOpeningTimesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeOpeningTimes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weeklyOpeningHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'weekNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'storeDayOpeningTime' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeDayOpeningTime' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreDayOpeningTime' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exceptional' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ranges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closeOnSameDay' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreOpeningTimesFragment, unknown>
export const StoreContactDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeContactDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phoneNumber' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'callCharge' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'callChargeGroup' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postcodeName' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreContactDetailsFragment, unknown>
export const StoreServicesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeServices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'services' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreServicesFragment, unknown>
export const StoreLowestDeliveryPriceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeLowestDeliveryPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoreLowestDeliveryPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreLowestDeliveryPriceFragment, unknown>
export const StoreLowestDeliveryPricesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeLowestDeliveryPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lowestDeliveryPrices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'storeLowestDeliveryPrice' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeLowestDeliveryPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoreLowestDeliveryPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreLowestDeliveryPricesFragment, unknown>
export const StoreAddressFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'street' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postcodeName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreAddressFragment, unknown>
export const StoreInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreAddress' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weeklyOpeningHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'weekNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exceptional' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ranges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'closeOnSameDay' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'street' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postcodeName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreInfoFragment, unknown>
export const RemoteNavigationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteNavigation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'navigation' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'children' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'children' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteNavigationQuery, RemoteNavigationQueryVariables>
export const RemoteUserFavoritesListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteUserFavoritesList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFavorites' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ean' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteUserFavoritesListQuery, RemoteUserFavoritesListQueryVariables>
export const RemoteUserFavoriteProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteUserFavoriteProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFavorites' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'storeId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'userFavoritesListProducFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'coreFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'units' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'categorization' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hierarchyPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrients' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Nutrient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrientsPerServing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NutrientPerServing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'details' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productNutrients' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrientsPerServing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productNutrientsPerServing' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'supplierName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'userFavoritesListProducFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'coreFields' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'units' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'categorization' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'details' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isGlobalFallback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inStore' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabels' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'measurement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'pceApproxWeight' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoteUserFavoriteProductsQuery,
  RemoteUserFavoriteProductsQueryVariables
>
export const RemoteAddFavoriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoteAddFavorite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ean' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFavoritesAddItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ean' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ean' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteAddFavoriteMutation, RemoteAddFavoriteMutationVariables>
export const RemoteRemoveFavoriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoteRemoveFavorite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ean' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userFavoritesRemoveItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ean' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ean' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteRemoveFavoriteMutation, RemoteRemoveFavoriteMutationVariables>
export const UpdateAuthenticationRedirectPathDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAuthenticationRedirectPath' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authRedirectPath' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAuthenticationRedirectPath' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authRedirectPath' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authRedirectPath' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAuthenticationRedirectPathMutation,
  UpdateAuthenticationRedirectPathMutationVariables
>
export const ClearAuthenticationRedirectPathDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClearAuthenticationRedirectPath' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clearAuthenticationRedirectPath' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClearAuthenticationRedirectPathMutation,
  ClearAuthenticationRedirectPathMutationVariables
>
export const UpdateAuthenticationTokensDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAuthenticationTokens' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'accessToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'refreshToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'idToken' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAuthenticationTokens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'accessToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refreshToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'refreshToken' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'idToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'idToken' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAuthenticationTokensMutation,
  UpdateAuthenticationTokensMutationVariables
>
export const ClearAuthenticationTokensDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClearAuthenticationTokens' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clearAuthenticationTokens' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClearAuthenticationTokensMutation,
  ClearAuthenticationTokensMutationVariables
>
export const GetAuthenticationRedirectPathDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuthenticationRedirectPath' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authenticationRedirectPath' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAuthenticationRedirectPathQuery,
  GetAuthenticationRedirectPathQueryVariables
>
export const GetLocalAuthenticationTokensDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLocalAuthenticationTokens' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authenticationTokens' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetLocalAuthenticationTokensQuery,
  GetLocalAuthenticationTokensQueryVariables
>
export const LocalUserProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocalUserProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockmannCardNumber' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocalUserProfileQuery, LocalUserProfileQueryVariables>
export const GetBonusInfoDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBonusInfoData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bonusInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'membershipNumber' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBonusInfoDataQuery, GetBonusInfoDataQueryVariables>
export const GetUserProfileDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserProfileData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'identityCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isEmailVerified' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isPhoneNumberVerified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserProfileDataQuery, GetUserProfileDataQueryVariables>
export const GetRemoteAuthenticationTokensDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRemoteAuthenticationTokens' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'refreshToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authTokens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refreshToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'refreshToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetRemoteAuthenticationTokensQuery,
  GetRemoteAuthenticationTokensQueryVariables
>
export const RemoteUserProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteUserProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bonusInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'membershipNumber' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customerType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tags' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'apartmentNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'streetAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteUserProfileQuery, RemoteUserProfileQueryVariables>
export const RemoteUserCompanyInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteUserCompanyInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteUserCompanyInfoQuery, RemoteUserCompanyInfoQueryVariables>
export const GetInitialDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInitialData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerDetails' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerType' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryDetailsInfo' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryStore' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEditActive' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentDetails' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stockmannCardNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selectedPaymentCardId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savePaymentCard' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectedBrand' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectedStoreId' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectedAreaId' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authenticationTokens' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'refreshToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInitialDataQuery, GetInitialDataQueryVariables>
export const CitrusAdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CitrusAd' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'placement' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slotId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'brand' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'useCustomerId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'citrusAd' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'placement' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'placement' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slotId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slotId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'brand' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'brand' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'useCustomerId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'useCustomerId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contentStandardId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'banners' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'citrusAdId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'citrusCampaignName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'backgroundImage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'heroImage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ctaText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'headingText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bannerText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gtins' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CitrusAdQuery, CitrusAdQueryVariables>
export const SendCitrusAdUserEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendCitrusAdUserEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'adId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eventType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CitrusAdUserEventType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendCitrusAdUserEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'adId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'adId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'eventType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendCitrusAdUserEventMutation, SendCitrusAdUserEventMutationVariables>
export const GetAllClientCartItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllClientCartItems' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cartItems' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientCartItemFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientCartItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientCartItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inStoreSelection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainCategoryName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'replace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllClientCartItemsQuery, GetAllClientCartItemsQueryVariables>
export const GetClientCartItemCountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientCartItemCounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cartItems' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientCartItemCountsQuery, GetClientCartItemCountsQueryVariables>
export const GetClientCartItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientCartItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cartItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ClientCartItemFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientCartItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientCartItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inStoreSelection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainCategoryName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'replace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientCartItemQuery, GetClientCartItemQueryVariables>
export const GetClientCartItemCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetClientCartItemCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cartItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetClientCartItemCountQuery, GetClientCartItemCountQueryVariables>
export const RemoteClientCartItemFromProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteClientCartItemFromProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fallbackToGlobal' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ClientCartItemViewFromProduct' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientCartItemViewFromProduct' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hierarchyPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isGlobalFallback' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoteClientCartItemFromProductQuery,
  RemoteClientCartItemFromProductQueryVariables
>
export const ComplementaryProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ComplementaryProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'focusOnEan' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eans' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'complementaryProducts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eans' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'eans' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'focusOnEan' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'focusOnEan' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'coreFields' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'units' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
                { kind: 'Field', name: { kind: 'Name', value: 'packagingLabels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hierarchyPath' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isGlobalFallback' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'countryName' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'coreFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'units' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ComplementaryProductsQuery, ComplementaryProductsQueryVariables>
export const UpdatePersonalInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePersonalInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePersonalInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePersonalInfoMutation, UpdatePersonalInfoMutationVariables>
export const UpdateCustomerInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCustomerInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomerInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customerType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCustomerInfoMutation, UpdateCustomerInfoMutationVariables>
export const UpdateCustomerInfoTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomerInfoTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stockmannCardNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomerInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'customerType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStockmannCardNumber' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stockmannCardNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'stockmannCardNumber' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCustomerInfoTemplateMutation,
  UpdateCustomerInfoTemplateMutationVariables
>
export const GetCustomerDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerDetails' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>
export const GetCustomerTypeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCustomerType' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerType' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCustomerTypeQuery, GetCustomerTypeQueryVariables>
export const UpdateDeliveryDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDeliveryDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryTime' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotPrice' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryMethod' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'additionalInfo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDeliveryDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryTime' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryTime' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliverySlotPrice' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotPrice' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryMethod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliverySlotId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'postalCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'additionalInfo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'additionalInfo' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateDeliveryDetailsMutation, UpdateDeliveryDetailsMutationVariables>
export const GetDeliveryDetailsInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeliveryDetailsInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryDetailsInfo' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDeliveryDetailsInfoQuery, GetDeliveryDetailsInfoQueryVariables>
export const GetSelectedDeliverySlotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSelectedDeliverySlot' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryDetailsInfo' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSelectedDeliverySlotQuery, GetSelectedDeliverySlotQueryVariables>
export const SearchDeliveryAreasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchDeliveryAreas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryMethod' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'freetext' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Location' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchDeliveryAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryMethod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domains' },
                value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'S_KAUPAT' }] },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freetext' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'freetext' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'location' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'location' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'postalCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homeDeliveryType' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'NORMAL' },
                    { kind: 'EnumValue', value: 'ROBOT' },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'areas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DeliveryOption' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'distance' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextDeliverySlot' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'estimatedFastTrackTime' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DeliveryOption' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryArea' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'homeDeliveryType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'alcoholSellingAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availablePaymentMethods' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'districts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'street' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchDeliveryAreasQuery, SearchDeliveryAreasQueryVariables>
export const GetDeliveryAreaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeliveryArea' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alcoholSellingAllowed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliverySlots' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reservationId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deliveryTimes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'closingTimestamp' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'alcoholSellingAllowed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'modificationTimestamp' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'time' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'store' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'availablePaymentMethods' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'districts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDeliveryAreaQuery, GetDeliveryAreaQueryVariables>
export const GetDeliveryAreaDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeliveryAreaDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dynamicPricing' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'store' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'city' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDeliveryAreaDetailsQuery, GetDeliveryAreaDetailsQueryVariables>
export const GetDeliveryAreaWithNextSlotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeliveryAreaWithNextSlot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeNextSlot' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'store' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'shortName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nextDeliverySlot' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'includeNextSlot' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'estimatedFastTrackTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeliveryAreaWithNextSlotQuery,
  GetDeliveryAreaWithNextSlotQueryVariables
>
export const GetDeliverySlotByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeliverySlotById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slotId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliverySlot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slotId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'alcoholSellingAllowed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closingTimestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'estimatedFastTrackTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modificationTimestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'time' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDeliverySlotByIdQuery, GetDeliverySlotByIdQueryVariables>
export const CreateDeliverySlotReservationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateDeliverySlotReservation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDeliverySlotReservation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliverySlotId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reservationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateDeliverySlotReservationMutation,
  CreateDeliverySlotReservationMutationVariables
>
export const RefreshDeliverySlotReservationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefreshDeliverySlotReservation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refreshDeliverySlotReservation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reservationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reservationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RefreshDeliverySlotReservationMutation,
  RefreshDeliverySlotReservationMutationVariables
>
export const ReleaseDeliverySlotReservationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReleaseDeliverySlotReservation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'releaseDeliverySlotReservation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reservationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReleaseDeliverySlotReservationMutation,
  ReleaseDeliverySlotReservationMutationVariables
>
export const DeliverySlotReservationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeliverySlotReservation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliverySlotReservation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reservationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'reservationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryArea' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'alcoholSellingAllowed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliverySlot' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ReservedDeliverySlot' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReservedDeliverySlot' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliverySlot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alcoholSellingAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedFastTrackTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modificationTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeliverySlotReservationQuery, DeliverySlotReservationQueryVariables>
export const DeliverySlotReservationViewFromDeliverySlotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DeliverySlotReservationViewFromDeliverySlot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slotId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliverySlot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slotId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ReservedDeliverySlot' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReservedDeliverySlot' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliverySlot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'alcoholSellingAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'estimatedFastTrackTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'modificationTimestamp' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'time' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeliverySlotReservationViewFromDeliverySlotQuery,
  DeliverySlotReservationViewFromDeliverySlotQueryVariables
>
export const SendGiosgChatMetadataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendGiosgChatMetadata' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'visitorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendGiosgChatMetadata' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visitorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'visitorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendGiosgChatMetadataMutation, SendGiosgChatMetadataMutationVariables>
export const CancelOrderModificationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelOrderModification' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isLoggedIn' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clearCartAndOrderDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isLoggedIn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isLoggedIn' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetDeliveryStore' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelOrderModificationMutation,
  CancelOrderModificationMutationVariables
>
export const SetExistingOrderAsEditableOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetExistingOrderAsEditableOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryTime' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'additionalInfo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentMethod' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryMethod' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomerInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStoreId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stockmannCardNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentStatus' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderStatus' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderStatus' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedAreaId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStoreName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedBrand' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeBrand' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotPrice' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedPaymentCardId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'savePaymentCard' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startOrderEdit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderStatus' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDeliveryDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryTime' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryTime' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliverySlotPrice' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotPrice' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryMethod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliverySlotId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'postalCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'additionalInfo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'additionalInfo' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelectedBrand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedBrand' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedBrand' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelectedStoreId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedStoreId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStoreId' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelectedAreaId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedAreaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDeliveryStore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStoreId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'areaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'brand' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeBrand' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePersonalInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomerType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerType' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePaymentMethodDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentMethod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stockmannCardNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'stockmannCardNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentStatus' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedPaymentCardId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedPaymentCardId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'savePaymentCard' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'savePaymentCard' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetExistingOrderAsEditableOrderMutation,
  SetExistingOrderAsEditableOrderMutationVariables
>
export const SetExistingOrderAsOrderTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetExistingOrderAsOrderTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryTime' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'additionalInfo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentMethod' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryMethod' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomerInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStoreId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stockmannCardNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentStatus' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedAreaId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStoreName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeBrand' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotPrice' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'customerType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedPaymentCardId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'savePaymentCard' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDeliveryDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryTime' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryTime' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliverySlotPrice' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotPrice' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryMethod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliverySlotId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'postalCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'additionalInfo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'additionalInfo' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelectedStoreId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedStoreId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStoreId' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelectedAreaId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedAreaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDeliveryStore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStoreId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'areaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'brand' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeBrand' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePersonalInfo' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'phone' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'phone' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyName' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'comment' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'comment' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomerType' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customerType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'customerType' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePaymentMethodDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentMethod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stockmannCardNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'stockmannCardNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentStatus' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedPaymentCardId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedPaymentCardId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'savePaymentCard' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'savePaymentCard' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOrderEdit' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetExistingOrderAsOrderTemplateMutation,
  SetExistingOrderAsOrderTemplateMutationVariables
>
export const StartOrderEditDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StartOrderEdit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderStatus' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderStatus' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'startOrderEdit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderStatus' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StartOrderEditMutation, StartOrderEditMutationVariables>
export const StopOrderEditDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StopOrderEdit' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopOrderEdit' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StopOrderEditMutation, StopOrderEditMutationVariables>
export const ClearCartAndOrderDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClearCartAndOrderDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isLoggedIn' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clearCartAndOrderDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isLoggedIn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isLoggedIn' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClearCartAndOrderDetailsMutation,
  ClearCartAndOrderDetailsMutationVariables
>
export const CreateOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkVerificationToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bonusCard' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sMembershipNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sMembershipCardLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sMembershipCardPostalCode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cartItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'replace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateOrderMutation, CreateOrderMutationVariables>
export const UpdateOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'OrderInput' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkVerificationToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bonusCard' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sMembershipNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sMembershipCardLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sMembershipCardPostalCode' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cartItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'replace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateOrderMutation, UpdateOrderMutationVariables>
export const CancelOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelOrderMutation, CancelOrderMutationVariables>
export const ResetOrderPaymentCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetOrderPaymentCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetOrderPaymentCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetOrderPaymentCardMutation, ResetOrderPaymentCardMutationVariables>
export const IsOrderEditActiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IsOrderEditActive' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderEditActive' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IsOrderEditActiveQuery, IsOrderEditActiveQueryVariables>
export const GetOrderDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrderData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectedStoreId' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentDetails' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stockmannCardNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selectedPaymentCardId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savePaymentCard' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerType' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cartItems' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'replace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'inStoreSelection' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerDetails' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryDetailsInfo' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderDataQuery, GetOrderDataQueryVariables>
export const GetOrderByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrderById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkVerificationToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkVerificationToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkVerificationToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkVerificationToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCancelable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliverySlot' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'closingTimestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'estimatedFastTrackTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'modificationTimestamp' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bonusCard' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceAddress' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceCity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceCompanyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceFirstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoicePhone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoicePostalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sMembershipNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sMembershipCardLastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sMembershipCardPostalCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cartItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'replace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderByIdQuery, GetOrderByIdQueryVariables>
export const GetOrderHistoryDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrderHistoryData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Domain' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataSources' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserOrderDataSource' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataSources' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataSources' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dataSource' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotClosed' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliverySlotModificationTimestamp' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeDeliveryType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCancelable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrderHistoryDataQuery, GetOrderHistoryDataQueryVariables>
export const GetUserOrderByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserOrderById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userOrder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotClosingTimestamp' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliverySlotModificationTimestamp' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modifiedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cartItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'replace' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dataSource' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserOrderByIdQuery, GetUserOrderByIdQueryVariables>
export const RemoteGetRecentOrdersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteGetRecentOrders' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Domain' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'dataSources' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserOrderDataSource' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userOrders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataSources' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'dataSources' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homeDeliveryType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteGetRecentOrdersQuery, RemoteGetRecentOrdersQueryVariables>
export const UpdatePaymentMethodDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePaymentMethodDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentMethod' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stockmannCardNumber' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentStatus' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedPaymentCardId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'savePaymentCard' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePaymentMethodDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentMethod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentMethod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invoiceNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invoiceNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stockmannCardNumber' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'stockmannCardNumber' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentStatus' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentStatus' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedPaymentCardId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedPaymentCardId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'savePaymentCard' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'savePaymentCard' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePaymentMethodDetailsMutation,
  UpdatePaymentMethodDetailsMutationVariables
>
export const CreatePaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'device' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeviceType' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customWebstoreRedirectUrl' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cardId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shouldSavePaymentCard' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'device' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'device' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customWebstoreRedirectUrl' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customWebstoreRedirectUrl' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cardId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cardId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shouldSavePaymentCard' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shouldSavePaymentCard' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'redirectUrl' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreatePaymentMutation, CreatePaymentMutationVariables>
export const AuthorizePaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthorizePayment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authorizePayment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'orderId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'authorized' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthorizePaymentMutation, AuthorizePaymentMutationVariables>
export const SaveUserPaymentCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveUserPaymentCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveUserPaymentCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveUserPaymentCardMutation, SaveUserPaymentCardMutationVariables>
export const RemoveUserPaymentCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveUserPaymentCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cardId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserPaymentCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cardId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cardId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveUserPaymentCardMutation, RemoveUserPaymentCardMutationVariables>
export const RenameUserPaymentCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RenameUserPaymentCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cardId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'renameUserPaymentCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cardId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cardId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RenameUserPaymentCardMutation, RenameUserPaymentCardMutationVariables>
export const SetDefaultUserPaymentCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetDefaultUserPaymentCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cardId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDefaultUserPaymentCard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cardId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cardId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetDefaultUserPaymentCardMutation,
  SetDefaultUserPaymentCardMutationVariables
>
export const GetPaymentDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaymentDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentDetails' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stockmannCardNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selectedPaymentCardId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'savePaymentCard' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPaymentDetailsQuery, GetPaymentDetailsQueryVariables>
export const GetAvailablePaymentMethodsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailablePaymentMethods' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availablePaymentMethods' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAvailablePaymentMethodsQuery,
  GetAvailablePaymentMethodsQueryVariables
>
export const GetUserPaymentCardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserPaymentCards' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPaymentCards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cards' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maskedCardNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userGeneratedName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expired' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultPaymentCardId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserPaymentCardsQuery, GetUserPaymentCardsQueryVariables>
export const GetProductInfoByEansDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductInfoByEans' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eans' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fallbackToGlobal' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'availabilityDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeAvailabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'eans' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'eans' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fallbackToGlobal' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fallbackToGlobal' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '200' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'coreFields' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'units' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'categorization' },
                            },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'details' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'productAvailability' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'countryName' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fi' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isGlobalFallback' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'packagingLabels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'inStore' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'measurement' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pceApproxWeight' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrients' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Nutrient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrientsPerServing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NutrientPerServing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'coreFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'units' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'categorization' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hierarchyPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'details' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productNutrients' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrientsPerServing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productNutrientsPerServing' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'supplierName' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productAvailability' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'availabilityDate' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeAvailabilities' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProductInfoByEansQuery, GetProductInfoByEansQueryVariables>
export const RemoteProductAvailabilitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteProductAvailabilities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'availabilityDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeAvailabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'eans' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'productIds' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'productAvailability' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productAvailability' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'availabilityDate' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeAvailabilities' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoteProductAvailabilitiesQuery,
  RemoteProductAvailabilitiesQueryVariables
>
export const RemoteProductInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteProductInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ean' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeGlobalFallback' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'availabilityDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeAvailabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ean' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fallbackToGlobal' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeGlobalFallback' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'globalProductFields' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'priceSpecificProductFields' },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productAvailability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'packagingLabels' } },
                { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'localizedFields' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'locationSpeficProductFields' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productCategory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HierarchyPathItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrients' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Nutrient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrientsPerServing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NutrientPerServing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'globalProductFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryOfMainRawMaterial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hierarchyPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productCategory' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isGlobalFallback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productNutrients' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrientsPerServing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productNutrientsPerServing' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supplierName' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'priceSpecificProductFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depositPrice' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productAvailability' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'availabilityDate' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeAvailabilities' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'localizedFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'localized' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fi' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userGuideForConsumer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'storageGuideForConsumer' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allergens' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'allergenTypeCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'allergenTypeText' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'levelOfContainmentCode' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sv' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'userGuideForConsumer' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'storageGuideForConsumer' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allergens' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'allergenTypeCode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'allergenTypeText' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'levelOfContainmentCode' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'locationSpeficProductFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'aisle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'floor' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteProductInfoQuery, RemoteProductInfoQueryVariables>
export const SendProductUserEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendProductUserEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eventType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProductUserEventType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendProductUserEvents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sessionId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sessionId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'eventType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'eventType' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendProductUserEventMutation, SendProductUserEventMutationVariables>
export const GetRecipeByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRecipeById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fallbackToGlobal' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'availabilityDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeAvailabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recipe' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'categories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'diet' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'cookTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yield' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yieldType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ingredients' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'recipeIngredient' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fallbackToGlobal' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fallbackToGlobal' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'storeId' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'globalProductFields' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'priceSpecificProductFields' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'productAvailability' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'instructions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publisher' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productCategory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HierarchyPathItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrients' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Nutrient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrientsPerServing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NutrientPerServing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'recipeIngredient' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RecipeIngredient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amountType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'groupName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'eans' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ingredientTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'maxAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minAmount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'globalProductFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryOfMainRawMaterial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hierarchyPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productCategory' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isGlobalFallback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productNutrients' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrientsPerServing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productNutrientsPerServing' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supplierName' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'priceSpecificProductFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depositPrice' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productAvailability' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'availabilityDate' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeAvailabilities' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRecipeByIdQuery, GetRecipeByIdQueryVariables>
export const RemoteFilteredProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteFilteredProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'facets' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'StructuredFacetInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Filters' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'generatedSessionId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'loop54DirectSearch' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortOrder' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortKey' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'queryString' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchProvider' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchProvider' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'useRandomId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'availabilityDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeAvailabilities' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: false },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fallbackToGlobal' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filters' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'from' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'generatedSessionId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'generatedSessionId' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'loop54DirectSearch' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'loop54DirectSearch' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'order' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'queryString' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'queryString' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'searchProvider' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'searchProvider' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'structuredFacets' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'facets' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'useRandomId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'useRandomId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fallbackToGlobal' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fallbackToGlobal' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'searchProvider' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'coreFields' } },
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'units' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'productAvailability' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'packagingLabels' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hierarchyPath' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'isGlobalFallback' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'countryName' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fi' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'structuredFacets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'StringFacet' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'stringValue' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'ObjectFacet' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'objectValue' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'doc_count' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'coreFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'units' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productAvailability' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'availabilities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'availabilityDate' } },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeAvailabilities' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteFilteredProductsQuery, RemoteFilteredProductsQueryVariables>
export const RemoteAvailableStoreProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteAvailableStoreProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'from' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'modified' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoteAvailableStoreProductsQuery,
  RemoteAvailableStoreProductsQueryVariables
>
export const RemoteStoreSearchAllDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteStoreSearchAll' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStores' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_dangerousLoadAllOverrideForSitemap' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stores' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteStoreSearchAllQuery, RemoteStoreSearchAllQueryVariables>
export const SetStoreSelectionTemplateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetStoreSelectionTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'additionalInfo' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'brand' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryMethod' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryTime' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotPrice' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDeliveryDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryTime' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryTime' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliverySlotPrice' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotPrice' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliveryMethod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deliverySlotId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'address' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'postalCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'postalCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'additionalInfo' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'additionalInfo' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelectedStoreId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedStoreId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelectedAreaId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedAreaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelectedBrand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedBrand' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'brand' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDeliveryStore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'brand' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'brand' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'areaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetStoreSelectionTemplateMutation,
  SetStoreSelectionTemplateMutationVariables
>
export const UpdateSelectedStoreIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSelectedStoreId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStoreId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelectedStoreId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedStoreId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedStoreId' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSelectedStoreIdMutation, UpdateSelectedStoreIdMutationVariables>
export const UpdateSelectedAreaIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSelectedAreaId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedAreaId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelectedAreaId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedAreaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedAreaId' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSelectedAreaIdMutation, UpdateSelectedAreaIdMutationVariables>
export const UpdateSelectedBrandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSelectedBrand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'selectedBrand' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSelectedBrand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedBrand' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'selectedBrand' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateSelectedBrandMutation, UpdateSelectedBrandMutationVariables>
export const SetDeliveryStoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetDeliveryStore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'brand' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setDeliveryStore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'brand' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'brand' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'areaId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areaId' } },
              },
            ],
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetDeliveryStoreMutation, SetDeliveryStoreMutationVariables>
export const ResetDeliveryStoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetDeliveryStore' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetDeliveryStore' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetDeliveryStoreMutation, ResetDeliveryStoreMutationVariables>
export const GetSelectedStoreIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSelectedStoreId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectedStoreId' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSelectedStoreIdQuery, GetSelectedStoreIdQueryVariables>
export const GetSelectedAreaIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSelectedAreaId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectedAreaId' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSelectedAreaIdQuery, GetSelectedAreaIdQueryVariables>
export const GetSelectedBrandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSelectedBrand' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectedBrand' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSelectedBrandQuery, GetSelectedBrandQueryVariables>
export const GetDeliveryStoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeliveryStore' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryStore' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDeliveryStoreQuery, GetDeliveryStoreQueryVariables>
export const GetBrandByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBrandById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBrandByIdQuery, GetBrandByIdQueryVariables>
export const GetDeliverySlotAndStoreDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeliverySlotAndStoreData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliverySlot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeliverySlotAndStoreDataQuery,
  GetDeliverySlotAndStoreDataQueryVariables
>
export const StoreDeliveryMethodsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StoreDeliveryMethods' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchDeliveryAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storeId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'areas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isRemote' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StoreDeliveryMethodsQuery, StoreDeliveryMethodsQueryVariables>
export const RemoteStoreNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteStoreName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'storeIdAndName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeIdAndName' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteStoreNameQuery, RemoteStoreNameQueryVariables>
export const RemoteStoreInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteStoreInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'storeIdAndName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coOperative' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'storeContactDetails' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'storeServices' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'storeOpeningTimes' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'storeLowestDeliveryPrices' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeDayOpeningTime' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreDayOpeningTime' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'date' } },
          { kind: 'Field', name: { kind: 'Name', value: 'day' } },
          { kind: 'Field', name: { kind: 'Name', value: 'exceptional' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ranges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                { kind: 'Field', name: { kind: 'Name', value: 'closeOnSameDay' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeLowestDeliveryPrice' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StoreLowestDeliveryPrice' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeIdAndName' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeContactDetails' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'phoneNumber' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'callCharge' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'callChargeGroup' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'street' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postcodeName' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coordinates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lon' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedback' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeServices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'services' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeOpeningTimes' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weeklyOpeningHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'weekNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'storeDayOpeningTime' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeLowestDeliveryPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Store' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lowestDeliveryPrices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'storeLowestDeliveryPrice' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteStoreInfoQuery, RemoteStoreInfoQueryVariables>
export const RemoteStoreSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteStoreSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'brand' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreBrand' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchStores' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'brand' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'brand' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cursor' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'cursor' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'stores' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'storeInfo' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StoreAddress' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreAddress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'street' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postcodeName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'default' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'storeInfo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreInfo' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
          { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StoreAddress' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'weeklyOpeningHours' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'weekNumber' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'openingTimes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'exceptional' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ranges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'open' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'close' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'closeOnSameDay' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteStoreSearchQuery, RemoteStoreSearchQueryVariables>
export const RemoteServiceSubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteServiceSubscriptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'serviceTypes' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ServiceSubscriptionServiceType' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'serviceSubscriptions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serviceTypes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'serviceTypes' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'serviceType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'serviceName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscribed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStartDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'changeAllowed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoteServiceSubscriptionsQuery,
  RemoteServiceSubscriptionsQueryVariables
>
export const GetDeliveryAreaAlcoholSellingStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeliveryAreaAlcoholSellingStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alcoholSellingAllowed' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDeliveryAreaAlcoholSellingStatusQuery,
  GetDeliveryAreaAlcoholSellingStatusQueryVariables
>
export const GetMandatoryProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMandatoryProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DeliveryMethod' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mandatoryProducts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'deliveryMethod' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryMethod' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'deliverySlotId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reservationId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMandatoryProductsQuery, GetMandatoryProductsQueryVariables>
export const GetDiscountCodeProductDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDiscountCodeProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'discountCode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discountProduct' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'discountCode' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'discountCode' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'discountType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isValid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'discountAmount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDiscountCodeProductQuery, GetDiscountCodeProductQueryVariables>
export const OrderTrackingGetOrderStatusByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderTrackingGetOrderStatusById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkVerificationToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkVerificationToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkVerificationToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OrderTrackingGetOrderStatusByIdQuery,
  OrderTrackingGetOrderStatusByIdQueryVariables
>
export const OrderTrackingGetOrderIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'OrderTrackingGetOrderId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderTrackingGetOrderIdQuery, OrderTrackingGetOrderIdQueryVariables>
export const GetTrackingSelectedStoreDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTrackingSelectedStoreData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTrackingSelectedStoreDataQuery,
  GetTrackingSelectedStoreDataQueryVariables
>
export const RemoteGetTrackingSelectedStoreDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteGetTrackingSelectedStoreData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoteGetTrackingSelectedStoreDataQuery,
  RemoteGetTrackingSelectedStoreDataQueryVariables
>
export const RemoteGetTrackingSelectedDeliverySlotDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteGetTrackingSelectedDeliverySlotData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliverySlot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoteGetTrackingSelectedDeliverySlotDataQuery,
  RemoteGetTrackingSelectedDeliverySlotDataQueryVariables
>
export const RemoteStoreCoopInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteStoreCoopInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'coOperative' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteStoreCoopInfoQuery, RemoteStoreCoopInfoQueryVariables>
export const AddressValidateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AddressValidate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addressValidate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'countryCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'countryCode' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lang' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lang' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isValid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressValidateQuery, AddressValidateQueryVariables>
export const RemoteStoreAvailabilityByEanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteStoreAvailabilityByEan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ean' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ean' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeAgeLimitedByAlcohol' },
                value: { kind: 'BooleanValue', value: true },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fallbackToGlobal' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storeAvailability' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoteStoreAvailabilityByEanQuery,
  RemoteStoreAvailabilityByEanQueryVariables
>
export const LocalDomainOrderDeliveryMethodDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocalDomainOrderDeliveryMethod' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryAreaId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LocalDomainOrderDeliveryMethodQuery,
  LocalDomainOrderDeliveryMethodQueryVariables
>
export const RemoteDeliveryAreaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteDeliveryArea' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'store' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'brand' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteDeliveryAreaQuery, RemoteDeliveryAreaQueryVariables>
export const LocalOverviewCardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocalOverviewCard' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cartItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inStoreSelection' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocalOverviewCardQuery, LocalOverviewCardQueryVariables>
export const LocalDomainOrderCartItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocalDomainOrderCartItems' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cartItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'replace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inStoreSelection' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mainCategoryName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LocalDomainOrderCartItemsQuery,
  LocalDomainOrderCartItemsQueryVariables
>
export const PackagingMaterialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PackagingMaterials' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryAreaId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cartItems' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'deliveryAreaId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availablePackagingMaterials' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'materialType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'materialPrice' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PackagingMaterialsQuery, PackagingMaterialsQueryVariables>
export const LocalDomainOrderPaymentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocalDomainOrderPayment' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocalDomainOrderPaymentQuery, LocalDomainOrderPaymentQueryVariables>
export const LocalDomainOrderPaymentModalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocalDomainOrderPaymentModal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockmannCardNumber' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LocalDomainOrderPaymentModalQuery,
  LocalDomainOrderPaymentModalQueryVariables
>
export const RemoteStorePaymentMethodsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteStorePaymentMethods' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availablePaymentMethods' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoteStorePaymentMethodsQuery,
  RemoteStorePaymentMethodsQueryVariables
>
export const ServiceFeesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ServiceFees' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cartItems' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serviceProducts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ServiceFeesQuery, ServiceFeesQueryVariables>
export const RemoteGetOrderSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteGetOrderSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'packagingMaterialEan' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'store' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mandatoryProducts' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'deliverySlotId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'deliverySlotId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'packagingMaterialEan' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'packagingMaterialEan' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reservationId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'reservationId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteGetOrderSummaryQuery, RemoteGetOrderSummaryQueryVariables>
export const RemoteGetDiscountCodeForOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteGetDiscountCodeForOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkVerificationToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'order' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkVerificationToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkVerificationToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoteGetDiscountCodeForOrderQuery,
  RemoteGetDiscountCodeForOrderQueryVariables
>
export const GetLocalDomainOrderStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLocalDomainOrderState' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLocalDomainOrderStateQuery, GetLocalDomainOrderStateQueryVariables>
export const GetLocalDomainOrderStoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLocalDomainOrderStore' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'storeId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLocalDomainOrderStoreQuery, GetLocalDomainOrderStoreQueryVariables>
export const LocalSelectedDeliverySlotIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocalSelectedDeliverySlotId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LocalSelectedDeliverySlotIdQuery,
  LocalSelectedDeliverySlotIdQueryVariables
>
export const RemoteDeliverySlotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteDeliverySlot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slotId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliverySlot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slotId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isClosed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availability' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDateTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDateTime' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'time' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alcoholSellingAllowed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isModifiable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'modificationTimestamp' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFastTrack' } },
                { kind: 'Field', name: { kind: 'Name', value: 'estimatedFastTrackTime' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoteDeliverySlotQuery, RemoteDeliverySlotQueryVariables>
export const LocalDomainOrderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocalDomainOrder' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'domainOrder' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discountCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cardType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stockmannCardNumber' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deliveryAddress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'address' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'payment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invoiceNumber' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cartItems' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ClientCartItemFields' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reservationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliverySlotId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deliveryAreaId' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ClientCartItemFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ClientCartItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'additionalInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'inStoreSelection' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'itemCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainCategoryName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'replace' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocalDomainOrderQuery, LocalDomainOrderQueryVariables>
export const RemoteOrderDeliveryAreaDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RemoteOrderDeliveryAreaDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deliveryArea' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'areaId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'address' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'street' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'store' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'street' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RemoteOrderDeliveryAreaDetailsQuery,
  RemoteOrderDeliveryAreaDetailsQueryVariables
>
export const GetProductRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductRecommendations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeRecentPurchases' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includePurchaseHistory' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeOther' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
          defaultValue: { kind: 'BooleanValue', value: true },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'recentPurchases' },
            name: { kind: 'Name', value: 'userProductRecommendation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'CART' },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includeRecentPurchases' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'runId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'storeId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'priceSpecificProductFields' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'globalProductFields' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'packagingLabels' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'purchaseHistory' },
            name: { kind: 'Name', value: 'userProductRecommendation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'YOU_MAY_NEED' },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'includePurchaseHistory' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'runId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'storeId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'priceSpecificProductFields' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'globalProductFields' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'packagingLabels' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'other' },
            name: { kind: 'Name', value: 'userProductRecommendation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'EnumValue', value: 'TRY' },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeOther' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'runId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'storeId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'storeId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'items' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'priceSpecificProductFields' },
                            },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'globalProductFields' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'packagingLabels' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productCategory' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HierarchyPathItem' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrients' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Nutrient' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'productNutrientsPerServing' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NutrientPerServing' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignPrices' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lowest30DayPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'campaignPriceValidUntil' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regularPrice' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'priceSpecificProductFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'approxPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonPrice' } },
          { kind: 'Field', name: { kind: 'Name', value: 'comparisonUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'priceUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'depositPrice' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'globalProductFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'basicQuantityUnit' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brandName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'consumerPackageUnit' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countryName' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'fi' } }],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'countryOfMainRawMaterial' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ean' } },
          { kind: 'Field', name: { kind: 'Name', value: 'frozen' } },
          { kind: 'Field', name: { kind: 'Name', value: 'packagingLabelCodes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hierarchyPath' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productCategory' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ingredientStatement' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAgeLimitedByAlcohol' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isGlobalFallback' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'productNutrients' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nutrientsPerServing' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'productNutrientsPerServing' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'quantityMultiplier' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supplierName' } },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'CampaignPrices' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductRecommendationsQuery,
  GetProductRecommendationsQueryVariables
>
