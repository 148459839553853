import { FALLBACK_CATEGORY_NAME, MISSING_ITEMS_CATEGORY_NAME } from '@shared/domain/categories'

export const getMainCategoryName = (
  x: { hierarchyPath?: { name: string }[] | null } | null,
): string => {
  if (!x) {
    return MISSING_ITEMS_CATEGORY_NAME
  }

  const path =
    x?.hierarchyPath && x.hierarchyPath.length > 0
      ? x.hierarchyPath[x.hierarchyPath.length - 1]
      : null
  return path?.name || FALLBACK_CATEGORY_NAME
}
