import type { CartItem } from '@shared/gql/document-nodes'

import { DISCOUNT_EAN_CODES, EAN_CODES_TO_IGNORE } from './constants'

export const filterServiceItems = (cartItems: CartItem[]): CartItem[] => {
  if (!cartItems) {
    throw new Error('Cannot filter service items. Cart item array is undefined')
  }
  return cartItems.filter(
    (cartItem) =>
      // [].includes(undefined) is always `false`, but TSC doesn't like it
      !EAN_CODES_TO_IGNORE.includes(cartItem?.ean as string) &&
      !DISCOUNT_EAN_CODES.includes(cartItem?.ean as string),
  )
}
