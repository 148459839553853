import type { ClientCartItem } from '@shared/gql/document-nodes'

import { useApolloClient } from '@apollo/client'

import { DaoService } from '../../LocalStorage'
import { shoppingCartVar } from '../cache'

export const useSetCartItems = () => {
  const apolloClient = useApolloClient()
  return async (cartItems: ClientCartItem[]) => {
    await DaoService.CartDAO.saveCartItems(cartItems, apolloClient)
    shoppingCartVar(DaoService.CartDAO.loadCartItems())
  }
}
