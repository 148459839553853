import type { CSSObject, DefaultTheme } from 'styled-components'

import { minWidthFromTheme as _minWidthFromTheme } from '@sok/design-system'

export const LAYOUT_MAX_WIDTH = 1920
export const CONTENT_PAGE_MAX_WIDTH = 1200
export const NARROW_PAGE_MAX_WIDTH = 1000

export const TEXT_CONTENT_MAX_WIDTH = 700

/**
 * iOS compatible "min-height: 100vh;", because it would
 * be too easy otherwise... without this hack the content
 * would go below the top/bottom bars.
 */
export const MIN_HEIGHT_FULLSCREEN: CSSObject = {
  minHeight: '100vh',

  '@supports (-webkit-touch-callout: none)': {
    minHeight: '-webkit-fill-available',
  },
} as const

export const Z_INDEX = {
  antdPopOver: 1030 /** This z-index comes from Ant Design (.ant-popover class) */,
  globalLoadingIndicator: 70,
  modal: 60,
  menu: 50,
  snackbar: 10000001, // on top of giosg chat button
  reservationWarning: 2000,
} as const

export const NAVBAR_HEIGHT = {
  mobile: 120,
  mobileHalf: 64,
  desktop: 80,
}

export const NAVBAR_WIDTH = 1920

export const SERVICE_INFORMATION_BANNER_HEIGHT = 50

export const middleDesktop = '@media (min-width: 1280px)'
export const smallMobile = '@media (min-width: 375px)'

export const customMinWidthFromTheme = (
  theme: DefaultTheme,
): ReturnType<typeof _minWidthFromTheme> & { middleDesktop: typeof middleDesktop } => {
  return {
    ..._minWidthFromTheme(theme),
    middleDesktop: middleDesktop,
  }
}
