import type { NextAvailableSlotType } from './delivery-option-info-card/NextAvailableSlot'
import type { Brand } from '@shared/domain/brand'
import type { DeliveryOption } from 'domain/delivery-option'
import type { FC, KeyboardEvent, MutableRefObject } from 'react'

import { DeliveryMethod, HomeDeliveryType } from '@shared/gql/document-nodes'
import {
  ConfirmedFilled24,
  InfoOutline16,
  LocationOutline16,
  minWidthFromTheme,
  RiskIndicatorOutline24,
} from '@sok/design-system'
import { BrandStoreLogo } from 'components/BrandStoreLogo'
import { BORDER_RADIUS } from 'components/Button/border-radius'
import { ImageLinkButton } from 'components/common/Buttons/ImageLinkButton'
import { formatDistance, isDistance } from 'domain/location'
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css, useTheme } from 'styled-components'
import { getDeliveryMethodString } from 'utils/delivery/get-delivery-method-string'

import { NextAvailableSlot } from './delivery-option-info-card/NextAvailableSlot'
import { OptionLabels } from './delivery-option-info-card/OptionLabels'

const StyledContainer = styled.div(({ theme }) => ({
  ...theme.variants.body2.regular,
  border: '1px solid transparent',
  display: 'flex',
  flexDirection: 'column',

  '&:not(:first-of-type)': {
    borderTopColor: theme.colors.grey200,
  },

  '&[aria-selected="true"]': {
    borderColor: `${theme.color.inputHoverColor} !important`,
    ...BORDER_RADIUS,
  },

  '&:focus-within': {
    borderColor: `${theme.color.highlightFocusColor} !important`,
    ...BORDER_RADIUS,
    outline: 'none',
  },

  '&:focus-within + &, &[aria-selected="true"] + &': {
    borderTopColor: 'transparent',
  },
}))

const StyledLabel = styled.label`
  ${({ theme }) => css(theme.variants.body2.regular)};
  display: flex;
  align-items: center;
  position: relative;
  min-height: 74px;
  cursor: pointer;
  margin: 0 ${({ theme }) => theme.spacings.medium};
  padding-right: ${({ theme }) => theme.spacings.large};

  input[type='radio'] {
    opacity: 0;
    pointer-events: none;
  }
`

const ListItemTitle = styled.span(({ theme }) => ({
  ...theme.variants.body1.medium,
}))

const Distance = styled.small.attrs<{ distance: number }>(({ distance }) => ({
  'data-test-id': 'delivery-option-distance',
  children: `(${formatDistance(distance)})`,
}))<{ distance: number }>({
  display: 'inline-block',
  opacity: 0.7,
})

const ListItemTitleContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',

  [NextAvailableSlot]: {
    flex: '1 1 100%' /** force on own row */,
  },
})

const ListItemInfoContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexWrap: 'wrap',
  gap: theme.spacings.xxxSmall,
  margin: theme.spacings.xSmall,

  [minWidthFromTheme(theme).tablet]: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

const StyledCheckMarkContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
`

const StyledSelectedOptionDetails = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.grey100,
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  boxShadow: 'inset 0 1px 0 rgba(0,0,0,0.02)',
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacings.small} ${theme.spacings.medium}`,
}))

const StyledAnimationContainer = styled.div<{
  isSelected: boolean
  animationHeight: number | undefined
}>`
  overflow: hidden;
  max-height: 0;
  visibility: hidden;
  transition: max-height 0.3s ease-in-out, visibility 0.3s ease-in-out;
  /* AFAIK CSS only solution for animating height is not possible for dynamic height elements */
  ${({ isSelected, animationHeight }) =>
    isSelected && animationHeight && `max-height: ${animationHeight}px; visibility: visible;`}
`

const StyledSelectedOptionDetailsDescription = styled.div`
  display: flex;
  flex-direction: row;
  ${({ theme }) => css(theme.variants.body1.regular)};
  &:not(:first-child) {
    padding-top: ${({ theme }) => theme.spacings.xxSmall};
  }
`

const StyledSelectedOptionDetailsIconContainer = styled.div`
  padding-right: ${({ theme }) => theme.spacings.xxSmall};
  .icon {
    vertical-align: text-bottom;
  }
`

const StyledDownloadMobileAppContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
`

const StyledDownloadMobileApp = styled.div`
  margin-top: ${({ theme }) => theme.spacings.xxSmall};
  display: flex;
  justify-content: flext-start;
  align-items: center;
  flex-direction: row;
  column-gap: ${({ theme }) => theme.spacings.xxSmall};
`

interface CheckMarkProps {
  isSelected: boolean
}

const CheckMark: FC<CheckMarkProps> = ({ isSelected }) => {
  const theme = useTheme()
  return (
    <StyledCheckMarkContainer className="checkmark-container" aria-hidden>
      {isSelected ? (
        <ConfirmedFilled24 color={theme.color.highlight600} />
      ) : (
        <RiskIndicatorOutline24 color={theme.color.darkGrey500} />
      )}
    </StyledCheckMarkContainer>
  )
}

export type DeliveryOptionInfoCardProps = {
  deliveryOption: Pick<
    DeliveryOption,
    'areaId' | 'isFastTrack' | 'homeDeliveryType' | 'deliveryMethod' | 'description'
  > & {
    nextDeliverySlot?: NextAvailableSlotType
  }
  alcoholSellingNotAllowed?: boolean
  brand: Brand
  deliveryLocation?: string
  distance?: number | null
  isSelected: boolean
  onSelect: (areaId: string) => void
  selectedDeliveryOptionRef: MutableRefObject<HTMLLabelElement | null> | null
  title: string
}

export const DeliveryOptionInfoCard: FC<DeliveryOptionInfoCardProps> = memo(
  ({
    deliveryOption,
    alcoholSellingNotAllowed,
    brand,
    deliveryLocation,
    distance,
    isSelected,
    onSelect,
    selectedDeliveryOptionRef,
    title,
  }) => {
    const { t } = useTranslation()
    const { areaId, description, deliveryMethod, homeDeliveryType, nextDeliverySlot } =
      deliveryOption
    const isFastTrack = !!deliveryOption.isFastTrack
    const deliveryDescription =
      isFastTrack && homeDeliveryType === HomeDeliveryType.Robot
        ? 'Ostokset perille jopa tunnissa – kokeile robokuljetusta sovelluksella!'
        : description
    // Need to use state instead of ref as we need to re-render when the height is known due to animation
    const [deliveryOptionDetailsHeight, setDeliveryOptionDetailsHeight] = useState<
      number | undefined
    >(undefined)

    const handleOnChange = () => {
      onSelect(areaId)
    }

    const handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
      // space key
      if (e.keyCode === 32) {
        e.preventDefault()
        handleOnChange()
      }
    }

    const listItemTitleId = `list-item-title-${areaId}`

    return (
      <StyledContainer
        aria-selected={isSelected}
        data-test-id="delivery-option-info-card"
        data-test-name={title}
        onClick={handleOnChange}
        onKeyDown={handleOnKeyDown}
        role="option"
        tabIndex={0}
        aria-labelledby={listItemTitleId}
      >
        <StyledLabel ref={selectedDeliveryOptionRef ?? null}>
          <BrandStoreLogo brand={brand} data-test-id="delivery-option-info-card-logo" />

          <ListItemInfoContainer>
            <ListItemTitleContainer>
              <p>
                <ListItemTitle data-test-id="delivery-option-info-card-title" id={listItemTitleId}>
                  {deliveryMethod === DeliveryMethod.HomeDelivery && isFastTrack
                    ? `${title} ${t(
                        getDeliveryMethodString(deliveryMethod, isFastTrack, homeDeliveryType),
                      ).toLocaleLowerCase()}`
                    : title}
                </ListItemTitle>
                {isDistance(distance) && (
                  <>
                    {' '}
                    <Distance distance={distance} />
                  </>
                )}
              </p>

              {nextDeliverySlot && <NextAvailableSlot slot={nextDeliverySlot} />}
            </ListItemTitleContainer>
            <OptionLabels
              alcoholSellingNotAllowed={alcoholSellingNotAllowed}
              deliveryMethod={deliveryMethod}
              homeDeliveryType={homeDeliveryType}
              isFastTrack={isFastTrack}
            />
          </ListItemInfoContainer>

          <input
            checked={isSelected}
            id={`delivery-area-option-${title}`}
            name="delivery-area-option"
            onChange={handleOnChange}
            tabIndex={-1}
            type="radio"
            value={areaId}
          />

          <CheckMark isSelected={isSelected} />
        </StyledLabel>

        {(deliveryDescription || deliveryLocation) && (
          <StyledAnimationContainer
            isSelected={isSelected}
            animationHeight={deliveryOptionDetailsHeight}
          >
            <StyledSelectedOptionDetails
              ref={
                isSelected
                  ? (ref) => {
                      setDeliveryOptionDetailsHeight(ref?.clientHeight)
                    }
                  : null
              }
              data-test-id="delivery-option-info-card-details"
            >
              {deliveryDescription && (
                <StyledSelectedOptionDetailsDescription data-test-id="delivery-option-details-description">
                  <StyledSelectedOptionDetailsIconContainer>
                    <InfoOutline16 />
                  </StyledSelectedOptionDetailsIconContainer>
                  <div>{deliveryDescription}</div>
                </StyledSelectedOptionDetailsDescription>
              )}

              {homeDeliveryType === HomeDeliveryType.Robot && (
                <StyledDownloadMobileAppContainer>
                  <ListItemTitle>{t('Download S-Kaupat app')}</ListItemTitle>
                  <StyledDownloadMobileApp>
                    <ImageLinkButton
                      url="https://play.google.com/store/apps/details?id=fi.sok.skaupat"
                      alt={t('menuDrawer_placeholder Download from Google Play')}
                      imageUrl="/icons/playstore.svg"
                    />
                    <ImageLinkButton
                      url="https://apps.apple.com/fi/app/s-kaupat/id1528187064"
                      alt={t('menuDrawer_placeholder Download from App Store')}
                      imageUrl="/icons/appstore.svg"
                    />
                  </StyledDownloadMobileApp>
                </StyledDownloadMobileAppContainer>
              )}

              {deliveryLocation && (
                <StyledSelectedOptionDetailsDescription data-test-id="delivery-option-details-location">
                  <StyledSelectedOptionDetailsIconContainer>
                    <LocationOutline16 />
                  </StyledSelectedOptionDetailsIconContainer>
                  <div>{deliveryLocation}</div>
                </StyledSelectedOptionDetailsDescription>
              )}
            </StyledSelectedOptionDetails>
          </StyledAnimationContainer>
        )}
      </StyledContainer>
    )
  },
)

DeliveryOptionInfoCard.displayName = 'DeliveryOptionInfoCard'
