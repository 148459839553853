import type { ApolloCache, NormalizedCache, Operation, QueryResult } from '@apollo/client'
import type {
  AuthenticationTokens,
  GetRemoteAuthenticationTokensQuery,
  GetRemoteAuthenticationTokensQueryVariables,
} from '@shared/gql/document-nodes'

import { MissingFieldError } from '@apollo/client'
import { getConfig } from '@shared/config'
import {
  GetLocalAuthenticationTokensDocument,
  GetRemoteAuthenticationTokensDocument,
} from '@shared/gql/document-nodes'
import { print } from 'graphql'
import { refreshTokenExpired } from 'lib/apollo/reactive-vars'
import { DaoService } from 'services/LocalStorage'
import { commonLogger } from 'utils/log/common-logger'

const { api } = getConfig()

const cacheRefreshedTokens = (
  result: QueryResult<GetRemoteAuthenticationTokensQuery, GetRemoteAuthenticationTokensQuery>,
  apolloCache: ApolloCache<NormalizedCache>,
  message: string,
): void => {
  const { data, error } = result || {}
  if (data && data.authTokens) {
    const authenticationTokens: AuthenticationTokens = {
      ...data.authTokens,
      modified: new Date().getTime(),
      __typename: 'AuthenticationTokens',
    }

    apolloCache.writeQuery({
      query: GetLocalAuthenticationTokensDocument,
      data: {
        authenticationTokens,
        __typename: 'Query',
      },
    })
    DaoService.SessionDAO.saveAuthTokens(authenticationTokens)
    refreshTokenExpired(false)
  } else {
    commonLogger.errorSync({
      error: new Error(message),
      message: '[errorLink] Failed to extract tokens, data is missing from response',
      data: {
        error,
      },
    })
    refreshTokenExpired(true)
  }
}

export const getCachedTokens = (params: {
  operation: Operation
}): { authenticationTokens: AuthenticationTokens | null } | null => {
  const { operation } = params
  const { cache } = operation.getContext()
  const apolloCache = cache as ApolloCache<NormalizedCache>
  try {
    return apolloCache.readQuery({
      query: GetLocalAuthenticationTokensDocument,
    })
  } catch (e) {
    const missingFieldError = e instanceof MissingFieldError
    if (!missingFieldError) {
      throw e
    }
    return null
  }
}

export const fetchRefreshedTokens = async (params: {
  authenticationTokens: AuthenticationTokens
  operation: Operation
  message: string
}): Promise<void> => {
  const { authenticationTokens, operation, message } = params

  const query = print(GetRemoteAuthenticationTokensDocument)
  const variables: GetRemoteAuthenticationTokensQueryVariables = {
    refreshToken: authenticationTokens.refreshToken,
  }
  const { cache } = operation.getContext()
  const apolloCache = cache as ApolloCache<NormalizedCache>
  try {
    const response = await fetch(api.url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query,
        variables,
        operationName: 'GetRemoteAuthenticationTokens',
      }),
    })
    if (!response.ok) {
      throw new Error(`Got response code ${response.status} from token refresh query.`)
    }
    const result = await response.json()
    cacheRefreshedTokens(result, apolloCache, message)
  } catch (error) {
    commonLogger.errorSync({
      error,
      message: '[GraphQL error] Failed to refresh tokens',
      data: { originalMessage: message },
    })
    refreshTokenExpired(true)
  }
}
