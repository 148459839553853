import type { UseOrder } from './util.types'
import type { CartItem, Customer, Order, ProductList } from '@shared/gql/document-nodes'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  CustomerType,
  GetOrderByIdDocument,
  GetPaymentDetailsDocument,
  SetExistingOrderAsEditableOrderDocument,
} from '@shared/gql/document-nodes'
import { mergeCartItems } from 'domain/map-products-to-cart-items'
import { useRouter } from 'next/router'
import { concat, defaultTo } from 'ramda'
import { shoppingCartVar } from 'services/ClientCart/cache'
import { clearCart } from 'services/ClientCart/mutations/clear-cart'
import { mapCartItemsToRefetchQueries } from 'services/ClientCart/mutations/map-cart-items-to-refetch-queries'
import { useSetCartItems } from 'services/ClientCart/mutations/set-cart-items'
import {
  useCancelDeliverySlotReservation,
  useDeliverySlotReservationId,
} from 'services/DeliverySlot/hooks'
import { DeliverySlotReleaseReason } from 'services/DeliverySlot/tracking'
import { useLinkVerificationToken } from 'services/LinkVerificationToken/use-link-verification-token'
import { useAlcoholAllowedForDeliveryArea } from 'utils/hooks/delivery/use-alcohol-allowed'
import { mapCustomerToCustomerInput } from 'utils/mappers/backend-to-frontend-order-mapper'
import { mapCartItemsToClientCartItems } from 'utils/mappers/user-order-cart-to-client-cart-mapper'
import { getDeliverySlotPrice } from 'utils/order/get-delivery-slot-price'
import { useGetProductList } from 'views/shoppinglists/queries/use-add-list-to-cart'

import { filterServiceItems } from './filter-service-items'
import { getServiceFee } from './get-service-fee'
import { getStoreAndSlotData } from './get-store-and-slot-data'

export const useMergeOrder: UseOrder = (redirectPath) => {
  const clientCartItems = shoppingCartVar()
  const setCartItems = useSetCartItems()
  const router = useRouter()
  const orderItemsToCart = useGetOrderItems()

  return async (incomingOrder) => {
    const mappedCartItems = await orderItemsToCart(incomingOrder)
    const compiledItems = mergeCartItems(mappedCartItems, clientCartItems)
    clearCart()
    await setCartItems(compiledItems)
    await router.push(redirectPath)
  }
}

export const useReplaceOrder: UseOrder = (redirectPath) => {
  const setCartItems = useSetCartItems()
  const router = useRouter()
  const orderItemsToCart = useGetOrderItems()
  return async (incomingOrder) => {
    const mappedCartItems = await orderItemsToCart(incomingOrder)
    clearCart()
    await setCartItems(mappedCartItems)
    await router.push(redirectPath)
  }
}

const useGetOrderItems = () => {
  const getProductList = useGetProductList()
  const [setExistingOrderAsEditableOrder] = useMutation(SetExistingOrderAsEditableOrderDocument)
  const { data: paymentDetailsData } = useQuery(GetPaymentDetailsDocument)
  const alcoholSellingAllowed = useAlcoholAllowedForDeliveryArea()
  const apolloClient = useApolloClient()
  const linkVerificationToken = useLinkVerificationToken()
  const deliverySlotReservationId = useDeliverySlotReservationId()
  const cancelDeliverySlotReservation = useCancelDeliverySlotReservation()

  return async (incomingOrder: Order) => {
    const orderByIdQuery = await apolloClient.query({
      query: GetOrderByIdDocument,
      variables: { id: incomingOrder.id, linkVerificationToken },
      fetchPolicy: 'network-only',
    })

    /**
     * @todo [VOIK-7520] Trying to edit cancelled order shouldn't be allowed
     *
     * Maybe we can add some additional checks here that instead of
     * redirecting to edit we just reload the page
     * (and show that the order is cancelled).
     */
    const order = orderByIdQuery.data.order || incomingOrder

    const {
      cartItems,
      storeId,
      orderStatus,
      additionalInfo,
      customer,
      comment,
      deliveryDate,
      deliveryMethod,
      deliverySlotId,
      deliveryTime,
      id,
      paymentMethod,
      paymentStatus,
    } = order

    const orderCartItems = cartItems as CartItem[]
    const filteredCartItems = filterServiceItems(orderCartItems)
    const serviceFee = getServiceFee(orderCartItems)
    const sortedCart = serviceFee ? concat([serviceFee], filteredCartItems) : filteredCartItems

    const [{ name: storeName, brand, areaId }, deliverySlotPrice] = await Promise.all([
      getStoreAndSlotData(apolloClient, deliverySlotId, storeId),
      getDeliverySlotPrice(apolloClient, storeId, deliveryMethod, deliverySlotId, id, null),
      !!deliverySlotReservationId &&
        cancelDeliverySlotReservation(
          deliverySlotReservationId,
          DeliverySlotReleaseReason.ORDER_EDIT_STARTED,
        ),
    ])
    const customerType = customer?.companyName ? CustomerType.B2b : CustomerType.B2c

    await setExistingOrderAsEditableOrder({
      awaitRefetchQueries: true,
      variables: {
        additionalInfo: additionalInfo,
        address: customer?.address as string,
        city: customer?.city as string,
        comment,
        companyName: customer?.companyName as string,
        customer: mapCustomerToCustomerInput(customer as Customer),
        deliveryDate,
        deliveryMethod,
        deliverySlotId,
        deliveryTime,
        email: customer?.email as string,
        firstName: customer?.firstName as string,
        invoiceNumber: customer?.invoiceNumber as string,
        lastName: customer?.lastName as string,
        orderId: id,
        paymentMethod,
        paymentStatus,
        phone: customer?.phone as string,
        postalCode: customer?.postalCode as string,
        selectedStoreId: storeId,
        stockmannCardNumber: customer?.bonusCard as string,
        orderStatus,
        areaId: defaultTo(null, areaId),
        selectedAreaId: defaultTo(null, areaId),
        selectedStoreName: defaultTo(null, storeName),
        storeBrand: defaultTo(null, brand),
        storeName: defaultTo(null, storeName),
        deliverySlotPrice,
        selectedBrand: brand,
        selectedPaymentCardId: defaultTo(
          null,
          paymentDetailsData?.paymentDetails?.selectedPaymentCardId,
        ),
        savePaymentCard: defaultTo(false, paymentDetailsData?.paymentDetails?.savePaymentCard),
        customerType,
      },
      ...mapCartItemsToRefetchQueries(filteredCartItems, order, !!alcoholSellingAllowed),
    })

    const eans = sortedCart.map((c) => c.ean || '')
    const { items } = (await getProductList(eans, storeId)) as ProductList
    return mapCartItemsToClientCartItems(sortedCart, items || [])
  }
}
