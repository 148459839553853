import { CustomerType } from '@shared/gql/document-nodes'
import * as t from 'io-ts'

export enum CardType {
  SCARD = 'scard',
  STOCKMANN = 'stockmann',
}

export const PrivateCustomerDetails = t.type({
  type: t.literal(CustomerType.B2c),
  firstName: t.string,
  lastName: t.string,
  phone: t.string,
  email: t.string,
  stockmannCardNumber: t.union([t.string, t.null]),
  cardType: t.union([t.literal(CardType.SCARD), t.literal(CardType.STOCKMANN), t.null]),
})
export type PrivateCustomerDetails = t.TypeOf<typeof PrivateCustomerDetails>

export const CompanyCustomerDetails = t.type({
  type: t.literal(CustomerType.B2b),
  companyName: t.string,
  firstName: t.string,
  lastName: t.string,
  phone: t.string,
  email: t.string,
})
export type CompanyCustomerDetails = t.TypeOf<typeof CompanyCustomerDetails>

export const CustomerDetails = t.union([PrivateCustomerDetails, CompanyCustomerDetails])
export type CustomerDetails = PrivateCustomerDetails | CompanyCustomerDetails
