import type { FC } from 'react'

import { Button } from '@s-group/design-system-components'
import { acceptService, ConsentType, showSecondLayer } from '@s-group/react-usercentrics'
import { Popover } from '@sok/design-system'
import { ServiceId } from 'config/usercentrics'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getPopupContainer } from 'utils/common/get-popup-container'
import { useIsClientSide } from 'utils/hooks/use-is-client-side'

import { buttonPlacement, StyledChatButton, StyledSpan } from './styled'

const serviceId = ServiceId.Giosg

const _GiosgConsentGate: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  return (
    <article className={className}>
      <StyledElementGroup>
        <h1>{t`Chat requires consent`}</h1>
        <p>
          <small>{t`Enabling chat service`}</small>
        </p>
      </StyledElementGroup>
      <StyledElementGroup>
        <Button
          onClick={() => acceptService(serviceId, ConsentType.Explicit)}
          sizing="xsmall"
          rounding="small"
          primary
          compact
        >{t`Accept`}</Button>
        <Button
          onClick={() => showSecondLayer(serviceId)}
          sizing="xsmall"
          plain
        >{t`Read more`}</Button>
      </StyledElementGroup>
    </article>
  )
}

const GiosgConsentGate = styled(_GiosgConsentGate)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  minWidth: '232px',
  textAlign: 'center',
  padding: '1rem',

  h1: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },

  button: {
    width: '100%',
  },
})

const StyledElementGroup = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`

const _GiosgConsentGateButton: FC<{ bottomMargin: string; className?: string }> = ({
  bottomMargin,
  className,
}) => {
  const { t } = useTranslation()
  const isClient = useIsClientSide()
  if (!isClient) return null
  return (
    <div className={className}>
      <Popover
        content={<GiosgConsentGate />}
        getPopupContainer={getPopupContainer}
        placement="topLeft"
        trigger="click"
      >
        <StyledChatButton id="GiosgChatButton" bottomMargin={bottomMargin}>
          <StyledSpan>{t('Chat')}</StyledSpan>
        </StyledChatButton>
      </Popover>
    </div>
  )
}

export const GiosgConsentGateButton = styled(_GiosgConsentGateButton).attrs({
  className: 'uiButton helpButtonEnabled',
})(buttonPlacement, {
  /** Wrap the button in a container so that the Popover component works properly */
  [StyledChatButton]: {
    bottom: 18,
    left: 15,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: 40,
    border: '2px solid rgb(255, 255, 255)',
    background: 'rgb(228, 245, 232)',
  },
})
