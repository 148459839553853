import type { FC } from 'react'

import { useReactiveVar } from '@apollo/client'
import { DeliveryMethod } from '@shared/gql/document-nodes'
import { Alert, AlertType, ClearOutline16, Popover } from '@sok/design-system'
import { ErrorIcon } from 'components/icons'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  geolocationEnabledVar,
  pendingProductEanVar,
  storeAvailabilityFilterVar,
} from 'services/Delivery/cache'
import styled from 'styled-components'
import { useGetCartAlcoholItems } from 'utils/hooks/cart'
import { isPostalCode } from 'validation/is-postal-code'

import {
  GeolocationButton,
  GeolocationPrompt,
} from './delivery-option-search-input/GeolocationButton'
import {
  StoreAvailabilityCheck,
  StyledAlert,
  StyledClearButton,
  StyledInputLabelContainer,
  StyledSearchIcon,
  StyledSearchInput,
  StyledSearchInputContainer,
} from './delivery-option-search-input/styled'

interface DeliveryOptionSearchInputProps {
  activeDeliveryMethod: DeliveryMethod
  className?: string
  initialLoading: boolean
  onSearch: (searchTerm: string) => void
  searchTerm: string
}

const _DeliveryOptionSearchInput: FC<DeliveryOptionSearchInputProps> = ({
  activeDeliveryMethod,
  className,
  initialLoading,
  onSearch,
  searchTerm,
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(searchTerm)
  const [error, setError] = useState<string | null>(null)

  const isHomeDelivery = activeDeliveryMethod === DeliveryMethod.HomeDelivery

  const pendingEan = useReactiveVar(pendingProductEanVar)
  const storeAvailabilityFilterActive = useReactiveVar(storeAvailabilityFilterVar)
  const handleToggleStoreAvailaibilityFilter = useCallback(() => {
    storeAvailabilityFilterVar(!storeAvailabilityFilterActive)
  }, [storeAvailabilityFilterActive])

  const alcoholItems = useGetCartAlcoholItems()
  const cartContainsAlcohol = alcoholItems.length > 0

  useEffect(() => {
    setValue(searchTerm)
    setError(null)
  }, [searchTerm, activeDeliveryMethod])

  const handleChange = useCallback(
    (searchValue: string) => {
      setValue(searchValue)
      setError(null)

      if (isHomeDelivery) {
        if (isPostalCode(searchValue)) {
          onSearch(searchValue)
        } else if (searchValue.length > 5) {
          setError(t('Check your postal code') || '')
        } else if (searchValue.length === 0) {
          onSearch('')
        }
      } else {
        onSearch(searchValue)
        geolocationEnabledVar(false)
      }
    },
    [isHomeDelivery, onSearch, t],
  )

  const geolocationEnabled = useReactiveVar(geolocationEnabledVar)
  const [showGeolocationPopover, setShowGeolocationPopover] = useState(false)

  // When enabled, clicking won't show popover, but simply directly disable
  const handleGeolocationClick = () =>
    geolocationEnabled ? geolocationEnabledVar(false) : setShowGeolocationPopover(true)

  // Close prompt when status changes, probably because geolocation failed
  useEffect(() => {
    if (!geolocationEnabled) setShowGeolocationPopover(false)
  }, [geolocationEnabled])

  const clearButtonVisible = searchTerm.length > 0

  /** Use different id to make sure input value history doesn't leak between tabs */
  const inputId = `delivery-search-input--${isHomeDelivery ? 'home-delivery' : 'pickup'}`

  return (
    <div className={className}>
      {isHomeDelivery && cartContainsAlcohol && !value && (
        <Alert
          data-test-id="alcohol-warning"
          message={t('If you change delivery method alcohol will no longer be available')}
          type={AlertType.WARNING}
          closable
          className="alcohol-warning"
        />
      )}

      <StyledInputLabelContainer>
        <label htmlFor={inputId} data-test-id="delivery-option-search-input-label">
          {isHomeDelivery
            ? t('Search by postal code label')
            : t('Search with city or store name label')}
        </label>

        {!!pendingEan && (
          <StoreAvailabilityCheck
            data-test-id="checkbox-group-checkbox"
            defaultChecked={storeAvailabilityFilterActive}
            name="store-availability-toggle"
            onChange={handleToggleStoreAvailaibilityFilter}
          >
            {t`Show only stores selling this product`}
          </StoreAvailabilityCheck>
        )}
      </StyledInputLabelContainer>

      <StyledSearchInputContainer clearButtonVisible={clearButtonVisible}>
        <Popover
          className="geolocation-popover"
          content={
            <GeolocationPrompt
              onClose={() => setShowGeolocationPopover(false)}
              onToggleEnabled={() => geolocationEnabledVar(true)}
            />
          }
          visible={showGeolocationPopover}
        >
          <StyledSearchInput
            aria-label={t('Search')}
            autoComplete={
              /**
               * address-level2 should be the "city" of a saved address
               * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
               */
              isHomeDelivery ? 'postal-code' : 'address-level2'
            }
            data-test-id="delivery-option-search-input"
            disabled={initialLoading}
            id={inputId}
            onChange={(event) => handleChange(event.target.value)}
            placeholder={
              initialLoading
                ? t('Loading...')
                : t(isHomeDelivery ? 'Search by postal code' : 'Search with city or store name')
            }
            type={isHomeDelivery ? 'tel' : 'text'}
            value={value}
          />
        </Popover>

        <StyledSearchIcon />

        {!isHomeDelivery && (
          <GeolocationButton enabled={geolocationEnabled} onClick={handleGeolocationClick} />
        )}

        {clearButtonVisible && (
          <StyledClearButton title={t('Clear search')} onClick={() => handleChange('')}>
            <ClearOutline16 />
          </StyledClearButton>
        )}
      </StyledSearchInputContainer>

      {error && (
        <StyledAlert
          data-test-id="postal-code-error"
          icon={<ErrorIcon />}
          message={error}
          type={AlertType.ERROR}
        />
      )}
    </div>
  )
}

export const DeliveryOptionSearchInput = styled(_DeliveryOptionSearchInput)(({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginBottom: theme.spacings.small,
  paddingTop: theme.spacings.small,

  '> .alcohol-warning': {
    marginBottom: theme.spacings.small,
  },
}))
