import type { DocumentNode } from '@apollo/client'
import type { OperationDefinitionNode } from 'graphql'

const isOperationDefinition = (
  x: DocumentNode['definitions'][number],
): x is OperationDefinitionNode => x.kind === 'OperationDefinition'

export const getDocumentName = (document: DocumentNode): string => {
  const queryDef = document.definitions.find(isOperationDefinition)
  if (queryDef && queryDef.name) {
    return queryDef.name.value
  }
  throw new Error('Missing name in document definitions')
}
