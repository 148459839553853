import type { PureQueryOptions } from '@apollo/client/core'
import type {
  CartItem,
  GetProductInfoByEansQueryVariables,
  Order,
} from '@shared/gql/document-nodes'

import { GetProductInfoByEansDocument } from '@shared/gql/document-nodes'
import { isNonNullable } from 'utils/nullable'

export const mapCartItemsToRefetchQueries = (
  filteredCartItems: CartItem[],
  order: Pick<Order, 'storeId'>,
  includeAgeLimitedByAlcohol: boolean,
): { refetchQueries: PureQueryOptions[] } | undefined => {
  const eans = filteredCartItems.map((item) => item.ean).filter(isNonNullable)

  if (!eans.length) {
    return undefined
  }

  const variables: GetProductInfoByEansQueryVariables = {
    availabilityDate: null,
    eans: eans,
    fallbackToGlobal: null,
    id: order.storeId,
    includeAgeLimitedByAlcohol,
    includeAvailabilities: false /** cart will query these separately  */,
  }

  return {
    refetchQueries: [{ query: GetProductInfoByEansDocument, variables }],
  }
}
