import type { ApolloError } from '@apollo/client'

import { useQuery } from '@apollo/client'
import {
  GetDeliveryAreaDetailsDocument,
  GetSelectedAreaIdDocument,
} from '@shared/gql/document-nodes'
import { useMemo } from 'react'

interface SelectedDeliveryAreaDetails {
  called: boolean
  city: string | null
  deliveryAreaName?: string
  dynamicPricing: boolean
  error?: ApolloError
  isFastTrack: boolean
  loading: boolean
  storeName?: string
}

export const useSelectedDeliveryAreaDetails = (): SelectedDeliveryAreaDetails => {
  const { data: selectedAreaIdData } = useQuery(GetSelectedAreaIdDocument)

  const { called, data, error, loading } = useQuery(GetDeliveryAreaDetailsDocument, {
    skip: !selectedAreaIdData?.selectedAreaId,
    variables: { id: selectedAreaIdData?.selectedAreaId ?? '' },
  })

  return useMemo(
    () => ({
      called: called,
      city: data?.deliveryArea?.address?.city ?? null,
      deliveryAreaName: data?.deliveryArea?.name,
      /** @deprecated — This is only to enable a feature in clients. Will be removed in near future */
      dynamicPricing: data?.deliveryArea?.dynamicPricing ?? false,
      error: error,
      isFastTrack: data?.deliveryArea?.isFastTrack ?? false,
      loading,
      storeName: data?.deliveryArea?.store?.name ?? undefined,
    }),
    [
      called,
      data?.deliveryArea?.address?.city,
      data?.deliveryArea?.dynamicPricing,
      data?.deliveryArea?.isFastTrack,
      data?.deliveryArea?.name,
      data?.deliveryArea?.store?.name,
      error,
      loading,
    ],
  )
}
