// Attempt an operation multiple times if it fails due to network or server errors.
// https://www.apollographql.com/docs/link/links/retry/
import { RetryLink } from '@apollo/client/link/retry'

export const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 1000,
    jitter: true,
  },
  attempts: {
    max: 6,
    retryIf: (error) => !!error,
  },
})
