import type { FC } from 'react'

import { IconControlCompare } from '@s-group/design-system-icons'
import { DeliveryMethod } from '@shared/gql/document-nodes'
import { DeliveryMethodIcon } from 'components/DeliveryMethodIcon'
import { NextLink } from 'components/NextLink'
import { useTranslation } from 'react-i18next'
import { setDeliveryModalStage } from 'services/Delivery/cache'
import styled, { css, useTheme } from 'styled-components'
import { DeliverySelectionStage } from 'types/global'

const handleChangeDeliveryArea = () =>
  setDeliveryModalStage(DeliverySelectionStage.SELECT_METHOD_AND_STORE)

interface Props {
  className?: string
  deliveryMethod: DeliveryMethod
  isStoreSelectionDisabled?: boolean
  isSlotSelected: boolean
  deliveryAreaName?: string
  storeName?: string
}

const _CurrentSelectionInfo: FC<Props> = ({
  className,
  deliveryMethod,
  deliveryAreaName,
  storeName,
  isStoreSelectionDisabled,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const deliveryMethodText = t(deliveryMethod, { context: undefined })
  const description = deliveryMethod === DeliveryMethod.HomeDelivery ? storeName : deliveryAreaName

  return (
    <NextLink
      className={className}
      as="button"
      data-test-id="change-selected-store"
      disabled={isStoreSelectionDisabled}
      onClick={handleChangeDeliveryArea}
    >
      <div className="icon">
        <DeliveryMethodIcon deliveryMethod={deliveryMethod} isFastTrack={false} />
      </div>
      <div className="header">
        <h4>{deliveryMethodText}</h4>
      </div>
      <div className="description">
        <h5>{description}</h5>
      </div>
      <div className="swap">
        <IconControlCompare color={theme.colors.SDS_BRAND_COLOR_BORDER_STRONG_PRIMARY} />
      </div>
    </NextLink>
  )
}

export const CurrentSelectionInfo = styled(_CurrentSelectionInfo)(
  ({ theme }) => css`
    width: 100%;
    border: 1px solid ${theme.colors.SDS_BRAND_COLOR_BORDER_WEAK_GREY};
    border-radius: 0.375rem;
    padding: 0 ${theme.spacings.medium};
    margin: 0;
    text-align: center;
    background: inherit;
    color: ${theme.colors.SDS_BRAND_COLOR_TEXT_STRONGER_GREY};
    cursor: pointer;
    min-height: 48px;
    max-height: 48px;

    &:hover {
      color: inherit;
    }

    @media (max-width: ${theme.breakpoints.tablet}) {
      max-width: 440px;
      margin-left: auto;
      margin-right: auto;
    }

    display: grid;
    justify-content: left;
    grid-template-areas:
      'icon method swap'
      'icon store swap';
    grid-template-columns: auto 1fr auto;
    font: ${theme.fontWeights.medium} 1rem / 1rem 'S Bonus UX';

    .header {
      grid-area: method;
      text-align: left;
      padding-bottom: 1px;
      font-weight: ${theme.fontWeights.medium};
      margin-top: auto;
      width: 100%;
      align-self: flex-end;
      h4 {
        font-size: 0.75rem;
        font-weight: ${theme.fontWeights.medium};
      }
    }
    .description {
      padding-top: 1px;
      grid-area: store;
      text-align: left;
      h5 {
        font-size: 0.75rem;
        font-weight: ${theme.fontWeights.regular};
      }
    }
    .swap {
      grid-area: swap;
      text-align: left;
      align-self: center;
    }

    .icon {
      grid-area: icon;
      justify-items: center;
      padding-right: ${theme.spacings.xxSmall};
      align-self: center;
      position: relative;
    }

    &:disabled {
      .icon {
        filter: grayscale(1);
        opacity: 0.32;
      }
      .swap {
        opacity: 0.32;
      }
    }
  `,
)
