import { useQuery } from '@apollo/client'
import { CustomerType, GetCustomerTypeDocument } from '@shared/gql/document-nodes'

import { useGetCurrentProfile } from './use-get-current-profile'

function isCustomerType(x: unknown): x is CustomerType {
  return x === CustomerType.B2b || x === CustomerType.B2c
}

export const useCustomerType = (): CustomerType => {
  const { data: customerTypeData } = useQuery(GetCustomerTypeDocument)
  const currentUser = useGetCurrentProfile()

  if (currentUser?.customerType) {
    return currentUser.customerType
  }

  if (customerTypeData && isCustomerType(customerTypeData.customerType)) {
    return customerTypeData.customerType
  }
  return CustomerType.B2c
}
