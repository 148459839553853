import { DeliveryMethod } from '@shared/gql/document-nodes'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { StyledHeading, StyledSubHeading } from './styles/styled-time-slots-error'

interface TimeSlotsErrorProps {
  className?: string
  deliveryMethod: DeliveryMethod
}
const _TimeSlotsError = ({ className, deliveryMethod }: TimeSlotsErrorProps) => {
  const theme = useTheme()
  return (
    <div data-test-id="time-slots-error" className={className}>
      <img src={theme.logos.notFoundSlot} alt="no-time-slot-found" />
      <NoTimeSlotsInfo deliveryMethod={deliveryMethod} />
    </div>
  )
}
export const TimeSlotsError = styled(_TimeSlotsError)`
  display: flex;
  flex: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface NoTimeSlotsInfoProps {
  deliveryMethod: DeliveryMethod
}
const NoTimeSlotsInfo = ({ deliveryMethod }: NoTimeSlotsInfoProps) => {
  const { t } = useTranslation()
  let _: never

  switch (deliveryMethod) {
    case DeliveryMethod.HomeDelivery: {
      return (
        <>
          <StyledHeading data-test-id={`no-time-slot-heading-${DeliveryMethod.HomeDelivery}`}>
            {t('No free delivery times')}
          </StyledHeading>
          <StyledSubHeading data-test-id={`no-time-slot-subHeading-${DeliveryMethod.HomeDelivery}`}>
            {t('This month, all delivery times are booked')}
          </StyledSubHeading>
        </>
      )
    }

    case DeliveryMethod.Pickup: {
      return (
        <>
          <StyledHeading data-test-id={`no-time-slot-heading-${DeliveryMethod.Pickup}`}>
            {t('No free pick-up times')}
          </StyledHeading>
          <StyledSubHeading data-test-id={`no-time-slot-subHeading-${DeliveryMethod.Pickup}`}>
            {t('This month, all pick-up times are booked')}
          </StyledSubHeading>
        </>
      )
    }

    default: {
      _ = deliveryMethod
      return _
    }
  }
}
