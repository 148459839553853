import type { MutationResolvers } from 'types/gql/type-defs'

import {
  GetDeliveryStoreDocument,
  GetSelectedAreaIdDocument,
  GetSelectedBrandDocument,
  GetSelectedStoreIdDocument,
} from '@shared/gql/document-nodes'
import { DaoService } from 'services/LocalStorage'

export const updateSelectedAreaId: MutationResolvers['updateSelectedAreaId'] = (
  _,
  args,
  { cache },
) => {
  const selectedAreaId = args.selectedAreaId ?? null

  cache.writeQuery({
    query: GetSelectedAreaIdDocument,
    data: { __typename: 'Query', selectedAreaId },
  })

  DaoService.DeliveryDAO.saveSelectedAreaId(selectedAreaId)

  return null
}

export const updateSelectedStoreId: MutationResolvers['updateSelectedStoreId'] = (
  _,
  args,
  { cache },
) => {
  const selectedStoreId = args.selectedStoreId ?? null

  cache.writeQuery({
    query: GetSelectedStoreIdDocument,
    data: { __typename: 'Query', selectedStoreId },
  })

  DaoService.StoreDAO.saveStoreId(selectedStoreId)

  return null
}

export const updateSelectedBrand: MutationResolvers['updateSelectedBrand'] = (
  _,
  args,
  { cache },
) => {
  const selectedBrand = args.selectedBrand ?? null

  cache.writeQuery({
    query: GetSelectedBrandDocument,
    data: { __typename: 'Query', selectedBrand },
  })

  DaoService.StoreDAO.saveSelectedBrand(selectedBrand)

  return null
}

export const setDeliveryStore: MutationResolvers['setDeliveryStore'] = (_, args, { cache }) => {
  const deliveryStore = {
    __typename: 'DeliveryStore' as const,
    areaId: args.areaId ?? null,
    availablePaymentMethods: args.availablePaymentMethods ?? [],
    brand: args.brand ?? null,
    id: args.id ?? '',
    name: args.name ?? null,
  }

  cache.writeQuery({
    query: GetDeliveryStoreDocument,
    data: {
      __typename: 'Query',
      deliveryStore,
    },
  })

  DaoService.StoreDAO.saveDeliveryStore(deliveryStore)

  return null
}

export const resetDeliveryStore: MutationResolvers['resetDeliveryStore'] = (
  _,
  _args,
  { cache },
) => {
  cache.writeQuery({
    query: GetDeliveryStoreDocument,
    data: {
      __typename: 'Query',
      deliveryStore: null,
    },
  })

  return null
}
