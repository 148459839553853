import type { ApolloError } from '@apollo/client'
import type { GetUserProfileDataQuery, UserProfile } from '@shared/gql/document-nodes'

import { useQuery } from '@apollo/client'
import { GetUserProfileDataDocument } from '@shared/gql/document-nodes'

import { useGetCachedAuthorizationTokens } from './use-get-cached-authorization-tokens'

type RemoteProfileData = {
  loading: boolean
  userProfile: GetUserProfileDataQuery['userProfile'] | null | undefined
  error: ApolloError | undefined
}

export const useGetRemoteUserProfile = (): RemoteProfileData => {
  const { accessToken: localAccessToken } = useGetCachedAuthorizationTokens() || {}
  const { data, loading, error } = useQuery(GetUserProfileDataDocument, {
    fetchPolicy: 'network-only',
    skip: !localAccessToken,
  })

  return {
    loading: loading,
    userProfile: data?.userProfile,
    error: error,
  }
}

export const useGetCachedRemoteUserProfile = (): UserProfile | null | undefined => {
  const { accessToken: localAccessToken } = useGetCachedAuthorizationTokens() || {}
  const { data } = useQuery(GetUserProfileDataDocument, {
    skip: !localAccessToken,
  })

  return data?.userProfile
    ? {
        ...data.userProfile,
        personContactId: null,
      }
    : undefined
}
