import type { AppPropsWithState } from 'domain/next'

import '@s-group/design-system-tokens/web/assets/fonts/SBonusUX.css'
import '@s-group/design-system-tokens/web/tokens/font.css'
import 'intersection-observer'

import { ApolloProvider, useReactiveVar } from '@apollo/client'
import {
  useHasServiceConsent,
  useHasUserInteracted,
  useIsInitialized,
  UsercentricsProvider,
} from '@s-group/react-usercentrics'
import { getConfig } from '@shared/config'
import { initI18n } from '@shared/i18n'
import { DesignSystemThemeProvider } from '@sok/design-system'
import { AccountContextProvider } from 'components/Account/AccountContextProvider'
import { CollapsibleSidepanelProvider } from 'components/CollapsibleSidepanel/CollapsibleSidepanelProvider'
import { DefaultNextSeo } from 'components/DefaultNextSeo'
import { DynatraceScript } from 'components/DynatraceScript'
import { ErrorModalContainer } from 'components/errors/ErrorModalContainer'
import { FullscreenLoader } from 'components/FullscreenLoader'
import { GlobalLoadingIndicator } from 'components/GlobalLoadingIndicator'
import { IEPage } from 'components/IEPage'
import { OpenSearchDescription } from 'components/structured-data/OpenSearchDescription'
import { ServiceId, USERCENTRICS_WINDOW_EVENT_NAME } from 'config/usercentrics'
import { FavoritesProvider } from 'favorites/FavoritesProvider'
import { useClientSideApolloClient } from 'lib/apollo/get-apollo-client'
import {
  isClientSideVar,
  localizationVar,
  navigationVisibleVar,
  overrideBrandVar,
  searchVisibleVar,
} from 'lib/apollo/reactive-vars'
import { useLocalStorageRefresh, useRefreshOnce } from 'lib/apollo/refresh-apollo'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Context as ResponsiveContext } from 'react-responsive'
import { ContentfulProvider } from 'services/Contentful/contentful-provider'
import { DeliverySlotReservationTracker } from 'services/DeliverySlot/components/DeliverySlotReservationTracker'
import { ExperimentProvider } from 'services/Experiment/ExperimentProvider'
import { GiosgScript } from 'services/Giosg/components/GiosgScript'
import { ModalRoot } from 'services/Modal'
import { SearchCacheEvictor } from 'services/Search/components/SearchCacheEvictor'
import { useSelectSearchProvider } from 'services/Search/hooks/use-search-provider'
import { GlobalSnackbars, Snackbars } from 'services/Snackbar/Snackbars'
import { ThemeProvider } from 'styled-components'
import { DefaultGlobalStyle } from 'styles/global'
import theme from 'themes/s-kaupat'
import { useOverrideBrand } from 'utils/brand/override-brand'
import { useGetSelectedBrand } from 'utils/brand/use-get-selected-brand'
import { useUpdateDeliveryDate } from 'utils/hooks/delivery/use-delivery-date'
import { useSessionActivityDetector } from 'utils/hooks/use-is-session-active'
import { useOnRouteChangeStart } from 'utils/hooks/use-on-route-change-start'
import { useDeliveryModalOpener } from 'utils/hooks/use-open-delivery-modal'
import { useSelectedStoreCookie } from 'utils/hooks/use-selected-store-cookie'
import { initSentryLogger } from 'utils/log/sentry-logger'
import { useLoginTracker } from 'utils/tracking/hooks/use-login-tracker'
import { useOrderEditTracker } from 'utils/tracking/hooks/use-order-edit-tracker'
import { useStoreAndDeliveryTracker } from 'utils/tracking/hooks/use-store-and-delivery-tracker'
import { dataLayer, initializeTagManager } from 'utils/tracking/tag-manager'
import { getUserAgent, isOlderIE } from 'utils/user-agent'
import { DeliveryModalContainer } from 'views/delivery-modal/DeliveryModal'

const { featureFlags, google, stage } = getConfig()

type WebstoreAppProps = Pick<AppPropsWithState, 'Component' | 'pageProps' | 'err'>

const WebstoreApp = ({ Component, pageProps, err }: WebstoreAppProps) => {
  const router = useRouter()

  useEffect(() => {
    isClientSideVar(true)
  }, [])

  const isClientSide = useReactiveVar(isClientSideVar)

  useDeliveryModalOpener()
  useOverrideBrand()
  useSelectedStoreCookie()
  useSelectSearchProvider()
  useSessionActivityDetector()
  useOnRouteChangeStart(() => {
    searchVisibleVar(false)
    navigationVisibleVar(false)
  })

  const hasSentryConsent = useHasServiceConsent(ServiceId.Sentry)

  useEffect(() => {
    if (hasSentryConsent) {
      initSentryLogger()
    }
  }, [hasSentryConsent])

  const overrideBrand = useReactiveVar(overrideBrandVar)
  const currentBrand = useGetSelectedBrand()
  const brand = overrideBrand || currentBrand

  useOrderEditTracker()
  useLoginTracker()
  useStoreAndDeliveryTracker(brand)
  useUpdateDeliveryDate()

  const initializedGTM = useRef(false)
  const hasExplicitConsentStatus = useHasUserInteracted()
  const isUserCentricsInitialized = useIsInitialized()
  const hasGTMConsent = useHasServiceConsent(ServiceId.GoogleTagManager)

  useEffect(() => {
    /** Initialize GTM only after UC initialized and user interacts with consent modal, so consent_status datalayer with type explicit is pushed before other dataLayers*/
    if (
      google.tagManagerId &&
      isUserCentricsInitialized &&
      hasExplicitConsentStatus &&
      hasGTMConsent &&
      !initializedGTM.current
    ) {
      initializeTagManager(google.tagManagerId)
      dataLayer({ siteBrand: currentBrand, featureFlags })
      initializedGTM.current = true
    }
  }, [currentBrand, isUserCentricsInitialized, hasExplicitConsentStatus, hasGTMConsent])

  useEffect(() => {
    dataLayer({
      event: 'themeChange',
      siteBrand: currentBrand,
    })
  }, [currentBrand])

  const localization = useReactiveVar(localizationVar)
  const i18n = useMemo(() => initI18n(localization), [localization])

  if (isOlderIE(getUserAgent())) {
    return (
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <DefaultGlobalStyle />
          <IEPage />
        </ThemeProvider>
      </I18nextProvider>
    )
  }

  /**
   * Tokens might not exist on the login page, so avoid rendering most providers
   * that might access authenticated data.
   */
  if (router.asPath.startsWith('/kirjaudutaan')) {
    return (
      <ThemeProvider theme={theme}>
        <DesignSystemThemeProvider theme={theme.designSystem}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Component {...pageProps} err={err} />
        </DesignSystemThemeProvider>
      </ThemeProvider>
    )
  }

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <DesignSystemThemeProvider theme={theme.designSystem}>
          <DefaultNextSeo />

          <ResponsiveContext.Provider
            value={isClientSide ? undefined : { width: 320, deviceWidth: 320, handheld: true }}
          >
            <DefaultGlobalStyle />

            <OpenSearchDescription />

            <GlobalLoadingIndicator />

            <SearchCacheEvictor />

            <DeliverySlotReservationTracker />

            <ContentfulProvider brand={brand} initialState={pageProps.contentfulState}>
              <GiosgScript />

              <DynatraceScript />

              <AccountContextProvider>
                <ExperimentProvider>
                  <CollapsibleSidepanelProvider>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Component {...pageProps} err={err} />
                    <ModalRoot />
                    <DeliveryModalContainer />
                    <ErrorModalContainer />
                    <FavoritesProvider />
                    <FullscreenLoader />
                  </CollapsibleSidepanelProvider>
                </ExperimentProvider>
              </AccountContextProvider>

              {isClientSide && (
                <>
                  <Snackbars />
                  <GlobalSnackbars />
                </>
              )}
            </ContentfulProvider>
          </ResponsiveContext.Provider>
        </DesignSystemThemeProvider>
      </ThemeProvider>
    </I18nextProvider>
  )
}

const App = ({ Component, pageProps, err }: AppPropsWithState) => {
  const apolloClient = useClientSideApolloClient(pageProps.apolloState)
  useRefreshOnce(apolloClient)
  useLocalStorageRefresh(apolloClient)

  return (
    <ApolloProvider client={apolloClient}>
      <UsercentricsProvider
        timeout={15000}
        strictMode={stage === 'dev'}
        windowEventName={USERCENTRICS_WINDOW_EVENT_NAME}
      >
        <WebstoreApp Component={Component} pageProps={pageProps} err={err} />
      </UsercentricsProvider>
    </ApolloProvider>
  )
}

export default App
