import { PaymentMethod } from '@shared/gql/document-nodes'
import * as z from 'zod'

/** When bumping this the next time, change it to a simple number, e.g 1. The only purpose of the version is to increment it. */
export const PAYMENT_SCHEMA_VERSION = '1.2.17'

export const PaymentSchema = z.object({
  cacheVersion: z.literal(PAYMENT_SCHEMA_VERSION),
  paymentData: z.object({
    paymentDetails: z
      .object({
        __typename: z.literal('PaymentDetailsResponse'),
        paymentMethod: z.nativeEnum(PaymentMethod).nullable(),
        invoiceNumber: z.string().nullable(),
        stockmannCardNumber: z.string().nullable(),
        /**
         * There is a PaymentStatus enum, but in `PaymentDetailsResponse`
         * this is typed as a string
         */
        paymentStatus: z.string().nullable(),
        selectedPaymentCardId: z.string().nullable(),
        savePaymentCard: z.boolean(),
      })
      .nullable(),
  }),
})
