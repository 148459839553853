import type { AssertedStoreInfo, SearchDeliveryAreasQueryDeliveryArea } from './types'

import { commonLogger } from 'utils/log/common-logger'

export const isValidDeliveryArea = <T extends SearchDeliveryAreasQueryDeliveryArea>(
  deliveryArea: T,
): deliveryArea is T & { store: AssertedStoreInfo } => {
  if (!!deliveryArea.store?.name && !!deliveryArea.store?.brand) {
    return true
  } else {
    commonLogger.errorSync({
      error: Error(
        'Received delivery area with missing data. It will be filtered out from delivery options list.',
      ),
      data: deliveryArea,
    })
    return false
  }
}
